import React, { useState } from "react";
import common, { api } from "../../../../../data/common";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { useMainContext } from "../../../../../contexts/Provider";

/*component*/
import UnderstandingModal from "./UnderstandingModal";
import ReportAnswerImage from "./ReportAnswerImage";

const InspenctionPage1 = ({
  surveydate,
  surveyid,
  understanded,
  setUnderstanded,
  MYID,
  jangruReplyNurse,
  jangruReplyNurseAdmin,
  jangruCheck,
  imageUrls,
  extraUrls,
}) => {
  const { loginInput } = useMainContext();
  const messagebol = "/images/mo/messagebol.svg";
  const defaultimage = "/images/mo/defaultimage.svg";
  const fileicon = "/images/mo/file.svg";
  const [understandModal, setUnderStandModal] = useState(false);
  console.log(imageUrls, extraUrls);
  const showUnderStandModal = () => {
    setUnderStandModal(!understandModal);
  };
  console.log(jangruCheck, jangruReplyNurseAdmin);
  return (
    <>
      {jangruCheck === undefined ||
      jangruReplyNurse === undefined ||
      jangruReplyNurseAdmin.length === 0 ? (
        <div className="w-full p-[24px] wordbreak-keepall">
          <div className="flex gap-x-[16px] pb-[16px] border-b">
            <span className="inline-block w-[40px] h-[40px] bg-[#F1F2F3] rounded-full"></span>
            <div className="">
              <span className="block text-[#343B48] subtitle_2">
                장루간호사
              </span>
              <span className="block caption_2 text-[#676C76]">작성중...</span>
            </div>
          </div>

          <div className="p-[16px] relative">
            <span className=" text-[#1B5EDC] body_1_600 bg-[#CBDFFC] p-[8px] rounded-xl">
              건강상태를 확인하고 있어요~
            </span>
            <span className="absolute top-[20px] left-[6px] inline-block bg-[#CBDFFC] w-[12px] h-[16px] rounded-bl-full"></span>
          </div>

          <div className="pt-[8px]">
            <span className="block">담당 장루간호사가 현재</span>
            <span className="block">
              {loginInput.name}님의 건강상태를 확인하고 있습니다.
            </span>
          </div>
          <div className="pt-[24px]">
            <span className="block">
              점검 내용이 올라오면, 알림을 보내드려요.
            </span>
            <span className="block">잠시만 기다려주세요~☺️</span>
          </div>
        </div>
      ) : (
        <>
          <div className="w-full bg-[#CBDFFC] px-[24px] py-[16px]">
            {jangruCheck.fullEvaluation === 1 ? (
              <div className="flex items-start subtitle_1_600">
                <img src={messagebol} alt="messagebol" />
                <div className="pl-[17px]">
                  <span className="block leading-6">
                    추적 관찰이 필요합니다.
                  </span>
                  <span className="block leading-6">
                    다음 정기 점검 때 관찰하겠습니다.
                  </span>
                </div>
              </div>
            ) : jangruCheck.fullEvaluation === 2 ? (
              <div>
                <span className="block">병원 방문이 필요합니다.</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="p-[24px]">
            <div className="flex items-center pb-[24px] border-b">
              {jangruReplyNurseAdmin.profileURL === null ? (
                <img
                  src={defaultimage}
                  alt="defaultimg"
                  className="w-[40px] h-[40px]"
                />
              ) : (
                <img
                  src={
                    common.networkImg +
                    encodeURIComponent(jangruReplyNurseAdmin.profileURL)
                  }
                  alt={jangruReplyNurseAdmin.profileURL}
                  className="w-[40px] h-[40px] rounded-full"
                />
              )}

              <div className="pl-[16px]">
                <span className="block text-[#343B48] font-semibold">
                  {jangruReplyNurseAdmin.name}{" "}
                  {jangruReplyNurseAdmin.role === 0
                    ? "관리자"
                    : jangruReplyNurseAdmin.role === 1
                    ? "의사"
                    : jangruReplyNurseAdmin.role === 2
                    ? "장루 간호사"
                    : ""}
                </span>
                <span className="block caption_2 text-[#676C76]">
                  {dayjs(jangruReplyNurse.createdAt).format("YYYY/MM/DD")}
                </span>
              </div>
            </div>
            <div
              className="block pt-[24px]"
              dangerouslySetInnerHTML={{
                __html: jangruReplyNurse.reply,
              }}
            ></div>
            <div className="block mt-4">
              {imageUrls.length > 0
                ? imageUrls.map((el, index) => (
                    <ReportAnswerImage key={index} imageurl={el} />
                  ))
                : ""}
              {extraUrls.length > 0 ? (
                <div className="bg-[#F2F6FC] p-[8px] rounded-lg flex items-start">
                  <img src={fileicon} alt="file" />
                  <div className="break-all">
                    {extraUrls.map((el, index) => (
                      <div key={index} className="pl-[6px]">
                        <a
                          href={common.networkImg + encodeURIComponent(el)}
                          className="underline block hover:text-[#1B5EDC] overflow-hidden break-all"
                          download={el}
                        >
                          {el}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="fixed bottom-0 left-1/2  w-full px-[24px] py-[12px] transform-x-50 bg-[#FAFCFF]">
            {understanded === undefined ? (
              <>
                <div
                  className="w-full button_700 text-[#1B5EDC] border border-[#1B5EDC] 
                          hover:bg-[#F2F6FC] hover:text-[#18428E] ease-in-out duration-300 
                          rounded p-[8px] text-center cursor-pointer"
                  onClick={showUnderStandModal}
                >
                  이해도 체크
                </div>

                <span className="text-[#3F82ED] caption_1_400 pt-[4px] inline-block">
                  점검내용에 대한 이해정도를 체크해주세요 :)
                </span>
              </>
            ) : (
              <>
                <div
                  className="w-full button_700 text-[#CCCDD1] border border-[#CCCDD1] rounded p-[8px] text-center cursor-pointer"
                  onClick={showUnderStandModal}
                >
                  이해도 체크 완료
                </div>

                <span className="text-[#3F82ED] caption_1_400 pt-[4px] inline-block">
                  점검내용에 대한 이해정도를 체크해주세요 :)
                </span>
              </>
            )}
          </div>
          {understandModal ? (
            <UnderstandingModal
              showUnderStandModal={showUnderStandModal}
              surveyid={surveyid}
              understanded={understanded}
              setUnderstanded={setUnderstanded}
              surveydate={surveydate}
              MYID={MYID}
            />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default InspenctionPage1;
