import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StomaManageText } from "../../Main/data/StomaManageText";
const Check11 = ({ checkInfo, setCheckInfo }) => {
  const jangruboy = "/images/mo/jangruboyhi.svg";
  const navigate = useNavigate();
  console.log(checkInfo.symptom);
  const [recommend, setRecommend] = useState([]);
  const [recoIMG1, setRecoIMG1] = useState([]);
  const [recoIMG2, setRecoIMG2] = useState([]);
  const [IMG1, setIMG1] = useState();
  const [IMG2, setIMG2] = useState();

  useEffect(() => {
    let pageOpen = false;
    if (!pageOpen) {
      const filterSymptom1 = checkInfo.symptom.filter((el) => el === 1);
      const filterSymptom2 = checkInfo.symptom.filter((el) => el === 2);
      const filterSymptom3 = checkInfo.symptom.filter((el) => el === 3);
      const filterSymptom4 = checkInfo.symptom.filter((el) => el === 4);
      const filterSymptom5 = checkInfo.symptom.filter((el) => el === 5);
      const filterSymptom6 = checkInfo.symptom.filter((el) => el === 6);

      const titleList1 = ["자주묻는질문", "소화기관?!", "장루?!"];
      const titleList2 = ["생활 속 관리", "신체활동 관리"];
      const titleList3 = ["장루?!", "장루 관리방법"];
      const titleList4 = ["식생활 관리", "생활 속 관리"];
      const titleList5 = ["장루 관리방법", "장루 합병증", "피부 합병증"];
      const titleList6 = ["정신건강 관리", "복지혜택"];
      const titleList7 = [];
      //1.checkNote 값있을시 =>랜덤선택
      if (checkInfo.symptomNote !== "") {
        let duplicatieTitleList = titleList1.concat(
          titleList2,
          titleList3,
          titleList4,
          titleList5,
          titleList6
        );
        let titleListAllRan = [...new Set(duplicatieTitleList)];

        let pickRandomall = Math.floor(Math.random() * titleListAllRan.length);
        let pickRandomall_2 = 0;
        if (pickRandomall >= 1) {
          pickRandomall_2 = pickRandomall - 1;
        } else {
          pickRandomall_2 = pickRandomall + 1;
        }
        setRecommend([
          titleListAllRan[pickRandomall],
          titleListAllRan[pickRandomall_2],
        ]);
        let filterImage1 = StomaManageText.filter(
          (el) => el.title === titleListAllRan[pickRandomall]
        );
        let filterImage2 = StomaManageText.filter(
          (el) => el.title === titleListAllRan[pickRandomall_2]
        );

        setRecoIMG1(filterImage1);
        setRecoIMG2(filterImage2);
        setIMG1(filterImage1[0].checkurl);
        setIMG2(filterImage2[0].checkurl);
      } else if (checkInfo.symptom.length === 1) {
        if (filterSymptom1.length === 1) {
          //문제없음선택시
          let pickRandom1 = Math.floor(Math.random() * titleList1.length);

          let pickRandom1_2 = 0;
          if (pickRandom1 >= 1) {
            pickRandom1_2 = pickRandom1 - 1;
          } else {
            pickRandom1_2 = pickRandom1 + 1;
          }
          setRecommend([titleList1[pickRandom1], titleList1[pickRandom1_2]]);
          let filterImage1 = StomaManageText.filter(
            (el) => el.title === titleList1[pickRandom1]
          );
          let filterImage2 = StomaManageText.filter(
            (el) => el.title === titleList1[pickRandom1_2]
          );
          setRecoIMG1(filterImage1);
          setRecoIMG2(filterImage2);
          setIMG1(filterImage1[0].checkurl);
          setIMG2(filterImage2[0].checkurl);
        }
        if (filterSymptom2.length === 1) {
          //일상생활.사회활동 어려움
          setRecommend(titleList2);
          let filterImage1 = StomaManageText.filter(
            (el) => el.title === titleList2[0]
          );
          let filterImage2 = StomaManageText.filter(
            (el) => el.title === titleList2[1]
          );
          setRecoIMG1(filterImage1);
          setRecoIMG2(filterImage2);
          setIMG1(filterImage1[0].checkurl);
          setIMG2(filterImage2[0].checkurl);
        }
        if (filterSymptom3.length === 1) {
          //장루교체가 능숙하지 않음
          setRecommend(titleList3);
          let filterImage1 = StomaManageText.filter(
            (el) => el.title === titleList3[0]
          );
          let filterImage2 = StomaManageText.filter(
            (el) => el.title === titleList3[1]
          );
          setRecoIMG1(filterImage1);
          setRecoIMG2(filterImage2);
          setIMG1(filterImage1[0].checkurl);
          setIMG2(filterImage2[0].checkurl);
        }
        if (filterSymptom4.length === 1) {
          //식생활.영양관리 어려움
          setRecommend(titleList4);
          let filterImage1 = StomaManageText.filter(
            (el) => el.title === titleList4[0]
          );
          let filterImage2 = StomaManageText.filter(
            (el) => el.title === titleList4[1]
          );
          setRecoIMG1(filterImage1);
          setRecoIMG2(filterImage2);
          setIMG1(filterImage1[0].checkurl);
          setIMG2(filterImage2[0].checkurl);
        }
        if (filterSymptom5.length === 1) {
          //대변 누출이 잦음
          let pickRandom5 = Math.floor(Math.random() * titleList5.length);
          let pickRandom5_2 = 0;
          if (pickRandom5 >= 1) {
            pickRandom5_2 = pickRandom5 - 1;
          } else {
            pickRandom5_2 = pickRandom5 + 1;
          }
          setRecommend([titleList5[pickRandom5], titleList5[pickRandom5_2]]);
          let filterImage1 = StomaManageText.filter(
            (el) => el.title === titleList1[pickRandom5]
          );
          let filterImage2 = StomaManageText.filter(
            (el) => el.title === titleList1[pickRandom5_2]
          );
          setRecoIMG1(filterImage1);
          setRecoIMG2(filterImage2);
          setIMG1(filterImage1[0].checkurl);
          setIMG2(filterImage2[0].checkurl);
        }
        if (filterSymptom6.length === 1) {
          //심리적 어려움
          setRecommend(titleList6);
          let filterImage1 = StomaManageText.filter(
            (el) => el.title === titleList6[0]
          );
          let filterImage2 = StomaManageText.filter(
            (el) => el.title === titleList6[1]
          );
          setRecoIMG1(filterImage1);
          setRecoIMG2(filterImage2);
          setIMG1(filterImage1[0].checkurl);
          setIMG2(filterImage2[0].checkurl);
        }
      } else {
        //2.중복선택
        for (let i = 0; checkInfo.symptom.length > i; i++) {
          //다중선택에서 문제없음이 들어가면 문제없음뺄것
          // if (checkInfo.symptom[i] === 1) {
          //   titleList7.push(...titleList1);
          // }
          if (checkInfo.symptom[i] === 2) {
            titleList7.push(...titleList2);
          }
          if (checkInfo.symptom[i] === 3) {
            titleList7.push(...titleList3);
          }
          if (checkInfo.symptom[i] === 4) {
            titleList7.push(...titleList4);
          }
          if (checkInfo.symptom[i] === 5) {
            titleList7.push(...titleList5);
          }
          if (checkInfo.symptom[i] === 6) {
            titleList7.push(...titleList6);
          }
        }

        let pickRandom7 = Math.floor(Math.random() * titleList7.length);
        let pickRandom7_2 = 0;
        if (pickRandom7 >= 1) {
          pickRandom7_2 = pickRandom7 - 1;
        } else {
          pickRandom7_2 = pickRandom7 + 1;
        }

        setRecommend([titleList7[pickRandom7], titleList7[pickRandom7_2]]);
        let filterImage1 = StomaManageText.filter(
          (el) => el.title === titleList7[pickRandom7]
        );
        let filterImage2 = StomaManageText.filter(
          (el) => el.title === titleList7[pickRandom7_2]
        );

        setRecoIMG1(filterImage1);
        setRecoIMG2(filterImage2);
        setIMG1(filterImage1[0]?.checkurl);
        setIMG2(filterImage2[0]?.checkurl);
      }
    }
    return () => {
      pageOpen = true;
    };
  }, [checkInfo.symptom, checkInfo.symptomNote]);

  console.log(recommend, recoIMG1, recoIMG2);
  return (
    <div className="pt-[56px]">
      <div className=" p-[24px] flex flex-col items-center">
        <img alt="jangruboy" src={jangruboy} className="pt-[5px] pb-[20px]" />
        <span className="block pb-6">
          장루 점검을 위한 <strong>설문</strong>이 완료되었습니다👏
        </span>
        <span className="block">작성하신 내용을 토대로</span>
        <strong className="block">​ 장루 간호사 선생님께서 확인 후</strong>
        <span className="block">답변해 드릴 예정입니다.</span>
        <span className="block pb-6">
          답변 제공 시 <strong>푸시 알림</strong>이 발송됩니다.
        </span>

        <span className="block">답변이 제공되기 전까지</span>
        <span className="block">추천 콘텐츠를 확인해 주세요☺️</span>
      </div>

      <div className="h-[16px] bg-[#F1F2F3]"></div>
      <div className="w-full p-[24px]">
        <span className="subtitle_2">👍 추천 콘텐츠</span>
        <div className="w-full pt-[16px]">
          <div className="flex gap-x-[12px]">
            <div
              className="border w-1/2 p-[8px] cursor-pointer"
              onClick={() => {
                navigate("/more", {
                  state: {
                    title: recoIMG1[0].title,
                    title2: recoIMG1[0].title2,
                    btn1: recoIMG1[0].btn1,
                    btn2: recoIMG1[0].btn2,
                    btn3: recoIMG1[0].btn3,
                    btn4: recoIMG1[0].btn4,
                    btn5: recoIMG1[0].btn5,
                    btn6: recoIMG1[0].btn6,
                    svgurl: recoIMG1[0].svgurl,
                    bigTitle: recoIMG1[0].bigTitle,
                    writeDate: recoIMG1[0].writeDate,
                  },
                  replace: true,
                });
              }}
            >
              <img src={IMG1} alt="checkurl1" className="w-full" />
              <span className="subtitle_2 text-center w-full inline-block pt-[16px]">
                {recommend[0]}
              </span>
            </div>
            <div
              className="border w-1/2 p-[8px] cursor-pointer"
              onClick={() => {
                navigate("/more", {
                  state: {
                    title: recoIMG2[0].title,
                    title2: recoIMG2[0].title2,
                    btn1: recoIMG2[0].btn1,
                    btn2: recoIMG2[0].btn2,
                    btn3: recoIMG2[0].btn3,
                    btn4: recoIMG2[0].btn4,
                    btn5: recoIMG2[0].btn5,
                    btn6: recoIMG2[0].btn6,
                    svgurl: recoIMG2[0].svgurl,
                    bigTitle: recoIMG2[0].bigTitle,
                    writeDate: recoIMG2[0].writeDate,
                    jangrucontents: "gomain",
                  },
                  replace: true,
                });
              }}
            >
              <img src={IMG2} alt="checkurl" className="w-full" />
              <span className="subtitle_2 text-center w-full inline-block pt-[16px]">
                {recommend[1]}
              </span>
            </div>
          </div>
        </div>
        <div className="h-[120px]"></div>
      </div>
      <div className="px-[24px] fixed bottom-0 left-0 w-full bg-[#FAFCFF]">
        <span
          className="inline-block w-full button_700 flex justify-center items-center inline-block w-full py-[20px] rounded-2xl bg-[#FAFCFF] border border-[#1B5EDC] text-[#1B5EDC] active:text-[#18428E] active:bg-[#F2F6FC] ease-in-out duration-300 button_700 cursor-pointer"
          onClick={() => {
            navigate("/main");
            // window.location.reload();
            // window.location.replace("/");
          }}
        >
          홈으로
        </span>
        <div className="h-[16px]"></div>
      </div>
    </div>
  );
};

export default Check11;
