import React from "react";

const FeelTired = ({ bigTitle, btn2 }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400 wordbreak-keepall">
      <div className="pt-[72px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
        <h1 className="h2_800 pt-[4px] pb-[24px]">{btn2}</h1>
      </div>
      <div className="w-full pt-[24px] body_1_400_jangrucont">
        <div className="flex ">
          <span className="inline-block">•</span>
          <div className="pl-[4px]">
            <span className="inline-block">
              피로를 줄이기 위해 일상생활을 조정하여 에너지를 아끼고 보존하는
              방법을 사용합니다. 특히 암 관련 피로는 오후 시간에 더 심해지는
              경향이 있으므로 많은 에너지가 소모되는 일들은 오전에 계획하여
              진행하면 좋습니다.
            </span>
          </div>
        </div>

        <span className="inline-block ">
          • 적당한 휴식과 함께 규칙적인 신체활동을 합니다.
        </span>
        <div className="flex">
          <span className="inline-block ">-</span>
          <div className="pl-[4px]">
            <span className="inline-block ">
              적절한 수준의 신체 활동(요가,걷기,산책 등) 낮고 중간 수준의 신체
              활동을 하도록 적극 권장합니다
            </span>
          </div>
        </div>

        <span className="inline-block ">
          • 매주 일정한 시간에 운동을 하도록 합니다.
        </span>
        <div className="flex">
          <span className="inline-block ">-</span>
          <div className="pl-[4px]">
            운동의 강도 및 일의 양을 적절하게 정합니다. (예 : 150 분 중등도2 ~ 3
            회의 추가 근력 운동(아령 운동)과 함께 주당 유산소 운동
          </div>
        </div>
        <span className="inline-block ">
          • [빠른 걷기, 사이클링 또는 수영]금기 사항이 아닌 한 주당 세션
        </span>

        <span className="inline-block ">
          • 건강식단을 구성하여 균형 잡힌 식사를 합니다.
        </span>

        <div className="flex">
          <span className="inline-block ">• 수면의 질을 개선합니다.</span>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            음악을 듣고, 읽고, 명상하고, 안내 이미지를 연습하거나, 좋아하는
            사람들과 시간을 보내세요. 에너지를 절약하고 스트레스를 줄이는 데
            도움이 될 수 있습니다.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            피곤하다면 하루에 1 시간 미만의 짧은 낮잠을 취하십시오. 그러나 낮에
            너무 많은 수면을 취하면 밤에 잠을 잘 수 없습니다. 식사나 운전과 같은
            중요한 작업에 대한 도움을 요청하세요.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            식사를 잘 챙겨 드세요. 단백질과 칼로리가 높은 음식은 체력을 유지하는
            데 도움이 됩니다. 하루에 세 번의 큰 식사 대신 작은 식사를 많이 하는
            것이 더 오히려 유익할 수 있습니다.
          </div>
        </div>

        <span className="inline-block ">
          • 수분 유지하기. 카페인과 알코올 섭취를 제한하세요.
        </span>
      </div>
      <div className="h-[80px]"></div>
    </div>
  );
};

export default FeelTired;
