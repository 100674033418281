import { useTable, useSortBy, usePagination } from "react-table";
import { useMainContext } from "../../../../../contexts/Provider";

export default function DetailPageMemoTable({
  columns,
  data,
  setMemoCancelPopup,
}) {
  const memo_cancel = "/images/admin/memo_cancel.svg";
  const { adminLogin } = useMainContext();
  const {
    getTableBodyProps,
    page,

    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 2 } },
    useSortBy,
    usePagination
  );
  const paginationBtns = new Array(pageCount).fill(0).map((el, idx) => idx + 1);

  // pagination UI
  const paginationUI = () => {
    const arrow_left = "/images/admin/carousel_left.svg";
    const arrow_right = "/images/admin/carousel_right.svg";

    return (
      <div className="flex justify-center items-center mt-[18px]">
        <button
          className="px-[17.6px]"
          onClick={() => {
            previousPage();
          }}
          disabled={!canPreviousPage}
        >
          <img
            src={arrow_left}
            alt="arrow_left"
            className="w-[6.4px] h-[11.2px]"
            style={
              !canPreviousPage
                ? {
                    filter:
                      "brightness(0) saturate(100%) invert(48%) sepia(0%) saturate(5417%) hue-rotate(195deg) brightness(134%) contrast(82%)",
                  }
                : {
                    filter:
                      "brightness(0) saturate(100%) invert(3%) sepia(29%) saturate(7499%) hue-rotate(218deg) brightness(99%) contrast(100%)",
                  }
            }
          />
        </button>
        <div className="flex gap-x-1">
          {paginationBtns.map((el, idx) => {
            if (
              idx >= Math.floor(pageIndex / 5) * 5 &&
              idx <= Math.floor(pageIndex / 5) * 5 + 4
            ) {
              return (
                <button
                  key={idx}
                  className="flex justify-center items-center border w-[32px] h-[32px] text-center text-base text-[#020A1B] font-semibold leading-5 rounded-[28px]"
                  onClick={() => {
                    gotoPage(idx);
                  }}
                  style={{
                    borderColor: pageIndex === idx ? "#020A1B" : "transparent",
                  }}
                >
                  {idx + 1}
                </button>
              );
            } else return null;
          })}
        </div>
        <button
          className="px-[17.6px]"
          onClick={() => {
            nextPage();
          }}
          disabled={!canNextPage}
        >
          <img
            src={arrow_right}
            alt="arrow_right"
            className="w-[6.4px] h-[11.2px]"
            style={
              !canNextPage
                ? {
                    filter:
                      "brightness(0) saturate(100%) invert(48%) sepia(0%) saturate(5417%) hue-rotate(195deg) brightness(134%) contrast(82%)",
                  }
                : {
                    filter:
                      "brightness(0) saturate(100%) invert(3%) sepia(29%) saturate(7499%) hue-rotate(218deg) brightness(99%) contrast(100%)",
                  }
            }
          />
        </button>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-col w-full">
        <table
          className="table-fixed border-separate w-full "
          style={{ borderSpacing: "0 8px" }}
        >
          <tbody {...getTableBodyProps()}>
            {page.map((row, rowIdx) => {
              prepareRow(row);
              return (
                <tr
                  key={row.id}
                  {...row.getRowProps()}
                  className="max-h-[122px] overflow-auto"
                >
                  {row.cells.map((cell) => {
                    let el = cell.row.original;
                    let newRole = "";
                    if (el.admin.role === 0) newRole = " 관리자";
                    else if (el.admin.role === 1) newRole = "의사";
                    else if (el.admin.role === 2) newRole = "간호사";
                    return (
                      <td
                        key={cell.row.id}
                        className="h-[148px] min-h-[148px] border border-[#CCCDD1] rounded-[6px] px-4 pb-2 pt-0"
                      >
                        <div className="flex flex-col justify-start">
                          {/* 삭제버튼 */}
                          {el.admin.id !== adminLogin.id ? (
                            <div className="flex justify-end items-center min-h-[36px]"></div>
                          ) : (
                            <div className="flex justify-end items-center min-h-[36px]">
                              <img
                                src={memo_cancel}
                                alt="memo_cancel"
                                className="w-[20px] h-[20px] cursor-pointer"
                                onClick={() => {
                                  // console.log("삭제");
                                  setMemoCancelPopup({
                                    memoCancelPopup: true,
                                    id: el.id,
                                  });
                                }}
                              />
                            </div>
                          )}

                          {/* 메모 내용 */}
                          <label className="flex items-start h-[74px] min-h-[74px] w-full text-base text-[#020A1B] leading-[22px] font-normal mb-4 break-words overflow-auto">
                            {el.content}
                          </label>
                          {/* 작성자 정보*/}
                          <div className="flex justify-end text-sm text-[#676C76] leading-[18px]">
                            <p className="font-semibold ">
                              {`${el.admin.name} ${newRole} `}
                              <span className="font-normal">{`| ${el.createdAt
                                .substring(0, 10)
                                .replace(/-/gi, ".")}`}</span>
                            </p>
                          </div>
                        </div>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* pagenation */}
        {paginationUI()}
      </div>
    </>
  );
}
