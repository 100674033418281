import { useNavigate } from "react-router-dom";

const HeaderTitle = ({ title }) => {
  const arrow_right = "/images/admin/arrow_right.svg";
  const navigate = useNavigate();

  return (
    <>
      {title.map((el, idx) => {
        if (idx !== title.length - 1) {
          return (
            <div className="flex items-center" key={idx}>
              <span
                className="truncate cursor-pointer "
                onClick={() => {
                  if (el.state)
                    navigate(
                      el.url,
                      { state: el.state },
                      { replace: el.replace ? el.replace : false }
                    );
                  else navigate(el.url);
                }}
              >
                {el.title}
              </span>
              <img
                key={idx}
                src={arrow_right}
                alt="arrow_right"
                className="w-[7.7px] h-[13.4px] mx-3 "
              />
            </div>
          );
        } else {
          return (
            <span
              key={idx}
              className="text-lg leading-[22px] font-semibold text-[#343B48] truncate"
            >
              {el.title}
            </span>
          );
        }
      })}
    </>
  );
};

export default HeaderTitle;
