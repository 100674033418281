import React, { useState, useEffect } from "react";
import TextEditorToolbar, {
  modules,
  formats,
} from "../../../admin_components/TextEditorToolbar";
import ReactQuill from "react-quill";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";

import common, { api } from "../../../../../data/common";
import { Flip, toast, ToastContainer } from "react-toastify";
import { BtnBlack } from "../../../admin_components/Buttons";
import { toastCommonProps } from "../../../admin_components/Toast";
import DatePicker from "react-datepicker";
import { useMainContext } from "../../../../../contexts/Provider";
import Popup from "reactjs-popup";

let disable = [];

const NewsEditComponent = () => {
  const PopUp = ({ open, onClose }) => {
    const number_id = [1, 2, 3, 4, 5];
    return (
      <>
        <style jsx="true">{`
          .popup-content.jangrucheck__popup-content {
            box-sizing: border-box;
            border-radius: 8px;
            width: 303px;
            height: 170px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0px;
            position: relative;
            background: #fafbfc;
            border: 1px solid #1b5edc;
          }
        `}</style>
        <ToastContainer className="admin" />
        <Popup
          key={number_id.toString()}
          open={open}
          closeOnDocumentClick
          onClose={onClose}
          className="jangrucheck__popup"
        >
          <div className="flex flex-row items-start px-[32px] pt-[32px] pb-[16px] static w-[286px] h-[92px] left-[8.5px] top-0 flex-none order-none grow-0 ">
            <p className="static w-[222px] h-[44px] left-8 top-8 not-italic	font-normal	text-base	text-center	text-[#020A1B] flex-none order-none grow-0 mt-[10px] ">
              정말 삭제하시겠습니까?
            </p>
          </div>

          <div className="flex flex-row items-start	p-[16px] static w-[303px] h-[78px] left-0 top-[92px] flex-none order-1 self-stretch	grow-0	">
            <button
              onClick={() => {
                api
                  .post(common.baseURL + "news/delete/" + editNewsInput.id)
                  .then((res) => {
                    if (res.data.ok === true) {
                      navigate("/admin/main/news");
                    } else {
                      toast(
                        <p>{res.data.error}</p>,
                        toastCommonProps(
                          3000,
                          "top-left",
                          "toast_common toast__normal",
                          Flip
                        )
                      );
                    }
                  });
              }}
              className="hover:bg-blue-300 flex flex-row justify-center items-center p-[12px] static w-[131.5px] h-[46px] 
          left-[16px] top-[16px] bg-[#FAFCFF] box-border rounded-[4px] border border-[#1B5EDC] flex-none order-none grow mr-[8px]"
            >
              <p className="static w-[28px] h-[22px] left-[51.75px] top-[12px] not-italic	font-bold	text-base	flex items-center text-center text-[#1B5EDC] flex-none order-none grow-0">
                삭제
              </p>
            </button>
            <button
              onClick={onClose}
              className="hover:bg-blue-300 flex flex-row justify-center items-center p-[12px] static w-[131.5px] h-[46px] left-[155.5px] 
          top-[16px] bg-[#FAFCFF] box-border rounded-[4px] border border-[#1B5EDC] flex-none order-1 grow"
            >
              <p className="static w-[28px] h-[22px] left-[51.75px] top-[12px] not-italic	font-bold	text-base	flex items-center text-center text-[#1B5EDC] flex-none order-none grow-0">
                취소
              </p>
            </button>
          </div>
        </Popup>
      </>
    );
  };
  const [toastElPosition, setToastElPosition] = useState({ top: 0, left: 0 }); // toast창 위치 값
  const [flag, setFlag] = useState(false);
  const [fileon, setFileon] = useState(false);
  const [setfile, setSetfile] = useState([]);
  const [loading] = useState(false);
  const [files, setFiles] = useState([]);
  const regex = /\s/gi;
  const location = useLocation();
  const newsdata = location.state?.data || location.state;
  const [editurl, setEditUrl] = useState([]);
  const [newsidData, setNewsIdData] = useState([]);
  const [startDate, setStartDate] = useState(new Date(newsdata.startDate));
  const [endDate, setEndDate] = useState(new Date(newsdata.endDate));
  const [dateclk, setDateclk] = useState(false);
  const [pageflag, setPageflag] = useState(false); // 새소식 확인, 수정 구분 flag
  const { adminLogin } = useMainContext();
  const temp_category = ["장루소식", "앱소식", "커뮤니티"];
  const toastEl = document.querySelector(".toast_standard");
  const { state } = useLocation(); // 이전 페이지에서 넘겨준 띄울 사용자 정보 id값
  console.log(state);
  const toast_func = (text) => {
    console.log(text);
    toast(
      <p>{text}</p>,
      toastCommonProps(3000, "top-left", "toast_common toast__normal", Flip)
    );
  };

  function getDatesStartToLast(startDate, lastDate) {
    var regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
    if (!(regex.test(startDate) && regex.test(lastDate)))
      return "Not Date Format";
    var result = [];
    var curDate = new Date(startDate);
    while (curDate <= new Date(lastDate)) {
      result.push(curDate.toISOString().split("T")[0]);
      curDate.setDate(curDate.getDate() + 1);
    }
    return result;
  }
  useEffect(() => {
    let pageOpen = false;
    disable = [];

    const getNews = () => {
      api.get(common.baseURL + "news").then((res) => {
        //모든 데이터를 불러와서 이미 공지된 날짜 disable
        if (res.data.ok === true) {
          const get_data = res.data.newsList;
          for (let i = 0; i < get_data.length; i++) {
            if (get_data[i].id !== newsdata.id) {
              disable[i] = getDatesStartToLast(
                get_data[i].startDate,
                get_data[i].endDate
              );
            } else {
              setEditUrl(get_data[i].attachedFileURL);
              setNewsIdData(get_data[i]);
            }
          }
          disable = disable.flat();
          for (let i = 0; i < disable.length; i++) {
            disable[i] = new Date(disable[i]);
          }
        }
      });
    };
    if (!pageOpen) getNews();
    return () => {
      pageOpen = true;
    };
  }, [newsdata]);

  const [editNewsInput, setEditNewsInput] = useState({
    id: newsdata.id,
    admin: newsdata.admin.id,
    title: newsdata.title,
    category: (temp_category.indexOf(newsdata.category) + 1).toString(),
    startDate: newsdata.startDate,
    endDate: newsdata.endDate,
    content: newsdata.content,
    attachedFileURL: [],
  });

  const newsFileArr = [];
  const navigate = useNavigate();
  const bowl = [];

  const onChangeFile = (e) => {
    setToastElPosition({
      top: toastEl.getBoundingClientRect().top,
      left: toastEl.getBoundingClientRect().left,
    });
    const file = e.target.files;
    let cnt = 0;
    for (let i = 0; i < file.length; i++) {
      if (
        editurl?.includes(
          file[i].lastModified + file[i].name.replaceAll(" ", "")
        )
      ) {
        toast_func("동일한 파일이 존재합니다.");
        continue;
      }
      newsFileArr.push(file[i].lastModified + file[i].name.replaceAll(" ", ""));
      bowl.push(file[i].name);
    }
    const new_file = editurl?.concat(newsFileArr);
    for (let i = 0; i < new_file?.length; i++) {
      const file_form = new_file[i].slice(
        new_file[i].indexOf("."),
        new_file[i].length
      );
      if (file_form === ".jpg" || file_form === ".jpeg" || file_form === ".png")
        cnt++;
    }

    if (cnt < 6) {
      setFiles(file);
      setEditNewsInput({ ...editNewsInput, attachedFileURL: newsFileArr });
      setFileon(true);
      setSetfile(bowl);
      if (editurl?.length > 0) setEditUrl(editurl.concat(newsFileArr));
    } else toast_func("이미지가 5개를 초과하였습니다.");
  };

  const onContent = (value) => {
    setEditNewsInput({ ...editNewsInput, content: value });
  };
  const onChangeInfo = (e) => {
    const { name, value } = e.target;
    setEditNewsInput({ ...editNewsInput, [name]: value });
  };

  const submitData = () => {
    const toastEl = document.querySelector(".toast_standard");

    setToastElPosition({
      top: toastEl.getBoundingClientRect().top,
      left: toastEl.getBoundingClientRect().left,
    });

    let body = {
      id: editNewsInput.id,
      admin: adminLogin.id,
      title: editNewsInput.title,
      category: editNewsInput.category,
      startDate: dateclk
        ? dayjs(startDate).format("YYYY-MM-DD")
        : editNewsInput.startDate,
      endDate: dateclk
        ? dayjs(endDate).format("YYYY-MM-DD")
        : editNewsInput.endDate,
      content: editNewsInput.content,
      attachedFileURL:
        editurl?.length > 0 ? editurl : editNewsInput.attachedFileURL,
    };
    let axiosnum = 0;
    let include = false;
    for (let i = 0; i < disable.length; i++) {
      if (startDate < disable[i] && endDate > disable[i]) include = true;
    }

    if (
      body.startDate === "" ||
      body.startDate === "Invalid Date" ||
      body.endDate === "" ||
      body.endDate === "Invalid Date"
    ) {
      toast_func("게시기간을 등록해주세요.");
    } else if (
      body.title.replace(/(\s*)/g, "") === "" ||
      body.content === "" ||
      body.content === "<p><br></p>" ||
      body.content.replace(/(\s*)/g, "") === "<p></p>"
    ) {
      toast_func("누락된 항목이 있습니다.");
    } else if (include) {
      alert(
        "게시 기간이 중복된 새소식이 있습니다.\n이전 글의 게시 기간 또는 현재 글의 게시 기간을 조정하여 등록해 주세요."
      );
    }
    //각 항목에 대한 체크 후, 첨부파일 유무에 따른 저장
    else {
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          let formData = new FormData();
          const filename = files[i].lastModified + files[i].name;
          formData.append("file", files[i]);
          formData.append("name", filename.replace(regex, ""));
          // eslint-disable-next-line no-loop-func
          api
            .post(common.baseURL + "upload/oneNew", formData)
            .then((awsres) => {
              if (awsres.data.ok === true) {
                axiosnum = axiosnum + 1;
              } else {
                toast_func("사진 등록이 실패하였습니다.");
              }
              if (axiosnum === files.length) {
                api
                  .post(common.baseURL + "news", body)
                  .then((postRes) => {
                    const p_text = postRes.data.ok
                      ? "저장되었습니다."
                      : "누락된 항목이 있습니다.";
                    toast_func(p_text);

                    if (postRes.data.ok)
                      setTimeout(() => navigate("/admin/main/news"), 3000);
                  })
                  .catch((error) => console.log(error));
              }
            });
        }
      } else {
        api.post(common.baseURL + "news", body).then((res) => {
          const p_text = res.data.ok
            ? "저장되었습니다."
            : "새소식 등록에 실패하였습니다.";
          toast_func(p_text);

          if (res.data.ok) setTimeout(() => navigate("/admin/main/news"), 3000);
        });
      }
    }
  };

  const deleteData = () => {
    setOpen(true);
  };

  let file_clk = false;
  let back_clk = false;
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  return (
    <>
      <style jsx="true">{`
        .Toastify__toast {
          top: ${toastElPosition.top - 20}px;
          left: ${toastElPosition.left - 285}px;
        }
        .ql-toolbar.ql-snow {
          border-top-width: 0 !important;
          border-left-width: 0 !important;
          border-right-width: 0 !important;
          border-bottom-width: 2px !important;
          border-color: #e5e6e7 !important;
        }
        .ql-container.ql-snow {
          border: none !important;
        }
        .ql-container.ql-snow .ql-editor {
          padding: 0px !important;
        }
      `}</style>
      <PopUp open={open} onClose={closeModal} />
      <ToastContainer />

      <div className="admin flex flex-col grow py-[42px] px-[12px]">
        <label className="text-2xl font-extrabold leading-8 text-[#020A1B]">
          {pageflag ? "새소식 수정" : "새소식 확인"}
        </label>
        <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-3 min-w-[975px]">
          <div className="grid grid-cols-5 min-w-[915px] text-center border border-[#E5E6E7] bg-[#fafbfc] ">
            <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border">
              작성자
            </label>
            <label className="flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7] bg-[#F1F2F3] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-4">
              {/* {newsdata.admin.role === 0 ? "헤링스 관리자" : "서울대 운영자"} -->작성자 기준 표기 */}
              {/* 수정자 기준 표기 */}
              {adminLogin.role === 0
                ? adminLogin.belongName + " 관리자"
                : adminLogin.role === 1
                ? adminLogin.belongName + " 의사"
                : adminLogin.belongName + " 간호사"}
            </label>

            <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border">
              제목
            </label>
            {pageflag ? (
              <input
                className="w-full flex justify-start items-center text-left px-6 py-[7px] border border-[#E5E6E7] leading-[22px] box-border col-span-3"
                value={editNewsInput.title}
                name="title"
                onChange={onChangeInfo}
              />
            ) : (
              <label
                className="w-full flex justify-start items-center text-left px-6 py-[7px] font-semibold text-base border border-[#E5E6E7] leading-[22px] box-border col-span-3 bg-white"
                name="title"
              >
                {editNewsInput.title}
              </label>
            )}
            {pageflag ? (
              <select
                className="inline-block border-l pl-4 bg-[#F2F6FC] col-span-1 border border-[#E5E6E7]"
                onChange={onChangeInfo}
                name="category"
                value={editNewsInput.category}
              >
                <option value="">선택해주세요</option>
                <option value="1">장루소식</option>
                <option value="2">앱소식</option>
                <option value="3">커뮤니티</option>
              </select>
            ) : (
              <label className="w-full flex justify-start items-center text-left px-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] box-border col-span-1 bg-[#F2F6FC]">
                {temp_category[Number(editNewsInput.category) - 1]}
              </label>
            )}

            <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border">
              게시 기간
            </label>
            <div className="w-full flex text-left px-6 py-[7px] border border-[#E5E6E7] leading-[22px] box-border col-span-4 bg-white">
              <div className="flex w-5/6 bg-white">
                {pageflag ? (
                  <div className="inline-block w-4/6 px-2">
                    <DatePicker
                      className="w-full my-1 font-bold text-center border rounded-full "
                      selectsRange={true}
                      dateFormat="yyyy년 MM월 dd일"
                      selected=""
                      startDate={startDate}
                      endDate={endDate}
                      minDate={new Date()}
                      excludeDates={disable}
                      monthsShown={2}
                      onChange={(update) => {
                        setStartDate(update[0]);
                        setEndDate(update[1]);
                        setDateclk(true);
                      }}
                      isClearable={false}
                      selectsDisabledDaysInRange
                      withPortal
                    />
                  </div>
                ) : (
                  <label className="text-base font-semibold ">
                    {dayjs(startDate).format("YYYY년 MM월 DD일") +
                      "~" +
                      dayjs(endDate).format("YYYY년 MM월 DD일")}
                  </label>
                )}
              </div>
            </div>
            <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border">
              내용
            </label>
            <div className="w-full bg-white vertical-middle border border-[#E5E6E7] col-span-4 overflow-auto">
              {pageflag ? (
                <>
                  <TextEditorToolbar />
                  <ReactQuill
                    className="w-full h-40 p-6"
                    theme="snow"
                    placeholder={"내용을 적어주세요"}
                    modules={modules}
                    formats={formats}
                    value={editNewsInput.content}
                    onChange={onContent}
                  />
                </>
              ) : (
                <div
                  className="p-4 px-6 text-left "
                  dangerouslySetInnerHTML={{ __html: editNewsInput.content }}
                ></div>
              )}
            </div>

            {pageflag || editurl?.length > 0 ? ( // 확인/수정 페이지 구분, 첨부파일 유무에 따른 표출 유무
              <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border">
                첨부파일
              </label>
            ) : (
              <></>
            )}
            {/* 배경 시작 */}
            {pageflag ? (
              <div className="flex flex-col flex-wrap col-span-4 font-semibold break-all">
                <label
                  className={
                    editurl?.length > 0
                      ? "bg-white border border-[#E5E6E7] p-6 cursor-grab"
                      : "bg-white border border-[#E5E6E7] p-6 "
                  }
                  htmlFor={editurl?.length > 0 ? "input_file" : ""}
                  onClick={() => {
                    back_clk = true;
                    if (!file_clk && back_clk) {
                      setFlag(true);
                    }
                    back_clk = false;
                    file_clk = false;
                  }}
                >
                  {editurl?.length > 0 ? (
                    /* element 시작 */
                    editurl.map((el, i) => (
                      <div className=" w-fit" key={i}>
                        <label className="text-black">
                          <span
                            className="flex text-left break-all w-fit "
                            onClick={() => {
                              file_clk = true;
                              if (file_clk && !back_clk) {
                                setFlag(false);
                              }
                            }}
                          >
                            <label
                              className="underline cursor-pointer font-semibold text-base leading-[22px] text-[#020A1B] pb-1 hover:text-blue-500"
                              onClick={(e) => {
                                window.open(
                                  `${common.networkImg}${el.replace(
                                    /(\s*)/g,
                                    ""
                                  )}`
                                );
                              }}
                            >
                              {el.slice(13, el.length)}
                            </label>
                            <span
                              className="material-icons text-[15px] cursor-pointer px-1 pt-1 rounded-full"
                              onClick={(e) => {
                                file_clk = true;
                                setFlag(false);

                                let filterdata = editurl.filter(
                                  (el, index) => index !== i
                                );
                                setEditUrl(filterdata);
                              }}
                            >
                              cancel
                            </span>
                          </span>
                        </label>
                      </div>
                    ))
                  ) : (
                    <div className="flex flex-col flex-wrap col-span-4 p-2 font-semibold ">
                      <label
                        htmlFor="input_file"
                        className="w-[88px] h-[38px] px-4 py-2 bg-[#4E535F] hover:bg-gray-700 cursor-pointer text-[#FFFFFF] font-bold text-base leading-[22px] rounded-[6px] mb-2"
                      >
                        파일첨부
                      </label>

                      <input
                        id="input_file"
                        className="hidden w-full"
                        type="file"
                        label="Upload"
                        accept=".jpeg, .jpg, .png, .pdf"
                        multiple
                        placeholder="첨부할 파일이 있는 경우 버튼을 클릭하여 등록하세요. (이미지는 최대 5개)"
                        onChange={onChangeFile}
                      />
                      {fileon ? (
                        <p className="w-auto mt-2 text-sm text-left text-gray-400">
                          {setfile.join(", ")}
                        </p>
                      ) : (
                        <p className="mt-2 text-sm text-left text-gray-400">
                          *첨부 파일이 있는 경우 이곳을 클릭하여 등록
                          하세요.(이미지는 최대 5개)
                        </p>
                      )}
                    </div>
                  )}
                </label>
              </div>
            ) : editurl?.length > 0 ? (
              <div className="flex flex-col flex-wrap col-span-4 font-semibold break-all bg-white">
                <div className="flex flex-col items-start gap-y-2 px-6 py-3 border border-[#E5E6E7] col-span-4 bg-white">
                  {editurl.map((el) => (
                    <span key={el} className="flex inline-block mr-2 text-left">
                      <p
                        className="underline cursor-pointer font-semibold text-base leading-[22px] text-[#020A1B]"
                        onClick={() => {
                          window.open(
                            `${common.networkImg}${el.replace(/(\s*)/g, "")}`
                          );
                        }}
                      >
                        {el.slice(13, el.length)}
                      </p>
                    </span>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}

            {flag ? (
              <input
                id="input_file"
                className="hidden w-full"
                type="file"
                label="Upload"
                accept=".jpeg, .jpg, .png, .pdf"
                multiple
                placeholder="첨부할 파일이 있는 경우 버튼을 클릭하여 등록하세요. (이미지는 최대 5개)"
                onChange={onChangeFile}
              />
            ) : (
              ""
            )}

            <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border">
              작성일
            </label>
            <label className="flex bg-white justify-start items-center pl-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-4 ">
              {dayjs(new Date(newsidData.updatedAt)).format("YYYY.MM.DD")}
            </label>
          </div>
          {adminLogin.belong === 1 ? (
            <div className="flex w-full justify-end items-center mt-[18px]">
              {pageflag ? (
                <div className="flex gap-x-2">
                  <BtnBlack
                    context="삭제하기"
                    onClick={deleteData}
                    loading={loading}
                    width="88px"
                    standard="toast_standard"
                  />
                  <BtnBlack
                    context="저장하기"
                    onClick={submitData}
                    loading={loading}
                    width="88px"
                  />
                </div>
              ) : (
                <div className="flex gap-x-2">
                  <BtnBlack
                    context="수정하기"
                    onClick={() => setPageflag(true)}
                    loading={loading}
                    width="88px"
                  />
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default NewsEditComponent;
