import React from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import Tutorial1 from "./components/Tutorial1";
import Tutorial2 from "./components/Tutorial2";
import Tutorial3 from "./components/Tutorial3";
import Tutorial4 from "./components/Tutorial4";
import Tutorial5 from "./components/Tutorial5";
import Tutorial6 from "./components/Tutorial6";
import Tutorial7 from "./components/Tutorial7";
import Tutorial8 from "./components/Tutorial8";
SwiperCore.use([Pagination]);
const Tutorial = () => {
  const closeicon = "/images/mo/closebasic.svg";
  const navigate = useNavigate();
  const gomain = () => {
    navigate("/main");
  };
  return (
    <div className="fixed top-0 left-0 bg-[#FAFCFF] w-full h-screen z-10 cursor-pointer">
      <img
        src={closeicon}
        alt="closeicon"
        className="fixed top-0 right-0 z-20 cursor-pointer"
        onClick={gomain}
      />
      <div className="tutorial bg-[#FAFCFF] h-screen flex items-center">
        <Swiper
          spaceBetween={8}
          pagination={true}
          loop={true}
          slidesPerView={1}
          onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            <div className={`  subtitle_2   cursor-pointer`}>
              <Tutorial1 />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`  subtitle_2   cursor-pointer`}>
              <Tutorial2 />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`  subtitle_2   cursor-pointer`}>
              <Tutorial3 />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`  subtitle_2   cursor-pointer`}>
              <Tutorial4 />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`  subtitle_2   cursor-pointer`}>
              <Tutorial5 />
            </div>
          </SwiperSlide>
          {/* <SwiperSlide>
            <div className={`  subtitle_2   cursor-pointer`}>
              <Tutorial6 />
            </div>
          </SwiperSlide> */}
          <SwiperSlide>
            <div className={`  subtitle_2   cursor-pointer`}>
              <Tutorial7 />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`  subtitle_2   cursor-pointer`}>
              <Tutorial8 />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Tutorial;
