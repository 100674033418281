import React from "react";

const StomDepression = ({ bigTitle, btn5 }) => {
  const image14 = "/images/mo/jangrucont/장루합병증14.jpg";
  const image16 = "/images/mo/jangrucont/장루합병증16.jpg";
  return (
    <div className="w-full flex flex-col  px-[16px] wordbreak-keepall">
      <div className="pt-[72px] ">
        <div className="flex justify-center flex-col items-center w-full border-b">
          <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
          <h1 className="h2_800 pt-[4px] pb-[24px]">{btn5}</h1>
        </div>
        <div className="w-full pt-[24px] body_1_400">
          <div>
            <div className="subtitle_1_700 pb-[8px]">1) 정의</div>
            <div className="w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
              <img src={image14} alt="image14" className="h-full" />
            </div>
            <div className="pt-[8px]">
              장루 주위의 피부와 장점막이 분리되는 것을 말합니다.
            </div>
          </div>
          <div className="pt-[32px]">
            <div className="subtitle_1_700 pb-[8px]">2) 관리방법</div>

            <div className="mt-[8px] relative w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
              <img src={image16} alt="image14" className="h-full" />
            </div>

            <div className="pt-[8px] body_1_400_jangrucont">
              <div className="flex  ">
                <span className="inline-block">1.&nbsp;</span>
                <div>과도한 체중 증가 또는 감소를 피합니다.</div>
              </div>
              <div className="flex  ">
                <span className="inline-block">2.&nbsp;</span>
                <div>피부 보호판을 함몰형으로 변경합니다.</div>
              </div>

              <div className="flex  ">
                <span className="inline-block">3.&nbsp;</span>
                <div>
                  <span className="inline-block">
                    장루용 벨트를 착용합니다.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StomDepression;
