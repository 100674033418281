import React from "react";
import { useLocation } from "react-router-dom";

const CenterModal = ({
  children,
  showCheckModal,
  showNotFound,
  showNOPeriodModal,
  showNOReplyModal,
  showCantgo,
  showSurveyDateModal,
  setPhotoAlert,
  setWarnBlock,
  setBlocking,
  setWarning,
  showDeleteAppuse,
  showImageBig,
  imagebigKey
}) => {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/" ? (
        <>
          <div
            className="w-full h-screen fixed top-0 left-0 bg-[#020A1B] opacity-[85%] z-10"
            onClick={() => {
              if (showNOPeriodModal) {
                showNOPeriodModal();
              } else {
                showNOReplyModal();
              }
            }}
          ></div>
          <div className="fixed z-20 min-w-[300px] top-1/2 left-1/2 transform-50 p-[32px] bg-[#FAFCFF] rounded-md wordbreak-keepall body_1_400 ">
            {children}
          </div>
        </>
      ) : location.pathname === "/login" ? (
        <>
          <div
            className="w-full h-screen fixed top-0 left-0 bg-[#020A1B] opacity-[85%] z-10"
            onClick={showNotFound}
          ></div>
          <div className="fixed z-20 min-w-[300px] top-1/2 left-1/2 transform-50 p-[32px] bg-[#FAFCFF] rounded-md wordbreak-keepall body_1_400">
            {children}
          </div>
        </>
      ) : location.pathname === "/report/inspection" ? (
        <>
          <div
            className="fixed top-0 left-0 z-10 w-full h-screen bg-black opacity-30"
            onClick={showCantgo}
          ></div>
          <div className="fixed z-20 min-w-[320px] top-1/2 left-1/2 transform-50 p-[16px] bg-[#FAFCFF] rounded-md items-center wordbreak-keepall body_1_400">
            {children}
          </div>
        </>
      ) : location.pathname === "/main/message" ? (
        <>
          <div
            className="fixed top-0 left-0 z-10 w-full h-screen bg-black opacity-30 "
            onClick={showSurveyDateModal}
          ></div>
          <div className="fixed z-20 min-w-[320px] top-1/2 left-1/2 transform-50 p-[16px] bg-[#FAFCFF] rounded-md items-center wordbreak-keepall body_1_400">
            {children}
          </div>
        </>
      ) : location.pathname === "/check" ||
        location.pathname === "/check/1" ||
        location.pathname === "/check/2" ||
        location.pathname === "/check/3" ||
        location.pathname === "/check/4" ||
        location.pathname === "/check/5" ||
        location.pathname === "/check/6" ||
        location.pathname === "/check/7" ||
        location.pathname === "/check/9" ||
        location.pathname === "/check/10" ||
        location.pathname === "/check/11" ? (
        <>
          <div
            className="fixed top-0 left-0 z-10 w-full h-screen bg-black opacity-30"
            onClick={showCheckModal}
          ></div>
          <div className="fixed z-20 min-w-[320px] max-h-[540px] top-1/2 left-1/2 transform-50 p-[16px] bg-[#FAFCFF] rounded-md items-center wordbreak-keepall body_1_400 overflow-hidden">
            {children}
          </div>
        </>
      ) : location.pathname === "/check/8" ? (
        <>
          <div
            className="fixed top-0 left-0 z-10 w-full h-screen bg-black opacity-30 "
            onClick={() => {
              if (imagebigKey) {
                showImageBig();
              } else {
                showCheckModal();
              }
            }}
          ></div>
          <div className="fixed z-20 min-w-[320px] max-h-[540px] top-1/2 left-1/2 transform-50 px-[16px] py-[8px] bg-[#FAFCFF] rounded-md items-center wordbreak-keepall body_1_400 overflow-hidden">
            {children}
          </div>
        </>
      ) : location.pathname === "/healthdiary" ? (
        <>
          <div
            className="fixed top-0 left-0 z-10 w-full h-screen bg-black opacity-30 "
            onClick={() => {
              if (imagebigKey) {
                showImageBig();
              } else {
                console.log("this");
                setPhotoAlert(false);
              }
            }}
          ></div>
          <div className="fixed z-20 min-w-[320px] max-h-[540px] top-1/2 left-1/2 transform-50 p-[32px] bg-[#FAFCFF] rounded-md items-center wordbreak-keepall body_1_400">
            {children}
          </div>
        </>
      ) : location.pathname === "/community/post" ? (
        <>
          <div
            className="fixed top-0 left-0 z-10 w-full h-screen bg-black opacity-30 "
            onClick={() => {
              setWarning(false);
              setBlocking(false);
              setWarnBlock(false);
            }}
          ></div>
          <div className="fixed z-20 min-w-[320px] max-h-[540px] top-1/2 left-1/2 transform-50 p-[32px] bg-[#FAFCFF] rounded-md items-center body_1_400 wordbreak-keepall">
            {children}
          </div>
        </>
      ) : location.pathname === "/myinfo/appusecont" ? (
        <>
          <div
            className="fixed top-0 left-0 z-10 w-full h-screen bg-black opacity-30 "
            onClick={showDeleteAppuse}
          ></div>
          <div className="fixed z-20 min-w-[320px] max-h-[540px] top-1/2 left-1/2 transform-50 p-[32px] bg-[#FAFCFF] rounded-md items-center body_1_400 wordbreak-keepall">
            {children}
          </div>
        </>
      ) : (
        <>
          <div className="fixed top-0 left-0 z-10 w-full h-screen bg-black opacity-30 body_1_400"></div>
          <div className="fixed z-10 min-w-[300px] top-1/2 left-1/2 transform-50 p-[32px] bg-[#FAFCFF] rounded-md flex flex-col items-center  body_1_400 wordbreak-keepall">
            {children}
          </div>
        </>
      )}
    </>
  );
};

export default CenterModal;
