import React, { useState } from "react";
import RightModal from "../../../mobilecomponent/RightModal";
import BlockingPost from "./BlockingPost";
import WarnPostModal from "./WarnPostModal";

const WarnBlockPost = ({
  setWarnBlock,
  postingid,
  setSubmitwarn,
  commentUserId,
  reportOX
}) => {
  const [warning, setWarning] = useState(false);
  const [blocking, setBlocking] = useState(false);

  return (
    <>
      <div
        className="z-4 fixed top-0 left-0 w-full h-full bg-transparent"
        onClick={() => {
          setWarnBlock(false);
        }}
      ></div>
      <RightModal>
        <div
          className="px-[16px] py-[20px] cursor-pointer border-b flex items-center hover:bg-[#CBDFFC] ease-in-out duration-300 "
          onClick={() => {
            setWarning(true);
          }}
        >
          <span className="inline-block pr-2 cursor-pointer">신고하기</span>
        </div>
        <div
          className="px-[16px] py-[20px] cursor-pointer border-b flex items-center hover:bg-[#CBDFFC] ease-in-out duration-300 "
          onClick={() => {
            setBlocking(true);
          }}
        >
          <span className="inline-block pr-2 cursor-pointer">차단하기</span>
        </div>
      </RightModal>

      {warning && (
        <WarnPostModal
          setWarnBlock={setWarnBlock}
          setBlocking={setBlocking}
          setWarning={setWarning}
          postingid={postingid}
          setSubmitwarn={setSubmitwarn}
        />
      )}
      {blocking && (
        <BlockingPost
          setBlocking={setBlocking}
          setWarning={setWarning}
          setWarnBlock={setWarnBlock}
          commentUserId={commentUserId}
        />
      )}
    </>
  );
};

export default WarnBlockPost;
