import React from "react";
import { useNavigate } from "react-router-dom";
const GraphDefault = () => {
  const grabox = "/images/mo/pngjpg/grabox.png";
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col justify-center items-center pt-[24px]">
        <img src={grabox} alt="grabox" />
        <div className="pt-[20px]">아직 작성된 다이어리가 없어요!</div>
        <div className="pt-[16px]">건강다이어리를 작성하여</div>
        <div className="pt-[16px]">체중, 수분섭취량, 배변량 변화를</div>
        <div>주단위로 체크해보세요 :)</div>
      </div>
      <div className="mt-[48px] w-full px-[24px] ">
        <div
          className=" bg-[#1B5EDC] text-white py-[20px] button_700 text-center hover:bg-[#18428E] rounded-full cursor-pointer  ease-in-out duration-300"
          onClick={() => navigate("/healthdiary")}
        >
          다이어리 바로가기
        </div>
      </div>
    </>
  );
};

export default GraphDefault;
