import Pagination from "react-js-pagination";

function Paging({ page, itemsCount, totalCount, setPage }) {
  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <>
      <Pagination
        activePage={page}
        itemsCountPerPage={itemsCount}
        totalItemsCount={totalCount}
        pageRangeDisplayed={5}
        prevPageText={""}
        nextPageText={""}
        onChange={handlePageChange}
        value={page}
      />
    </>
  );
}

export default Paging;
