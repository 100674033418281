import React from "react";
import RightModal from "../../../mobilecomponent/RightModal";
const ReportDiaryEditModal = ({
  showEditModal,
  reportdiaryDate,
  diaryId,
  reportdiaryedit,
  setReportEditDelete,
  setReporteditEdit
}) => {
  const trashcanicon = "/images/mo/trashcan.svg";
  const penicon = "/images/mo/pen.svg";
  return (
    <>
      <div
        className="w-full h-full bg-transparent fixed z-4"
        onClick={showEditModal}
      ></div>
      <RightModal>
        <div className="px-[17px] py-[20px] cursor-pointer border-b flex items-center hover:bg-[#CBDFFC] ease-in-out duration-300 ">
          <span
            className="inline-block pr-2 cursor-pointer"
            onClick={() => {
              if (reportdiaryedit === true) {
                setReporteditEdit(true);
              }
            }}
          >
            기록 수정
          </span>
          <img src={penicon} alt="penicon" className="w-[18px] h-[18px]" />
        </div>
        <div className="px-[17px] py-[20px] cursor-pointer border-b flex items-center hover:bg-[#CBDFFC] ease-in-out duration-300 ">
          <span
            className="inline-block pr-2"
            onClick={() => {
              if (reportdiaryedit === true) {
                setReportEditDelete(true);
              }
            }}
          >
            기록 삭제
          </span>
          <img
            src={trashcanicon}
            alt="trashcanicon"
            className="w-[18px] h-[18px]"
          />
        </div>
      </RightModal>
      <div
        className="fixed top-[34px] left-0 w-full h-full bg-transparent cursor-pointer z-3"
        onClick={showEditModal}
      ></div>
    </>
  );
};

export default ReportDiaryEditModal;
