import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import commonUrl, { api } from "../../../../../data/common";
import Popup from "reactjs-popup";
import { ToastContainer } from "react-toastify";
import ToggleBtnCommuInfo from "./ToggleBtnCommuInfo";
import common from "../../../../../data/common";

const CommuInfoTable = ({ commudata }) => {
  const PopUp = ({ data, open, onClose, value }) => {
    const number_id = [1, 2, 3, 4, 5];
    return (
      <>
        {value === "report" ? (
          <style jsx="true">{`
            .popup-content.jangrucheck__popup-content {
              box-sizing: border-box;
              border-radius: 16px;
              width: 373px;
              height: auto;
              display: flex;

              flex-direction: column;

              padding: 0px;
              position: relative;
              background: #fafbfc;
              border: 2px solid black;
            }
          `}</style>
        ) : (
          <style jsx="true">{`
            .popup-content.jangrucheck__popup-content {
              box-sizing: border-box;
              border-radius: 16px;
              overflow-y: auto;
              max-width: 384px;
              border-width: 2px;
              width: auto;
              height: auto;
              max-height: 100%;
              padding: 0px;
            }
          `}</style>
        )}
        <ToastContainer className="admin" />
        <Popup
          key={number_id.toString()}
          open={open}
          closeOnDocumentClick
          onClose={onClose}
          className="jangrucheck__popup"
        >
          {/* icon */}
          <div className="flex">
            <span
              className={
                value === "report"
                  ? "material-icons absolute text-gray-500 cursor-pointer rounded-full text-3xl right-0 m-4"
                  : "material-icons absolute text-white bg-black hover:text-gray-500 cursor-pointer rounded-[12px] text-1xl right-0 m-2"
              }
              onClick={onClose}
            >
              clear
            </span>
          </div>

          {value !== "report" ? (
            // image popup
            data.imageURL.length ? (
              data.imageURL.map((el, index) => {
                return value === el ? (
                  <img
                    key={index}
                    className="flex justify-center"
                    src={
                      commonUrl.networkImg +
                      encodeURIComponent(data.imageURL[index])
                    }
                    alt="사진"
                  />
                ) : (
                  <div key={index}></div>
                );
              })
            ) : (
              <></>
            )
          ) : (
            // 신고 popup
            <>
              <div className="w-full h-full">
                <p className="font-bold text-base text-starttext-[#020A1B] mx-6 my-6 text-xl">
                  신고 내용
                </p>
                {reportList.map((e, idx) => (
                  <div className="flex mt-4" key={idx}>
                    <span
                      className="mx-6 text-xl text-gray-500 rounded-full cursor-pointer material-icons"
                      onClick={onClose}
                    >
                      check
                    </span>
                    <p>
                      {e[0]} - {e[1]}건
                    </p>
                  </div>
                ))}
              </div>
              <div className="bottom-0 flex items-center justify-center my-5">
                <button
                  onClick={(e) => {
                    onClose();
                  }}
                  className="flex items-center justify-center w-24 h-8 bg-[#1B5EDC] text-white rounded-[4px] border-black "
                >
                  확인
                </button>
              </div>
            </>
          )}
        </Popup>
      </>
    );
  };
  const [reportList, setReportdata] = useState([]);
  const [info_patient, setInfo_patient] = useState([]);

  const [open, setOpen] = useState(false); // popup open 여부
  const closeModal = () => setOpen(false); // popup 닫을 시 실행하는 함수
  const [sort, setSort] = useState(""); // 사진 구분
  const report_flag = commudata.report !== "-" ? true : false;
  let only_img = [];
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      const response = await api.get(
        common.baseURL + "community/posting/showreport/" + commudata.id
      );
      setReportdata(response.data.reportList);
      const patient_info = await api.get(
        common.baseURL + "patient/Id/" + commudata.patient.id
      );
      setInfo_patient(patient_info.data.patient);
    }
    fetchData();
  }, []);

  if (commudata.photoURL) {
    for (let i = 0; i < commudata.photoURL.length; i++) {
      const file_form = commudata.photoURL[i].slice(
        commudata.photoURL[i].indexOf("."),
        commudata.photoURL[i].length
      ); //확장자만 slice
      if (
        file_form === ".jpg" ||
        file_form === ".jpeg" ||
        file_form === ".png"
      ) {
        only_img.push(commudata.photoURL[i]); // 이미지 찾으면?
      }
    }
  }
  console.log(commudata.photoURL, "333");
  return (
    <>
      <PopUp
        open={open}
        onClose={closeModal}
        value={sort}
        data={{
          imageURL: only_img,
        }}
      />
      <ToastContainer />
      <div className="grid grid-cols-10 min-w-[915px] text-center border border-[#E5E6E7] bg-[#fafbfc] ">
        <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-2">
          작성자(등록번호)
        </label>
        <label className="flex justify-start bg-white items-center text-left px-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-5">
          <p
            onClick={() =>
              navigate("/admin/main/client/modify", {
                state: {
                  data: info_patient,
                },
              })
            }
            className="cursor-pointer hover:underline"
          >
            {commudata.name}
          </p>

          {/* /admin/main/client/modify */}
        </label>
        <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-1">
          구분
        </label>
        <label className="flex justify-start bg-white items-center text-left px-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-2">
          {commudata.patient.belong.name}
        </label>
        <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-2">
          내용
        </label>
        <label className="h-auto break-all text-left overflow-auto pl-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-8 bg-white">
          {commudata.contents}
        </label>
        <>
          <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-2">
            첨부이미지
          </label>
          <label className="flex justify-start bg-white items-center pl-4 border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-5">
            {only_img.length === 0 ? (
              <p>-</p>
            ) : (
              only_img.map((el, index) => (
                <div key={index}>
                  <img
                    id="image"
                    src={common.networkImg + encodeURIComponent(el)}
                    alt={el}
                    className="w-12 h-12 mx-1 cursor-pointer overflow-hidden rounded-[6px] border-2"
                    onClick={() => {
                      setSort(el);
                      setOpen(true);
                    }}
                  />
                </div>
              ))
            )}
          </label>
          <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border ">
            신고
          </label>
          <label className="flex justify-center bg-white items-center text-left px-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-2">
            <button
              className={
                report_flag
                  ? //신고 들어온 게시물
                    "border bg-red-500 px-6 py-1 rounded-[6px]"
                  : //신고 안들어온 게시물
                    "border bg-gray-300 px-6 py-1 rounded-[6px] cursor-default"
              }
              onClick={() => {
                report_flag ? setOpen(true) : setOpen(false);
                setSort("report");
              }}
            >
              <p className="text-sm font-bold text-white">신고</p>
            </button>
          </label>
        </>

        <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-2">
          작성일
        </label>
        <label className="flex justify-start items-center px-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border  col-span-5 bg-white">
          {dayjs(new Date(commudata.createdAt)).format("YYYY.MM.DD")}
        </label>
        <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-1">
          공개여부
        </label>
        <label className="flex flex-col justify-start items-center px-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-2 bg-white">
          <ToggleBtnCommuInfo commudata={commudata} />
        </label>
      </div>
    </>
  );
};

export default CommuInfoTable;
