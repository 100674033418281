import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
SwiperCore.use([Autoplay]);
const Epidermis = ({ btn3, bigTitle }) => {
  const image2 = "/images/mo/jangrucont/피부합병증2.jpg";
  const image3 = "/images/mo/jangrucont/피부합병증3.jpg";
  return (
    <div className="w-full flex flex-col  px-[16px] body_1_400_jangrucont wordbreak-keepall">
      <div className="pt-[72px] ">
        <div className="flex justify-center flex-col items-center w-full border-b">
          <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
          <h1 className="h2_800 pt-[4px] pb-[24px]">{btn3}</h1>
        </div>

        <div className="w-full pt-[24px]">
          <div>
            <div className="subtitle_1_700 pb-[8px]">1) 정의</div>
            <Swiper
              loop={true}
              spaceBetween={8}
              slidesPerView={1}
              autoplay={{ delay: 1000, disableOnInteraction: false }}
            >
              <SwiperSlide>
                <div className="w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
                  <img src={image2} alt="image2" className="h-full" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
                  <img src={image3} alt="image3" className="h-full" />
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="pt-[8px]">
              <span className="inline-block">
                장루 주위 피부가 장기간 대변에 의해 자극됨으로써 정상적인 세포의
                수가 증가되어 장점 막과 피부의 경계가 울퉁불퉁한 육아조직이
                발생하는 것을 말합니다.
              </span>
            </div>
          </div>
          <div className="pt-[32px]">
            <div className="subtitle_1_700 pb-[8px]">2) 관리방법</div>
            <div className="pt-[8px]">
              <div className="flex  ">
                <span className="inline-block">1.&nbsp;</span>
                <div>피부에 배설물이 만성적으로 노출되는 것을 막습니다.</div>
              </div>
              <div className="flex  ">
                <span className="inline-block">2.&nbsp;</span>
                <div>
                  <span className="inline-block">
                    피부 보호판의 개구부를 장루에 맞게 오리세요.
                  </span>
                </div>
              </div>
              <div className="flex justify-end  ">
                <span className="inline-block">3.&nbsp;</span>
                <div>
                  장루와 피부 보호판 사이의 틈새를 틈막음 연고나 링으로 잘
                  메우세요.
                </div>
              </div>
              <div className="flex justify-end  ">
                <span className="inline-block">4.&nbsp;</span>
                <div>
                  피부 보호판 교체에 어려움이 있거나 통증이 심하면 병원을
                  방문해서 비대해진 조직을 제거합니다. (전기소작술)
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Epidermis;
