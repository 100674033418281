import { useEffect, useRef, useState } from "react";
import { BtnBlack, BtnWhite } from "../../../admin_components/Buttons";
import autoInputPhone from "../../../admin_components/AutoInputPhone";
import commonUrl, { api } from "../../../../../data/common";
import { ToastContainer, toast } from "react-toastify";
import { useMainContext } from "../../../../../contexts/Provider";
import Header2 from "../../../admin_components/Header/Header2";
import { useNavigate } from "react-router-dom";
import {
  toastCommonProps,
  findToastPosition,
} from "../../../admin_components/Toast";
import {
  convertNameToBelong,
  convertRole,
} from "../../../admin_components/ConvertAdmin";
import { handlingError } from "../../../admin_components/Error";
import common from "../../../../../data/common";

function Register() {
  const navigate = useNavigate();
  const { adminLogin, belongs, setBelongs } = useMainContext(); // 로그인 정보 Consumer
  // 관리자 등록 정보 //todo
  const [manager, setManager] = useState({
    name: "",
    email: "",
    phone: "",
    adminPwd: "",
    belong: adminLogin.belong !== 1 ? adminLogin.belong : "소속 선택",
    role: "구분 선택",
    power: adminLogin.belong !== 1 ? "1" : "권한 선택",
    license: "", // 면허번호
    subjectCode: "", // 전문 과목코드
    qualification: "", // 자격번호
    status: "활성",
  });
  const [loading, setLoading] = useState(false); //
  const [toastElPosition, setToastElPosition] = useState({ top: 0, left: 0 }); // toast창 위치 값
  const phoneRef = useRef(); // 휴대폰 번호 입력칸 ref
  // 구분 or 권한 설정 칸 클릭시(소속 먼저 선택했는지 체크)
  const clickRoleOrPower = (e) => {
    // toast 창 위치 설정
    setToastElPosition({
      top: findToastPosition("top"),
      left: findToastPosition("left"),
    });

    // 아직 소속 선택안했다면
    if (manager.belong === "소속 선택") {
      toast(
        <p>소속 먼저 선택해주세요.</p>,
        toastCommonProps(1500, "top-left", "toast_common toast__normal")
      );
    }
    // 의사에서 구분 변경시 과목코드 초기화
    if (
      e.target.name === "role" &&
      manager.role !== 1 &&
      manager.subjectCode !== ""
    ) {
      setManager({ ...manager, subjectCode: "" });
    }
  };

  // input 값 변경 //todo
  const change = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "eee");

    if (name === "phone") {
      // 휴대폰 번호 - 자동입력
      autoInputPhone(
        manager.phone,
        "phone",
        value,
        setManager,
        manager,
        phoneRef
      );
    }
    // 구분 간호사일경우 - 과목코드 05 저장
    else if (name === "role" && value === "2") {
      setManager({ ...manager, role: value, subjectCode: "05" });
    }
    // 휴대폰 번호 제외한 변수들
    else setManager({ ...manager, [name]: value });
  };

  // 제출 전에 제출 형식에 맞는 지 확인 //todo
  const checkForSave = () => {
    let msg = ""; // 오류났을 시 메세지

    // 휴대폰 번호 | 이메일 주소 정규식
    const regPhone = /^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/;
    const regEmail =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    // 오직 문자열만 정규식
    const onlyString = /^[가-힣a-zA-Z]+$/;
    // 오직 숫자만 정규식
    const onlyNum = /^[0-9]*$/;

    // 누락된 칸이 있을 경우
    if (manager.name.trim() === "") {
      msg = "이름을 입력해 주세요.";
    } // 이름 형식 체크
    else if (!onlyString.test(manager.name)) {
      msg = "이름이 올바르지 않습니다.";
    }
    //todo
    else if (manager.email.trim() === "") {
      msg = "이메일을 입력해 주세요.";
    } else if (!regEmail.test(manager.email)) {
      msg = "이메일 형식이 알맞지 않습니다.";
    } else if (manager.phone.trim() === "") {
      msg = "휴대폰 번호를 입력해 주세요.";
    } else if (!regPhone.test(manager.phone)) {
      msg = "휴대폰 번호 형식이 알맞지 않습니다.";
    } else if (manager.adminPwd.trim() === "") {
      msg = "비밀번호를 입력해 주세요.";
    } else if (manager.belong === "소속 선택") {
      msg = "소속을 선택해 주세요.";
    } else if (manager.role.trim() === "구분 선택") {
      msg = "구분을 선택해 주세요.";
    } else if (manager.power.trim() === "권한 선택") {
      msg = "권한을 선택해 주세요.";
    } else if (manager.power.trim() === "") {
      msg = "권한을 선택해 주세요.";
    } else if (manager.license.trim() === "") {
      msg = "면허번호를 입력해 주세요.";
    } else if (manager.license.length < 4 || !onlyNum.test(manager.license)) {
      msg = "면허번호가 잘못되었습니다.";
    } else if (manager.qualification.trim() === "") {
      msg = "자격번호를 입력해 주세요.";
    } else if (onlyNum.test(manager.qualification) === false) {
      msg = "자격번호가 잘못되었습니다.";
    } else if (
      (manager.role.trim() === "1" && // 1 = 의사
        manager.subjectCode.trim() === "") ||
      manager.subjectCode === "선택"
    ) {
      msg = "전문과목코드를 선택해 주세요.";
    }

    // 모두 통과
    if (msg === "") return true;
    // 형식 오류 있을 때
    else {
      toast(
        <p>{msg}</p>,
        toastCommonProps(1500, "top-left", "toast_common toast__normal")
      );
      return false;
    }
  };
  // 저장하기
  const save = () => {
    // toast창 위치 설정
    setToastElPosition({
      top: findToastPosition("top"),
      left: findToastPosition("left"),
    });

    // 제출 형식 체크 통과하면 //todo
    if (checkForSave()) {
      // axios로 보낼 데이터
      let newData = {
        ...manager,
        name: manager.name.trim(),
        email: manager.email.trim(),
        role: Number(manager.role),
        belong: Number(manager.belong),
        power: Number(manager.power),
        license: manager.license.trim(), // 면허번호
        subjectCode:
          manager.role === "2" && manager.subjectCode === ""
            ? "05"
            : manager.subjectCode, // 전문 과목코드
        qualification: manager.qualification.trim(), // 자격번호
        status: 0,
        agree: false,
      };

      // Axios //todo
      async function fetchData() {
        setLoading(true);
        console.log(newData);
        const res1 = await api({
          method: "post",
          url: commonUrl.admin,
          data: newData,
        }).catch((error) => {
          handlingError(error, "top-left", "toast_common toast__normal");
        });
        console.log(res1.data, ">>> Axios admin(add)");
        // Axios 성공 시
        if (res1.data.ok) {
          toast(
            <p>저장 되었습니다.</p>,
            toastCommonProps(1500, "top-left", "toast_common toast__normal")
          );
          setTimeout(() => {
            setLoading(false);
            navigate("/admin/main/manager", {
              replace: true,
            });
          }, 2000);
        }
        // Axios 실패 시
        else {
          setLoading(false);
          toast(
            <p className="text-sm">{res1.data.error}</p>,
            toastCommonProps(1500, "top-left", "toast_common toast__normal")
          );
        }
      }
      fetchData();
    }
  };

  // 소속과 구분을 선택한 상태에서 소속만 바꿨을 경우 처리 //todo
  // (소속-병원 이면 구분-관리자 항목 안보이게 소속-헤링스 이면 구분-의사,간호사 안보이게)
  // 0-헤링스, 1-의사, 2-간호사
  useEffect(() => {
    // 소속만 헤링스로 바꿨고, 이전에 구분이 의사나 간호사였을 때
    if (
      manager.belong === "1" &&
      (manager.role === "1" || manager.role === "2")
    )
      setManager({ ...manager, role: "구분 선택" });
    // 소속만 병원으로 바꿨고, 이전에 구분이 관리자였을 때
    else if (manager.role === "0")
      setManager({ ...manager, role: "구분 선택" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manager.belong]);

  useEffect(() => {
    api.get(common.baseURL + "belong").then((res) => {
      if (res.data.ok) {
        setBelongs(res.data.belongList);
      }
    });
  }, []);

  return (
    <Header2
      title={[
        { title: "관리자 등록/관리", url: -1 },
        { title: "관리자 등록", url: 0 },
      ]}
    >
      <ToastContainer />
      <style jsx="true">{`
        .Toastify__toast {
          top: ${toastElPosition.top - 20}px;
          left: ${toastElPosition.left - 295}px;
        }
      `}</style>
      <div className="admin flex flex-col grow p-[32px]">
        <div className="h-[80px] min-h-[80px]"></div>
        <label className="text-2xl font-extrabold leading-8 text-[#020A1B] mb-3">
          관리자 등록
        </label>
        <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] min-w-[975px]">
          {/* 표 */}
          <div className="grid grid-cols-4">
            <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
              이름
            </label>
            <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
              <input
                type="text"
                className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B]"
                name="name"
                onChange={change}
              />
            </div>
            <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
              이메일 주소
            </label>
            <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
              <input
                type="text"
                className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none text-[#020A1B]"
                name="email"
                placeholder="이메일 주소가 ID가 됩니다."
                onChange={change}
              />
            </div>
            <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
              휴대폰 번호
            </label>
            <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
              <input
                type="text"
                className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none text-[#020A1B]"
                name="phone"
                placeholder="010-1234-5678"
                onChange={change}
                ref={phoneRef}
              />
            </div>
            <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
              임시 비밀번호
            </label>
            <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
              <input
                type="text"
                className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B]"
                name="adminPwd"
                onChange={change}
              />
            </div>
            <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
              소속
            </label>
            {/* manager 관련 페이지는 power === 0 인 경우에만 접근 가능하므로 belong 으로 구분  */}
            {/* 헤링스 슈퍼관리자 */}
            {adminLogin.belong === 1 ? (
              <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                <select
                  className="w-full mx-[1px] px-[5px] py-[3px] border border-[#E5E6E7] vertical-middle focus:outline-none text-[#020A1B]"
                  name="belong"
                  defaultValue="소속 선택"
                  onChange={change}
                >
                  <option value="소속 선택">소속 선택</option>
                  {belongs.map((el, idx) => {
                    return (
                      <option key={idx} value={el.id}>
                        {el.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : (
              // 병원 슈퍼관리자
              <label className="border border-[#E5E6E7] font-normal p-[5px]">
                {adminLogin.belongName}
              </label>
            )}
            <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
              구분
            </label>
            <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
              {/* 헤링스 슈퍼관리자 */}
              {adminLogin.belong === 1 ? (
                // 소속을 헤링스로 선택
                manager.belong === "1" ? (
                  <select
                    className="w-full mx-[1px] px-[5px] py-[3px] border border-[#E5E6E7] vertical-middle focus:outline-none text-[#020A1B]"
                    name="role"
                    defaultValue="소속 선택"
                    onChange={change}
                    onClick={clickRoleOrPower}
                  >
                    <option value="구분 선택">구분 선택</option>
                    <option value="0">관리자</option>
                  </select>
                ) : (
                  // 소속을 병원으로 선택
                  <select
                    className="w-full mx-[1px] px-[5px] py-[3px] border border-[#E5E6E7] vertical-middle focus:outline-none text-[#020A1B]"
                    name="role"
                    defaultValue="소속 선택"
                    onChange={change}
                    onClick={clickRoleOrPower}
                  >
                    <option value="구분 선택">구분 선택</option>
                    <option value="1">의사</option>
                    <option value="2">장루 간호사</option>
                  </select>
                )
              ) : (
                // 병원 슈퍼관리자
                <select
                  className="w-full mx-[1px] px-[5px] py-[3px] border border-[#E5E6E7] vertical-middle focus:outline-none text-[#020A1B]"
                  name="role"
                  defaultValue="소속 선택"
                  onChange={change}
                  onClick={clickRoleOrPower}
                >
                  <option value="구분 선택">구분 선택</option>
                  <option value="1">의사</option>
                  <option value="2">장루 간호사</option>
                </select>
              )}
            </div>
            {/* 헤링스 슈퍼관리자 */}
            {adminLogin.belong === 1 || adminLogin.power === 0 ? (
              <>
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  권한 설정
                </label>
                <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                  <select
                    className="w-full mx-[1px] px-[5px] py-[3px] border border-[#E5E6E7] vertical-middle focus:outline-none text-[#020A1B]"
                    name="power"
                    // defaultValue="권한 선택"
                    value={manager.power}
                    onChange={change}
                    onClick={clickRoleOrPower}
                  >
                    <option value="권한 선택">권한 선택</option>
                    <option value="0">수퍼 관리자</option>
                    <option value="1">일반 관리자</option>
                  </select>
                </div>
              </>
            ) : null}
            {manager.belong !== "1" ? (
              <>
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  면허번호
                </label>
                <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                  <input
                    type="text"
                    className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none text-[#020A1B]"
                    name="license"
                    minLength={4}
                    maxLength={6}
                    placeholder="4~6자리의 숫자를 입력해 주세요."
                    onChange={change}
                  />
                </div>
              </>
            ) : null}

            {manager.belong !== "1" ? (
              <>
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  자격번호
                </label>
                <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                  <input
                    type="text"
                    className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none text-[#020A1B]"
                    name="qualification"
                    maxLength={6}
                    placeholder="6자리 이하의 숫자를 입력해 주세요."
                    onChange={change}
                  />
                </div>
              </>
            ) : null}

            {manager.belong !== "1" ? (
              <>
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  전문과목코드
                </label>
                <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                  <select
                    className="w-full mx-[1px] px-[5px] py-[3px] border border-[#E5E6E7] vertical-middle focus:outline-none text-[#020A1B]"
                    name="subjectCode"
                    // defaultValue={"선택"}
                    value={manager.subjectCode}
                    onChange={change}
                    disabled={manager.role !== "1" ? true : false}
                  >
                    <option value="선택">
                      {manager.role === "2" ? "간호사(05)" : "선택"}
                    </option>
                    <option value="010400">외과(010400)</option>
                    <option value="010401">간담췌외과(010401)</option>
                    <option value="010402">대장항문외과(010402)</option>
                    <option value="010403">소아외과(010403)</option>
                    <option value="010404">위장관외과(010404)</option>
                    <option value="010405">수부외과(010405)</option>
                    <option value="010406">중환자외과(010406)</option>
                    <option value="010407">외상외과(010407)</option>
                    <option value="010408">외과유방질환 분과(010408)</option>
                  </select>
                </div>
              </>
            ) : null}
          </div>
          {/* 목록 & 저장하기 버튼 */}
          <div className="flex justify-end mt-[20px]">
            <div className="flex justify-between gap-x-3">
              <BtnWhite
                context="목록"
                onClick={() => {
                  navigate(-1);
                }}
                style={{ width: "88px" }}
                standard="toast_standard"
              />
              <BtnBlack
                context="저장하기"
                onClick={save}
                loading={loading}
                style={{ width: "88px" }}
              />
            </div>
          </div>
        </div>
        <div className="w-full h-[32px]"></div>
      </div>
    </Header2>
  );
}

export default Register;
