import React from "react";
import CenterModal from "../../../mobilecomponent/CenterModal";

const ImageSizeBig = ({ showImageBig }) => {
  const closebasic = "/images/mo/closebasic.svg";
  const jangruboy_wow = "/images/mo/jangruboy_wow.svg";
  const imagebigKey = true;
  return (
    <CenterModal showImageBig={showImageBig} imagebigKey={imagebigKey}>
      <img
        src={closebasic}
        alt="closebasic"
        className="absolute top-0 right-0 cursor-pointer"
        onClick={showImageBig}
      />
      <div className="wordbreak-keepall flex flex-col items-center">
        <img src={jangruboy_wow} alt="jangruboywow" />
        <div className="pt-[16px]">15MB 이하의 파일만 추가 가능합니다.</div>
        <div>파일 사이즈를 확인해주세요!</div>
      </div>
    </CenterModal>
  );
};

export default ImageSizeBig;
