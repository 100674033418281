import React from "react";
import { useLocation } from "react-router-dom";
const RightModal = ({ children }) => {
  const pathname = useLocation().pathname;
  return (
    <div
      className={
        pathname === "/myinfo/appusecont" || pathname === "/report/diary"
          ? "fixed top-[20px] right-[24px] bg-[#FAFCFF] border rounded-lg drop-shadow-sm z-5 button_700"
          : pathname === "/community/post"
          ? "fixed top-[92px] right-[24px] bg-[#FAFCFF] border rounded-lg drop-shadow-sm z-5 button_700"
          : ""
      }
    >
      {children}
    </div>
  );
};

export default RightModal;
