import React from "react";

const AppDownLoad = () => {
  const mobileTypeFn = () => {
    const uagent = navigator.userAgent.toLowerCase();
    const android_agent = uagent.search("android");
    const iphone = uagent.search("iphone");
    const ipad = uagent.search("ipad");
    const mac = uagent.search("mac");

    if (android_agent > -1) {
      //안드로이드
      return "android";
    } else if (iphone > -1 || ipad > -1 || mac > -1) {
      //아이폰
      return "iphone";
    } else return "pc";
    // return uagent;
  };
  // console.log(mobileTypeFn());
  if (mobileTypeFn() === "iphone") {
    window.location.replace(
      "https://apps.apple.com/kr/app/id1619734796",
      "_blank"
    );
    setTimeout(() => {
      window.close();
    }, 1000);
  } else {
    window.location.replace(
      `https://play.google.com/store/apps/details?id=com.herings.stoma`,
      "_blank"
    );
    setTimeout(() => {
      window.close();
    }, 1000);
  }
};

export default AppDownLoad;
