import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, useSortBy, usePagination } from "react-table";

export default function Table({
  columns,
  data,
  setData,
  // checkedPatientList,
  // setCheckedPatientList,
  pageMove,
  state, // prevPath 절달
  sorting, // sorting 기능이 필요한 테이블이면 true
  pagination, // pagination이 필요한 테이블이면 true
  showAll, // 검색기능이 있어서 전체보기가 필요한 테이블이면 click(=showAll) 함수
  pageSizeNum, // pageSize 조정
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: pageSizeNum ? pageSizeNum : 10 },
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  );
  const arrow_left = "/images/admin/carousel_left.svg";
  const arrow_right = "/images/admin/carousel_right.svg";
  const navigate = useNavigate();
  const paginationBtns = new Array(pageCount).fill(0).map((el, idx) => idx + 1);
  let flag = 0;

  // pagination UI
  const paginationUI = () => {
    return (
      <div className="flex justify-center items-center mt-[25px]">
        <button
          className="px-3"
          onClick={() => {
            previousPage();
          }}
          disabled={!canPreviousPage}
        >
          <img
            src={arrow_left}
            alt="arrow_left"
            className="w-[6.4px] h-[11.2px]"
            style={
              !canPreviousPage
                ? {
                    filter:
                      "brightness(0) saturate(100%) invert(48%) sepia(0%) saturate(5417%) hue-rotate(195deg) brightness(134%) contrast(82%)",
                  }
                : {
                    filter:
                      "brightness(0) saturate(100%) invert(3%) sepia(29%) saturate(7499%) hue-rotate(218deg) brightness(99%) contrast(100%)",
                  }
            }
          />
        </button>
        <div className="flex gap-x-1">
          {paginationBtns.map((el, idx) => {
            if (
              idx >= Math.floor(pageIndex / 5) * 5 &&
              idx <= Math.floor(pageIndex / 5) * 5 + 4
            ) {
              return (
                <button
                  key={idx}
                  className="flex justify-center items-center border w-[32px] h-[32px] text-center text-base text-[#020A1B] font-semibold leading-5 rounded-[28px]"
                  onClick={() => {
                    gotoPage(idx);
                  }}
                  style={{
                    borderColor: pageIndex === idx ? "#020A1B" : "transparent",
                  }}
                >
                  {idx + 1}
                </button>
              );
            } else return null;
          })}
        </div>
        <button
          className="px-3"
          onClick={() => {
            nextPage();
          }}
          disabled={!canNextPage}
        >
          <img
            src={arrow_right}
            alt="arrow_right"
            className="w-[6.4px] h-[11.2px]"
            style={
              !canNextPage
                ? {
                    filter:
                      "brightness(0) saturate(100%) invert(48%) sepia(0%) saturate(5417%) hue-rotate(195deg) brightness(134%) contrast(82%)",
                  }
                : {
                    filter:
                      "brightness(0) saturate(100%) invert(3%) sepia(29%) saturate(7499%) hue-rotate(218deg) brightness(99%) contrast(100%)",
                  }
            }
          />
        </button>
      </div>
    );
  };

  let color_1 = "#F2F6FC";
  let color_2 = "#FAFCFF";
  if (page.length % 2 === 0) {
    color_1 = "#FAFCFF";
    color_2 = "#F2F6FC";
  }
  console.log(page);
  return (
    <>
      <div className="flex flex-col w-full">
        <table className="w-full table-fixed">
          <thead {...getTableProps()}>
            {headerGroups.map((headerGroup) => {
              return (
                <tr key={headerGroup.headers}>
                  {headerGroup.headers.map((col, idx) => {
                    if (col.render("Header") === "내용") flag = idx;
                    return (
                      <th
                        key={col.id}
                        className="relative first:rounded-tl-2xl last:rounded-tr-2xl align-middle p-4 bg-[#CBDFFC] text-base text-[#020A1B] leading-[22px] font-semibold"
                        {...col.getHeaderProps(
                          idx === 0 || typeof col.Header !== "string"
                            ? {
                                style: { width: col.width },
                              }
                            : sorting
                            ? col.getSortByToggleProps({
                                style: { width: col.width },
                              })
                            : {
                                style: { width: col.width },
                              }
                        )}
                      >
                        {col.render("Header")}
                        <span className="align-top text-[10px]">
                          {sorting
                            ? col.isSorted
                              ? col.isSortedDesc
                                ? " ▼"
                                : " ▲"
                              : idx === 0
                              ? ""
                              : ""
                            : null}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, rowIdx) => {
              // rowIdx = Number(row.id);
              prepareRow(row);
              return (
                <tr
                  key={row.id}
                  {...row.getRowProps()}
                  className={
                    rowIdx % 2 === 0
                      ? data[rowIdx].answer === undefined &&
                        data[rowIdx].category !== undefined
                        ? `cursor-pointer bg-[${color_1}] hover:bg-[#DDE9FA]`
                        : `cursor-pointer bg-[${color_1}] hover:bg-[#DDE9FA]`
                      : data[rowIdx].answer === undefined &&
                        data[rowIdx].category !== undefined
                      ? `cursor-pointer bg-[${color_2}] hover:bg-[#DDE9FA]`
                      : `cursor-pointer bg-[${color_2}] hover:bg-[#DDE9FA]`
                  }
                >
                  {row.cells.map((cell, idx) => {
                    return (
                      <td
                        key={cell.row.id}
                        className={
                          page.length - 1 === rowIdx
                            ? `align-middle p-4 first:rounded-bl-2xl last:rounded-br-2xl border-t border-[#CBDFFC] text-base text-[#020A1B] leading-[22px] font-normal ${
                                cell.column.Header === "소속" &&
                                cell.value === "헤링스"
                                  ? "text-[#FF8C00]"
                                  : "text-[#020A1B]"
                              }`
                            : `align-middle p-4 border-t border-[#CBDFFC] text-base leading-[22px] font-normal ${
                                cell.column.Header === "소속" &&
                                cell.value === "헤링스"
                                  ? "text-[#FF8C00]"
                                  : "text-[#020A1B]"
                              }`
                        }
                        {...cell.getCellProps({
                          style: { width: cell.column.width },
                        })}
                        onClick={() => {
                          if (cell.column.id === "check") {
                            return;
                          }
                          if (pageMove !== undefined) {
                            navigate(pageMove, {
                              state: row.original,
                            });
                          }
                        }}
                      >
                        {cell.column.id === "check" && (
                          <input
                            type="checkbox"
                            className="w-full p-auto"
                            checked={cell.value}
                            onChange={(e) => {
                              setData(
                                data.map((patient) => {
                                  if (patient.idx === row.values.idx) {
                                    return {
                                      ...patient,
                                      check: e.currentTarget.checked,
                                    };
                                  } else {
                                    return { ...patient };
                                  }
                                })
                              );
                              // if (e.currentTarget.checked) {
                              //   setCheckedPatientList([
                              //     ...checkedPatientList,
                              //     row.values,
                              //   ]);
                              // } else {
                              //   setCheckedPatientList(
                              //     checkedPatientList.filter(
                              //       (list) => list.idx !== row.values.idx
                              //     )
                              //   );
                              // }
                            }}
                          />
                        )}
                        {idx === 2 &&
                        data[pageIndex * 10 + rowIdx].category !== undefined &&
                        data[pageIndex * 10 + rowIdx].startDate ===
                          undefined ? (
                          data[pageIndex * 10 + rowIdx].answer === undefined &&
                          data[pageIndex * 10 + rowIdx].category !==
                            undefined ? (
                            <div className="mx-auto flex flex-row items-start px-[6px] py-[2px] static w-[68px] h-[26px] left-0 top-0 border border-[#999CA3] box-border rounded-[2px] flex-none order-none grow-0">
                              <p className="static w-[56px] h-[22px] left-1.5 top-0.5 not-italic font-normal text-base flex items-center text-center text-[#999CA3] flex-none order-none grow-0 mr-[10px]">
                                답변대기
                              </p>
                            </div>
                          ) : (
                            <div className="mx-auto flex flex-row items-start px-[6px] py-[2px] static w-[68px] h-[26px] left-0 top-0 bg-[#1B5EDC] rounded-[2px] flex-none order-none grow-0">
                              <p className="static w-[56px] h-[22px] left-[6px] top-[2px] not-italic font-normal text-base flex items-center text-center text-[#FAFBFC] flex-none order-none grow-0 mr-[10px]">
                                답변완료
                              </p>
                            </div>
                          )
                        ) : (
                          <div
                            className={
                              cell.column.Header === "내용" ||
                              cell.column.Header === "신고 내용"
                                ? "w-full text-left truncate"
                                : cell.column.Header === "상세보기"
                                ? "w-full text-center truncate underline"
                                : "w-full text-center truncate"
                            }
                            id={cell.column.Header}
                          >
                            {flag === idx && cell.column.Header === "내용"
                              ? cell.value.toString().replace(/<[^>]*>/g, "")
                              : cell.render("Cell")}
                          </div>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* pagenation */}
        {pagination && page.length !== 0 ? (
          // 전체보기가 필요한 경우
          showAll ? (
            <div className="flex justify-between">
              <p
                className="mx-[5px] mt-[25px] min-w-[65px] cursor-pointer underline underline-offset-4 font-bold"
                onClick={showAll}
              >
                전체보기
              </p>
              {/* 2페이지 이상일 때 */}
              {data.length > pageSize ? paginationUI() : null}
              <div className="mx-[5px] mt-[25px] min-w-[65px]"></div>
            </div>
          ) : // 전체보기가 필요없는 경우 + 2페이지 이상일 때
          data.length > pageSize ? (
            paginationUI()
          ) : null
        ) : null}
      </div>
    </>
  );
}
