import React from "react";

const Tutorial4 = () => {
  const tutorial4 = "/images/mo/pngjpg/tutorial4.png";
  const lefthand = "/images/mo/pngjpg/lefthand.png";
  return (
    <div>
      <div className="pt-[25.5px] flex justify-center ">
        <div className="w-[375px] h-[329px] flex items-center justify-center relative">
          <img src={tutorial4} alt="tutorial4" />
          <img
            src={lefthand}
            alt="lefthand"
            className="absolute top-36 right-16 tutohand34"
          />
        </div>
      </div>
      <div className="flex items-center flex-col pt-[50px]">
        <span className="block text-[#1B5EDC] h2_800">건강 상태 알림</span>
        <div className="text-[#343B48] text-center body_1_400 pt-[8px]">
          <div>알림을 통해 간호사의 쪽지와</div>
          <div>점검일을 놓치지않고 체크 할 수 있어요.</div>
        </div>
      </div>
    </div>
  );
};

export default Tutorial4;
