import React from "react";

const WelfareConsultant = ({ bigTitle, btn4 }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400 wordbreak-keepall">
      <div className="pt-[72px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
        <h1 className="h2_800 pt-[4px] pb-[24px]">{btn4}</h1>
      </div>
      <div className="w-full pt-[24px] body_1_400_jangrucont">
        <div className="border mb-[24px]">
          <div className="border-b bg-[#F2F6FC] p-[8px] subtitle_2 text-center">
            대상환자
          </div>
          <div className="p-[16px]">
            아래 대상(질환)에 해당하고 진료담당의사가 치료효과를 높이기 위하여
            교육 필요성을 인정하는 경우 · 지속적인 장루 또는 요루 유지가 필요한
            환자 · 자연적으로 형성된 루(fistula)를 통해 분변(뇨) 배출이
            이루어지는 환자
          </div>
        </div>
        <div className="border mb-[24px]">
          <div className="border-b bg-[#F2F6FC] p-[8px] subtitle_2 text-center">
            교육내용 및 방법
          </div>
          <div className="p-[16px]">
            <div className="flex">
              <span className="inline-block">1.</span>
              <p className="pl-[4px]">
                일반사항: 관련 학회 등에서 제시한 표준 교육자료를 이용하여
                교육팀, 교육의 내용·횟수·간격 등이 미리 계획된 교육프로그램에
                의해 질환의 치료 및 합병증 예방 등 자가관리를 할 수 있는
                포괄적인 내용을 교육하며, 교육프로그램 일부 내용의 반복교육 및
                추후 관리를 포함함.
                <br />
                필수교육내용: 장루·요루에 대한 이해, 장루·요루 기구의 종류 및
                교환 방법, 장루·요루 주위 피부관리, 합병증 예방 및 치료방법,
                일상생활관리, 식이관리
              </p>
            </div>

            <span className="inline-block pt-[25px]">
              2. 교육방법: 개별 교육하는 것을 원칙으로 함
            </span>
          </div>
        </div>
        <div className="border ">
          <div className="border-b bg-[#F2F6FC] p-[8px] subtitle_2 text-center">
            교육주체 (교육팀)
          </div>
          <div className="p-[16px]">
            <div className="flex">
              <span className="inline-block">1.</span>
              <p className="inline-block pl-[4px]">
                일반사항: 의사, 간호사, 영양사, 약사 등 관련 분야 상근 전문
                인력으로 교육팀을 구성하되, 교육프로그램 전반을 관리하는
                코디네이터를 1인 이상 두어야 함.
              </p>
            </div>
            <div className="pt-[25px]">
              <span className="inline-block ">2. 교육팀 자격요건</span>
              <div className="flex">
                <span className="inline-block">-</span>
                <span className="inline-block pl-[4px]">
                  의사: 해당분야 전문의(세부 전문의 포함) 또는 진료담당 전문의
                </span>
              </div>
              <div className="flex">
                <span className="inline-block">-</span>
                <span className="inline-block pl-[4px]">
                  간호사: WOCN(Wound, Ostomy, Continence- Nursing,
                  상처장루실금간호사) 교육과정을 이수한 자 또는 해당분야
                  실무경력 3년 이상인 자
                </span>
              </div>

              <span className="inline-block">
                - 영양사: 국민영양관리법 제23조에 따른 임상영양사
              </span>

              <div className="flex">
                <span className="inline-block">-</span>
                <span className="inline-block pl-[4px]">
                  약사 등: 교육프로그램 상 필요하다 판단되는 해당분야 실무경력
                  3년 이상인 자
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[70px]"></div>
    </div>
  );
};

export default WelfareConsultant;
