import React from "react";
import CenterModal from "../../../mobilecomponent/CenterModal";

const CanUseModal = ({ showCanUseModal }) => {
  return (
    <CenterModal>
      <div className="text-center">
        <div>귀하께서는 규정을 위반하여</div>
        <div>커뮤니티 이용이</div>
        <div>차단되었습니다.</div>
      </div>
      <span
        className="mt-[24px] block w-full border rounded border-[#1B5EDC] text-[#1B5EDC] bg-[#FAFCFF] text-center px-[36px] py-[20px] hover:bg-[#F2F6FC] hover:border-[#18428E] hover:text-[#18428E] ease-in-out duration-300 cursor-pointer"
        onClick={showCanUseModal}
      >
        확인
      </span>
    </CenterModal>
  );
};

export default CanUseModal;
