import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import common, { api } from "../../../../data/common";
/*component*/
import Header2 from "../../admin_components/Header/Header2";
import InquiryInfoTable from "./components/InquiryInfoTable";
import InquiryAnswer from "./components/InquiryAnswer";

import InquiryEdit from "./components/InquiryEdit";
import { ToastContainer } from "react-toastify";
import { Loading } from "../../admin_components/Loading";
import { BtnBlack } from "../../admin_components/Buttons";

const InquiryId = () => {
  const [toastElPosition] = useState({ top: 0, right: 0 }); // toast창 위치 값
  const inquirydata = useLocation().state;
  const [realdata, setRealData] = useState([]);
  const [images, setImages] = useState([]);
  const [modify, setModify] = useState(false);
  const [loading] = useState(true);
  useEffect(() => {
    let pageOpen = false;
    const getQuestion = () => {
      api
        .get(common.baseURL + "question/id/" + inquirydata.question.id)
        .then((res) => {
          if (res.data.ok === true) {
            setRealData(res.data.question);
            setImages(res.data.question.photoURL);
          }
        })
        .catch((error) => console.log(error));
    };
    if (!pageOpen) {
      getQuestion();
    }
    return () => {
      pageOpen = true;
    };
  }, [inquirydata.question.id]);

  const flag = inquirydata.answer === undefined ? true : false;

  return (
    <>
      <Header2
        title={[
          { title: "이용 문의", url: "/admin/main/inquiry" },
          {
            title: "이용 문의 상세보기",
            url: 0,
          },
        ]}
        key={inquirydata.question.id}
      >
        <style jsx="true">{`
          .Toastify__toast {
            top: ${toastElPosition.top - 20}px;
            left: ${toastElPosition.left - 275}px;
          }
        `}</style>
        <ToastContainer />

        <div className="admin flex flex-col grow p-[32px] ">
          <div className="h-[80px] min-h-[80px]"></div>
          <label className="text-2xl font-extrabold leading-8 text-[#020A1B]">
            이용 문의 상세보기
          </label>

          {loading.first ? (
            <Loading />
          ) : (
            <>
              <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-3 min-w-[975px]">
                <InquiryInfoTable
                  inquirydata={inquirydata}
                  realdata={realdata}
                  images={images}
                />
              </div>

              <div className="mt-6  pb-[18px]">
                {flag ? ( //이용문의 작성 - 수정 구분
                  <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-3 min-w-[975px]">
                    <InquiryAnswer />
                  </div>
                ) : modify ? (
                  <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-3 min-w-[975px]">
                    <InquiryEdit />
                  </div>
                ) : (
                  <div className="flex justify-end items-center mx-[22px] ">
                    <BtnBlack
                      context="수정하기"
                      onClick={() => {
                        setModify(true);
                      }}
                      style={{
                        width: "120px",
                        height: "40px",
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </Header2>
    </>
  );
};

export default InquiryId;
