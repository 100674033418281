import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import commonUrl, { api } from "../../../../../data/common";
import { ToastContainer, toast } from "react-toastify";
import Popup from "reactjs-popup";
import { LoadingBlue } from "../../../admin_components/Loading";
import {
  toastCommonProps,
  findToastPosition,
} from "../../../admin_components/Toast";
import autoInputPhone from "../../../admin_components/AutoInputPhone";
import { handlingError } from "../../../admin_components/Error";

const FindId = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // 아이디 찾기 입력 내용
  const [findId, setFindId] = useState({
    name: "",
    phone: "",
  });
  const nameInputBox = useRef(); // 이름 input ref
  const phoneInputBox = useRef(); // 휴대폰 번호 input ref
  const [open, setOpen] = useState({ open: false, message: "" }); // 아이디 찾기 성공 popup

  // 엔터키 눌렀을 때
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      submit();
    }
  };

  // input 값 변경
  const change = (e) => {
    const { name, value } = e.target;

    // 휴대폰 번호 자동입력
    if (name === "phone") {
      autoInputPhone(
        findId.phone,
        "phone",
        value,
        setFindId,
        findId,
        phoneInputBox
      );
    } else setFindId({ ...findId, [name]: value });
  };

  // 이메일 확인 modal 닫기
  const closePopup = () => setOpen({ ...open, open: false });

  // 확인 버튼 누를 시
  const submit = () => {
    // axios
    async function fetchData() {
      setLoading(true);
      const res = await api({
        method: "post",
        url: commonUrl.admin + "findId",
        data: {
          name: findId.name.trim(),
          phone: findId.phone,
        },
      }).catch((error) => {
        handlingError(error);
      });
      console.log(res.data, ">>> Axios admin/findId");
      setLoading(false);

      // axios 성공 시
      if (res.data.ok) {
        setOpen({ open: true, message: res.data.message });
      }
      // axios 실패 시
      else {
        setFindId({ name: "", phone: "" });
        phoneInputBox.current.value = "";
        toast(
          <p>일치하는 정보가 없습니다.</p>,
          toastCommonProps(1500, "top-center", "toast_common toast__login")
        );
        nameInputBox.current.focus();
      }
    }
    fetchData();
  };

  return (
    <div className="admin bg-[#CBDFFC] ">
      <style jsx="true">{`
        .popup-content.findId-content {
          font-family: Pretendard, sans-serif !important;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
          border-radius: 24px;
          padding: 0px;
          max-width: 400px;
          color: #020a1b;
        }
        .Toastify__toast {
          top: ${findToastPosition("top") + 150}px;
          margin-left: 35px;
        }
      `}</style>
      {/* 아이디 찾기 성공 시 팝업 */}
      <Popup
        open={open.open}
        closeOnDocumentClick
        onClose={closePopup}
        className="findId"
      >
        <div className="flex flex-col justify-center items-center p-[10px] text-[#020A1B] font-normal">
          귀하의 아이디는
          <br />
          <span className="text-[#020A1B] font-semibold">
            {open.message + " "}
          </span>
          입니다.
        </div>
        <div className="w-full h-[1px] bg-slate-400"></div>
        <button
          className="flex justify-center items-center w-full p-2 cursor-pointer font-bold focus:outline-none"
          onClick={() => {
            setOpen({ ...open, open: false });
            setTimeout(() => {
              navigate(-1);
            }, [300]);
          }}
        >
          확인
        </button>
      </Popup>
      <ToastContainer />
      <div className="relative bg-[#4472c4] h-screen min-h-[500px] min-w-[450px] ">
        <div className="absolute w-[460px] h-[440px] inset-0 m-auto bg-white drop-shadow-md rounded-sm">
          <div className="absolute w-[385px] h-[380px] inset-0 m-auto ">
            <h3 className="flex justify-center text-center font-bold text-xl mt-[15px] mb-[35px] text-[#1B5EDC]">
              아이디 찾기
            </h3>
            <p className="text-[15px] mb-[20px]">
              <span className="text-[#1B5EDC] font-bold">
                이름, 휴대폰 번호
              </span>
              를 입력해 주세요.
            </p>
            {/* 이름 & 휴대폰번호 */}
            <div className="flex flex-between border-x-0 border-t-0 border-b">
              <label className="flex w-[90px] justify-start items-center font-bold ">
                이름
              </label>
              <input
                className="w-[300px] p-[10px] focus:outline-none"
                type="text"
                name="name"
                value={findId.name}
                onChange={change}
                onKeyPress={onKeyPress}
                ref={nameInputBox}
                autoFocus
              />
            </div>
            <div className="flex flex-between border-x-0 border-t-0 border-b mt-[10px]">
              <label className="flex w-[90px] justify-start items-center font-bold ">
                휴대폰 번호
              </label>
              <input
                className="w-[300px] p-[10px] focus:outline-none placeholder:text-base"
                type="text"
                name="phone"
                onChange={change}
                placeholder="예시) 010-1234-5678"
                onKeyPress={onKeyPress}
                ref={phoneInputBox}
              />
            </div>
            <button
              className="toast_standard flex justify-center items-center w-full h-[60px] bg-[#679dee] hover:bg-[#1B5EDC] ease-in-out duration-300 text-xl rounded-sm text-white font-semibold mt-[45px]"
              onClick={submit}
            >
              {loading ? <LoadingBlue /> : "확인"}
            </button>
            <button
              className="flex justify-center my-[20px] inset-x-0 mx-auto border-b leading-5 text-sm"
              onClick={() => {
                navigate(-1);
              }}
            >
              홈으로
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindId;
