import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import dayjs from "dayjs";

const Check4 = ({
  checkInfo,
  setCheckInfo,
  onChangeCheck,
  selectedDate,
  setSelectedDate,
  errorMessage,
}) => {
  const calendericon = "/images/mo/calender.svg";

  const navigate = useNavigate();

  const setTimeoutScroll = () => {
    const forScrollEl = document.querySelector(".for__scroll");
    forScrollEl.scroll(0, forScrollEl.clientHeight);
  };
  const goCheck3 = () => {
    navigate(`/check/3`);
  };
  const goCheck5 = () => {
    navigate(`/check/5`);
  };
  const DatePickerInput = ({ ...props }) => (
    <input {...props} readOnly={true} />
  );

  return (
    <>
      <div className="pt-[94px] px-[24px]">
        <h1 className="h3_800 pb-[80px] wordbreak-keepall">
          최근 장루 교환일과 장루교환 주기를 작성해 주세요.
        </h1>
        <span className="subtitle_2 block pb-[4px]">장루 교환일</span>
        <div
          className={
            " jangruchange relative mb-[12px] flex  items-center border rounded w-full  cursor-pointer bg-[#F2F6FC] border-[#9DC4FA] active:bg-[#CBDFFC]"
          }
        >
          <DatePicker
            className=" px-[24px] py-[16px] text-black w-full h-full bg-transparent cursor-pointer focus:outline-none"
            selected={selectedDate}
            dateFormat="yyyy-MM-dd"
            maxDate={new Date()}
            minDate={new Date(new Date().setDate(new Date().getDate() - 20))}
            locale={ko}
            withPortal
            onChange={(date) => {
              setSelectedDate(date);
              console.log(date);
              setCheckInfo({
                ...checkInfo,
                changedDate: date ? dayjs(date).format("YYYY-MM-DD") : "",
              });
            }}
            customInput={<DatePickerInput />}
          />

          <img
            className="absolute top-1/2 right-[24px] transform-y-50 w-[18px] h-[18px] inline-block "
            src={calendericon}
            alt="calendericon"
          />
        </div>
        <span className="subtitle_2 block pb-[4px]">장루 교환주기</span>
        <div
          className={
            checkInfo.changeCycle.length > 0
              ? "flex justify-between items-center border rounded w-full px-[24px] py-[16px] cursor-pointer bg-[#CBDFFC] border-[#1B5EDC]"
              : "flex justify-between items-center border rounded w-full px-[24px] py-[16px]  cursor-pointer bg-[#F2F6FC] border-[#9DC4FA] "
          }
          onClick={setTimeoutScroll}
        >
          <input
            type="number"
            inputMode="decimal"
            htmlFor="changeCycle"
            className="w-full h-full bg-transparent surveycheck focus:outline-none body_1_600"
            name="changeCycle"
            placeholder="예) 7"
            onChange={onChangeCheck}
            value={checkInfo.changeCycle}
          />
          <span className="pl-4">일</span>
        </div>
        <span className="text-[#F62828]">{errorMessage}</span>
      </div>
      <div className="w-full h-[110px]"></div>

      {checkInfo.changeCycle !== "" &&
      checkInfo.changedDate !== "" &&
      !errorMessage ? (
        <div className="fixed bottom-0 left-0 flex w-full font-bold ">
          <div
            className="border w-2/5 py-[20px] flex justify-center items-center cursor-pointer
                 bg-[#FAFCFF] active:bg-[#F2F6FC] border border-[#679DEE] active:border-[#18428E] inline-block button_700   cursor-pointer ease-in-out duration-300 text-[#1B5EDC] active:text-[#18428E]"
            onClick={goCheck3}
          >
            이전
          </div>
          <div
            className=" w-3/5 py-[20px] flex justify-center items-center cursor-pointer  bg-[#1B5EDC] button_700 text-white active:bg-[#18428E] ease-in-out duration-300"
            onClick={goCheck5}
          >
            다음
          </div>
        </div>
      ) : (
        <div className="fixed bottom-0 left-0 flex w-full font-bold ">
          <div
            className="border w-2/5 py-[20px] flex justify-center items-center cursor-pointer
               bg-[#FAFCFF] active:bg-[#F2F6FC] border border-[#679DEE] active:border-[#18428E] inline-block button_700   cursor-pointer  ease-in-out duration-300 text-[#1B5EDC] active:text-[#18428E]"
            onClick={goCheck3}
          >
            이전
          </div>
          <div className=" w-3/5 py-[20px] flex justify-center items-center bg-[#9DC4FA]  text-white button_700 ease-in-out duration-300">
            다음
          </div>
        </div>
      )}
    </>
  );
};

export default Check4;
