import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CenterModal from "../../../mobilecomponent/CenterModal";
import { useMainContext } from "../../../../../contexts/Provider";

import common, { api } from "../../../../../data/common";

const WarnPostModal = ({
  setWarning,
  setBlocking,
  setWarnBlock,
  postingid,
  setSubmitwarn,
}) => {
  const radio_on = "/images/mo/radio_on.svg";
  const radio_off = "/images/mo/radio_off.svg";
  const [warn1, setWarn1] = useState(false);
  const [warn2, setWarn2] = useState(false);
  const [warn3, setWarn3] = useState(false);
  const [warn4, setWarn4] = useState(false);
  const [warn5, setWarn5] = useState(false);
  const [reason, setReason] = useState("");
  const [submitok, setSubmitok] = useState(false);
  const { loginInput } = useMainContext();
  const navigate = useNavigate();
  const GoWarnReport = () => {
    console.log(loginInput.id, postingid, reason, ">>>");
    let body = {
      id: postingid,
      report: {
        who: loginInput.id,
        reason: reason,
      },
    };

    api
      .post(common.baseURL + "community/posting/report", body)
      .then((res) => {
        if (res.data.ok === true) {
          console.log(res);

          api
            .get(
              common.baseURL + `community/posting/${postingid}/${loginInput.id}`
            )
            .then((getres) => {
              if (getres.data.ok === true) {
                console.log(getres, "getres 신고하기");
                setSubmitok(true);
                let start = setTimeout(() => {
                  navigate("/community");
                }, 2000);

                return () => {
                  clearTimeout(start);
                };
              }
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <CenterModal
      setWarning={setWarning}
      setBlocking={setBlocking}
      setWarnBlock={setWarnBlock}
    >
      {submitok ? (
        <div>
          <div className="text-center">
            <span className="block">신고가 접수되었습니다.</span>
            <span className="block">신고 내용을 검토한 후</span>
            <span className="block">신속하게 처리하겠습니다.</span>
          </div>
          <span
            className="mt-[24px] block w-full border rounded border-[#1B5EDC] text-[#1B5EDC] bg-[#FAFCFF] text-center px-[36px] py-[20px] hover:bg-[#F2F6FC] hover:border-[#18428E] hover:text-[#18428E] ease-in-out duration-300 cursor-pointer"
            onClick={() => {
              navigate("/community");
            }}
          >
            확인
          </span>
        </div>
      ) : (
        <div>
          <span className="block pb-[24px]">신고 사유를 선택해주세요.</span>
          <div>
            <div
              className="flex items-center gap-x-[12px] px-[16px] py-[12px] bg-[#F2F6FC] rounded cursor-pointer"
              onClick={() => {
                setWarn1(!warn1);
                setWarn2(false);
                setWarn3(false);
                setWarn4(false);
                setWarn5(false);
                setReason("부적절한 홍보(광고) 내용");
              }}
            >
              {warn1 ? (
                <img src={radio_on} alt={radio_on} />
              ) : (
                <img src={radio_off} alt={radio_off} />
              )}

              <span>부적절한 홍보(광고) 내용</span>
            </div>
            <div
              className="mt-[4px] flex items-start gap-x-[12px] px-[16px] py-[12px] bg-[#F2F6FC] rounded cursor-pointer"
              onClick={() => {
                setWarn2(!warn2);
                setWarn1(false);
                setWarn3(false);
                setWarn4(false);
                setWarn5(false);
                setReason("사생활 침해 및 저작권 침해 내용");
              }}
            >
              {warn2 ? (
                <img src={radio_on} alt={radio_on} />
              ) : (
                <img src={radio_off} alt={radio_off} />
              )}
              <span>사생활 침해 및 저작권 침해 내용</span>
            </div>
            <div
              className="mt-[4px] flex items-start gap-x-[12px] px-[16px] py-[12px] bg-[#F2F6FC] rounded cursor-pointer"
              onClick={() => {
                setWarn3(!warn3);
                setWarn2(false);
                setWarn1(false);
                setWarn4(false);
                setWarn5(false);
                setReason("욕설/비방/비하/혐오/불쾌한 내용");
              }}
            >
              {warn3 ? (
                <img src={radio_on} alt={radio_on} />
              ) : (
                <img src={radio_off} alt={radio_off} />
              )}
              <span>욕설/비방/비하/혐오/불쾌한 내용</span>
            </div>
            <div
              className="mt-[4px] flex items-start gap-x-[12px] px-[16px] py-[12px] bg-[#F2F6FC] rounded cursor-pointer"
              onClick={() => {
                setWarn4(!warn4);
                setWarn1(false);
                setWarn2(false);
                setWarn3(false);
                setWarn5(false);
                setReason("음란성 내용");
              }}
            >
              {warn4 ? (
                <img src={radio_on} alt={radio_on} />
              ) : (
                <img src={radio_off} alt={radio_off} />
              )}
              <span>음란성 내용</span>
            </div>
            {warn5 ? (
              <>
                <div
                  className="mt-[4px] flex items-start gap-x-[12px] px-[16px] py-[12px] bg-[#F2F6FC] rounded cursor-pointer"
                  onClick={() => {
                    setWarn5(!warn5);
                    setWarn1(false);
                    setWarn2(false);
                    setWarn3(false);
                    setWarn4(false);
                  }}
                >
                  <img src={radio_on} alt={radio_on} />

                  <span>기타(직접입력)</span>
                </div>

                <input
                  className="mt-[4px] border border-[#9DC4FA] bg-[#F2F6FC] w-full rounded py-[8px] px-[10px] outline-none"
                  placeholder="내용을 입력하세요."
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                />
              </>
            ) : (
              <div
                className="mt-[4px] flex items-start gap-x-[12px] px-[16px] py-[12px] bg-[#F2F6FC] rounded cursor-pointer"
                onClick={() => {
                  setWarn5(!warn5);
                  setWarn1(false);
                  setWarn2(false);
                  setWarn3(false);
                  setWarn4(false);
                }}
              >
                <img src={radio_off} alt={radio_off} />

                <span>기타(직접입력)</span>
              </div>
            )}
          </div>
          <div className="flex gap-x-[8px] button_700 pt-[24px]">
            {reason !== "" ? (
              <span
                className="w-1/2 border rounded border-[#1B5EDC] text-[#1B5EDC] bg-[#FAFCFF] text-center px-[36px] py-[20px] hover:bg-[#F2F6FC] hover:border-[#18428E] hover:text-[#18428E] ease-in-out duration-300 cursor-pointer"
                onClick={GoWarnReport}
              >
                신고하기
              </span>
            ) : (
              <span className="w-1/2 border rounded border-[#679DEE] text-[#679DEE] bg-[#FAFCFF] text-center px-[36px] py-[20px] ">
                신고하기
              </span>
            )}

            <span
              className="w-1/2 border rounded border-[#1B5EDC] text-[#1B5EDC] text-center px-[36px] py-[20px] bg-[#FAFCFF] hover:bg-[#F2F6FC] hover:border-[#18428E] hover:text-[#18428E] ease-in-out duration-300 cursor-pointer"
              onClick={() => {
                setWarning(false);
                setWarnBlock(false);
              }}
            >
              취소
            </span>
          </div>
        </div>
      )}
    </CenterModal>
  );
};

export default WarnPostModal;
