import { useEffect, useRef, useState } from "react";
import { BtnBlack, BtnWhite } from "../../../admin_components/Buttons";
import commonUrl, { api } from "../../../../../data/common";
import { ToastContainer, toast } from "react-toastify";
import Header2 from "../../../admin_components/Header/Header2";
import { useLocation, useNavigate } from "react-router-dom";
import {
  toastCommonProps,
  findToastPosition,
} from "../../../admin_components/Toast";
import { Loading } from "../../../admin_components/Loading";
import dayjs from "dayjs";
import Popup from "reactjs-popup";
import Postcode from "../components/Postcode";
import { handlingError } from "../../../admin_components/Error";

function Modify() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState({ first: true, save: false }); // 첫랜더 로딩,  저장 시 로딩
  const [toastElPosition, setToastElPosition] = useState({ top: 0, left: 0 }); // toast창 위치 값
  // 병원 수정 정보
  const [hospital, setHospital] = useState({
    id: null, // 등록 후 생기는 id
    name: "", // 병원 명
    employeeName: "", // 담당자 이름
    tel: "", // 담당자 휴대폰번호
    address: "", // 주소
    addressDetail: "", // 상세 주소
    email: "", // 이메일 주소
    createdAt: "", // 등록일
    status: "", // 상태 변경
  });
  const [open, setOpen] = useState(false); // 주소찾기 팝업창 오픈 여부
  const telRef = useRef(); // 휴대폰 번호 자동입력 위한 Ref

  // input 값 변경
  const change = (e) => {
    const { name, value } = e.target;

    // 들어온 값이 휴대폰 번호(자동입력 기능)
    if (name === "tel") {
      // 자동작대기 넣기 위한 정규표현식
      const regPhone1 = /^([0-9]{3})$/;
      const regPhone2 = /^([0-9]{3})-([0-9]{4})$/;

      // 삭제중인 경우
      if (hospital.tel.length > value.length) {
        setHospital({ ...hospital, [name]: value });
      }
      // 숫자가 아니고 + "-"도 아니고 + 현재까지의 값이 13자리 넘으면 넣지 말기
      else if (
        (isNaN(value.substring(value.length, value.length - 1)) &&
          value.substring(value.length, value.length - 1) !== "-") ||
        value.length > 13
      ) {
        setHospital({
          ...hospital,
          [name]: value.substring(0, value.length - 1),
        });
      }
      // 값을 저장해도 되면
      else {
        // 길이가 3이고 정규식에 부합 || 길이가 8이고 정규식에 부합 -> 작대기 넣기
        if (
          (value.length === 3 && regPhone1.test(value)) ||
          (value.length === 8 && regPhone2.test(value))
        ) {
          setHospital({ ...hospital, [name]: value + "-" });
        }
        // 그 밖의 경우
        else setHospital({ ...hospital, [name]: value });
      }
    }
    // 휴대폰 번호 아닌 변수가 변한 경우
    else setHospital({ ...hospital, [name]: value });
  };

  // 제줄 전에 제출 형식에 맞는 지 확인
  const checkForSave = () => {
    // 휴대폰 번호 정규식
    const regPhone = /^01([0|1|6|7|8|9])-([0-9]{4})-([0-9]{4})$/;

    // 누락된 칸이 있을 경우
    if (
      hospital.name.trim() === "" ||
      hospital.email.trim() === "" ||
      hospital.tel.trim() === "" ||
      hospital.address.trim() === "" ||
      hospital.employeeName.trim() === ""
    ) {
      toast(
        <p>누락된 항목이 있습니다.</p>,
        toastCommonProps(1500, "top-left", "toast_common toast__normal")
      );
      return false;
    }
    // 휴대폰 번호 형식 안맞는 경우
    else if (!regPhone.test(hospital.tel.trim())) {
      toast(
        <p className="text-sm">휴대폰 번호 형식이 알맞지 않습니다.</p>,
        toastCommonProps(1500, "top-left", "toast_common toast__normal")
      );
      return false;
    }
    // 모두 통과됐을 경우
    else {
      return true;
    }
  };

  // 저장하기 버튼 눌렀을 시
  const save = () => {
    // toast창 위치 설정
    setToastElPosition({
      top: findToastPosition("top"),
      left: findToastPosition("left"),
    });

    if (checkForSave()) {
      // Axios
      async function fetchData() {
        setLoading({ ...loading, save: true });
        const res1 = await api({
          method: "post",
          url: commonUrl.baseURL + "belong",
          data: {
            ...hospital,
            name: hospital.name.trim(), // 검색 위해
            status: Number(hospital.status),
          },
        }).catch((error) => {
          handlingError(error, "top-left", "toast_common toast__normal");
        });
        console.log(res1.data, ">>> Axios belong(수정)");
        // axios 성공시
        if (res1.data.ok) {
          toast(
            <p>저장 되었습니다.</p>,
            toastCommonProps(1500, "top-left", "toast_common toast__normal")
          );
          setTimeout(() => {
            setLoading({ ...loading, save: false });
            navigate(-1);
          }, 1800);
        }
        // axios 실패시
        else {
          setLoading({ ...loading, save: false });
          toast(
            <p>{res1.data.error}</p>,
            toastCommonProps(1500, "top-left", "toast_common toast__normal")
          );
        }
      }
      fetchData();
    }
  };

  // 첫랜더시 정보 불러오기
  useEffect(() => {
    // axios
    async function fetchData() {
      const response = await api({
        method: "get",
        url: commonUrl.baseURL + "belong/" + state.id,
      }).catch((error) => {
        handlingError(error, "top-left", "toast_common toast__normal");
      });
      console.log(response.data, ">>> Axios belong/id");
      setLoading({ ...loading, first: false });
      // axios 성공시
      if (response.data.ok && response.data.belong) {
        const belong = response.data.belong;
        setHospital({
          id: belong.id,
          name: belong.name,
          email: belong.email,
          tel: belong.tel,
          employeeName: belong.employeeName,
          address: belong.address,
          addressDetail: belong.addressDetail,
          createdAt: belong.createdAt,
          status: String(belong.status),
        });
      }
      // axios 실패시
      else {
        toast(
          <p>{response.data.error}</p>,
          toastCommonProps(1500, "top-center", "toast_common toast__normal")
        );
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(hospital);

  return (
    <Header2
      title={[
        { title: "병원 등록/관리", url: -1 },
        { title: "병원 확인/수정", url: 0 },
      ]}
    >
      {/* toast 창 ui 변경 */}
      <style jsx="true">{`
        .Toastify__toast {
          top: ${toastElPosition.top - 20}px;
          left: ${toastElPosition.left - 285}px;
        }
      `}</style>
      <ToastContainer />
      {/* 주소찾기 팝업창 */}
      <Popup
        open={open}
        closeOnDocumentClick
        onClose={() => {
          setOpen(false);
        }}
      >
        <Postcode
          place={hospital}
          setPlace={setHospital}
          onClose={() => {
            setOpen(false);
          }}
        />
      </Popup>
      {/* 병원 확인 / 수정 */}
      <div className="admin flex flex-col grow p-[32px]">
        <div className="h-[80px] min-h-[80px]"></div>
        <label className="text-2xl font-extrabold leading-8 text-[#020A1B] mb-3">
          병원 확인/수정
        </label>
        {loading.first ? (
          <Loading />
        ) : (
          <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] min-w-[975px]">
            {/* 수정사항 등록 표 */}
            <div className="grid grid-cols-[15%,30%,15%,40%] border border-[#E5E6E7]">
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                병원 명
              </label>
              <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                <input
                  type="text"
                  className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-base"
                  name="name"
                  value={hospital.name}
                  onChange={change}
                  placeholder="병원명을 입력해 주세요."
                />
              </div>
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border row-span-2">
                병원 주소
              </label>
              <div className="flex flex-col gap-y-[6px] p-[4px] w-full border border-[#E5E6E7] font-normal box-border row-span-2">
                <div className="flex gap-x-1">
                  <input
                    type="text"
                    className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-base"
                    name="address"
                    onChange={change}
                    value={hospital.address}
                    placeholder="주소를 입력해 주세요."
                  />
                  <BtnBlack
                    context="주소찾기"
                    style={{
                      minHeight: "35px",
                      width: "75px",
                      minWidth: "75px",
                      padding: "0 5px",
                    }}
                    onClick={() => {
                      setOpen(true);
                    }}
                  />
                </div>
                <input
                  type="text"
                  className="flex mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-base"
                  name="addressDetail"
                  onChange={change}
                  value={hospital.addressDetail}
                  placeholder="상세주소를 입력해주세요."
                />
              </div>
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                담당자 이름
              </label>
              <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                <input
                  type="text"
                  className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-base"
                  name="employeeName"
                  onChange={change}
                  value={hospital.employeeName}
                  placeholder="이름을 입력해 주세요."
                />
              </div>
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                담당자 휴대폰번호
              </label>
              <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                <input
                  type="text"
                  className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-base"
                  name="tel"
                  onChange={change}
                  value={hospital.tel}
                  placeholder="예) 010-1234-5678"
                  ref={telRef}
                />
              </div>
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                이메일 주소
              </label>
              <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                <input
                  type="text"
                  className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-base"
                  name="email"
                  onChange={change}
                  value={hospital.email}
                  placeholder="이메일 주소를 입력해 주세요."
                />
              </div>
              {/* 등록일, 상태 변경 */}
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                등록일
              </label>
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                {dayjs(new Date(hospital.createdAt)).format("YYYY-MM-DD")}
              </label>
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                상태변경
              </label>
              <div className="flex items-center justify-evenly p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                <label>
                  <input
                    type="radio"
                    name="status"
                    value="1"
                    onChange={change}
                    className="mr-[5px]"
                    checked={hospital.status === "1"}
                  />
                  비활성
                </label>
                <label>
                  <input
                    type="radio"
                    name="status"
                    value="0"
                    onChange={change}
                    className="mr-[5px]"
                    checked={hospital.status === "0"}
                  />
                  활성
                </label>
              </div>
            </div>
            {/* 목록 & 저장 버튼 */}
            <div className="flex justify-end items-center mt-[18px]">
              <div className="flex gap-x-2">
                <BtnWhite
                  context="목록"
                  onClick={() => {
                    navigate(-1);
                  }}
                  style={{ width: "90px" }}
                  standard="toast_standard"
                />
                <BtnBlack
                  context="저장하기"
                  onClick={save}
                  loading={loading.save}
                  style={{ width: "88px" }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Header2>
  );
}

export default Modify;
