import { useEffect, useState } from "react";
import { Loading } from "../../../admin_components/Loading";
import commonUrl, { api } from "../../../../../data/common";
import { useNavigate } from "react-router-dom";
import { useTable, useSortBy, usePagination } from "react-table";
import { handlingError } from "../../../admin_components/Error";

function HealthDiaryTable({ state }) {
  const [data, setData] = useState([]);
  /*  */
  const [loading, setLoading] = useState(false);

  /******************************* Table UI 관련 변수 & 함수들  ******************************/
  // Table에 들어갈 column명
  const columns = [
    {
      accessor: "idx",
      Header: "NO.",
      width: "7%",
    },
    {
      accessor: "writeDate",
      Header: "작성일",
      width: "15%",
    },
    {
      accessor: "weight",
      Header: "체중(kg)",
      width: "15%",
    },
    {
      accessor: "water",
      Header: "수분 섭취량(ml)",
      width: "15%",
    },
    {
      accessor: "toilet",
      Header: "배변량(ml)",
      width: "15%",
    },
  ];

  // BMI 계산
  // const calculateBMI = (bmi) => {
  //   if (bmi < 18.5) return "(저체중)";
  //   else if (bmi >= 18.5 && bmi <= 22.9) return "(정상)";
  //   else if (bmi >= 23 && bmi <= 24.9) return "(과체중)";
  //   else if (bmi >= 25 && bmi <= 29.9) return "(1단계 비만)";
  //   else if (bmi >= 30 && bmi <= 34.9) return "(2단계 비만)";
  //   else return "(3단계 비만)";
  // };

  // axios로 불러온 데이타 UI에 맞게 가공하여 state에 저장시키는 함수
  const handleData = (data) => {
    return data.map((el, idx) => {
      // const height = el.diary.patient.height;
      // BMI 계산
      // let newBMI = el.diary.weight / ((height / 100) * (height / 100));
      // newBMI = Math.round(Number(newBMI.toFixed(2)) * 10) / 10;

      return {
        idx: data.length - idx,
        id: el.diary.id,
        writeDate: el.diary.writeDate,
        weight: el.diary.weight,
        water: el.totalWater === 0 ? "-" : el.totalWater,
        toilet: el.totalToilet === 0 ? "-" : el.totalToilet,
      };
    });
  };
  /*** 첫 랜더시 정보 불러오기***/
  useEffect(() => {
    // 비동기 함수
    async function fetchData() {
      setLoading(true);
      const response = await api({
        method: "get",
        url: commonUrl.diary + "admin/pid/" + state.id,
      }).catch((error) => {
        handlingError(error);
      });
      // console.log(response.data, ">>> Axios diary + pid + id");

      setData(handleData(response.data.diaryList));

      setLoading(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : data.length !== 0 ? (
        <TableBlue
          columns={columns}
          data={data}
          pageMove={"/admin/main/check/healthdiary"}
          pageSizeNum={5}
          pagination={true}
          state={state}
        />
      ) : (
        <label className=" text-base font-normal leading-[20px] text-[#A0A3AB] mt-2 ">
          등록된 내용이 없습니다.
        </label>
      )}
    </>
  );
}

const TableBlue = ({
  columns,
  data,
  pageMove,
  state, // prevPath 절달
  sorting, // sorting 기능이 필요한 테이블이면 true
  pagination, // pagination이 필요한 테이블이면 true
  showAll, // 검색기능이 있어서 전체보기가 필요한 테이블이면 click(=showAll) 함수
  pageSize, // pageSize 조정
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: pageSize ? pageSize : 10 },
    },
    useSortBy,
    usePagination
  );
  const arrow_left = "/images/admin/carousel_left.svg";
  const arrow_right = "/images/admin/carousel_right.svg";
  const navigate = useNavigate();
  const paginationBtns = new Array(pageCount).fill(0).map((el, idx) => idx + 1);
  let flag = 0;

  // pagination UI
  const paginationUI = () => {
    return (
      <div className="flex justify-center items-center mt-[25px]">
        <button
          className="px-3"
          onClick={() => {
            previousPage();
          }}
          disabled={!canPreviousPage}
        >
          <img
            src={arrow_left}
            alt="arrow_left"
            className="w-[6.4px] h-[11.2px]"
            style={
              !canPreviousPage
                ? {
                    filter:
                      "brightness(0) saturate(100%) invert(48%) sepia(0%) saturate(5417%) hue-rotate(195deg) brightness(134%) contrast(82%)",
                  }
                : {
                    filter:
                      "brightness(0) saturate(100%) invert(3%) sepia(29%) saturate(7499%) hue-rotate(218deg) brightness(99%) contrast(100%)",
                  }
            }
          />
        </button>
        <div className="flex gap-x-1">
          {paginationBtns.map((el, idx) => {
            if (
              idx >= Math.floor(pageIndex / 5) * 5 &&
              idx <= Math.floor(pageIndex / 5) * 5 + 4
            ) {
              return (
                <button
                  key={idx}
                  className="flex justify-center items-center border w-[32px] h-[32px] text-center text-base text-[#020A1B] font-semibold leading-5 rounded-[28px]"
                  onClick={() => {
                    gotoPage(idx);
                  }}
                  style={{
                    borderColor: pageIndex === idx ? "#020A1B" : "transparent",
                  }}
                >
                  {idx + 1}
                </button>
              );
            } else return null;
          })}
        </div>
        <button
          className="px-3"
          onClick={() => {
            nextPage();
          }}
          disabled={!canNextPage}
        >
          <img
            src={arrow_right}
            alt="arrow_right"
            className="w-[6.4px] h-[11.2px]"
            style={
              !canNextPage
                ? {
                    filter:
                      "brightness(0) saturate(100%) invert(48%) sepia(0%) saturate(5417%) hue-rotate(195deg) brightness(134%) contrast(82%)",
                  }
                : {
                    filter:
                      "brightness(0) saturate(100%) invert(3%) sepia(29%) saturate(7499%) hue-rotate(218deg) brightness(99%) contrast(100%)",
                  }
            }
          />
        </button>
      </div>
    );
  };
  return (
    <>
      <div className="flex flex-col w-full">
        <table className="w-full table-fixed">
          <thead {...getTableProps()}>
            {headerGroups.map((headerGroup) => {
              return (
                <tr key={headerGroup.headers}>
                  {headerGroup.headers.map((col, idx) => {
                    if (col.render("Header") === "내용") flag = idx;
                    return (
                      <th
                        key={col.id}
                        className="relative first:rounded-tl-2xl last:rounded-tr-2xl align-middle p-4 bg-[#CBDFFC] text-base text-[#020A1B] leading-[22px] font-semibold"
                        {...col.getHeaderProps(
                          idx === 0
                            ? {
                                style: { width: col.width },
                              }
                            : sorting
                            ? col.getSortByToggleProps({
                                style: { width: col.width },
                              })
                            : {
                                style: { width: col.width },
                              }
                        )}
                      >
                        {col.render("Header")}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, rowIdx) => {
              prepareRow(row);
              return (
                <tr
                  key={row.id}
                  {...row.getRowProps()}
                  className={
                    // 보여주는 행 개수가 홀수이면
                    page.length % 2 !== 0
                      ? rowIdx % 2 === 0
                        ? "cursor-pointer bg-[#F2F6FC] hover:bg-[#DDE9FA]"
                        : "cursor-pointer bg-[#FAFCFF] hover:bg-[#DDE9FA]"
                      : // 보여주는 행 개수가 짝수이면
                      rowIdx % 2 !== 0
                      ? "cursor-pointer bg-[#F2F6FC] hover:bg-[#DDE9FA]"
                      : "cursor-pointer bg-[#FAFCFF] hover:bg-[#DDE9FA]"
                  }
                >
                  {row.cells.map((cell, idx) => {
                    return (
                      <td
                        key={cell.row.id}
                        className={
                          page.length - 1 === rowIdx
                            ? "align-middle p-4 first:rounded-bl-2xl last:rounded-br-2xl border-t border-[#CBDFFC] text-base text-[#020A1B] leading-[22px] font-normal"
                            : "align-middle p-4 border-t border-[#CBDFFC] text-base text-[#020A1B] leading-[22px] font-normal "
                        }
                        {...cell.getCellProps({
                          style: { width: cell.column.width },
                        })}
                        onClick={() => {
                          if (pageMove !== undefined) {
                            navigate(pageMove, {
                              state: {
                                data: {
                                  ...row.original,
                                  detailPageId: state.id,
                                },
                              },
                            });
                          }
                        }}
                      >
                        {
                          <div
                            className="w-full text-center truncate"
                            id={cell.column.Header}
                          >
                            {flag === idx
                              ? cell.value.toString().replace(/<[^>]*>/g, "")
                              : cell.render("Cell")}
                          </div>
                        }
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* pagenation */}
        {pagination && page.length !== 0 ? (
          showAll ? (
            <div className="flex justify-between">
              <p
                className="mx-[5px] mt-[25px] min-w-[65px] cursor-pointer underline underline-offset-4 font-bold"
                onClick={showAll}
              >
                전체보기
              </p>
              {paginationUI()}
              <div className="mx-[5px] mt-[25px] min-w-[65px]"></div>
            </div>
          ) : (
            paginationUI()
          )
        ) : null}
      </div>
    </>
  );
};

export default HealthDiaryTable;
