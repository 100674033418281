import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useMainContext } from "../../../../contexts/Provider";
import dayjs from "dayjs";
/*component*/
import Check1 from "./components/Check1";
import Check2 from "./components/Check2";
import Check3 from "./components/Check3";
import Check4 from "./components/Check4";
import Check5 from "./components/Check5";
import Check6 from "./components/Check6";
import Check7 from "./components/Check7";
import Check8 from "./components/Check8";
import Check9 from "./components/Check9";
import Check10 from "./components/Check10";
import Check11 from "./components/Check11";
import Close from "../../mobilecomponent/Close";
import LoadingBar from "./components/LoadingBar";
const CheckId = () => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const paramId = Number(useParams().id);
  const { loginInput } = useMainContext();
  const userPid = loginInput.id;
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [errorMessage, setErrorMessage] = useState("");
  const [checkInfo, setCheckInfo] = useState({
    writer: "",
    symptom: [],
    symptomNote: "",
    weight: "",
    changeDate: dayjs(selectedDate).format("YYYY-MM-DD"),
    changeCycle: "",
    jangruComplicationsOX: "",
    skinComplicationsOX: "",
    dehydrationOX: "",
    imageURL: [],
    imageText: "",
    question: "",
    patient: userPid,
  });

  const onChangeCheck = (e) => {
    const { name, value } = e.target;

    const threeReg = /^\d{0,3}$/;
    const threeReg2 = /^[1-9]$/;
    const threeoneReg = "^([0-9]{0,3}[.][0-9]{0,1})?$";
    const threetwoReg = "^([0-9]{0,5}[.][0-9]{2})?$";
    if (name === "weight") {
      if ((value.match(threeoneReg) || value.match(threeReg)) && value !== "") {
        setErrorMessage("");
        setCheckInfo({ ...checkInfo, [name]: value });
      } else {
        setErrorMessage("정확한 체중을 입력해주세요.");
        if (value === "" || value === 0) {
          setCheckInfo({ ...checkInfo, [name]: "" });
          console.log(value);
        } else if (value === threetwoReg) {
          setCheckInfo({ ...checkInfo, [name]: Number(value).toFixed(1) });
        }
      }
    } else if (name === "changeCycle") {
      setCheckInfo({ ...checkInfo, changeCycle: value });
      if (e.target.value.match(threeReg2) && e.target.value !== "") {
        setErrorMessage("");
      } else {
        setErrorMessage("1부터 9까지의 숫자를 입력해 주세요.");
      }
    } else if (name === "imageText" || name === "question") {
      setCheckInfo({ ...checkInfo, [name]: value.slice(0, 200) });
    } else {
      setCheckInfo({ ...checkInfo, [name]: value });
    }
  };

  window.onpopstate = function () {
    if (pathname === "/check/11" || pathname === "/check/10") {
      navigate("/main");
    }
  };
  useEffect(() => {
    let pageOpen = false;
    if (!pageOpen) {
      if (
        loginInput.id === null ||
        loginInput.id === undefined ||
        loginInput.id === ""
      ) {
        navigate("/main");
      } else {
        setErrorMessage("");
        if (pathname !== "/check/1" && checkInfo.writer === "") {
          navigate("/main");
        }
      }
    }
    return () => {
      pageOpen = true;
    };
  }, [loginInput.id, navigate]);

  return (
    <>
      {(pathname === "/check10" ||
        pathname === "/check/11" ||
        pathname === "/check/9") &&
      checkInfo.writer === "" ? (
        ""
      ) : (
        <div
          className={
            pathname === "/check/10"
              ? "for__scroll h-screen bg-[#F1F2F3]  relative overflow-y-auto"
              : "for__scroll h-screen bg-[#FAFCFF]  relative overflow-y-auto"
          }
        >
          {paramId === 10 ? (
            <Close title={"작성 내용 보기"} />
          ) : paramId === 11 ? (
            <Close title={"작성 완료"} />
          ) : (
            <Close title={"장루 점검하기"} />
          )}
          <LoadingBar />
          {paramId === 1 ? (
            <Check1
              checkInfo={checkInfo}
              setCheckInfo={setCheckInfo}
              onChangeCheck={onChangeCheck}
            />
          ) : paramId === 2 ? (
            <Check2 checkInfo={checkInfo} setCheckInfo={setCheckInfo} />
          ) : paramId === 3 ? (
            <Check3
              checkInfo={checkInfo}
              onChangeCheck={onChangeCheck}
              errorMessage={errorMessage}
            />
          ) : paramId === 4 ? (
            <Check4
              checkInfo={checkInfo}
              onChangeCheck={onChangeCheck}
              setCheckInfo={setCheckInfo}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              errorMessage={errorMessage}
            />
          ) : paramId === 5 ? (
            <Check5 checkInfo={checkInfo} setCheckInfo={setCheckInfo} />
          ) : paramId === 6 ? (
            <Check6 checkInfo={checkInfo} setCheckInfo={setCheckInfo} />
          ) : paramId === 7 ? (
            <Check7 checkInfo={checkInfo} setCheckInfo={setCheckInfo} />
          ) : paramId === 8 ? (
            <Check8
              checkInfo={checkInfo}
              setCheckInfo={setCheckInfo}
              onChangeCheck={onChangeCheck}
            />
          ) : paramId === 9 ? (
            <Check9 checkInfo={checkInfo} onChangeCheck={onChangeCheck} />
          ) : paramId === 10 ? (
            <Check10 checkInfo={checkInfo} setCheckInfo={setCheckInfo} />
          ) : paramId === 11 ? (
            <Check11 checkInfo={checkInfo} setCheckInfo={setCheckInfo} />
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};

export default CheckId;
