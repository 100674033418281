import React from "react";
const StomaPage = () => {
  const image = "/images/mo/jangrucont/장루.png";
  return (
    <>
      <img src={image} alt="image" className="w-full" />

      <div className="pt-[16px] body_1_400_jangrucont">
        <div className=" subtitle_1_700">1&#41; 회장루</div>
        <span className="inline-block">1. 변이 묽은 상태로 배출</span>
        <div className="flex ">
          <span className="inline-block">2. </span>

          <span className="inline-block">
            소화효소가 포함되어 피부손상 시킬 수 있으므로 피부 관리에 주의해야
            합니다.
          </span>
        </div>
        <div className="subtitle_1_700 pt-[32px]">2&#41; 상행결장루</div>
        <span className="inline-block">1. 변이 묽은 상태로 배출</span>
        <div className="flex ">
          <span className="inline-block">2. </span>
          <span className="inline-block">
            소화효소가 포함되어 피부손상 시킬 수 있으므로 피부 관리에 주의해야
            합니다.
          </span>
        </div>
        <div className="subtitle_1_700 pt-[32px]">3&#41; 횡행결장루</div>
        <span className="inline-block">
          변이 굳은 편이나 음식물에 따라 묽은 변이 배출되기도 합니다.
        </span>
        <div className="subtitle_1_700 pt-[32px]">4&#41; 하행/에스 결장루</div>
        <span className="inline-block">1. 변이 굳은 상태로 배출</span>
        <div className="flex ">
          <span className="inline-block">2.&nbsp;</span>
          <span className="inline-block">
            변비 예방을 위해 충분한 수분과 섬유소 섭취를 합니다.
          </span>
        </div>
      </div>
    </>
  );
};

export default StomaPage;
