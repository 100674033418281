import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Check5 = ({ checkInfo, setCheckInfo }) => {
  const navigate = useNavigate();
  useEffect(() => {
    let pageOpen = false;

    if (!pageOpen) {
    }
    return () => {
      pageOpen = true;
    };
  }, []);
  const onClickState1 = (e) => {
    setCheckInfo({ ...checkInfo, dehydrationOX: "O" });
  };
  const onClickState2 = (e) => {
    setCheckInfo({ ...checkInfo, dehydrationOX: "X" });
  };
  const goCheck6 = () => {
    navigate(`/check/6`);
  };
  const goCheck8 = () => {
    navigate(`/check/8`);
  };
  const coloredBg =
    "mb-[8px] border rounded w-full px-[24px] py-[16px] cursor-pointer bg-[#CBDFFC] border-[#1B5EDC] body_1_600";
  const noneBg =
    "mb-[8px] border rounded w-full px-[24px] py-[16px] cursor-pointer bg-[#F2F6FC] border-[#9DC4FA] active:bg-[#CBDFFC]";
  return (
    <>
      <div className="pt-[94px]">
        <div className="px-[24px] pb-[80px]">
          <h1 className="h3_800 wordbreak-keepall">
            지난 일주일 동안 잦은 목마름, 소변량 감소, 소변색 진해짐, 피부 탄력
            저하, 기운이 없고 피곤함 등의 증상이 있으셨나요?
          </h1>
        </div>

        <div className="px-4 pt-4">
          <div
            className={checkInfo.dehydrationOX === "O" ? coloredBg : noneBg}
            onClick={onClickState1}
          >
            ⭕️ &nbsp;있다
          </div>
          <div
            className={checkInfo.dehydrationOX === "X" ? coloredBg : noneBg}
            onClick={onClickState2}
          >
            ❌ &nbsp;없다
          </div>
        </div>
      </div>
      {checkInfo.dehydrationOX !== "" ? (
        <div className="fixed  bottom-0 left-0 w-full  flex ">
          <div
            className="border w-2/5 py-[20px] flex justify-center items-center cursor-pointer
                 bg-[#FAFCFF] active:bg-[#F2F6FC] border border-[#679DEE] active:border-[#18428E] inline-block   button_700 cursor-pointer  ease-in-out duration-300 text-[#1B5EDC] active:text-[#18428E]"
            onClick={goCheck6}
          >
            이전
          </div>
          <div
            className=" w-3/5 py-[20px]  flex justify-center items-center cursor-pointer bg-[#1B5EDC] text-white button_700 active:bg-[#18428E] ease-in-out duration-300"
            onClick={goCheck8}
          >
            다음
          </div>
        </div>
      ) : (
        <div className="fixed  bottom-0 left-0 w-full  flex ">
          <div
            className="border w-2/5 py-[20px] flex justify-center items-center cursor-pointer
               bg-[#FAFCFF] active:bg-[#F2F6FC] border border-[#679DEE] active:border-[#18428E] inline-block button_700   cursor-pointer  ease-in-out duration-300 text-[#1B5EDC] active:text-[#18428E]"
            onClick={goCheck6}
          >
            이전
          </div>
          <div className=" w-3/5 py-[20px]  flex justify-center items-center bg-[#9DC4FA] button_700 text-white ease-in-out duration-300">
            다음
          </div>
        </div>
      )}
    </>
  );
};

export default Check5;
