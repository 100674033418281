import React from "react";

const MyInfoGoback = ({
  showManageInfo,
  showUseTerms,
  showPersonalInfo,
  showNotification,
  showWrite,
  title,
  title2,
}) => {
  const leftarrow = "/images/mo/leftarrow.svg";
  const showCurrentPage = [
    showManageInfo,
    showUseTerms,
    showPersonalInfo,
    showNotification,
  ];

  return (
    <>
      {showCurrentPage?.map((showPage, idx) =>
        showPage !== undefined ? (
          <div
            key={idx}
            className="fixed px-[8px] top-0 left-0 w-full flex justify-between items-center bg-[#FAFCFF] border-b subtitle_1_400"
          >
            <img
              className="inline-block cursor-pointer "
              src={leftarrow}
              alt="leftarrow"
              onClick={showPage}
            />
            <span className="inline-block ">{title}</span>
            <span className="inline-block w-[48px] h-[48px]"></span>
          </div>
        ) : null
      )}
    </>
  );
};

export default MyInfoGoback;
