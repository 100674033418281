import { useNavigate, useLocation } from "react-router-dom";
import { useMainContext } from "../../../contexts/Provider";
const MainIcons = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const homeicon = "/images/mo/home.svg";
  const homeiconBL = "/images/mo/home_blue.svg";
  const reporticon = "/images/mo/report.svg";
  const reporticonBL = "/images/mo/report_blue.svg";
  const communityicon = "/images/mo/comunity.svg";
  const communityiconBL = "/images/mo/comunity_blue.svg";
  const showall = "/images/mo/showall.svg";
  const showallBL = "/images/mo/showall_blue.svg";
  const myinfoicon = "/images/mo/myinfo.svg";
  const myinfoiconBL = "/images/mo/myinfo_blue.svg";
  const { tabWhat } = useMainContext();

  return (
    <>
      <div className="fixed bottom-0 left-0 w-full z-2 bg-[#FAFCFF]">
        <div className="relative flex flex-col max-w-md mx-auto overflow-hidden antialiased text-gray-600">
          <div className="w-full flex items-center justify-between gap-x-2 px-[38px] py-[15px] bg-[#FAFCFF] border-t-[1px]">
            {location.pathname === "/main" ? (
              <img
                src={homeiconBL}
                alt="home"
                className="w-[32px] h-[32px] inline-block cursor-pointer"
                onClick={() => {
                  tabWhat.current = "check";
                  navigate("/main");
                }}
              />
            ) : (
              <img
                src={homeicon}
                alt="home"
                className="w-[32px] h-[32px] inline-block cursor-pointer"
                onClick={() => {
                  tabWhat.current = "check";
                  navigate("/main");
                }}
              />
            )}
            {location.pathname === "/alljangru" ? (
              <img
                src={showallBL}
                alt="all"
                className="w-[32px] h-[32px] inline-block cursor-pointer"
                onClick={() => {
                  tabWhat.current = "check";
                  navigate("/alljangru");
                }}
              />
            ) : (
              <img
                src={showall}
                alt="all"
                className="w-[32px] h-[32px] inline-block cursor-pointer"
                onClick={() => {
                  tabWhat.current = "check";
                  navigate("/alljangru");
                }}
              />
            )}

            {location.pathname === "/report" ? (
              <img
                src={reporticonBL}
                alt="report"
                className="w-[32px] h-[32px] inline-block cursor-pointer"
                onClick={() => {
                  navigate("/report");
                }}
              />
            ) : (
              <img
                src={reporticon}
                alt="report"
                className="w-[32px] h-[32px] inline-block cursor-pointer"
                onClick={() => {
                  tabWhat.current = "check";
                  navigate("/report");
                }}
              />
            )}
            {/* {location.pathname === "/community" ||
            location.pathname === "/community/notice" ? (
              <img
                src={communityiconBL}
                alt="community"
                className="w-[32px] h-[32px] inline-block cursor-pointer"
                onClick={() => {
                  tabWhat.current = "check";
                  navigate("/community");
                }}
              />
            ) : (
              <img
                src={communityicon}
                alt="community"
                className="w-[32px] h-[32px] inline-block cursor-pointer"
                onClick={() => {
                  tabWhat.current = "check";
                  navigate("/community");
                }}
              />
            )} */}
            {location.pathname === "/myinfo" ||
            location.pathname === "/myinfo/appuse" ||
            location.pathname === "/myinfo/notice" ? (
              <img
                src={myinfoiconBL}
                alt="myinfo"
                className="w-[32px] h-[32px] inline-block cursor-pointer"
                onClick={() => {
                  tabWhat.current = "check";
                  navigate("/myinfo");
                }}
              />
            ) : (
              <img
                src={myinfoicon}
                alt="myinfo"
                className="w-[32px] h-[32px] inline-block cursor-pointer"
                onClick={() => {
                  navigate("/myinfo");
                  tabWhat.current = "check";
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainIcons;
