// 휴대폰 번호 입력시 작대기 자동입력
const autoInputPhone = (
  targetInState,
  name,
  value,
  setState,
  state,
  phoneRef
) => {
  // targetInState: state들 중의 폰 번호를 가지고 있는 변수명
  // value: input event의 value
  // setState:  폰번호를 가진 setState
  // state: 폰번호를 포함하는 state 이름
  // phoneRef: 폰번호 입력 input의 ref

  const regPhone1 = /^([0-9]{3})$/;
  const regPhone2 = /^([0-9]{3})-([0-9]{4})$/;

  // 삭제중인 경우
  if (targetInState.length > value.length) {
    setState({ ...state, [name]: value });
    return;
  }
  // 숫자 아니면
  if (
    (isNaN(value.substring(value.length, value.length - 1)) &&
      value.substring(value.length, value.length - 1) !== "-") ||
    value.length > 13
  ) {
    phoneRef.current.value = value.substring(0, value.length - 1);
    return;
  }
  // 작대기 자동 넣기
  if (value.length === 3 && regPhone1.test(value)) {
    phoneRef.current.value = value + "-";
  } else if (value.length === 8 && regPhone2.test(value)) {
    phoneRef.current.value = value + "-";
  } else {
    // 숫자 이외의 문자를 제거합니다.
    const cleaned = ("" + phoneRef.current.value).replace(/\D/g, "");
    // 숫자를 그룹으로 나누어 형식을 맞춥니다.
    const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);

    // 하이픈(-)을 추가하여 반환합니다.
    if (match) {
      // setClient({ ...client, phone: `${match[1]}-${match[2]}-${match[3]}` });
      phoneRef.current.value = `${match[1]}-${match[2]}-${match[3]}`;
    }
  }
  setState({ ...state, [name]: phoneRef.current.value });
};

export default autoInputPhone;
