import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../../contexts/Provider";
import common, { api } from "../../../../data/common";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { toast, ToastContainer } from "react-toastify";
import "swiper/css";
import PhotoAlertModal from "../HealthDiary/component/PhotoAlertModal";
import BasicLoading from "../../mobilecomponent/BasicLoading";

SwiperCore.use([Autoplay, Pagination]);

const Write = () => {
  const postingid = useLocation().state;
  const leftarrow = "/images/mo/leftarrow.svg";
  const defaultimage = "/images/mo/defaultimage.svg";
  const camera = "/images/mo/camera.svg";
  const closeicon = "/images/mo/closebasic.svg";
  const navigate = useNavigate();
  const [photoAlert, setPhotoAlert] = useState(false);

  const [contents, setContents] = useState("");
  // 기존 포스트 수정시 원래 저장되어 있던 이미지(photoURL)
  const [originImages, setOriginImages] = useState([]);
  // 새 포스트 작성 혹은 기존 포스트 수정시 새로 추가하여 저장할 이미지(미리보기용)
  const [loadedImages, setLoadedImages] = useState([]);
  // 새 포스트 작성 혹은 기존 포스트 수정시 새로 추가하여 저장할 이미지(파일)
  const [fileArray, setFileArray] = useState([]);

  const { loginInput } = useMainContext();
  // console.log(originImages, loadedImages, fileArray);
  const [user, setUser] = useState([]);
  const [userProfile, setUserProfile] = useState();
  const userPid = loginInput.id;

  const [ready, setReady] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (
      contents === "" &&
      originImages.length === 0 &&
      loadedImages.length === 0
    ) {
      setReady(false);
    } else {
      setReady(true);
    }
  }, [contents, originImages, loadedImages]);

  const onChangeWrite = (e) => {
    const { name, value } = e.target;
    let contents = value.replaceAll("<br>", "\r\n");
    setContents(contents);
  };

  const submitWriteData = () => {
    setReady(false);
    setSaving(true);
    const newImage = {
      contents: contents,
      photoURL: fileArray.map((file) => file.lastModified + file.name),
      public: 0,
      patient: userPid,
    };

    const editImage = {
      id: postingid,
      contents: contents,
      photoURL: originImages.concat(
        fileArray.map((file) => file.lastModified + file.name)
      ),
      public: 0,
      patient: userPid,
    };

    const ToastAndNavigate = ({ commupost }) => {
      toast(`👏 저장되었습니다.`, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        limit: 1,
        className: "transparent toastbottom",
      });
      let start = setTimeout(() => {
        setSaving(false);
        navigate("/community/post", {
          state: commupost.data.messageNum,
        });
      }, 1000);
      return () => {
        clearTimeout(start);
      };
    };
    const ToastAndNavigateGetPosting = () => {
      toast(`👏 저장되었습니다.`, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        limit: 1,
        className: "transparent toastbottom",
      });
      let start = setTimeout(() => {
        setSaving(false);
        navigate("/community/post", {
          state: postingid,
        });
      }, 1000);
      return () => {
        clearTimeout(start);
      };
    };

    if (!postingid && fileArray.length > 0) {
      // 새글 포스팅, 사진있는 경우
      for (let i = 0; i < fileArray.length; i++) {
        let formData = new FormData();
        formData.append("file", fileArray[i]);
        formData.append("name", fileArray[i].lastModified + fileArray[i].name);

        //서버에저장
        api.post(common.baseURL + "upload/oneNew", formData).then((awsres) => {
          console.log(awsres);
          if (awsres.data.ok === true) {
            i = i + 1;
            if (i === fileArray.length) {
              api
                .post(common.baseURL + "community/posting", newImage)
                .then((commupost) => {
                  console.log(commupost);
                  if (commupost.data.ok === true) {
                    ToastAndNavigate({ commupost });
                  }
                })
                .catch((error) => console.log(error));
            }
          }
        });
      }
    } else if (!postingid && fileArray.length === 0) {
      // 새글 포스팅, 사진없는 경우
      api
        .post(common.baseURL + "community/posting", newImage)
        .then((commupost) => {
          console.log(commupost);
          if (commupost.data.ok === true) {
            ToastAndNavigate({ commupost });
          }
        })
        .catch((error) => console.log(error));
    } else if (fileArray.length > 0) {
      // 수정후 새로 업로드할 사진이 있을 경우
      for (let i = 0; i < fileArray.length; i++) {
        let formData = new FormData();
        formData.append("file", fileArray[i]);
        formData.append("name", fileArray[i].lastModified + fileArray[i].name);

        api.post(common.baseURL + "upload/oneNew", formData).then((awsres) => {
          if (awsres.data.ok === true) {
            i = i + 1;
            if (i === fileArray.length) {
              api
                .post(common.baseURL + "community/posting", editImage)
                .then((commupost) => {
                  console.log(commupost);
                  if (commupost.data.ok === true) {
                    ToastAndNavigateGetPosting();
                  }
                })
                .catch((error) => console.log(error));
            }
          }
        });
      }
    } else {
      // 수정 후 새로 업로드할 사진이 없는 경우
      api
        .post(common.baseURL + "community/posting", editImage)
        .then((commupost) => {
          console.log(commupost);
          if (commupost.data.ok === true) {
            ToastAndNavigateGetPosting();
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const setTimeoutScroll = () => {
    const forScrollEl = document.querySelector(".for__scroll");
    forScrollEl.scroll(0, forScrollEl.clientHeight);
    if (forScrollEl.scrollHeight >= forScrollEl.clientHeight) {
      console.log("true");
      console.log(forScrollEl.scrollHeight, forScrollEl.clientHeight);
      forScrollEl.scroll(0, forScrollEl.scrollHeight);
    }
    // const onScroll = () => {
    //   if (forScrollEl.scrollHeight >= forScrollEl.clientHeight) {
    //     console.log("true");
    //     forScrollEl.scroll(0, forScrollEl.scrollHeight);
    //   }
    // };
    // window.addEventListener("scroll", onScroll);
  };
  const imageHandleChange = async (e) => {
    let imageArr = [];
    if (
      e.target.files?.length + originImages?.length + loadedImages?.length >
      5
    ) {
      setPhotoAlert(true);
    } else {
      setPhotoAlert(false);

      await Promise.all(
        Array.from(e.target.files).map(async (image) => {
          let reader = new FileReader();
          reader.readAsDataURL(image);
          imageArr.push(image);
          reader.onload = (e) => {
            setLoadedImages((current) => [...current, e.target.result]);
          };
        })
      );

      setFileArray([...fileArray, ...imageArr]);
    }

    setTimeoutScroll();
  };

  const deletePosting = (el, index, type) => {
    if (type === "origin") {
      // 기존 글에서 불러온 사진(미리보기 포함) 삭제
      originImages.splice(index, 1);
      setOriginImages([...originImages]);
    } else {
      // 새로업로드할 사진(미리보기 포함) 삭제
      loadedImages.splice(index, 1);
      fileArray.splice(index, 1);
      setLoadedImages([...loadedImages]);
      setFileArray([...fileArray]);
    }
  };

  const goback = () => {
    navigate(-1);
  };

  useEffect(() => {
    const getUser = () => {
      api.get(common.baseURL + "patient/Id/" + loginInput.id).then((res) => {
        if (res.data.ok === true) {
          setUser(res.data.patient);
          setUserProfile(res.data.patient.profileURL);
        }
      });
    };
    const getCommunityPosting = () => {
      api
        .get(common.baseURL + `community/posting/${postingid}/${loginInput.id}`)
        .then((res) => {
          if (res.data.ok === true) {
            setContents(res.data.posting.contents);
            setOriginImages(res.data.posting.photoURL);
          }
        })
        .catch((error) => console.log(error));
    };

    getUser();
    if (!userPid) {
      navigate("/main", { replace: true });
    }
    if (postingid) {
      // postingid 가 있으면 기존글 수정하는 화면이므로, 기존 포스트를 불러와서 contens 와 originImages에 넣어줌
      getCommunityPosting();
    }
  }, []);

  return (
    <>
      {saving ? <BasicLoading /> : null}
      <div className="h-screen bg-[#FAFCFF] ">
        <div className=" fixed top-0 left-0 w-full z-2 bg-[#FAFCFF] subtitle_1_400 ">
          <div className="w-full border-b-[1px] flex justify-between items-center pl-[8px] pr-[24px] bg-[#FAFCFF]">
            <img
              className="inline-block cursor-pointer"
              src={leftarrow}
              alt="leftarrow"
              onClick={goback}
            />
            <h1 className="pl-4 ">작성하기</h1>

            {!ready ? (
              <span className="inline-block bg-[#9DC4FA] px-[14px] py-[4px] subtitle_2 text-white rounded-full ">
                저장
              </span>
            ) : (
              <span
                className="inline-block bg-[#1B5EDC] hover:bg-[#18428E]  ease-in-out duration-300 px-[14px] py-[4px] subtitle_2 text-white rounded-full cursor-pointer"
                onClick={submitWriteData}
              >
                저장
              </span>
            )}
          </div>
        </div>
        <div className="relative h-screen overflow-y-auto for__scroll">
          <div className="pt-[66px] px-[24px] pb-[16px]">
            <div className="flex">
              <img
                src={
                  userProfile
                    ? common.networkImg + encodeURIComponent(userProfile)
                    : defaultimage
                }
                alt="userprofile"
                className="w-[24px] h-[24px] rounded-full border"
              />
              <span className="inline-block pl-[8px] caption_1_600">
                {user.name}
              </span>
            </div>
            <div className="pt-[19px] flex flex-col">
              {originImages?.map((el, index) => (
                <div
                  className="mb-[8px] w-full flex items-end rounded-sm overflow-hidden relative "
                  key={index}
                >
                  <span
                    className="w-[24px] h-[24px] bg-[#E5E6E7] rounded-full absolute top-[12px] right-[12px] 
                    flex justify-center items-center
                    z-1 cursor-pointer"
                    onClick={() => {
                      deletePosting(el, index, `origin`);
                    }}
                  >
                    <img
                      src={closeicon}
                      alt="closeicon"
                      className="relative "
                    />
                  </span>
                  <img
                    src={common.networkImg + encodeURIComponent(el)}
                    alt={el}
                    className="w-full"
                  />
                </div>
              ))}
              {loadedImages?.map((el, index) => (
                <div className="mb-[8px] w-full  relative " key={index}>
                  <span
                    className="w-[24px] h-[24px] bg-[#E5E6E7] rounded-full absolute top-[12px] right-[12px] 
                    flex justify-center items-center
                    z-1 cursor-pointer"
                    onClick={() => {
                      deletePosting(el, index, `loaded`);
                    }}
                  >
                    <img src={closeicon} alt="closeicon" className="relative" />
                  </span>
                  <img src={el} alt={el} className="w-full" />
                </div>
              ))}
            </div>
            <textarea
              className=" w-full h-[200px] min-h-[100px] pt-[16px] focus:outline-none bg-transparent"
              placeholder="내용을 입력해주세요.(500자 내외)"
              name="contents"
              value={contents}
              onChange={onChangeWrite}
            />
          </div>
        </div>

        <div className="fixed z-1 bottom-0 py-[24px] left-0 w-full bg-[#FAFCFF] ">
          <div className="flex items-center gap-x-[8px] max-w-md mx-auto px-[24px]">
            <div className="bg-[#F1F2F3] min-w-[285px] w-[500px] p-[8px] caption_1_400 flex justify-between">
              <span>사진을 첨부할 수 있어요!</span>

              <span className="text-black caption_1_600">
                {originImages.length + loadedImages.length} / 5
              </span>
            </div>
            <input
              type="file"
              accept=".jpg,.png,.jpeg"
              multiple
              id="file"
              hidden
              onChange={imageHandleChange}
            />
            <div
              className="cursor-pointer label-holder"
              onClick={setTimeoutScroll}
            >
              <label htmlFor="file" className="label">
                <div className="w-[34px] h-[34px] border border-[#999CA3] flex justify-center items-center">
                  <img src={camera} alt="camera" />
                </div>
              </label>
            </div>
          </div>
        </div>
        {/* ) : (
          ""
        )} */}
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        bodyClassName={() =>
          "w-[175px] p-3 rounded-full overflow-hidden bg-[#CBDFFC] text-black text-sm font-normal"
        }
      ></ToastContainer>
      {photoAlert ? <PhotoAlertModal setPhotoAlert={setPhotoAlert} /> : ""}
    </>
  );
};

export default Write;
