import React from "react";
import { useMainContext } from "../../../../../contexts/Provider";
import { useNavigate } from "react-router-dom";
import CenterModal from "../../../mobilecomponent/CenterModal";

import common, { api } from "../../../../../data/common";
const LogoutModal = ({ showLogout }) => {
  const { setLoginInput, loginInput, setMobileHistory } = useMainContext();
  const navigate = useNavigate();

  const onLogout = () => {
    api
      .post(common.baseURL + "patient/logout/" + loginInput.id)
      .then((res) => {
        if (res.data.ok === true) {
          console.log(res);
          setLoginInput({
            patientID: "",
            id: "",
            name: "",
            nickName: "",
            createdAt: "",
            belongid: "",
            fcmToken: loginInput.fcmToken,
            isLoggedIn: false,
          });
          window.localStorage.clear();
          setMobileHistory([]);

          window.sessionStorage.removeItem("mobileHistory");
          window.sessionStorage.removeItem("mobhistoryPrevIdx");

          navigate("/login", { replace: true });
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <CenterModal>
      <span>로그아웃 하시겠습니까?</span>
      <div className="flex gap-[8px] pt-[24px] w-full">
        <span
          className="w-1/2 py-[16px] bg-[#FAFCFF] border border-[#1B5EDC] rounded-sm text-[#1B5EDC] hover:bg-[#F2F6FC] hover:text-[#18428E] hover:border-[#18428E] ease-in-out duration-300 font-semibold flex justify-center items-center cursor-pointer"
          onClick={showLogout}
        >
          취소
        </span>
        <span
          className="w-1/2 py-[16px] bg-[#1B5EDC] text-white hover:bg-[#18428E] ease-in-out duration-300 rounded-sm flex justify-center items-center font-semibold cursor-pointer"
          onClick={onLogout}
        >
          로그아웃
        </span>
      </div>
    </CenterModal>
  );
};

export default LogoutModal;
