import React, { useState, useEffect } from "react";

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import common, { api } from "../../../../../data/common";
import { useMainContext } from "../../../../../contexts/Provider";

const SaveList = ({ post }) => {
  const navigate = useNavigate();
  const { loginInput } = useMainContext();
  const [thumbupNum, setThumbupNum] = useState(null);
  const [myThumbup, setMyThumbup] = useState(null);
  const messagebol = "/images/mo/messagebol.svg";
  const cheerupicon = "/images/mo/cheerup.svg";
  const cheerupicon_col = "/images/mo/cheerup_col.svg";
  const defaultimage = "/images/mo/defaultimage.svg";

  const cheerUpThumb = () => {
    let body1 = {
      status: 0,
      posting: post.posting.id,
      patient: loginInput.id,
    };
    let body2 = {
      status: 1,
      posting: post.posting.id,
      patient: loginInput.id,
    };
    if (myThumbup) {
      //응원취소
      api
        .post(common.baseURL + "community/cheerup", body1)
        .then((res) => {
          if (res.data.ok) {
            api
              .get(
                common.baseURL +
                  `community/posting/${post.posting.id}/${loginInput.id}`
              )

              .then((res) => {
                if (res.data.ok) {
                  setThumbupNum(res.data.cheerupList.length);
                  setMyThumbup(
                    res.data.cheerupList.find(
                      (one) => one.patient?.id === loginInput.id
                    )
                  );
                }
              })
              .catch((err) => console.log(err));
          }
        })
        .catch((err) => console.log(err));
    } else {
      api
        .post(common.baseURL + "community/cheerup", body2) //응원함
        .then((res) => {
          if (res.data.ok) {
            api

              .get(
                common.baseURL +
                  `community/posting/${post.posting.id}/${loginInput.id}`
              )
              .then((res) => {
                if (res.data.ok) {
                  setThumbupNum(res.data.cheerupList.length);
                  setMyThumbup(
                    res.data.cheerupList.find(
                      (one) => one.patient?.id === loginInput.id
                    )
                  );
                }
              })
              .catch((err) => console.log(err));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (post.posting?.cheerups) {
      setThumbupNum(post.posting?.cheerups.length);
      setMyThumbup(
        post.posting?.cheerups.find((one) => one.patient?.id === loginInput.id)
      );
    }
  }, [post.posting?.cheerups, loginInput.id]);

  ///댓글 status 0일때 보여지게 수정
  return (
    <div
      className="bg-[#FAFCFF] cursor-pointer px-[24px] py-[24px] mb-[8px]"
      onClick={() => {
        navigate("/community/post", {
          state: post.posting.id,
        });
      }}
    >
      <div>
        <div className="flex border-b pb-[16px] justify-between items-center">
          <div className="flex">
            {post.posting.patient?.profileURL ? (
              <span
                className="border rounded-full w-[40px] h-[40px]
          flex justify-center items-center overflow-hidden"
              >
                <img
                  src={
                    common.networkImg +
                    encodeURIComponent(post.posting.patient?.profileURL)
                  }
                  alt={post.posting.patient?.profileURL}
                />
              </span>
            ) : (
              <span className="border rounded-full flex justify-center items-center h-[40px] w-[40px] bg-no-repeat bg-center">
                <img src={defaultimage} alt="defaultimage" />
              </span>
            )}
            <div className="flex flex-col justify-center pl-[16px]">
              <span className="subtitle_2">
                {post.posting.patient?.nickName}
              </span>
              <span className="text-[#676C76] caption_2">
                {dayjs(post.posting.createdAt).format("YYYY-MM-DD")}
              </span>
            </div>
          </div>
        </div>
        <div className="pt-[20px]">
          {post.posting?.photoURL?.length > 0 ? (
            <div className="w-full h-[220px] overflow-hidden rounded relative bg-[#f1f2f3] flex justify-center">
              <span className="bg-[#343B48] text-white rounded-full inline-bock w-[30px] h-[26px] flex justify-center items-center absolute top-[12px] right-[12px]">
                {post.posting?.photoURL?.length}
              </span>
              <img
                src={
                  common.networkImg +
                  encodeURIComponent(post.posting?.photoURL[0])
                }
                alt="커뮤니티 이미지"
                className="h-full"
              />
            </div>
          ) : (
            ""
          )}
          <div className="pt-[4px] body_1_400 ">
            {post.posting?.contents?.length > 56 ? (
              <>
                <p className="break-all">
                  {post.posting.contents.slice(0, 56)}...
                </p>
              </>
            ) : (
              <p className="break-all"> {post.posting.contents}</p>
            )}
          </div>
        </div>
      </div>

      <div className="flex pt-[18px] caption_1_400">
        <div onClick={cheerUpThumb} className="flex items-center w-[100px]">
          {myThumbup ? (
            <img
              src={cheerupicon_col}
              alt="cheerupicon_col"
              className="w-6 h-6 pr-1"
            />
          ) : (
            <img src={cheerupicon} alt="cheerupicon" className="w-6 h-6 pr-1" />
          )}

          <span className="inline-block">응원해요 {thumbupNum}</span>
        </div>
        <div className="flex items-center w-[100px]">
          <img src={messagebol} alt="messagebol" className="w-6 h-6" />
          <span>댓글 {post?.commentNum}</span>
        </div>
      </div>
    </div>
  );
};

export default SaveList;
