import React from "react";
import CenterModal from "../../../mobilecomponent/CenterModal";

const CantGobackPage = ({ showCantgo }) => {
  const closeicon = "/images/mo/closebasic.svg";
  const jangruboy_wow = "/images/mo/jangruboy_wow.svg";
  return (
    <CenterModal showCantgo={showCantgo}>
      <div className="w-full flex justify-end absolute top-0 left-0">
        <img
          src={closeicon}
          alt="closeicon"
          className="cursor-pointer flex justify-end"
          onClick={showCantgo}
        />
      </div>
      <div className="flex justify-center">
        <img src={jangruboy_wow} alt="jangruboy_wow" />
      </div>
      <div className="flex flex-col items-center pt-[32px]">
        <span className="block">의료진이 제공한 내용에 대해</span>
        <span className="block">이해도체크를 작성하지 않으셨어요!</span>
        <span className="block pt-[16px]">
          점검내용에 대한 이해정도를 체크해주세요.
        </span>
      </div>
    </CenterModal>
  );
};

export default CantGobackPage;
