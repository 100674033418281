import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMainContext } from "../../../../../contexts/Provider";

/*component */
import RightModal from "../../../mobilecomponent/RightModal";
import DeletePostModal from "./DeletePostModal";

const PostEdit = ({ setPostedit, postinguserid, postingid }) => {
  console.log(postingid, ">>>postingid");
  const { loginInput } = useMainContext();
  const trashcanicon = "/images/mo/trashcan.svg";
  const penicon = "/images/mo/pen.svg";
  const [deletemo, setDeletemo] = useState(false);
  const navigate = useNavigate();
  const showDelete = () => {
    setDeletemo(true);
  };

  return (
    <>
      {loginInput.id === postinguserid ? (
        <>
          <RightModal>
            <div className="px-[17px] py-[20px] cursor-pointer border-b flex items-center hover:bg-[#CBDFFC] ease-in-out duration-300 ">
              <span
                className="inline-block pr-2 cursor-pointer"
                onClick={() => {
                  navigate("/community/write", {
                    state: postingid
                  });
                }}
              >
                게시글 수정
              </span>
              <img src={penicon} alt="penicon" className="w-[18px] h-[18px]" />
            </div>
            <div className="px-[17px] py-[20px] cursor-pointer border-b flex items-center hover:bg-[#CBDFFC] ease-in-out duration-300 ">
              <span className="inline-block pr-2" onClick={showDelete}>
                게시글 삭제
              </span>
              <img
                src={trashcanicon}
                alt="trashcanicon"
                className="w-[18px] h-[18px]"
              />
            </div>
          </RightModal>

          <div
            className="fixed top-0 left-0 w-full h-full bg-transparent cursor-pointer z-4 "
            onClick={() => {
              setPostedit(false);
            }}
          ></div>
        </>
      ) : (
        ""
      )}
      {deletemo ? (
        <DeletePostModal
          postingid={postingid}
          setDeletemo={setDeletemo}
          setPostedit={setPostedit}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default PostEdit;
