import React, { useState } from "react";
import QuestionList from "./Questions/QuestionList";
import { QuestionData } from "./Questions/data/QuestionData";
import QuestionTap from "./Questions/QuestionTap";
const Style5 = ({ svgurl, bigTitle }) => {
  const [tabname, setTabname] = useState("");
  const eatQuestion = QuestionData.filter((el) => el.category === "식생활");
  const exerciseQuestion = QuestionData.filter((el) => el.category === "운동");
  const lifeQuestion = QuestionData.filter((el) => el.category === "일상생활");
  const stomaQuestion = QuestionData.filter((el) => el.category === "장루");
  const symptomQuestion = QuestionData.filter((el) => el.category === "증상");
  const benefitQuestion = QuestionData.filter((el) => el.category === "혜택");

  return (
    <div className="px-[16px] pt-[29px] body_1_400 wordbreak_keepall">
      <div className="flex items-center gap-x-[13px]">
        <img src={svgurl} alt="svgurl" className="w-[38px] h-[38px]" />
        <span className="h2_800">{bigTitle}</span>
      </div>
      <QuestionTap setTabname={setTabname} tabname={tabname} />
      <div className="pt-[53px] body_1_400_jangrucont">
        {tabname === "전체보기"
          ? QuestionData.map((question) => (
              <QuestionList question={question} key={question.id} />
            ))
          : tabname === "식생활"
          ? eatQuestion.map((question) => (
              <QuestionList question={question} key={question.id} />
            ))
          : tabname === "운동"
          ? exerciseQuestion.map((question) => (
              <QuestionList question={question} key={question.id} />
            ))
          : tabname === "일상생활"
          ? lifeQuestion.map((question) => (
              <QuestionList question={question} key={question.id} />
            ))
          : tabname === "장루"
          ? stomaQuestion.map((question) => (
              <QuestionList question={question} key={question.id} />
            ))
          : tabname === "증상"
          ? symptomQuestion.map((question) => (
              <QuestionList question={question} key={question.id} />
            ))
          : tabname === "혜택"
          ? benefitQuestion.map((question) => (
              <QuestionList question={question} key={question.id} />
            ))
          : ""}
      </div>
    </div>
  );
};

export default Style5;
