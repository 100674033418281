import React, { useState } from "react";
import common, { api } from "../../../../../data/common";
import ImageModal from "../../../mobilecomponent/ImageModal";

const ReportAnswerImage = ({ imageurl }) => {
  const [imageModal, setImageModal] = useState(false);
  const showImageModal = () => {
    setImageModal(!imageModal);
  };

  return (
    <>
      <img
        src={common.networkImg + encodeURIComponent(imageurl)}
        alt="inspectionimage"
        className="cursor-pointer pb-2"
        onClick={showImageModal}
      />
      {imageModal ? (
        <ImageModal showImageModal={showImageModal} imageurl={imageurl} />
      ) : (
        ""
      )}
    </>
  );
};

export default ReportAnswerImage;
