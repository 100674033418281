export const StomaManageText = [
  {
    id: 1,
    title: "소화기관?!",
    title2: "소화기관 알기",
    svgurl: "/images/mo/stoma.svg",
    checkurl: "/images/mo/pngjpg/check_stoma.png",
    bigTitle: "소화기관?!",
  },
  {
    id: 2,
    title: "장루?!",
    title2: "장루알기",
    svgurl: "/images/mo/stomapocket.svg",
    checkurl: "/images/mo/pngjpg/check_stomapocket.png",
    bigTitle: "장루?!",
  },
  {
    id: 3,
    title: "장루 관리방법",
    title2: "장루 관리방법",
    svgurl: "/images/mo/stomamanage.svg",

    checkurl: "/images/mo/pngjpg/check_stomamanage.png",
    bigTitle: "주머니 교환 주기에 관하여..",
  },
  {
    id: 4,
    title: "식생활 관리",
    title2: "식생활 관리",
    svgurl: "/images/mo/foodmanage.svg",
    slideTitle1: "장루 수술 후",
    slideTitle2: "건강한 식생활 관리",
    checkurl: "/images/mo/pngjpg/check_foodmanage.png",
    btn1: "장루로 인한 영양문제 & 장루식사요법의 목표",
    btn2: "수술 후 첫 4-6주간 영양가이드",
    btn3: "기타 고려사항",
    bigTitle: "식생활 관리",
  },
  {
    id: 5,
    title: "생활 속 관리",
    title2: "생활 속 관리",
    svgurl: "/images/mo/sun.svg",
    checkurl: "/images/mo/pngjpg/check_sun.png",
    btn1: "옷은 어떻게 입을까?",
    btn2: "여행시 고려사항은?",
    btn3: "외출과 사회활동은?",
    btn4: "장루와 성생활은?",
    bigTitle: "생활 속 관리",
  },
  {
    id: 6,
    title: "신체활동 관리",
    title2: "신체활동 관리",
    svgurl: "/images/mo/exercise.svg",

    checkurl: "/images/mo/pngjpg/check_exercise.png",
    bigTitle: "신체활동 관리",
  },
  {
    id: 7,
    title: "장루 합병증",
    title2: "장루 합병증",
    svgurl: "/images/mo/complication.svg",
    checkurl: "/images/mo/pngjpg/check_complication.png",
    btn1: "장루 주위 탈장",
    btn2: "장루 주위 출혈",
    btn3: "장탈출",
    btn4: "피부와 장점막 분리",
    btn5: "함몰",
    btn6: "협착",
    bigTitle: "장루 합병증",
  },
  {
    id: 8,
    title: "피부 합병증",
    title2: "피부 합병증",
    svgurl: "/images/mo/skin.svg",
    checkurl: "/images/mo/pngjpg/check_skin.png",
    btn1: "대변에 의한 손상",
    btn2: "알러지에 의한 손상",
    btn3: "표피 비대",
    btn4: "괴저성 농피증",
    bigTitle: "피부 합병증",
  },

  {
    id: 9,
    title: "응급상황 대처",
    title2: "응급상황 대처",
    svgurl: "/images/mo/emergency.svg",
    checkurl: "/images/mo/pngjpg/check_emergency.png",
    bigTitle: "응급상황 대처",
  },
  {
    id: 10,
    title: "정신건강 관리",
    title2: "정신건강 관리",
    svgurl: "/images/mo/heart.svg",
    checkurl: "/images/mo/pngjpg/check_heart.png",
    btn1: "기분이 나아지는 방법",
    btn2: "피로감이 느껴질 때",
    btn3: "불안감이 느껴질 때",
    btn4: "우울감이 느껴질 때",
    btn5: "복식호흡 하는 방법",
    btn6: "명상/심상유도 방법",
    bigTitle: "정신건강 관리",
  },
  {
    id: 11,
    title: "복지혜택",
    title2: "복지혜택",
    svgurl: "/images/mo/benefit.svg",
    checkurl: "/images/mo/pngjpg/check_benefit.png",
    btn1: "복지혜택 참고사항",
    btn2: "복지혜택 정리",
    btn3: "치료재료 급여 인정 기준",
    btn4: "교육 상담료 급여 기준",
    bigTitle: "장루, 요로 장애 복지 혜택",
  },
  {
    id: 12,
    title: "자주묻는질문",
    title2: "자주묻는질문",
    svgurl: "/images/mo/questionmark.svg",
    checkurl: "/images/mo/pngjpg/check_questionmark.png",
    bigTitle: "자주 묻는 질문",
  },
  {
    id: 13,
    title: "장루 교체방법",
    title2: "장루 교체방법",
    bigTitle: "장루 교체방법",
    slideTitle1: "장루 교체방법",
    slideTitle2: "완벽하게 마스터하기",
  },

  {
    id: 14,
    title: "원피스 교체",
    title2: "원피스 교체",
    bigTitle: "원피스 교체",
  },
  {
    id: 15,
    title: "투피스 교체",
    title2: "투피스 교체",
    bigTitle: "투피스 교체",
  },
  {
    id: 16,
    title: "장루 셀프 교체",
    title2: "장루 셀프 교체",
    bigTitle: "장루 셀프 교체",
  },

  {
    id: 17,
    title: "일상속 장루",
    title2: "일상속 장루",
    bigTitle: "일상속 장루",
    slideTitle1: "일상 생활 속",
    slideTitle2: "장루 관리 방법",
  },
];

export const MainStomaText = [
  {
    id: 1,
    title: "장루 관리방법",
    title2: "장루 관리방법",
    svgurl: "/images/mo/stomamanage.svg",

    bigTitle: "주머니 교환 주기에 관하여..",
  },
  {
    id: 2,
    title: "장루 합병증",
    title2: "장루 합병증",
    svgurl: "/images/mo/complication.svg",

    btn1: "장루 주위 탈장",
    btn2: "장루 주위 출혈",
    btn3: "장탈출",
    btn4: "피부와 장점막 분리",
    btn5: "함몰",
    btn6: "협착",
    bigTitle: "장루 합병증",
  },
  {
    id: 3,
    title: "식생활 관리",
    title2: "식생활 관리",
    svgurl: "/images/mo/foodmanage.svg",

    btn1: "장루로 인한 영양문제 & 장루식사요법의 목표",
    btn2: "수술 후 첫 4-6주간 영양가이드",
    btn3: "기타 고려사항",
    bigTitle: "식생활 관리",
  },
  {
    id: 4,
    title: "응급상황 대처",
    title2: "응급상황 대처",
    svgurl: "/images/mo/emergency.svg",

    bigTitle: "응급상황 대처",
  },
  {
    id: 5,
    title: "장루?!",
    title2: "장루알기",
    svgurl: "/images/mo/stomapocket.svg",
    conttext1: "conttext1-id2",
    conttext2: "conttext2-id2",
    bigTitle: "장루?!",
  },
  {
    id: 6,
    title: "생활 속 관리",
    title2: "생활 속 관리",
    svgurl: "/images/mo/sun.svg",
    btn1: "옷은 어떻게 입을까?",
    btn2: "여행시 고려사항은?",
    btn3: "외출과 사회활동은?",
    btn4: "장루와 성생활은?",
    bigTitle: "생활 속 관리",
  },
  {
    id: 7,
    title: "전체 보기",
    title2: "전체 보기",
    svgurl: "/images/mo/viewall.svg",
  },
];
