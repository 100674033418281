import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, usePagination } from "react-table";

function MorePageTable({
  columns,
  data,
  popupPosition, // 팝업 위치설정 top 값 & 팝업 height 값
  setPopupPosition, // 팝업 top & height 저장
  nested, // 팝업이 위로 뜨는 지 아래로 뜨는 지 ("bottom" || "top")
}) {
  const arrow_left = "/images/admin/carousel_left.svg";
  const arrow_right = "/images/admin/carousel_right.svg";
  const navigate = useNavigate();
  const {
    getTableBodyProps,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 5 } },
    usePagination
  );
  const paginationBtns = new Array(pageCount).fill(0).map((el, idx) => idx + 1);
  const firstHeight = useRef(null); // 팝업 창의 높이값

  // pagination 버튼들
  const paginationUI = () => {
    return (
      <div className="flex justify-center items-center mt-[10px]">
        <button
          className="pr-[17.6px]"
          onClick={() => {
            previousPage();
            // 버튼 눌릴 때 popup 세로값 초기화
            // 초기화 안하면 현재 popupPosition.height값이 null이 아닐 경우에 page 이동 시 ui가 이상해짐...
            // 이전에 설정된 height값이 null이 아닐 경우 ===> 이전에 열었던 pupup에서 마지막 페이지를 보고있었을 경우
            // 번호 & next 버튼 누를 때도 마찬가지
            setPopupPosition({
              ...popupPosition,
              height: null,
            });
          }}
          disabled={!canPreviousPage}
        >
          <img
            src={arrow_left}
            alt="arrow_left"
            className="w-[6.4px] h-[11.2px]"
            style={{
              filter: !canPreviousPage
                ? "brightness(0) saturate(100%) invert(48%) sepia(0%) saturate(5417%) hue-rotate(195deg) brightness(134%) contrast(82%)"
                : "brightness(0) saturate(100%) invert(3%) sepia(29%) saturate(7499%) hue-rotate(218deg) brightness(99%) contrast(100%)",
            }}
          />
        </button>
        <div className="flex gap-x-1">
          {paginationBtns.map((el, idx) => {
            if (
              idx >= Math.floor(pageIndex / 3) * 3 &&
              idx <= Math.floor(pageIndex / 3) * 3 + 2
            ) {
              return (
                <button
                  key={idx}
                  className="flex justify-center items-center border w-[32px] h-[32px] text-center text-base text-[#020A1B] font-semibold leading-5 rounded-[28px]"
                  onClick={() => {
                    if (pageIndex !== idx) {
                      gotoPage(idx);
                      // 버튼 눌릴 때 popup hegiht 초기화
                      setPopupPosition({
                        ...popupPosition,
                        height: null,
                      });
                    }
                  }}
                  style={{
                    borderColor: pageIndex === idx ? "#020A1B" : "transparent",
                  }}
                >
                  {idx + 1}
                </button>
              );
            } else return null;
          })}
        </div>
        <button
          className="pl-[17.6px]"
          onClick={() => {
            nextPage();
            // 버튼 눌릴 때 popup hegiht 초기화
            setPopupPosition({
              ...popupPosition,
              height: null,
            });
          }}
          disabled={!canNextPage}
        >
          <img
            src={arrow_right}
            alt="arrow_right"
            className="w-[6.4px] h-[11.2px]"
            style={{
              filter: !canNextPage
                ? "brightness(0) saturate(100%) invert(48%) sepia(0%) saturate(5417%) hue-rotate(195deg) brightness(134%) contrast(82%)"
                : "brightness(0) saturate(100%) invert(3%) sepia(29%) saturate(7499%) hue-rotate(218deg) brightness(99%) contrast(100%)",
            }}
          />
        </button>
      </div>
    );
  };

  useEffect(() => {
    const popEl = document.querySelector(
      ".popup-content.calender__popup-content"
    );

    // 현재 팝업창의 크기가 맨 처음 팝업 열었을 때와 달라졌을 때
    if (
      pageIndex === pageOptions.length - 1 && // 맨 마지막 페이지이면서
      pageIndex !== 0 && // 맨 첫페이지가 아닐 때(전체 페이지 수가 1이 아닐때)
      nested === "top" // 위로 띄워진 팝업일 떄
    ) {
      setPopupPosition({
        ...popupPosition,
        height: firstHeight.current - popEl.getBoundingClientRect().height + 1,
        // 맨 첫페이지 세로길이 - 현재 팝업창의 세로 길이 뺀 값 + 1(왠지 모를 차이가 1px정도 남...)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  // 팝업 위치 설정을 위한 setting
  useEffect(() => {
    // 맨 처음 팝업이 오픈됐을 때의 팝업 세로길이 값 구해서 저장
    const popEl = document.querySelector(
      ".popup-content.calender__popup-content"
    );
    firstHeight.current = popEl.getBoundingClientRect().height;

    // 맨처음 팝업이 오픈됐을 때 popup api에서 자체 설정해주는 top값 구해서 저장
    const style = window.getComputedStyle(popEl);
    const top = style.getPropertyValue("top");
    setPopupPosition({
      height: null,
      top: Number(top.substring(0, top.length - 3)),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <style jsx="true">{`
        .table {
          border-spacing: 1px !important;
        }
      `}</style>
      <div className="flex flex-col w-full">
        <table
          className="table-fixed border-separate w-full "
          border="0"
          cellSpacing="0"
          cellPadding="0"
        >
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr key={row.id} {...row.getRowProps()} className="!p-0">
                  {row.cells.map((cell) => {
                    const el = cell.row.original;
                    return (
                      <td key={cell.row.id} style={{ border: "none" }}>
                        <div className="flex justify-between items-center p-2 shadow-[0_1px_4px_rgba(0,0,0,0.25)] border-transparent rounded-[4px] bg-[#FAFBFC] mb-2">
                          <label
                            className=" my-[5px] font-semibold text-sm leading-[18px] text-[#020A1B] cursor-pointer"
                            onClick={() => {
                              navigate("/admin/main/check/detail", {
                                state: {
                                  data: {
                                    id: el.patient.id,
                                  },
                                },
                              });
                            }}
                          >
                            {el.patient.name}
                            <span className="text-xs leading-4 font-normal text-[#4E535F] ml-1">
                              {el.patient.patientID}
                            </span>
                          </label>

                          <button
                            className="text-xs font-normal leading-4 text-[#FAFBFC] px-2 py-[2px] border rounded-[4px] bg-[#4E535F] hover:bg-[#393C44]"
                            onClick={() => {
                              window.open(
                                `/admin/main/pdf/${el.surveyId}/${el.checkId}/${el.replyId}`
                              );
                            }}
                          >
                            pdf보기
                          </button>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* pagenation */}
        {pageOptions.length > 1 ? paginationUI() : null}
      </div>
    </>
  );
}

export default MorePageTable;

/* popupPosition, setPopupPosition의 쓰임새에 대한 설명 */

// 팝업 ui에서 page별로 띄워질 수 있는 환자 리스트 갯수는 5개인데, 만약 맨 마지막 페이지의 환자 리스트가 3개밖에 안될경우(->예시)
// 팝업의 세로 길이가 달라져서 팝업의 위치가 붕뜨게 됨
// (팝업의 위치는 popup api에서 자동 설정됨. 이는 맨 처음 팝업이 열릴 때 설정되는 top값에 의존.
// 따라서 페이지 이동해도 top값은 변하지 않아 붕 뜨게 됨)
// 문제 해결을 위해 api에서 자동설정되는 popup의 top값과,
// 페이지 이동시마다 변하는 popup창의 세로길이를 구해서 필요시마다 popup 위치 값을 재설정 해주어야 함
