import React from "react";

const Tutorial1 = () => {
  const tutorial1 = "/images/mo/pngjpg/tutorial1.png";
  const righthand = "/images/mo/pngjpg/righthand.png";
  return (
    <div>
      <div className="pt-[25.5px] flex justify-center ">
        <div className="w-[375px] h-[329px] flex items-center justify-center relative">
          <img src={tutorial1} alt="tutorial1" />
          <img
            src={righthand}
            alt="righthand"
            className="absolute bottom-[-50px] left-6 tutohand1"
          />
        </div>
      </div>
      <div className="flex items-center flex-col pt-[50px]">
        <span className="block  text-[#1B5EDC] h2_800">장루 점검하기</span>
        <div className="text-[#343B48] text-center body_1_400 pt-[8px]">
          <div>월 2회, 장루점검일에 맞춰</div>
          <div>장루 상태를 기록하고</div>
          <div>장루 간호사의 피드백을 받아 보세요.</div>
        </div>
      </div>
    </div>
  );
};

export default Tutorial1;
