import React from "react";
import { StomaManageText } from "../data/StomaManageText";
import { useNavigate } from "react-router-dom";
const AllJangruVideo = () => {
  const videoicon = "/images/mo/videoicon.svg";
  const jangru_home = "/images/mo/jangru_home.svg";
  const navigate = useNavigate();
  return (
    <div className="px-[24px]  wordbreak-keepall text-[#676C76] caption_1_600 ">
      <div className="flex gap-x-[8px] ">
        <img src={videoicon} alt="videoicon" />
        <span className="subtitle_2">장루, 이렇게 관리하세요!</span>
      </div>
      <div className="pt-[16px] flex gap-x-[8px]">
        <div
          className="bg-[#F5F6F7] flex flex-col justify-center items-center w-1/4 rounded p-[16px] hover:bg-[#e5e6e7] cursor-pointer"
          onClick={() => {
            const findCont = StomaManageText.filter(
              (el) => el.title === "장루 교체방법"
            );
            navigate("/more", {
              state: {
                title: findCont[0].title,
                title2: findCont[0].title2,
                bigTitle: findCont[0].bigTitle,
              },
            });
          }}
        >
          <span className="block">장루</span>
          <span className="block">교체방법</span>
        </div>
        <div
          className="bg-[#F5F6F7] flex flex-col justify-center items-center  w-1/4 rounded p-[16px] text-center  hover:bg-[#e5e6e7] cursor-pointer"
          onClick={() => {
            const findCont = StomaManageText.filter(
              (el) => el.title === "원피스 교체"
            );
            navigate("/more", {
              state: {
                title: findCont[0].title,
                title2: findCont[0].title2,
                bigTitle: findCont[0].bigTitle,
              },
            });
          }}
        >
          <span className="block">원피스</span>
          <span className="block">교체</span>
        </div>
        <div
          className="bg-[#F5F6F7] flex flex-col justify-center items-center  w-1/4 rounded p-[16px] text-center  hover:bg-[#e5e6e7] cursor-pointer"
          onClick={() => {
            const findCont = StomaManageText.filter(
              (el) => el.title === "투피스 교체"
            );
            navigate("/more", {
              state: {
                title: findCont[0].title,
                title2: findCont[0].title2,
                bigTitle: findCont[0].bigTitle,
              },
            });
          }}
        >
          <span className="block">투피스</span>
          <span className="block">교체</span>
        </div>
        <div
          className="bg-[#F5F6F7] flex flex-col justify-center items-center  w-1/4 rounded p-[16px] text-center  hover:bg-[#e5e6e7] cursor-pointer flex-shrink-0"
          onClick={() => {
            const findCont = StomaManageText.filter(
              (el) => el.title === "장루 셀프 교체"
            );
            navigate("/more", {
              state: {
                title: findCont[0].title,
                title2: findCont[0].title2,
                bigTitle: findCont[0].bigTitle,
              },
            });
          }}
        >
          <span className="block">장루</span>
          <span className="block">셀프교체</span>
        </div>
      </div>
      <div className="pt-[16px] ">
        <div
          className="flex justify-center items-center gap-x-[8px] bg-[#F5F6F7] w-full rounded p-[16px] hover:bg-[#e5e6e7] cursor-pointer"
          onClick={() => {
            const findCont = StomaManageText.filter(
              (el) => el.title === "일상속 장루"
            );
            navigate("/more", {
              state: {
                title: findCont[0].title,
                title2: findCont[0].title2,
                bigTitle: findCont[0].bigTitle,
              },
            });
          }}
        >
          <img src={jangru_home} alt="jangru_home" />
          <span>일상에서 장루관리법</span>
        </div>
      </div>
      <div className="h-[84px]"></div>
    </div>
  );
};

export default AllJangruVideo;
