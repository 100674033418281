function convertBelongToName(belongs, belong) {
  let resultName = "잘못된 입력";
  belongs.forEach((el) => {
    if (belong === el.id) {
      resultName = el.name;
      return resultName;
    }
  });

  return resultName;
}

function convertNameToBelong(belongs, belongName) {
  let resultNum = 0;
  belongs.forEach((el, idx) => {
    if (belongName === el.name) {
      resultNum = el.id;
      return resultNum;
    }
  });

  return resultNum;
}

// role 번호에 따라 역할을 한글로 변환하여 리턴
function convertRole(role) {
  if (role === 0) return "관리자";
  else if (role === 1) return "의사";
  else if (role === 2) return "장루 간호사";
  else return "";
}
export { convertBelongToName, convertNameToBelong, convertRole };
