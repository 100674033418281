import React from "react";
const DigestiveSystem = () => {
  const image = "/images/mo/jangrucont/소화기관.png";
  return (
    <>
      <img src={image} alt="image1" className="w-full" />

      <div className="pt-[16px] ">
        <div>
          <span className="inline-block body_1_400_jangrucont">
            소화관은 입에서 항문까지 연결된 관으로 몸에 수분과 영양물질을
            공급하고 노폐물을 배출하는 기능을 합니다.
          </span>
        </div>
      </div>
      <div className="pt-[16px] body_1_400_jangrucont">
        <div className="flex justify-end ">
          <span className="inline-block">1. &nbsp;</span>
          <div>
            <span className="inline-block">
              입을 통해 섭취한 음식물은 식도를 지나 위를 거쳐 소장으로
              보내집니다.
            </span>
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">2.&nbsp;</span>
          <span className="inline-block">
            소장은 인체에 필요한 영양소를 흡수하는 작용을 합니다.
          </span>
        </div>
        <div className="flex ">
          <span className="inline-block">3.&nbsp;</span>
          <span className="inline-block">
            소장을 지난 음식물은 대장으로 이동합니다.
          </span>
        </div>
        <div className="flex ">
          <span className="inline-block">4.&nbsp;</span>
          <span className="inline-block">
            대장에서 수분 재흡수가 되며 대변이 만들어집니다.
          </span>
        </div>
        <div className="flex ">
          <span className="inline-block">5.&nbsp;</span>
          <div>
            <span className="inline-block">
              대변이 직장에 차면 변의가 느껴지고 항문을 통해 변이 배출됩니다.
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigestiveSystem;
