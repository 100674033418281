import React, { useEffect } from "react";
import { useMainContext } from "../../../../../contexts/Provider";

import common, { api } from "../../../../../data/common";
import { useLocation, useNavigate } from "react-router-dom";
const ReprtTab = ({
  inspection,
  inspectionDateShow,
  diaryDateShow,
  diary,
  graph,
  graphDateShow,
  thisyear,
  setInspectJan,
  setInspectFeb,
  setInspectMar,
  setInspectApr,
  setInspectMay,
  setInspectJun,
  setInspectJul,
  setInspectAug,
  setInspectNov,
  setInspectDec,
  setInspectOct,
  setInspectSep,
  setDiaryJan,
  setDiaryFeb,
  setDiaryMar,
  setDiaryApr,
  setDiaryMay,
  setDiaryJun,
  setDiaryJul,
  setDiaryAug,
  setDiaryNov,
  setDiaryOct,
  setDiarySep,
  setDiaryDec,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { loginInput, tabWhat } = useMainContext();

  // 원래는 main 페이지로 돌아갔는데 메인에 있는 onpopstate로 가기전에
  // 여기서 onpopstate를 추가하니까 여기서 막혀서 메인의 onpopstate는 실행되지 않는 듯 함
  // window.onpopstate == ios에서의 슬라이드로 뒤로가기
  window.onpopstate = function () {
    console.log(location.pathname, "홈으로");
    if (location.pathname === "/report") {
      console.log("홈으로!!!!!!!!");
      navigate("/main");
    }
  };

  // state 받아서 클릭했던 탭 유지
  useEffect(() => {
    if (tabWhat.current === "check") {
      console.log("check");
      inspectionDateShow();
    } else if (tabWhat.current === "diary") {
      console.log("diary");
      diaryDateShow();
    } else {
      console.log("graph");
      graphDateShow();
    }
  });

  const onClickInspection = () => {
    // if (state && state.prevPath) {
    //   state.prevPath = undefined;
    // }
    tabWhat.current = "check";
    inspectionDateShow();

    api
      .get(common.baseURL + "jangruSurvey/list/" + loginInput.id)
      .then((res) => {
        console.log(res);
        if (res.data.ok === true) {
          let Janu = res.data.Jan.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let Febu = res.data.Feb.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let Marc = res.data.Mar.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let Apri = res.data.Apr.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let May = res.data.May.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let June = res.data.Jun.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let July = res.data.Jul.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let Augu = res.data.Aug.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let Octo = res.data.Oct.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let Sept = res.data.Sep.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let Nove = res.data.Nov.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let Dece = res.data.Dec.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          setInspectJan(Janu);
          setInspectFeb(Febu);
          setInspectMar(Marc);
          setInspectApr(Apri);
          setInspectMay(May);
          setInspectJun(June);
          setInspectJul(July);
          setInspectAug(Augu);
          setInspectOct(Octo);
          setInspectSep(Sept);
          setInspectNov(Nove);
          setInspectDec(Dece);
        }
      })
      .catch((error) => console.log(error));
  };

  const onClickDiary = () => {
    // if (state && state.prevPath) {
    //   state.prevPath = undefined;
    // }
    tabWhat.current = "diary";
    diaryDateShow();
    api
      .get(common.baseURL + "diary/list/" + loginInput.id)
      .then((res) => {
        console.log(res);
        if (res.data.ok === true) {
          let Janu = res.data.Jan.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let Febu = res.data.Feb.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let Marc = res.data.Mar.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let Apri = res.data.Apr.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let May = res.data.May.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let June = res.data.Jun.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let July = res.data.Jul.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let Augu = res.data.Aug.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let Octo = res.data.Oct.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let Sept = res.data.Sep.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let Nove = res.data.Nov.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          let Dece = res.data.Dec.filter(
            (el) => el.slice(0, 4) === thisyear.year
          );
          setDiaryJan(Janu);
          setDiaryFeb(Febu);
          setDiaryMar(Marc);
          setDiaryApr(Apri);
          setDiaryMay(May);
          setDiaryJun(June);
          setDiaryJul(July);
          setDiaryAug(Augu);
          setDiaryOct(Octo);
          setDiarySep(Sept);
          setDiaryNov(Nove);
          setDiaryDec(Dece);
        }
      })
      .catch((error) => console.log(error));
  };
  const onClickGraph = () => {
    // if (state && state.prevPath) {
    //   state.prevPath = undefined;
    // }
    tabWhat.current = "graph";
    graphDateShow();
  };
  return (
    <div className="pt-[48px] flex justify-between relative bg-[#FAFCFF]">
      <div
        className={
          inspection
            ? "w-1/3 h-[80px] flex justify-center items-center cursor-pointer text-[#1B5EDC] subtitle_1_700 "
            : "w-1/3 h-[80px] flex justify-center items-center cursor-pointer text-[#676C76] subtitle_1_700"
        }
        onClick={onClickInspection}
      >
        <span
          className={
            inspection
              ? "inline-block w-1/3 h-0.5 bg-[#1B5EDC] absolute left-0 bottom-[-2px]"
              : ""
          }
        ></span>
        장루점검
      </div>
      <div
        className={
          diary
            ? "w-1/3  h-[80px]  flex justify-center items-center cursor-pointer text-[#1B5EDC] subtitle_1_700 "
            : "w-1/3  h-[80px]  flex justify-center items-center cursor-pointer text-[#676C76] subtitle_1_700"
        }
        onClick={onClickDiary}
      >
        <span
          className={
            diary
              ? "inline-block w-1/3 h-0.5 bg-[#1B5EDC] absolute left-1/3 bottom-[-2px]"
              : ""
          }
        ></span>
        건강다이어리
      </div>
      <div
        className={
          graph
            ? "w-1/3 h-[80px]  flex justify-center items-center cursor-pointer text-[#1B5EDC] subtitle_1_700 "
            : "w-1/3 h-[80px]  flex justify-center items-center cursor-pointer text-[#676C76] subtitle_1_700"
        }
        onClick={onClickGraph}
      >
        <span
          className={
            graph
              ? "inline-block w-1/3 h-0.5 bg-[#1B5EDC] absolute left-2/3 bottom-[-2px]"
              : ""
          }
        ></span>
        그래프
      </div>
    </div>
  );
};

export default ReprtTab;
