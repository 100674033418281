import React, { useState } from "react";
import Switch from "react-switch";

const ToggleBtn = ({ setShowTable, prevPath }) => {
  const [on, setOn] = useState(() => {
    if (prevPath === "장루 상태평가" || !prevPath) return false;
    else return true;
  });
  const [css, setCss] = useState(() => {
    if (prevPath === "장루 상태평가" || !prevPath) {
      return { left: -114, content: "장루 상태평가" };
    } else {
      return { left: -3, content: "건강 다이어리" };
    }
  });

  const onClickToggle = () => {
    // 건강 다이어리 => 장루 상태평가
    if (on === true) {
      setShowTable({ jangru: true, diary: false });
      setCss({ left: -114, content: "장루 상태평가" });
    }
    // 장루 상태평가 => 건강 다이어리
    else {
      setShowTable({ jangru: false, diary: true });
      setCss({ left: -3, content: "건강 다이어리" });
    }
  };

  return (
    <>
      <style jsx="true">{`
        .react-switch-bg div {
          background-color: #e5e6e7 !important;
          border-radius: 24px !important;
          width: 108px !important;
          opacity: 1 !important;
        }
        .react-switch-handle {
          width: 108px !important;
          height: 33.5px !important;
          border-radius: 24px !important;
          background-color: #fafbfc !important;
          top: 4px !important;
          left: ${css.left}px !important;
          transform: translate(110%, -0%) !important;
          transition: left 0.25s ease !important;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          color: #020a1b;
          padding: 8px;
          box-shadow: none !important;
        }
        .react-switch-handle:before {
          content: "${css.content}";
        }
      `}</style>
      <div className="h-[42px]">
        <Switch
          checked={on}
          onChange={() => {
            setOn(!on);
            onClickToggle();
          }}
          offColor={"#e5e6e7"}
          onColor={"#e5e6e7"}
          width={228}
          height={42}
          handleDiameter={28}
          uncheckedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "76px",
                height: "100%",
                margin: "0",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "18px",
                textAlign: "center",
                paddingRight: "2px",
                color: "#676C76",
              }}
            >
              건강 다이어리
            </div>
          }
          checkedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "76px",
                height: "100%",
                margin: "0",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "18px",
                textAlign: "center",
                paddingLeft: "2px",
                color: "#676C76",
              }}
            >
              장루 상태평가
            </div>
          }
        />
      </div>
    </>
  );
};

export default ToggleBtn;
