import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
SwiperCore.use([Autoplay]);
const StomaHernia = ({ bigTitle, btn3 }) => {
  const image9 = "/images/mo/jangrucont/장루합병증9.jpg";
  const image10 = "/images/mo/jangrucont/장루합병증10.jpg";
  const image11 = "/images/mo/jangrucont/장루합병증11.jpg";
  const image12 = "/images/mo/jangrucont/장루합병증12.jpg";
  return (
    <div className="w-full flex flex-col  px-[16px] body_1_400 wordbreak-keepall">
      <div className="pt-[72px] ">
        <div className="flex justify-center flex-col items-center w-full border-b">
          <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
          <h1 className="h2_800 pt-[4px] pb-[24px]">{btn3}</h1>
        </div>
        <div className="w-full pt-[24px]">
          <div>
            <div className="subtitle_1_700 pb-[8px]">1) 정의</div>
            <div className="w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
              <img src={image9} alt="image9" className="h-full" />
            </div>
            <div className="pt-[8px]">
              장이 체외로 빠져 나오는 것을 말합니다.
            </div>
          </div>
          <div className="pt-[32px] body_1_400_jangrucont">
            <div className="subtitle_1_700 pb-[8px]">2) 관리방법</div>
            <div>
              <div className="pt-[8px]">
                <div className="flex   ">
                  <span className="inline-block">1. &nbsp;</span>
                  <div>
                    장이 체외로 빠져 나온 경우 누운 상태에서 적당한 압력을 주어
                    복원합니다.
                  </div>
                </div>
                <div className="flex   ">
                  <span className="inline-block">2. &nbsp;</span>
                  <div>
                    부종을 감소시키기 위해 많은 양의 설탕을 탈출된 장 점막에
                    뿌리세요.
                  </div>
                </div>
                <div className="flex   ">
                  <span className="inline-block">3.&nbsp;</span>
                  <div>
                    장루가 다치는 것을 예방하기 위해 직경이 큰 피부 보호판이나
                    원피스 제품으로 변경하세요.
                  </div>
                </div>
                <div className="flex   ">
                  <span className="inline-block">4.&nbsp;</span>
                  <div>
                    장이 들어가면 재발을 막기 위해 복대를 해서 복부를 지지하고
                    복압이 상승되지 않도록 합니다.
                  </div>
                </div>
                <div className="flex   ">
                  <span className="inline-block">5. &nbsp;</span>
                  <span className="inline-block">
                    장루 괴사나 폐쇄의 증상이 있으면 응급실을 방문합니다.
                  </span>
                </div>
              </div>
            </div>
            <div className="pt-[16px]">
              <Swiper
                loop={true}
                spaceBetween={8}
                slidesPerView={1}
                autoplay={{ delay: 1000, disableOnInteraction: false }}
              >
                <SwiperSlide>
                  <div className="w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
                    <img src={image10} alt="image10" className="h-full" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
                    <img src={image11} alt="image11" className="h-full" />
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="pt-[8px]">
                <div className="flex ">
                  <span className="inline-block">• &nbsp;</span>
                  <span className="inline-block">
                    장루 괴사나 폐쇄의 증상이 있으면 응급실을 방문합니다.
                  </span>
                </div>
              </div>
            </div>
            <div className="pt-[16px]">
              <div className="w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
                <img src={image12} alt="image12" className="h-full" />
              </div>
              <div className="pt-[8px]">
                <div className=" ">
                  • 장루 색깔이 검정색으로 변하면 응급실을 방문합니다.
                </div>
              </div>
            </div>
          </div>
          <div className="pt-[32px]">
            <div className="subtitle_1_700 pb-[8px]">3) 예방</div>
            <div className="flex  ">
              <span className="inline-block">1. &nbsp;</span>
              <div>
                <span className="inline-block">
                  무거운 것을 드는 것을 피하세요.
                </span>
              </div>
            </div>
            <div className="flex   ">
              <span className="inline-block">2. &nbsp;</span>
              <div>
                <span className="inline-block">
                  배변을 위해 필요 없이 복부에 힘을 주는 것은 하지 않습니다.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StomaHernia;
