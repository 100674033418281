import React, { useState } from "react";

import common, { api } from "../../../../../data/common";
import { toast } from "react-toastify";

const NewPass = ({ showfindPass, closeimg, findPassInput }) => {
  const [newPWinput, setNewPWinput] = useState({
    newpass: "",
    passconfirm: "",
  });
  const [errormsg, setErrorMsg] = useState("");
  const [numlettermsg, setNumletterMsg] = useState(false);

  const onChangeNewPw = (e) => {
    const { value, name } = e.target;
    let numletterReg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,12}$/;
    setNewPWinput({ ...newPWinput, [name]: value.slice(0, 12) });
    if (name === "newpass") {
      if (value.match(numletterReg) && value.length >= 8) {
        setNumletterMsg("안전한 비밀번호입니다.");
      } else {
        setNumletterMsg(
          "숫자, 영문자, 특수문자 포함 8-12자리로 입력해 주세요."
        );
      }
    } else {
      if (
        (newPWinput.newpass !== "" || newPWinput.passconfirm !== "") &&
        e.target.value !== ""
      ) {
        if (newPWinput.newpass === e.target.value && e.target.value !== "") {
          setErrorMsg("일치합니다.");
        } else {
          setErrorMsg("비밀번호가 일치하지 않습니다.");
        }
      }
    }
  };

  const onClickSubmitPass = () => {
    if (errormsg === "일치합니다.") {
      console.log(newPWinput.passconfirm, findPassInput);
      let body = {
        name: findPassInput.name,
        birth: `${findPassInput.birth.slice(0, 4)}-${findPassInput.birth.slice(
          4,
          6
        )}-${findPassInput.birth.slice(6, 8)}`,
        patientID: findPassInput.patientID,
        newPwd: newPWinput.passconfirm,
      };

      api
        .post(common.baseURL + "patient/changePwd", body)
        .then((res) => {
          console.log(res);
          if (res.data.ok === true) {
            toast(`👏 저장되었습니다.`, {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              className: "transparent toastbottom",
            });
            let start = setTimeout(() => {
              showfindPass();
            }, 1000);
            return () => {
              clearTimeout(start);
            };
          } else {
            toast(res.data.error, {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              className: "transparent toastbottom",
            });
          }
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <div className="fixed top-0 left-0 z-10 w-full">
      <form className="px-4 max-w-md h-screen flex flex-col mx-auto text-gray-600 antialiased bg-[#FAFCFF] relative">
        <div className="w-full h-[48px] ">
          <img
            className="absolute top-0 right-0 cursor-pointer"
            src={closeimg}
            alt="close"
            onClick={showfindPass}
          />
        </div>
        <h1 className="h3_800 pt-[14px]">새 비밀번호 입력</h1>
        <div className=" pt-[8px]">
          <span className="text-[#1B5EDC]">새 비밀번호</span>
          <span>를 입력해 주세요.</span>
        </div>
        <div
          className={
            numlettermsg ===
            "숫자, 영문자, 특수문자 포함 8-12자리로 입력해 주세요."
              ? "pt-[48px] border-b-[1px] border-[#F62828]"
              : numlettermsg === "안전한 비밀번호입니다."
              ? "pt-[48px] border-b-[1px] border-[#1B5EDC]"
              : "pt-[48px] border-b-[1px]"
          }
        >
          <label htmlFor="name" className="block subtitle_1_600">
            새 비밀번호 입력
          </label>
          <input
            autoComplete="off"
            type="password"
            className="w-full py-[12px] caption_1_600 focus:outline-none bg-transparent bg-transparent"
            placeholder="숫자, 영문자, 특수문자 포함 8-12자리"
            name="newpass"
            value={newPWinput.newpass}
            onChange={onChangeNewPw}
          />
        </div>

        {numlettermsg === "안전한 비밀번호입니다." ? (
          <span className="text-[#1B5EDC] caption_1_600">{numlettermsg}</span>
        ) : (
          <span className="text-[#F62828] caption_1_600">{numlettermsg}</span>
        )}
        <div
          className={
            errormsg === "비밀번호가 일치하지 않습니다."
              ? "border-b-[1px] pt-[16px] border-[#F62828]"
              : errormsg === "일치합니다."
              ? "border-b-[1px] pt-[16px] border-[#1B5EDC]"
              : "border-b-[1px] pt-[16px]"
          }
        >
          <label htmlFor="name" className="block subtitle_1_600 ">
            비밀번호 재입력
          </label>
          <input
            autoComplete="off"
            type="password"
            className="w-full py-[12px] caption_1_600 focus:outline-none bg-transparent"
            name="passconfirm"
            value={newPWinput.passconfirm}
            onChange={onChangeNewPw}
          />
        </div>
        {errormsg === "일치합니다." ? (
          <span className="text-[#1B5EDC] caption_1_600">{errormsg}</span>
        ) : (
          <span className="text-[#F62828] caption_1_600">{errormsg}</span>
        )}
        {errormsg === "일치합니다." &&
        numlettermsg === "안전한 비밀번호입니다." ? (
          <span
            className="fixed bottom-0 left-0 w-full block text-center text-white font-bold bg-[#1B5EDC] hover:bg-[#18428E] py-[20px] ease-in-out duration-300 cursor-pointer"
            onClick={onClickSubmitPass}
          >
            저장하기
          </span>
        ) : (
          <span className="fixed bottom-0 left-0 w-full block text-center text-white font-bold bg-[#9DC4FA] py-[20px] ">
            저장하기
          </span>
        )}
      </form>
    </div>
  );
};

export default NewPass;
