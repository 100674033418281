import "dayjs/locale/ko";
import TableYellow from "../../admin_components/TableYellow";

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header2 from "../../admin_components/Header/Header2";
import common, { api } from "../../../../data/common";
import { Flip, toast, ToastContainer } from "react-toastify";
import { toastCommonProps } from "../../admin_components/Toast";
import { BtnBlue } from "../../admin_components/Buttons";
import { useMainContext } from "../../../../contexts/Provider";

export function News() {
  const showAllRef = useRef(false); // 전체보기 버튼 보여줄지 말지
  const navigate = useNavigate();
  const dataRef = useRef([]); // 표기할 정보 담기
  /*** 검색 관련 state, ref ***/
  const [data, setData] = useState([]); // 표기할 정보 담기
  const [search, setSearch] = useState("");
  const searchRef = useRef();
  const [loading, setLoading] = useState(false); //
  const search_icon = "/images/admin/header_dash/search.svg";
  const { adminLogin } = useMainContext(); // 로그인 정보 Consumer

  // 검색버튼을 누르고 search 가 바뀌었을 시
  useEffect(() => {
    if (search !== "") {
      const newData = dataRef.current.filter((el) => {
        return el.title.includes(search) || el.content.includes(search);
      });
      if (newData.length === 0) {
        toast(
          <p>검색된 내용이 없습니다.</p>,
          toastCommonProps(
            2000,
            "top-right",
            "toast_common toast__search",
            Flip
          )
        );
        setSearch("");
        searchRef.current.value = ""; // 검색창 빈칸으로 만들기
      } else {
        setData(newData);
        showAllRef.current = true;
      }
    }
  }, [search]);

  // 전체 보기 클릭시
  const onClickShowAll = () => {
    setData(dataRef.current);
    setSearch("");
    searchRef.current.value = ""; // 검색창 빈칸으로 만들기
    showAllRef.current = false;
  };

  // 첫 랜더시 데이터 불러오기()
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await api.get(common.baseURL + "news");
      console.log(response);

      dataRef.current = response.data.newsList;
      setData(dataRef.current);
      setLoading(false);
    }
    fetchData();
  }, []);

  /******************************* Table UI 관련 변수 & 함수들  ******************************/
  // Table에 들어갈 column명
  const columns = [
    {
      accessor: "idx",
      Header: "No",
      width: "5%",
    },
    {
      accessor: "category",
      Header: "구분",
      width: "10%",
    },
    {
      accessor: "title",
      Header: "제목",
      width: "15%",
    },
    {
      accessor: "flag",
      Header: "표시",
      width: "7%",
    },
    {
      accessor: "date",
      Header: "게시기간",
      width: "15%",
    },

    {
      accessor: "writer",
      Header: "작성자",
      width: "10%",
    },
  ];

  function currentPosts(data) {
    let sortedData = data?.sort(
      (a, b) => new Date(b.startDate) - new Date(a.startDate)
    );

    // data 있으면 새소식 (게시글) 내림차순으로 정렬
    // (게시기간 지난것도 제외하지 않고 다 보여주도록 변경함)

    return data === undefined
      ? []
      : sortedData?.map((post, idx) => {
          const temp_category = ["장루소식", "앱소식", "커뮤니티"];
          const role = ["관리자", "의사", "간호사"];

          let flag =
            new Date() <= new Date(post.endDate) &&
            new Date(post.startDate) <= new Date();

          return {
            ...post,
            idx: data.length - idx,
            category: temp_category[post.category - 1],
            title: post.title,
            date: `${post.startDate}~${post.endDate}`,
            flag: flag ? "표시" : "표시 안함",
            writer: `${adminLogin.belongName} ${role[adminLogin.role]}`,
          };
        });
  }

  return (
    <Header2 title={[{ title: "새소식", url: 0 }]}>
      <ToastContainer />
      <div className="admin p-[32px]">
        <div className="h-[80px] min-h-[80px]"></div>
        <label className="text-2xl font-extrabold leading-8 text-[#020A1B]">
          새소식
        </label>
        <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-3 min-w-[975px]">
          <div className="flex justify-between">
            <div className="flex items-center">
              {adminLogin.belong === 1 ? (
                <BtnBlue
                  context="등록하기"
                  onClick={() => {
                    navigate("/admin/main/newswrite");
                  }}
                  style={{ width: "auto", padding: "8px 16px" }}
                />
              ) : (
                <></>
              )}
              {loading ? (
                <svg
                  className="animate-spin h-[22px] w-[22px] ml-[13px] mr-3 rounded-full border-2 border-white border-t-blue-500 border-r-blue-500 "
                  viewBox="0 0 24 24"
                />
              ) : null}
            </div>
            {/* 검색하기 */}
            <div className="flex gap-x-2 ml-[10px]">
              <div className="flex items-center w-[290px] h-[38px] border border-[#CCCDD1] box-border px-6 py-2 rounded-[48px]">
                <img
                  src={search_icon}
                  alt="search"
                  className="w-[16.5px] h-[16.6px] mr-[9.69px]"
                />
                <input
                  type="text"
                  className="w-[210px] h-[22px] bg-transparent text-[#4E535F] focus:outline-none placeholder:font-semibold placeholder:text-base placeholder:leading-[22px] placeholder:text-[#4E535F]"
                  placeholder="제목 또는 내용으로 검색하기"
                  ref={searchRef}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      setSearch(searchRef.current.value);
                    }
                  }}
                />
              </div>
              <BtnBlue
                onClick={() => setSearch(searchRef.current.value)}
                context="검색"
                style={{
                  width: "76px",
                  height: "38px",
                  borderRadius: "48px",
                }}
              />
            </div>
          </div>
          {loading === false ? (
            <div className="flex mt-[20px] grow">
              <TableYellow
                pageMove={"/admin/main/newsid"}
                data={currentPosts(data)}
                sorting={false}
                columns={columns}
                pagination={true}
                pageSize={10}
                showAll={showAllRef.current ? onClickShowAll : false}
              />
            </div>
          ) : null}
        </div>
        <div className="w-full h-[32px]"></div>
      </div>
    </Header2>
  );
}

export default News;
