import React from "react";

const AbdominalBreathing = ({ bigTitle, btn5 }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400 wordbreak-keepall">
      <div className="pt-[72px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
        <h1 className="h2_800 pt-[4px] pb-[24px]">{btn5}</h1>
      </div>
      <div className="w-full pt-[24px] body_1_400_jangrucont">
        <span className="inline-block ">
          • 편한 자세를 취하고 한 손을 아랫배 위에 얹습니다.
        </span>

        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            공기가 코로 들어왔다가 나가는 것에 집중하면서 코를 통해 호흡을
            시작합니다. (5-6회 시행)
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            배를 이용해 좀 더 깊이 숨을 쉬어 봅시다. 배 안에 풍선이 있다고
            상상하시면 더 쉽게 하실 수 있습니다. 숨을 들이쉴 때 배 위에 있는
            손이 위로 올라가고 내쉴 때는 아래로 내려가는 것을 느껴보세요. (5-6회
            시행)
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            호흡의 속도를 천천히 하기 위해 숨을 들이쉬고 내쉴 때마다 천천히
            숫자를 세도록 합니다.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            셋을 세는 동안 숨을 들이마셔 배가 부풀어 오르는 것을 느낍니다. 다시
            셋을 세면서 천천히 숨을 내쉽니다. (5-6회 시행)
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            숨을 일부러 길게 하지 말고 평소의 호흡 속도대로 자연스럽게 숨을
            쉽니다. 익숙해지면 손을 뗀 후 계속하시면 됩니다.
          </div>
        </div>
        <span className="inline-block ">
          • 호흡은 마음의 변화에 따라 다르게 나타납니다.
        </span>
        <span className="inline-block ">• 긴장하거나 불안함 → 짧고 얕아짐</span>
        <span className="inline-block ">• 흥분하거나 화가 남 → 빨라짐</span>
        <span className="inline-block ">• 안정감과 행복감을 느낌 → 느려짐</span>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div>
            <span className="inline-block ">
              &nbsp;반대로 호흡의 변화가 마음에 영향을 줄 수도 있습니다.
            </span>
          </div>
        </div>
        <span className="inline-block ">
          • 호흡에 집중하고 깊게 호흡함 → 불안의 감소
        </span>
      </div>
      <div className="h-[80px]"></div>
    </div>
  );
};

export default AbdominalBreathing;
