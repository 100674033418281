import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import common, { api } from "../../../../data/common";
import dayjs from "dayjs";
/*component*/
import Header2 from "../../admin_components/Header/Header2";
import { ToastContainer } from "react-toastify";
import { useMainContext } from "../../../../contexts/Provider";

const MessageId = () => {
  const patient_ID = useLocation().state.patient.id;
  const dataid = useLocation().state.id;
  const [msg, setMsg] = useState([]);
  const [msgpatient, setMsgPatient] = useState([]);
  const [msgadmin, setmsgAdmin] = useState([]);
  const { adminLogin } = useMainContext(); // 로그인 정보 Consumer

  useEffect(() => {
    let pageOpen = false;

    const getMessage = () => {
      api
        .get(common.baseURL + "message/aid/" + dataid)
        .then((res) => {
          if (res.data.ok === true) {
            console.log(res.data);
            setMsg(res.data.m);
            setmsgAdmin(res.data.m.admin);
          }
        })
        .then((error) => {
          console.log(error);
        });
    };
    if (!pageOpen) {
      getMessage();
    }
    return () => {
      pageOpen = true;
    };
  }, [dataid]);

  useEffect(() => {
    let pageOpen = false;

    const getMessage = () => {
      api
        .get(common.baseURL + "patient/Id/" + patient_ID)
        .then((res) => {
          if (res.data.ok === true) {
            console.log(res.data);
            setMsgPatient(res.data.patient);
          }
        })
        .then((error) => {
          console.log(error);
        });
    };
    if (!pageOpen) {
      getMessage();
    }
    return () => {
      pageOpen = true;
    };
  }, [patient_ID]);
  // 전송한 쪽지 불러와서 listing

  const role =
    adminLogin.role === 0
      ? adminLogin.belongName + " 관리자"
      : adminLogin.role === 1
      ? adminLogin.belongName + " 의사"
      : adminLogin.belongName + " 간호사";

  const [toastElPosition] = useState({ top: 0, right: 0 }); // toast창 위치 값
  let dd = new Date(msg.readDate);

  return (
    <>
      <Header2
        title={[
          { title: "쪽지", url: "/admin/main/message" },
          {
            title: "쪽지 확인",
            url: 0,
          },
        ]}
      >
        <style jsx="true">{`
          .Toastify__toast {
            top: ${toastElPosition.top - 20}px;
            left: ${toastElPosition.left - 275}px;
          }
        `}</style>
        <ToastContainer />

        <div className="admin flex flex-col grow p-[32px]">
          <div className="h-[80px] min-h-[80px]"></div>
          <label className="text-2xl font-extrabold leading-8 text-[#020A1B]">
            쪽지 확인
          </label>
          <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-3 min-w-[975px]">
            <div className="grid grid-cols-10 min-w-[915px] text-center border border-[#E5E6E7] bg-[#fafbfc] ">
              <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-2">
                작성자
              </label>
              <label className="flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7] bg-[#F1F2F3] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-8">
                {msgadmin.name + " (" + role + ")"}
              </label>

              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-2">
                받는 사람
              </label>
              <label className="flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7] bg-white font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-8">
                {msgpatient.name + " (" + msgpatient.patientID + ")"}
              </label>

              <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-2">
                내용
              </label>
              <label className=" flex justify-start items-center p-4 border border-[#E5E6E7] box-border  bg-white col-span-8">
                <div
                  className="p-2 text-left"
                  dangerouslySetInnerHTML={{ __html: msg.content }}
                ></div>
              </label>
              <label
                onClick={() => console.log(new Date(msg.createdAt))}
                className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-2"
              >
                발송일
              </label>
              <label className="flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7] bg-white font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-5">
                {dayjs(new Date(msg.createdAt)).format("YYYY.MM.DD")}
              </label>
              <label
                onClick={() => {
                  console.log(dd);
                }}
                className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-1"
              >
                수신확인
              </label>
              <label className="flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7] bg-white font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-2">
                {msg.readDate === null ? "-" : dayjs(dd).format("YYYY.MM.DD")}
              </label>
            </div>
          </div>
        </div>
      </Header2>
    </>
  );
};

export default MessageId;
