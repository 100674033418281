import React, { useState, useRef, useEffect } from "react";
import common, { api } from "../../../../data/common";

import { ToastContainer, toast } from "react-toastify";
import { useMainContext } from "../../../../contexts/Provider";
import { useNavigate } from "react-router-dom";
import MyinfoEditModal from "./components/MyinfoEditModal";
import { resizeFile } from "../../../../resizeFile";
/*component*/

const DataEdit = () => {
  const navigate = useNavigate();
  const { loginInput } = useMainContext();
  const fileInputRef = useRef();
  const leftarrow = "/images/mo/leftarrow.svg";
  const defaultimage = "/images/mo/defaultimage.svg";
  const myinfocamera = "/images/mo/myinfocamera.svg";

  const [nicknameInput, setNicknameInput] = useState({
    photoURL: "",
    nickname: "",
  });
  const [changeError, setChangeError] = useState("");

  const [myinfoImage, setMyinfoImage] = useState();
  const [myinfoeditModal, setMyinfoEditmodal] = useState(false);
  const [files, setFiles] = useState([]);
  const [uploadedImage, setUploadedImage] = useState(null);

  const onChangeNickname = (e) => {
    const { value, name } = e.target;
    setNicknameInput({ ...nicknameInput, [name]: value.slice(0, 6) });
  };

  const clickCamera = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const fileChange = async (e) => {
    const file = await resizeFile(e.target.files[0]);

    setNicknameInput({
      ...nicknameInput,
      photoURL: file.lastModified + file.name,
    });

    const reader = new FileReader();

    setFiles(file);
    reader.readAsDataURL(file);
    reader.onload = () => {
      setUploadedImage(reader.result);
    };
  };

  const nickNameCheck = () => {
    if (nicknameInput.nickname.length < 2) {
      setChangeError("2자리 이상 입력해주세요.");
      return;
    }
    api
      .get(common.baseURL + `patient/all/${loginInput.belongid}`)
      .then((res) => {
        if (res.data.ok === true) {
          const allPatient = res.data.patientList;
          const samenickname = allPatient.filter(
            (el) => el.nickName === nicknameInput.nickname
          );
          if (samenickname.length > 0) {
            setNicknameInput({ ...nicknameInput, nickname: "" });
            setChangeError("이미 사용중인 닉네임입니다.");
          } else if (
            samenickname.length === 0 &&
            nicknameInput.nickname !== ""
          ) {
            setChangeError("사용가능한 닉네임입니다.");
          } else {
            setChangeError("빈값을 채우세요.");
          }
        }
      });
  };

  const showMyInfoEditModal = () => {
    setMyinfoEditmodal(!myinfoeditModal);
  };

  useEffect(() => {
    const getPatientId = () => {
      api.get(common.baseURL + "patient/Id/" + loginInput.id).then((res) => {
        console.log(res);
        if (res.data.ok === true) {
          setMyinfoImage(res.data.patient.profileURL);
        }
      });
    };

    getPatientId();
  }, []);

  const submitData = () => {
    let body = {
      id: loginInput.id,
      nickName: nicknameInput.nickname ? nicknameInput.nickname : undefined,
      profileURL: nicknameInput.photoURL ? nicknameInput.photoURL : undefined,
    };
    if (uploadedImage !== null) {
      const formData = new FormData();
      formData.append("file", files);
      formData.append("name", nicknameInput.photoURL);
      for (const keyValue of formData) console.log(keyValue);
      api.post(common.baseURL + "upload/oneNew", formData).then((awsres) => {
        if (awsres.data.ok === true) {
          api
            .post(common.baseURL + "patient/edit/mobile", body)
            .then((editRes) => {
              console.log(editRes);
              if (editRes.data.ok === true) {
                showMyInfoEditModal(true);
              } else {
                toast.error(editRes.data.error, {
                  autoClose: 3000,
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            });
        } else {
          toast.error(awsres.data.error, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    } else {
      api.post(common.baseURL + "patient/edit/mobile", body).then((editRes) => {
        console.log(editRes);
        if (editRes.data.ok === true) {
          showMyInfoEditModal(true);
        } else {
          toast.error(editRes.data.error, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  return (
    <>
      {myinfoeditModal ? <MyinfoEditModal /> : ""}
      <div className="h-screen bg-[#FAFCFF] overflow-auto">
        <div className="w-full px-[8px] flex justify-between items-center border-b subtitle_1_400">
          <img
            className="inline-block cursor-pointer"
            src={leftarrow}
            alt="leftarrow"
            onClick={() => {
              navigate(-1);
            }}
          />
          <span className="inline-block">정보변경</span>

          {(nicknameInput.nickname !== "" &&
            changeError === "사용가능한 닉네임입니다.") ||
          uploadedImage !== null ? (
            <span
              className="inline-block subtitle_2 text-white bg-[#1B5EDC] hover:bg-[#18428E] ease-in-out duration-300 rounded-full px-[14px] py-[4px] cursor-pointer"
              onClick={submitData}
            >
              저장
            </span>
          ) : (
            <span className="inline-block cursor-pointer subtitle_2 text-white bg-[#9DC4FA] rounded-full px-[14px] py-[4px] ">
              저장
            </span>
          )}
        </div>
        <div className="pt-[24px]">
          <div className="flex items-end justify-center h-20 mx-auto w-28">
            <div className="w-20 h-20 overflow-hidden border rounded-full write__img__box">
              {/* 처음 페이지 렌더링시 기존 프로필이미지 있으면 보여줌 */}
              {/* 이후 유저가 이미지 업로드하면 새로 업로드한 이미지를 미리보기 시켜줌 */}
              {uploadedImage ? (
                <img
                  src={uploadedImage}
                  alt="uploadedImage"
                  className="w-full h-full"
                />
              ) : myinfoImage ? (
                <img
                  src={`${common.networkImg + encodeURIComponent(myinfoImage)}`}
                  alt="infoimage"
                  className="w-full h-full"
                />
              ) : (
                <img
                  className="w-full h-full"
                  src={defaultimage}
                  alt="defaultimage"
                />
              )}
            </div>

            <img
              src={myinfocamera}
              alt="myinfocamera"
              onClick={clickCamera}
              className="w-6 h-6 cursor-pointer"
            />
          </div>

          <input
            type="file"
            ref={fileInputRef}
            name="photoURL"
            onChange={fileChange}
            accept=".jpg,.png,.jpeg"
            hidden
          />
        </div>
        {/* <div className="pt-[28px] px-[24px]">
          <div className="block subtitle_1_600">닉네임</div>
          <input
            className="dataedit w-full py-[12px] inline-block border-b border-black focus:outline-none bg-transparent"
            name="nickname"
            placeholder="한글, 영문, 숫자 입력이 가능합니다.(2~6자리 내외)"
            value={nicknameInput.nickname}
            onChange={onChangeNickname}
          />
          <span
            className="rounded-sm mt-[16px] inline-block w-full border border-[#1B5EDC] bg-[#FAFCFF] hover:border-[#18428E] hover:bg-[#F2F6FC] hover:text-[#18428E] ease-in-out duration-300 rounde-sm cursor-pointer py-[16px] text-center text-[#1B5EDC] button_700"
            onClick={nickNameCheck}
          >
            중복확인
          </span>
          <ToastContainer />
        </div> */}

        {changeError === "사용가능한 닉네임입니다." ? (
          <span className="pt-[16px] px-[24px] text-[#1B5EDC] caption_1_600 font-semibold inline-block">
            {changeError}
          </span>
        ) : (
          <span className="pt-[16px] px-[24px] text-[#F62828] caption_1_600 font-semibold inline-block">
            {changeError}
          </span>
        )}
      </div>
    </>
  );
};

export default DataEdit;
