import dayjs from "dayjs";
import "dayjs/locale/ko";
import TableBlue from "../../admin_components/TableBlue";

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header2 from "../../admin_components/Header/Header2";
import { useMainContext } from "../../../../contexts/Provider";
import common, { api } from "../../../../data/common";

import { Flip, toast, ToastContainer } from "react-toastify";
import { toastCommonProps } from "../../admin_components/Toast";
import { BtnBlue } from "../../admin_components/Buttons";

export function Message() {
  const showAllRef = useRef(false); // 전체보기 버튼 보여줄지 말지

  const navigate = useNavigate();
  const dataRef = useRef([]); // 표기할 정보 담기
  const { adminLogin } = useMainContext(); // 로그인 정보 Consumer
  /*** 검색 관련 state, ref ***/
  const [data, setData] = useState([]); // 표기할 정보 담기
  const [search, setSearch] = useState("");
  const searchRef = useRef();

  const [loading, setLoading] = useState(false); //
  const search_icon = "/images/admin/header_dash/search.svg";

  // 검색 버튼 클릭했을 때
  const searchClick = () => {
    setSearch(searchRef.current.value);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      searchClick();
    }
  };

  // 검색버튼을 누르고 search 가 바뀌었을 시
  useEffect(() => {
    console.log(dataRef.current);
    if (search !== "") {
      const newData = dataRef.current.filter((el) => {
        return (
          el.content.includes(search) ||
          el.patient.name.includes(search) ||
          el.patient.patientID.includes(search)
        );
      });
      if (newData.length === 0) {
        toast(
          <p>검색된 내용이 없습니다.</p>,
          toastCommonProps(
            2000,
            "top-right",
            "toast_common toast__search",
            Flip
          )
        );
        setSearch("");
        searchRef.current.value = ""; // 검색창 빈칸으로 만들기
      } else {
        setData(newData);
        showAllRef.current = true;
      }
    }
  }, [search]);
  // 전체 보기 클릭시
  const onClickShowAll = () => {
    setData(dataRef.current);
    setSearch("");
    searchRef.current.value = ""; // 검색창 빈칸으로 만들기
    showAllRef.current = false;
  };
  // 첫 랜더시 데이터 불러오기()
  useEffect(() => {
    console.log(adminLogin.id);
    async function fetchData() {
      setLoading(true);
      const response = await api.get(
        common.baseURL + "message/a/" + adminLogin.id
      );
      console.log(response.data);
      dataRef.current = response.data.mList;
      setData(dataRef.current);
      setLoading(false);
    }
    fetchData();
  }, [adminLogin.id]);

  /******************************* Table UI 관련 변수 & 함수들  ******************************/
  // Table에 들어갈 column명
  const columns = [
    {
      accessor: "idx",
      Header: "No",
      width: "5%",
    },
    {
      accessor: "contents",
      Header: "내용",
      width: "30%",
    },
    {
      accessor: "name",
      Header: "받는 사람(등록번호)",
      width: "20%",
    },
    {
      accessor: "createdAt",
      Header: "발송일",
      width: "15%",
    },

    {
      accessor: "readDate", //활성화 여부 , backend와 논의
      Header: "수신확인",
      width: "15%",
    },
  ];

  // Pagination위해 한페이지 당 보여줄 내용 다듬어서 내보냄
  function currentPosts(data) {
    let newData = [];
    let count = 1;
    if (data === undefined) data = [];
    const sort_date = data.map((col) => {
      return col.createdAt;
    });

    const sort_list = sort_date.sort().reverse();
    let idle = 0;
    newData = data.map((d) => {
      for (let i = 0; i < sort_list.length; i++)
        if (d.createdAt === sort_list[i]) idle = i;

      return { ...d, idx: idle + count };
    });
    let n_data = [];
    for (let i = 0; i < newData.length; i++) {
      for (let k = 0; k < newData.length; k++)
        if (newData[k].idx === i + 1) n_data[i] = newData[k];
    }

    // 페이지 당 데이타 갯수 15개로 자르기
    let currentPosts = n_data;
    for (let i = 0; i < currentPosts.length; i++) {
      currentPosts[i].idx = currentPosts.length - i;
    }
    return currentPosts.map((post, idx) => {
      let dd = new Date(post.readDate);
      dd.setHours(dd.getHours() - 9);
      let newContens = post.content;
      let newName = post.patient.name;
      let newPatientID = post.patient.patientID;
      let newCreatedAt = dayjs(new Date(post.createdAt)).format("YYYY.MM.DD");
      let newReadDate =
        post.readDate === null ? "-" : dayjs(dd).format("YYYY.MM.DD");

      return {
        ...post,
        contents: newContens,
        name: `${newName}(${newPatientID})`,
        createdAt: newCreatedAt,
        readDate: newReadDate,
      };
    });
  }

  return (
    <Header2 title={[{ title: "쪽지", url: 0 }]}>
      <ToastContainer />

      <div className="admin p-[32px]">
        <div className="h-[80px] min-h-[80px]"></div>
        <label className="text-2xl font-extrabold leading-8 text-[#020A1B]">
          쪽지
        </label>
        <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-3 min-w-[975px]">
          <div className="flex justify-between">
            <div className="flex items-center">
              <BtnBlue
                context="작성하기"
                onClick={() => {
                  navigate("/admin/main/messagewrite");
                }}
                style={{ width: "auto", padding: "8px 16px" }}
              />

              {loading ? (
                <svg
                  className="animate-spin h-[22px] w-[22px] ml-[13px] mr-3 rounded-full border-2 border-white border-t-blue-500 border-r-blue-500 "
                  viewBox="0 0 24 24"
                />
              ) : null}
            </div>

            {/* 검색하기 */}
            <div className="flex gap-x-2 ml-[10px]">
              <div className="flex items-center w-[290px] h-[38px] border border-[#CCCDD1] box-border px-6 py-2 rounded-[48px]">
                <img
                  src={search_icon}
                  alt="search"
                  className="w-[16.5px] h-[16.6px] mr-[9.69px]"
                />
                <input
                  type="text"
                  className="w-[210px] h-[22px] bg-transparent text-[#4E535F] focus:outline-none placeholder:font-semibold placeholder:text-base placeholder:leading-[22px] placeholder:text-[#4E535F]"
                  placeholder="내용 또는 받는사람으로 검색하기"
                  ref={searchRef}
                  onKeyPress={onKeyPress}
                />
              </div>
              <BtnBlue
                onClick={searchClick}
                context="검색"
                style={{
                  width: "76px",
                  height: "38px",
                  borderRadius: "48px",
                }}
              />
            </div>
          </div>
          {loading === false ? (
            <div className="mt-[20px] y-[10px]">
              <TableBlue
                columns={columns}
                data={currentPosts(data)}
                pageMove={"/admin/main/messageId"}
                pageSizeNum={10}
                sorting={false}
                pagination={true}
                showAll={showAllRef.current ? onClickShowAll : false}
              />
            </div>
          ) : null}
        </div>
        <div className="w-full h-[32px]"></div>
      </div>
    </Header2>
  );
}

export default Message;
