import Header2 from "../../../admin_components/Header/Header2";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import commonUrl, { api } from "../../../../../data/common";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import PopUp from "./PopUp";
import {
  BtnBlack,
  BtnWhiteBack,
  BtnWhiteList,
} from "../../../admin_components/Buttons";
import ReactQuill from "react-quill";
import { convertRole } from "../../../admin_components/ConvertAdmin";
import { useMainContext } from "../../../../../contexts/Provider";
import ChartDataLabels from "chartjs-plugin-datalabels";
import dayjs from "dayjs";
import { handlingError } from "../../../admin_components/Error";
import common from "../../../../../data/common";
const radio_on = "/images/mo/radio_on.svg";
const radio_off = "/images/mo/radio_off.svg";

const understandingList = [
  "거의 이해 못함",
  "대부분 이해 못함",
  "보통 이해함",
  "대부분 이해함",
  "전부 이해함",
];

const JangruCheck = () => {
  const headerSend = "/images/admin/header_dash/header_send.svg";
  const navigate = useNavigate();
  const { adminLogin } = useMainContext();
  const { state } = useLocation();
  const [inspect, setInspect] = useState({
    id: "",
    weight: "",
    symptom: [],
    changeDate: "",
    changeCycle: "",
    jangruComplicationsOX: "",
    skinComplicationsOX: "",
    dehydrationOX: "",
    imageURL: [],
    imageText: "",
    question: "",
    writer: "",
    createdAt: "",
    understanding: "",
  });
  const [radioBtn, setRadioBtn] = useState(null);
  const [check, setCheck] = useState({}); // 평가 내용
  const [reply, setReply] = useState({}); // 상담 내용
  const [patient, setPatient] = useState({}); // 환자 정보
  const [flexCol, setFlexCol] = useState(() => {
    if (window.innerWidth <= 1440) return true;
    else return false;
  }); // viewport가 너비 1280 이하일 때 flex-row -> flex-col
  console.log(inspect);
  /*  */
  const [async, setAsync] = useState({
    loading: false,
    data: null,
  });

  /********************** popup 관련 state, 함수들 **********************/
  // popup open 여부
  const [open, setOpen] = useState(false);

  // popup 닫을 시 실행하는 함수
  const closeModal = () => setOpen(false);

  // popup & 상담내용 저장했을 시 다시 업데이트 된 정보를 불러오는 함수
  const updateData = async () => {
    setAsync({ ...async, loading: true });
    const response = await api({
      method: "post",
      url: commonUrl.jangruReply + "forPatient",
      data: {
        createdAt: inspect.createdAt.substring(0, 10),
        id: inspect.id,
      },
    }).catch((error) => {
      handlingError(error);
    });
    console.log(response.data, ">>> Axios -> updateData");
    if (response.data.jangruReplyNurse)
      setReply(response.data.jangruReplyNurse);
    if (response.data.jangruCheck) setCheck(response.data.jangruCheck);
    setAsync({ ...async, loading: false });
  };
  console.log(check?.id, reply?.id);
  // axios로 불러온 데이타 UI에 맞게 가공하여 state에 저장시키는 함수
  const handleData = (data) => {
    // 받은 데이터 변수이름 단순화
    const {
      id,
      weight, // 체중
      symptom, // 현재 상황
      symptomNote, // 현재상황 기타
      changeDate, // 장루 교환일
      changeCycle, // 장루 교환 주기
      jangruComplicationsOX, // 장루 합병증
      skinComplicationsOX, // 피부 합병증
      dehydrationOX, // 탈수
      imageURL, // 장루/피부 사진
      imageText, // 사진 설명
      question, // 문의 내용
      writer, // 작성자
      createdAt, // 작성 일시
    } = data.jangruSurvey;

    let newCreatedAt = ""; // 작성 일시
    let newSymptom = []; // 현재 상황
    let newJcOX = ""; // 장루 합병증
    let newScOX = ""; // 피부 합병증
    let newDOX = ""; // 탈수
    let newUnderstanding = ""; // 관리 내용 이해 정도

    // 작성 일시
    let hour =
      createdAt.substring(11, 13) > 12
        ? createdAt.substring(11, 13) - 12
        : createdAt.substring(11, 13);
    let minute = createdAt.substring(14, 16);
    if (createdAt.substring(11, 13) >= 12) {
      newCreatedAt = `${createdAt.substring(0, 10)} PM ${hour}:${minute}`;
    } else {
      newCreatedAt = `${createdAt.substring(0, 10)} AM ${hour}:${minute}`;
    }
    newCreatedAt = dayjs(new Date(createdAt)).format("YYYY-MM-DD A hh:mm");

    // 현재 상황
    if (symptom !== null) {
      newSymptom = symptom
        .map((el) => {
          if (el === 1) return "문제없음";
          else if (el === 2) return "장루교체가 능숙하지 않음";
          else if (el === 3) return "일상생활·사회활동 어려움";
          else if (el === 4) return "식생활·영양관리 어려움";
          else if (el === 5) return "대변 누출이 잦음";
          else if (el === 6) return "심리적 어려움";
          else if (el === 7) return symptomNote;
          else return "";
        })
        .join(", ");
    } else {
      newSymptom = "작성 내용 없음";
    }

    // 장루 합병증
    if (jangruComplicationsOX === "O") newJcOX = "유";
    else newJcOX = "무";

    // 피부 합병증
    if (skinComplicationsOX === "O") newScOX = "유";
    else newScOX = "무";

    // 탈수
    if (dehydrationOX === "O") newDOX = "유";
    else if (dehydrationOX === "X") newDOX = "무";
    else newDOX = "해당 없음";

    // 상태 미리보기 처리
    // if (data.understanding) {
    //   if (data.understanding.degree === 0) newUnderstanding = "거의 이해 못함";
    //   else if (data.understanding.degree === 1)
    //     newUnderstanding = "대부분 이해 못함";
    //   else if (data.understanding.degree === 2)
    //     newUnderstanding = "보통 이해함";
    //   else if (data.understanding.degree === 3)
    //     newUnderstanding = "대부분 이해함";
    //   else if (data.understanding.degree === 4)
    //     newUnderstanding = "전부 이해함";
    //   else newUnderstanding = "미작성";
    // } else newUnderstanding = "미작성";

    // cliet 변수에 저장
    setInspect({
      id,
      weight,
      symptom: newSymptom,
      changeDate,
      changeCycle,
      jangruComplicationsOX: newJcOX,
      skinComplicationsOX: newScOX,
      dehydrationOX: newDOX,
      imageURL,
      imageText: imageText === null ? "작성 내용 없음" : imageText,
      question: question === null ? "작성 내용 없음" : question,
      writer,
      createdAt: newCreatedAt,
      understanding: data.understanding?.degree || null,
    });
  };

  // resize시 그래프 세로 길이 변경
  const handleResize = () => {
    if (window.innerWidth <= 1440) setFlexCol(true);
    else setFlexCol(false);
  };

  /*** 첫 랜더시 환자 id로 정보 불러오기***/
  useEffect(() => {
    // 비동기 함수
    async function fetchData() {
      setAsync({ ...async, loading: true });
      const res = await api({
        method: "get",
        url: commonUrl.jangruSurvey + state.data.surveyId,
      }).catch((error) => {
        handlingError(error);
      });
      console.log(res.data, ">>> Axios -> jangruSurvery + id");
      if (res.data.ok) {
        // UI에 맞게 데이타 가공
        handleData(res.data);
        // popup 페이지 정보 불러오기 //\
        const response = await api({
          method: "post",
          url: commonUrl.jangruReply + "forPatient",
          data: {
            createdAt: res.data.jangruSurvey.createdAt.substring(0, 10),
            id: res.data.jangruSurvey.id,
          },
        }).catch((error) => {
          handlingError(error);
        });
        console.log(response.data, ">>> Axios -> forPatient");
        if (response.data.jangruReplyNurse)
          setReply(response.data.jangruReplyNurse);
        if (response.data.jangruCheck) setCheck(response.data.jangruCheck);
        if (response.data.patient)
          setPatient({
            ...response.data.patient,
            jangruSurveyCreated: response.data.jangruSurveyCreated,
          });
      }
      setAsync({ ...async, loading: false });
    }
    fetchData();

    // resize detect
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveUnderstanding = () => {
    let body = {
      surveyId: inspect.id,
      degree: radioBtn,
    };

    api
      .post(common.baseURL + "jangruSurvey/addUnderstanding", body)
      .then((res) => {
        if (res.data.ok === true) {
          setInspect({ ...inspect, understanding: radioBtn });
        } else {
          alert("이해정도 저장에 문제가 있습니다.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Header2
        title={[
          {
            title: "장루점검",
            url: "/admin/main/check",
          },
          {
            title: "대상자 상세보기",
            url: -1,
            state: patient.id
              ? { data: { id: patient.id, prevPath: undefined } }
              : undefined,
          },
          { title: "장루 상태 평가", url: 0 },
        ]}
      >
        <style jsx="true">{`
          .ql-editor {
            width: auto;
            padding: 0;
          }
          .ql-container {
            overflow: "auto";
            max-height: 700px;
          }
        `}</style>
        {/* 장루 평가 팝업 Component*/}
        <PopUp
          open={open}
          onClose={closeModal}
          data={{
            ...check,
            imageURL: inspect.imageURL,
            jangruSurvey: inspect.id,
          }}
          updateData={updateData}
        />
        <div className="admin grow p-[32px]">
          <div className="h-[80px] min-h-[80px]"></div>
          {/* 목록 & 뒤로가기 버튼 */}
          <div className="flex items-center mb-3 gap-x-2">
            <BtnWhiteList
              to={"/admin/main/check"}
              context="대상자 목록보기"
              style={{ width: "173px", height: "40px" }}
            />
            <BtnWhiteBack
              to={"/admin/main/check/detail"}
              state={{
                // data: {
                id: state.detailPageId,
                prevPath: "장루 상태평가",
                // },
              }}
            />
          </div>
          {/* 표 + 상담내용 + 그래프 */}
          <div
            className={
              flexCol ? "flex flex-col w-full" : "flex w-full gap-x-6 "
            }
          >
            <div className="flex flex-col grow mb-[24px]">
              {/* 표 */}
              <div className="flex flex-col p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mb-6">
                {/* <div className="flex justify-between"> */}
                {patient.name && patient.patientID ? (
                  <div className="flex items-center justify-between">
                    <div className="flex items-start gap-x-6">
                      <p className="flex font-extrabold align-middle text-lg text-[#343B48] leading-[22px] text-[#4E535F] box-border mb-[10px]">
                        {`${patient.name}(${patient.patientID})`}
                      </p>
                      {/* 쪽지하기 버튼 */}
                      {/* 일반관리자일 경우 쪽지 불가 */}
                      {adminLogin.belong === 1 &&
                      adminLogin.power === 1 ? null : (
                        <img
                          src={headerSend}
                          alt="headerSend"
                          className="w-[20px] h-[17px] mt-1 cursor-pointer "
                          onClick={() => {
                            navigate("/admin/main/messagewrite", {
                              state: {
                                patientID: state.data.patientID,
                              },
                            });
                          }}
                        />
                      )}
                    </div>

                    <div className="flex font-normal text-base leading-[22px] text-[#343B48]">
                      {`작성일시 | ` + inspect.createdAt}
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center h-[38px] w-[50px]"></div>
                )}

                {/* </div> */}

                {/* 표 내용*/}
                <div className="grid grid-cols-[18%_14%_15%_18%_20%_15%] min-w-[690px] max-w-[1187px] text-center border border-[#E5E6E7]">
                  {/* 첫번째 줄 */}
                  <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                    체중
                  </label>
                  <label className="flex justify-start items-center px-6 py-[7px] border border-[#E5E6E7] font-normal text-base leading-[22px] text-[#020A1B] box-border">
                    {`${inspect.weight}kg`}
                  </label>
                  <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                    장루 교환일
                  </label>
                  <label className="flex justify-start items-center px-6 py-[7px] border border-[#E5E6E7] font-normal text-base leading-[22px] text-[#020A1B] box-border">
                    {inspect.changeDate.replace(/-/gi, ".")}
                  </label>
                  <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                    장루 교환 주기
                  </label>
                  <label className="flex justify-start items-center px-6 py-[7px] border border-[#E5E6E7] font-normal text-base leading-[22px] text-[#020A1B] box-border">
                    {`${inspect.changeCycle}일`}
                  </label>
                  {/* 두번째 줄 */}
                  <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                    현재 상황
                  </label>
                  <label className="flex justify-start items-center text-left px-6 py-[7px] border border-[#E5E6E7] font-normal text-base leading-[22px] text-[#020A1B] box-border col-span-5">
                    {inspect.symptom}
                  </label>
                  {/* 세번째 줄 */}
                  <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                    장루 합병증
                  </label>
                  <label className="flex justify-start items-center px-6 py-[7px] border border-[#E5E6E7] font-normal text-base leading-[22px] text-[#020A1B] box-border">
                    {inspect.jangruComplicationsOX}
                  </label>
                  <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                    피부 합병증
                  </label>
                  <label className="flex justify-start items-center px-6 py-[7px] border border-[#E5E6E7] font-normal text-base leading-[22px] text-[#020A1B] box-border">
                    {inspect.skinComplicationsOX}
                  </label>
                  <p className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                    탈수<span className="text-[13px]">(회장루만 해당)</span>
                  </p>
                  <label className="flex justify-start items-center px-6 py-[7px] border border-[#E5E6E7] font-normal text-base leading-[22px] text-[#020A1B] box-border">
                    {inspect.dehydrationOX}
                  </label>
                  {/* 네번째 줄 */}
                  <label className="flex justify-start items-center text-left px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                    장루/피부 사진
                  </label>
                  <div className="flex flex-col h-full py-[8px] px-6 border border-[#E5E6E7] col-span-5">
                    <div className="flex flex-wrap overflow-hidden mb-2 gap-x-[4px]">
                      {inspect.imageURL.map((el, idx) => {
                        console.log(inspect.imageURL[idx]);
                        return (
                          <div
                            className=" w-full min-w-[72px] min-h-[72px] max-w-[72px] max-h-[72px] cursor-pointer"
                            key={idx}
                            onClick={() => {
                              setOpen(true);
                            }}
                          >
                            <img
                              className="w-[88px] h-[88px] object-contain"
                              src={
                                commonUrl.networkImg +
                                encodeURIComponent(inspect.imageURL[idx])
                              }
                              alt={`${idx + 1}.jpg`}
                            />
                          </div>
                        );
                      })}
                    </div>
                    {/* check 비었는지 체크 */}
                    {adminLogin.belong === 1 && adminLogin.power === 1 ? (
                      <label className="flex justify-start items-center text-base text-normal leading-[22px] text-[#1B5EDC]">
                        *장루/피부 사진 선택하여 합병증을 평가내용을 확인하세요.
                      </label>
                    ) : Object.keys(check).length === 0 ? (
                      <label className="flex justify-start items-center text-base text-normal leading-[22px] text-[#F62828]">
                        *장루/피부 사진 선택하여 합병증을 평가해주세요.
                      </label>
                    ) : (
                      <label className="flex justify-start items-center text-base text-normal leading-[22px] text-[#1B5EDC]">
                        *장루/피부 합병증 평가를 완료하였습니다.
                      </label>
                    )}
                  </div>
                  {/* 다섯번째 줄 */}
                  <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                    사진 설명
                  </label>
                  <p className="text-left px-6 py-[7px] border border-[#E5E6E7] font-normal text-base leading-[22px] text-[#020A1B] box-border col-span-5 break-all">
                    {inspect.imageText}
                  </p>
                  {/* 여섯번째 줄 */}
                  <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                    문의 내용
                  </label>
                  <p className="flex justify-start items-center text-left px-6 py-[7px] border border-[#E5E6E7] font-normal text-base leading-[22px] text-[#020A1B] box-border col-span-5">
                    {inspect.question}
                  </p>
                  {/* 일곱번째 줄 */}
                  <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                    작성자
                  </label>
                  <label className="flex justify-start items-center px-6 py-[7px] border border-[#E5E6E7] font-normal text-base leading-[22px] text-[#020A1B] box-border col-span-5">
                    {inspect.writer}
                  </label>
                  <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                    관리 내용 이해정도
                  </label>
                  <div className="flex justify-start items-center px-6 py-[7px] border border-[#E5E6E7] font-normal text-base leading-[22px] text-[#020A1B] box-border col-span-5">
                    {reply?.id === undefined || check?.id === undefined ? (
                      "평가중"
                    ) : inspect.understanding !== null ? (
                      understandingList[inspect.understanding]
                    ) : (
                      <div className="flex items-center justify-between w-full">
                        <ul className="flex gap-4">
                          {understandingList.map((el, idx) => (
                            <li
                              className="cursor-pointer flex items-center gap-x-[2px]"
                              onClick={(e) => setRadioBtn(idx)}
                              key={idx}
                            >
                              {idx === radioBtn ? (
                                <img src={radio_on} alt={radio_on} />
                              ) : (
                                <img src={radio_off} alt={radio_off} />
                              )}
                              <span>{el}</span>
                            </li>
                          ))}
                        </ul>
                        <button
                          className={`${
                            radioBtn === null ? "bg-slate-400" : "bg-[#1B5EDC]"
                          } px-2 py-1 text-white rounded-lg`}
                          disabled={radioBtn === null}
                          onClick={saveUnderstanding}
                        >
                          저장하기
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* 상담내용 or 그래프들 */}
              {flexCol ? (
                <Graphs state={state ? state.data : null} flexCol={flexCol} />
              ) : (
                <ReplyComponent
                  reply={reply}
                  state={state}
                  inspect={inspect}
                  patient={patient}
                  updateData={updateData}
                />
              )}
            </div>
            {/* 그래프들 or 상담내용 */}
            {flexCol ? (
              <>
                <ReplyComponent
                  reply={reply}
                  state={state}
                  inspect={inspect}
                  patient={patient}
                  updateData={updateData}
                />
                <div className="w-full min-h-[32px]"></div>
              </>
            ) : (
              <Graphs state={state ? state.data : null} flexCol={flexCol} />
            )}
          </div>
        </div>
      </Header2>
    </>
  );
};

/* 상담 내용 미리보기 &  작성 부분 */
const ReplyComponent = ({ reply, state, inspect, patient, updateData }) => {
  const { adminLogin } = useMainContext();
  const navigate = useNavigate();

  // 서비스 제공내용 체크 박스 컴포넌트 내보내는 함수
  const showService = (serviceArray) => {
    let serviceCheck = new Array(11).fill(false);
    serviceArray.forEach((el) => {
      serviceCheck[el] = true;
    });
    if (serviceCheck[9]) serviceCheck[10] = true;

    return [
      "장루 기능 상태 확인",
      "올바른 장루 교환 수행 여부 확인",
      "장루 및 피부 상태 점검",
      "합병증 예방 관리​",
      "병원 방문이 필요한 상황",
      "식생활/영양/체중 관리",
      "일상생활 관리​",
      "정신건강 관리",
      "기타",
      reply.serviceText,
    ].map((service, idx) => {
      if (idx === 10) {
        if (!serviceCheck[9]) {
          return null;
        }
      }
      return (
        <div
          key={idx}
          className={
            serviceCheck[idx]
              ? "flex justify-center items-center py-1 px-4 bg-[#4E535F] text-base leading-[22px] font-normal text-[#FAFBFC] border border-[#4E535F] rounded-[18px]"
              : "flex justify-center items-center py-1 px-4 bg-[#FAFBFC] text-base leading-[22px] font-normal text-[#4E535F] border border-[#4E535F] rounded-[18px]"
          }
        >
          <p>{service}</p>
        </div>
      );
    });
  };
  return (
    <div className="flex flex-col p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)]">
      {Object.keys(reply).length === 0 ? (
        <>
          <p className="flex justify-start font-extrabold text-xl leading-[26px] text-[#343B48] mb-4">
            상담 내용
          </p>
          <div className="flex justify-center items-center h-[200px] w-full rounded-lg bg-[#F1F2F3]">
            {adminLogin.belong === 1 && adminLogin.power === 1 ? (
              <p className="text-center text-base leadidng-[22px] font-normal text-[#343B48]">
                작성된 내용이 없습니다.
              </p>
            ) : (
              <p
                className="text-center text-base leadidng-[22px] font-normal text-[#343B48] cursor-pointer"
                onClick={() => {
                  navigate(`/admin/main/check/reply`, {
                    state: {
                      data: {
                        ...reply,
                        pid: patient.id,
                        jangruSurvey: inspect.id,
                        detailPageId: patient.id,
                      },
                    },
                    updateData,
                  });
                }}
              >
                작성된 내용이 없습니다.
                <br /> 여기를 클릭하여 내용을 작성해 주세요.
              </p>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-between items-center mb-[18px]">
            <p className="font-extrabold leading-[26px] text-xl text-[#343B48]">
              상담 내용
            </p>
            {adminLogin.belong === 1 && adminLogin.power === 1 ? null : (
              <BtnBlack
                context="수정하기"
                onClick={() => {
                  navigate(`/admin/main/check/reply`, {
                    state: {
                      data: { ...reply, jangruSurvey: inspect.id },
                      detailPageId: patient.id,
                    },
                  });
                }}
                style={{
                  width: "120px",
                  height: "40px",
                }}
              />
            )}
          </div>
          {/* 표 형식 상담 내용*/}
          <div className="grid grid-cols-4 text-center border border-[#E5E6E7]">
            {/* 작성자, 작성일시 */}
            <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
              작성자
            </label>
            <label className="flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7] bg-[#F1F2F3] font-normal text-base leading-[22px] text-[#020A1B] box-border col-span-3">
              {reply.admin
                ? reply.admin.name + " " + convertRole(reply.admin.role)
                : ""}
            </label>
            {/* 내용 */}
            <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
              내용
            </label>
            <div className="p-6 col-span-3 border border-[#E5E6E7]">
              <ReactQuill
                className="flex text-left !p-0 items-center font-bold "
                theme="bubble"
                value={reply.reply ? reply.reply : ""}
                readOnly
                style={{
                  height: "100%",
                }}
              />
            </div>

            {/* 첨부파일 */}
            <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
              첨부 파일
            </label>
            <div className="flex flex-col items-start gap-y-2 p-6 border border-[#E5E6E7] text-base leading-[22px] font-normal text-[#020A1B] col-span-3 ">
              {reply.attachedFileURL
                ? reply.attachedFileURL.map((el, idx) => {
                    return (
                      <label
                        className="underline cursor-pointer font-normal text-base leading-[22px] text-[#020A1B]"
                        key={idx}
                        onClick={() => {
                          window.open(
                            `${commonUrl.networkImg}${encodeURIComponent(el)}`
                          );
                        }}
                      >
                        {`${el}\n`}
                      </label>
                    );
                  })
                : ""}
            </div>
            {/* 서비스 제공내용 */}
            <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
              *서비스 제공 내용
            </label>
            <div className="flex flex-wrap text-left p-6 border border-[#E5E6E7] col-span-3 gap-x-2 gap-y-2">
              {reply.service ? showService(reply.service) : ""}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const Graphs = ({ state, flexCol }) => {
  const [async, setAsync] = useState({
    // 비동기
    loading: false,
    data: null,
  });
  const [weightList, setWeightList] = useState([]);
  const [waterList, setWaterList] = useState([]);
  const [toiletList, setToiletList] = useState([]);
  const [dayList, setDayList] = useState([]);
  const changeJangru = useRef(new Array(7).fill(false));

  const handleData = (data) => {
    let tempData = data.sort((a, b) => a.date.localeCompare(b.date));

    let newWeightList = [];
    let newWaterList = [];
    let newToiletList = [];

    tempData.forEach((el, idx) => {
      // 체중
      if (el.weight === undefined) {
        if (idx === 0) newWeightList.push(undefined);
        else newWeightList.push(newWeightList[newWeightList.length - 1]);
      } else {
        newWeightList.push(el.weight);
      }

      // 수분 섭취량
      if (el.water === undefined) {
        newWaterList.push(undefined);
      } else {
        newWaterList.push(el.water);
      }

      // 배변량
      if (el.toilet === undefined) {
        newToiletList.push(undefined);
      } else {
        newToiletList.push(el.toilet);
      }

      // 장루 교환일 체크
      if (el.changeOX) {
        changeJangru.current[idx] = true;
      }

      setWeightList(newWeightList);
      setWaterList(newWaterList);
      setToiletList(newToiletList);
    });

    // 날짜
    let newDayList = [];
    tempData.forEach((el) => {
      newDayList.push(
        Number(el.date.substring(5, 7)) + "/" + Number(el.date.substring(8, 10))
      );
    });
    setDayList(newDayList);
  };

  /*** 첫 랜더시 정보 불러오기***/
  useEffect(() => {
    async function fetchData() {
      setAsync({ ...async, loading: true });
      const res = await api({
        method: "get",
        url: commonUrl.diary + "graph/" + state.surveyId,
      }).catch((error) => {
        handlingError(error);
      });
      console.log(res.data, ">>> Axios -> ", "diary/graph/" + state.surveyId);
      if (res.data.ok) {
        // UI에 맞게 데이타 가공
        handleData(res.data.graphList);
        setAsync({ data: res.data, loading: false });
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*************************** Chart UI 관련 변수 & 함수들  *************************/
  const weightData = {
    labels: dayList,
    datasets: [
      {
        data: weightList.length !== 0 ? weightList : [],
        backgroundColor: ["#5FC17B"],
        borderWidth: 0,
        hoverOffset: 4,
        maxBarThickness: 35,
        barThickness: "flex",
      },
    ],
  };

  const waterData = {
    labels: dayList,
    datasets: [
      {
        data: waterList.length !== 0 ? waterList : [],
        backgroundColor: ["#1B5EDC"],
        borderWidth: 0,
        hoverOffset: 4,
        maxBarThickness: 35,
        barThickness: "flex",
      },
    ],
  };

  const toiletData = {
    labels: dayList,
    datasets: [
      {
        data: toiletList.length !== 0 ? toiletList : [],
        backgroundColor: ["#F7CE4B"],
        borderWidth: 0,
        hoverOffset: 4,
        maxBarThickness: 35,
        barThickness: "flex",
      },
    ],
  };

  // 공통 plugins
  const commonPlugins = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: function (context) {
          if (context.dataset.data[context.dataIndex] === 0)
            return context.dataset.backgroundColor[0];
          else return "#FAFBFC";
        },
        anchor: function (context) {
          if (context.dataset.data[context.dataIndex] === 0) return "end";
          else return "end";
        },
        align: function (context) {
          if (context.dataset.data[context.dataIndex] === 0) return "end";
          else return "bottom";
        },
        font: function (context) {
          if (context.dataset.data[context.dataIndex] === 0) {
            return { lineHeight: 1 };
          } else return { lineHeight: 2 };
        },
      },
    },
  };

  //  체중 옵션
  const weightOptions = Object.assign(
    {
      responsive: true,
      indexAxis: "x",
      minBarLength: 3,
      scales: {
        x: {
          display: true,
          grid: {
            color: "transparent",
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            color: "#E5E6E7",
            borderColor: "transparent",
            tickColor: "transparent",
          },
          ticks: {
            display: false,
            beginAtZero: true,
          },
        },
      }, // index만 hover 되도 tooltip 뜨도록
      interaction: {
        intersect: false,
        mode: "index",
      },
    },
    commonPlugins
  );

  // 수분섭취량  옵션
  const waterOptions = Object.assign(
    {
      responsive: true,
      indexAxis: "x",
      minBarLength: 3,
      scales: {
        x: {
          display: true,
          grid: {
            color: "transparent",
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            color: "#E5E6E7",
            borderColor: "transparent",
            tickColor: "transparent",
          },
          ticks: {
            display: false,
            beginAtZero: true,
          },
        },
      }, // index만 hover 되도 tooltip 뜨도록
      interaction: {
        intersect: false,
        mode: "index",
      },
    },
    commonPlugins
  );

  // 배변량 options
  const toiletOptions = Object.assign(
    {
      responsive: true,
      indexAxis: "x",
      minBarLength: 3,
      scales: {
        x: {
          display: true,
          grid: {
            color: "transparent",
          },
          ticks: {
            showLabelBackdrop: function (context) {
              if (changeJangru.current[context.index]) return true;
              else return false;
            },
            backdropColor: "#FAE38E",
            backdropPadding: 3,
            padding: 10,
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            color: "#E5E6E7",
            borderColor: "transparent",
            tickColor: "transparent",
          },
          ticks: {
            display: false,
            beginAtZero: true,
          },
        },
        // index만 hover 되도 tooltip 뜨도록
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
    },
    commonPlugins
  );

  return (
    <div
      className={
        flexCol
          ? "flex grid grid-rows-[minmax(230px,270px)] grid-cols-[minmax(320px,360px)_minmax(320px,360px)_minmax(320px,360px)] gap-x-4"
          : "flex grid grid-rows-[minmax(230px,246px)_minmax(230px,246px)_minmax(230px,270px)] grid-cols-[minmax(320px,360px)] gap-y-4 mb-[23px]"
      }
    >
      {/* 체중 */}
      <div className="flex flex-col max-h-[246px] p-4 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)]">
        <div className="flex justify-between p-2">
          <p className="text-xl leading-[26px] font-extrabold text-[#343B48]">
            체중
          </p>
          <p className="font-normal cursor-pointer text-[14px] leading-[18px] text-[#999CA3]">
            kg
          </p>
        </div>
        <div className="flex flex-col grow">
          <Bar
            options={weightOptions}
            data={weightData}
            plugins={[ChartDataLabels]}
            className="bg-[#F1F2F3]"
          />
        </div>
      </div>
      {/* 수분 섭취량 */}
      <div className="flex flex-col max-h-[246px] p-4 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)]">
        <div className="flex justify-between p-2">
          <p className="text-xl leading-[26px] font-extrabold text-[#343B48]">
            수분 섭취량
          </p>
          <p className="font-normal cursor-pointer text-[14px] leading-[18px] text-[#999CA3]">
            ml
          </p>
        </div>
        <div className="flex flex-col grow">
          <Bar
            options={waterOptions}
            data={waterData}
            plugins={[ChartDataLabels]}
            className="bg-[#F1F2F3]"
          />
        </div>
      </div>
      {/* 배변량 */}
      <div className="flex flex-col p-4 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)]">
        <div className="flex justify-between p-2">
          <p className="text-xl leading-[26px] font-extrabold text-[#343B48]">
            배변량
          </p>
          <p className="font-normal cursor-pointer text-[14px] leading-[18px] text-[#999CA3]">
            ml
          </p>
        </div>
        <div className="flex flex-col grow ">
          <Bar
            options={toiletOptions}
            data={toiletData}
            plugins={[ChartDataLabels]}
            className="bg-[#F1F2F3]"
          />
          <div className="flex mt-2 text-[#343B48] font-normal text-sm leading-4 mb-1">
            <div className="ml-[4px] h-[16px] w-[30px] bg-[#FAE38E]"></div>
            <p className="ml-[4px] ">장루주머니 교체일</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JangruCheck;
