import React from "react";

const CantuseBg = ({ canuse, setCanuseModal }) => {
  return (
    <div
      className="fixed top-0 left-0 z-2 w-full h-full bg-transparent"
      onClick={() => {
        if (canuse === false) {
          setCanuseModal(true);
        }
      }}
    ></div>
  );
};

export default CantuseBg;
