import React, { useState, useEffect, useRef } from "react";
import { useMainContext } from "../../../../contexts/Provider";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import common, { api } from "../../../../data/common";
/*component*/
import FindUserId from "./components/FindUserId";
import FindPass from "./components/FindPass";
import BasicLoading from "../../mobilecomponent/BasicLoading";
import MainLoading from "../Main/components/MainLoading";
import NotfoundInfo from "./components/NotfoundInfo";
import FirstAgreeModal from "./components/FirstAgreeModal";
const Login = () => {
  const [showMain, setShowMain] = useState(false);
  const { setLoginInput, loginInput } = useMainContext();
  const [loginIDPW, setLoginidpw] = useState({
    loginid: "",
    loginpw: "",
  });
  const locationState = useLocation().state;
  const [findId, setFindId] = useState(false);
  const [findPass, setFindPass] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [idring, setIdRing] = useState(false);
  const [passring, setPassRing] = useState(false);
  // const [loading, setLoading] = useState(true);
  const [logintomainLoading, setlogintomainLoading] = useState(false);
  const navigate = useNavigate();
  const usericon = "/images/mo/user.svg";
  const passicon = "/images/mo/password.svg";

  const onChangeLogin = (e) => {
    const { value, name } = e.target;
    setLoginidpw({ ...loginIDPW, [name]: value });
  };
  const loginId = useRef(null);
  const loginPw = useRef(null);
  const clickIdRing = (e) => {
    if (idring !== true) {
      e.preventDefault();
      loginPw.current.blur();
      setIdRing(true);
      setPassRing(false);
      const __tempEl__ = document.createElement("input");
      __tempEl__.style.position = "absolute";
      __tempEl__.style.height = 0;
      __tempEl__.style.opacity = 0;
      document.body.appendChild(__tempEl__);
      __tempEl__.focus();
      setTimeout(function () {
        loginId.current.focus({ preventScroll: true });
        document.body.removeChild(__tempEl__);
      }, 0);
    }
  };
  const clickPassRing = (e) => {
    if (passring !== true) {
      e.preventDefault();
      loginId.current.blur();
      setIdRing(false);
      setPassRing(true);
      const __tempEl__ = document.createElement("input");
      __tempEl__.style.position = "absolute";
      __tempEl__.style.height = 0;
      __tempEl__.style.opacity = 0;
      document.body.appendChild(__tempEl__);
      __tempEl__.focus();
      setTimeout(function () {
        loginPw.current.focus({ preventScroll: true });
        document.body.removeChild(__tempEl__);
      }, 0);
    }
  };

  const onClickSubmit = (e) => {
    //4.메인에서 자동로그인 실패시 넘겨준 FCM을 location으로 받아서 login 실행시 삽입
    e.preventDefault();
    let body = {
      patientID: loginIDPW.loginid,
      patientPwd: String(loginIDPW.loginpw),
      fcmToken:
        locationState !== null
          ? locationState
          : loginInput.fcmToken !== ""
          ? loginInput.fcmToken
          : null,
    };

    api
      .post(common.baseURL + "patient/login", body)
      .then((res) => {
        console.log(res, "********login");
        if (res.data.ok === true) {
          setLoginInput({
            isLoggedIn: true,
            patientID: res.data.patient.patientID,
            id: res.data.patient.id,
            name: res.data.patient.name,
            nickName: res.data.patient.nickName,
            createdAt: res.data.patient.createdAt,
            belongid: res.data.patient.belong.id,
            fcmToken: body.fcmToken,
          });

          if (res.data.patient.agree === false) {
            setlogintomainLoading(true);
            let closemainloading = setTimeout(() => {
              setlogintomainLoading(false);
              setShowMain(true);
            }, 3000);

            return () => {
              clearTimeout(closemainloading);
            };
          } else {
            setlogintomainLoading(true);
            let closemainloading = setTimeout(() => {
              setlogintomainLoading(false);
              navigate("/main");
            }, 3000);

            return () => {
              clearTimeout(closemainloading);
            };
          }
        } else {
          setLoginInput({
            patientID: "",
            id: "",
            name: "",
            nickName: "",
            createdAt: "",
            belongid: "",
            fcmToken: "",
            isLoggedIn: false,
          });
          setNotFound(true);
        }
      })
      .catch((error) => console.log(error));
  };
  const showfindId = () => {
    setFindId(!findId);
  };
  const showfindPass = () => {
    setFindPass(!findPass);
  };
  const showNotFound = () => {
    setNotFound(!notFound);
  };

  // useEffect(() => {
  //     let start = setTimeout(() => {
  //       setLoading(false);
  //     }, 500);

  //     return () => {
  //       clearTimeout(start);
  //     };
  // }, []);

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        bodyClassName={() =>
          "w-[200px] p-3 rounded-full overflow-hidden bg-[#CBDFFC] text-black  font-normal"
        }
      ></ToastContainer>
      {/* {loading ? <BasicLoading /> : ""} */}
      {logintomainLoading ? <MainLoading /> : ""}
      {showMain ? <FirstAgreeModal setShowMain={setShowMain} /> : ""}
      <div className="h-screen px-[47.5px] bg-[#FAFCFF]">
        <div className="flex items-center justify-center pt-[64px]">
          <span className="text-[#1B5EDC] montserrat_700 text-3xl leading-[34px]">
            OstoCare
          </span>
        </div>

        <span className="pt-4 block leading-[22px] text-[#343B48] text-center body_1_400">
          오스토케어와 함께 하는 장루관리
        </span>

        <div className="mt-[148px] mb-[16px]">
          <div
            className={
              idring
                ? "border border-[#999CA3] rounded w-full px-2 h-[56px] flex items-center bg-[#f1f2f3]"
                : "rounded w-full px-2 h-[56px] flex items-center bg-[#f1f2f3]"
            }
          >
            <span className="flex justify-center w-1/6">
              <img src={usericon} alt="usericon" />
            </span>
            <input
              autoComplete="off"
              placeholder="등록번호를 입력해주세요"
              className="w-5/6 px-2 bg-transparent loginid login focus:outline-none"
              name="loginid"
              ref={loginId}
              value={loginIDPW.loginid}
              onChange={onChangeLogin}
              onMouseDown={(e) => clickIdRing(e)}
            />
          </div>
          <div
            className={
              passring
                ? "border border-[#999CA3] rounded-sm w-full px-2 mt-[8px] h-[56px] flex items-center bg-[#f1f2f3]"
                : "rounded-sm w-full px-2 mt-[8px] h-[56px] flex items-center bg-[#f1f2f3]"
            }
          >
            <span className="flex justify-center block w-1/6">
              <img src={passicon} alt="passicon" />
            </span>
            <input
              autoComplete="off"
              type="password"
              placeholder="비밀번호를 입력해주세요"
              className="w-5/6 px-2 bg-transparent login focus:outline-none"
              name="loginpw"
              ref={loginPw}
              value={loginIDPW.loginpw}
              onChange={onChangeLogin}
              onMouseDown={(e) => clickPassRing(e)}
            />
          </div>
        </div>
        {loginIDPW.loginid !== "" && loginIDPW.loginpw !== "" ? (
          <div
            className="bg-[#1b5edc] text-white cursor-pointer 
            hover:bg-[#18428E]
            text-center button_700 rounded-sm w-full py-[20px] ease-in-out duration-300"
            onClick={onClickSubmit}
          >
            로그인
          </div>
        ) : (
          <div className="bg-[#9DC4FA] text-white text-center button_700 rounded-sm w-full py-[20px]">
            로그인
          </div>
        )}

        <div className="w-full flex justify-center pt-[16px] caption_1_400 text-[#4E535F]">
          <div
            className="cursor-pointer w-1/2 flex justify-end pr-[16px] "
            onClick={showfindId}
          >
            등록번호 찾기
          </div>
          <div
            className="cursor-pointer w-1/2 border-l-[1px] border-black pl-[16px]"
            onClick={showfindPass}
          >
            비밀번호 찾기
          </div>
        </div>
        <div className="pt-[142px] w-full text-center caption_2">
          <span className="block">
            로그인에 문제가 있을 시 아래의 메일로 문의해주세요
          </span>
          <span className="block">
            &#40;문의:product@heringsglobal.com&#41;
          </span>
        </div>
      </div>
      {findId ? <FindUserId showfindId={showfindId} /> : ""}
      {findPass ? <FindPass showfindPass={showfindPass} /> : ""}
      {notFound ? <NotfoundInfo showNotFound={showNotFound} /> : ""}
    </>
  );
};

export default Login;
