import React from "react";
import common, { api } from "../../../../../data/common";
import CenterModal from "../../../mobilecomponent/CenterModal";

const PhotoGuide = ({ showGuide }) => {
  const closeimg = "/images/mo/closebasic.svg";
  const videoskin = "/images/mo/pngjpg/장루사진_스킨.png";

  return (
    <CenterModal>
      <div className="flex justify-between">
        <h1 className="subtitle_1_700 h-[48px] flex items-center">
          사진 촬영 방법 안내
        </h1>
        <img
          className="cursor-pointer inline-block absolute top-[8px] right-0"
          src={closeimg}
          alt="close"
          onClick={showGuide}
        />
      </div>

      <video
        src={common.networkCont + "photo.mp4"}
        poster={videoskin}
        controls
      />

      <div className="mt-[30px] body_1_400 h-[250px] relative overflow-y-scroll">
        <div>
          장루 사진 촬영의 목적은 장루 관찰을 통해 장루 관련 합병증 예방 및
          관리하기 위함입니다. 장루 점검시 사진 촬영은 최소 3장 이상 촬영하여
          첨부하셔야 합니다.
        </div>
        <div className="bg-[#F2F6FC] rounded-lg p-[8px] mt-[8px] ">
          <span className="text-[#1B5EDC]">촬영 준비</span>
          <div className="flex">
            <span className="block">1.&nbsp;</span>
            <span className="block">
              장루 주머니와 피부 보호판을 모두 제거합니다.
            </span>
          </div>
          <div className="flex">
            <span className="block">2.&nbsp;</span>
            <span className="block">
              혼자 사진을 촬영할 수 있다면 셀카 모드로 찍고, 보호자가 촬영 시
              후면 카메라 모드로 찍습니다.
            </span>
          </div>
        </div>
        <div className="bg-[#F2F6FC] rounded-lg p-[8px] mt-[8px]">
          <span className="text-[#1B5EDC]">사진 촬영</span>
          <div className="flex">
            <span className="block">1.&nbsp;</span>
            <span className="block">
              장루와 복부 전체가 정면으로 나오도록 촬영합니다.
            </span>
          </div>
          <div className="flex">
            <span className="block">2.&nbsp;</span>
            <span className="block">
              장루와 복부 전체가 측면으로 나오도록 촬영합니다.
            </span>
          </div>
          <div className="flex">
            <span className="block">3.&nbsp;</span>
            <span className="block">
              장루와 피부 보호판을 부착했던 부위가 나오도록 촬영합니다.
            </span>
          </div>
          <div className="flex">
            <span className="block">4.&nbsp;</span>
            <span className="block">
              문제가 생긴 부위가 있다면 해당 부분이 자세히 나오도록 촬영합니다.
            </span>
          </div>
        </div>
        <div className="pt-[8px]">
          장루 점검 시 장루에 문제가 없더라도 위의 사진 촬영 1~3번에 해당하는
          사진을 촬영하여 첨부하셔야 합니다. 자세한 내용은 영상을 통해
          확인해주세요.
        </div>
      </div>
    </CenterModal>
  );
};

export default PhotoGuide;
