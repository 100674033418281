import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import common, { api } from "../../../../../data/common";

import { useMainContext } from "../../../../../contexts/Provider";
const InspectionPage2 = ({ survey }) => {
  console.log(survey, ">>>>survey");
  const { loginInput } = useMainContext();
  const [user, setUser] = useState([]);
  let newSymptom = [];
  if (survey.symptom !== null) {
    if (new Array(...survey.symptom).filter((el) => el === "1")) {
      newSymptom.push("문제없음");
    } else if (new Array(...survey.symptom).filter((el) => el === "2")) {
      newSymptom.push("장루교체가 능숙하지 않음");
    } else if (new Array(...survey.symptom).filter((el) => el === "3")) {
      newSymptom.push("일상생활,사회활동 어려움");
    } else if (new Array(...survey.symptom).filter((el) => el === "4")) {
      newSymptom.push("식생활, 영양관리 어려움");
    } else if (new Array(...survey.symptom).filter((el) => el === "5")) {
      newSymptom.push("대변 누출이 잦음");
    } else if (new Array(...survey.symptom).filter((el) => el === "6")) {
      newSymptom.push("심리적 어려움");
    }
  } else {
    newSymptom.push("증상없음");
  }

  let newImg = [];
  if (survey.imageURL !== null) {
    for (let i = 0; survey.imageURL.length < i; i++) {
      newImg.push(survey.imageURL[i]);
    }
  } else {
    newImg.push("");
  }
  console.log(user.name);
  useEffect(() => {
    let pageOpen = false;
    const getPatientId = () => {
      api
        .get(common.baseURL + "patient/Id/" + loginInput.id)
        .then((res) => {
          if (res.data.ok === true) {
            setUser(res.data.patient);
          }
        })
        .catch((error) => console.log(error));
    };
    if (!pageOpen) {
      getPatientId();
    }
  }, [loginInput.id]);
  return (
    <div>
      <div className="px-[16px] flex items-center justify-between">
        <span className="body_1_600 block py-[16px]">
          작성자 |{" "}
          {survey.writer !== "본인" && survey.writer !== ""
            ? `${user.name}의 보호자 (${survey.writer})`
            : survey.writer === "본인"
            ? survey.writer
            : "미작성"}
        </span>
        <span className="caption_2 text-[#676C76]">
          {dayjs(survey.createdAt).format("YYYY.MM.DD")}
        </span>
      </div>
      <div className="mx-[16px] bg-[#FAFCFF] rounded-lg p-[16px]">
        <span className="subtitle_1_700">현재상황</span>
        <div className="py-[8px] border-b">
          {survey.symptom.map((el, index) => (
            <span className="block text-[#245BBF] " key={index}>
              {el === 1
                ? "· 문제없음"
                : el === 2
                ? "· 장루교체가 능숙하지 않음"
                : el === 3
                ? "· 일상생활,사회활동 어려움"
                : el === 4
                ? "· 식생활,영양관리 어려움"
                : el === 5
                ? "· 대변 누출이 잦음"
                : el === 6
                ? "· 심리적 어려움"
                : ""}
            </span>
          ))}
        </div>
        <ul className="py-[8px]">
          <li className="flex justify-between">
            <span>현재 체중</span>
            <span className="text-[#245BBF] ">{survey.weight}kg</span>
          </li>
          <li className="flex justify-between pt-[10px]">
            <span>장루주머니 교체일</span>
            <span className="text-[#245BBF] ">{survey.changeDate}</span>
          </li>
          <li className="flex justify-between pt-[10px]">
            <span>장루주머니 교체 주기</span>
            <span className="text-[#245BBF] ">{survey.changeCycle}일</span>
          </li>
        </ul>
      </div>
      <div className="mx-[16px] bg-[#FAFCFF] rounded-lg p-[16px] mt-[16px]">
        <ul className="py-[8px]">
          <li className="flex justify-between">
            <span>장루 합병증</span>
            <span className="text-[#245BBF] ">
              {survey.jangruComplicationsOX === "O" ? "있음" : "없음"}
            </span>
          </li>
          <li className="flex justify-between pt-[10px]">
            <span>피부 합병증</span>
            <span className="text-[#245BBF] ">
              {survey.skinComplicationsOX === "O" ? "있음" : "없음"}
            </span>
          </li>
          {survey.dehydrationOX === "O" ? (
            <li className="flex justify-between pt-[10px]">
              <span>탈수 증상</span>
              <span className="text-[#245BBF] ">있음</span>
            </li>
          ) : (
            ""
          )}
        </ul>
      </div>
      <div className="w-full mt-[16px] relative px-[32px] flex justify-end">
        <span className="absolute top-[10px] right-[18px] inline-block w-4 h-4  rounded-br-full bg-[#CBDFFC] "></span>
        <div className=" rounded-lg bg-[#CBDFFC] p-[16px]">
          {survey.imageURL.length > 3 ? (
            <>
              <div className="flex gap-x-2">
                {survey.imageURL.slice(0, 3).map((el, index) => (
                  <img
                    src={common.networkImg + encodeURIComponent(el)}
                    alt={el}
                    key={index}
                    className="w-[80px] h-[80px] border bg-[#FAFCFF]"
                  />
                ))}
              </div>
              <div className="flex gap-x-[4px] pt-[4px]">
                {survey.imageURL
                  .slice(-(survey.imageURL.length - 3))
                  .map((el, index) => (
                    <img
                      src={common.networkImg + encodeURIComponent(el)}
                      alt={el}
                      key={index}
                      className="w-[80px] h-[80px] border bg-[#FAFCFF]"
                    />
                  ))}
              </div>
            </>
          ) : (
            <div className="flex gap-x-[4px]">
              {survey.imageURL.map((el, index) => (
                <img
                  src={common.networkImg + encodeURIComponent(el)}
                  alt={el}
                  key={index}
                  className="w-[80px] h-[80px]"
                />
              ))}
            </div>
          )}
          <p className="text-right pt-[8px]">{survey.imageText}</p>
        </div>
      </div>
      {survey.question ? (
        <div className="w-full mt-[16px] relative px-[32px] flex justify-end">
          <span className="absolute top-[10px] right-[18px] inline-block w-4 h-4  rounded-br-full bg-[#CBDFFC] "></span>
          <div className=" rounded-lg bg-[#CBDFFC] p-[16px]">
            {survey.question}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default InspectionPage2;
