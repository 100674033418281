import React from "react";

const TabBasic = ({ tab1, tab2, showPage1, showPage2, page1, page2 }) => {
  const penlinewhite = "/images/mo/penline_white.svg";
  const penlineblack = "/images/mo/penline_black.svg";
  const crosswhite = "/images/mo/cross_white.svg";
  const crossblack = "/images/mo/cross_black.svg";
  return (
    <div className="bg-[#FAFCFF] py-[16px] px-[16px]">
      <div className="border border-[#679DEE] rounded-xl w-full flex">
        <div
          className={
            page1
              ? "rounded-lg bg-[#1B5EDC] text-white body_1_600 w-1/2 h-10  flex justify-center items-center cursor-pointer"
              : " w-1/2 h-10 body_1_600 flex justify-center items-center cursor-pointer"
          }
          onClick={showPage1}
        >
          {page1 ? (
            <img src={crosswhite} alt="crosswhite" />
          ) : (
            <img src={crossblack} alt="crossblack" />
          )}
          <span>{tab1}</span>
        </div>
        <div
          className={
            page2
              ? "rounded-lg bg-[#1B5EDC] text-white body_1_600 w-1/2 h-10  flex justify-center items-center cursor-pointer"
              : " w-1/2 h-10 body_1_600 flex justify-center items-center cursor-pointer"
          }
          onClick={showPage2}
        >
          {page2 ? (
            <img src={penlinewhite} alt="penlinewhite" />
          ) : (
            <img src={penlineblack} alt="penlineblack" />
          )}
          <span>{tab2}</span>
        </div>
      </div>
    </div>
  );
};

export default TabBasic;
