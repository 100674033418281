import React from "react";
import CenterModal from "../../../mobilecomponent/CenterModal";
const Poop1900Modal = ({ showPoop1900 }) => {
  const jangruboy_wow = "/images/mo/jangruboy_wow.svg";
  const closeicon = "/images/mo/closebasic.svg";
  return (
    <CenterModal>
      <img
        src={closeicon}
        alt="closeicon"
        className="cursor-pointer absolute top-0 right-0"
        onClick={showPoop1900}
      />

      <div className="pt-[16px] pb-[8px] flex justify-center items-center">
        <img src={jangruboy_wow} alt="jangruboy_wow" />
      </div>
      <div className="flex flex-col items-center">
        <span className="block">배변량이 과도하게 많네요!</span>
        <span className="block">병원 방문이 필요한 상황이오니</span>
        <span className="block">가까운 병원에 방문해 주세요!</span>
      </div>
    </CenterModal>
  );
};

export default Poop1900Modal;
