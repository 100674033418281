import React from "react";

const FoodManage3StomaBlock = ({ bigTitle, btn3, btn3_4 }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400 wordbreak-keepall">
      <div className="py-[24px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">
          {bigTitle} &gt; {btn3}
        </span>
        <h1 className="h2_800 pt-[4px]">{btn3_4}</h1>
      </div>
      <div className="pt-[24px] body_1_400_jangrucont">
        <div>
          <div className="flex ">
            <span className="inline-block">1. </span>
            <div>
              <span className="inline-block">
                &nbsp;아래와 같은 증상이 있을 때 장루가 막혔을 가능성이
                있습니다.
              </span>
            </div>
          </div>
          <div className="flex">
            <span className="inline-block">-</span>
            <div>
              <span className="inline-block ">&nbsp;복부 경련 및 통증</span>
            </div>
          </div>
          <div className="flex ">
            <span className="inline-block">-</span>
            <div>
              <span className="inline-block ">&nbsp;복부 팽만</span>
            </div>
          </div>
          <div className="flex">
            <span className="inline-block">-</span>
            <div>
              <span className="inline-block ">&nbsp;악취가 나는 묽은 변</span>
            </div>
          </div>
          <div className="flex">
            <span className="inline-block">-</span>
            <div>
              <span className="inline-block ">&nbsp;분출하는 대변</span>
            </div>
          </div>
          <div className="flex">
            <span className="inline-block">-</span>
            <div>
              <span className="inline-block ">
                &nbsp;장루에 압력이 느껴지지만 대변이 거의 또는 전혀 배출되지
                않음
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[80px]"></div>
    </div>
  );
};

export default FoodManage3StomaBlock;
