import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useMainContext } from "../../../../contexts/Provider";
/*component*/
import GobackPage from "../../mobilecomponent/GoBackPage";
import common, { api } from "../../../../data/common";
import ReportAnswerImage from "../Report/components/ReportAnswerImage";

const EachNews = () => {
  const newsState = useLocation().state;
  const [getNews, setGetNews] = useState([]);
  const href = new URL(window.location.href);
  const urlParams = href.searchParams;
  const tokenID = urlParams.get("newsId");
  const newsId = tokenID ? tokenID : newsState.id;
  const { loginInput } = useMainContext();
  const navigate = useNavigate();

  let codes = getNews.content;
  useEffect(() => {
    let pageOpen = false;
    const getNewsId = () => {
      api
        .get(common.baseURL + "news/id/" + newsId)
        .then((res) => {
          if (res.data.ok === true) {
            setGetNews(res.data.news);
          }
        })
        .catch((error) => console.log(error));
    };
    if (!pageOpen) {
      if (
        loginInput.id === null ||
        loginInput.id === undefined ||
        loginInput.id === ""
      ) {
        navigate("/main", { replace: true });
      } else {
        getNewsId();
      }
    }
    return () => {
      pageOpen = true;
    };
  }, [loginInput.id, navigate, newsId]);
  return (
    <div className="h-screen bg-[#FAFCFF]">
      <GobackPage>새소식</GobackPage>
      <div className="mt-[48px] p-[24px]">
        <div className="border-b">
          <span className="font-semibold">
            {getNews.category === 1
              ? "장루소식"
              : getNews.category === 2
              ? "앱소식"
              : getNews.category
              ? "커뮤니티"
              : ""}
          </span>
          <div>{getNews.title}</div>
          <span className="text-[#999CA3] caption_1_400 block py-[16px]">
            {dayjs(getNews.createdAt).format("YYYY.MM.DD")}{" "}
            {dayjs(getNews.createdAt).format("A") === "PM" ? "오후" : "오전"}
            {dayjs(getNews.createdAt).format("H") > 12
              ? dayjs(getNews.createdAt).format("H") - 12
              : dayjs(getNews.createdAt).format("H")}
            시
          </span>
        </div>
        <div
          className="py-[16px]"
          dangerouslySetInnerHTML={{ __html: codes }}
        ></div>
        {getNews.attachedFileURL
          ?.filter(
            (el) =>
              el.slice(-4) === ".jpg" ||
              el.slice(-5) === ".jpeg" ||
              el.slice(-4) === ".png"
          )
          .map((img, idx) => (
            <ReportAnswerImage key={idx} imageurl={img} />
          ))}
        {getNews.attachedFileURL
          ?.filter((el) => el.includes(".pdf"))
          .map((pdf, idx) => (
            <div
              className="bg-[#F2F6FC] p-[8px] rounded-lg flex items-start "
              key={idx}
            >
              <img src="/images/mo/file.svg" alt="file" />

              <div className="pl-[6px]">
                <a
                  href={common.networkImg + encodeURIComponent(pdf)}
                  className="underline block hover:text-[#1B5EDC] overflow-hidden break-all"
                  download={pdf}
                >
                  {pdf}
                </a>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default EachNews;
