import dayjs from "dayjs";

//previousday
export const previousToday = (index, startDtae) => {
  return {
    clickDate: dayjs(startDtae)
      .subtract(index, "day")

      .format("YYYY-MM-DD")
  };
};

// nextToday
export const nextToday = (index, startDtae) => {
  return {
    clickDate: dayjs(startDtae)
      .add(index, "day")

      .format("YYYY-MM-DD")
  };
};
