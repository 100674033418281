import React from "react";

const FoodManage3WeightLose = ({ bigTitle, btn3, btn3_1 }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400 wordbreak-keepall">
      <div className="py-[24px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">
          {bigTitle} &gt; {btn3}
        </span>
        <h1 className="h2_800 pt-[4px]">{btn3_1}</h1>
      </div>
      <div className="w-full pt-[24px] body_1_400_jangrucont">
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <div>체중감소가 지속되는 경우 이는 영양불량을 의미합니다.</div>
        </div>
        <div className="flex ">
          <span className="inline-block">•&nbsp;</span>
          <div>
            체중감소로 저체중 상태가 되었다면 영양상태 회복을 위한 섭취량 증가가
            필요합니다.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block">• &nbsp;</span>
          <div>
            식사 외에도 간식 섭취량을 늘려보세요. 진료 시, 영양상담을
            요청합니다.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <div>
            회장루 환자의 경우 장루 배설 양상이 묽을 수록, 횟수와 배설량이 많은
            경우 영양소 흡수 손실이 클 수 있습니다.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <div>식사 중에 국물 등의 수분섭취량을 줄여봅니다.</div>
        </div>
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <div>
            단, 식사와 식사 사이의 수분 섭취량은 충분히 하는 것이 필요합니다.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <div>장루 배설량이 과도하게 많은 경우, 식사를 점검해봅니다.</div>
        </div>
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <div>지사제복용이 필요할 수 있으므로 담당의사와 상의합니다.</div>
        </div>
      </div>
    </div>
  );
};

export default FoodManage3WeightLose;
