import React from "react";
import { useLocation } from "react-router-dom";
const SkeletonLoading = () => {
  const pathname = useLocation().pathname;

  return (
    <div className="fixed top-0 left-0 bg-[#FAFCFF] opacity-100 w-full h-screen overflow-hidden z-10">
      <div className="w-full h-full max-w-md mx-auto ">
        {pathname === "/report" ? (
          <>
            <div className="bg-[#FAFCFF] w-full ">
              <div className="w-full py-[13px]  flex justify-between items-center px-[8px]">
                <span className=" inline-block w-[15.5px] h-[15.5px]" />
                <span className="inline-block text-transparent ">기록보기</span>
                <span className="inline-block w-[15.5px] h-[15.5px]"></span>
              </div>
            </div>
            <div className="w-full px-[16px] pt-[1px] flex justify-between relative bg-[#FAFCFF] border-b-[2px] border-[#E5E6E7]">
              <div className="w-1/2 h-[78px] flex justify-center items-center ">
                <span className="block w-full h-[32px] rounded-lg skeleton-bg text-transparent">
                  장루점검
                </span>
              </div>
              <div className="w-1/2 h-[78px] flex justify-center items-center ">
                <span className="block text-transparent">장루점검</span>
              </div>
            </div>
            <div className="bg-[#F1F2F3] h-[8px]"></div>
            <div className="p-[24px]">
              <div className="w-full h-[240px] rounded skeleton-bg"></div>
              <div className="w-full h-[58px] rounded skeleton-bg mt-[16px]"></div>
              <div className="w-full h-[58px] rounded skeleton-bg mt-[16px]"></div>
            </div>
          </>
        ) : pathname === "/community" ? (
          <>
            <div className="bg-[#FAFCFF] w-full ">
              <div className="w-full py-[13px]  flex justify-between items-center px-[8px]">
                <span className=" inline-block w-[15.5px] h-[15.5px]" />
                <span className="inline-block text-transparent">기록보기</span>
                <span className="inline-block w-[15.5px] h-[15.5px]"></span>
              </div>
            </div>
            <div className="w-full py-[17px] px-[24px] flex skeleton-bg">
              <span className="inline-block text-lg font-semibold w-full text-transparent">
                커뮤니티 이용안내
              </span>
            </div>
            <div>
              <div className="px-[24px] py-[24px] mb-[8px] ">
                <div>
                  <div className="flex border-b border-[#E5E6E7] pb-[19px] justify-between items-center">
                    <div className="flex">
                      <span className="inline-block skeleton-bg rounded-full flex justify-center items-center h-[40px] w-[40px] bg-no-repeat bg-center"></span>

                      <div className=" pl-[16px]">
                        <span className="block text-transparent text-sm skeleton-bg rounded-sm">
                          홍길동이나라를구했는가????
                        </span>
                        <span className="inline-block mt-[4px] skeleton-bg rounded-sm text-transparent text-sm tracking-tighter">
                          2022-11-01
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-[20px] h-[200px] skeleton-bg rounded-sm"></div>
                  <span className="block skeleton-bg rounded-sm mt-[8px] text-transparent">
                    스토마스토마스토마화이팅
                  </span>
                  <span className="inline-block skeleton-bg rounded-sm mt-[4px] text-transparent">
                    스토마스토마스토마화이팅 스토마
                  </span>
                  <div className="flex mt-[16px]">
                    <div className="inline-block text-transparent skeleton-bg rounded-sm">
                      스토마스토마화이
                    </div>
                    <div className="ml-[12px] inline-block text-transparent skeleton-bg rounded-sm">
                      스토마스토마
                    </div>
                  </div>
                </div>
              </div>

              <div className="skeleton-bg h-[8px]"></div>
              <div className="px-[24px] py-[24px] mb-[8px] ">
                <div>
                  <div className="flex border-b border-[#E5E6E7] pb-[19px] justify-between items-center">
                    <div className="flex">
                      <span className="inline-block skeleton-bg rounded-full flex justify-center items-center h-[40px] w-[40px] bg-no-repeat bg-center"></span>

                      <div className=" pl-[16px]">
                        <span className="block text-transparent text-sm skeleton-bg rounded-sm">
                          홍길동이나라를구했는가????
                        </span>
                        <span className="inline-block mt-[4px] skeleton-bg rounded-sm text-transparent text-sm tracking-tighter">
                          2022-11-01
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-[20px] h-[200px] skeleton-bg rounded-sm"></div>
                  <span className="block skeleton-bg rounded-sm mt-[8px] text-transparent">
                    스토마스토마스토마화이팅
                  </span>
                  <span className="inline-block skeleton-bg rounded-sm mt-[4px] text-transparent">
                    스토마스토마스토마화이팅 스토마
                  </span>
                  <div className="flex mt-[16px]">
                    <div className="inline-block text-transparent skeleton-bg rounded-sm">
                      스토마스토마화이
                    </div>
                    <div className="ml-[12px] inline-block text-transparent skeleton-bg rounded-sm">
                      스토마스토마
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SkeletonLoading;
