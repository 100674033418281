import React from "react";

const ChangeContent = ({ content, title }) => {
  return (
    <>
      {title === "장루주머니 교환할 때 필요한 물품은 무엇인가요?" ? (
        <div>
          <p>{content}</p>
          <p>- 피부보호판&#40;Skin Barrier&#41;</p>
          <p>- 장루주머니&#40;Colostomy Pouch&#41;</p>
          <p>- 피부보호 필름&#40;Protective Film&#41;</p>
          <p>- 피부보호 연고&#40;Protective Barrier Paste&#41;</p>
          <p>- 곡가위</p>
          <p>
            - 기타 : 매직펜&#40;혹은 볼펜&#41;, 자&#40;Stoma Guide&#41;, 휴지,
            방수포, 반창고, 비닐주머니, 거즈나 작은 수건&#40;젖은 것과 마른
            것&#41; 등 자세한 내용은 오스토케어 앱의 &lt;장루교체방법 &gt;
            영상을 통해 배우실 수 있습니다.
          </p>
        </div>
      ) : (
        <div>
          <p>{content}</p>
          <p>- 세브란스 장루요루전문관리팀. 장루, 요루관리</p>
          <p>- 암스쿨</p>
          <p>- 캔서앤서</p>
          <p>- 삼성창원병원</p>
          <p>- Coloplast</p>
          <p>- 서울대병원 장루간호팀</p>
        </div>
      )}
    </>
  );
};
{
}
export default ChangeContent;
