import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
const Check5 = ({ checkInfo, setCheckInfo }) => {
  const [state1, setState1] = useState(false);
  const [state2, setState2] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    let pageOpen = false;
    const chageJangruComplication = () => {
      if (checkInfo.jangruComplicationsOX === "O") {
        setState1(true);
      }
      if (checkInfo.jangruComplicationsOX === "X") {
        setState2(true);
      }
    };
    if (!pageOpen) {
      chageJangruComplication();
    }
    return () => {
      pageOpen = true;
    };
  }, [checkInfo]);
  const onClickState1 = (e) => {
    setState1(!state1);
    setState2(false);
    setCheckInfo({ ...checkInfo, jangruComplicationsOX: "O" });
  };
  const onClickState2 = (e) => {
    setState2(!state2);
    setState1(false);
    setCheckInfo({ ...checkInfo, jangruComplicationsOX: "X" });
  };
  const goCheck4 = () => {
    navigate(`/check/4`);
  };
  const goCheck6 = () => {
    navigate(`/check/6`);
  };
  const coloredBg =
    "mb-[12px] border rounded w-full px-[24px] py-[16px] cursor-pointer bg-[#CBDFFC] border-[#1B5EDC] body_1_600";
  const noneBg =
    "mb-[12px] border rounded w-full px-[24px] py-[16px] cursor-pointer bg-[#F2F6FC] border-[#9DC4FA] active:bg-[#CBDFFC]";
  return (
    <>
      <div className="pt-[94px] text-[#4E535F]">
        <div className="px-[24px]">
          <h1 className="h3_800 wordbreak-keepall">
            지난 일주일 동안 장루 부위에 출혈, 괴사, 함몰 장폐쇄 등 장루에 이상
            증상 혹은 불편한 증상이 있으셨나요?
          </h1>
        </div>

        <div className="px-[24px] pt-[80px]">
          <div
            className={
              checkInfo.jangruComplicationsOX === "O" ? coloredBg : noneBg
            }
            onClick={onClickState1}
          >
            ⭕️ &nbsp;있다
          </div>
          <div
            className={
              checkInfo.jangruComplicationsOX === "X" ? coloredBg : noneBg
            }
            onClick={onClickState2}
          >
            ❌ &nbsp;없다
          </div>
        </div>
      </div>
      {checkInfo.jangruComplicationsOX !== "" ? (
        <div className="fixed  bottom-0 left-0 w-full font-bold flex ">
          <div
            className="border w-2/5 py-[20px] flex justify-center items-center cursor-pointer
                 bg-[#FAFCFF] active:bg-[#F2F6FC] border border-[#679DEE] active:border-[#18428E] inline-block button_700   cursor-pointer  ease-in-out duration-300 text-[#1B5EDC] active:text-[#18428E]"
            onClick={goCheck4}
          >
            이전
          </div>
          <div
            className=" w-3/5 py-[20px]  flex justify-center items-center cursor-pointer bg-[#1B5EDC] button_700 text-white active:bg-[#18428E] ease-in-out duration-300"
            onClick={goCheck6}
          >
            다음
          </div>
        </div>
      ) : (
        <div className="fixed  bottom-0 left-0 w-full font-bold flex ">
          <div
            className="border w-2/5 py-[20px] flex justify-center items-center cursor-pointer
               bg-[#FAFCFF] active:bg-[#F2F6FC] border border-[#679DEE] active:border-[#18428E] inline-block button_700   cursor-pointer  ease-in-out duration-300 text-[#1B5EDC] active:text-[#18428E]"
            onClick={goCheck4}
          >
            이전
          </div>
          <div className=" w-3/5 py-[20px]  flex justify-center items-center bg-[#9DC4FA] button_700 text-white ease-in-out duration-300">
            다음
          </div>
        </div>
      )}
    </>
  );
};

export default Check5;
