import React from "react";

const MainLoading = () => {
  return (
    <div className="w-full h-screen fixed top-0 left-0 bg-[#1b5edc] z-5">
      <div className="pt-[220px] flex flex-col items-center justify-center">
        <div className="w-[140px] h-[140px] main_imagechange"></div>
        <div className="text-3xl montserrat_700 text-white pt-[16px]">
          Osto Care
        </div>
        <div className="text-white body_1_400 pt-[16px]">
          본 앱은 장루관리를 돕고 모니터링을
        </div>
        <div className="text-white body_1_400 ">
          하기 위한 목적으로 개발되었습니다.
        </div>
      </div>
    </div>
  );
};

export default MainLoading;
