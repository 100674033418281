import React from "react";

const FoodManage2ColostomyMeal = ({ bigTitle, btn2, btn2_3 }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400 wordbreak-keepall">
      <div className="py-[24px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">
          {bigTitle} &gt; {btn2}
        </span>
        <h1 className="h2_800 pt-[4px]">{btn2_3}</h1>
      </div>
      <div className="w-full pt-[24px] body_1_400_jangrucont">
        <div className="flex pb-[25px]">
          <span className="inline-block">•&nbsp;</span>
          <div>
            결장루 보유 환자의 경우, 피해야 하는 음식은 없습니다. 변비나 설사가
            발생하는 경우 아래의 표를 참고하여 해당식품 섭취를 조절해 봅니다.
            변비가 지속되는 경우 변 완하제 복용에 대해 담당의사와 상의합니다.
          </div>
        </div>
        <div className="border mb-[25px]">
          <div className="border-b p-[8px] bg-[#F2F6FC] font-semibold text-center">
            증상완화를 위한 식품리스트
          </div>
          <div className="flex border-b">
            <div className="w-[80px] bg-[#F2F6FC] font-semibold flex justify-center items-center border-r">
              변비 완화
            </div>
            <span className="w-[310px] p-[8px] inline-block">
              과일, 과일주스, 푸룬, 건포도 귀리 등 잡곡류, 채소 및 과일류 충분한
              수분섭취, 따뜻한 음료나 차, 변 완하제
            </span>
          </div>
          <div className="flex border-b">
            <div className="w-[80px] bg-[#F2F6FC] font-semibold flex justify-center items-center border-r">
              냄새 완화
            </div>
            <span className="w-[310px] p-[8px] inline-block">
              발효유 등을 통한 유산균 채소 및 과일, 충분한 수분섭취로 변비예방
              크랜베리주스, 오렌지주스
            </span>
          </div>
          <div className="flex">
            <div className="w-[80px] bg-[#F2F6FC] font-semibold flex justify-center items-center border-r">
              설사 완화
            </div>
            <span className="w-[310px] p-[8px] inline-block">
              바나나, 삶은감자, 흰밥/면/빵 등 도정된 곡류, 애플소스 (갈아서 끓인
              사과소스), 마시멜로우, 땅콩버터 (Creamy peanut butter)
            </span>
          </div>
        </div>
        <div className="border">
          <div className="border-b p-[8px] bg-[#F2F6FC] font-semibold text-center">
            증상 관련된 식품리스트
          </div>
          <div className="flex border-b">
            <div className="w-[80px] bg-[#F2F6FC] font-semibold flex justify-center items-center border-r">
              가스생성
            </div>
            <span className="w-[310px] p-[8px] inline-block">
              <span className="inline-block">
                알콜음료, 탄산음료, 콩째 먹는 음식, 견과류, 양배추, 컬리플라워,
                오이,양파, 무 유제품, 껌씹기
              </span>
            </span>
          </div>
          <div className="flex border-b">
            <div className="w-[80px] bg-[#F2F6FC] font-semibold flex justify-center items-center border-r">
              냄새 유발
            </div>
            <span className="w-[310px] p-[8px] inline-block">
              아스파라거스, 볶은 콩, 브로콜리, 양배추, 대구간유, 계란, 생선,
              향이 강한 치즈 양파, 마늘, 땅콩버터,일부 비타민
            </span>
          </div>
          <div className="flex border-b">
            <div className="w-[80px] bg-[#F2F6FC] font-semibold flex justify-center items-center border-r">
              설사 유발
            </div>
            <span className="w-[310px] p-[8px] inline-block">
              알콜음료, 잡곡, 곡류 씨리얼, 조리한 양배추, 풋과일, 생야채,
              향신료, 건포도, 푸룬 사과/푸룬주스
            </span>
          </div>
          <div className="flex">
            <div className="w-[80px] bg-[#F2F6FC] font-semibold flex flex-col justify-center items-center border-r">
              <span className="inline-block">장루막힘</span>
              <span className="inline-block">유발</span>
            </div>
            <span className="w-[310px] p-[8px] inline-block">
              과일껍질/씨째먹는 과일 오렌지, 파인애플, 생 양배추, 샐러리, 버섯
              잡곡, 옥수수, 팝콘, 코코넛 견과류, 큰 씨앗, 말린 과일
            </span>
          </div>
        </div>
      </div>
      <div className="h-[70px]"></div>
    </div>
  );
};

export default FoodManage2ColostomyMeal;
