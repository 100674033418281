import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CheckCloseModal from "../pages/Check/components/CheckCloseModal";
const Close = ({ title }) => {
  const pathname = useLocation().pathname;
  const closeimg = "/images/mo/closebasic.svg";
  const navigate = useNavigate();
  const [checkmodal, setCheckModal] = useState(false);
  const showCheckModal = () => {
    setCheckModal(!checkmodal);
  };

  return (
    <>
      <div
        className={
          pathname === "/check/10" || pathname === "/check/11"
            ? "fixed top-0 left-0 z-2 w-full px-[8px] border-b bg-[#FAFCFF]"
            : "fixed top-0 left-0 z-2 w-full px-[8px]  bg-[#FAFCFF]"
        }
      >
        <div className="flex justify-between items-center">
          <span className="block w-[48px] h-[48px]"></span>
          <span>{title}</span>
          <img
            className="cursor-pointer inline-block"
            src={closeimg}
            alt="close"
            onClick={showCheckModal}
          />
        </div>
      </div>
      {checkmodal ? <CheckCloseModal showCheckModal={showCheckModal} /> : ""}
    </>
  );
};

export default Close;
