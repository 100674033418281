import Header2 from "../../admin_components/Header/Header2";

import React, { useEffect, useRef, useState } from "react";
import commonUrl, { api } from "../../../../data/common";
import { useNavigate, useLocation } from "react-router-dom";
import { useMainContext } from "../../../../contexts/Provider";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import DetailPageCheckTable from "./components/DetailPageCheckTable";
import DetailPageDiaryTable from "./components/DetailPageDiaryTable";
import { BtnBlack } from "../../admin_components/Buttons";
import { Loading } from "../../admin_components/Loading";
import DetailPageMemoTable from "./components/DetailPageMemoTable";
import ToggleBtn from "./components/ToggleBtn";
import { handlingError } from "../../admin_components/Error";
import { ToastContainer, toast } from "react-toastify";
import { toastCommonProps } from "../../admin_components/Toast";
import Memo from "./components/Memo";
export function Detail() {
  const graph_picture = "/images/admin/graph_picture_btn.svg";
  const location = useLocation();
  const navigate = useNavigate();

  const state = location.state; // 이전 페이지에서 넘겨준 띄울 사용자 정보 id값

  const { adminLogin, adminHistory } = useMainContext(); // 로그인 정보 Consumer
  // console.log(state, "~~~~!");
  const [loading, setLoading] = useState(true);
  /* 환자 등록 정보 */
  const [client, setClient] = useState({
    name: "", // 이름
    patientID: "", // 등록 번호
    nickName: "", // 닉네임
    birth: "", // 생일
    gender: "", // 성별
    height: "", // 키
    weight: "", // 현재 체중
    bmi: "", // BMI
    jangruType: "", // 장루 종류
    jangruSurgeryDate: "", // 장루 수술일
    professor: "", // 담당교수
    jangruClosedSurgeryOX: "", // 장루 폐쇄술 시행 여부
    jangruClosedSurgeryDate: "", // 장루 폐쇄술 시행일
    patientPwd: "", // 임시 비밀번호
    chemotherapyOX: "", // 항암치료 유무
    radiationtherapyOX: "", // 방사선 치료 유무
    endDate: "", // 참여 종류일
    disease: [], // 기저 질환
    surgery: "", // 수술명
    surveyDay: ["", ""], // 장루 정기 점검일
    createdAt: "", // 등록일
    updatedAt: "", // 최근 접속일
    status: "", // 상태
    patientMemos: [], // 메모
  });
  // 하단의 장루 상태평가 / 건강 다이어리 중 어떤 테이블을 보여줘야 하는지
  const [showTable, setShowTable] = useState(() => {
    // 이전 페이지가 건강 다이어리였다면
    if (state.prevPath === "건강 다이어리")
      return { jangru: false, diary: true };
    // 이전 페이지가 장루 상태평가 페이지였거나 그 이외였다면
    else return { jangru: true, diary: false, firstRender: true };
  });
  // const [memo, setMemo] = useState(""); // 메모 입력 내용
  // const memoRef = useRef(); // 메모 textarea focus 위해
  // viewport 너비에 따른 UI 변경 (너비 1280px 이하일 때 flex-row -> flex-col)
  const [flexCol, setFlexCol] = useState(() => {
    if (window.innerWidth <= 1280) return true;
    else return false;
  });

  // // 메모 삭제 팝업/ 삭제되는 메모의 id
  // const [memoCancelPopup, setMemoCancelPopup] = useState({
  //   memoCancelPopup: false,
  //   id: 0,
  // });

  // 메모 저장하는 함수
  // const saveMemo = () => {
  //   // 빈칸일 경우 저장 불가
  //   if (memo.trim() === "") {
  //     memoRef.current.focus();
  //     return;
  //   }
  //   async function fetchData() {
  //     setLoading(true);
  //     const response = await api({
  //       method: "post",
  //       url: commonUrl.patient + "memo",
  //       data: {
  //         content: memo,
  //         patient: state.data.id,
  //         admin: adminLogin.id,
  //       },
  //     }).catch((error) => {
  //       handlingError(error);
  //     });
  //     console.log(response.data, ">>> memo 저장 후");
  //     if (response.data.ok) {
  //       setMemo("");
  //       const response = await api({
  //         method: "get",
  //         url: commonUrl.patient + "Id/" + state.data.id,
  //       }).catch((error) => {
  //         handlingError(error);
  //       });
  //       console.log(
  //         response.data,
  //         ">>> Patient/id/id, memo 저장 후 데이터 다시 부르기"
  //       );
  //       if (response.data.ok) {
  //         const p = response.data.patient;
  //         // 표에 적합한 형식으로 다듬어서 저장
  //         setClient({
  //           ...p,
  //           age: new Date().getFullYear() - p.birth.substring(0, 4) + 1,
  //           gender: p.gender === "M" ? "남" : "여",
  //           jangruClosedSurgeryOX: p.jangruClosedSurgeryOX === "O" ? "유" : "무",
  //           chemotherapyOX: p.chemotherapyOX === "O" ? "유" : "무",
  //           radiationtherapyOX: p.radiationtherapyOX === "O" ? "유" : "무",
  //           status: p.status === 0 ? "활성" : "비활성",
  //         });
  //         setLoading(false);
  //       }
  //     }
  //   }
  //   fetchData();
  // };

  // // 메모 삭제하는 함수
  // const cancelMemo = () => {
  //   async function fetchData() {
  //     setLoading(true);
  //     const response = await api({
  //       method: "get",
  //       url: commonUrl.patient + "memo/delete/" + memoCancelPopup.id,
  //     });
  //     console.log(response.data);
  //     if (response.data.ok) {
  //       const response = await api({
  //         method: "get",
  //         url: commonUrl.patient + "Id/" + state.data.id,
  //       }).catch((error) => {
  //         handlingError(error);
  //       });
  //       console.log(response.data, ">>> Axios patient/Id/", `${state.data.id}`);
  //       if (response.data.ok && response.data.patient) {
  //         const p = response.data.patient;
  //         // 표에 적합한 형식으로 다듬어서 저장
  //         setClient({
  //           ...p,
  //           age: new Date().getFullYear() - p.birth.substring(0, 4) + 1,
  //           gender: p.gender === "M" ? "남" : "여",
  //           jangruClosedSurgeryOX: p.jangruClosedSurgeryOX === "O" ? "유" : "무",
  //           chemotherapyOX: p.chemotherapyOX === "O" ? "유" : "무",
  //           radiationtherapyOX: p.radiationtherapyOX === "O" ? "유" : "무",
  //           status: p.status === 0 ? "활성" : "비활성",
  //         });
  //         setLoading(false);
  //       }
  //     }
  //   }
  //   fetchData();
  //   setMemoCancelPopup({ ...memoCancelPopup, memoCancelPopup: false });
  // };

  // resize시 UI위치 구성 변경: flex-col || flex-row

  const handleResize = () => {
    if (window.innerWidth <= 1280) setFlexCol(true);
    else setFlexCol(false);
  };

  // bmi 결과 리턴하는 함수
  // const explainBMI = (bmi) => {
  //   // BMI 결과
  //   if (bmi <= 18.5) return bmi + "(저체중)";
  //   else if (bmi > 18.5 && bmi <= 22.9) return bmi + "(정상)";
  //   else if (bmi >= 23 && bmi <= 24.9) return bmi + "(과체중)";
  //   else if (bmi >= 25 && bmi <= 29.9) return bmi + "(1단계 비만)";
  //   else if (bmi >= 30 && bmi <= 34.9) return bmi + "(2단계 비만)";
  //   else return bmi + "(3단계 비만)";
  // };

  // 첫 랜더시 환자 id로 정보 불러오기
  useEffect(() => {
    // Axios
    async function fetchData() {
      setLoading(true);
      const response = await api({
        method: "get",
        url: commonUrl.patient + "Id/" + state.id,
      }).catch((error) => {
        handlingError(error);
      });
      // console.log(response.data, `>>> patient/id/${state.id}`);
      // axios 성공 시
      if (response.data.ok && response.data.patient) {
        const p = response.data.patient;
        // 표에 적합한 형식으로 다듬어서 저장
        setClient({
          ...p,
          age: new Date().getFullYear() - p.birth.substring(0, 4) + 1,
          gender: p.gender === "M" ? "남" : "여",
          jangruClosedSurgeryOX: p.jangruClosedSurgeryOX === "O" ? "유" : "무",
          chemotherapyOX: p.chemotherapyOX === "O" ? "유" : "무",
          radiationtherapyOX: p.radiationtherapyOX === "O" ? "유" : "무",
          status: p.status === 0 ? "활성" : "비활성",
        });
        setLoading(false);
      }
      // axios 실패 시
      else {
        setLoading(false);
        toast(
          <p>{response.data.error}</p>,
          toastCommonProps(1500, "top-left", "toast_common toast__normal")
        );
      }
    }
    fetchData();

    // resize detect
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 토글 버튼 누르면 자동으로 스크롤이 맨 밑으로 가게하기
  useEffect(() => {
    setTimeout(() => {
      // 첫 랜더한 경우가 아닐 때만 실행(첫 랜더 후에는 스크롤이 맨 위여야함)
      if (!showTable.firstRender) {
        const adminScroll = document
          .querySelector(".admin.scroll")
          .getBoundingClientRect().height;
        // console.log(document.querySelector(".admin.scroll"));
        window.scrollBy(0, adminScroll);
      }
    }, [300]);
  }, [showTable, state.prevPath]);

  return (
    <>
      <Header2
        title={[
          {
            title: "장루점검",
            url:
              // 달력에서 클릭하여 들어왔을 경우
              adminHistory[adminHistory.length - 2] ===
                "/admin/main/dashboard/more" ||
              // 대시보드의 장루 점검 대상자를 클릭하여 들어왔을 경우
              adminHistory[adminHistory.length - 2] === "/admin/main/dashboard"
                ? "/admin/main/check"
                : // 장루 관리를 통하여 들어왔을 경우
                  -1,
          },
          { title: "대상자 상세보기", url: 0 },
        ]}
      >
        {/* <style jsx="true">{`
          .popup-content {
            font-family: Pretendard !important;
            width: 303px;
            height: 170px;
            border: 1px solid #1b5edc;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 16px;
          }
        `}</style> */}
        {/* 메모 삭제 팝업 */}
        {/* <Popup
          memoCancelPopup={memoCancelPopup.memoCancelPopup}
          closeOnDocumentClick
          onClose={() => {
            setMemoCancelPopup({ ...memoCancelPopup, memoCancelPopup: false });
          }}
        >
          <div className="flex flex-col justify-between w-full h-full">
            <div className="w-full h-full mt-4 text-center font-normal text-base leading-[22px] text-[#020A1B] ">
              삭제 하시겠습니까?
            </div>
            <div className="flex gap-x-2">
              <button
                className="flex justify-center items-center w-[131.5px] h-[46px] font-bold text-base leading-[22px] text-[#1B5EDC] border border-[#1B5EDC] rounded-[4px] focus:outline-none hover:bg-[#1B5EDC] hover:text-[#FAFCFF]"
                onClick={cancelMemo}
              >
                삭제
              </button>
              <button
                className="flex justify-center items-center w-[131.5px] h-[46px] font-bold text-base leading-[22px] text-[#1B5EDC] border border-[#1B5EDC] rounded-[4px] focus:outline-none hover:bg-[#1B5EDC] hover:text-[#FAFCFF]"
                onClick={() => {
                  setMemoCancelPopup({
                    ...memoCancelPopup,
                    memoCancelPopup: false,
                  });
                }}
              >
                취소
              </button>
            </div>
          </div>
        </Popup> */}
        {/* 상세보기 페이지 UI */}
        <div className="admin scroll flex flex-col grow p-[32px]">
          <div className="h-[80px] min-h-[80px]"></div>
          {loading ? (
            <Loading />
          ) : (
            <>
              <label className="text-2xl font-extrabold leading-8 text-[#020A1B] mb-3">
                {`${client.name ? client.name : " _____"}님 상세기록 차트`}
              </label>
              <div
                className={
                  flexCol
                    ? "flex flex-col w-full min-w-[975px] gap-y-3 "
                    : "flex flex-row gap-x-3 "
                }
              >
                <div
                  className={
                    flexCol
                      ? "flex flex-col w-full min-w-[975px] p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)]"
                      : "min-w-[690px] max-w-[1202px] w-full flex flex-col p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)]"
                  }
                >
                  {/* 기본정보 표 */}
                  <label className="text-lg font-extrabold leading-[22px] text-[#343B48] mb-[10px]">
                    기본 정보
                  </label>
                  <div className="grid grid-cols-4 text-left ">
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                      환자 성명
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] font-normal p-[5px]">
                      {client.name}
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                      등록번호
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] font-normal p-[5px]">
                      {client.patientID}
                    </label>
                    {/* <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                      닉네임
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] font-normal p-[5px]">
                      {client.nickName}
                    </label> */}
                    {/* 두번째 줄 */}
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                      생년월일
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] font-normal p-[5px]">
                      {client.birth}
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border ">
                      성별
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] font-normal p-[5px]">
                      {client.gender}
                    </label>
                    {/* 세번째 줄 */}
                    {/* <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                      키(cm)
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] font-normal p-[5px]">
                      {client.height}
                    </label>{" "}
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                      최초 체중(kg)
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] font-normal p-[5px]">
                      {client.weight}
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                      BMI
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] font-normal  ">
                      {explainBMI(client.bmi)}
                    </label> */}
                  </div>

                  {/* 환자 장루 진료관련 표 */}
                  <label className="text-lg font-extrabold leading-[22px] text-[#343B48] mb-[10px] mt-[18px]">
                    장루 및 상세 정보
                  </label>
                  <div className="grid grid-cols-[27%_24%_25%_24%] text-left">
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                      수술명
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] font-normal p-[5px]">
                      {client.surgery === "선택" ? "-" : client.surgery}
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                      장루 종류
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] font-normal p-[5px]">
                      {client.jangruType}
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                      장루 수술일
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] font-normal p-[5px]">
                      {client.jangruSurgeryDate}
                    </label>

                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                      장루 폐쇄술 시행여부
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] font-normal p-[5px]">
                      {client.jangruClosedSurgeryOX}
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                      장루 폐쇄술 시행일
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] font-normal p-[5px]">
                      {client.jangruClosedSurgeryDate
                        ? client.jangruClosedSurgeryDate
                        : "해당없음"}
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                      항암 치료
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] font-normal p-[5px]">
                      {client.chemotherapyOX}
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                      방사선 치료
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] font-normal p-[5px]">
                      {client.radiationtherapyOX}
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                      장루 정기 점검일
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] font-normal p-[5px]">
                      {client.surveyDay[0] && client.surveyDay[1]
                        ? `매월 ${client.surveyDay[0]}일, ${client.surveyDay[1]}일`
                        : ""}
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                      등록일자
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] font-normal p-[5px]">
                      {client.startDate}
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                      기저질환
                    </label>
                    <label className="flex flex-wrap justify-start items-center px-4 py-[7px] border border-[#E5E6E7] font-normal p-[5px]">
                      {client.disease && client.disease.length > 0
                        ? client.disease?.map((disease, idx) => (
                            <span className="shrink-0">
                              {disease}
                              {idx === client.disease.length - 1 ? (
                                ""
                              ) : (
                                <span>,&nbsp;</span>
                              )}
                            </span>
                          ))
                        : "-"}
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                      참여 종료일
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] font-normal p-[5px]">
                      {client.endDate}
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                      상태
                    </label>
                    <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] font-normal p-[5px]">
                      {client.status}
                    </label>
                  </div>
                </div>

                {/* 메모 */}
                <Memo
                  flexCol={flexCol}
                  patientMemos={client.patientMemos}
                  state={state}
                  setClient={setClient}
                />
              </div>

              {/* 장루 상태 평가 / 건강 다이어리 / 그래프 & 사진 모아보기 */}
              <div className="flex flex-col w-full min-w-[975px] p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-3 ">
                <div className="flex items-center justify-between ">
                  <ToggleBtn
                    showTable={showTable}
                    setShowTable={setShowTable}
                    prevPath={state.prevPath ? state.prevPath : undefined}
                  />
                  {/* backfrom={state.backfrom}/> */}
                  <button
                    className="flex justify-center items-center w-[185px] min-w-[178px] h-[36px] bg-[#343B48] text-[#FAFBFC] text-sm leading-[18px] font-normal py-2 px-4 border border-transparent rounded-[48px]"
                    onClick={() => {
                      navigate("/admin/main/check/graphandpicture", {
                        state: {
                          id: state.id,
                          name: client.name ? client.name : "",
                        },
                      });
                    }}
                  >
                    <img
                      className=" w-[16.67px] h-[16.67px] mr-[5.6px]"
                      src={graph_picture}
                      alt="graph_picture"
                    />
                    그래프&사진 모아보기
                  </button>
                </div>
                {/* 테이블 */}
                <div className="mt-7 ">
                  {showTable.jangru ? (
                    <DetailPageCheckTable
                      state={
                        state
                          ? { id: state.id, patientName: client.name }
                          : null
                      }
                    ></DetailPageCheckTable>
                  ) : null}
                  {showTable.diary ? (
                    <DetailPageDiaryTable
                      state={state ? { id: state.id, name: client.name } : null}
                    ></DetailPageDiaryTable>
                  ) : null}
                </div>
              </div>
            </>
          )}
        </div>
      </Header2>
    </>
  );
}

export default Detail;
