import commonUrl, { api } from "../../../../../data/common";
import { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";

const ImagePopup = ({ url, date, from, closePopup, open }) => {
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });
  const imageRef = useRef();

  // setTimeout, clearInterval을 위해 값을 hold하고 있는 함수
  let movement_timer = null;

  // resize 됐을 때 새로운 너비 높이 값을 저장하는 함수
  const resize = () => {
    if (imageRef.current) {
      setSize({
        width: imageRef.current.offsetWidth,
        height: imageRef.current.offsetHeight,
      });
    }
  };

  // 창이 resize될 때 이벤트를 읽어서 pop UI 수정
  window.addEventListener("resize", () => {
    clearInterval(movement_timer);
    movement_timer = setTimeout(resize, 100);
  });

  // 첫랜더시에 사진 사이즈 저장
  useEffect(() => {
    if (imageRef.current) {
      setSize({
        width: imageRef.current.offsetWidth,
        height: imageRef.current.offsetHeight,
      });
    }
  }, [url]);

  return (
    <>
      <style jsx="true">{`
        .popup-overlay.imagePopup-overlay {
          overflow: auto;
        }
        .popup-content.imagePopup-content {
          background-color: transparent;
          border: 1px solid transparent;
          height: ${size.height}px;
          width: ${size.width}px;
        }
        .react-datepicker-wrapper {
          width: 118px;
        }
      `}</style>
      <Popup
        open={open}
        closeOnDocumentClick
        onClose={closePopup}
        className="imagePopup"
      >
        <img
          className="graphImage absolute max-h-[900px] max-w-[900px] z-[1000] inset-0 m-auto border border-slate-300 box-border object-contain overflow-auto"
          src={commonUrl.networkImg + encodeURIComponent(url)}
          alt={`${url}.jpg`}
          ref={imageRef}
        />
        <div
          className="absolute inset-0 m-auto z-[1001] "
          style={{
            width: `${size.width}px`,
            height: `${size.height}px`,
          }}
        >
          <div
            className=" flex justify-end bg-transparent p-[5px]"
            style={{ height: `45px`, width: `${size.width}px` }}
          >
            <div
              className="flex items-center justify-center h-[40px] w-[40px] rounded-[24px] bg-[#4E535F]"
              onClick={closePopup}
            >
              <span className="material-icons text-[35px] cursor-pointer rounded-[20px] text-white">
                close
              </span>
            </div>
          </div>
          <div
            className="bg-transparent"
            style={{ height: `${size.height - 80}px`, width: size.width }}
          ></div>
          <label
            className="flex jusetify-start mx-[25px] font-bold truncate text-white"
            style={{ width: size.width }}
          >{`${date} ${from}`}</label>
        </div>
      </Popup>
    </>
  );
};

export default ImagePopup;
