import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import common, { api } from "../../../../data/common";
import { useMainContext } from "../../../../contexts/Provider";
import { ToastContainer } from "react-toastify";
/*component*/
import TabBasic from "../../mobilecomponent/TabBasic";
import InspenctionPage1 from "./components/InspenctionPage1";
import InspectionPage2 from "./components/InspectionPage2";
import GobackPage from "../../mobilecomponent/GoBackPage";

import BasicLoading from "../../mobilecomponent/BasicLoading";
import CantGobackPage from "./components/CantGobackPage";

const Inspection = () => {
  const navigate = useNavigate();
  const { loginInput } = useMainContext();
  const locationstate = useLocation().state;
  const href = new URL(window.location.href);
  const urlParams = href.searchParams;
  const tokenSurveyDate = urlParams.get("surveyDate");
  const tokenID = urlParams.get("id");
  const surveyCreatedDate = tokenSurveyDate ? tokenSurveyDate : locationstate;
  const MYID = tokenID ? tokenID : loginInput.id;

  const [page1, setPage1] = useState(false);
  const [page2, setPage2] = useState(false);
  const [survey, setSurvey] = useState([]);
  const [understanded, setUnderstanded] = useState("");
  const [loading, setLoading] = useState(true);
  const [cantgoback, setCantgoback] = useState(false);
  const [ReplyM, setReplyM] = useState(false);
  const [jangruReplyNurse, setJangruReplyNurse] = useState([]);
  const [jangruReplyNurseAdmin, setJangruReplyNurseAdmin] = useState([]);
  const [jangruCheck, setJangruCheck] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [extraUrls, setExtraUrls] = useState([]);
  const showPage1 = () => {
    setPage1(true);
    setPage2(false);
  };
  const showPage2 = () => {
    setPage2(true);
    setPage1(false);
  };
  const showCantgo = () => {
    setCantgoback(!cantgoback);
  };
  useEffect(() => {
    let pageOpen = false;
    const getSurveyCreated = () => {
      api
        .get(common.baseURL + `jangruSurvey/${MYID}/${surveyCreatedDate}`)
        .then((res) => {
          // console.log(res, ">>>>>>>>res");
          // console.log(surveyCreatedDate, MYID);
          setUnderstanded(res.data.understanding);
          if (res.data.ok === true) {
            setSurvey(res.data.jangruSurvey);
            api
              .post(common.baseURL + "jangruReply/forPatient/", {
                id: res.data.jangruSurvey.id,
              })
              .then((postres) => {
                // console.log(postres, "postrespostres");
                if (postres.data.ok === true) {
                  if (
                    postres.data.message !==
                    "jangruReply와 jangruCheck 모두 존재하지 않습니다."
                  ) {
                    setReplyM(true);
                    setJangruReplyNurse(
                      postres.data.jangruReplyNurse &&
                        postres.data.jangruReplyNurse
                    );
                    setJangruCheck(postres.data.jangruCheck);
                    setJangruReplyNurseAdmin(
                      postres.data.jangruReplyNurse?.admin
                    );
                    // setJangruReplyNurseAdmin(postres.data.jangruCheck.admin);

                    const allFiles =
                      postres.data.jangruReplyNurse?.attachedFileURL;
                    // postres.data.jangruCheck.attachedFileURL;

                    const filterimageUrl = allFiles?.filter(
                      (el) =>
                        el.slice(-4) === ".jpg" ||
                        el.slice(-5) === ".jpeg" ||
                        el.slice(-4) === ".png"
                    );
                    const filterOtherUrl = allFiles?.filter(
                      (el) => el.slice(-4) === ".pdf"
                    );

                    setImageUrls(filterimageUrl);
                    setExtraUrls(filterOtherUrl);
                  }
                  let start = setTimeout(() => {
                    setLoading(false);
                  }, 500);
                  return () => {
                    clearTimeout(start);
                  };
                }
              })
              .catch((error) => console.log(error));
          }
        })
        .catch((error) => console.log(error));
    };
    if (!pageOpen) {
      if (
        loginInput.id === null ||
        loginInput.id === undefined ||
        loginInput.id === ""
      ) {
        navigate("/main", { replace: true });
      } else {
        setPage1(true);
        getSurveyCreated();
      }
    }

    return () => {
      pageOpen = true;
    };
  }, [loginInput.id, surveyCreatedDate, MYID, navigate]);

  return (
    <>
      {loading ? <BasicLoading /> : ""}
      <div className="bg-[#F1F2F3] h-screen relative overflow-y-auto">
        <GobackPage
          understanded={understanded}
          setCantgoback={setCantgoback}
          ReplyM={ReplyM}
          jangruReplyNurse={jangruReplyNurse}
        >
          기록보기
        </GobackPage>
        <div
          className={
            page1
              ? "pt-[48px] pb-20 bg-[#FAFCFF] h-screen relative overflow-y-auto"
              : "pt-[48px] pb-20"
          }
        >
          <TabBasic
            tab1={"점검내용"}
            tab2={"작성내용"}
            showPage1={showPage1}
            showPage2={showPage2}
            page1={page1}
            page2={page2}
          />
          {page1 ? (
            <InspenctionPage1
              surveydate={surveyCreatedDate}
              surveyid={survey.id}
              understanded={understanded}
              setUnderstanded={setUnderstanded}
              MYID={MYID}
              jangruReplyNurse={jangruReplyNurse}
              jangruReplyNurseAdmin={jangruReplyNurseAdmin}
              jangruCheck={jangruCheck}
              imageUrls={imageUrls}
              extraUrls={extraUrls}
            />
          ) : (
            ""
          )}
          {page2 ? <InspectionPage2 survey={survey} /> : ""}
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        bodyClassName={() =>
          "w-[175px] p-3 rounded-full overflow-hidden bg-[#CBDFFC] text-black text-sm font-normal"
        }
      ></ToastContainer>
      {cantgoback ? <CantGobackPage showCantgo={showCantgo} /> : ""}
    </>
  );
};

export default Inspection;
