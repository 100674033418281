import React from "react";
const AnnouncedUserId = ({ showfindId, findeuserId }) => {
  return (
    <div className="w-full fixed top-[34px] left-0 z-3 ">
      <div className=" px-4 h2_800 max-w-md h-screen flex flex-col mx-auto text-gray-600 antialiased bg-[#FAFCFF] relative">
        <h1 className="h2_800 pt-[48px]">등록번호 안내</h1>
        <p className="h2_800 pt-[52px]">
          <span className="block">귀하의 등록번호는</span>
          <span className="inline-block text-green-500">{findeuserId}</span>
          <span className="inline-block">입니다.</span>
        </p>
      </div>
      <span
        className="fixed bottom-0 left-0 w-full block text-center text-white button_700 bg-[#9DC4FA] hover:bg-[#1B5EDC] w-full py-[20px] ease-in-out duration-300 cursor-pointer"
        onClick={showfindId}
      >
        로그인 하기
      </span>
    </div>
  );
};

export default AnnouncedUserId;
