import React, { useEffect, useState } from "react";
import { BtnBlack } from "../../../admin_components/Buttons";
import { useLocation, useNavigate } from "react-router-dom";
import { Flip, toast, ToastContainer } from "react-toastify";
import common, { api } from "../../../../../data/common";

import { useMainContext } from "../../../../../contexts/Provider";
import ReactQuill from "react-quill";
import TextEditorToolbar, {
  modules,
  formats,
} from "../../../admin_components/TextEditorToolbar";
import "react-quill/dist/quill.snow.css";
import { toastCommonProps } from "../../../admin_components/Toast";

const InquiryEdit = () => {
  const [loading] = useState(false);
  const { adminLogin } = useMainContext();
  const [url, setUrl] = useState([]);
  const [contents, setContents] = useState([]);
  const questionid = useLocation().state.question.id;
  const [fileon, setFileon] = useState(false);
  const [setfile, setSetfile] = useState([]);
  const navigate = useNavigate();
  const [flag, setFlag] = useState(false);
  const [files, setFiles] = useState([]);
  const regex = /\s/gi;
  const toastEl = document.querySelector(".toast_standard");
  const [answer, setAnswer] = useState({
    admin: adminLogin.id,
    content: "a",
    fileURL: url,
    question: questionid,
  });

  const toast_func = (text) => {
    console.log(text);
    toast(
      <p>{text}</p>,
      toastCommonProps(3000, "top-left", "toast_common toast__normal", Flip)
    );
  };
  useEffect(() => {
    let pageOpen = false;
    const getNews = () => {
      api.get(common.baseURL + "question/id/" + questionid).then((res) => {
        if (res.data.ok === true) {
          setUrl(res.data.answer.fileURL);
          setContents(res.data.answer.content);
          setAnswer({ ...answer, content: contents });
        }
      });
    };
    if (!pageOpen) {
      getNews();
    }
    return () => {
      pageOpen = true;
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionid, contents]);

  const newsFileArr = [];
  const bowl = [];

  const onChangeFile = (e) => {
    setToastElPosition({
      top: toastEl.getBoundingClientRect().top,
      left: toastEl.getBoundingClientRect().left,
    });
    const file = e.target.files;
    let cnt = 0;

    for (let i = 0; i < file.length; i++) {
      if (url.includes(file[i].lastModified + file[i].name)) {
        toast_func("동일한 파일이 존재합니다.");
        break;
      }
      newsFileArr.push(file[i].lastModified + file[i].name);
      bowl.push(file[i].name);
    } //동일파일 점검 for문
    const new_file = url.concat(newsFileArr);

    for (let i = 0; i < new_file.length; i++) {
      const file_form = new_file[i].slice(
        new_file[i].indexOf("."),
        new_file[i].length
      );
      if (file_form === ".jpg" || file_form === ".jpeg") cnt++;
    }

    if (cnt < 6) {
      setFiles(file);
      setAnswer({ ...answer, fileURL: newsFileArr });
      setFileon(true);
      setSetfile(bowl);
      if (url.length > 0) setUrl(new_file);
    } else toast_func("이미지가 5개를 초과하였습니다.");
  };

  const [toastElPosition, setToastElPosition] = useState({ top: 0, left: 0 }); // toast창 위치 값

  const submitData = () => {
    const toastEl = document.querySelector(".toast_standard");

    setToastElPosition({
      top: toastEl.getBoundingClientRect().top,
      left: toastEl.getBoundingClientRect().left,
    });

    let body = {
      admin: answer.admin,
      content: answer.content,
      fileURL: url.length > 0 ? url : answer.fileURL,
      question: answer.question,
    };
    let axiosnum = 0;
    if (
      body.content.replace(/(\s*)/g, "") === "<p></p>" ||
      body.content === "<p><br></p>" ||
      body.content === ""
    ) {
      toast_func("누락된 항목이 있습니다.");
    } else {
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          let formData = new FormData();
          const filename = files[i].lastModified + files[i].name;
          formData.append("file", files[i]);
          formData.append("name", filename.replace(regex, ""));
          // eslint-disable-next-line no-loop-func
          api
            .post(common.baseURL + "upload/oneNew", formData)
            .then((awsres) => {
              if (awsres.data.ok === true) {
                axiosnum = axiosnum + 1;
              } else {
                toast_func("사진 등록이 실패하였습니다.");
              }
              if (axiosnum === files.length) {
                api
                  .post(common.baseURL + "answer", body)
                  .then((postRes) => {
                    if (postRes.data.ok === true) {
                      toast_func("저장되었습니다.");
                      setTimeout(() => navigate("/admin/main/inquiry"), 3000);
                    } else {
                      toast_func("누락된 항목이 있습니다.");
                    }
                  })
                  .catch((error) => console.log(error));
              }
            });
        }
      } else {
        api.post(common.baseURL + "answer", body).then((res) => {
          if (res.data.ok === true) {
            toast_func("저장되었습니다.");

            setTimeout(() => navigate("/admin/main/inquiry"), 3000);
          } else {
            toast_func("누락된 항목이 있습니다.");
          }
        });
      }
    }
  };
  let file_clk = false;
  let back_clk = false;
  return (
    <>
      <style jsx="true">{`
        .Toastify__toast {
          top: ${toastElPosition.top - 20}px;
          left: ${toastElPosition.left - 285}px;
        }
        .ql-toolbar.ql-snow {
          border-top-width: 0 !important;
          border-left-width: 0 !important;
          border-right-width: 0 !important;
          border-bottom-width: 2px !important;
          border-color: #e5e6e7 !important;
        }
        .ql-container.ql-snow {
          border: none !important;
        }
        .ql-container.ql-snow .ql-editor {
          padding: 0px !important;
        }
      `}</style>
      <div className="text-left text-xl leading-[26px] font-extrabold text-[#343B48] mb-[25px]">
        답변내용 (수정)
      </div>
      {/* 표 */}
      <div className="grid grid-cols-5 min-w-[915px] text-center border border-[#E5E6E7] bg-[#fafbfc] ">
        <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border">
          작성자
        </label>
        <label className="flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7] bg-[#F1F2F3] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-4">
          {adminLogin.role === 0
            ? adminLogin.belongName + " 관리자"
            : adminLogin.role === 1
            ? adminLogin.belongName + " 의사"
            : adminLogin.belongName + " 간호사"}
        </label>
        <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border">
          내용
        </label>

        <div className="w-full bg-white vertical-middle border border-[#E5E6E7] col-span-4 overflow-auto">
          <TextEditorToolbar />
          <ReactQuill
            className="w-full h-40 p-6 "
            theme="snow"
            placeholder={"내용을 적어주세요."}
            modules={modules}
            formats={formats}
            value={answer.content.toString()}
            onChange={(content, delta, source, editor) => {
              setAnswer({ ...answer, content: editor.getHTML() });
            }}
          />
        </div>
        <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border">
          첨부 파일
        </label>
        <div className="items-start gap-y-2 p-2 border border-[#E5E6E7] col-span-4 bg-white">
          <label
            className={
              url.length > 0
                ? "inline-block w-full p-6 cursor-grab"
                : "inline-block w-full p-6 "
            }
            htmlFor={url.length > 0 ? "input_file" : ""}
            onClick={() => {
              console.log("qorud");
              back_clk = true;
              if (!file_clk && back_clk) {
                setFlag(true);
              }
              back_clk = false;
              file_clk = false;
            }}
          >
            {url.length > 0 ? (
              /* element 시작 */
              url.map((el, idx) => (
                <div className="w-fit" key={idx}>
                  <label key={idx + 10} className="text-black">
                    <label
                      className="flex text-left break-all w-fit"
                      onClick={() => {
                        file_clk = true;
                        if (file_clk && !back_clk) {
                          setFlag(false);
                        }
                      }}
                    >
                      <label
                        className="underline cursor-pointer font-semibold text-base leading-[22px] text-[#020A1B] pb-1 hover:text-blue-500"
                        onClick={(e) => {
                          window.open(
                            `${common.networkImg}${el.replace(/(\s*)/g, "")}`
                          );
                        }}
                      >
                        {el.slice(13, el.length)}
                      </label>
                      <span
                        className=" material-icons text-[15px]  cursor-pointer px-1 pt-1 rounded-full"
                        onClick={(e) => {
                          file_clk = true;
                          setFlag(false);

                          let filterdata = url.filter(
                            (el, index) => index !== idx
                          );
                          setUrl(filterdata);
                        }}
                      >
                        cancel
                      </span>
                    </label>
                  </label>
                </div>
              ))
            ) : (
              <div className="flex flex-col flex-wrap col-span-4 p-2 font-semibold w-fit">
                <label
                  htmlFor="input_file"
                  className="w-[88px] h-[38px] px-4 py-2 bg-[#4E535F] hover:bg-gray-700 cursor-pointer text-[#FFFFFF] font-bold text-base leading-[22px] rounded-[6px] mb-2"
                >
                  파일첨부
                </label>
                <input
                  id="input_file"
                  className="hidden w-full"
                  type="file"
                  label="Upload"
                  accept=".jpeg, .jpg, .png, .pdf"
                  multiple
                  placeholder="첨부할 파일이 있는 경우 버튼을 클릭하여 등록하세요. (이미지는 최대 5개)"
                  onChange={onChangeFile}
                />
                {fileon ? (
                  <>
                    {setfile.map((e, i) => (
                      <p
                        className="w-auto mt-2 text-sm text-left text-gray-400"
                        key={i}
                      >
                        {e}
                      </p>
                    ))}
                  </>
                ) : (
                  <p className="mt-2 text-sm text-left text-gray-400">
                    *첨부 파일이 있는 경우 이곳을 클릭하여 등록 하세요.(이미지는
                    최대 5개)
                  </p>
                )}
              </div>
            )}
          </label>
        </div>
        {flag ? (
          <input
            id="input_file"
            className="hidden w-full"
            type="file"
            label="Upload"
            accept=".jpeg, .jpg, .doc, .docx, .pdf"
            multiple
            placeholder="첨부할 파일이 있는 경우 버튼을 클릭하여 등록하세요. (이미지는 최대 5개)"
            onChange={onChangeFile}
          />
        ) : (
          ""
        )}
      </div>
      <div className="flex w-full justify-end items-center mt-[18px]">
        <div className="flex gap-x-2">
          <BtnBlack
            context="저장하기"
            onClick={submitData}
            loading={loading}
            width="88px"
            standard="toast_standard"
          />
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default InquiryEdit;
