import React, { useState, useEffect, useCallback } from "react";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { useMainContext } from "../../../../contexts/Provider";
import { useNavigate, useLocation } from "react-router-dom";

import common, { api } from "../../../../data/common";
import { toast, ToastContainer } from "react-toastify";
/*component*/
import { previousToday, nextToday } from "../../mobilecomponent/daypagination";
import DiaryCenterModal from "../../mobilecomponent/DiaryCenterModal";
import DiaryImageShow from "./component/DiaryImageShow";
import Poop1000Modal from "./component/Poop1000Modal";
import Poop1900Modal from "./component/Poop1900Modal";
import ImageSizeBig from "./component/ImageSizeBig";
import BasicLoading from "../../mobilecomponent/BasicLoading";

const HealthDiary = () => {
  const checkon = "/images/mo/checkon.svg";
  const checkoff = "/images/mo/checkoff.svg";
  const leftarrow = "/images/mo/leftarrow.svg";
  const poopinner13 = "/images/mo/poopinner13.svg";
  const poopinner23 = "/images/mo/poopinner23.svg";
  const water = "/images/mo/water.svg";
  const lefticon = "/images/mo/left.svg";
  const righticon = "/images/mo/right.svg";
  const rightunactive = "/images/mo/right_unactive.svg";
  const text13 = "/images/mo/text13.svg";
  const text23 = "/images/mo/text23.svg";
  const navigate = useNavigate();
  const {
    setFileArray,
    fileArray,
    files,
    setFiles,
    selectedImages,
    setSelectedImages,
    loginInput,
    setClickedDate,
  } = useMainContext();
  const state = useLocation().state;
  let val = "today";
  const [range, setRange] = useState({
    thisDate: state ? state : dayjs().format("YYYY-MM-DD"),
  });
  const [hydroGuide, setHydroGuide] = useState(false);
  const [poopGuide, setPoopGuide] = useState(false);
  const [diarydata, setDiaryData] = useState({
    id: "",
    writeDate: "",
    weight: 0,
    water: 0,
    toilet13: 0,
    toilet23: 0,
    jangruChange: 1,
    meal: "",
    memo: "",
    imageURL: [],
    patient: "",
  });
  const [hasimageData, setHasImageData] = useState([]);
  const [poop1000, setPoop1000] = useState(false);
  const [poop1900, setPoop1900] = useState(false);
  const [oldData, setOldData] = useState([]);
  const [transbg, setTransBg] = useState(false);
  const [imageSizeBig, setImageSizeBig] = useState(false);
  const [saving, setSaving] = useState(false);

  // 이전기록과비교
  const diaryDataOldDataAxios = useCallback(
    ({ res }) => {
      // console.log(res, ">>>>res???????!!!!");

      setDiaryData({
        id: res.data.diary.id,
        writeDate: res.data.diary.writeDate,
        weight: res.data.diary.weight,
        water: res.data.diary.water,
        toilet13: res.data.diary.toilet13,
        toilet23: res.data.diary.toilet23,
        jangruChange: res.data.diary.jangruChange,
        meal: res.data.diary.meal,
        memo: res.data.diary.memo,
        imageURL: res.data.diary.imageURL,
        patient: loginInput.id,
      });
      setOldData({
        id: res.data.diary.id,
        writeDate: res.data.diary.writeDate,
        weight: res.data.diary.weight,
        water: res.data.diary.water,
        toilet13: res.data.diary.toilet13,
        toilet23: res.data.diary.toilet23,
        jangruChange: res.data.diary.jangruChange,
        meal: res.data.diary.meal,
        memo: res.data.diary.memo,
        imageURL: res.data.diary.imageURL,
        patient: loginInput.id,
      });
      console.log(res);
      setHasImageData(res.data.diary.imageURL);
    },
    [loginInput.id]
  );

  // 이미지관련모든배열빈값
  const imageArrayAllNull = useCallback(() => {
    setHasImageData([]);
    setFileArray([]);
    setSelectedImages([]);
  }, [setHasImageData, setFileArray, setSelectedImages]);

  // 다이어리데이터 초기화
  const DiaryDataNull = useCallback(
    ({ loginInput }) => {
      setDiaryData({
        writeDate: "",
        weight: 0,
        water: 0,
        toilet13: 0,
        toilet23: 0,
        jangruChange: 1,
        meal: "",
        memo: "",
        imageURL: [],
        patient: loginInput.id,
      });
    },
    [setDiaryData]
  );


  // 최근에 저장된 체중값
  useEffect(() => {
    api
      .get(common.baseURL + "patient/Id/" + loginInput.id)
      .then((res) => {
        // console.log(res, "patiendInfo");
        if (
          res.data.ok === true &&
          dayjs().format("YYYY-MM-DD") === range.thisDate
        ) {
          setDiaryData((prevState) => {
            return { ...prevState, weight: res.data.patient.weight };
          });
          // console.log(res.data.patient.weight, "patient.weight"); //todo 체중값 다이어리 날자 변경할때마다 바껴야함
        }
      })
      .catch((error) => console.log("환자 정보 가져오기 실패:" + error));
  }, [range]);

  useEffect(() => {
    let pageOpen = false;

    if (!pageOpen) {
      if (
        loginInput.id === null ||
        loginInput.id === undefined ||
        loginInput.id === ""
      ) {
        navigate("/main", { replace: true });
      } else {
        setRange({
          thisDate: range.thisDate,
        });

        let body = {
          id: loginInput.id,
          writeDate: range.thisDate,
        };

        api
          .post(common.baseURL + "diary/getDiary", body)
          .then((res) => {
            imageArrayAllNull();
            if (res.data.ok === true) {
              diaryDataOldDataAxios({ res });
              // console.log(res, "diary/getDiary");
            } else {
              setHasImageData([]);
              DiaryDataNull({ loginInput });
            }
          })
          .catch((error) => console.log(error));
      }
    }
    return () => {
      pageOpen = true;
    };
  }, [
    loginInput,
    setFileArray,
    setFiles,
    setSelectedImages,
    state,
    range.thisDate,
    navigate,
    imageArrayAllNull,
    diaryDataOldDataAxios,
    DiaryDataNull,
  ]);

  // 이전날짜변경
  const handleLeft = () => {
    //changeDate(prev)
    const day = 1;
    const { thisDate } = range;

    switch (val) {
      case "today":
        const { clickDate } = previousToday(day, thisDate);
        setRange({
          thisDate: clickDate,
        });
        break;

      default:
        setRange({
          thisDate: thisDate,
        });
    }
    setClickedDate(previousToday(day, thisDate).clickDate);
    imageArrayAllNull();

    api
      .post(common.baseURL + "diary/getDiary", {
        id: loginInput.id,
        writeDate: `${previousToday(day, thisDate).clickDate}`,
      })

      .then((res) => {
        // console.log(res, "diary/getDiary222");
        if (res.data.ok === true) {
          diaryDataOldDataAxios({ res });
        } else {
          DiaryDataNull();
          setHasImageData([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //이후날짜변경
  const handleRight = () => {
    //changeDate(next)
    const day = 1;
    const { thisDate } = range;

    switch (val) {
      case "today":
        const { clickDate } = nextToday(day, thisDate);
        setRange({
          thisDate: clickDate,
        });
        break;

      default:
        setRange({
          thisDate: thisDate,
        });
    }

    setClickedDate(nextToday(day, thisDate).clickDate);
    imageArrayAllNull();
    api
      .post(common.baseURL + "diary/getDiary", {
        id: loginInput.id,
        writeDate: `${nextToday(day, thisDate).clickDate}`,
      })
      .then((res) => {
        // console.log(res, "diary/getDiary333");
        if (res.data.ok === true) {
          diaryDataOldDataAxios({ res });
        } else {
          DiaryDataNull();
          setHasImageData([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //오늘날짜로변경
  const handleToday = () => {
    setRange({
      thisDate: dayjs().format("YYYY-MM-DD"),
    });

    setClickedDate(dayjs().format("YYYY-MM-DD"));
    imageArrayAllNull();
    api
      .post(common.baseURL + "diary/getDiary", {
        id: loginInput.id,
        writeDate: `${dayjs().format("YYYY-MM-DD")}`,
      })
      .then((res) => {
        console.log(res);
        if (res.data.ok === true) {
          diaryDataOldDataAxios({ res });
        } else {
          DiaryDataNull();
          setHasImageData([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //배변 1000이상일때 모달
  const showPoop1000 = () => {
    setPoop1000(!poop1000);
  };

  //배변 1900이상일때 모달
  const showPoop1900 = () => {
    setPoop1900(!poop1900);
  };

  //수분섭취 안내 모달
  const showHydroguide = () => {
    setHydroGuide(!hydroGuide);
  };

  //배변량 안내 모달
  const showPoopguide = () => {
    setPoopGuide(!poopGuide);
  };
  //이미지사이즈 15mb 이상일때 모달
  const showImageBig = () => {
    setImageSizeBig(!imageSizeBig);
  };

  //diarydata onchange + 숫자 정규식
  const onChangeDiaryData = (e) => {
    const { value, name } = e.target;
    const threeReg = /^\d{0,3}$/;
    const threeoneReg = "^([0-9]{0,3}[.][0-9]{0,1})?$";
    const threetwoReg = "^([0-9]{0,5}[.][0-9]{2})?$";

    if (name === "weight") {
      if ((value.match(threeoneReg) || value.match(threeReg)) && value !== "") {
        setDiaryData({ ...diarydata, [name]: value });
      } else {
        if (value === "" || value === 0) {
          setDiaryData({ ...diarydata, [name]: "" });
        } else if (value === threetwoReg) {
          setDiaryData({ ...diarydata, [name]: Number(value).toFixed(1) });
        }
      }
    } else {
      setDiaryData({ ...diarydata, [name]: value });
    }
  };
  //데이터 저장시 toast + 이전데이터가져오기 +
  const toastAndNavigate = ({ range }) => {
    toast(`👏 저장되었습니다.`, {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "transparent toastbottom",
    });

    let start = setTimeout(() => {
      setSaving(false);
      navigate("/report/diary", {
        state: {
          where: "healthdiary",
          date: range.thisDate,
        },
      });
      setTransBg(false);
    }, 1000);
    return () => {
      clearTimeout(start);
    };
  };

  const submitdata = () => {
    setSaving(true);
    let thisDateInfo = {
      id: loginInput.id,
      writeDate: range.thisDate,
    };
    let editOriginalData = {
      id: diarydata.id,
      writeDate: range.thisDate,
      weight: diarydata.weight ? diarydata.weight : 0,
      water: diarydata.water ? diarydata.water : 0,
      toilet13: diarydata.toilet13 ? diarydata.toilet13 : 0,
      toilet23: diarydata.toilet23 ? diarydata.toilet23 : 0,
      jangruChange: diarydata.jangruChange,
      meal: diarydata.meal ? diarydata.meal : undefined,
      memo: diarydata.memo ? diarydata.memo : undefined,
      imageURL: hasimageData.length > 0 ? hasimageData : fileArray,
      patient: loginInput.id,
    };
    let submitNewData = {
      writeDate: range.thisDate,
      weight: diarydata.weight ? diarydata.weight : 0,
      water: diarydata.water ? diarydata.water : 0,
      toilet13: diarydata.toilet13 ? diarydata.toilet13 : 0,
      toilet23: diarydata.toilet23 ? diarydata.toilet23 : 0,
      jangruChange: diarydata.jangruChange,
      meal: diarydata.meal ? diarydata.meal : undefined,
      memo: diarydata.memo ? diarydata.memo : undefined,
      imageURL: fileArray,
      patient: loginInput.id,
    };

    api
      .post(common.baseURL + "diary/getDiary", thisDateInfo)
      .then((res) => {
        console.log(res);
        if (res.data.ok === true) {
          //1.diaryid가 있을때 기존 정보 수정
          console.log(hasimageData.length);

          if (hasimageData.length > 0) {
            //2.diaryid 있을때 + 기존 정보수정 + 기존 이미지 갯수 수정
            api
              .post(common.baseURL + "diary", editOriginalData)
              .then((editoriginal) => {
                console.log(editOriginalData, ">>>>>수정시 보여짐");
                if (editoriginal.data.ok === true) {
                  console.log(
                    "diaryid 있을때 기존 정보수정 + 기존 이미지갯수수정"
                  );
                  toastAndNavigate({ range });
                } else {
                  console.log(
                    "diaryid 있을때 기존 정보수정 + 기존 이미지갯수수정 실패"
                  );
                }
              })
              .catch((error) => console.log(error));
          } else {
            if (files.length > 0) {
              //3-1.diaryid가 있을때 + 기본정보수정 + 기존 사진 다지우고 새 사진 등록
              let num = 0;
              let fileBigNum = 0;
              for (let i = 0; i < files.length; i++) {
                console.log(files[i].size);
                if (files[i].size / 1000000 > 15) {
                  fileBigNum = 1;
                } else {
                  setTransBg(true);
                  let formData = new FormData();
                  formData.append("file", files[i]);
                  formData.append(
                    "name",
                    files[i].lastModified + files[i].name.replaceAll(" ", "")
                  );

                  //서버에 저장
                  api
                    .post(common.baseURL + "upload/oneNew", formData)
                    .then((awsres) => {
                      if (awsres.data.ok === true) {
                        num = num + 1;
                        if (files.length === num) {
                          api
                            .post(common.baseURL + "diary", editOriginalData)
                            .then((submitnew) => {
                              console.log(
                                editOriginalData,
                                ">>>>>수정시 보여짐"
                              );
                              if (submitnew.data.ok === true) {
                                console.log(
                                  "diaryid 있을때 기본정보수정 + 기존사진 다지우고 새사진 등록"
                                );
                                toastAndNavigate({ range });
                              }
                            })
                            .catch((error) => console.log(error));
                        }
                      }
                    });
                }
              }
              if (fileBigNum === 1) {
                setImageSizeBig(true);
              } else {
              }
            } else {
              //3-2. 사진이미지 없을때
              api
                .post(common.baseURL + "diary", editOriginalData)
                .then((submitnew) => {
                  if (submitnew.data.ok === true) {
                    console.log("diaryid 있을때 기본정보수정 (사진없을때)");
                    toastAndNavigate({ range });
                  }
                })
                .catch((error) => console.log(error));
            }
          }
        } else {
          //1.diaryid가 없을때 새기록 등록
          if (files.length > 0) {
            //새이미지 포함 정보 등록

            let num = 0;
            let fileBigNum = 0;
            for (let i = 0; i < files.length; i++) {
              console.log(files[i].size);
              if (files[i].size / 1000000 > 15) {
                fileBigNum = 1;
              } else {
                setTransBg(true);
                let formData = new FormData();
                formData.append("file", files[i]);
                formData.append(
                  "name",
                  files[i].lastModified + files[i].name.replaceAll(" ", "")
                );

                //서버에 저장
                api
                  .post(common.baseURL + "upload/oneNew", formData)
                  .then((awsres) => {
                    if (awsres.data.ok === true) {
                      num = num + 1;
                      if (files.length === num) {
                        api
                          .post(common.baseURL + "diary", submitNewData)
                          .then((submitnew) => {
                            if (submitnew.data.ok === true) {
                              toastAndNavigate({ range });
                            }
                          })
                          .catch((error) => console.log(error));
                      }
                    }
                  });
              }
            }
            if (fileBigNum === 1) {
              setImageSizeBig(true);
            }
          } else {
            //2.diaryid가 없을때 새기록 등록
            //새이미지 제외한 정보 등록
            api
              .post(common.baseURL + "diary", submitNewData)
              .then((submitnew) => {
                if (submitnew.data.ok === true) {
                  toastAndNavigate({ range });
                }
              })
              .catch((error) => console.log(error));
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const goBack = () => {
    navigate("/main");
  };

  const onClickWaterMinus = () => {
    if (Number(diarydata.water) > 0) {
      setDiaryData({
        ...diarydata,
        water: Number(diarydata.water) - 1,
      });
    } else {
      setDiaryData({ ...diarydata, water: 0 });
    }
  };
  const onClickWaterPlus = () => {
    setDiaryData({
      ...diarydata,
      water: Number(diarydata.water) + 1,
    });
  };
  const onClickToilet13Minus = () => {
    if (Number(diarydata.toilet13) > 0) {
      setDiaryData({
        ...diarydata,
        toilet13: Number(diarydata.toilet13) - 1,
      });
    } else {
      setDiaryData({ ...diarydata, toilet13: 0 });
    }
  };
  const onClickToilet13Plus = () => {
    setDiaryData({
      ...diarydata,
      toilet13: Number(diarydata.toilet13) + 1,
    });

    if (
      Number(diarydata.toilet13 + 1) * 100 + diarydata.toilet23 * 200 ===
        1900 ||
      Number(diarydata.toilet13 + 1) * 100 + diarydata.toilet23 * 200 === 2000
    ) {
      setPoop1000(true);
      setDiaryData({
        ...diarydata,
        toilet13: Number(diarydata.toilet13),
      });
    } else if (
      Number(diarydata.toilet13 + 1) * 100 + diarydata.toilet23 * 200 ===
      1000
    ) {
      setPoop1000(true);
      setDiaryData({
        ...diarydata,
        toilet13: Number(diarydata.toilet13) + 1,
      });
    }
  };
  const onClickToilet23Minus = () => {
    if (Number(diarydata.toilet23) > 0) {
      setDiaryData({
        ...diarydata,
        toilet23: Number(diarydata.toilet23) - 1,
      });
    } else {
      setDiaryData({ ...diarydata, toilet23: 0 });
    }
  };
  const onClickToilet23Plus = () => {
    setDiaryData({
      ...diarydata,
      toilet23: Number(diarydata.toilet23) + 1,
    });

    if (
      Number(diarydata.toilet23 + 1) * 200 + diarydata.toilet13 * 100 ===
        1900 ||
      Number(diarydata.toilet23 + 1) * 200 + diarydata.toilet13 * 100 === 2000
    ) {
      setPoop1900(true);
      setDiaryData({
        ...diarydata,
        toilet23: Number(diarydata.toilet23),
      });
    } else if (
      Number(diarydata.toilet23 + 1) * 200 + diarydata.toilet13 * 100 ===
      1000
    ) {
      setPoop1000(true);
      setDiaryData({
        ...diarydata,
        toilet23: Number(diarydata.toilet23) + 1,
      });
    }
  };
  const onClickJangru1 = () => {
    setDiaryData({
      ...diarydata,
      jangruChange: 1,
    });
  };

  const onClickJangru0 = () => {
    setDiaryData({
      ...diarydata,
      jangruChange: 0,
    });
  };
  return (
    <>
      {saving ? <BasicLoading /> : null}
      {transbg ? (
        <div className="fixed top-0 left-0 z-10 w-full h-full bg-transparent"></div>
      ) : (
        ""
      )}

      <div className="relative h-screen overflow-y-auto">
        <div className="fixed top-0 left-0 bg-[#FAFCFF] w-full z-5 subtitle_1_400">
          <div className="w-full flex items-center justify-between pl-[8px] pr-[24px]">
            <img
              className="cursor-pointer inline-block pr-[20.25px]"
              src={leftarrow}
              alt="leftarrow"
              onClick={goBack}
            />
            <span className="inline-block ">건강 다이어리</span>

            {diarydata.weight === oldData.weight &&
            diarydata.water === oldData.water &&
            diarydata.toilet13 === oldData.toilet13 &&
            diarydata.toilet23 === oldData.toilet23 &&
            diarydata.jangruChange === oldData.jangruChange &&
            diarydata.meal === oldData.meal &&
            diarydata.memo === oldData.memo &&
            hasimageData.length < 3 &&
            selectedImages.length < 3 ? (
              <span className="inline-block cursor-poiner subtitle_2 text-white bg-[#9DC4FA] rounded-full px-[14px] py-[4px]">
                저장
              </span>
            ) : (diarydata.weight === "" || diarydata.weight === 0) &&
              (diarydata.water === "" || diarydata.water === 0) &&
              (diarydata.toilet13 === "" || diarydata.toilet13) === 0 &&
              (diarydata.toilet23 === "" || diarydata.toilet23) === 0 &&
              diarydata.jangruChange === 1 &&
              (diarydata.meal === "" || diarydata.meal === null) &&
              (diarydata.memo === null || diarydata.memo === "") &&
              hasimageData.length < 3 &&
              selectedImages.length < 3 ? (
              <span className="inline-block cursor-poiner subtitle_2 text-white bg-[#9DC4FA] rounded-full px-[14px] py-[4px]">
                저장
              </span>
            ) : (
              <span
                className="inline-block cursor-poiner subtitle_2 text-white bg-[#1B5EDC] hover:bg-[#18428E] ease-in-out duration-300 rounded-full px-[14px] py-[4px] cursor-pointer"
                onClick={submitdata}
              >
                저장
              </span>
            )}
          </div>
          <div className="border-b bg-[#FAFCFF] flex justify-center py-[16px] px-[24px] flex justify-between items-center">
            <span className="inline-block subtitle_1_600">
              {range.thisDate}
              {dayjs(range.thisDate).format("d") === "0"
                ? "(일)"
                : dayjs(range.thisDate).format("d") === "1"
                ? "(월)"
                : dayjs(range.thisDate).format("d") === "2"
                ? "(화)"
                : dayjs(range.thisDate).format("d") === "3"
                ? "(수)"
                : dayjs(range.thisDate).format("d") === "4"
                ? "(목)"
                : dayjs(range.thisDate).format("d") === "5"
                ? "(금)"
                : dayjs(range.thisDate).format("d") === "6"
                ? "(토)"
                : ""}
            </span>
            <div className="flex gap-x-[8px]">
              <span
                className="inline-block  w-[32px] h-[32px] flex justify-center items-center border border-black rounded-full cursor-pointer"
                onClick={handleLeft}
              >
                <img src={lefticon} alt="lefticon" className="w-5 h-5" />
              </span>
              <span
                className="cursor-pointer inline-block subtitle_2 border border-black h-[32px] px-[8px] rounded-full flex items-center justify-center "
                onClick={handleToday}
              >
                오늘
              </span>
              {dayjs().format("YYYY-MM-DD") === `${range.thisDate}` ? (
                <span className="inline-block w-[32px] h-[32px] flex justify-center items-center border border-[#999CA3] rounded-full ">
                  <img
                    src={rightunactive}
                    alt="rightunactive"
                    className="w-5 h-5 "
                  />
                </span>
              ) : (
                <span
                  className="inline-block  w-[32px] h-[32px] flex justify-center items-center border border-black rounded-full cursor-pointer"
                  onClick={handleRight}
                >
                  <img src={righticon} alt="righticon" className="w-5 h-5" />
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="bg-[#FAFCFF] py-[16px] px-[24px] mb-[8px] mt-[112px]">
          <h2 className="subtitle_1_600">현재 체중</h2>
          <span className="inline-block text-[#4E535F] caption_1_400 pb-2">
            소수점 첫째자리까지만 입력해주세요.
          </span>
          <div className="w-full py-2 px-4 h-12 border border-[#9DC4FA] bg-[#F2F6FC] flex items-center">
            <input
              type="number"
              inputMode="decimal"
              className="w-full h-full bg-transparent healthdiary focus:outline-none body_1_600"
              name="weight"
              value={diarydata.weight}
              placeholder="예)50.8"
              onChange={onChangeDiaryData}
            />
            <span className="body_1_600">kg</span>
          </div>
        </div>
        <div className="py-[17px] px-[24px] bg-[#FAFCFF] mb-[8px]">
          <div className="flex items-center">
            <span className="pr-[9.25px] subtitle_1_600">수분 섭취</span>
            <span
              className="inline-block font-bold border-2 border-black w-[21.5px] h-[21.5px] flex justify-center items-center rounded-full cursor-pointer"
              onClick={showHydroguide}
            >
              ?
            </span>
          </div>
          <span className="text-[#4E535F] caption_1_400">
            200ml 단위로 섭취량을 기록할 수 있어요.
          </span>
          <div className="flex flex-col items-center pt-[28.27px]">
            <div className="w-[60px] h-[60px] relative overflow-hidden relative">
              <div className="absolute top-0 left-0 w-full h-full cupimage z-1"></div>
              <img
                src={water}
                alt="water"
                className={
                  diarydata.water === "" || diarydata.water === 0
                    ? "absolute bottom-[6px] left-[40%] w-[78%] transform-x-50 waterdown"
                    : "absolute bottom-[6px] left-[40%] w-[78%] transform-x-50 waterup"
                }
              />
            </div>
            <div className="flex w-1/2 justify-between items-center pt-[12.27px]">
              <span
                className="text-4xl font-thin w-[46px] h-[46px] rounded-full inline-block flex justify-center items-center ease-in-out duration-300 cursor-pointer bg-[#E5E6E7] hover:bg-[#9DC4FA]"
                onClick={onClickWaterMinus}
              >
                -
              </span>
              <span className="subtitle_2">
                {diarydata.water !== "" ? diarydata.water : 0}컵
              </span>
              <span
                className="text-4xl font-thin w-[46px] h-[46px] rounded-full inline-block flex justify-center items-center ease-in-out duration-300 cursor-pointer bg-[#E5E6E7] hover:bg-[#9DC4FA]"
                onClick={onClickWaterPlus}
              >
                +
              </span>
            </div>
          </div>
          <div className="mt-[28px] rounded-full bg-[#F1F2F3] p-[2px] flex justify-center ">
            <strong>
              총 {diarydata.water !== "" ? diarydata.water : 0}컵 (
              {diarydata.water !== "" ? diarydata.water * 200 : 0}
              ml)을
            </strong>
            &nbsp;마셨어요!
          </div>
        </div>
        <div className="bg-[#FAFCFF] py-[17px] px-[24px] mb-[8px]">
          <div className="flex items-center">
            <span className="pr-2 subtitle_1_600">배변량</span>
            <span
              className="inline-block font-bold border-2 border-black w-[21.5px] h-[21.5px] flex justify-center items-center rounded-full cursor-pointer"
              onClick={showPoopguide}
            >
              ?
            </span>
          </div>
          <span className="text-[#4E535F] caption_1_400 pt-[5.25px]">
            주머니를 비운 횟수와 배변량을 기록할 수 있어요.
          </span>
          <div className="flex pt-[24px]">
            <div className="poopleft w-1/2 pr-[15px] border-r">
              <div className="flex flex-col items-center">
                <div className="w-[44.85px] h-[64px] relative overflow-hidden">
                  <div className="absolute top-0 left-0 w-full h-full poopimage z-1"></div>
                  <img
                    src={poopinner13}
                    alt="poopinner13"
                    className={
                      diarydata.toilet13 === "" || diarydata.toilet13 === 0
                        ? "absolute bottom-[7px] left-1/2 w-full transform-x-50 poopup13"
                        : "absolute bottom-[7px] left-1/2 w-full transform-x-50 poopdown13"
                    }
                  />

                  <img
                    src={text13}
                    alt={text13}
                    className="absolute bottom-[10px] left-1/2 transform-x-50 z-3"
                  />
                </div>
                <div className="w-full flex justify-between items-center pt-[8.91px]">
                  <span
                    className="text-4xl font-thin w-[46px] h-[46px] rounded-full inline-block flex justify-center items-center ease-in-out duration-300 cursor-pointer bg-[#E5E6E7] hover:bg-[#FAE38E]"
                    onClick={onClickToilet13Minus}
                  >
                    -
                  </span>
                  <span className="subtitle_2">
                    {diarydata.toilet13 !== "" ? diarydata.toilet13 : 0}회
                  </span>
                  <span
                    className="text-4xl font-thin w-[46px] h-[46px] rounded-full inline-block flex justify-center items-center ease-in-out duration-300 cursor-pointer bg-[#E5E6E7] hover:bg-[#FAE38E]"
                    onClick={onClickToilet13Plus}
                  >
                    +
                  </span>
                </div>
              </div>
            </div>
            <div className="poopright w-1/2 pl-[15px]">
              <div className="flex flex-col items-center ">
                <div className="w-[44.85px] h-[64px] relative overflow-hidden">
                  <div className="absolute top-0 left-0 w-full h-full poopimage z-1"></div>
                  <img
                    src={poopinner23}
                    alt="poopinner23"
                    className={
                      diarydata.toilet23 === "" || diarydata.toilet23 === 0
                        ? "absolute bottom-[7px] left-1/2 w-full transform-x-50 poopup23"
                        : "absolute bottom-[7px] left-1/2 w-full transform-x-50 poopdown23"
                    }
                  />

                  <img
                    src={text23}
                    alt={text23}
                    className="absolute bottom-[10px] left-1/2 transform-x-50 z-3"
                  />
                </div>
                <div className="w-full flex justify-between items-center pt-[8.91px]">
                  <span
                    className="text-4xl font-thin w-[48px] h-[48px] rounded-full inline-block flex justify-center items-center ease-in-out duration-300 cursor-pointer bg-[#E5E6E7] hover:bg-[#FAE38E]"
                    onClick={onClickToilet23Minus}
                  >
                    -
                  </span>
                  <span className="subtitle_2">
                    {diarydata.toilet23 !== "" ? diarydata.toilet23 : 0}회
                  </span>
                  <span
                    className="text-4xl font-thin w-[48px] h-[48px] rounded-full inline-block flex justify-center items-center ease-in-out duration-300 cursor-pointer bg-[#E5E6E7] hover:bg-[#FAE38E]"
                    onClick={onClickToilet23Plus}
                  >
                    +
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[24px] rounded-full bg-[#F1F2F3] p-[2px] flex justify-center ">
            <strong>1/3</strong>만큼 채워진 주머니를&nbsp;
            <strong>
              {diarydata.toilet13 !== "" ? diarydata.toilet13 : 0}
            </strong>
            회 &nbsp;비웠어요!
          </div>
          <div className="mt-2 rounded-full bg-[#F1F2F3] p-[2px] flex justify-center ">
            <strong>2/3</strong>만큼 채워진 주머니를&nbsp;
            <strong>
              {diarydata.toilet23 !== "" ? diarydata.toilet23 : 0}
            </strong>
            회 &nbsp;비웠어요!
          </div>
          <div className="pt-[25px] flex items-center justify-end gap-x-2">
            <span>장루 주머니 교체시 체크</span>
            <div>
              {diarydata.jangruChange === 0 ? (
                <img
                  src={checkon}
                  alt={checkon}
                  className="cursor-pointer"
                  onClick={onClickJangru1}
                />
              ) : (
                <img
                  src={checkoff}
                  alt={checkoff}
                  className="cursor-pointer"
                  onClick={onClickJangru0}
                />
              )}
            </div>
          </div>
        </div>
        <div className="px-[24px] py-[17px] bg-[#FAFCFF] mb-[8px]">
          <h2 className="subtitle_1_600 pb-[8px]">식사기록</h2>
          <div className="w-full p-[16px] h-[109px] rounded-sm border border-[#9DC4FA] bg-[#F2F6FC] flex items-center">
            <textarea
              type="text"
              className="w-full h-full bg-transparent focus:outline-none body_1_600"
              placeholder="오늘 하루 설사, 가스등 불편함을 유발한 식품이 있다면 기록해 보세요.
              "
              name="meal"
              value={
                !(diarydata.meal === "" || diarydata.meal === null)
                  ? diarydata.meal
                  : ""
              }
              onChange={onChangeDiaryData}
            />
          </div>
        </div>
        <div className="px-[24px] py-[17px] bg-[#FAFCFF] mb-[8px]">
          <div className=" caption_1_400 p-[8px] flex justify-between">
            <h2 className="subtitle_1_600 ">메모</h2>
            <span className="text-black caption_1_600">
              {hasimageData && hasimageData.length > 0
                ? `${hasimageData.length} / 5`
                : selectedImages && selectedImages.length >= 5
                ? `5 / 5`
                : `${selectedImages.length} / 5`}
            </span>
          </div>
          <DiaryImageShow
            hasimageData={hasimageData}
            setHasImageData={setHasImageData}
            setDiaryData={setDiaryData}
          />
          <div className="w-full mt-[8px] p-[16px] h-[109px] rounded-sm border border-[#9DC4FA] bg-[#F2F6FC] flex items-center">
            <textarea
              type="text"
              className="w-full h-full bg-transparent focus:outline-none body_1_600"
              placeholder="장루 상태나 오늘의 건강 상태를
              기록해보세요.
              "
              name="memo"
              value={
                !(diarydata.memo === "" || diarydata.memo === null)
                  ? diarydata.memo
                  : ""
              }
              onChange={onChangeDiaryData}
            />
          </div>
        </div>
        <div className="h-[70px]"></div>
        {imageSizeBig ? <ImageSizeBig showImageBig={showImageBig} /> : ""}
        {hydroGuide ? (
          <DiaryCenterModal
            title={"수분 섭취량 측정 안내"}
            showHydroguide={showHydroguide}
          />
        ) : (
          ""
        )}
        {poopGuide ? (
          <DiaryCenterModal
            title={"배변량 및 횟수 측정 안내"}
            showPoopguide={showPoopguide}
          />
        ) : (
          ""
        )}
        {poop1000 ? <Poop1000Modal showPoop1000={showPoop1000} /> : ""}
        {poop1900 ? <Poop1900Modal showPoop1900={showPoop1900} /> : ""}
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        bodyClassName={() =>
          "w-[175px] p-3 rounded-full overflow-hidden bg-[#CBDFFC] text-black text-sm font-normal"
        }
      ></ToastContainer>
    </>
  );
};

export default HealthDiary;
