import Header2 from "../../../admin_components/Header/Header2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import commonUrl, { api } from "../../../../../data/common";

import { useLocation } from "react-router-dom";
import ImagePopup from "../components/ImagePopup";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Loading } from "../../../admin_components/Loading";
import { BtnWhiteBack } from "../../../admin_components/Buttons";
import Paging from "../../../admin_components/Paging";
import dayjs from "dayjs";
import moment from "moment";
import { handlingError } from "../../../admin_components/Error";

const GraphAndPicture = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  // window 가로 길이 따른 사진 보여주는 방법 조절
  const [girdColNum, setGirdColNum] = useState(() => {
    if (window.innerWidth > 1440) return 5;
    else if (window.innerWidth > 1280) return 4;
    else if (window.innerWidth > 1170) return 3;
    else if (window.innerWidth > 1100) return 2;
    else return 4;
  });
  /******************* 시간 조회 버튼 관련 state *******************/
  const [above15Day, setAbove15Day] = useState(false);
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });
  // {start: 시작일 캘린더 열기, end: 종료일 캘린더 열기}
  const [calenderOpen, setCalenderOpen] = useState({
    start: false,
    end: false,
  });

  const [photoList, setPhotoList] = useState([]);
  const [dayList, setDayList] = useState([]);
  const [waterList, setWaterList] = useState([]);
  const [weightList, setWeightList] = useState([]);
  const [toiletList, setToiletList] = useState([]);
  const [showData, setShowData] = useState([]); // 표기할 정보 담기
  const entireDataRef = useRef([]); // 전체 그래프 정보 담기
  const entirePhotoRef = useRef([]); // 전체 사진 정보 담기
  const [flexCol, setFlexCol] = useState(() => {
    if (window.innerWidth <= 1000) return true;
    else return false;
  }); // viewport가 너비 1280 이하일 때 flex-row -> flex-col

  /******************* Paging 관련 state *******************/
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  // 한페이지당 보여줄 사진 갯수
  const [itemsCount, setItemCount] = useState(() => {
    if (window.innerWidth > 1440) {
      return 25;
    } else if (window.innerWidth > 1280) {
      return 20;
    } else if (window.innerWidth > 1170) {
      return 15;
    } else if (window.innerWidth > 1000) return 10;
    else return 20;
  });
  const pagingRangeNum = useRef(1); // paging 범위

  // resize시 flexCol 변경 & 사진 보여주는 gird열 갯수/한 페이지당 보여줄 사진 갯수 설정
  const handleResize = () => {
    let itemsCountNum = itemsCount; // grid 열 갯수

    if (window.innerWidth > 1440) {
      setGirdColNum(5);
      itemsCountNum = 25;
    } else if (window.innerWidth > 1280) {
      setGirdColNum(4);
      itemsCountNum = 20;
    } else if (window.innerWidth > 1170) {
      setGirdColNum(3);
      itemsCountNum = 15;
    } else if (window.innerWidth > 1000) {
      setGirdColNum(2);
      itemsCountNum = 10;
    } else {
      setGirdColNum(4);
      itemsCountNum = 20;
    }
    setItemCount(itemsCountNum);

    if (window.innerWidth <= 1000) setFlexCol(true);
    else setFlexCol(false);
  };

  // popup open 여부
  const [open, setOpen] = useState({
    open: false,
    url: "imageUrl",
    date: "",
  });

  // popup 닫을 시 실행하는 함수
  const closePopup = () => setOpen({ ...open, open: false });

  /*************************** Chart UI 관련 변수 & 함수들  *************************/
  const weightData = {
    labels: dayList,
    datasets: [
      {
        data: weightList.length !== 0 ? weightList : [],
        backgroundColor: ["#5FC17B"],
        borderWidth: above15Day ? 2 : 1,
        borderColor: ["#5FC17B"],
        pointBorderWidth: dayList.length > 40 ? 0 : 0.5,
        pointRadius: dayList.length > 40 ? 0 : 2,
        hoverOffset: 4,
        barPercentage: 0.8,
        categoryPercentage: 1,
        borderRadius: 2,
      },
    ],
  };

  const waterData = {
    labels: dayList,
    datasets: [
      {
        data: waterList.length !== 0 ? waterList : [],
        backgroundColor: ["#1B5EDC"],
        borderWidth: above15Day ? 2 : 1,
        borderColor: ["#1B5EDC"],
        pointBorderWidth: dayList.length > 40 ? 0 : 0.5,
        pointRadius: dayList.length > 40 ? 0 : 2,
        hoverOffset: 4,
        barPercentage: 0.8,
        categoryPercentage: 1,
        borderRadius: 2,
      },
    ],
  };

  const toiletData = {
    labels: dayList,
    datasets: [
      {
        data: toiletList.length !== 0 ? toiletList : [],
        backgroundColor: ["#F7CE4B"],
        borderWidth: above15Day ? 2 : 1,
        borderColor: ["#F7CE4B"],
        pointBorderWidth: dayList.length > 40 ? 0 : 0.5,
        pointRadius: dayList.length > 40 ? 0 : 2,
        hoverOffset: 4,
        barPercentage: 0.8,
        categoryPercentage: 1,
        borderRadius: 2,
      },
    ],
  };

  // 공통 plugins
  const commonPlugins = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: function (context) {
          if (context.dataset.data[context.dataIndex] === 0)
            return context.dataset.backgroundColor[0];
          else return "#FAFBFC";
        },
        anchor: function (context) {
          if (context.dataset.data[context.dataIndex] === 0) return "end";
          else return "end";
        },
        align: function (context) {
          if (context.dataset.data[context.dataIndex] === 0) return "end";
          else return "bottom";
        },
        font: function (context) {
          if (context.dataset.data[context.dataIndex] === 0) {
            return { lineHeight: 1, size: 11.5 };
          } else return { lineHeight: 2, size: 11.5 };
        },
      },
    },
  };

  const toiletPlugins = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: function (context) {
          if (context.dataset.data[context.dataIndex] === 0)
            return context.dataset.backgroundColor[0];
          else return "#FAFBFC";
        },
        anchor: function (context) {
          if (context.dataset.data[context.dataIndex] === 0) return "end";
          else return "end";
        },
        align: function (context) {
          if (context.dataset.data[context.dataIndex] === 0) return "end";
          else return "bottom";
        },
        font: function (context) {
          if (context.dataset.data[context.dataIndex] === 0) {
            return { lineHeight: 1 };
          } else return { lineHeight: 2 };
        },
      },
      tooltip: {
        callbacks: {
          afterLabel: function (tooltipItem) {
            if (checkOXRef.current[tooltipItem.dataIndex]) return "장루 교체일";
            else return null;
          },
        },
        bodyFontSize: 14,
      },
    },
  };

  //  체중 옵션
  const weightOptions = Object.assign(
    {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 2.65,
      indexAxis: "x",
      minBarLength: above15Day ? undefined : 2,

      scales: {
        x: {
          display: true,
          grid: {
            color: "transparent",
          },
          ticks: {
            padding: 5,
            font: {
              size: 12,
            },
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            color: "#E5E6E7",
            borderColor: "transparent",
            tickColor: "transparent",
          },
          ticks: {
            display: false,
            beginAtZero: true,
            stepSize: 10,
          },
          min: 0,
        },
      },
      // index만 hover 되도 tooltip 뜨도록
      interaction: {
        intersect: false,
        mode: "index",
      },
    },
    commonPlugins
  );

  //  수분섭취량  옵션
  const waterOptions = Object.assign(
    {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 2.65,
      indexAxis: "x",
      minBarLength: above15Day ? undefined : 2,
      scales: {
        x: {
          display: true,
          grid: {
            color: "transparent",
          },
          ticks: {
            padding: 5,
            font: {
              size: 12,
            },
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            color: "#E5E6E7",
            borderColor: "transparent",
            tickColor: "transparent",
          },
          ticks: {
            display: false,
            beginAtZero: true,
            stepSize: 200,
          },
        },
      },
      // index만 hover 되도 tooltip 뜨도록
      interaction: {
        intersect: false,
        mode: "index",
      },
    },
    commonPlugins
  );

  // 배변량 options
  const toiletOptions = Object.assign(
    {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: above15Day ? 2.1 : 2.65,
      indexAxis: "x",
      minBarLength: above15Day ? undefined : 2,
      scales: {
        x: {
          display: true,
          grid: {
            color: "transparent",
          },
          ticks: {
            showLabelBackdrop: function (context) {
              if (checkOXRef.current[context.index] && !above15Day) return true;
              else return false;
            },
            backdropColor: "#FAE38E",
            backdropPadding: 1,
            padding: 10,
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            color: "#E5E6E7",
            borderColor: "transparent",
            tickColor: "transparent",
          },
          ticks: {
            display: false,
            beginAtZero: true,
          },
        },
      },
      // index만 hover 되도 tooltip 뜨도록
      interaction: {
        intersect: false,
        mode: "index",
      },
    },
    toiletPlugins
  );
  // 보여줘야하는 그래프 데이타 다듬는 함수
  const filtering = () => {
    let days = showData.map((el, idx) => {
      return (
        Number(el.date.substring(5, 7)) + "/" + Number(el.date.substring(8, 10))
      );
    });
    setDayList(days);

    // 체중 계산(정보 없을시 이전에 기록된 내용으로 보여줌)
    let prevWeight = 0;
    let weight = showData.map((el, idx) => {
      // console.log(showData, "showData");
      if (el.weight === undefined) {
        if (idx === 0) {
          prevWeight = el.weight;
          return undefined;
        } else {
          return prevWeight;
        }
      }
      prevWeight = el.weight;
      return el.weight;
    });
    setWeightList(weight);

    // 수분 섭취량 기록
    let water = showData.map((el, idx) => {
      return el.water;
    });
    setWaterList(water);

    // 배변량 기록
    let toilet = showData.map((el, idx) => {
      return el.toilet;
    });
    setToiletList(toilet);
  };

  let checkOXRef = useRef([]);

  // 사진 출력하기 편한 방식으로 data 재구성
  const handlePhotoData = (data) => {
    let newImageList = [];
    let newChangeOXList = [];
    data.forEach((el, idx) => {
      let tempImageList = [];
      let tempFromList = [];

      let date = el.date;
      let newChangeOX = el.changeOX ? true : false; // 장루 교체일
      newChangeOXList.push(newChangeOX);

      if (el.diaryPhotoURL) {
        tempImageList = tempImageList.concat(el.diaryPhotoURL);
        tempFromList = tempFromList.concat(
          Array(el.diaryPhotoURL.length).fill("다이어리 기록")
        );
      }
      if (el.jangruSurveyPhotoURL) {
        tempImageList = tempImageList.concat(el.jangruSurveyPhotoURL);
        tempFromList = tempFromList.concat(
          Array(el.jangruSurveyPhotoURL.length).fill("설문 기록")
        );
      }

      newImageList = newImageList.concat(
        tempImageList.map((el, idx) => {
          return {
            date: date,
            url: el,
            from: tempFromList[idx],
          };
        })
      );
    });
    checkOXRef.current = newChangeOXList;
    return newImageList;
  };

  // 창 가로 너비에 따라 한페이지당 보여줄 itemsCount이 변했을 때 paging 가능한 범위도 수정
  // ex) 3페이지에 있다가 가로 너비 늘어나서 paging 범위가 1~2로 변했을 경우 처리
  useEffect(() => {
    // 페이지 범위 계산
    let quotient = Math.floor(photoList.length / itemsCount);
    let remainder = photoList.length % itemsCount;
    if (remainder === 0) pagingRangeNum.current = quotient;
    else pagingRangeNum.current = quotient + 1;

    // 현재 페이지가 paging범위 보다 클 때
    if (pagingRangeNum.current !== 0 && pagingRangeNum.current < currentPage) {
      setCurrentPage(pagingRangeNum.current);
    }
  }, [currentPage, itemsCount, photoList.length]);

  // 시간조회 선택 뒤
  useEffect(() => {
    if (date.startDate !== "" && date.endDate !== "") {
      // date 형식 바꾼 startDate
      const newSDate = dayjs(new Date(date.startDate)).format("YYYY-MM-DD");
      // date 형식 바꾼 endDate
      const newEDate = dayjs(new Date(date.endDate)).format("YYYY-MM-DD");

      // 15일 안넘을 경우 & 넘을 경우
      let start = moment(newSDate);
      let end = moment(newEDate);
      if (end.diff(start, "day") <= 15) setAbove15Day(false);
      else setAbove15Day(true);

      // 날짜 필터링
      const newData = entireDataRef.current.filter(
        (el) => newSDate <= el.date && newEDate >= el.date
      );
      const photoFiltering = entirePhotoRef.current.filter(
        (el) => newSDate <= el.date && newEDate >= el.date
      );
      const newPhoto = handlePhotoData(photoFiltering);

      setShowData(newData);
      setPhotoList(newPhoto);
      setCurrentPage(1);

      let quotient = Math.floor(photoList.length / itemsCount);
      let remainder = photoList.length % itemsCount;
      if (remainder === 0) pagingRangeNum.current = quotient;
      else pagingRangeNum.current = quotient + 1;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  // data바뀐 경우
  useEffect(() => {
    filtering();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showData]);

  /*** 첫 랜더시 정보 불러오기***/
  useEffect(() => {
    // 비동기 함수
    async function fetchData() {
      setLoading(true);
      // 그래프
      const res = await api({
        method: "post",
        url: commonUrl.diary + "wholeGraph/" + state.id,
      }).catch((error) => {
        handlingError(error);
      });
      // console.log(res.data, ">>> Axios -> diary/wholeGraph + id");

      for (let i = 1; res.data.graphList.length > i; i++) {
        res.data.graphList[i] = !(
          res.data.graphList[i].weight ||
          res.data.graphList[i].water ||
          res.data.graphList[i].toilet
        )
          ? {
              ...res.data.graphList[i - 1],
              date: res.data.graphList[i].date,
              weight: res.data.graphList[i - 1].weight,
              water: null,
              toilet: null,
            }
          : !res.data.graphList[i].weight
          ? (res.data.graphList[i] = {
              ...res.data.graphList[i],
              weight: res.data.graphList[i - 1].weight,
            })
          : res.data.graphList[i];
      }

      if (res.data.ok) {
        setLoading(false);
        entireDataRef.current = res.data.graphList;
        let newData = [];

        // 처음에는 15일치만 보여주기
        if (entireDataRef.current.length >= 10) {
          newData = res.data.graphList.slice(
            res.data.graphList.length - 10,
            res.data.graphList.length
          );
          setShowData(newData);
        } else setShowData(entireDataRef.current);
      }
      // 사진
      const res2 = await api({
        method: "get",
        url: commonUrl.diary + "wholePhoto/" + state.id,
      }).catch((error) => {
        handlingError(error);
      });
      console.log(res2.data, ">>> Axios -> diary/wholePhoto + id");
      if (res2.data.ok) {
        let newData = [];
        entirePhotoRef.current = res2.data.photoURLList;
        // 처음에는 10일치만 보여주기
        let sliceList;
        if (entirePhotoRef.current.length >= 10) {
          sliceList = entirePhotoRef.current.slice(
            entirePhotoRef.current.length - 10,
            entirePhotoRef.current.length
          );
        } else sliceList = entirePhotoRef.current;

        newData = handlePhotoData(sliceList);
        setPhotoList(newData);
      }
      setLoading(false);
    }
    fetchData();

    // resize detect
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <style jsx="true">{`
        .react-datepicker-wrapper {
          width: 118px !important;
        }
      `}</style>
      <Header2
        title={[
          { title: "장루점검", url: "/admin/main/check" },
          {
            title: "대상자 상세보기",
            url: -1,
          },
          { title: "그래프 & 사진 모아보기", url: 0 },
        ]}
      >
        {/* 이미지 팝업 */}
        {open.open ? (
          <ImagePopup
            url={open.url}
            date={open.date}
            from={open.from}
            closePopup={closePopup}
            open={open.open}
          />
        ) : null}

        <div className="admin w-full m-[32px]">
          <div className="h-full">
            <div className="h-[80px] min-h-[80px]"></div>
            <BtnWhiteBack to={-1} />
            <div
              className={
                flexCol
                  ? "flex flex-col justify-center w-full mb-3 gap-y-2 mt-3"
                  : "flex justify-start items-center h-[38px] w-full mt-3 mb-3"
              }
            >
              <label className="text-2xl font-extrabold leading-8 text-[#020A1B] mr-[70px]">
                {state.name ? state.name : "_____"}님 그래프&사진 모아보기
              </label>
              {/* 날짜 기준으로 필터링 */}
              <div className="flex h-[38px] items-center ">
                <label className="min-w-[67px] text-lg leading-[22px] font-semibold text-[#343B48] mr-4">
                  기간 조회
                </label>
                {/* 시작일 */}
                <DatePicker
                  onInputClick={() => {
                    // 시작일 datepicker 열기
                    setCalenderOpen({ start: true, end: false });
                  }}
                  onClickOutside={() => {
                    setCalenderOpen({ start: false, end: false });
                  }}
                  open={calenderOpen.start}
                  className="flex h-[38px] w-[118px] justify-center items-center border border-[#CCCDD1] rounded-[48px] box-border text-center placeholder:text-base placeholder:leading-[22px] placeholder:font-semibold placeholder:text-[#4E535F] placeholder:text-center focus:outline-none"
                  selected={date.startDate}
                  minDate={
                    entireDataRef.current[0]
                      ? new Date(
                          Number(entireDataRef.current[0].date.substring(0, 4)),
                          Number(
                            entireDataRef.current[0].date.substring(5, 7)
                          ) - 1,
                          Number(entireDataRef.current[0].date.substring(8, 10))
                        )
                      : null
                  }
                  maxDate={new Date()}
                  onChange={(value) => {
                    // 종료일 datepicker 열기
                    setCalenderOpen({ start: false, end: true });
                    // 시작일이 종료일 클 경우
                    if (date.endDate !== "" && value > date.endDate)
                      setDate({ ...date, endDate: "" });
                    // 종료일 선택하고 시작일 바꿀 경우
                    else setDate({ ...date, startDate: value });
                  }}
                  value={date.startDate}
                  placeholderText="시작일"
                />
                <div className="text-base leading-[22px] font-semibold text-[#343B48] mx-4">
                  ~
                </div>
                {/* 종료일 */}
                <DatePicker
                  onInputClick={() => {
                    // 종료일 datepicker 열기
                    setCalenderOpen({ start: false, end: true });
                  }}
                  onClickOutside={() => {
                    setCalenderOpen({ start: false, end: false });
                  }}
                  open={calenderOpen.end}
                  className="flex h-[38px] w-[118px] justify-center items-center border border-[#CCCDD1] rounded-[48px] box-border text-center placeholder:text-base placeholder:leading-[22px] placeholder:font-semibold placeholder:text-[#4E535F] placeholder:text-center focus:outline-none"
                  selected={date.endDate}
                  minDate={date.startDate}
                  maxDate={new Date()}
                  disabled={date.startDate === "" ? true : false}
                  onChange={(value) => {
                    setDate({ ...date, endDate: value });
                    // 날짜 선택되었으면 DatePicker 자동 닫기
                    setCalenderOpen({ start: false, end: false });
                  }}
                  value={date.endDate}
                  placeholderText="종료일"
                />
              </div>
            </div>
            {/* 그래프와 사진 */}
            {loading ? (
              <Loading />
            ) : (
              <div
                className={
                  flexCol
                    ? "flex flex-col gap-y-4  w-[180px] max-w-[480px]"
                    : "flex flex-row gap-x-6"
                }
              >
                {/* 그래프들 */}
                <div className="flex grid grid-rows-[minmax(246px,246px)_minmax(246px,246px)_minmax(270px,270px)] grid-cols-[minmax(480px,540px)] gap-y-4">
                  {/* 그래프 */}
                  <div className="flex flex-col p-4 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)]">
                    <div className="flex justify-between mb-2">
                      <p className="text-xl leading-[26px] font-extrabold text-[#343B48] ">
                        체중
                      </p>
                      <p className="font-semibold cursor-pointer text-[14px] leading-[18px] text-[#999CA3]">
                        단위 kg
                      </p>
                    </div>
                    {above15Day ? (
                      <Line
                        options={weightOptions}
                        data={weightData}
                        className="bg-[#F1F2F3] h-[180px] min-h-[180px] border border-t-[#CCCDD1]"
                      />
                    ) : (
                      <Bar
                        options={weightOptions}
                        data={weightData}
                        plugins={[ChartDataLabels]}
                        className="bg-[#F1F2F3] h-[180px] min-h-[180px] border border-t-[#CCCDD1]"
                      />
                    )}
                  </div>
                  <div className="flex flex-col  p-4 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)]">
                    <div className="flex justify-between mb-2">
                      <p className="text-xl leading-[26px] font-extrabold text-[#343B48] ">
                        수분 섭취량
                      </p>
                      <p className="font-semibold cursor-pointer text-[14px] leading-[18px] text-[#999CA3]">
                        단위 ml
                      </p>
                    </div>
                    {above15Day ? (
                      <Line
                        options={waterOptions}
                        data={waterData}
                        className="bg-[#F1F2F3] h-[180px] min-h-[180px] border border-t-[#CCCDD1]"
                      />
                    ) : (
                      <Bar
                        options={waterOptions}
                        data={waterData}
                        plugins={[ChartDataLabels]}
                        className="bg-[#F1F2F3] h-[180px] min-h-[180px] border border-t-[#CCCDD1]"
                      />
                    )}
                  </div>
                  <div className="flex flex-col  p-4 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] ">
                    <div className="flex justify-between mb-2">
                      <p className="text-xl leading-[26px] font-extrabold text-[#343B48] ">
                        배변량
                      </p>
                      <p className="font-semibold cursor-pointer text-[14px] leading-[18px] text-[#999CA3]">
                        단위 ml
                      </p>
                    </div>
                    {above15Day ? (
                      <Line
                        options={toiletOptions}
                        data={toiletData}
                        className="bg-[#F1F2F3] h-[180px] min-h-[180px] border border-t-[#CCCDD1]"
                      />
                    ) : (
                      <Bar
                        options={toiletOptions}
                        data={toiletData}
                        plugins={[ChartDataLabels]}
                        className="bg-[#F1F2F3] h-[180px] min-h-[180px] border border-t-[#CCCDD1]"
                      />
                    )}
                    {above15Day ? null : (
                      <div className="flex mt-2 text-[#343B48] font-normal text-sm leading-4 mb-1">
                        <div className="ml-[4px] h-[16px] w-[30px] bg-[#FAE38E]"></div>
                        <p className="ml-[4px] ">장루주머니 교체일</p>
                      </div>
                    )}
                  </div>
                </div>

                {/* 사진들 */}
                {photoList.length === 0 ? (
                  <div
                    className="flex flex-col p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] "
                    style={{
                      maxWidth: flexCol ? "540px" : "600px",
                      minWidth: flexCol ? "480px" : "250px",
                      height: flexCol ? "250px" : "794px",
                    }}
                  >
                    <p className="text-xl leading-[26px] font-extrabold text-[#343B48] ">
                      사진
                    </p>
                    <label className=" text-base font-semibold leading-[20px] text-[#A0A3AB] mt-4">
                      등록된 사진이 없습니다.
                    </label>
                  </div>
                ) : (
                  <div
                    className="flex flex-col w-full h-[794px] p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] overflow-auto"
                    style={{
                      maxWidth: flexCol ? "540px" : "600px",
                      minWidth: flexCol ? "480px" : "250px",
                    }}
                  >
                    <div className="flex flex-col">
                      <p className="text-xl leading-[26px] font-extrabold text-[#343B48] ">
                        사진
                      </p>
                      {/* <div className="flex flex-wrap mt-[10px] max-w-[750px] gap-x-3 gap-y-3 overflow-auto"> */}
                      <div
                        className="grid grid-rows-5 mt-[10px] max-w-[750px] gap-x-3 gap-y-3"
                        style={{
                          gridTemplateColumns: `repeat(${girdColNum}, minmax(96px, 96px))`,
                        }}
                      >
                        {photoList
                          .slice(
                            (currentPage - 1) * itemsCount,
                            (currentPage - 1) * itemsCount + itemsCount
                          )
                          .map((el, idx) => {
                            const { date, url, from } = el;
                            return (
                              <div
                                className="flex flex-col items-center h-[118px] w-[96px] p-1 bg-[#F1F2F3] border border-[#E5E6E7] rounded-[8px] cursor-pointer"
                                key={idx}
                                onClick={() => {
                                  setOpen({
                                    open: true,
                                    url: url,
                                    date: date,
                                    from: from,
                                  });
                                }}
                              >
                                <img
                                  className="cursor-pointer border border-transparent rounded-[4px] w-[88px] h-[88px] object-contain"
                                  src={
                                    commonUrl.networkImg +
                                    encodeURIComponent(url)
                                  }
                                  alt={`${idx}.jpg`}
                                />
                                <label className="cursor-pointer text-xs text-center font-normal leading-4 text-[#020A1B] mt-1">
                                  {date}
                                </label>
                              </div>
                            );
                          })}
                      </div>
                      <div className="flex justify-center items-end h-[70px] w-full ">
                        <Paging
                          page={currentPage}
                          itemsCount={itemsCount}
                          totalCount={photoList.length}
                          setPage={setCurrentPage}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Header2>
    </>
  );
};

export default GraphAndPicture;
