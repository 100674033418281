import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../../../contexts/Provider";
import CenterModal from "../../../mobilecomponent/CenterModal";

import common, { api } from "../../../../../data/common";
const NoReplyModal = ({ showNOReplyModal }) => {
  const closeicon = "/images/mo/closebasic.svg";
  const flatboy = "/images/mo/flatboy.svg";
  const jangruboyhi = "/images/mo/jangruboyhi.svg";
  const { loginInput } = useMainContext();
  const [user, setUser] = useState([]);

  useEffect(() => {
    let pageOpen = false;

    if (!pageOpen) {
      api
        .get(common.baseURL + "patient/Id/" + loginInput.id)
        .then((res) => {
          if (res.data.ok === true) {
            setUser(res.data.patient);
          }
        })
        .catch((error) => console.log(error));
    }
    return () => {
      pageOpen = true;
    };
  }, [loginInput.id]);

  return (
    <CenterModal showNOReplyModal={showNOReplyModal}>
      <img
        src={closeicon}
        alt="closeicon"
        className="absolute top-0 right-0 cursor-pointer"
        onClick={showNOReplyModal}
      />

      <div className="pt-[16px] flex items-end justify-center gap-x-2">
        <img src={flatboy} alt="flatboy" />
        <img src={jangruboyhi} alt="jangruboyhi" />
      </div>
      <div className="pt-[16px] text-center">
        {user.name}님의 장루 점검 기록을
      </div>
      <div className="text-center">장루 간호사님이 검토하고 있습니다.</div>
      <div className="text-center">
        검토가 완료되는 대로 안내해 드리겠습니다.👏
      </div>
    </CenterModal>
  );
};

export default NoReplyModal;
