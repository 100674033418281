import React from "react";

const FoodManage3AfterFood = ({ bigTitle, btn3, btn3_3 }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400 wordbreak-keepall">
      <div className="py-[24px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">
          {bigTitle} &gt; {btn3}
        </span>
        <h1 className="h2_800 pt-[4px]">{btn3_3}</h1>
      </div>
      <div className="w-full pt-[24px] body_1_400_jangrucont">
        <div className="pb-[30px]">
          <span className="inline-block pb-[8px]">
            1. 장루복원 후 배변 불편감 발생시 식사요법
          </span>
          <span className="inline-block">- 꼭꼭 씹어 먹습니다.</span>
          <div className="flex ">
            <span className="inline-block">-&nbsp;</span>
            <div>
              설사, 잦은 배변 시 섬유소 섭취량을 줄여봅니다. 대변횟수가 감소하는
              등 배변 개선이 있으면 섬유소 섭취량을 점차적으로 늘려나갑니다.
            </div>
          </div>
          <div className="flex">
            <span className="inline-block">-&nbsp;</span>
            <div>
              단, 수용성 섬유질이 많은 음식(오트밀, 쌀겨, 사과, 바나나등)을
              섭취해 봅니다. 대변형태 형성에 도움이 되며 배변규칙성과 배설시간을
              지연 하는데 도움이 될 수 있습니다. 차전자피 등의 섬유소 섭취도
              도움이 될 수 있습니다.
            </div>
          </div>
          <div className="flex">
            <span className="inline-block">-&nbsp;</span>
            <div>
              유제품에는 유당이 포함되어 있어 일부에서는 설사를 악화시킬 수
              있습니다. 유당분해우유를 섭취해 봅니다.
            </div>
          </div>
          <div className="flex">
            <span className="inline-block">-&nbsp;</span>
            <div>
              <span className="inline-block ">
                설사, 가스로 인한 불편감이 큰 경우{" "}
                <strong className="text-[#245BBF]">아래의 표</strong>를
                참고합니다.
              </span>
            </div>
          </div>
          <div className="flex">
            <span className="inline-block">-&nbsp;</span>
            <div>
              <span className="inline-block ">
                탈수 예방을 위해 충분한 수분을 섭취합니다.
              </span>
              <span className="inline-block ">
                식사와 식사 사이, 식간에 섭취하는 것이 좋습니다.
              </span>
            </div>
          </div>
          <div className="flex">
            <span className="inline-block">-</span>
            <div>
              <span className="inline-block ">
                &nbsp;카페인 및 알코올 섭취를 피합니다.
              </span>
            </div>
          </div>
          <div className="flex">
            <span className="inline-block">-</span>
            <div>
              <span className="inline-block ">
                &nbsp;로페라마이드 등의 지사제 복용이 도움이 될 수 있습니다.
              </span>
              <span className="inline-block ">
                &nbsp; 담당의사와 상의합니다.
              </span>
            </div>
          </div>
        </div>
        <div>
          <span className="inline-block pb-[8px]">
            2. 장루 막힘 예방을 위한 식사요령
          </span>
          <div className="flex  ">
            <span className="inline-block">-</span>
            <div>
              <span className="inline-block">
                &nbsp;식사는 천천히 꼭꼭 씹어 식사합니다.
              </span>
            </div>
          </div>
          <div className="flex ">
            <span className="inline-block">-&nbsp;</span>
            <div>
              <span className="inline-block ">
                수술 후 첫 6-8주 이후에는 수분을 흡수하는 귀리, 감귤류,
              </span>
              <span className="inline-block ">
                사과, 콩 등 고섬유소 식품은 주의하는 것이 좋습니다.
              </span>
            </div>
          </div>
          <div className="flex">
            <span className="inline-block">-&nbsp;</span>
            <div>
              과일과 채소의 두꺼운 껍질은 제거하고 단단하거나 꼭꼭 씹어
              먹습니다.
            </div>
          </div>
          <div className="border mt-[32px]">
            <div className="bg-[#F2F6FC] border-b p-[8px] text-center">
              장 막힘 유발 가능 식품
            </div>
            <div className="p-[16px]">
              <span className="inline-block ">
                과일껍질 및 껍질/씨째 먹는 과일 오렌지, 파인애플, 생 양배추,
                샐러리, 버섯 잡곡, 옥수수, 팝콘, 코코넛 견과류, 큰 씨앗, 말린
                과일
              </span>
            </div>
          </div>
          <div className="flex pt-[20px]">
            <span className="inline-block">-&nbsp;</span>
            <div>
              만약 장루 배설이 없고 구토를 한다면 아무것도 드시거나 마시지 말고
              즉시 응급실을 방문합니다.
            </div>
          </div>
        </div>
      </div>
      <div className="h-[70px]"></div>
    </div>
  );
};

export default FoodManage3AfterFood;
