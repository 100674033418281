import React, { useState } from "react";
import common, { api } from "../../../../../data/common";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
/*component*/
import CenterModal from "../../../mobilecomponent/CenterModal";

const ReportEditDeleteModal = ({ showBTNDeleteModal, diarydataid }) => {
  const navigate = useNavigate();
  const [transbg, setTransBg] = useState(false);
  const deletOK = () => {
    setTransBg(true);
    api
      .post(common.baseURL + "diary/delete/" + diarydataid)
      .then((res) => {
        if (res.data.ok === true) {
          showBTNDeleteModal();
          toast(`삭제되었습니다.`, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: "transparent toastbottom",
          });
          let start = setTimeout(() => {
            navigate("/report");
            setTransBg(false);
          }, 2000);
          return () => {
            clearTimeout(start);
          };
        } else {
          showBTNDeleteModal();
          toast(`${res.data.error}`, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: "transparent toastbottom",
          });
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <>
      {transbg ? (
        <div className="fixed top-0 left-0 z-20 w-full h-full bg-transparent"></div>
      ) : (
        ""
      )}

      <CenterModal>
        <span className="block text-center">
          해당 날짜에 저장된 기록이 있습니다.
        </span>
        <span className="block text-center">삭제 하시겠습니까?</span>
        <div className="pt-[24px] flex gap-x-[8px] font-semibold w-full">
          <span
            className="p-[16px] w-1/2 border text-[#1B5EDC] border-[#1B5EDC] hover:bg-[#F2F6FC] ease-in-out duration-300 rounded-sm inline-block flex justify-center items-center cursor-pointer"
            onClick={deletOK}
          >
            삭제
          </span>
          <span
            className="p-[16px] w-1/2 border text-[#1B5EDC] border-[#1B5EDC] hover:bg-[#F2F6FC] ease-in-out duration-300 rounded-sm inline-block flex justify-center items-center cursor-pointer"
            onClick={showBTNDeleteModal}
          >
            취소
          </span>
        </div>
      </CenterModal>
    </>
  );
};

export default ReportEditDeleteModal;
