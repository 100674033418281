import { useMainContext } from "../../../../contexts/Provider";
import Popup from "reactjs-popup";
import { convertRole } from "../ConvertAdmin";
import commonUrl, { api } from "../../../../data/common";
import { useNavigate } from "react-router-dom";
import { convertBelongToName } from "../ConvertAdmin";

const HeaderMyInfoPopup = ({ open, onClose, profileUrl }) => {
  const cancel_black = "/images/admin/header_dash/cancel_black.svg";
  const header_logout = "/images/admin/header_dash/header_logout.svg";
  const header_myinfo = "/images/admin/header_dash/header_myinfo.svg";
  const right_arrow = "/images/admin/header_dash/right_arrow.svg";
  const profile = "/images/admin/profile.svg";

  const navigate = useNavigate();
  const { adminLogin, setAdminLogin, belongs, setBelongs, setAdminHistory } =
    useMainContext();

  return (
    <>
      <style jsx="true">{`
        .popup-content.myinfo_popup-content {
          font-family: -apple-system, BlinkMacSystemFont, "Apple SD Gothic Neo",
            Pretendard, Roboto, "Noto Sans KR", "Segoe UI", "Malgun Gothic",
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif !important;
          position: absolute !important;
          right: 25px !important;
          top: 72px;
          width: 320px;
          box-sizing: border-box;
          max-width: 400px;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          background: #fafbfc;
          padding: 0px;
          border: none;
        }
        .header_myinfo_btn:hover .header_myinfo,
        .header_myinfo_btn:hover .right_arrow_myinfo {
          filter: brightness(0) saturate(100%) invert(82%) sepia(93%)
            saturate(6628%) hue-rotate(180deg) brightness(111%) contrast(108%);
        }
        .header_logout_btn:hover .header_logout,
        .header_logout_btn:hover .right_arrow_logout {
          filter: brightness(0) saturate(100%) invert(82%) sepia(93%)
            saturate(6628%) hue-rotate(180deg) brightness(111%) contrast(108%);
        }
      `}</style>
      <Popup
        className="myinfo_popup"
        open={open}
        closeOnDocumentClick
        onClose={onClose}
      >
        <div className="flex flex-col w-full rounded-[8px]">
          <div className="flex justify-between p-4">
            <p className="text-sm font-normal text-[#020A1B] leading-[18px]">
              현재 로그인 계정
            </p>
            <img
              src={cancel_black}
              alt="cancel_black"
              className="h-[16px] w-[16px] cursor-pointer"
              onClick={() => {
                onClose();
              }}
            />
          </div>
          <div className="relative flex items-center gap-x-4 p-4 rounded-b-[8px] shadow-[0_2px_2px_rgba(0,0,0,0.12)] bg-[#FAFBFC] z-10">
            {adminLogin.profileURL ? (
              <img
                src={
                  commonUrl.networkImg +
                  encodeURIComponent(adminLogin.profileURL)
                }
                alt="profileUrl"
                className="h-[48px] w-[48px] rounded-[23px] object-cover"
              />
            ) : (
              <img
                className="h-[48px] w-[48px] rounded-[23px] object-contain"
                src={profile}
                alt="profile"
              />
            )}
            <div>
              <label className="font-semibold text-base leading-5">
                {`${adminLogin.name} ${convertRole(adminLogin.role)}`}
              </label>
              <p className="font-normal text-sm leading-[18px]">
                {convertBelongToName(belongs, adminLogin.belong)}
              </p>
            </div>
          </div>

          {/* 내정보 관리 & 로그아웃 */}
          <div className="flex flex-col bg-[#F1F2F3] pt-2 rounded-b-[10px]">
            <button
              className="header_myinfo_btn flex justify-between items-center w-full p-4 bg-[#F1F2F3] text-[#020A1B] filter-none hover:bg-[#3F82ED] hover:text-[#FAFCFF] focus:outline-none"
              onClick={() => {
                navigate("/admin/main/myinfo");
              }}
            >
              <div className="flex items-center gap-x-2 font-semibold text-base leading-[22px] ">
                <img
                  src={header_myinfo}
                  alt="header_myinfo"
                  className="header_myinfo h-[18px] w-[18px] "
                />
                내 정보 관리
              </div>
              <img
                src={right_arrow}
                alt="right_arrow_myinfo"
                className="right_arrow_myinfo h-[18px] w-[18px]"
              />
            </button>

            <button
              className="header_logout_btn flex justify-between items-center w-full p-4 bg-[#F1F2F3] text-[#020A1B] filter-none hover:bg-[#3F82ED] hover:text-[#FAFCFF] rounded-b-[7px] focus:outline-none"
              onClick={() => {
                // 세션 스토리지에서 삭제
                setAdminLogin({ ..."" });
                window.sessionStorage.removeItem("adminLogin");
                setBelongs([]);
                window.sessionStorage.removeItem("belongs");
                setAdminHistory([]);
                window.sessionStorage.removeItem("adminHistory");
                window.sessionStorage.removeItem("historyPrevIdx");
                navigate("/admin/", { replace: true });
              }}
            >
              <div className="flex items-center gap-x-2 font-semibold text-base leading-[22px] rounded-b-[8px]">
                <img
                  src={header_logout}
                  alt="header_logout"
                  className="header_logout h-[18px] w-[18px] "
                />
                로그아웃
              </div>
              <img
                src={right_arrow}
                alt="right_arrow_logout"
                className="right_arrow_logout h-[18px] w-[18px]"
              />
            </button>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default HeaderMyInfoPopup;
