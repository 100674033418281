import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
SwiperCore.use([Autoplay]);
const AroundStomaBlooding = ({ bigTitle, btn2 }) => {
  const image5 = "/images/mo/jangrucont/장루합병증5.jpg";
  const image6 = "/images/mo/jangrucont/장루합병증6.jpg";
  const image7 = "/images/mo/jangrucont/장루합병증7.jpg";
  const image8 = "/images/mo/jangrucont/장루합병증8.jpg";
  return (
    <div className="w-full flex flex-col  px-[16px] wordbreak-keepall">
      <div className="pt-[72px] ">
        <div className="flex flex-col items-center justify-center w-full border-b">
          <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
          <h1 className="h2_800 pt-[4px] pb-[24px]">{btn2}</h1>
        </div>
        <div className="w-full pt-[24px] body_1_400_jangrucont">
          <div>
            <div className="subtitle_1_700 pb-[8px]">1) 정의</div>
            <Swiper
              loop={true}
              spaceBetween={8}
              slidesPerView={1}
              autoplay={{ delay: 1000, disableOnInteraction: false }}
            >
              <SwiperSlide>
                <div className="w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
                  <img src={image5} alt="image5" className="h-full" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
                  <img src={image6} alt="image6" className="h-full" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
                  <img src={image7} alt="image7" className="h-full" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
                  <img src={image8} alt="image8" className="h-full" />
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="pt-[8px] ">
              <span className="inline-block">
                장루 점막이나 내부에서 출혈이 발생하여 자연적으로 멈추지 않은
                상태를 말합니다.
              </span>
            </div>
          </div>
          <div className="pt-[32px]">
            <div className="subtitle_1_700 pb-[8px]">2) 관리방법</div>

            <div className="pt-[8px] ">
              <div>1. 출혈의 양을 확인하세요.</div>
              <div>2. 출혈 부위에 국부적인 압력을 가합니다.</div>
              <div>3. 장루 점막을 너무 세게 닦지 않습니다.</div>
              <div>4. 피부 보호판를 장루 크기보다 조금 크게 오리세요.</div>
              <div>5. 장루용 파우더를 출혈 부위에 뿌립니다.</div>
              <div>6. 필요시 담당의사와 상담하세요.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AroundStomaBlooding;
