import React from "react";
import { useNavigate } from "react-router-dom";
const Tutorial8 = () => {
  const jangruboyhi = "/images/mo/jangruboyhi.svg";
  const navigate = useNavigate();
  const gomain = () => {
    navigate("/main");
  };
  return (
    <div className="px-[48px]">
      <div className="pt-[64px] flex flex-col items-center ">
        <div className="text-center body_1_400">
          <span className="block">모두 확인하셨다면</span>
          <span className="block">지금 바로 앱을 이용해 볼까요?</span>
        </div>
        <img
          src={jangruboyhi}
          alt="jangruboyhi"
          className="w-[118px] pt-[16px]"
        />
      </div>
      <div className="flex items-center flex-col pt-[118px]">
        <span
          className="block text-center w-full p-[12px] text-white button_700 rounded-full bg-[#1B5EDC] hover:bg-[#18428E] ease-in-out duration-300 cursor-pointer"
          onClick={gomain}
        >
          시작하기
        </span>
        <div className="text-[#343B48] text-center pt-[8px] ">
          {/* {pathname !== "/" ? <div>가이드 그만보기 체크</div> : ""} */}
        </div>
      </div>
    </div>
  );
};

export default Tutorial8;
