import React, { useState, useEffect } from "react";
import common, { api } from "../../../../../data/common";
import ToggleButton from "react-toggle-button";
import { Flip, toast, ToastContainer } from "react-toastify";
import { toastCommonProps } from "../../../admin_components/Toast";

const ToggleBtnCommuInfo = ({ commudata }) => {
  const [on, setOn] = useState(false);
  const postingid = commudata.id;
  const borderRadiusStyle = { borderRadius: 2 };

  useEffect(() => {
    let pageOpen = false;
    const ToggleSet = async () => {
      commudata.public === 0 ? setOn(true) : setOn(false);
    };
    if (!pageOpen) {
      ToggleSet();
    }
    return () => {
      pageOpen = true;
    };
  }, [commudata.public]);

  const onClickToggle = () => {
    let toast_text;
    on
      ? (toast_text = "게시글 비공개 처리 되었습니다.")
      : (toast_text = "게시글 공개 처리 되었습니다.");
    toast(
      <p>{toast_text}</p>,
      toastCommonProps(2000, "top-right", "toast_common toast__search", Flip)
    );
    api
      .post(common.baseURL + "community/posting/changePublic", {
        id: postingid,
        public: on ? 1 : 0,
      })
      .then((res) => {
        if (res.data.ok === true) {
          on ? setOn(false) : setOn(true);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="absolute">
      <ToggleButton
        // inactiveLabel={"비공개"}
        // activeLabel={"공개"}
        thumbStyle={borderRadiusStyle}
        trackStyle={borderRadiusStyle}
        value={on}
        onToggle={() => {
          onClickToggle();
        }}
      />
      <ToastContainer />
    </div>
  );
};

export default ToggleBtnCommuInfo;
