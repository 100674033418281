import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMainContext } from "../../../../contexts/Provider";
import { Bar } from "react-chartjs-2";
import dayjs from "dayjs";
import ChartDataLabels from "chartjs-plugin-datalabels";

const GraphFull = ({}) => {
  let closeimg = "/images/mo/closebasic.svg";
  let navigate = useNavigate();
  let diarydata = useLocation().state.data;
  console.log(diarydata, ">>> diarydata");
  let diarytitle = useLocation().state.title;
  // graph option
  let dayList = [];
  let weightList = [];
  let waterList = [];
  let poopList = [];
  let jangruChangeList = [];
  let maxWeight = 0; // 체중 최고 수치
  let maxWater = 0;
  let maxPoop = 0;
  const { loginInput, tabWhat } = useMainContext();
  const [graphPosition, setGraphPosition] = useState(null); // graph 양옆 빈칸 bottom 값

  useEffect(() => {
    let pageOpen = false;

    if (!pageOpen) {
      if (
        loginInput.id === null ||
        loginInput.id === undefined ||
        loginInput.id === ""
      ) {
        navigate("/main", { replace: true });
      }
    }
    return () => {
      pageOpen = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginInput.id]);

  if (diarydata.length > 0) {
    for (let i = 0; i < diarydata.length; i++) {
      dayList.push(
        dayjs(new Date(diarydata[i].diary.writeDate)).format("MM/DD")
      );
      weightList.push(diarydata[i].diary.weight);
      waterList.push(diarydata[i].diary.water * 200);
      poopList.push(
        diarydata[i].diary.toilet13 * 100 + diarydata[i].diary.toilet23 * 200
      );
      jangruChangeList.push(diarydata[i].diary.jangruChange);
      // 최고 체중/수분량/배변량 계산
      if (diarydata[i].diary.weight > maxWeight)
        maxWeight = diarydata[i].diary.weight;
      if (diarydata[i].diary.water * 200 > maxWater)
        maxWater = diarydata[i].diary.water * 200;
      if (
        diarydata[i].diary.toilet13 * 100 + diarydata[i].diary.toilet23 * 200 >
        maxPoop
      )
        maxPoop =
          diarydata[i].diary.toilet13 * 100 + diarydata[i].diary.toilet23 * 200;
    }
  } else {
    for (let i = 0; i < diarydata.length; i++) {
      dayList.push(
        dayjs(new Date(diarydata[i].diary.writeDate)).format("MM/DD")
      );
      weightList.push(diarydata[i].diary.weight);
      waterList.push(diarydata[i].diary.water * 200);
      poopList.push(
        diarydata[i].diary.toilet13 * 100 + diarydata[i].diary.toilet23 * 200
      );
      jangruChangeList.push(diarydata[i].diary.jangruChange);
      // 최고 체중/수분량/배변량 계산
      if (diarydata[i].diary.weight > maxWeight)
        maxWeight = diarydata[i].diary.weight;
      if (diarydata[i].diary.water * 200 > maxWater)
        maxWater = diarydata[i].diary.water * 200;
      if (
        diarydata[i].diary.toilet13 * 100 + diarydata[i].diary.toilet23 * 200 >
        maxPoop
      )
        maxPoop =
          diarydata[i].diary.toilet13 * 100 + diarydata[i].diary.toilet23 * 200;
    }
    for (let i = 0; i < diarydata.length; i++) {
      dayList.push("");
      weightList.push("");
      waterList.push("");
      poopList.push("");
      jangruChangeList.push("");
    }
  }
  console.log(jangruChangeList, ">>jangruchange");

  const weightData = {
    labels: dayList.length !== 0 ? dayList : [],
    datasets: [
      {
        label: "",
        data: weightList.length !== 0 ? weightList : [],
        backgroundColor: ["#5FC17B"],
        barPercentage: 0.8,
        categoryPercentage: 1,
        borderWidth: 1,
        borderColor: "#5FC17B",
        borderRadius: 2,
      },
    ],
  };
  const waterData = {
    labels: dayList.length !== 0 ? dayList : [],
    datasets: [
      {
        label: "",
        data: waterList.length !== 0 ? waterList : [],
        backgroundColor: ["#1B5EDC"],
        barPercentage: 0.8,
        categoryPercentage: 1,
        borderWidth: 1,
        borderColor: "#1B5EDC",
        borderRadius: 2,
      },
    ],
  };
  const poopData = {
    labels: dayList.length !== 0 ? dayList : [],
    datasets: [
      {
        label: "",
        data: poopList.length !== 0 ? poopList : [],
        backgroundColor: ["#F7CE4B"],
        barPercentage: 0.8,
        categoryPercentage: 1,
        borderWidth: 1,
        borderColor: "#F7CE4B",
        borderRadius: 2,
      },
    ],
  };

  // option들의 공통적으로 들어가는 plugin
  const commonPlugin = (max) => {
    return {
      plugins: {
        tooltip: {
          enabled: false,
        },
        datalabels: {
          color: function (context) {
            if (
              context.dataset.data[context.dataIndex] === 0 ||
              context.dataset.data[context.dataIndex] < Math.floor(max / 8)
            )
              return context.dataset.backgroundColor[0];
            else return "#FAFBFC";
          },
          anchor: function (context) {
            if (
              context.dataset.data[context.dataIndex] === 0 ||
              context.dataset.data[context.dataIndex] < Math.floor(max / 8)
            )
              return "end";
            else return "end";
          },
          align: function (context) {
            if (
              context.dataset.data[context.dataIndex] === 0 ||
              context.dataset.data[context.dataIndex] < Math.floor(max / 8)
            )
              return "end";
            else return "bottom";
          },
          font: function (context) {
            if (
              context.dataset.data[context.dataIndex] === 0 ||
              context.dataset.data[context.dataIndex] < Math.floor(max / 8)
            ) {
              return { lineHeight: 1, size: diarydata.length <= 11 ? 15 : 9 };
            } else
              return { lineHeight: 1.5, size: diarydata.length <= 11 ? 14 : 8 };
          },
        },
        legend: {
          display: false,
        },
      },
    };
  };

  const weightOptions = Object.assign(
    {
      responsive: diarydata.length <= 11 ? true : false,
      maintainAspectRatio: true,
      aspectRatio: 2.535,
      indexAxis: "x",
      minBarLength: 2,
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            display: false,
            stepSize: Math.floor(maxWeight / 4),
          },
          max: maxWeight + Math.floor(maxWeight / 6),
          grid: {
            borderColor: "transparent",
            tickColor: "transparent",
            drawBorder: false,
            color: function (context) {
              if (
                context.tick.value >
                Math.floor(maxWeight + Math.floor(maxWeight / 7))
              ) {
                return "transparent";
              } else return "#E5E6E7";
            },
          },
        },
        x: {
          grid: { display: false },
          ticks: {
            font: {
              size: diarydata.length <= 11 ? 15 : 8,
            },
          },
        },
      },
      tooltips: {
        enabled: false,
      },
    },
    commonPlugin(maxWeight)
  );

  const waterOptions = Object.assign(
    {
      responsive: diarydata.length <= 11 ? true : false,
      maintainAspectRatio: true,
      aspectRatio: 2.535,
      indexAxis: "x",
      minBarLength: 2,
      scales: {
        y: {
          beginAtZero: true,
          ticks: { display: false, stepSize: Math.floor(maxWater / 4) },
          max: maxWater + Math.floor(maxWater / 6),
          drawBorder: false,
          grid: {
            borderColor: "transparent",
            tickColor: "transparent",
            drawBorder: false,
            color: function (context) {
              if (
                context.tick.value >
                Math.floor(maxWater + Math.floor(maxWater / 7))
              ) {
                return "transparent";
              } else return "#E5E6E7";
            },
          },
        },
        x: {
          grid: { display: false },
          ticks: {
            font: {
              size: diarydata.length <= 11 ? 15 : 8,
            },
          },
        },
      },
    },
    commonPlugin(maxWater)
  );

  // 배변량 그래프 옵션
  const poopOptions = Object.assign(
    {
      responsive: diarydata.length <= 11 ? true : false,
      maintainAspectRatio: true,
      aspectRatio: 2.75,
      indexAxis: "x",
      minBarLength: 2,
      scales: {
        y: {
          beginAtZero: true,
          ticks: { display: false, stepSize: Math.floor(maxPoop / 4) },
          max: maxPoop + Math.floor(maxPoop / 6),
          grid: {
            borderColor: "transparent",
            tickColor: "transparent",
            drawBorder: false,
            color: function (context) {
              if (
                context.tick.value >
                Math.floor(maxPoop + Math.floor(maxPoop / 7))
              ) {
                return "transparent";
              } else return "#E5E6E7";
            },
          },
        },
        x: {
          grid: {
            display: false,
          },
          ticks: {
            showLabelBackdrop: function (context) {
              if (jangruChangeList[context.index] === 0) return true;
              else return false;
            },
            backdropColor: "#FAE38E",
            backdropPadding: 3,
            padding: 10,
            font: {
              size: diarydata.length <= 11 ? 15 : 8,
            },
          },
        },
      },
    },
    commonPlugin(maxPoop)
  );

  // grid 튀어나온 부분 가리기 위해 올리는 div bottom 값 구하기
  useEffect(() => {
    const originFrameEl = document.querySelector(".origin-center");
    const graphEl = document.querySelector(".chart-container");
    setGraphPosition(
      originFrameEl.getBoundingClientRect().right -
        graphEl.getBoundingClientRect().right +
        10
    );
  }, []);

  return (
    <>
      <style jsx="true">{`
        .chart-container canvas.reportgraph {
          height: 270px !important;
        }
        .chart-container canvas.reportgraph.poop {
          height: 250px !important;
        }
        .reportgraph,
        .reportgraph.poop {
          width: ${diarydata.length <= 11
            ? "initial !important"
            : "initial !important"};
        }
      `}</style>
      <div className="w-full h-screen fixed top-0 left-0 z-10 bg-[#FAFCFF] flex justify-center items-center">
        <div className=" origin-center rotate-[-90deg] text-gray-600 antialiased border p-8">
          <div className="flex justify-between">
            <span></span>
            <span>{diarytitle}</span>
            <img
              src={closeimg}
              alt="closeimg"
              className="cursor-pointer reportgraph_cancel"
              onClick={() => {
                tabWhat.current = "graph";
                // navigate("/report", { state: { prevPath: "graph" } });
                navigate(-1, { state: { prevPath: "graph" } });
              }}
            />
          </div>
          <div className="w-[700px] h-[320px] overflow-hidden relative ">
            <div className="relative w-full h-full overflow-x-scroll ">
              <div className="relative w-full h-full chart-container ">
                {diarytitle === "수분섭취량" ? (
                  <>
                    <div
                      className="fixed min-w-[700px] h-[272px] bg-[#F1F2F3] border border-transparent border-t-[#CCCDD1]"
                      style={{ bottom: graphPosition - 1 }}
                    ></div>
                    <div
                      className="fixed z-1 left-8 w-6 h-[271px] bg-[#F1F2F3] border border-transparent border-t-[#CCCDD1]"
                      style={{ bottom: graphPosition }}
                    ></div>
                    <div
                      className="fixed z-1 right-8 w-6 h-[270px] bg-[#F1F2F3] border-transparent border-t-[#CCCDD1]"
                      style={{ bottom: graphPosition }}
                    ></div>
                    <Bar
                      className="reportgraph absolute left-0 bottom-[10px] bg-[#F1F2F3] pr-3 border border-t-[#CCCDD1]"
                      id="chart"
                      options={waterOptions}
                      data={waterData}
                      plugins={[ChartDataLabels]}
                      width={
                        diarydata.length <= 11 ? "auto" : 40 * diarydata.length
                      }
                    />
                  </>
                ) : (
                  ""
                )}
                {diarytitle === "체중" ? (
                  <>
                    <div
                      className="fixed min-w-[700px] h-[272px] bg-[#F1F2F3] border border-transparent border-t-[#CCCDD1]"
                      style={{ bottom: graphPosition - 1 }}
                    ></div>
                    <div
                      className="fixed z-1 left-8 w-6 h-[271px] bg-[#F1F2F3] border border-transparent border-t-[#CCCDD1]"
                      style={{ bottom: graphPosition }}
                    ></div>
                    <div
                      className="fixed z-1 right-8 w-6 h-[270px] bg-[#F1F2F3] border-transparent border-t-[#CCCDD1]"
                      style={{ bottom: graphPosition }}
                    ></div>
                    <Bar
                      id="chart"
                      options={weightOptions}
                      data={weightData}
                      plugins={[ChartDataLabels]}
                      className="reportgraph absolute left-0 bottom-[10px] bg-[#F1F2F3] pr-3 border border-transparent border-t-[#CCCDD1]"
                      width={
                        diarydata.length <= 11 ? "auto" : 40 * diarydata.length
                      }
                    />
                  </>
                ) : (
                  ""
                )}
                {diarytitle === "배변량" ? (
                  <div className="flex justify-end h-full">
                    <div
                      className="fixed min-w-[700px] h-[252px] bg-[#F1F2F3] border border-transparent border-t-[#CCCDD1]"
                      style={{ bottom: graphPosition - 28 }}
                    ></div>
                    <div
                      className="fixed z-1 left-8 w-6 h-[251px] bg-[#F1F2F3]  border-transparent border-t-[#CCCDD1]"
                      style={{ bottom: graphPosition + 29 }}
                    ></div>
                    <div
                      className="fixed z-1 right-8 w-6 h-[251px] bg-[#F1F2F3]  border-transparent border-t-[#CCCDD1]"
                      style={{ bottom: graphPosition + 29 }}
                    ></div>
                    <Bar
                      id="chart"
                      options={poopOptions}
                      data={poopData}
                      plugins={[ChartDataLabels]}
                      className="reportgraph poop absolute left-0 bottom-[40px] bg-[#F1F2F3] min-h-[230px] pr-3 border border-t-[#CCCDD1]"
                      width={
                        diarydata.length <= 11 ? "auto" : 40 * diarydata.length
                      }
                    />
                    <div className="absolute left-0 bottom-[0px] mt-2 text-[#343B48] font-normal text-sm leading-4 mb-1">
                      <div className="flex pb-2">
                        <div className="ml-[4px] h-[16px] w-[30px] bg-[#FAE38E]"></div>
                        <p className="inline ml-[4px] ">장루주머니 교체일</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GraphFull;
