import React from "react";
import dayjs from "dayjs";
const UserData1 = ({ user, surveydayarr }) => {
  return (
    <ul>
      <li className="flex mt-4 gap-x-2">
        <div className="w-1/2 flex items-center bg-[#FAFCFF] p-3 rounded-xl ">
          <span className="block w-1/3 caption_1_600">이름</span>
          <span className="block body_1_600">{user.name}</span>
        </div>
        <div className="w-1/2 flex items-center bg-[#FAFCFF] p-3 rounded-xl gap-2">
          <span className="block w-1/3 caption_1_600">등록번호</span>
          <span className="block body_1_600">
            {user.patientID?.slice(0, 2) + "****"}
          </span>
        </div>
      </li>

      <li className="mt-4 bg-[#FAFCFF] p-3 rounded-xl flex items-center">
        <span className="block w-1/3 caption_1_600">생년월일</span>
        <span className="block body_1_600">{user.birth}</span>
      </li>
      <li className="mt-4 bg-[#FAFCFF] p-3 rounded-xl flex items-center">
        <span className="block w-1/3 caption_1_600">성별</span>
        <span className="block body_1_600">
          {user.gender === "M" ? "남성" : user.gender === "F" ? "여성" : ""}
        </span>
      </li>
      <li className="mt-4 bg-[#FAFCFF] p-3 rounded-xl flex items-center">
        <span className="block w-1/3 caption_1_600">앱 가입일</span>
        <span className="block body_1_600">
          {user.agreeDate ? dayjs(user.agreeDate).format("YYYY-MM-DD") : ""}
        </span>
      </li>
      <li className="mt-4 bg-[#FAFCFF] p-3 rounded-xl flex items-center">
        <span className="block w-1/3 caption_1_600">참여 시작일</span>
        <span className="block body_1_600">
          {user.startDate ? dayjs(user.startDate).format("YYYY-MM-DD") : ""}
        </span>
      </li>
      <li className="mt-4 bg-[#FAFCFF] p-3 rounded-xl flex items-center">
        <span className="block w-1/3 caption_1_600">참여 종료일</span>
        <span className="block body_1_600">
          {user.endDate ? user.endDate : ""}
        </span>
      </li>
      <li className="mt-4 bg-[#FAFCFF] p-3 rounded-xl flex items-center">
        <span className="block w-1/3 caption_1_600">장루 점검일</span>
        <span className="block body_1_600">
          {surveydayarr !== null
            ? `매월 ${surveydayarr[0]}일, ${surveydayarr[1]}일`
            : ""}
        </span>
      </li>
      <li className="mt-4 bg-[#FAFCFF] p-3 rounded-xl flex items-center">
        <span className="block w-1/3 caption_1_600">휴대폰번호</span>
        <span className="block body_1_600">{user.phone ? user.phone : ""}</span>
      </li>
    </ul>
  );
};

export default UserData1;
