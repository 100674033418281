const servicePolicy1 = [
  "제 1 조 (목적)",
  '이 이용약관(이하 "약관"이라 합니다)은 주식회사 헤링스(이하 "회사"라 합니다)가 운영하는 장루관리 웹에 병원 또는 의료인 회원(이하 “병원회원” 또는 “회원”이라 합니다)으로 가입하여 비대면 진료 및 건강삼담 관련 플랫폼 서비스 및 기타 서비스(이하 "서비스"라 합니다)를 이용하는 자간의 권리, 의무 및 책임사항, 서비스의 이용조건 및 절차, 기타 필요한 사항을 규정함을 목적으로 합니다.',
];
const servicePolicy2 = [
  "제 2 조 (약관의 게시와 개정)",
  "1. 회사는 이 약관의 내용을 회원이 쉽게 알 수 있도록 회원가입 화면 등에 게시합니다.",
  "2. 회사는 약관의규제에관한법률 등 관련 법률을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.",

  "3. 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 장루관리 웹 상에 그 적용일자 14일 이전 부터 적용일자 전일까지 공지합니다. 다만, 변경 내용이 중대하거나 병원회원에게 불리한 경우에는 그 적용일자 30일 이전 부터 적용일자 전일까지 공지하고 병원회원에게 개별 통지합니다. 변경된 약관은 그 적용일자 이전으로 소급하여 적용되지 아니합니다.",
  "4. 이 약관에서 정하지 않은 사항은 서비스 이용약관, 서비스별 안내 및 관련 법령의 규정과 일반적인 상관례에 의합니다.",
];
const servicePolicy3 = [
  "제 3 조 (회사의 법률 준수사항)",
  " 회사는 서비스 이용 등과 관련하여 불공정행위를 하지 않으며, 의료법 제27조(무면허 의료행위 등 금지)를 준수합니다.",
];
const servicePolicy4 = [
  " 제 4 조 (서비스의 종류)",
  "1. 회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.",
  '1) 비대면 진료 플랫폼 개발 및 운영 서비스 (이하 "비대면 진료 플랫폼 서비스")',
  '2) 건강상담 플랫폼 개발 및 운영 서비스 (이하 "건강상담 플랫폼 서비스")',
  '3) 건강관리 플랫폼 개발 및 운영 서비스 (이하 "건강관리 서비스")',
  '(상기 1)~3)의 서비스를 통칭하여 "비대면 플랫폼 서비스"라 합니다.)',
  "4) 비대면 진료 및 건강상담 업무지원 서비스",
  "5) 건강관리 업무지원 서비스",
  "6) 광고/이벤트/프로모션 집행 서비스",
  "7) 장루 정보 플랫폼 서비스",
  "8) 이용자(환자) 지원 서비스",
  "9) 병의원 등 정보검색 서비스",
  "10) 게시판형/커뮤니티형/쪽지형 서비스",
  "11) 기타 회사가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 회원에게 제공하는 일체의 서비스",
];
const servicePolicy5 = [
  "제 5 조 (대리행위의 부인)",
  "회사는 비대면 플랫폼 서비스 제공자로서 원활한 비대면 진료, 건강상담을 위한 시스템 운영 및 관리 책임만을 부담하며, 이용자(환자)와 병원회원간의 진료, 건강상담 행위와 관련하여 이용자 또는 병원회원을 대리하지 아니하고, 병원회원이 제공하고 등록한 정보, 이용자(환자)와 병원회원 간의 진료, 건강상담 행위 및 이용자(환자) 본인의 건강에 관한 의사 결정에 대해서는 일체의 책임을 지지 않습니다.",
];
const servicePolicy6 = [
  "제 6 조(보증의 부인)",
  "회사는 회사가 제공하는 시스템을 통하여 이루어지는 이용자(환자) 와 병원회원 간의 진료, 건강상담과 관련하여 진정성, 이용자(환자) 또는 병원회원이 입력하는 정보 및 그 정보를 통하여 링크된 URL에 게재된 자료의 진실성 또는 적법성 등 일체에 대하여 보증하지 아니하며, 이와 관련한 일체의 위험과 책임은 해당 회원이 전적으로 부담합니다.",
];
const servicePolicy7 = [
  "제 7 조(이용 계약의 성립)",
  "1. 이용계약은 회사가 제공하는 비대면 플랫폼 서비스를 이용하고자 하는 자의 이용신청에 대하여 회사가 이를 승낙함으로써 성립합니다.회사는 이용승낙의 의사표시를 해당 서비스화면에 게시하거나 e - mail 또는 기타 방법으로 통지합니다.",
  "2. 비대면 플랫폼 서비스를 이용하고자 하는 자는 본 약관에 동의하고, 회사가 정하는 회원 가입 신청 양식에 따라 필요한 사항을 기입합니다.",
  "3. 회원가입은 만 14 세 이상의 개인 또는 사업자(개인사업자 및 법인사업자) 가 할 수 있으며, 이용신청자는 실명으로 가입신청을 해야 하며, 실명이 아니거나 타인의 정보를 도용하는 경우 서비스이용이 제한되거나 관련 법령에 의거 처벌받을 수 있습니다.",
  "4. 이용신청의 처리는 신청순서에 의하며, 회원가입의 성립시기는 회사의 승낙이 회원에게 도달한 시점으로 합니다.",
  "5. 회사는 회사 재량으로 소정의 가입비를 병원회원에게 부과할 수 있으며, 가입비 납부가 완료될 때까지 이용신청에 대한 승낙을 유보할 수 있습니다.",
  "6. 회사는 다음과 같은 사유가 발생한 경우 이용신청에 대한 승낙을 거부하거나 유보할 수 있습니다.",
  "1) 회사의 실명확인절차에서 본인의 실명으로 가입 신청하지 않은 것이 확인된 경우",
  "2) 이미 가입된 회원과 이름 및 주민등록번호(또는 사업자등록번호) 가 동일한 경우",
  "3) 회사에 의하여 이용계약이 해지된 날로부터 2 개월 이내에 재이용신청을 하는 경우",
  "4) 회사로부터 회원자격 정지 조치 등을 받은 회원이 그 조치기간 중에 이용계약을 임의 해지하고 재이용신청을 하는 경우",
  "5) 설비에 여유가 없거나 기술상 지장이 있는 경우",
  "6) 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우",
];
const servicePolicy8 = [
  "제 8 조(증빙서류)",
  "회사는 병원회원이 이 약관 제7조 제2항에 따라 제공한 정보의 정확성을 확인하기 위하여 관련법령이 허용하는 범위 내에서 증빙자료의 제공을 요청할 수 있습니다.병원회원이 부당하게 증빙자료를 제공하지 않는 경우 회사는 이용계약의 해지, 병원회원 활동의 제한 또는 정산금의 지급 보류 등 조치를 취할 수 있으며, 병원회원은 이로 인하여 발생하는 손해에 대해 회사에게 어떠한 책임도 물을 수 없습니다.",
];
const servicePolicy9 = [
  "제 9 조(개인정보의 변경, 보호)",
  "1. 병원회원은 이용신청 시 허위의 정보를 제공하여서는 아니 되며, 기재한 사항이 변경되었을 경우에는 즉시 변경사항을 최신의 정보로 수정하여야 합니다.단, 법령에 의한 변경 이외의 이름, ID, 주민등록번호 등은 수정할 수 없습니다.병원회원은 변경사항과 관련하여 회사가 요청하는 경우 즉시 변경사항에 관한 증빙자료를 제공하여야 합니다.",
  "2. 회사의 병원회원에 대한 통지는 회원이 제공한 주소 또는 e - mail주소에 도달하거나 회원이 제공한 휴대폰번호에 알림톡 등 전자적 안내방법으로 도달함으로써 통지된 것으로 보며, 수정하지 않은 정보로 인하여 발생하는 회원의 손해 또는 타인의 손해는 당해 회원이 전적으로 부담하며, 회사는 이에 대하여 아무런 책임을 지지 않습니다.또한, 병원회원이 변경된 정보를 수정하지 않거나 또는 허위 내용으로 수정하여 회사에게 손해를 입힌 경우에는 이에 대한 손해배상 책임을 부담합니다.",
  "3. 회사는 이용계약을 위하여 병원회원이 제공한 정보를 회원이 동의한 회사 서비스 운영을 위한 목적 이외의 용도로 사용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용· 제공단계에서 당해 회원에게 그 목적을 고지하고 동의를 받습니다.다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.",
  '4. 회사는 병원회원의 개인정보를 보호하기 위해 제3자에게 병원회원의 개인정보를 제공할 필요가 있는 경우에는 제공되는 개인정보 항목, 제공받는 자, 제공받는 자의 개인정보 이용목적 및 보유· 이용기간 등을 명시하여 병원회원의 동의를 받고, 개인정보를 위탁하는 경우에는 관련 법령이 정하는 바에 따라 "개인정보처리방침"을 수립하고 개인정보 보호 책임자를 지정하여 이를 게시하고 운영합니다.',
];
const servicePolicy10 = [
  "제 10 조(아이디 및 비밀번호의 관리)",
  "1. 아이디(ID) 및 비밀번호에 대한 관리책임은 병원회원에게 있으며, 회원은 어떠한 경우에도 본인의 아이디(ID) 또는 비밀번호를 타인에게 양도하거나 대여할 수 없습니다.",
  "2. 회사의 귀책사유 없이 아이디(ID) 또는 비밀번호의 유출, 양도, 대여로 인하여 발생하는 손실이나 손해에 대하여는 병원회원 및 이용자 본인이 그에 대한 책임을 부담합니다.",
  "3. 병원회원은 아이디(ID) 또는 비밀번호를 도난 당하거나 제3자가 무단으로 이를 사용하고 있음을 인지한 경우, 이를 즉시 회사에 통보하여야 하고 회사는 이에 대한 신속한 처리를 위하여 최선의 노력을 다합니다.",
];
const servicePolicy11 = [
  "제 11 조(계약기간 및 이용계약의 종료)",
  "1. 이용계약의 기간은 병원회원이 약관에 대해 동의한 날로부터 당해 연도 말일까지로 하고, 기간 만료 1 개월 전까지 서면에 의한 반대의 의사표시가 없는 한 계약기간은 동일한 조건으로 1 년간 자동 갱신됩니다.",
  "2. 회사의 해지",
  "1) 회사는 다음과 같은 사유가 발생하거나 확인된 경우 이용계약을 해지할 수 있습니다① 다른 회원 또는 타인의 권리나 명예, 신용 기타 정당한 이익을 침해하거나 대한민국 법령 또는 공서양속에 위배되는 행위를 한 경우② 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우③ 제7조 제5항의 승낙거부사유가 있음이 확인된 경우④ 병원회원이 제공한 정보 또는 그에 관한 증빙자료가 허위이거나 회사가 요청하는 증빙자료를 제공하지 않는 경우⑤ 기타 회사가 합리적인 판단에 기하여 서비스의 제공을 거부할 필요가 있다고 인정할 경우",
  "2) 회사가 해지를 하는 경우 회사는 병원회원에게 유선 또는 이메일, 기타의 방법을 통하여 해지사유를 밝혀 해지의사를 통보합니다.이 경우 회사는 해당 회원에게 사전에 해지사유에 대한 의견진술의 기회를 부여할 수 있습니다.",
  "3) 이용계약은 회사의 해지의사를 병원회원에게 통지한 시점에 종료됩니다.",
  "4) 본 항에서 정한 바에 따라 이용계약이 종료될 시에는 회사는 병원회원에게 부가적으로 제공한 각종 혜택을 회수할 수 있습니다.",
  "5) 본 항에서 정한 바에 따라 이용계약이 종료된 경우에는, 회원의 재이용신청에 대하여 회사는 이에 대한 승낙을 거절할 수 있습니다.",
  "6) 회사가 이용계약을 해지하는 경우, 병원회원은 이용자(환자) 보호를 위하여 해지 시까지 완결되지 않은 진료, 상담 등의 완결을 위해 필요한 조치를 취하여야 합니다.",
  "3. 당사자 일방에게 다음 각 호의 사유가 발생한 경우, 그 상대방은 별도의 최고 없이 해지의 통지를 함으로써 이용계약을 해지할 수 있습니다.",
  "1) 이용계약의 의무를 위반하여 상대방으로부터 그 시정을 요구 받은 후 7 일 이내에 이를 시정하지 아니한 경우",
  "2) 부도 등 금융기관의 거래정지, 회생 및 파산절차의 개시, 영업정지 및 취소 등의 행정처분, 주요 자산에 대한 보전처분, 영업양도 및 합병 등으로 이용계약의 이행이 불가능한 경우",
  "3) 병원회원의 책임 있는 사유로 2 개월간의 진료, 상담 건 중 30 % 이상 진료취소 또는 상담취소가 된 경우",
  "4) 관련 법령 위반 등 병원회원의 책임 있는 사유로 인하여 회사가 명예 실추 등 유무형적 손해를 입은 경우",
  "4. 병원회원의 해지",
  "제3항에도 불구하고, 병원회원은 언제든지 회사에게 해지의사를 통지함으로써 이용계약을 해지할 수 있습니다.다만, 병원회원은 해지의사를 통지하기 전에 진행중인 모든 진료, 상담 절차를 종료하고 회사에 대한 채무를 정산하여야 합니다.",
  "5. 회사는 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.이 경우 서비스 일시 중단 사실과 그 사유를 장루관리 웹 초기화면에 게시합니다.",
  "6. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스의 제공을 제한하거나 일시 중단할 수 있습니다.",
  "7. 이용계약의 해지에도 불구하고 병원회원은 해지 시까지 완결되지 않은 진료, 상담 등의 완결을 위해 필요한 조치를 취하여야 하며, 해지 이전에 완결된 진료, 상담과 관련하여 발생한 병원회원의 책임과 관련된 조항은 그 효력을 유지합니다.",
];
const servicePolicy12 = [
  "제 12 조(비대면 플랫폼 서비스)",
  "1. 회사는 비대면 플랫폼 서비스 제공을 통해 장루관리 웹 상에서 병원회원이 다양한 형태로 비대면 진료와 건강상담을 제공할 수 있도록 지원합니다.",
  "2. 회사는 홍보를 위해 병원회원이 등록한 정보를 홍보를 위해 국내외 포털 등 외부 사이트에 노출할 수 있습니다.",
  "3. 회사는 병원회원이 등록한 정보를 회사가 서비스 제공을 위하여 정한 기준과 방법에 따라 장루관리 웹에 게재합니다.회사는 게재하는 정보의 위치, 크기, 배열 등을 결정하고 조정할 수 있으며, 이벤트 광고 등 회사의 서비스를 위하여 해당 서비스화면을 구성, 편집할 수 있습니다.",
  "4. 회사는 비대면 플랫폼의 균형 잡힌 서비스 운영을 위하여, 특정 기간 동안의 특정 병원회원의 최대 진료 건수를 제한할 수 있습니다.",
  "5. 병원회원은 이용자(환자) 를 위하여 진료과목, 자격증명, 주요학력 및 경력, 병원전화번호, 병원주소, 진료 / 상담 가능시간 등 정보를 공개하여야 합니다.",
  "6. 병원회원은 진료 / 상담 신청 건(접수대기 건) 을 수시로 확인하여 원활한 진료 / 상담이 이루어질 수 있도록 조치를 취해야 합니다.",
  "7. 병원회원은 접수취소 처리시 정확한 귀책 사유를 입력하여야 하며, 이와 관련된 클레임 발생 시 병원회원에게 패널티가 부과될 수 있습니다.",
  "8. 병원회원의 귀책사유로 다수의 접수취소 건이 발생하는 경우 회사는 병원회원의 활동제한 등의 패널티를 부과할 수 있습니다.",
  "9. 병원회원은 이용자(환자) 와의 진료 / 상담 내용에 대하여 비밀준수의무를 집니다.",
  "10. 병원회원이 이용자(환자) 에게 결제요청 후 일정 기간 내에 결제가 되지 않을 경우, 회사는 당해 진료 / 상담 건을 회원의 동의 없이 취소할 수 있습니다.",
  "11. 병원회원은 광고, 브로커제안, 장난 등 서비스의 취지에 부합하지 않는 목적으로 상담 서비스를 이용하는 일반회원에 대하여 신고를 할 수 있으며, 회사는 적절한 조치를 취해야 합니다.",
  "12. 병원회원은 회사가 제공하는 서비스의 지적재산권을 침해하는 행위를 하여서는 안됩니다.",
];
const servicePolicy13 = [
  "제 13 조(서비스 이용료)",
  "1. 서비스이용료는 병원회원이 비대면 플랫폼 서비스 등 장루관리 웹 서비스와 각종 부가서비스를 이용함에 따른 대가로 병원회원이 회사에 지불하여야 하는 금액을 의미하며, 회사는 서비스제공 비용, 시장상황, 병원회원의 유형 등을 고려하여 병원회원에게 제공하는 서비스에 대한 이용료를 정합니다.",
  "2. 회사는 서비스 이용료를 장루관리 웹 상에 게시하여 병원회원에게 고지합니다.회사는 필요한 경우 서비스이용료를 신설, 변경할 수 있으며, 신설 또는 변경사항은 회사가 제공하는 서비스화면을 통하여 공지합니다.",
  "3. 회사는 서비스 활성화 등을 위하여 서비스 이용료를 할인할 수 있습니다.",
  "4. 서비스이용료는 진료비대금에서의 공제 등으로 결제할 수 있으며, 회사와 병원회원간의 협의 또는 회사의 내부 사정에 따라 요율, 결제방법 등이 변경될 수 있습니다.",
  "5. 회사는 매월 초에 전월에 발생한 1 항의 서비스 이용료에 대한 세금계산서를 발행합니다.",
];
const servicePolicy14 = [
  "제 14 조(회원 관리)",
  "1. 회사는 본 약관과 관련 법령 및 사회상규 일반원칙을 위반한 회원에 대하여 다음과 같은 조치를 할 수 있습니다.",
  "1) 정산일자 변경 또는 정산 보류",
  "2) 회사가 부가적으로 제공한 혜택의 일부 또는 전부의 회수",
  "3) 특정서비스 이용제한",
  "4) 이용계약의 해지",
  "5) 손해배상의 청구",
  "6) 노출 또는 정렬 관련 페널티 적용",
  "2. 회사가 전항 각 호에 정한 조치를 할 경우 회사는 사전에 회원에게 유선 또는 이메일, 기타의 방법을 통하여 통보하며, 회원의 연락이 두절되거나 긴급을 요하는 것과 같이 부득이한 경우 선 조치 후 사후 통보할 수 있습니다.",
  "3. 회원은 본조에 의한 회사의 조치에 대하여 항변의 사유가 있는 경우 이에 대하여 항변을 할 수 있습니다.",
];
const servicePolicy15 = [
  "제 15 조(취득한 이용자(환자) 정보의 보호)",
  "1. 병원회원은 비대면 플랫폼 서비스의 이용에 따라 지득한 이용자(환자) 등 타인의 개인정보를 이 약관에서 정한 목적 및 진료 / 상담 목적 이외의 용도로 사용할 수 없으며, 이를 위반할 경우 당해 회원은 관련 법령에 의한 모든 민ㆍ형사상의 법적 책임을 지고 자신의 노력과 비용으로 회사를 면책시켜야만 하며, 회사는 당해 회원을 탈퇴시킬 수 있습니다.",
  "2. 회사는 개인정보 보호를 위하여 병원회원에게 공개되어 있는 이용자(환자) 의 개인정보를 상당 기간이 경과한 후 비공개 조치할 수 있습니다.",
  "3. 회사가 개인정보의 보호를 위하여 상당한 주의를 기울였음에도 불구하고, 특정 병원회원이 제1항을 위반하여 타인의 개인정보를 유출 또는 유용한 경우 회사는 그에 대하여 아무런 책임을 지지 않습니다.",
  "4. 전기통신사업법 등 관련 법령이 규정하는 적법한 절차에 따라 수사관서 등이 회사에 병원회원에 관한 정보의 제공을 요청한 경우, 회사는 그에 관한 자료를 제출할 수 있습니다.",
  "5. 병원회원이 이용자(환자) 등 타인의 개인정보를 처리할 때에는 개인정보의 분실· 도난· 유출· 변조 또는 훼손을 방지하기 위하여 관련법령에서 정한 기술적· 관리적 조치를 다하여야 합니다.회원은 이용자의 개인정보를 처리하는 자를 최소한으로 제한하여야 합니다.회원은 목적을 다한 개인정보에 대하여 지체 없이 해당 개인정보를 복구· 재생할 수 없도록 파기하여야 합니다.",
];
const servicePolicy16 = [
  "제 16 조(금지행위)",
  "1. 허위상담 금지",
  "1) 허위상담이란 병원회원이 사용후기,",
  "별점 등의 개수 증가 또는 평가내용 조작을 위해 본인 또는 타인의 ID를 사용하여 병원회원 본인에게 진료 또는 상담을 받는 행위를 말하며, 회사는 이를 금지하고 있습니다.",
  "2) 허위상담 적발 시 회사는 병원회원에 대하여 후기삭제, 이용제한, 이용정지, 계약해지, 정산대금 지급보류 등 필요한 조치를 취할 수 있습니다.",
  "3) 허위상담 적발 시 회사는 사안에 따라 병원회원에게 부가적인 확인을 요구할 수 있으며, 회원은 이에 협조할 의무가 있습니다.",
  "2. 연락두절",
  "연락두절이란 병원회원이 회원가입 시 기재한 유선 또는 이메일, 기타의 방법을 통하여 이용자(환자) 및 회사가 연락을 시도하였음에도 불구하고 일체의 응대가 진행되지 않는 상태를 말합니다.병원회원은 휴업, 영업정지, 폐업 등 정상적인 영업활동이 불가한 상태 또는 이와 같은 상태가 예상되는 경우 이를 즉시 회사에 통보하여야 하며 정상적인 영업활동이 불가한 상태 또는 이와 같은 상태라 하더라도 이용자(환자) 의 정당한 요구에 대한 응대와 신속한 처리를 위하여 최선의 노력을 다하여야 합니다.병원회원의 연락두절로 인한 클레임이 반복적으로 발생하는 경우, 회사는 이용제한, 이용정지, 계약해지, 정산대금 지급보류 등 필요한 조치를 취할 수 있습니다.",
  "3. CS 불만족 미처리",
  "병원회원은 이용자(환자) 의 문의(간단문의 등) 를 신속하고 성실히 처리해야 합니다.회사는 일정기한 내 병원회원의 답변의 처리 유무에 따라 처리율을 판단하고, 답변에 대한 이용자 만족도를 조사하여 병원회원의 답변 만족도를 판단할 수 있습니다.이용자(환자) 문의에 대한 병원회원의 처리율 및 만족도가 저조한 경우 회사는 이용제한 등 필요한 조치를 취할 수 있습니다.",
  "4. 병원회원은 본 조 규정에 해당하는 행위를 하여서는 아니 되며 이를 위반한 경우 회사는 각 항에서 개별적으로 규정하고 있는 제재조치를 취할 수 있습니다.",
];
const servicePolicy17 = [
  "제 17 조(약관 외 준칙 및 관련 법령과의 관계)",
  "1. 이 약관에 명시되지 않은 사항은 관련 법령의 규정과 일반 상관례에 의합니다.",
  '2. 회사는 이 약관에서 규정되지 않은 구체적인 내용과 특정서비스에 관한 내용을 별도의 약관(이하 "개별약관"이라 함) 에 규정할 수 있으며, 이를 장루관리 웹을 통하여 공지하고 병원회원이 이에 동의한 경우 개별약관은 이 약관과 더불어 이용계약의 일부를 구성합니다.',
  "3. 회사는 전항의 개별약관에 변경이 있을 경우, 적용일자 및 변경사유를 명시하여 현행 개별약관 등과 함께 그 적용일자 7 일(다만, 병원회원에게 불리한 내용으로 변경하는 경우에는 30 일) 이전부터 적용일자 전일까지 위 2 항과 같은 방법으로 해당 변경사항을 공지합니다.변경된 개별약관은 그 적용일자 이전으로 소급하여 적용되지 아니 합니다.",
  "4. 회원은 이 약관 및 개별약관(이하“ 약관 등” 이라 합니다) 의 내용에 변경이 있는지 여부를 주시하여야 하며, 변경사항의 공지가 있을 시에는 이를 확인하여야 한다.",
  "5. 회사는 특정 병원회원과 개별적으로 약관 등에 규정된 내용과 다른 내용의 계약(이하“ 개별계약” 이라 합니다) 을 체결할 수 있습니다.이 경우 개별계약이 약관등에 우선하여 적용됩니다.회사는 개별계약을 체결한 병원회원에게 계약내용을 서면(전자문서 포함) 교부하거나 장루관리 웹 병원회원 화면에서 확인할 수 있도록 하여야 합니다.",
];
const servicePolicy18 = [
  "제 18 조(회사의 면책)",
  "1. 회사는 비대면 플랫폼 서비스 제공자로서 원활한 비대면 진료, 건강상담을 위한 시스템 운영 및 관리 책임만을 부담할 뿐, 이용자(환자) 와 병원 / 약국회원간의 진료, 건강상담 행위와 관련하여 분쟁이 발생한 경우 회사는 그 분쟁에 개입하지 않으며 그 분쟁의 결과로 인한 모든 책임은 병원회원이 부담합니다.또한 이와 관련하여 회사가 제3자에게 손해를 배상하거나 기타 비용을 지출한 경우 회사는 병원회원에게 구상권을 행사할 수 있습니다.",
  "2. 회사는 관련 법령에 의거하여 병원회원의 정보를 열람할 수 있는 방법을 이용자(환자) 에게 제공할 수 있으며, 병원회원은 당해 정보를 기재하지 아니하거나, 허위로 기재함으로써 발생하는 모든 책임을 부담하여야 합니다.이와 관련하여 회사가 제3자에게 손해를 배상하거나 기타 비용을 지출한 경우 회사는 병원회원에게 구상권을 행사할 수 있습니다.",
  "3. 회사는 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 판매서비스의 제공을 일시적으로 중단할 수 있으며, 이와 관련하여 회사는 고의 또는 중대한 과실이 없는 한 책임을 지지 않습니다.",
  "4. 병원회원이 자신의 개인정보를 타인에게 유출 또는 제공함으로써, 발생하는 피해에 대해서 회사는 일절 책임을 지지 않습니다.",
  "5. 기타 관련 법령 및 회사에서 제공한 이용약관 및 개별약관의 변경, 공지사항 등의 주의의무를 게을리하여 발생한 병원회원의 피해에 대해서 회사는 일절 책임을 지지 않습니다.",
];
const servicePolicy19 = [
  "제 19 조(기록∙ 게시물의 권리 귀속)",
  "1. 병원회원이 서비스 내에 기록∙ 게시한 기록∙ 게시물은 회사의 서비스를 바탕으로 이루어진 것이므로 그 소유권 및 그에 관한 저작권 등 일체의 지식재산권 역시 회사에게 귀속됩니다.회사는 상업적 / 연구 목적(새로운 서비스를 개발하고, 머신러닝 모델을 개발하는 등의 목적을 포함) 으로 병원회원이 등록한 기록∙ 게시물을 사용할 수 있습니다.",
  '2. "기록∙게시물"이라 함은 병원회원이 회사가 제공하는 서비스에 기록∙ 게시 또는 등록하는 부호, 문자, 음성, 음향, 화상, 사진, 동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 말하며, 병원회원이 환자의 장루 사진을 받고 이를 판정(출혈, 피부염 등) 해준 정보를 모두 포함합니다.',
  "3. 회사와 병원회원간 이용계약이 해지된 경우에도 본 조 규정은 계속하여 유효합니다.",
];
const servicePolicy20 = [
  "제 20 조(장루관리 웹과 서비스의 권리 귀속)",
  "1. 장루관리 웹과 서비스에 대한 저작권 및 지적재산권은 회사에 귀속됩니다.즉, 회사가 제공하는 서비스의 디자인, 회사가 만든 텍스트, 스크립트(script), 그래픽, 회원 상호간 전송 기능 등 회사가 제공하는 서비스에 관련된 모든 상표, 서비스 마크, 로고 등에 관한 저작권 기타 지적재산권은 대한민국 및 외국의 법령에 기하여 회사가 보유하고 있거나 회사에게 소유권 또는 사용권이 있습니다.단, 제휴계약에 따라 제공된 저작물 등은 제외합니다.",
  "2. 병원회원은 이 약관으로 인하여 서비스를 소유하거나 서비스에 관한 저작권을 보유하게 되는 것이 아니라, 회사로부터 이용조건에 따른 계정, 등록정보, 콘텐츠 등을 이용할 수 있는 서비스 이용권만을 허락 받으며, 병원회원은 이를 양도, 판매, 담보제공 등 처분할 수 없고, 정보취득을 위하여 개인용도로만 이용할 수 있습니다.",
  "3. 병원회원은 명시적으로 허락된 내용을 제외하고는 서비스를 통해 얻어지는 회원 상태정보를 영리 목적으로 사용, 복사, 유통하는 것을 포함하여 회사가 만든 텍스트, 스크립트, 그래픽의 회원 상호간 전송기능 등을 복사하거나 유통할 수 없습니다.",
  "4. 병원회원은 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 등 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.",
];
const servicePolicy21 = [
  "제 21 조(관할법원)",
  "이 약관과 회사와 회원 간의 이용계약 및 회원 상호간의 분쟁에 대해 회사를 당사자로 하는 소송이 제기될 경우에는 회사의 본사 소재지를 관할하는 법원을 합의관할법원으로 정합니다.",
];
const servicePolicy22 = [
  "제 22 조(기타조항)",
  "1. 병원회원은 주소지 또는 정산대금결제를 위한 통장계좌 등의 변경이 있을 경우 즉시 회사에 이를 통지하여야 하며, 회사는 통지의 지연으로 인하여 발생한 손해에 대하여 책임을 지지 않습니다.",
  "2. 회사는 필요한 경우 특정 서비스(혹은 그 일부) 를 장루관리 웹에 미리 공지한 후, 일시적 또는 영구적으로 수정하거나 중단할 수 있습니다.",
  "3. 회사와 회원은 상대방의 명백한 동의 없이 본 약관상의 권리와 의무를 제3자에게 양도할 수 없습니다.",
  "4. 이 약관과 관련하여 당사자 간의 합의에 의하여 추가로 작성된 계약서, 협정서, 통보서 등과 회사의 정책변경, 법령의 제정 개정 또는 공공기관의 고시 지침 등에 의하여 회사가 장루관리 웹을 통해 병원회원에게 공지하는 내용도 본 약관의 일부를 구성합니다.",
];
const servicePolicySub = [
  "[부칙]",
  "제 1 조(시행일)",
  "이 약관은 2022년 5월 6일부터 적용됩니다.",
];
const servicePolicy = [
  servicePolicy1,
  servicePolicy2,
  servicePolicy3,
  servicePolicy4,
  servicePolicy5,
  servicePolicy6,
  servicePolicy7,
  servicePolicy8,
  servicePolicy9,
  servicePolicy10,
  servicePolicy11,
  servicePolicy12,
  servicePolicy13,
  servicePolicy14,
  servicePolicy15,
  servicePolicy16,
  servicePolicy17,
  servicePolicy18,
  servicePolicy19,
  servicePolicy20,
  servicePolicy21,
  servicePolicy22,
  servicePolicySub,
];

const personalInfoPolicy1 = [
  "개인정보처리방침",
  "주식회사 헤링스(이하 “회사”라 합니다)는 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 “정보통신망법”), 개인정보보호법, 통신비밀보호법, 전기통신사업법 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.",
  "본 개인정보처리방침은 회사가 제공하는 장루관리 앱/웹 (이하 “서비스”)이용에 적용되며 다음과 같은 내용을 담고 있습니다.",
];

const personalInfoPolicy2 = [
  "[개인정보의 수집 항목 및 이용목적]",
  "회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.",
  "1. 회사는 서비스 제공을 위하여 수집한 개인정보를 아래의 목적으로 이용합니다.",
  "- 회원식별 및 가입의사 확인, 본인∙연령확인, 부정이용 방지",
  "- 신규서비스 개발, 다양한 서비스 제공, 문의사항 또는 불만∙분쟁처리, 공지사항 전달",
  "- 이벤트 행사 시 정보 전달, 마케팅 및 광고 등에 활용",
  "- 서비스 이용 기록, 접속 빈도 및 서비스 이용에 대한 통계, 맞춤형 서비스 제공, 서비스 개선에 활용",
  "- 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지",
  "2. 회사가 처리하는 이용자의 개인정보는 다음과 같습니다. ",
];

const personalInfoPolicy3 = [
  "[개인정보의 처리 및 보유 기간]",
  "1. 이용자의 개인정보는 그 수집목적 또는 제공받은 목적이 달성되거나 이용계약 해지(회원탈퇴)를 요청한 경우 파기하는 것을 원칙으로 하며, 이 경우 이용자의 개인정보는 재생할 수 없는 방법에 의하여 시스템에서 완전히 삭제되며 어떠한 용도로도 열람 또는 이용할 수 없도록 처리됩니다. 또한, 일시적인 목적(설문조사 등)으로 입력 받은 개인정보는 그 목적이 달성된 이후에는 동일한 방법으로 사후 재생이 불가능한 방법으로 처리됩니다.",
  "2. 회사는 불량 회원의 부정한 이용의 재발을 방지하기 위해 이용계약 해지일(회원탈퇴일)로부터 1년간 해당 회원의 개인정보를 보유할 수 있습니다. 그리고 상법 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.",
  "※ 회사 내부 정책에 의하여 수집 및 이용되는 정보",
  "※ 법령에 의하여 수집 및 이용되는 정보",
  "3. 이용약관 및 관계법령을 위반한 회원의 경우 다른 회원을 보호하고 사법기관 수사의뢰 시 증거자료로 활용하기 위해 회원탈퇴 후 2년 까지 회원정보를 보관할 수 있습니다.",
  "4. 2022년 5월 6일 이후 가입한 회원이 1년간 서비스 거래기록이 없을 시, “정보통신망법” 제29조에 근거하여 회원에게 사전통지하고 개인정보를 파기하거나 별도로 분리하여 저장 관리합니다. 고객의 요청이 있을 경우에는 위 기간을 달리 정할 수 있습니다. 단, 통신비밀보호법 등의 관계법령의 규정에 의하여 보존할 필요가 있는 경우 관계법령에서 규정한 일정한 기간 동안 회원 개인정보를 보관합니다. 회사는 위의 1년의 기간 만료 30일 전까지 개인정보가 파기되거나 분리되어 저장∙관리되는 사실과 기간 만료일 및 해당 개인정보의 항목을 전자우편 등의 방법으로 고객에게 알립니다. 이를 위해 고객은 정확한 연락처 정보를 제공/수정하여야 합니다.",
];

const personalInfoPolicy4 = [
  "[개인정보의 제3자에 대한 제공]",
  '1. 회사는 이용자들의 개인정보를 "개인정보의 수집 항목 및 이용목적"에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 다만 다음의 경우에는 이용자의 개인정보를 제3자에게 제공(공유를 포함)할 수 있습니다.',
  "- 이용자가 개인정보의 수집 및 이용에 대한 동의와 별도로 제3자 제공에 사전 동의한 경우: 회사는 이용자에게 개인정보를 제공받는 자의 성명과 - 연락처, 제공받는 자의 개인정보 이용 목적, 제공하는 개인정보의 항목, 제공받는 자의 개인정보 보유 및 이용 기간, 동의 거부권이 존재한다는 사실 및 동의 거부에 따른 불이익의 내용을 미리 알립니다.",
  "- 법률규정이 있거나 법령상 의무준수를 위해 불가피한 경우",
  "- 수사기관이 수사목적을 위해 관계법령이 정한 절차를 거쳐 요구하는 경우",
  "- 통계작성 및 학술연구 등의 목적을 위해 필요한 경우로서 특정 개인 또는 회원을 알아볼 수 없는 형태로 개인정보를 제공하는 경우",
];

const personalInfoPolicy5 = [
  "[개인정보 처리의 위탁]",
  "1. 회사는 향상된 서비스를 제공하기 위해 개인정보 처리를 위탁하여 처리할 수 있습니다. 위탁업무를 하는 경우에는 다음의 내용을 이용자에게 알리고 동의를 받으며, 어느 하나의 사항이 변경된 경우에도 동일합니다. 보다 나은 서비스 제공을 위해 국내에 위탁한 개인정보 수탁업체는 다음 과 같습니다",
  "2. 회사는 서비스 제공의 안정성과 최신 기술을 이용자에게 제공하기 위해 개인정보를 위탁하고 있으며, 이용자로부터 취득 또는 생성한 개인정보를 AWS (Amazon Web Services Inc.) 가 보유하고 있는 데이터베이스(물리적 저장 장소: 한국)에 저장합니다. AWS는 해당 서버의 물리적인 관리만을 행하고, 이용자의 개인정보에 접근할 수 없습니다.",
  "3. 회사는 위탁계약 체결 시 위탁업무 수행목적 외 개인정보 처리금지, 기술적∙관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리∙감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는 지를 감독하고 있습니다. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리방침을 통하여 공개합니다.",
];

const personalInfoPolicy6 = [
  "[개인정보주체의 권리와 의무 및 그 행사방법]",
  "1. 이용자는 언제든지 회사 서비스 상에 등록되어 있는 자신의 개인정보, 회사가 이용자의 개인정보를 이용하거나 제3자에게 제공한 현황, 회사에게 개인정보 수집, 이용, 제공 등의 동의를 한 현황에 대하여 열람이나 제공을 요구할 수 있고, 오류가 있는 경우에는 그 정정을 요구할 수 있으며, 삭제 내지 가입해지를 요구할 수도 있습니다.",
  "2. 이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 ‘개인정보변경’(또는 ‘이용자정보수정’ 등)을, 가입해지(동의철회)를 위해서는 “이용자탈퇴”를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.",
  "3. 이 경우에 회사는 지체 없이 그 개인정보를 조사하여 이용자의 요구에 따라 정정, 삭제 등 필요한 조치를 한 후 그 결과를 이용자에게 알립니다. 회사는 필요한 조치를 할 때까지는 해당 개인정보를 이용하거나 제공하지 않습니다.",
  "4. 이용자는 언제든지 회사에 자신의 개인정보 처리의 정지를 요구할 수 있으며, 이 경우에 회사는 지체 없이 그 요구에 따라 개인정보 처리의 전부 내지 일부를 정지하며, 처리가 정지된 개인정보에 대하여 지체 없이 그 개인정보의 파기 등 필요한 조치를 취합니다.",
];

const personalInfoPolicy7 = [
  "[개인정보 파기절차 및 방법]",
  "회사는 개인정보 보유기간의 경과, 개인정보의 수집 및 이용목적 달성 등 그 개인정보가 불필요하게 되었을 때에는 지체 없이 파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.",
  "1. 파기절차",
  "회사는 개인정보의 파기에 관한 사항을 기록하고 관리하며, 파기는 개인정보 보호책임자의 책임하에 수행되며, 개인정보 보호책임자는 파기 결과를 확인합니다. 회사는 다른 법령에 따라 보존해야 하는 경우에는 예외적으로 이용자의 개인정보를 파기하지 않을 수 있습니다.",
  "2. 파기방법",
  "종이나 그 밖의 기록매체에 저장된 개인정보는 파쇄하거나 소각합니다. 전자적 파일 형태로 저장된 개인정보는 복원이 불가능한 방법(또는 기록을 재생할 수 없는 기술적 방법)으로 영구 삭제합니다.",
  "3. 미파기 정보의 보존방법",
  "회사는 법령에 따라 개인정보를 파기하지 않고 보존하는 경우에 해당 개인정보 또는 개인정보파일을 다른 개인정보와 분리하여 저장 관리합니다. 회사는 별도 DB로 옮긴 개인정보를 법률에 의한 경우가 아니고서는 보유하는 이외의 다른 목적으로 이용하지 않습니다.",
];

const personalInfoPolicy8 = [
  "[개인정보 보호책임자 및 담당부서]",
  "회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자 및 담당부서를 지정하고 있습니다. 이용자는 회사의 서비스를 이용하면서 발생한 모든 개인정보보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및 처리합니다.",
];

const personalInfoPolicy9 = [
  "[개인정보 보호 책임자]",
  "- 개인정보 보호 책임자: 탁틴",
  "- 담당부서/직위: Product Team/차장",
  "- 이메일 주소: product@heringsglobal.com",
];

const personalInfoPolicy10 = [
  "[개인정보 처리방침의 변경]",
  "회사는 개인정보처리방침에 대한 변경이 있을 경우에는 개정 개인정보처리방침의 시행일로부터 최소 7일 전에 서비스(장루관리 앱/웹)의 공지사항 또는 이메일을 통해 고지합니다. 또한, 필요 시 이용자 동의를 다시 받을 수도 있습니다.",
];

const personalInfoPolicy11 = [
  "[개인정보의 안전성 확보조치]",
  "회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다.",

  "1. 이용자의 개인정보는 비밀번호에 의해 보호되며 파일 및 전송데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.",
  "2. 회사는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.",
  "3. 해킹 등 외부침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석시스템 등을 이용하여 보안에 만전을 기하고 있습니다.",
  "4. 회사는 이용자의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있으며 이용자를 직접 상대로 하여 마케팅 업무를 수행하는 자, 개인정보 보호책임자 및 담당자 등 개인정보관리업무를 수행하는 자, 기타 업무상 개인정보의 취급이 불가피한 자 외에는 접근을 엄격히 제한하고 담당직원에 대한 수시 교육을 통하여 본 정책의 준수를 강조하고 있습니다.",
];

const personalInfoPolicy12 = [
  "[개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항]",
  "회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를 저장하고 수시로 불러오는 ’쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.",
  "1. 쿠키의 사용 목적: 회사의 서비스와 웹 사이트들에 대한 이용자들의 방문 및 이용형태, 인기 검색어, 보안접속 여부, 이용자 규모 등을 파악하여 이용자에게 최적화된 정보 제공을 위하여 사용합니다.",
  "2. 쿠키의 설치/운영 및 거부:",
  "1) 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 단, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수 있습니다.",
  "2) 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.",
];

const personalInfoPolicy13 = [
  "부 칙",
  "1. 본 방침은 2022년 5월 6일부터 적용됩니다.",
];

const personalInfoPolicy = [
  personalInfoPolicy1,
  personalInfoPolicy2,
  personalInfoPolicy3,
  personalInfoPolicy4,
  personalInfoPolicy5,
  personalInfoPolicy6,
  personalInfoPolicy7,
  personalInfoPolicy8,
  personalInfoPolicy9,
  personalInfoPolicy10,
  personalInfoPolicy11,
  personalInfoPolicy12,
  personalInfoPolicy13,
];

export { servicePolicy, personalInfoPolicy };
