import React from "react";
import MyInfoGoback from "./MyInfoGoback";
import ToggleBtn from "./ToggleBtn";
const Notification = ({ showNotification }) => {
  return (
    <div className="w-full fixed top-0 left-0 z-1">
      <div className="max-w-md h-screen flex flex-col mx-auto text-gray-600 antialiased bg-[#FAFCFF] relative">
        <MyInfoGoback showNotification={showNotification} title={"알림설정"} />
        <div className="mt-[76px]  px-[16px]">
          <div className="flex items-center justify-between">
            <span className="subtitle_2">전체 알림</span>
            <ToggleBtn />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
