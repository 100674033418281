import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BtnWhiteBack } from "../../../admin_components/Buttons";
import Header2 from "../../../admin_components/Header/Header2";
import { Loading } from "../../../admin_components/Loading";
import commonUrl, { api } from "../../../../../data/common";
import { handlingError } from "../../../admin_components/Error";

const HealthDiary = () => {
  const location = useLocation();
  const state = location.state;
  const [loading, setLoading] = useState(false);
  const [diary, setDiary] = useState({
    weight: 0,
    writeDate: "",
    toilet13: 0,
    toilet23: 0,
    totalWater: 0,
    totalToilet: 0,
    meal: "",
    memo: "",
    imageURL: [],
  });

  const handleData = (data) => {
    let newTotalWater = data.totalWater;
    let newTotalToilet = data.totalToilet;

    setDiary({
      ...data.diary,
      totalWater: newTotalWater,
      totalToilet: newTotalToilet,
    });
  };

  /*** 첫 랜더시 정보 불러오기***/
  useEffect(() => {
    // 비동기 함수
    async function fetchData() {
      setLoading(true);
      const res = await api({
        method: "get",
        url: commonUrl.diary + state.data.id,
      }).catch((error) => {
        handlingError(error);
      });
      console.log(res.data, ">>> Axios -> diary + id");
      if (res.data.ok) {
        // UI에 맞게 데이타 가공
        handleData(res.data);
      }
      setLoading(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header2
        title={[
          { title: "장루점검", url: "/admin/main/check" },
          {
            title: "대상자 상세보기",
            url: "/admin/main/check/detail",
            state: state.data.detailPageId
              ? { data: { id: state.data.detailPageId, prevPath: undefined } }
              : undefined,
            replace: true,
          },
          { title: "건강 다이어리 상세보기", url: 0 },
        ]}
      >
        <div className="admin w-full m-[32px]">
          <div className="h-[80px] min-h-[80px]"></div>
          {/* 목록 & 뒤로가기 버튼 */}
          <div className="flex items-center gap-x-2 mb-3">
            <BtnWhiteBack
              to={"/admin/main/check/detail"}
              state={{
                // data: {
                id: state.data.detailPageId,
                prevPath: "건강 다이어리",
                // },
              }}
            />
          </div>
          {/* 표 */}
          {loading ? (
            <Loading />
          ) : (
            <div className="flex flex-col p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mb-6 min-w-[992px]">
              <p className="flex font-extrabold text-lg text-[#343B48] leading-[22px] text-[#4E535F] box-border mb-4">
                건강 다이어리 상세보기
              </p>
              <div className="grid grid-cols-4 text-center ">
                {/* 첫, 두번째 줄 */}
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  체중(kg)
                </label>
                <label className="flex justify-start items-center px-6 py-[7px] border border-[#E5E6E7] font-normal text-base leading-[22px] text-[#020A1B] box-border">
                  {`${diary.weight}kg`}
                </label>
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  작성일
                </label>
                <label className="flex justify-start items-center px-6 py-[7px] border border-[#E5E6E7] font-normal text-base leading-[22px] text-[#020A1B] box-border">
                  {diary.writeDate}
                </label>
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  수분 섭취량(ml)
                </label>
                <label className="flex justify-start items-center px-6 py-[7px] border border-[#E5E6E7] font-normal text-base leading-[22px] text-[#020A1B] box-border col-span-3">
                  {`${diary.totalWater}ml`}
                </label>
                {/* 세, 네번째 줄 */}
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  배변량(ml)
                </label>
                <label className="flex justify-start items-center px-6 py-[7px] border border-[#E5E6E7] font-normal text-base leading-[22px] text-[#020A1B] box-border col-span-3">
                  {`2/3 비움 ${diary.toilet23}회 + 1/3 비움 ${
                    diary.toilet13
                  }회 = 총 ${diary.toilet23 + diary.toilet13}회, ${
                    diary.totalToilet
                  }ml`}
                </label>
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  식사 기록
                </label>
                <label className="flex justify-start items-center px-6 py-[7px] border border-[#E5E6E7] font-normal text-base leading-[22px] text-[#020A1B] box-border col-span-3">
                  {diary.meal}
                </label>
                {/* 다섯번째 줄 */}
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  메모
                </label>
                <div className="flex flex-col justify-center items-start border border-[#E5E6E7] font-normal col-span-3">
                  <label className="flex justify-start items-center px-6 py-[7px] font-normal text-base leading-[22px] text-[#020A1B] box-border">
                    {diary.memo}
                  </label>

                  {diary.imageURL.length !== 0 ? (
                    <>
                      <div className="h-[1px] w-full bg-[#E5E6E7]"></div>
                      <div className="flex flex-wrap w-full pt-[15px] pl-[15px] overflow-hidden">
                        {diary.imageURL.map((el, idx) => {
                          return (
                            <div
                              className="box-border mr-[15px] mb-[15px] rounded-[5px] w-full max-w-[110px] max-h-[110px]"
                              key={idx}
                            >
                              <img
                                className="border border-[#E5E6E7] rounded-[3px] box-border max-w-[110px] max-h-[110px] min-w-[110px] min-h-[110px] object-cover"
                                src={
                                  commonUrl.networkImg + encodeURIComponent(el)
                                }
                                alt={`${idx + 1}.jpg`}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
      </Header2>
    </>
  );
};

export default HealthDiary;
