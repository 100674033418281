import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Document,
  Page,
  Text,
  View,
  Link,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
} from "@react-pdf/renderer";
import Pretendard from "../../../common/font/Pretendard-Regular.otf";
import commonUrl, { api } from "../../../data/common";
import { convertRole } from "./ConvertAdmin";
import dayjs from "dayjs";
import { handlingError } from "./Error";

Font.register({
  family: "Pretendard",
  src: Pretendard,
  fontStyle: "normal",
  fontWeight: "normal",
});

const pdfStyle = StyleSheet.create({
  page: {
    padding: "24px 20px",
    fontFamily: "Pretendard",
  },
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
    display: "flex",
  },
  bigTitle: {
    display: "flex",
    alignItems: "center",
    padding: "4px 16px",
    fontSize: "12px",
    fontWeight: "400",
    color: "#343B48",
    backgroundColor: "#9DC4FA",
    borderWidth: "1px",
    borderColor: "#E5E6E7",
    lineHeight: "1.35rem",
  },
  smallTitle: {
    display: "flex",
    width: "25%",
    minWidth: "25%",
    alignItems: "center",
    padding: "4px 16px",
    fontSize: "12px",
    fontWeight: "400",
    color: "#4E535F",
    backgroundColor: "#F2F6FC",
    borderWidth: "1px",
    borderColor: "#E5E6E7",
    lineHeight: "1.35rem",
  },
  smallTitle_pink: {
    flex: "1",
    width: "33.33%",
    padding: "4px 16px",
    fontSize: "12px",
    color: "#4E535F",
    backgroundColor: "#FCEEBA",
    borderWidth: "1px",
    borderColor: "#E5E6E7",
    lineHeight: "1.35rem",
  },
  content_small: {
    width: "25%",
    minWidth: "25%",
    padding: "4px 16px",
    fontSize: "12px",
    color: "#020A1B",
    borderWidth: "1px",
    borderColor: "#E5E6E7",
    backgroundColor: "#FAFBFC",
    lineHeight: "1.35rem",
  },
  content_middle: {
    width: "37.5%",
    minWidth: "37.5%",
    padding: "4px 16px",
    fontSize: "12px",
    color: "#020A1B",
    borderWidth: "1px",
    borderColor: "#E5E6E7",
    backgroundColor: "#FAFBFC",
    lineHeight: "1.35rem",
  },
  content_long: {
    width: "75%",
    padding: "4px 16px",
    fontSize: "12px",
    color: "#020A1B",
    borderWidth: "1px",
    borderColor: "#E5E6E7",
    fontFamily: "Pretendard",
    backgroundColor: "#FAFBFC",
    lineHeight: "1.35rem",
  },
  content_full: {
    width: "100%",
    padding: "4px 16px",
    fontSize: "12px",
    color: "#020A1B",
    borderWidth: "1px",
    borderColor: "#E5E6E7",
    fontFamily: "Pretendard",
    lineHeight: "1.35rem",
    backgroundColor: "#FAFBFC",
  },
  image: {
    width: "46.38px",
    height: "46.38px",
    objectFit: "cover",
  },
  file: {
    width: "100%",
    padding: "4px 16px",
    fontSize: "12px",
    color: "black",
    borderWidth: "1px",
    borderColor: "#E5E6E7",
    fontFamily: "Pretendard",
  },
});

function PDF() {
  const location = useLocation();
  const ids = location.pathname.split("/");
  const [, , , , surveyid, checkid, replyid] = ids;
  const [pdfData, setPdfData] = useState({});

  // 데이터 UI용으로 가공하는 함수
  const handleData = (data) => {
    const { jangruSurvey, jangruReplyNurse, jangruCheck, understanding } = data;

    let newRRN = "";
    let newReply = "";

    // 주민등록번호
    let firstNum = 1;
    if (jangruSurvey.patient.birth.substring(0, 2) === "19") {
      if (jangruSurvey.patient.gender === "M") firstNum = 1;
      else firstNum = 2;
    } else if (jangruSurvey.patient.birth.substring(0, 2) === "20") {
      if (jangruSurvey.patient.gender === "M") firstNum = 3;
      else firstNum = 4;
    }
    newRRN = `${jangruSurvey.patient.birth
      .substring(2, 10)
      .split("-")
      .join("")}-${firstNum}*******`;

    // 서비스 제공 내용
    const serviceList = [
      "장루 기능상태 확인",
      "올바른 장루 교환 수행여부 확인",
      "장루 및 피부상태 점검",
      "합병증 예방 관리​",
      "병원 방문이 필요한 상황",
      "식생활/영양/체중 관리",
      "일상생활 관리​",
      "정신건강 관리",
      `${jangruReplyNurse?.serviceText}`,
    ];

    // 주요 문제 현황
    const symptomList = [
      "",
      "문제없음",
      "장루교체가 능숙하지 않음",
      "일상생활·사회활동 어려움",
      "식생활·영양관리 어려움",
      "대변 누출이 잦음",
      "심리적 어려움",
    ];

    // 장루 합병증
    const jangruComplicationList = [
      "문제 없음",
      "출혈",
      "탈출",
      "탈장",
      "괴사",
      "허혈",
      "협착",
      "함몰",
      "장폐쇄",
      "누관형성",
      "장점막피부경계분리",
      `${jangruCheck.jangruComplicationsText}`,
    ];

    // 피부 합병증
    const skinComplicationsList = [
      "문제 없음",
      "자극성접촉 피부염",
      "알러지성 피부염",
      "모낭염",
      "칸디다즘",
      "괴저성 높이증",
      "장루주위 정맥류",
      "연화/침윤",
      "표피비대",
      "물리적 손상",
      `${jangruCheck.skinComplicationsText}`,
    ];

    // 이해도
    const understandingList = [
      "매우 낮음",
      "낮음",
      "보통",
      "높음",
      "매우 높음",
    ];

    // 상담내용 html parser
    const replyList = [];
    const htmlparser2 = require("htmlparser2");
    const parser = new htmlparser2.Parser({
      onopentag(tagname) {
        if (tagname === "br") {
          replyList.push("\n");
        }
      },
      ontext(text) {
        replyList.push(text.trim());
      },
      onclosetag(tagname) {
        if (tagname === "p") replyList.push("\n");
      },
    });
    parser.write(jangruReplyNurse.reply);
    parser.end();

    newReply = replyList.join(" ");

    const newData = {
      // 환자 정보
      name: jangruSurvey.patient.name, // 이름
      birthYear: jangruSurvey.patient.birth.substring(0, 4), // 태어난 년도
      rRNum: newRRN, // 주민등록번호
      startDate: dayjs(jangruSurvey.patient.startDate).format("YYYY.MM.DD"),
      endDate: jangruSurvey.patient.endDate.replace(/-/gi, "."), // 종료일자

      // 장루 관련 정보
      jangruSurgeryDate: jangruSurvey.patient.jangruSurgeryDate.replace(
        /-/gi,
        "."
      ), // 조성술 시행일
      jangruType: jangruSurvey.patient.jangruType, // 장루 종류
      jangruClosedSurgeryOX:
        jangruSurvey.patient.jangruClosedSurgeryOX === "O" ? "유" : "무", // 폐쇄술 시행여부

      // -월 -회차 관리
      jangruSurveyOrder: jangruSurvey.index,
      adminName: jangruReplyNurse.admin.name, // 관리자 이름
      adminRole: convertRole(jangruReplyNurse.admin.role), // 관리자 구분
      replyCreatedAt: dayjs(jangruReplyNurse.createdAt).format("YYYY.MM.DD"), // 시행일자
      client: jangruSurvey.writer, // 관리 제공 대상
      weight: jangruSurvey.weight, // 체중
      changeDate: jangruSurvey.changeDate.replace(/-/gi, "."), // 장루 교환일
      changeCycle: jangruSurvey.changeCycle, // 장루 교환주기
      symptom: jangruSurvey.symptom
        .map((el) => symptomList[el])
        .concat(jangruSurvey?.symptomNote)
        .filter((el) => el !== "")
        .join(", "), // 주요문제 현황
      jangruComplications: jangruCheck.jangruComplications
        .map((el) => jangruComplicationList[el])
        .join(", "), // 장루 합병증
      skinComplications: jangruCheck.skinComplications
        .map((el) => skinComplicationsList[el])
        .join(", "), // 피부 합병증
      service: jangruReplyNurse.service.map((el) => serviceList[el]).join(", "), // 서비스 제공 내용
      understanding: understandingList[understanding], //관리내용 이해 정도
      serviceText: jangruReplyNurse.serviceText
        ? jangruReplyNurse.serviceText
        : "", // 서비스 제공내용의 기타 기재사항
      imageURL: jangruSurvey.imageURL, // 사진
      imageText: jangruSurvey.imageText ? jangruSurvey.imageText : "", // 사진 설명

      // 상담 내용
      questionCreatedAt: dayjs(jangruSurvey.createdAt).format(
        "YYYY.MM.DD A hh:mm"
      ), // 문의 시간
      question:
        jangruSurvey.question || jangruSurvey.question !== ""
          ? jangruSurvey.question
          : "문의 내용 없음",
      replyName: jangruReplyNurse.admin.name, // 관리자 이름
      replyRole: convertRole(jangruReplyNurse.admin.role), // 관리자 구분
      replyTime: dayjs(jangruReplyNurse.createdAt).format("YYYY.MM.DD A hh:mm"),
      reply: jangruReplyNurse.reply ? newReply : "", // 상담 내용
      attachedFileURL: jangruReplyNurse.attachedFileURL, //첨부 파일
    };
    return newData;
  };

  // 첫랜더시 데이타 가져오기
  useEffect(() => {
    async function fetchData() {
      const res = await api
        .post(commonUrl.jangruReply + "forReport", {
          surveyid,
          checkid,
          replyid,
        })
        .catch((error) => {
          handlingError(error);
        });
      console.log(res.data, ">>> jangruReply + forReport");
      if (res.data.ok) {
        let newData = handleData(res.data);
        // console.log(newData, ">>> 받아서 가공한 데이타");
        setPdfData(newData);
      }
      // axios 실패시
      else {
        alert("pdf를 표시할 수 없습니다.");
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // PDFViewer: 브라우저에 pdf 뷰어를 랜더링 함
    <PDFViewer style={pdfStyle.viewer} showToolbar={true}>
      <Document>
        <Page size="A4" style={pdfStyle.page}>
          <View style={{ display: "flex", flexDirection: "column" }}>
            <Text style={pdfStyle.bigTitle}>
              암환자 재택의료 시범사업
              {`\n`}
              환자 관리료 점검 보고서
            </Text>
            {/* 환자 정보 */}
            <Text style={pdfStyle.bigTitle}>환자 정보</Text>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={pdfStyle.smallTitle}>환자 성명</Text>
              <Text style={pdfStyle.content_small}>{pdfData.name}</Text>
              <Text style={pdfStyle.smallTitle}>주민등록번호</Text>
              <Text style={pdfStyle.content_small}>{pdfData.rRNum}</Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={pdfStyle.smallTitle}>등록일자</Text>
              <Text style={pdfStyle.content_small}>{pdfData.startDate}</Text>
              <Text style={pdfStyle.smallTitle}>사업종료일자</Text>
              <Text style={pdfStyle.content_small}>{pdfData.endDate}</Text>
            </View>
            {/* 장루 관련 정보 */}
            <Text style={pdfStyle.bigTitle}>장루 관련 정보</Text>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={pdfStyle.smallTitle}> 장루 조성술 시행일</Text>
              <Text style={pdfStyle.content_long}>
                {pdfData.jangruSurgeryDate}
              </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={pdfStyle.smallTitle}>장루 종류</Text>
              <Text style={pdfStyle.content_long}>{pdfData.jangruType}</Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={pdfStyle.smallTitle}>
                장루폐쇄술(복원술) 시행 유무
              </Text>
              <Text style={pdfStyle.content_long}>
                {pdfData.jangruClosedSurgeryOX}
              </Text>
            </View>
            {/* ~~~~~ 회차 관리 */}
            <Text style={pdfStyle.bigTitle}>
              {pdfData.replyCreatedAt
                ? `${Number(pdfData.replyCreatedAt.substring(5, 7))}월 ${
                    pdfData.jangruSurveyOrder
                  }회차 관리`
                : ""}
            </Text>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={pdfStyle.smallTitle}> 관리자 정보</Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "75%",
                }}
              >
                <Text
                  style={pdfStyle.content_middle}
                >{`${pdfData.adminRole}`}</Text>
                <Text
                  style={pdfStyle.content_middle}
                >{`${pdfData.adminName}`}</Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={pdfStyle.smallTitle}> 시행일자</Text>
              <Text style={pdfStyle.content_long}>
                {pdfData.replyCreatedAt}
              </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={pdfStyle.smallTitle}> 관리방법</Text>
              <Text style={pdfStyle.content_long}>
                환자용 앱과 의료진용 웹을 활용한 관리
              </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={pdfStyle.smallTitle}> 관리제공 대상</Text>
              <Text style={pdfStyle.content_long}>{pdfData.client}</Text>
            </View>
            {/* 임상 정보 */}
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={pdfStyle.smallTitle}> 임상정보</Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "75%",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={pdfStyle.smallTitle}> 체중</Text>
                  <Text style={pdfStyle.content_long}>{pdfData.weight}kg</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={pdfStyle.smallTitle}> 장루 교환일</Text>
                  <Text
                    style={Object.assign(pdfStyle.content_small, {
                      flex: "1",
                    })}
                  >
                    {pdfData.changeDate}
                  </Text>
                  <Text style={pdfStyle.smallTitle}> 장루 교환주기</Text>
                  <Text
                    style={Object.assign(pdfStyle.content_small, {
                      flex: "1",
                    })}
                  >
                    {pdfData.changeCycle}일
                  </Text>
                </View>
              </View>
            </View>
            {/* 주요 문제 현황부터 */}
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={pdfStyle.smallTitle}> 주요 문제 현황</Text>
              <Text style={pdfStyle.content_long}>{pdfData.symptom}</Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={pdfStyle.smallTitle}> 장루 합병증</Text>
              <Text style={pdfStyle.content_long}>
                {pdfData.jangruComplications}
              </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={pdfStyle.smallTitle}> 피부 합병증</Text>
              <Text style={pdfStyle.content_long}>
                {pdfData.skinComplications}
              </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={pdfStyle.smallTitle}> 서비스 제공 내용</Text>
              <Text style={pdfStyle.content_long}>{pdfData.service}</Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={pdfStyle.smallTitle}> 관리내용 이해정도</Text>
              <Text style={pdfStyle.content_long}>{pdfData.understanding}</Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={pdfStyle.smallTitle}> 기타 기재사항</Text>
              <Text style={pdfStyle.content_long}>{pdfData.serviceText}</Text>
            </View>
            <View
              wrap={false}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Text style={pdfStyle.smallTitle}> 장루 / 피부 사진</Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "75%",
                  padding: "6px",
                  borderWidth: "1px",
                  borderColor: "#E5E6E7",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  {pdfData.imageURL
                    ? pdfData.imageURL.map((el, idx) => {
                        return (
                          <Image
                            key={idx}
                            src={{
                              uri: `${
                                commonUrl.networkImg + encodeURIComponent(el)
                              }`,
                              method: "GET",
                            }}
                            style={{
                              width: "60px",
                              height: "60px",
                              margin: "5px",
                              objectFit: "cover",
                            }}
                          />
                        );
                      })
                    : null}
                </View>
                <Text
                  style={{
                    fontSize: "12px",
                    color: "black",
                    margin: "5px",
                    width: "100%",
                  }}
                >
                  {pdfData.imageText}
                </Text>
              </View>
            </View>
            {/* 상담 내용 */}
            <Text style={pdfStyle.bigTitle} break>
              상담 내용
            </Text>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text
                style={Object.assign(pdfStyle.smallTitle, {
                  flex: "1",
                })}
              >
                문의 내용
              </Text>
              <Text
                style={Object.assign(pdfStyle.smallTitle, {
                  flex: "1",
                })}
              >
                {pdfData.questionCreatedAt}
              </Text>
            </View>
            <Text style={pdfStyle.content_full}>
              {pdfData.question ? pdfData.question : "작성 내용 없음"}
            </Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Text style={pdfStyle.smallTitle_pink}>답변 내용</Text>
              <Text
                style={pdfStyle.smallTitle_pink}
              >{`${pdfData.adminName} ${pdfData.adminRole}`}</Text>
              <Text style={pdfStyle.smallTitle_pink}>{pdfData.replyTime}</Text>
            </View>
            <Text style={pdfStyle.content_full}>{pdfData.reply}</Text>
            {pdfData.attachedFileURL && pdfData.attachedFileURL.length !== 0 ? (
              <Text style={pdfStyle.file}>
                {` 첨부파일:  `}
                {pdfData.attachedFileURL.map((el, idx) => {
                  const url = commonUrl.networkImg + encodeURIComponent(el);
                  return (
                    <View key={idx}>
                      <Link
                        src={url}
                        style={{
                          fontSize: "12px",
                          color: "black",
                          fontFamily: "Pretendard",
                          textDecoration: "underline",
                          cursor: "pointer",
                          paddingLeft: "15px",
                          lineHeight: "1.5rem",
                        }}
                      >
                        {el}
                      </Link>
                      <Text>{` `} </Text>
                    </View>
                  );
                })}
              </Text>
            ) : null}
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default PDF;
