import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMainContext } from "../../../../contexts/Provider";
import MainIcons from "../../mobilecomponent/MainIcons";
const CommunityNotice = () => {
  const leftarrow = "/images/mo/leftarrow.svg";
  const navigate = useNavigate();
  const { loginInput } = useMainContext();
  useEffect(() => {
    let pageOpen = false;
    if (!pageOpen) {
      if (
        loginInput.id === null ||
        loginInput.id === undefined ||
        loginInput.id === ""
      ) {
        navigate("/main", { replace: true });
      }
    }
    return () => {
      pageOpen = true;
    };
  }, [loginInput.id, navigate]);
  const goCommu = () => {
    navigate("/community");
  };
  return (
    <>
      <div className="wordbreak-keepall">
        <div className="fixed top-0 left-0 bg-[#FAFCFF] w-full border-b-[1px] px-[8px] flex justify-between items-center">
          <img
            className="inline-block cursor-pointer"
            src={leftarrow}
            alt="leftimg"
            onClick={goCommu}
          />
          <h1>공지사항</h1>
          <span className="w-[48px] h-[48px]"></span>
        </div>
        <div className="mt-[48px] px-[16px] py-[24px] border-b">
          <span className="caption_2 inline-block py-[2px] px-[6px] rounded-sm bg-[#5FC17B] text-white">
            공지사항
          </span>
          <div className="subtitle_1_700 text-black leading-6 pt-[4px]">
            커뮤니티 이용안내입니다.
          </div>
        </div>
        <div className="py-[24px] px-[16px]">
          <div>
            본 커뮤니티는 환우 여러분의 공간입니다. 서로에게 힘이 되는 좋은
            글이나 정보를 공유해보세요.
          </div>
          <div className="pt-[20px]">
            상처가 되는 말이나 불필요한 게시글은 운영자 권한에 의해 삭제될 수
            있습니다.
          </div>
          <div className="pt-[20px]">
            또한 의학적인 질문에 답변해 드릴 수 없습니다. 의학적인 질문에
            대해서는 장루 정기 점검 때 문의해 주세요.
          </div>
          <div className="pt-[20px]">
            커뮤니티에서 상처가 되는 말이나 불필요한 게시글은 운영자 권한에 의해
            삭제될 수 있습니다. 커뮤니티에서 부적절하다고 판단되는 게시글이나
            댓글을 발견하시면 고객센터로 말씀해 주시면 확인 후 처리하도록
            하겠습니다.
          </div>
          <div className="pt-[20px]">
            커뮤니티에 반복적으로 불필요한 게시글이나 댓글을 게시할 경우 운영자
            권한에 의해 앱 사용이 정지될 수 있사오니 이점 유념하여 주시기
            바랍니다.
          </div>
          <div className="pt-[20px]">
            모두가 기분 좋게 커뮤니티를 이용할 수 있도록 에티켓을 지켜 주세요.
          </div>
          <div>감사합니다.</div>
          <div className="pt-[20px]">■ 고객센터: product@heringsglobal.com</div>
          <div className="h-[64px]"></div>
        </div>
      </div>

      <MainIcons />
    </>
  );
};

export default CommunityNotice;
