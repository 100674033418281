import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import dayjs from "dayjs";

import common, { api } from "../../../../data/common";
import "swiper/css";
import { useMainContext } from "../../../../contexts/Provider";
import { MainStomaText } from "./data/StomaManageText";
/*components*/
import MainIcons from "../../mobilecomponent/MainIcons";
import StomaManage from "./components/StomaManage";
import NoPeriodModal from "./components/NoPeriodModal";
import NoReplyModal from "./components/NoReplyModal";
import MainBanner from "./components/MainBanner";
import MainLoading from "./components/MainLoading";

SwiperCore.use([Autoplay, Pagination]);

const Main = () => {
  const {
    setLoginInput,
    loginInput,
    alarmList,
    setAlarmList,
    setSelectedImages,
    setFileArray,
    setFiles,
    canSurvey,
    setCanSurvey,
    noPeriod,
    setNoPeriod,
    noReply,
    setNoReply,
    surveyInfo,
    setSurveyInfo,
  } = useMainContext();
  const navigate = useNavigate();
  const bellicon = "/images/mo/bell.svg";
  const bellonicon = "/images/mo/bellon.svg";
  const sirenicon = "/images/mo/siren.svg";
  const moreicon = "/images/mo/more_black.svg";
  const checkImg = "/images/mo/checklist.svg";
  const healthdiaryImg = "/images/mo/healthdiary.svg";
  const scrollRef = useRef();

  //임의 토큰
  // const FCM =
  //   "f31qVk4RneO1l5lctujES:APA91bGEYsWnkqQiZsw1tveirbcnaXggLJY5WHV2EJfE5SwhaMj_PC5CoeUa7nSYD0eA13ao90SVJgnkOITDiaeqKdFXxXpHKhZ0eobbKVJ0G_DyahQSlu0x2c-AEtSRi7PtR0eFvEqk";
  const [pageScrollY, setPageScrollY] = useState();
  const [passedDays, setPassedDays] = useState();
  const [stomaManage, setStomaManage] = useState(false);
  const [noReplyModal, setNoReplyModal] = useState(false);
  const [noPeriodModal, setNoPeriodModal] = useState(false);
  const [hasNews, setHasNews] = useState([]);
  const [showMain, setShowMain] = useState(false);
  const filterread = alarmList.filter((el) => el.read === 0);

  const showStomaManage = () => {
    setStomaManage(!stomaManage);
  };

  const pageOnScroll = () => {
    const scrollTop = scrollRef.current.scrollTop;
    setPageScrollY(scrollTop);
  };

  useEffect(() => {
    // alert(canSurvey);
    // alert(noReply);
    window.flutter_inappwebview?.callHandler("mainLoaded");

    const getPatient = () => {
      api
        .get(common.baseURL + "patient/Id/" + loginInput.id)
        .then((res) => {
          let today = dayjs();
          let expired_at = dayjs(new Date(res.data.patient.jangruSurgeryDate));
          let alreadyPass = -Math.floor(expired_at.diff(today, "day", true));
          setPassedDays(alreadyPass);
          if (res.data.ok === true && res.data.patient.agree === true) {
            getPatientAlarm();
            setLoginInput({
              patientID: res.data.patient.patientID,
              id: res.data.patient.id,
              name: res.data.patient.name,
              nickName: res.data.patient.nickName,
              createdAt: res.data.patient.createdAt,
              belongid: res.data.patient.belong.id,
              fcmToken: res.data.patient.fcmToken,
              isLoggedIn: true,
            });
            console.log(res.data);
            if (res.data.surveyId !== undefined && res.data.surveyId !== null) {
              setSurveyInfo({ ...surveyInfo, id: res.data.surveyId });
              //survey가 nursereply가 있는지 확인
              api
                .post(common.baseURL + "jangruReply/forPatient", {
                  id: res.data.surveyId,
                })
                .then((postres) => {
                  //장루체크가 없을경우 message에 jangruCheck 없다, 장루리플이없을경우 message에 jangrureply없다
                  if (
                    postres.data.ok === true &&
                    postres.data.jangruCheck !== undefined &&
                    postres.data.jangruReplyNurse !== undefined
                  ) {
                    //   장루 답변 있을경우
                    setNoReply(false);
                    setSurveyInfo({
                      ...surveyInfo,
                      createdAt: dayjs(postres.data.jangruSurveyCreated).format(
                        "YYYY-MM-DD"
                      ),
                    });
                    console.log(postres.data.jangruSurveyCreated);
                  } else {
                    //장루 답변 없을경우
                    setNoReply(true);
                  }
                })
                .catch((error) => console.log(error));
            } else if (res.data.canSurvey === true) {
              //장루 서베이가 작성기간내에(장루점검기준 3~5일) 작성된것이 없을 경우
              console.log("서베이가 아직 작성되지 않음");
              setCanSurvey(true);
            } else {
              //장루 점검 기간이 아닐경우
              setNoPeriod(true);
              // setSendPeriodDay(res.data.patient.surveyDay);
              // setRecentSurveyId(res.data.recentSurveyId);
              setSurveyInfo({
                ...surveyInfo,
                date: res.data.patient.surveyDay,
                recentSurveyId: res.data.recentSurveyId,
              });
            }
            setShowMain(true);
          } else {
            navigate("/login");
          }
        })
        .catch((error) => console.log(error));
    };

    const getPatientAlarm = () => {
      api.get(common.baseURL + "alarm/pid/" + loginInput.id).then((res) => {
        if (res.data.ok === true) {
          const filterAlarmList = res.data.alarmList.filter(
            (list) => list.read === 0
          );
          setAlarmList(filterAlarmList);
        }
      });
    };

    const getNews = () => {
      api
        .get(common.baseURL + "news/inTerm")
        .then((res) => {
          if (res.data.ok === true) {
            console.log(res, ">>>>hasnews??");
            setHasNews(res.data.newsList);
          }
        })
        .catch((error) => console.log(error));
    };

    // if (loginInput.id === "") {
    //   navigate("/login", {
    //     state: FCM,
    //   });
    // } else {
    getNews();
    getPatient();
    /*image 공통 컴퍼넌트 : main 진입시 모두 빈값 */
    setSelectedImages([]);
    setFiles([]);
    setFileArray([]);
  }, [loginInput.id, navigate, setAlarmList, setLoginInput]);

  const onClickJangruCheck = () => {
    // 점검기간 아니면 기간알림모달 띄우기,
    // 점검기간인데 점검 안했으면(canSurvey === true) 점검페이지로,
    // 점검기간에 점검완료 했는데 간호사답변 없으면 기다림알람모달 띄우기,
    // 점검기간에 점검, 답변 모두 완료되면 surveyId를 통해 해당 점검기록 보는 페이지로

    if (noPeriod === true) {
      setNoPeriodModal(true);
    } else if (canSurvey === true) {
      navigate("/check");
    } else if (noReply === true) {
      setNoReplyModal(true);
    } else {
      navigate("/report/inspection", {
        state: surveyInfo.createdAt,
      });
    }
  };

  const onClickMyinfoEachnews = () => {
    navigate("/myinfo/eachnews", { state: hasNews[0] });
  };
  const onClickTutorial = () => {
    navigate("/main/tutorial");
  };
  const onClickMore = ({ el }) => {
    navigate("/more", {
      state: {
        title: el.title,
        title2: el.title2,
        btn1: el.btn1,
        btn2: el.btn2,
        btn3: el.btn3,
        btn4: el.btn4,
        btn5: el.btn5,
        btn6: el.btn6,
        svgurl: el.svgurl,
        bigTitle: el.bigTitle,
      },
    });
  };
  console.log(loginInput, "loginInput");
  return (
    <>
      {noPeriodModal ? (
        <NoPeriodModal
          showNOPeriodModal={() => setNoPeriodModal(!noPeriodModal)}
          sendperiodDay={surveyInfo.date}
          recentSurveyId={surveyInfo.recentSurveyId}
        />
      ) : null}
      {noReplyModal ? (
        <NoReplyModal showNOReplyModal={() => setNoReplyModal(!noReplyModal)} />
      ) : null}

      <>
        {showMain ? (
          <div
            className="h-screen bg-[#FAFCFF] relative overflow-y-auto"
            ref={scrollRef}
            onScroll={pageOnScroll}
          >
            <div className="relative bg-[#3f82ed]">
              <div
                className={
                  pageScrollY > 5
                    ? "fixed z-8 top-0 left-0 w-full flex justify-end drop-shadow-sm bg-[#3f82ed] "
                    : "fixed z-8 top-0 left-0 w-full flex justify-end bg-[#3f82ed] "
                }
              >
                <img
                  src={filterread.length > 0 ? bellonicon : bellicon}
                  alt={filterread.length > 0 ? "bellonicon" : "bellicon"}
                  className="inline-block w-[24px] cursor-pointer m-[15px]"
                  onClick={() => navigate("/main/message")}
                />
              </div>
              <div className="pt-[70px] px-[24px] text-white">
                <h1 className="h2_800">{loginInput?.name}님! 안녕하세요.</h1>
                <div className="pt-[12px] subtitle_1_700">
                  <span className="block leading-5">오늘은 장루 수술 후</span>
                  <span className="block leading-5">
                    {passedDays}일째 되는 날입니다.
                  </span>
                </div>
                <div
                  className="rounded-full w-full bg-[#FAFCFF] text-[#676c76] p-[12px] subtitle_2 my-[24px] flex items-center cursor-pointer"
                  onClick={
                    hasNews.length > 0 ? onClickMyinfoEachnews : onClickTutorial
                  }
                >
                  <img src={sirenicon} alt="siren" />
                  <span className="inline-block pl-4">
                    {hasNews.length > 0
                      ? hasNews[0].title
                      : "오스토케어 사용가이드 보기"}
                  </span>
                </div>
              </div>
              <div className="bg-[#FAFCFF] rounded-tl-3xl rounded-tr-3xl">
                <MainBanner />
                <div>
                  <div className="flex justify-between pt-[24px]">
                    <h2 className="subtitle_2 px-[24px] text-[#020A1B]">
                      💬 &nbsp;장루의 모든것
                    </h2>
                    <div
                      className="flex items-center cursor-pointer px-[24px]"
                      onClick={() => navigate("/alljangru")}
                    >
                      <span className="caption_1_600">전체보기</span>
                      <img
                        src={moreicon}
                        alt="moreicon"
                        className="inline-block w-[16px] h-[16px]"
                      />
                    </div>
                  </div>
                  <div className="px-[24px] pt-[16px]">
                    <Swiper spaceBetween={8} slidesPerView={3.6}>
                      {MainStomaText.slice(0, 6).map((el, index) => (
                        <SwiperSlide key={index}>
                          <div
                            className=" bg-[#f5f6f7] text-[#676C76] hover:bg-[#e5e6e7] h-[96px] cursor-pointer flex flex-col justify-center items-center"
                            onClick={() => {
                              onClickMore({ el });
                            }}
                          >
                            <img
                              src={el.svgurl}
                              alt={el.title}
                              className="w-12 h-12"
                            />
                            <span className="caption_1_600">{el.title}</span>
                          </div>
                        </SwiperSlide>
                      ))}

                      <SwiperSlide>
                        <div
                          className=" bg-[#f5f6f7] text-[#676C76] hover:bg-[#e5e6e7] h-[96px] cursor-pointer flex flex-col justify-center items-center"
                          onClick={() => navigate("/alljangru")}
                        >
                          <img
                            src={MainStomaText[6].svgurl}
                            alt={MainStomaText[6].title}
                            className="w-12 h-12"
                          />
                          <span className="caption_1_600">
                            {MainStomaText[6].title}
                          </span>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                  <h2 className="subtitle_2 pt-[24px] pb-2 px-[24px] text-[#020A1B]">
                    ✏️ 오늘을 기록해보세요!
                  </h2>
                  <div className="pt-[16px] w-full flex justify-between px-[24px] pb-[24px]">
                    <div
                      className="w-[48%] cursor-pointer bg-[#5FC17B] h-[100px] rounded relative"
                      onClick={onClickJangruCheck}
                    >
                      <div className="pl-[12px] pt-[14px] button_700 text-[#FAFBFC]">
                        <span className="block">장루</span>
                        <span>점검하기</span>
                      </div>
                      <div className=" absolute w-1/2 h-4/5 bottom-[6px] right-[6px] overflow-hidden">
                        <img
                          src={checkImg}
                          alt="check"
                          className="absolute bottom-[-3px] right-[-3px]"
                        />
                      </div>
                    </div>
                    <div
                      className="w-[48%] cursor-pointer bg-[#F9D867] h-[100px] rounded relative"
                      onClick={() => navigate("/healthdiary")}
                    >
                      <div className="pl-[12px] pt-[14px] button_700 text-[#0E2B5D]">
                        <span className="block">건강</span>
                        <span>다이어리</span>
                      </div>
                      <div className=" absolute w-1/2 h-4/5 bottom-[6px] right-[6px] overflow-hidden">
                        <img
                          src={healthdiaryImg}
                          alt="healthdiary"
                          className="absolute bottom-[-3px] right-[-3px]"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="h-[68px]"></div>
                </div>
              </div>

              <MainIcons />
            </div>
          </div>
        ) : (
          ""
        )}
      </>

      {stomaManage ? <StomaManage showStomaManage={showStomaManage} /> : ""}
    </>
  );
};

export default Main;
