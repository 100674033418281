import React, { useEffect, useState } from "react";
import { useMainContext } from "../../../../../contexts/Provider";
import { useNavigate } from "react-router-dom";
/*component*/
import CenterModal from "../../../mobilecomponent/CenterModal";

import common, { api } from "../../../../../data/common";
import dayjs from "dayjs";

const NoPeriodModal = ({
  showNOPeriodModal,
  sendperiodDay,
  recentSurveyId,
}) => {
  const closeicon = "/images/mo/closebasic.svg";
  const { loginInput } = useMainContext();
  const [user, setUser] = useState([]);
  const [hasReplyCheck, setHasReplyCheck] = useState(false);
  const [surveyCreated, setSurveyCreated] = useState("");
  const navigate = useNavigate();
  console.log(recentSurveyId, ">>>recentsurveyid");
  useEffect(() => {
    let pageOpen = false;
    const getPatientInfo = () => {
      api
        .get(common.baseURL + "patient/Id/" + loginInput.id)
        .then((res) => {
          if (res.data.ok === true) {
            setUser(res.data.patient);
          }
        })
        .catch((error) => console.log(error));
    };
    const getReplyandCheck = () => {
      if (recentSurveyId !== undefined) {
        api
          .post(common.baseURL + "jangruReply/forPatient", {
            id: recentSurveyId,
          })
          .then((res) => {
            console.log(res, ">>>this res");
            if (
              res.data.ok === true &&
              res.data.message === "jangruReply와 jangruCheck 모두 존재합니다."
            ) {
              console.log(res);
              setHasReplyCheck(true);
              setSurveyCreated(res.data.jangruSurveyCreated);
            }
          });
      }
    };

    if (!pageOpen) {
      getPatientInfo();
      getReplyandCheck();
    }
    return () => {
      pageOpen = true;
    };
  }, [loginInput.id, recentSurveyId]);

  return (
    <CenterModal showNOPeriodModal={showNOPeriodModal}>
      <img
        src={closeicon}
        alt="closeicon"
        className="absolute top-0 right-0 cursor-pointer"
        onClick={showNOPeriodModal}
      />
      <div className="pt-[16px] text-center">
        <span className="block">{user.name}님의 장루 정기 점검일은</span>
        <span className=" text-[#3F82ED]">
          매월 {sendperiodDay[0]}일, {sendperiodDay[1]}일{" "}
        </span>
        입니다.
        <span className="block">점검일을 기준으로</span>
        <span className="block">3일 후 까지 점검이 가능합니다.</span>
        {dayjs() < dayjs(sendperiodDay[2]) && (
          <span className="block text-sm">
            <br />
            첫번째 점검일 : {dayjs(sendperiodDay[2]).format("YYYY년 M월 D일")}
          </span>
        )}
      </div>

      {hasReplyCheck ? (
        <span
          className="mt-[28px] border border-[#3F82ED] rounded-sm p-[16px] w-[264px] text-[#3F82ED] 
                      button_700 flex justify-center items-center hover:bg-[#1B5EDC] hover:text-white cursor-pointer ease-in-out duration-300 mx-auto"
          onClick={() => {
            navigate("/report/inspection", {
              state: dayjs(surveyCreated).format("YYYY-MM-DD"),
            });
          }}
        >
          마지막 점검 결과 보기
        </span>
      ) : (
        ""
      )}
    </CenterModal>
  );
};

export default NoPeriodModal;
