// 금일 처리 건수
const TodayProcessedPerson = ({ data }) => {
  const todayProcessed = "/images/admin/header_dash/secondLine_4.svg";
  return (
    <div className="flex w-full justify-center p-6 rounded-3xl bg-[#5FC17B] shadow-[0_1px_4px_rgba(0,0,0,0.25)] text-[#020A1B] ">
      <div className="flex flex-col justify-between w-full h-full">
        <div className="flex gap-x-[7px]">
          <img
            src={todayProcessed}
            alt="todayProcessed"
            className="w-[20px] h-[20px]"
          />
          <label className="text-[18px] font-extrabold leading-[22px]">
            금일 처리 건수
          </label>
        </div>
        <p className="flex justify-end items-center w-full text-lg leading-[22px] font-semibold">
          <span className="text-2xl font-extrabold mr-1">{data[3].treat}</span>
          건
        </p>
      </div>
    </div>
  );
};

export default TodayProcessedPerson;
