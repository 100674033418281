import React from "react";

const WelfareNote = ({ bigTitle, btn1 }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400">
      <div className="pt-[72px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
        <h1 className="h2_800 pt-[4px] pb-[24px]">{btn1}</h1>
      </div>
      <div className="w-full pt-[24px] body_1_400_jangrucont">
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            장루·요루 장애는 1~6등급으로 구분되어 있지만 대부분 4급에 해당되므로
            복지 혜택이 부족한 실정이다. 경증장애로 분류되면 보통 전화요금이나
            새마을호 승차권 할인 등의 몇 가지 혜택을 받게 된다.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            특히 장루·요루 장애인의 경우 거의 4급 판정을 받기 때문에 복지 혜택이
            거의 없어 본인의 자존심에 장애등록 자체를 하지 않는 사람도 많다.
            뿐만 아니라 장애 특성상 가족에게 도움을 받는데 한계가 있고, 가족이
            있어도 혼자 살거나 가족들에게 알리지 않는 경우가 많으며, 장애로 인한
            외출을 꺼려하기 때문에 병원에서 협회를 소개해줘도 가지 않는 사람들이
            훨씬 많은 상황이다.
          </div>
        </div>
        <div className="h-[80px]"></div>
      </div>
    </div>
  );
};

export default WelfareNote;
