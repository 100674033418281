import React from "react";

const FoodManage3AfterConsider = ({ bigTitle, btn3, btn3_2 }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400 wordbreak-keepall">
      <div className="py-[24px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">
          {bigTitle} &gt; {btn3}
        </span>
        <h1 className="h2_800 pt-[4px]">{btn3_2}</h1>
      </div>
      <div className="w-full pt-[24px] body_1_400_jangrucont">
        <div className="flex ">
          <span className="inline-block ">1.&nbsp;</span>
          <div>
            장루 복원 후 잦은 배변, 잔변감, 배변 긴박감 등의 불편감이 생길 수
            있으며 이런 증상을 저위전방절제술 증후군이라고 합니다.
          </div>
        </div>
        <div className="flex ">
          <span className="inline-block ">-&nbsp;</span>
          <div>
            이는 직장 수술이후 직장에서 대변을 저장할 공간이 줄어서 생기는
            문제로 시간이 경과함에 따라 호전이 됩니다. 하지만 수술전에 비해서는
            배변 불편감은 지속됩니다.
          </div>
        </div>
        <div className="flex ">
          <span className="inline-block ">-&nbsp;</span>
          <div>
            저위전방절제술 증후군이 심한 경우 섭취량 및 체중감소 발생 가능성이
            높습니다.
          </div>
        </div>
        <div className="flex pt-[25px]">
          <span className="inline-block ">2.&nbsp;</span>
          <div>
            잦은 배변 발생 시, 섬유소 섭취량 조절을 포함한 식사관리가
            필요합니다.
          </div>
        </div>
      </div>
      <div className="h-[80px]"></div>
    </div>
  );
};

export default FoodManage3AfterConsider;
