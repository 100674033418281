// 주소찾기 api에서 제공되는 창
import DaumPostcode from "react-daum-postcode";

const Postcode = ({ place, setPlace, onClose }) => {
  const handleComplete = (data) => {
    setPlace({ ...place, address: data.address });
  };

  return <DaumPostcode onComplete={handleComplete} onClose={onClose} />;
};

export default Postcode;
