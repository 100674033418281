import { Doughnut } from "react-chartjs-2";

// 성별 분포 그래프
const GenderGraph = ({ flexCol, graphData, options, data }) => {
  return (
    <>
      <p className="flex font-extrabold text-[18px] ">성별 분포</p>
      <div className="flex justify-between items-end w-full h-full">
        <div
          className="flex aspect-square w-full min-w-[160px] mr-6"
          style={{ maxWidth: flexCol ? "160px" : "240px" }} // flexCol일 경우에 그래프 최대크기는 160px
        >
          <Doughnut data={graphData} options={options} />
        </div>
        <div className="flex flex-col justify-end items-end h-full gap-y-3 text-[#020A1B]">
          <div className="flex justify-between w-[82px] text-base leading-[22px]">
            <p className="text-[#8EA0FF] mr-1">
              ●<span className="text-[#020A1B] ml-1">남</span>
            </p>
            <p className="font-semibold">{data[0].male}명</p>
          </div>
          <div className="flex justify-between w-[82px] text-base leading-[22px]">
            <p className="text-[#FFBBBB] mr-1">
              ●<span className="text-[#020A1B] ml-1">여</span>
            </p>
            <p className="font-semibold">{data[0].female}명</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenderGraph;
