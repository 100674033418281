import React, { useState, useEffect, useCallback } from "react";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { useMainContext } from "../../../../contexts/Provider";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import common, { api } from "../../../../data/common";
/*component*/

import DiaryCenterModal from "../../mobilecomponent/DiaryCenterModal";
import ReportDiaryEditModal from "./components/ReportDiaryEditModal";
import ReportEditDeleteModal from "./components/ReportEditDeleteModal";
import ReportEditEditModal from "./components/ReportEditEditModal";
import ReportDiaryImages from "../HealthDiary/component/ReportDiaryImages";
const Diary = () => {
  const leftarrow = "/images/mo/leftarrow.svg";
  const fullstoma13 = "/images/mo/fullstoma13.svg";
  const fullstoma23 = "/images/mo/fullstoma23.svg";
  const fullwater = "/images/mo/fullwater.svg";
  const lefticon = "/images/mo/left.svg";
  const righticon = "/images/mo/right.svg";
  const rightunactive = "/images/mo/right_unactive.svg";
  const threedots = "/images/mo/threedots.svg";
  const navigate = useNavigate();
  const stateReplace = useLocation().state.date;
  const statewhere = useLocation().state.where;
  const { loginInput, setClickedDate } = useMainContext();
  const [range, setRange] = useState({
    thisDate: stateReplace ? stateReplace : dayjs().format("YYYY-MM-DD"),
  });
  const [hydroGuide, setHydroGuide] = useState(false);
  const [poopGuide, setPoopGuide] = useState(false);
  const [reportdiaryedit, setReportDiaryEdit] = useState(false);
  const [hasReport, setHasReport] = useState(true);
  const [reporteditDelete, setReportEditDelete] = useState(false);
  const [reporteditEdit, setReporteditEdit] = useState(false);
  const [beforeDate, setBeforeDate] = useState("");
  const [afterDate, setAfterDate] = useState("");
  const [diarydata, setDiaryData] = useState({
    id: "",
    writeDate: "",
    weight: 0,
    water: 0,
    toilet13: 0,
    toilet23: 0,
    jangruChange: 1,
    meal: "",
    memo: "",
    imageURL: [],
    patient: "",
  });

  // const DiaryDataIn = useCallback(
  //   ({ res, loginInput }) => {
  //     setDiaryData({
  //       id: res.data.diary.id,
  //       writeDate: res.data.diary.writeDate,
  //       weight: res.data.diary.weight,
  //       water: res.data.diary.water,
  //       toilet13: res.data.diary.toilet13,
  //       toilet23: res.data.diary.toilet23,
  //       jangruChange: res.data.diary.jangruChange,
  //       meal: res.data.diary.meal,
  //       memo: res.data.diary.memo,
  //       imageURL: res.data.diary.imageURL,
  //       patient: loginInput.id,
  //     });
  //   },
  //   [setDiaryData]
  // );

  // const DiaryDataOut = useCallback(
  //   ({ loginInput }) => {
  //     setDiaryData({
  //       writeDate: "",
  //       weight: 0,
  //       water: 0,
  //       toilet13: 0,
  //       toilet23: 0,
  //       jangruChange: 1,
  //       meal: "",
  //       memo: "",
  //       imageURL: [],
  //       patient: loginInput.id,
  //     });
  //   },
  //   [setDiaryData]
  // );

  const getBeforeAfterDate = useCallback(
    ({ res }) => {
      setBeforeDate(res.data.beforeDate);
      setAfterDate(res.data.afterDate);
    },
    [setBeforeDate, setAfterDate]
  );
  useEffect(() => {
    let pageOpen = false;

    const getDiaryWritDate = () => {
      let body = {
        id: loginInput.id,
        writeDate: range.thisDate,
      };
      api
        .post(common.baseURL + "diary/getDiary", body)
        .then((res) => {
          console.log(res);
          getBeforeAfterDate({ res });
          if (res.data.ok === true) {
            setDiaryData({
              id: res.data.diary.id,
              writeDate: res.data.diary.writeDate,
              weight: res.data.diary.weight,
              water: res.data.diary.water,
              toilet13: res.data.diary.toilet13,
              toilet23: res.data.diary.toilet23,
              jangruChange: res.data.diary.jangruChange,
              meal: res.data.diary.meal,
              memo: res.data.diary.memo,
              imageURL: res.data.diary.imageURL,
              patient: loginInput.id,
            });
            setHasReport(true);
          } else {
            setHasReport(false);
          }
        })
        .catch((error) => console.log(error));
    };

    if (!pageOpen) {
      if (
        loginInput.id === null ||
        loginInput.id === undefined ||
        loginInput.id === ""
      ) {
        navigate("/main", { replace: true });
      } else {
        getDiaryWritDate();
        setRange({
          thisDate: range.thisDate,
        });
      }
    }
    return () => {
      pageOpen = true;
    };
  }, [loginInput, navigate, range.thisDate, getBeforeAfterDate]);

  const handleLeft = () => {
    setRange({ thisDate: beforeDate });
    let body = {
      id: loginInput.id,
      writeDate: beforeDate,
    };

    api
      .post(common.baseURL + "diary/getDiary", body)
      .then((res) => {
        getBeforeAfterDate({ res });
        if (res.data.ok === true) {
          console.log(res);
          setDiaryData({
            id: res.data.diary.id,
            writeDate: res.data.diary.writeDate,
            weight: res.data.diary.weight,
            water: res.data.diary.water,
            toilet13: res.data.diary.toilet13,
            toilet23: res.data.diary.toilet23,
            jangruChange: res.data.diary.jangruChange,
            meal: res.data.diary.meal,
            memo: res.data.diary.memo,
            imageURL: res.data.diary.imageURL,
            patient: loginInput.id,
          });
          setHasReport(true);
        } else {
          setHasReport(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRight = () => {
    setRange({ thisDate: afterDate });
    let body = {
      id: loginInput.id,
      writeDate: afterDate,
    };
    api
      .post(common.baseURL + "diary/getDiary", body)
      .then((res) => {
        getBeforeAfterDate({ res });
        if (res.data.ok === true) {
          console.log(res);
          setDiaryData({
            id: res.data.diary.id,
            writeDate: res.data.diary.writeDate,
            weight: res.data.diary.weight,
            water: res.data.diary.water,
            toilet13: res.data.diary.toilet13,
            toilet23: res.data.diary.toilet23,
            jangruChange: res.data.diary.jangruChange,
            meal: res.data.diary.meal,
            memo: res.data.diary.memo,
            imageURL: res.data.diary.imageURL,
            patient: loginInput.id,
          });
          setHasReport(true);
        } else {
          setHasReport(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleToday = () => {
    //changeDate(next)
    setRange({
      thisDate: dayjs().format("YYYY-MM-DD"),
    });
    setClickedDate(dayjs().format("YYYY-MM-DD"));
    let body = {
      id: loginInput.id,
      writeDate: `${dayjs().format("YYYY-MM-DD")}`,
    };
    api
      .post(common.baseURL + "diary/getDiary", body)
      .then((res) => {
        console.log(res);
        if (res.data.ok === true) {
          getBeforeAfterDate({ res });
          setDiaryData({
            id: res.data.diary.id,
            writeDate: res.data.diary.writeDate,
            weight: res.data.diary.weight,
            water: res.data.diary.water,
            toilet13: res.data.diary.toilet13,
            toilet23: res.data.diary.toilet23,
            jangruChange: res.data.diary.jangruChange,
            meal: res.data.diary.meal,
            memo: res.data.diary.memo,
            imageURL: res.data.diary.imageURL,
            patient: loginInput.id,
          });
        } else {
          // DiaryDataOut({ loginInput });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const showHydroguide = () => {
    setHydroGuide(!hydroGuide);
  };
  const showPoopguide = () => {
    setPoopGuide(!poopGuide);
  };

  const showBTNDeleteModal = () => {
    setReportEditDelete(!reporteditDelete);
  };
  const showBTNEditModal = () => {
    setReporteditEdit(!reporteditEdit);
  };

  const showEditModal = () => {
    setReportDiaryEdit(!reportdiaryedit);
  };

  return (
    <>
      {reportdiaryedit ? (
        <ReportDiaryEditModal
          showEditModal={showEditModal}
          reportdiaryedit={reportdiaryedit}
          reportdiaryDate={range.thisDate}
          diaryId={diarydata.id}
          setReportEditDelete={setReportEditDelete}
          setReporteditEdit={setReporteditEdit}
        />
      ) : (
        ""
      )}

      {reporteditDelete ? (
        <ReportEditDeleteModal
          showBTNDeleteModal={showBTNDeleteModal}
          diarydataid={diarydata.id}
        />
      ) : (
        ""
      )}
      {reporteditEdit ? (
        <ReportEditEditModal
          showBTNEditModal={showBTNEditModal}
          reportdiaryDate={range.thisDate}
        />
      ) : (
        ""
      )}
      <div className="relative overflow-y-auto">
        <div className="fixed top-0 left-0 w-full bg-[#FAFCFF] z-3">
          <div className=" w-full flex items-center justify-between px-[8px] subtitle_1_400">
            <div className="flex items-center justify-between w-full">
              <img
                className="cursor-pointer inline-block pr-[20.25px]"
                src={leftarrow}
                alt="leftarrow"
                onClick={() => {
                  console.log(statewhere === "healthdiary");
                  if (statewhere === "healthdiary") {
                    navigate("/healthdiary", { state: range.thisDate });
                  } else {
                    navigate(-1);
                  }
                }}
              />
              <span className="inline-block ">건강다이어리</span>
              {hasReport ? (
                <img
                  src={threedots}
                  alt="threedots"
                  className="cursor-pointer"
                  onClick={showEditModal}
                />
              ) : (
                <span className="inline-block"></span>
              )}
            </div>
          </div>
          <div className=" border-b bg-[#FAFCFF] flex justify-center py-[16px] px-[24px] flex justify-between items-center">
            <span className="inline-block subtitle_1_600">
              {range.thisDate}
              {dayjs(range.thisDate).format("d") === "0"
                ? "(일)"
                : dayjs(range.thisDate).format("d") === "1"
                ? "(월)"
                : dayjs(range.thisDate).format("d") === "2"
                ? "(화)"
                : dayjs(range.thisDate).format("d") === "3"
                ? "(수)"
                : dayjs(range.thisDate).format("d") === "4"
                ? "(목)"
                : dayjs(range.thisDate).format("d") === "5"
                ? "(금)"
                : dayjs(range.thisDate).format("d") === "6"
                ? "(토)"
                : ""}
            </span>
            <div className="flex items-center gap-x-[8px]">
              {beforeDate === undefined ? (
                <span className="inline-block w-[32px] h-[32px] flex justify-center items-center border border-[#999CA3] rounded-full rotate-180">
                  <img
                    src={rightunactive}
                    alt="leftunactive"
                    className="w-5 h-5 "
                  />
                </span>
              ) : (
                <span
                  className="inline-block w-[32px] h-[32px] flex justify-center items-center border border-black rounded-full cursor-pointer"
                  onClick={handleLeft}
                >
                  <img src={lefticon} alt="lefticon" className="w-5 h-5" />
                </span>
              )}

              <span
                className="cursor-pointer inline-block subtitle_2 border border-black h-[32px] px-[8px] rounded-full flex items-center justify-center "
                onClick={handleToday}
              >
                오늘
              </span>
              {afterDate === undefined ? (
                <span className="inline-block w-[32px] h-[32px] flex justify-center items-center border border-[#999CA3] rounded-full ">
                  <img
                    src={rightunactive}
                    alt="rightunactive"
                    className="w-5 h-5 "
                  />
                </span>
              ) : (
                <span
                  className="inline-block w-[32px] h-[32px] flex justify-center items-center border border-black rounded-full cursor-pointer"
                  onClick={handleRight}
                >
                  <img src={righticon} alt="righticon" className="w-5 h-5" />
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="mt-[112px] bg-[#F1F2F3] p-[16px] h-screen">
          <div className="bg-[#FAFCFF] mb-[8px] p-[16px] rounded-lg flex items-center">
            <span className="caption_1_600 pr-[24px]">체중</span>
            <span className="body_1_600 text-[#245BBF]">
              {diarydata.weight !== "" ? diarydata.weight : 0}
              kg
            </span>
          </div>
          <div className="flex gap-x-[8px] pb-[8px]">
            <div className="w-2/5 p-[16px] bg-[#FAFCFF] rounded-lg">
              <span className="caption_1_600 ">수분 섭취</span>
              <div className="flex flex-col items-center pt-[27.2px]">
                <img
                  src={fullwater}
                  alt="fullwater"
                  className="w-[48px] h-[48px]"
                />
              </div>
              <span className="pt-[19.2px] body_1_600 text-[#245BBF] w-full block text-center">
                {diarydata.water !== "" ? diarydata.water : 0}컵 (
                {diarydata.water * 200}ml)
              </span>
            </div>
            <div className="w-3/5 bg-[#FAFCFF] p-[16px] rounded-lg">
              <span className="caption_1_600">배변량 및 비움횟수</span>
              <div className="flex pt-[24px]">
                <div className="poopleft w-1/2 pr-[15px] border-r">
                  <div className="flex flex-col items-center">
                    <div className="w-[48px] h-[48px] relative overflow-hidden">
                      <img src={fullstoma13} alt="fullstoma13" />
                      <strong className="absolute bottom-[-20px] left-1/2 transform-x-50 text-xs z-3">
                        1/3
                      </strong>
                    </div>
                    <span className="pt-[17.6px] body_1_600 text-[#DEB53D] w-full block text-center">
                      1/3*{diarydata.toilet13 !== "" ? diarydata.toilet13 : 0}회
                    </span>
                  </div>
                </div>
                <div className="poopright w-1/2 pl-[15px]">
                  <div className="flex flex-col items-center ">
                    <div className="w-[48px] h-[48px] relative overflow-hidden">
                      <img src={fullstoma23} alt="fullstoma23" />
                      <strong className="absolute bottom-[-20px] left-1/2 transform-x-50 text-xs z-3">
                        2/3
                      </strong>
                    </div>
                    <span className="pt-[17.6px] body_1_600 text-[#DEB53D] w-full block text-center">
                      2/3*{diarydata.toilet23 !== "" ? diarydata.toilet23 : 0}회
                    </span>
                  </div>
                </div>
              </div>
              <div className="bg-[#FCEEBA] rounded-full caption_2 px-[8px] py-[2px] mt-[6px]">
                {diarydata.jangruChange === 1
                  ? "장루 주머니 교체 안한날"
                  : diarydata.jangruChange === 0
                  ? "장루 주머니 교체 한날"
                  : "기록 없음"}
              </div>
            </div>
          </div>

          <div className="p-[16px] bg-[#FAFCFF] rounded-lg mb-[8px]">
            <h2 className="caption_1_600  pb-[8px] border-b">식사기록</h2>
            <div className="pt-[8px] body_1_400">
              {!(diarydata.meal === "" || diarydata.meal === null)
                ? diarydata.meal
                : "작성된 기록이 없습니다."}
            </div>
          </div>

          <div className="p-[16px] bg-[#FAFCFF] rounded-lg mb-[8px]">
            <h2 className="caption_1_600  pb-[8px] border-b">메모</h2>

            <div className="pt-[8px] flex items-center">
              {diarydata.imageURL !== undefined &&
              diarydata.imageURL.length > 0 ? (
                <ReportDiaryImages diarydata={diarydata} />
              ) : (
                ""
              )}
            </div>

            <div className="mt-[8px]">
              <div>
                {!(diarydata.memo === "" || diarydata.memo === null)
                  ? diarydata.memo
                  : "작성된 기록이 없습니다."}
              </div>
            </div>
          </div>
        </div>

        {hydroGuide ? (
          <DiaryCenterModal
            title={"수분 섭취량 측정 안내"}
            showHydroguide={showHydroguide}
          />
        ) : (
          ""
        )}
        {poopGuide ? (
          <DiaryCenterModal
            title={"배변량 및 횟수 측정 안내"}
            showPoopguide={showPoopguide}
          />
        ) : (
          ""
        )}
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        bodyClassName={() =>
          "w-[175px] p-3 rounded-full overflow-hidden bg-[#CBDFFC] text-black "
        }
      ></ToastContainer>
    </>
  );
};

export default Diary;
