import React from "react";
import { useNavigate } from "react-router-dom";

const FirstAdminContent = () => {
  const adminprof = "/images/mo/pngjpg/adminprof.png";
  const pocketandjangruboys = "/images/mo/pngjpg/pocketandjangruboys.png";
  const navigate = useNavigate();
  return (
    <div
      className="bg-[#FAFBFC] p-[24px]"
      onClick={() => {
        navigate("/community/notice");
      }}
    >
      <div className="flex items-center gap-x-[16px] pb-[16px] border-b cursor-pointer">
        <img src={adminprof} alt="adminprof" className="w-[40px] h-[40px]" />
        <div className="flex flex-col">
          <span className="subtitle_2">운영자</span>
          <span className="text-[#676C76] caption_2">2022-05-15</span>
        </div>
      </div>
      <div className="flex justify-center pt-[78px]">
        <img src={pocketandjangruboys} alt="pocketandjangruboys" />
      </div>
      <div className="pt-[64px] body_1_400">
        <p>안녕하세요. 커뮤니티 이용안내 입니다 :)</p>
        <p>커뮤니티 이용글을 작성해주세요 !</p>
      </div>
    </div>
  );
};

export default FirstAdminContent;
