import React from "react";
import CenterModal from "../../../mobilecomponent/CenterModal";
import { useNavigate } from "react-router-dom";
const MyinfoEditModal = () => {
  const navigate = useNavigate();
  return (
    <CenterModal>
      <span>정보가 변경되었습니다.</span>
      <span
        className="mt-[24px] block w-full py-[16px] flex justify-center items-center border rounded-sm text-[#1B5EDC] border-[#1B5EDC] hover:bg-[#F2F6FC] hover:text-[#18428E] hover:border-[#18428E] ease-in-out duration-300 cursor-pointer"
        onClick={() => {
          navigate("/myinfo");
        }}
      >
        확인
      </span>
    </CenterModal>
  );
};

export default MyinfoEditModal;
