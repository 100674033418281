import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
/*component */
import InspectionDefault from "./InspectionDefault";
// import SelectCustom from "../../../mobilecomponent/SelectCustom";
const InspectionMonthList = ({
  cansurvey,
  inspectJan,
  inspectFeb,
  inspectMar,
  inspectApr,
  inspectMay,
  inspectJun,
  inspectJul,
  inspectAug,
  inspectNov,
  inspectOct,
  inspectSep,
  inspectDec
  // 2차개발-보류
  // inspection,
  // setThisYear,
  // setInspectJan,
  // setInspectFeb,
  // setInspectMar,
  // setInspectApr,
  // setInspectMay,
  // setInspectJun,
  // setInspectJul,
  // setInspectAug,
  // setInspectNov,
  // setInspectDec,
  // setInspectOct,
  // setInspectSep,
  // thisyear
}) => {
  const downicon = "/images/mo/down.svg";
  const righticon = "/images/mo/right.svg";
  const [clickJan, setClickJan] = useState(false);
  const [clickFeb, setClickFeb] = useState(false);
  const [clickmar, setClickMar] = useState(false);
  const [clickApr, setClickApr] = useState(false);
  const [clickMay, setClickMay] = useState(false);
  const [clickJun, setClickJun] = useState(false);
  const [clickJul, setClickJul] = useState(false);
  const [clickAug, setClickAug] = useState(false);
  const [clickOct, setClickOct] = useState(false);
  const [clickSep, setClickSep] = useState(false);
  const [clickNov, setClickNov] = useState(false);
  const [clickDec, setClickDec] = useState(false);

  // 2차개발보류
  // const options = [2022, 2023, 2024, 2025];
  const navigate = useNavigate();
  const onClickJan = () => {
    setClickJan(!clickJan);
  };
  const onClickFeb = () => {
    setClickFeb(!clickFeb);
  };
  const onClickMar = () => {
    setClickMar(!clickmar);
  };
  const onClickApr = () => {
    setClickApr(!clickApr);
  };
  const onClickMay = () => {
    setClickMay(!clickMay);
  };
  const onClickJun = () => {
    setClickJun(!clickJun);
  };
  const onClickJul = () => {
    setClickJul(!clickJul);
  };
  const onClickAug = () => {
    setClickAug(!clickAug);
  };
  const onClickSep = () => {
    setClickSep(!clickSep);
  };
  const onClickOct = () => {
    setClickOct(!clickOct);
  };
  const onClickNov = () => {
    setClickNov(!clickNov);
  };
  const onClickDec = () => {
    setClickDec(!clickDec);
  };
  useEffect(() => {
    let pageOpen = false;
    if (!pageOpen) {
      if (dayjs().format("M") === "1") {
        setClickJan(true);
      }
      if (dayjs().format("M") === "2") {
        setClickFeb(true);
      }
      if (dayjs().format("M") === "3") {
        setClickMar(true);
      }
      if (dayjs().format("M") === "4") {
        setClickApr(true);
      }
      if (dayjs().format("M") === "5") {
        setClickMay(true);
      }
      if (dayjs().format("M") === "6") {
        setClickJun(true);
      }
      if (dayjs().format("M") === "7") {
        setClickJul(true);
      }
      if (dayjs().format("M") === "8") {
        setClickAug(true);
      }
      if (dayjs().format("M") === "9") {
        setClickSep(true);
      }
      if (dayjs().format("M") === "10") {
        setClickOct(true);
      }
      if (dayjs().format("M") === "11") {
        setClickNov(true);
      }
      if (dayjs().format("M") === "12") {
        setClickDec(true);
      }
    }
    return () => {
      pageOpen = true;
    };
  }, []);
  console.log(cansurvey);
  return (
    <>
      {/* 2차개발-보류 */}
      {/* <div className="p-[13px] w-[120px] bg-[#FAFCFF] rounded border mb-[8px]">
        <SelectCustom
          options={options}
          inspection={inspection}
          setInspectJan={setInspectJan}
          setInspectFeb={setInspectFeb}
          setInspectMar={setInspectMar}
          setInspectApr={setInspectApr}
          setInspectMay={setInspectMay}
          setInspectJun={setInspectJun}
          setInspectJul={setInspectJul}
          setInspectAug={setInspectAug}
          setInspectOct={setInspectOct}
          setInspectSep={setInspectSep}
          setInspectNov={setInspectNov}
          setInspectDec={setInspectDec}
        />
      </div> */}

      {inspectDec.length > 0 ||
      inspectNov.length > 0 ||
      inspectOct.length > 0 ||
      inspectSep.length > 0 ||
      inspectAug.length > 0 ||
      inspectJul.length > 0 ||
      inspectJun.length > 0 ||
      inspectMay.length > 0 ||
      inspectApr.length > 0 ||
      inspectMar.length > 0 ||
      inspectFeb.length > 0 ||
      inspectJan.length > 0 ? (
        <>
          {inspectDec.length > 0 ? (
            <div
              className={
                clickDec
                  ? "border bg-[#FAFCFF] px-[16px] pt-[7px] py-[24px] rounded-md mb-4 cursor-pointer"
                  : "border bg-[#FAFCFF] px-[16px] py-[7px] rounded-md mb-4 cursor-pointer"
              }
            >
              <div
                className="flex justify-between w-full p-[9px]"
                onClick={onClickDec}
              >
                <span className="h3_800 text-[#245BBF]  inline-block ">
                  12월
                </span>
                <img
                  src={downicon}
                  alt="downicon"
                  className={
                    clickDec === true
                      ? "noticeup"
                      : clickDec === false
                      ? "noticedown"
                      : clickDec === ""
                      ? ""
                      : ""
                  }
                />
              </div>
              <div>
                {clickDec
                  ? inspectDec.map((el, idx) => (
                      <div
                        className="block px-[16px] py-[17px] bg-[#F2F6FC] border-[#CBDFFC] border-t  flex justify-between"
                        key={idx}
                        onClick={() => {
                          navigate("/report/inspection", { state: el });
                        }}
                      >
                        <span className="subtitle_1_600">{el}</span>
                        <img src={righticon} alt="righticon" />
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          ) : (
            ""
          )}
          {inspectNov.length > 0 ? (
            <div
              className={
                clickNov
                  ? "border bg-[#FAFCFF] px-[16px] pt-[7px] py-[24px] rounded-md mb-4 cursor-pointer"
                  : "border bg-[#FAFCFF] px-[16px] py-[7px] rounded-md mb-4 cursor-pointer"
              }
            >
              <div
                className="flex justify-between w-full  p-[9px]"
                onClick={onClickNov}
              >
                <span className="h3_800 text-[#245BBF]  inline-block ">
                  11월
                </span>
                <img
                  src={downicon}
                  alt="downicon"
                  className={
                    clickNov === true
                      ? "noticeup"
                      : clickNov === false
                      ? "noticedown"
                      : clickNov === ""
                      ? ""
                      : ""
                  }
                />
              </div>
              <div>
                {clickNov
                  ? inspectNov.map((el, idx) => (
                      <div
                        className="block px-[16px] py-[17px] bg-[#F2F6FC] border-[#CBDFFC] border-t  flex justify-between"
                        key={idx}
                        onClick={() => {
                          navigate("/report/inspection", { state: el });
                        }}
                      >
                        <span className="subtitle_1_600">{el}</span>
                        <img src={righticon} alt="righticon" />
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          ) : (
            ""
          )}
          {inspectOct.length > 0 ? (
            <div
              className={
                clickOct
                  ? "border bg-[#FAFCFF] px-[16px] pt-[7px] py-[24px] rounded-md mb-4 cursor-pointer"
                  : "border bg-[#FAFCFF] px-[16px] py-[7px] rounded-md mb-4 cursor-pointer"
              }
            >
              <div
                className="flex justify-between w-full  p-[9px]"
                onClick={onClickOct}
              >
                <span className="h3_800 text-[#245BBF]  inline-block ">
                  10월
                </span>
                <img
                  src={downicon}
                  alt="downicon"
                  className={
                    clickOct === true
                      ? "noticeup"
                      : clickOct === false
                      ? "noticedown"
                      : clickOct === ""
                      ? ""
                      : ""
                  }
                />
              </div>
              <div>
                {clickOct
                  ? inspectOct.map((el, idx) => (
                      <div
                        className="block px-[16px] py-[17px] bg-[#F2F6FC] border-[#CBDFFC] border-t  flex justify-between"
                        key={idx}
                        onClick={() => {
                          navigate("/report/inspection", { state: el });
                        }}
                      >
                        <span className="subtitle_1_600">{el}</span>
                        <img src={righticon} alt="righticon" />
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          ) : (
            ""
          )}
          {inspectSep.length > 0 ? (
            <div
              className={
                clickSep
                  ? "border bg-[#FAFCFF] px-[16px] pt-[7px] py-[24px] rounded-md mb-4 cursor-pointer"
                  : "border bg-[#FAFCFF] px-[16px] py-[7px] rounded-md mb-4 cursor-pointer"
              }
            >
              <div
                className="flex justify-between w-full  p-[9px]"
                onClick={onClickSep}
              >
                <span className="h3_800 text-[#245BBF]  inline-block ">
                  9월
                </span>
                <img
                  src={downicon}
                  alt="downicon"
                  className={
                    clickSep === true
                      ? "noticeup"
                      : clickSep === false
                      ? "noticedown"
                      : clickSep === ""
                      ? ""
                      : ""
                  }
                />
              </div>
              <div>
                {clickSep
                  ? inspectSep.map((el, idx) => (
                      <div
                        className="block px-[16px] py-[17px] bg-[#F2F6FC] border-[#CBDFFC] border-t  flex justify-between"
                        key={idx}
                        onClick={() => {
                          navigate("/report/inspection", { state: el });
                        }}
                      >
                        <span className="subtitle_1_600">{el}</span>
                        <img src={righticon} alt="righticon" />
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          ) : (
            ""
          )}
          {inspectAug.length > 0 ? (
            <div
              className={
                clickAug
                  ? "border bg-[#FAFCFF] px-[16px] pt-[7px] py-[24px] rounded-md mb-4 cursor-pointer"
                  : "border bg-[#FAFCFF] px-[16px] py-[7px] rounded-md mb-4 cursor-pointer"
              }
            >
              <div
                className="flex justify-between w-full  p-[9px]"
                onClick={onClickAug}
              >
                <span className="h3_800 text-[#245BBF]  inline-block ">
                  8월
                </span>
                <img
                  src={downicon}
                  alt="downicon"
                  className={
                    clickAug === true
                      ? "noticeup"
                      : clickAug === false
                      ? "noticedown"
                      : clickAug === ""
                      ? ""
                      : ""
                  }
                />
              </div>
              <div>
                {clickAug
                  ? inspectAug.map((el, idx) => (
                      <div
                        className="block px-[16px] py-[17px] bg-[#F2F6FC] border-[#CBDFFC] border-t  flex justify-between"
                        key={idx}
                        onClick={() => {
                          navigate("/report/inspection", { state: el });
                        }}
                      >
                        <span className="subtitle_1_600">{el}</span>
                        <img src={righticon} alt="righticon" />
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          ) : (
            ""
          )}
          {inspectJul.length > 0 ? (
            <div
              className={
                clickJul
                  ? "border bg-[#FAFCFF] px-[16px] pt-[7px] py-[24px] rounded-md mb-4 cursor-pointer"
                  : "border bg-[#FAFCFF] px-[16px] py-[7px] rounded-md mb-4 cursor-pointer"
              }
            >
              <div
                className="flex justify-between w-full  p-[9px]"
                onClick={onClickJul}
              >
                <span className="h3_800 text-[#245BBF]  inline-block ">
                  7월
                </span>
                <img
                  src={downicon}
                  alt="downicon"
                  className={
                    clickJul === true
                      ? "noticeup"
                      : clickJul === false
                      ? "noticedown"
                      : clickJul === ""
                      ? ""
                      : ""
                  }
                />
              </div>
              <div>
                {clickJul
                  ? inspectJul.map((el, idx) => (
                      <div
                        className="block px-[16px] py-[17px] bg-[#F2F6FC] border-[#CBDFFC] border-t  flex justify-between"
                        key={idx}
                        onClick={() => {
                          navigate("/report/inspection", { state: el });
                        }}
                      >
                        <span className="subtitle_1_600">{el}</span>
                        <img src={righticon} alt="righticon" />
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          ) : (
            ""
          )}
          {inspectJun.length > 0 ? (
            <div
              className={
                clickJun
                  ? "border bg-[#FAFCFF] px-[16px] pt-[7px] py-[24px] rounded-md mb-4 cursor-pointer"
                  : "border bg-[#FAFCFF] px-[16px] py-[7px] rounded-md mb-4 cursor-pointer"
              }
            >
              <div
                className="flex justify-between w-full  p-[9px]"
                onClick={onClickJun}
              >
                <span className="h3_800 text-[#245BBF]  inline-block ">
                  6월
                </span>
                <img
                  src={downicon}
                  alt="downicon"
                  className={
                    clickJun === true
                      ? "noticeup"
                      : clickJun === false
                      ? "noticedown"
                      : clickJun === ""
                      ? ""
                      : ""
                  }
                />
              </div>
              <div>
                {clickJun
                  ? inspectJun.map((el, idx) => (
                      <div
                        className="block px-[16px] py-[17px] bg-[#F2F6FC] border-[#CBDFFC] border-t  flex justify-between"
                        key={idx}
                        onClick={() => {
                          navigate("/report/inspection", { state: el });
                        }}
                      >
                        <span className="subtitle_1_600">{el}</span>
                        <img src={righticon} alt="righticon" />
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          ) : (
            ""
          )}
          {inspectMay.length > 0 ? (
            <div
              className={
                clickMay
                  ? "border bg-[#FAFCFF] px-[16px] pt-[7px] py-[24px] rounded-md mb-4 cursor-pointer"
                  : "border bg-[#FAFCFF] px-[16px] py-[7px] rounded-md mb-4 cursor-pointer"
              }
            >
              <div
                className="flex justify-between w-full  p-[9px]"
                onClick={onClickMay}
              >
                <span className="h3_800 text-[#245BBF]  inline-block ">
                  5월
                </span>
                <img
                  src={downicon}
                  alt="downicon"
                  className={
                    clickMay === true
                      ? "noticeup"
                      : clickMay === false
                      ? "noticedown"
                      : clickMay === ""
                      ? ""
                      : ""
                  }
                />
              </div>
              <div>
                {clickMay
                  ? inspectMay.map((el, idx) => (
                      <div
                        className="block px-[16px] py-[17px] bg-[#F2F6FC] border-[#CBDFFC] border-t  flex justify-between"
                        key={idx}
                        onClick={() => {
                          navigate("/report/inspection", { state: el });
                        }}
                      >
                        <span className="subtitle_1_600">{el}</span>
                        <img src={righticon} alt="righticon" />
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          ) : (
            ""
          )}
          {inspectApr.length > 0 ? (
            <div
              className={
                clickApr
                  ? "border bg-[#FAFCFF] px-[16px] pt-[7px] py-[24px] rounded-md mb-4 cursor-pointer"
                  : "border bg-[#FAFCFF] px-[16px] py-[7px] rounded-md mb-4 cursor-pointer"
              }
            >
              <div
                className="flex justify-between w-full  p-[9px]"
                onClick={onClickApr}
              >
                <span className="h3_800 text-[#245BBF]  inline-block ">
                  4월
                </span>
                <img
                  src={downicon}
                  alt="downicon"
                  className={
                    clickApr === true
                      ? "noticeup"
                      : clickApr === false
                      ? "noticedown"
                      : clickApr === ""
                      ? ""
                      : ""
                  }
                />
              </div>
              <div>
                {clickApr
                  ? inspectApr.map((el, idx) => (
                      <div
                        className="block px-[16px] py-[17px] bg-[#F2F6FC] border-[#CBDFFC] border-t  flex justify-between"
                        key={idx}
                        onClick={() => {
                          navigate("/report/inspection", { state: el });
                        }}
                      >
                        <span className="subtitle_1_600">{el}</span>
                        <img src={righticon} alt="righticon" />
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          ) : (
            ""
          )}
          {inspectMar.length > 0 ? (
            <div
              className={
                clickmar
                  ? "border bg-[#FAFCFF] px-[16px] pt-[7px] py-[24px] rounded-md mb-4 cursor-pointer"
                  : "border bg-[#FAFCFF] px-[16px] py-[7px] rounded-md mb-4 cursor-pointer"
              }
            >
              <div
                className="flex justify-between w-full  p-[9px]"
                onClick={onClickMar}
              >
                <span className="h3_800 text-[#245BBF]  inline-block ">
                  3월
                </span>
                <img
                  src={downicon}
                  alt="downicon"
                  className={
                    clickmar === true
                      ? "noticeup"
                      : clickmar === false
                      ? "noticedown"
                      : clickmar === ""
                      ? ""
                      : ""
                  }
                />
              </div>
              <div>
                {clickmar
                  ? inspectMar.map((el, idx) => (
                      <div
                        className="block px-[16px] py-[17px] bg-[#F2F6FC] border-[#CBDFFC] border-t  flex justify-between"
                        key={idx}
                        onClick={() => {
                          navigate("/report/inspection", { state: el });
                        }}
                      >
                        <span className="subtitle_1_600">{el}</span>
                        <img src={righticon} alt="righticon" />
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          ) : (
            ""
          )}
          {inspectFeb.length > 0 ? (
            <div
              className={
                clickFeb
                  ? "border bg-[#FAFCFF] px-[16px] pt-[7px] py-[24px] rounded-md mb-4 cursor-pointer"
                  : "border bg-[#FAFCFF] px-[16px] py-[7px] rounded-md mb-4 cursor-pointer"
              }
            >
              <div
                className="flex justify-between w-full  p-[9px]"
                onClick={onClickFeb}
              >
                <span className="h3_800 text-[#245BBF]  inline-block ">
                  2월
                </span>
                <img
                  src={downicon}
                  alt="downicon"
                  className={
                    clickFeb === true
                      ? "noticeup"
                      : clickFeb === false
                      ? "noticedown"
                      : clickFeb === ""
                      ? ""
                      : ""
                  }
                />
              </div>
              <div>
                {clickFeb
                  ? inspectFeb.map((el, idx) => (
                      <div
                        className="block px-[16px] py-[17px] bg-[#F2F6FC] border-[#CBDFFC] border-t  flex justify-between"
                        key={idx}
                        onClick={() => {
                          navigate("/report/inspection", { state: el });
                        }}
                      >
                        <span className="subtitle_1_600">{el}</span>
                        <img src={righticon} alt="righticon" />
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          ) : (
            ""
          )}
          {inspectJan.length > 0 ? (
            <div
              className={
                clickJan
                  ? "border bg-[#FAFCFF] px-[16px] pt-[7px] py-[24px] rounded-md mb-4 cursor-pointer"
                  : "border bg-[#FAFCFF] px-[16px] py-[7px] rounded-md mb-4 cursor-pointer"
              }
            >
              <div
                className="flex justify-between w-full  p-[9px]"
                onClick={onClickJan}
              >
                <span className="h3_800 text-[#245BBF]  inline-block ">
                  1월
                </span>
                <img
                  src={downicon}
                  alt="downicon"
                  className={
                    clickJan === true
                      ? "noticeup"
                      : clickJan === false
                      ? "noticedown"
                      : clickJan === ""
                      ? ""
                      : ""
                  }
                />
              </div>
              <div>
                {clickJan
                  ? inspectJan.map((el, idx) => (
                      <div
                        className="block px-[16px] py-[17px] bg-[#F2F6FC] border-[#CBDFFC] border-t  flex justify-between"
                        key={idx}
                        onClick={() => {
                          navigate("/report/inspection", { state: el });
                        }}
                      >
                        <span className="subtitle_1_600">{el}</span>
                        <img src={righticon} alt="righticon" />
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <InspectionDefault cansurvey={cansurvey} />
      )}
    </>
  );
};

export default InspectionMonthList;
