import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import SwiperCore, { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { useMainContext } from "../../../../contexts/Provider";

import common, { api } from "../../../../data/common";
import dayjs from "dayjs";
import { ToastContainer } from "react-toastify";
/*component*/
import RightModal from "../../mobilecomponent/RightModal";
import DeleteAppuse from "./components/DeleteAppuse";
import AppuseContAnswer from "./components/AppuseContAnswer";

SwiperCore.use([Pagination]);
const AppUseContent = () => {
  const navigate = useNavigate();
  //2차개발
  // window.onpopstate = function () {
  //   console.log("작동");
  //   navigate(1);
  // };
  const { loginInput } = useMainContext();
  const locationStateID = useLocation().state;
  const href = new URL(window.location.href);
  const urlParams = href.searchParams;
  const tokenQuestionID = urlParams.get("questionId");
  const dataquestionID = tokenQuestionID ? tokenQuestionID : locationStateID;
  const leftarrow = "/images/mo/leftarrow.svg";
  const threedots = "/images/mo/threedots.svg";
  const trashcanicon = "/images/mo/trashcan.svg";
  const penicon = "/images/mo/pen.svg";
  const downicon = "/images/mo/down.svg";

  const [editdel, setEditDel] = useState();
  const [answer, setAnswer] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [question, setQuestion] = useState([]);
  const [downquestion, setDownQuestion] = useState(true);
  const [images, setImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [extraUrls, setExtraUrls] = useState([]);
  const [deleteappuse, setDeleteappuse] = useState(false);
  const showDeleteAppuse = () => {
    setDeleteappuse(!deleteappuse);
  };
  useEffect(() => {
    let pageOpen = false;
    const getQuestionId = () => {
      api.get(common.baseURL + "question/id/" + dataquestionID).then((res) => {
        if (res.data.ok === true) {
          setImages(res.data.question.photoURL);
          if (res.data.answer !== undefined) {
            let allFiles = res.data.answer.fileURL;

            const filterimageUrl = allFiles.filter(
              (el) =>
                el.slice(-4) === ".jpg" ||
                el.slice(-5) === ".jpeg" ||
                el.slice(-4) === ".png"
            );
            const filterOtherUrl = allFiles.filter(
              (el) => el.slice(-4) === ".pdf"
            );
            setImageUrls(filterimageUrl);
            setExtraUrls(filterOtherUrl);
            setAdmin(res.data.answer.admin);
            setAnswer(res.data.answer);
          }

          setQuestion(res.data.question);
        }
      });
    };
    if (!pageOpen) {
      if (
        loginInput.id === null ||
        loginInput.id === undefined ||
        loginInput.id === ""
      ) {
        navigate("/main", { replace: true });
      } else {
        getQuestionId();
      }
    }
    return () => {
      pageOpen = true;
    };
  }, [dataquestionID, loginInput.id, navigate]);
  const showEditDelete = () => {
    if (editdel === true) {
      setEditDel(false);
    } else {
      setEditDel(true);
    }
  };

  const showDownQuestion = () => {
    setDownQuestion(!downquestion);
  };

  const goAppuseWrite = (dataquestionID) => {
    navigate("/myinfo/appusewrite", {
      state: dataquestionID,
    });
  };
  const goback = () => {
    navigate("/myinfo/appuse");
  };

  return (
    <>
      {editdel ? (
        <>
          <RightModal>
            <div
              className="px-[17px] py-[20px] cursor-pointer border-b flex items-center hover:bg-[#CBDFFC] ease-in-out duration-300 "
              onClick={() => {
                goAppuseWrite(dataquestionID);
              }}
            >
              <span className="inline-block pr-2 cursor-pointer">
                게시글 수정
              </span>
              <img src={penicon} alt="penicon" className="w-[18px] h-[18px]" />
            </div>
            <div
              className="px-[17px] py-[20px] cursor-pointer border-b flex items-center hover:bg-[#CBDFFC] ease-in-out duration-300 "
              onClick={showDeleteAppuse}
            >
              <span className="inline-block pr-2">게시글 삭제</span>
              <img
                src={trashcanicon}
                alt="trashcanicon"
                className="w-[18px] h-[18px]"
              />
            </div>
          </RightModal>
          <div
            className="fixed top-0 left-0 w-full h-full bg-transparent cursor-pointer z-4 "
            onClick={() => {
              setEditDel(false);
            }}
          ></div>
        </>
      ) : (
        ""
      )}
      <div className="h-screen relative overflow-y-auto bg-[#FAFCFF]">
        <div className="w-full  px-[8px] border-b-[1px] flex justify-between items-center bg-[#FAFCFF]">
          <div className="flex items-center justify-between w-full subtitle_1_400">
            <img
              className="inline-block cursor-pointer"
              src={leftarrow}
              alt="leftarrow"
              onClick={goback}
            />
            <span className="inline-block">답변확인</span>
            {answer.admin === undefined ? (
              <img
                src={threedots}
                alt="threedots"
                className="inline-block cursor-pointer"
                onClick={showEditDelete}
              />
            ) : (
              <span className="inline-block w-[48px] h-[48px]"></span>
            )}
          </div>
        </div>
        <div className="p-[24px] bg-[#FAFCFF]">
          <span className="block font-semibold">
            &#91;
            {question.category === 0
              ? "서비스 문의"
              : question.category === 1
              ? "장애 및 불편신고"
              : question.category === 2
              ? "제안사항"
              : question.category === 3
              ? "기타"
              : ""}
            &#93;
          </span>
          <span className="block">{question.title}</span>
          <div className="flex justify-between items-center pb-[33px] border-b">
            <span className="pt-[17px] block text-[#999CA3] caption_1_400">
              {dayjs(question.createdAt).format("YYYY.MM.DD")}{" "}
              {dayjs(question.createdAt).format("A") === "PM" ? "오후" : "오전"}{" "}
              {dayjs(question.createdAt).format("H") > 12
                ? dayjs(question.createdAt).format("H") - 12
                : dayjs(question.createdAt).format("H")}
              시
            </span>
            {question.status === 1 ? (
              <span className="inline-block py-[2px] px-[6px] bg-[#1B5EDC] text-white caption_2 rounded-sm">
                답변완료
              </span>
            ) : (
              <span className="inline-block py-[2px] px-[6px] border border-[#999CA3] text-[#999CA3] caption_2 rounded-sm">
                답변대기
              </span>
            )}
          </div>
          {answer.length === 0 ? (
            ""
          ) : (
            <>
              <div
                className="flex items-center justify-center gap-x-[13px] py-[16px] border mt-[24px] cursor-pointer "
                onClick={showDownQuestion}
              >
                <span>
                  {downquestion === true ? "내 질문 보기" : "내 질문 닫기"}
                </span>
                <img
                  src={downicon}
                  alt="downicon"
                  className={downquestion ? "noticedown" : "noticeup"}
                />
              </div>
              {downquestion === true ? (
                ""
              ) : (
                <div className="pt-[24px] ">
                  <div>
                    {images.length > 0
                      ? images.map((el, index) => (
                          <div
                            key={index}
                            className="w-full rounded overflow-hidden mb-[8px]"
                          >
                            <img
                              alt={el}
                              src={common.networkImg + encodeURIComponent(el)}
                            />
                          </div>
                        ))
                      : ""}
                  </div>

                  <span className="pt-[16px] block whitespace-pre-line">
                    {question.content}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
        {answer.length !== 0 ? (
          <AppuseContAnswer
            admin={admin}
            answer={answer}
            imageUrls={imageUrls}
            extraUrls={extraUrls}
            question={question}
          />
        ) : (
          <div className="pt-[24px] px-[24px]">
            <div>
              {images.length > 0
                ? images.map((el, index) => (
                    <div
                      key={index}
                      className="w-full rounded-3xl overflow-hidden mb-[8px]"
                    >
                      <img
                        alt={el}
                        src={common.networkImg + encodeURIComponent(el)}
                        className="w-full "
                      />
                    </div>
                  ))
                : ""}
            </div>

            <span className="pt-[16px] block whitespace-pre-line">
              {question.content}
            </span>
            <div className="h-[70px]"></div>
          </div>
        )}
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        bodyClassName={() =>
          "w-[175px] p-3 rounded-full overflow-hidden bg-[#CBDFFC] text-black "
        }
      ></ToastContainer>
      {deleteappuse ? (
        <DeleteAppuse
          showDeleteAppuse={showDeleteAppuse}
          dataquestionID={dataquestionID}
          setEditDel={setEditDel}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default AppUseContent;
