import React from "react";

const LifeInManage2 = ({ btn2, bigTitle }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400 wordbreak-keepall">
      <div className="pt-[72px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
        <h1 className="h2_800 pt-[4px] pb-[24px]">{btn2}</h1>
      </div>
      <div className="w-full pt-[24px] body_1_400_jangrucont">
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <div>
            피부 보호판을 미리 잘라두어 여행짐에 가위를 가져가는 일이 없도록
            합니다.
          </div>
        </div>
        <div className="flex ">
          <span className="inline-block">•&nbsp;</span>
          <div>장루물품을 기내용 짐과 위탁수하물에 나누어서 준비를 합니다.</div>
        </div>
        <div className="flex ">
          <span className="inline-block">•&nbsp;</span>
          <div>예상치 못한 상황에 대비하여 추가 수량을 준비합니다.</div>
        </div>
        <div className="flex ">
          <span className="inline-block">•&nbsp;</span>
          <div>
            장루 관리 제품과 관련하여 검색 받게 되는 경우를 대비하여 장루
            보유자라는 의사 소견서(영어)를 휴대합니다.
          </div>
        </div>
        <div className="flex ">
          <span className="inline-block">•&nbsp;</span>
          <div>여행지에서 물이나 음식물의 섭취를 주의해야 합니다.</div>
        </div>
        <div className="flex ">
          <span className="inline-block">•&nbsp;</span>
          <div>
            비행기 탑승시에는 압력의 변화로 가스 배출이 증가하므로 가스 필터가
            있는 주머니를 착용하고 가스 유발 음식을 제한합니다.
          </div>
        </div>
        <div className="flex ">
          <span className="inline-block">•&nbsp;</span>
          <div>비행기 탑승전에 가스를 제거하고 대변을 비웁니다.</div>
        </div>
      </div>
    </div>
  );
};

export default LifeInManage2;
