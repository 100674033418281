import { useEffect, useRef, useState } from "react";
import commonUrl, { api } from "../../../../data/common";
import TableBlue from "../../admin_components/TableBlue";
import { BtnBlue } from "../../admin_components/Buttons";
import Header2 from "../../admin_components/Header/Header2";
import { ToastContainer, toast, Flip } from "react-toastify";
import { toastCommonProps } from "../../admin_components/Toast";
import { Loading } from "../../admin_components/Loading";
import dayjs from "dayjs";
import { handlingError } from "../../admin_components/Error";

export function Service() {
  const search_img = "/images/admin/search.svg";

  const [loading, setLoading] = useState(false); //
  /*** 검색 관련 state, ref ***/
  const dataRef = useRef([]); // 표기할 정보 담기
  const [data, setData] = useState([]); // 표기할 정보 담기
  const [search, setSearch] = useState("");
  const showAllRef = useRef(false); // 전체보기 버튼 보여줄지 말지

  // 검색 값 변화할 때
  const change = (e) => {
    setSearch(e.target.value);
  };

  // 검색 버튼 클릭했을 때
  const searchClick = () => {
    // 검색한 값이 있으면
    if (search !== "") {
      const newData = dataRef.current.filter((el) => {
        return el.inquiry.includes(search);
      });
      if (newData.length === 0) {
        toast(
          <p>검색된 내용이 없습니다.</p>,
          toastCommonProps(
            1500,
            "top-right",
            "toast_common toast__search",
            Flip
          )
        );
        setSearch("");
      } else {
        setData(newData);
        showAllRef.current = true;
      }
    }
    // 검색한 값이 없으면
    else {
      setData(dataRef.current);
      setSearch("");
      showAllRef.current = false;
    }
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      searchClick();
    }
  };

  // 전체 보기 클릭시
  const onClickShowAll = () => {
    setData(dataRef.current);
    setSearch("");
    showAllRef.current = false;
  };

  // 첫 랜더시 데이터 불러오기()
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await api
        .get(commonUrl.baseURL + "service/apply")
        .catch((error) => {
          handlingError(error);
        });
      console.log(response.data, ">>> Axios service/apply");
      // axios 성공
      if (response.data.ok) {
        let newData = response.data.serviceApplyList;
        dataRef.current = newData;
        setData(dataRef.current);
        setLoading(false);
      }
      // axios 실패
      else {
        toast(
          <p>정보 불러오기에 실패하였습니다.</p>,
          toastCommonProps(
            1500,
            "top-right",
            "toast_common toast__search",
            Flip
          )
        );
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /******************************* Table UI 관련 변수 & 함수들  ******************************/
  // Table에 들어갈 column명
  const columns = [
    {
      accessor: "idx",
      Header: "NO.",
      width: "7%",
    },
    {
      accessor: "inquiry",
      Header: "내용",
      width: "40%",
    },
    {
      accessor: "name",
      Header: "병원명",
      width: "20%",
    },
    {
      accessor: "createdAt",
      Header: "등록일",
      width: "15%",
    },
    {
      accessor: "serviceAnswer",
      Header: "처리일",
      width: "15%",
    },
  ];

  // table에 넘겨줄 내용
  function currentPosts(data) {
    const sortedData = data.sort((a, b) => {
      return b.id - a.id;
    });
    console.log("sort!!!!!!!!!", sortedData);
    return sortedData.map((post, idx) => {
      return {
        id: post.id,
        idx: data.length - idx,
        inquiry: post.inquiry,
        name: post.name,
        createdAt: dayjs(new Date(post.createdAt)).format("YYYY-MM-DD"),
        serviceAnswer: post.serviceAnswer
          ? dayjs(new Date(post.serviceAnswer.createdAt)).format("YYYY-MM-DD")
          : "-",
      };
    });
  }
  return (
    <Header2 title={[{ title: "서비스 신청", url: 0 }]}>
      <ToastContainer />
      <div className="admin p-[32px]">
        <div className="h-[80px] min-h-[80px]"></div>
        <label className="text-2xl font-extrabold leading-8 text-[#020A1B]">
          서비스 신청
        </label>
        {loading ? (
          <Loading />
        ) : (
          <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-3 min-w-[975px]">
            <div className="flex justify-end">
              {/* 검색창 */}
              <div className="flex gap-x-2 ml-[10px]">
                <div className="flex items-center w-[290px] h-[38px] border border-[#CCCDD1] box-border px-6 py-2 rounded-[48px]">
                  <img
                    src={search_img}
                    alt="search_img"
                    className="w-[16.5px] h-[16.6px] mr-[9.69px]"
                  />
                  <input
                    type="text"
                    className="w-[210px] h-[22px] bg-transparent text-[#4E535F] focus:outline-none placeholder:font-semibold placeholder:text-base placeholder:leading-[22px] placeholder:text-[#4E535F]"
                    placeholder="내용을 검색하세요."
                    value={search}
                    onChange={change}
                    onKeyPress={onKeyPress}
                  />
                </div>
                <BtnBlue
                  onClick={searchClick}
                  context="검색"
                  style={{
                    width: "76px",
                    height: "38px",
                    borderRadius: "48px",
                  }}
                />
              </div>
            </div>
            {loading === false ? (
              <div className="flex grow mt-[20px]">
                <TableBlue
                  columns={columns}
                  data={currentPosts(data)}
                  pageMove={"/admin/main/service/detail"}
                  sorting={true}
                  pagination={true}
                  showAll={showAllRef.current ? onClickShowAll : false}
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </Header2>
  );
}
export default Service;
