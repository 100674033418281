import React from "react";
import { useNavigate } from "react-router-dom";
/*component*/
import CenterModal from "../../../mobilecomponent/CenterModal";

const CheckCloseModal = ({ showCheckModal }) => {
  const closeicon = "/images/mo/closebasic.svg";
  const jangruboy_wow = "/images/mo/jangruboy_wow.svg";
  const navigate = useNavigate();
  return (
    <CenterModal showCheckModal={showCheckModal}>
      <div className="absolute top-0 left-0 w-full flex justify-end">
        <img
          src={closeicon}
          alt="closeicon"
          className="cursor-pointer"
          onClick={showCheckModal}
        />
      </div>
      <div className="flex flex-col items-center pt-[32px]">
        <img src={jangruboy_wow} alt="jangruboy_wow" />
        <span className="block">종료하시겠습니까? 종료하시면</span>
        <span className="block">이전 설문 내용이 모두 삭제됩니다.</span>
      </div>
      <div className=" flex gap-[8px] pt-[24px] w-full">
        <span
          className="inline-block w-1/2 py-[16px] bg-[#FAFCFF] border border-[#1B5EDC] rounded-sm text-[#1B5EDC] hover:bg-[#F2F6FC] hover:text-[#18428E] hover:border-[#18428E] ease-in-out duration-300 button_700 flex justify-center items-center cursor-pointer"
          onClick={showCheckModal}
        >
          취소
        </span>
        <span
          className="inline-block w-1/2 py-[16px] bg-[#1B5EDC] text-white hover:bg-[#18428E] ease-in-out duration-300 rounded-sm flex justify-center items-center button_700 cursor-pointer"
          onClick={() => {
            navigate("/main");
          }}
        >
          확인
        </span>
      </div>
    </CenterModal>
  );
};

export default CheckCloseModal;
