import React from "react";
import Header2 from "../../admin_components/Header/Header2";
import NewsEditComponent from "./component/NewsEditComponent";
import { ToastContainer } from "react-toastify";
const NewsId = () => {
  return (
    <Header2
      title={[
        { title: "새소식", url: "/admin/main/news" },
        {
          title: "새소식 확인/수정",
          url: 0,
        },
      ]}
    >
      <div className="w-full h-full ">
        <div className="px-[20px] py-[70px]">
          <NewsEditComponent />
        </div>
      </div>
      <ToastContainer />
    </Header2>
  );
};

export default NewsId;
