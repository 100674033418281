import React from "react";
import CenterModal from "../../../mobilecomponent/CenterModal";

const NotfoundInfo = ({ showNotFound }) => {
  return (
    <CenterModal showNotFound={showNotFound}>
      <div className="flex flex-col items-center">
        <span className="inline-block body_1_400">
          일치하는 정보가 없습니다.
        </span>
        <span className="inline-block body_1_400">
          확인 후 다시 시도해주세요.
        </span>
        <span
          className="mt-[24px] w-[230px] rounded-sm border border-[#1B5EDC] button_700 text-[#1B5EDC] ease-in-out duration-300 bg-[#FAFCFF] hover:bg-[#F2F6FC] hover:text-[#18428E] p-[16px] flex justify-center items-center cursor-pointer"
          onClick={showNotFound}
        >
          확인
        </span>
      </div>
    </CenterModal>
  );
};

export default NotfoundInfo;
