import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import React from "react";
import common, { api } from "../../../../../data/common";
import CenterModal from "../../../mobilecomponent/CenterModal";
import { useMainContext } from "../../../../../contexts/Provider";

const BlockingPost = ({
  setBlocking,
  setWarning,
  setWarnBlock,
  commentUserId,
}) => {
  const { loginInput } = useMainContext();
  const navigate = useNavigate();
  const onClickBlockUser = () => {
    let body = {
      //id - 신고한사람 , who - 신고당한사람
      id: loginInput.id,
      block: {
        who: commentUserId,
      },
    };
    api
      .post(common.baseURL + "patient/block", body)
      .then((res) => {
        if (res.data.ok === true) {
          console.log(res);
          setBlocking(false);
          toast(`해당 사용자가 차단되었습니다.`, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            limit: 1,
            className: "transparent toastbottom",
          });
          let start = setTimeout(() => {
            navigate("/community");
          }, 2000);
          return () => {
            clearTimeout(start);
          };
        } else {
          navigate("/community/post");
          alert("차단기능에 오류가 있습니다.");
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <CenterModal
      setBlocking={setBlocking}
      setWarning={setWarning}
      setWarnBlock={setWarnBlock}
    >
      <div className="text-center pb-[24px]">
        <span className="block">선택하신 사용자의 게시물과</span>
        <span className="block">댓글이 모두 차단됩니다.</span>
        <span className="block">정말 차단하시겠습니까?</span>
      </div>
      <div className="flex gap-x-[12px]">
        <span
          className="w-1/2 border rounded border-[#1B5EDC] text-[#1B5EDC] text-center px-[36px] py-[20px] bg-[#FAFCFF] hover:bg-[#F2F6FC] hover:border-[#18428E] hover:text-[#18428E] ease-in-out duration-300 cursor-pointer"
          onClick={onClickBlockUser}
        >
          확인
        </span>
        <span
          className="w-1/2 border rounded border-[#1B5EDC] text-[#1B5EDC] text-center px-[36px] py-[20px] bg-[#FAFCFF] hover:bg-[#F2F6FC] hover:border-[#18428E] hover:text-[#18428E] ease-in-out duration-300 cursor-pointer"
          onClick={() => {
            setBlocking(false);
            setWarnBlock(false);
          }}
        >
          취소
        </span>
      </div>
    </CenterModal>
  );
};

export default BlockingPost;
