import React from "react";
import { useNavigate } from "react-router-dom";
/*component*/
import CenterModal from "../../../mobilecomponent/CenterModal";

const ReportEditEditModal = ({ showBTNEditModal, reportdiaryDate }) => {
  const navigate = useNavigate();
  console.log(reportdiaryDate);
  return (
    <CenterModal>
      <span className="block text-center">저장된 기록이 있습니다.</span>
      <span className="block text-center">수정 하시겠습니까?</span>
      <div className="pt-[24px] flex gap-x-[8px] font-semibold w-full">
        <span
          className="p-[16px] w-1/2 border text-[#1B5EDC] border-[#1B5EDC] hover:bg-[#F2F6FC] ease-in-out duration-300 rounded-sm inline-block flex justify-center items-center cursor-pointer"
          onClick={showBTNEditModal}
        >
          취소
        </span>
        <span
          className=" w-1/2 bg-[#1B5EDC] rounded-sm text-white hover:bg-[#18428E]  ease-in-out duration-300 inline-block flex justify-center items-center cursor-pointer"
          onClick={() =>
            navigate("/healthdiary", {
              state: reportdiaryDate
            })
          }
        >
          작성바로가기
        </span>
      </div>
    </CenterModal>
  );
};

export default ReportEditEditModal;
