import React from "react";

import DigestiveSystem from "../subpage/DigestiveSystem";
import PysicalActiveManagement from "../subpage/PysicalActiveManagement";
import StomaPage from "../subpage/StomaPage";
const Style1A = ({ title2, bigTitle }) => {
  return (
    <div className="w-full flex flex-col  px-[16px] body_1_400">
      <div className="flex justify-center flex-col items-center w-full border-b py-[24px]">
        <span className="text-[#679DEE] caption_1_400">{title2}</span>
        <h1 className="h2_800 pt-[4px]">{bigTitle}</h1>
      </div>

      <div className="w-full pt-[24px]">
        {bigTitle === "소화기관?!" ? <DigestiveSystem /> : ""}
        {bigTitle === "장루?!" ? <StomaPage /> : ""}
        {bigTitle === "신체활동 관리" ? <PysicalActiveManagement /> : ""}
        <div className="h-[80px]"></div>
      </div>
    </div>
  );
};

export default Style1A;
