import React, { useState } from "react";
import { useMainContext } from "../../../../../contexts/Provider";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import SelectedImages from "../../HealthDiary/component/SelectedImages";
import PhotoAlertModal from "../../HealthDiary/component/PhotoAlertModal";
import { resizeFile } from "../../../../../resizeFile";
const CheckImageShow = () => {
  const {
    selectedImages,
    setSelectedImages,
    setFileArray,
    fileArray,
    files,
    setFiles,
  } = useMainContext();
  SwiperCore.use([Autoplay, Pagination]);
  const cameraplus = "/images/mo/cameraplus.svg";
  const [photoAlert, setPhotoAlert] = useState(false);
  const imageHandleChange = async (e) => {
    // const temp = Array.from(e.target.files);
    let temp = [];
    for (let i = 0; i < e.target?.files?.length; i++) {
      let resize = await resizeFile(e.target?.files[i]);
      temp.push(resize);
    }

    //1.미리보기 가상의 주소 만들기
    const fileArrayURL = temp.map((file) => URL.createObjectURL(file));

    //Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));

    //2.db파일로 넘길 주소 만들기
    let realFile = temp.map(
      (file) => file.lastModified + file.name.replace(" ", "")
    );
    //3.dataTransfer로 이전파일 + 동적으로 가져온 파일 묶어서 하나의 묶음으로 만들기=>각각 delete하기 위한 목적
    const dataTranster = new DataTransfer();
    files
      .concat(temp)
      .slice(0, 5)
      .forEach((file) => {
        dataTranster.items.add(file);
      });
    document.getElementById("file").files = dataTranster.files;

    //files:실제 FileList, FileArray: db저장될 생성된 이미지 주소, seletedImages: 미리보기로 보여지는 가상의 주소
    setFiles([...new Set(Array.from(dataTranster.files))].slice(0, 5));
    setFileArray([...new Set(fileArray.concat(realFile))].slice(0, 5));
    setSelectedImages(
      [...new Set(selectedImages.concat(fileArrayURL))].slice(0, 5)
    );

    if ([...new Set(selectedImages.concat(fileArrayURL))].length >= 6) {
      setPhotoAlert(true);
    } else {
      setPhotoAlert(false);
    }
  };

  return (
    <>
      <div className="w-full mt-[10px] flex overflow-hidden">
        {selectedImages.length >= 0 && selectedImages.length <= 4 ? (
          <>
            <input
              type="file"
              accept=".jpg,.png,.jpeg"
              multiple
              id="file"
              hidden
              onChange={imageHandleChange}
            />
            <div className="result flex flex-wrap gap-x-[8px] gap-y-[8px]">
              <div className="label-holder">
                <label htmlFor="file" className="label">
                  <div className="w-[88px] h-[88px]  flex justify-center items-center relative cursor-pointer">
                    <img
                      src={cameraplus}
                      alt="cameraplus"
                      className="w-full h-full"
                    />
                  </div>
                </label>
              </div>
              {selectedImages.map((el, index) => (
                <SelectedImages key={index} el={el} index={index} />
              ))}
            </div>
          </>
        ) : selectedImages.length >= 0 && selectedImages.length === 5 ? (
          <div className="result flex flex-wrap gap-x-[8px] gap-y-[8px]">
            {selectedImages.map((el, index) => (
              <SelectedImages key={index} el={el} index={index} />
            ))}
          </div>
        ) : (
          ""
        )}
        <div className="flex items-center result"></div>
      </div>
      {photoAlert ? <PhotoAlertModal setPhotoAlert={setPhotoAlert} /> : ""}
    </>
  );
};

export default CheckImageShow;
