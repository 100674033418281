import React from "react";
import common, { api } from "../../../../../../data/common";

const Style6_3 = ({ title2, bigTitle }) => {
  const videoskin = "/images/mo/pngjpg/장루원피스_스킨.png";
  console.log(common.networkCont + "onepiece.mp4");
  return (
    <div className="px-[16px] body_1_400_jangrucont wordbreak-keepall">
      <div className="pt-[24px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">{title2}</span>
        <h1 className="h2_800 pt-[4px] pb-[24px]">{bigTitle}</h1>
      </div>
      <div className="pt-[24px] body_1_400_jangrucont">
        <video
          src={common.networkCont + "onepiece.mp4"}
          width="100%"
          height="400px"
          controls
          poster={videoskin}
        />
      </div>
      <div className="pt-[16px] wordbreak-keepall">
        <span className="block">원피스 장루 교체 방법입니다.</span>
        <span className="block">영상을 따라 장루 교체 방법을 익혀보세요.</span>
      </div>
    </div>
  );
};

export default Style6_3;
