import React from "react";
import { useLocation } from "react-router-dom";
import common, { api } from "../../../data/common";
const ImageModal = ({ showImageModal, imageurl, hasimageData }) => {
  const closeicon = "/images/mo/closebasic.svg";
  const pathname = useLocation().pathname;

  return (
    <>
      <div className="w-full h-screen fixed top-0 left-0 bg-[#020A1B] opacity-[85%] z-5"></div>
      <div className="fixed z-6 min-w-[300px] top-1/2 left-1/2 transform-50 bg-[#FAFCFF] rounded-md w-[320px] overflow-hidden">
        <div className="flex justify-end w-full border-b ">
          <img
            src={closeicon}
            alt="closeicon"
            className="cursor-pointer"
            onClick={showImageModal}
          />
        </div>
        <div>
          {pathname === "/report/inspection" ||
          pathname === "/report/diary" ||
          pathname === "/myinfo/eachnews" ? (
            <img
              src={common.networkImg + encodeURIComponent(imageurl)}
              alt="imageurl"
              className="w-[320px]"
            />
          ) : hasimageData && hasimageData.length > 0 ? (
            <img
              src={common.networkImg + imageurl}
              alt="imageurl"
              className="w-[320px]"
            />
          ) : (
            <img src={imageurl} alt="imageurl" className="w-[320px]" />
          )}
        </div>
      </div>
    </>
  );
};

export default ImageModal;
