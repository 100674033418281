import React, { useState } from "react";
import common, { api } from "../../../../../data/common";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
/*component*/
import CenterModal from "../../../mobilecomponent/CenterModal";

const DeleteAppuse = ({ showDeleteAppuse, dataquestionID, setEditDel }) => {
  const navigate = useNavigate();
  const [transbg, setTransBg] = useState(false);
  const deleteAppuseId = () => {
    setTransBg(true);
    api
      .post(common.baseURL + "question/delete/" + dataquestionID)
      .then((res) => {
        console.log(res);
        if (res.data.ok === true) {
          showDeleteAppuse();
          setEditDel(false);
          toast(`삭제되었습니다.`, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: "transparent toastbottom",
          });
          let start = setTimeout(() => {
            navigate("/myinfo/appuse");
            setTransBg(false);
          }, 2000);
          return () => {
            clearTimeout(start);
          };
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      {transbg ? (
        <div className="w-full h-full bg-transparent z-30 fixed top-0 left-0"></div>
      ) : (
        ""
      )}

      <CenterModal showDeleteAppuse={showDeleteAppuse}>
        <span className="pb-[24px] block text-center">
          앱문의를 삭제하시겠습니까?
        </span>
        <div className="flex gap-x-2 w-full">
          <span
            className="inine-block w-1/2 border border-[#1B5EDC] bg-[#FAFCFF] text-[#1B5EDC] hover:bg-[#F2F6FC] hover:border-[#18428E] hover:text-[#18428E] ease-in-out duration-300 p-[16px] text-center rounded-sm cursor-pointer"
            onClick={deleteAppuseId}
          >
            삭제
          </span>
          <span
            className="inine-block w-1/2 border border-[#1B5EDC] bg-[#FAFCFF] text-[#1B5EDC] hover:bg-[#F2F6FC] hover:border-[#18428E] hover:text-[#18428E] ease-in-out duration-300 p-[16px] text-center rounded-sm cursor-pointer"
            onClick={showDeleteAppuse}
          >
            취소
          </span>
        </div>
      </CenterModal>
    </>
  );
};

export default DeleteAppuse;
