import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useMainContext } from "../../../../contexts/Provider";
/*component*/
import GobackPage from "../../mobilecomponent/GoBackPage";
import DateList from "./components/DateList";
import ReportTab from "./components/ReportTab";
import MainIcons from "../../mobilecomponent/MainIcons";
import SkeletonLoading from "../../mobilecomponent/SkeletonLoading";

import common, { api } from "../../../../data/common";

const Report = () => {
  const navigate = useNavigate();
  const { loginInput } = useMainContext();
  const [inspection, setInspection] = useState(false);
  const [diary, setDiary] = useState(false);
  const [graph, setGraph] = useState(false);
  const [inspectJan, setInspectJan] = useState([]);
  const [inspectFeb, setInspectFeb] = useState([]);
  const [inspectMar, setInspectMar] = useState([]);
  const [inspectApr, setInspectApr] = useState([]);
  const [inspectMay, setInspectMay] = useState([]);
  const [inspectJun, setInspectJun] = useState([]);
  const [inspectJul, setInspectJul] = useState([]);
  const [inspectAug, setInspectAug] = useState([]);
  const [inspectOct, setInspectOct] = useState([]);
  const [inspectSep, setInspectSep] = useState([]);
  const [inspectNov, setInspectNov] = useState([]);
  const [inspectDec, setInspectDec] = useState([]);
  const [diaryJan, setDiaryJan] = useState([]);
  const [diaryFeb, setDiaryFeb] = useState([]);
  const [diaryMar, setDiaryMar] = useState([]);
  const [diaryApr, setDiaryApr] = useState([]);
  const [diaryMay, setDiaryMay] = useState([]);
  const [diaryJun, setDiaryJun] = useState([]);
  const [diaryJul, setDiaryJul] = useState([]);
  const [diaryAug, setDiaryAug] = useState([]);
  const [diaryOct, setDiaryOct] = useState([]);
  const [diarySep, setDiarySep] = useState([]);
  const [diaryNov, setDiaryNov] = useState([]);
  const [diaryDec, setDiaryDec] = useState([]);
  const [thisyear, setThisYear] = useState({ year: dayjs().format("YYYY") });
  const [cansurvey, setCanSurvey] = useState();

  const { tabWhat } = useMainContext();
  console.log(tabWhat);

  const inspectionDateShow = () => {
    setInspection(true);
    setDiary(false);
    setGraph(false);
  };
  const diaryDateShow = () => {
    setInspection(false);
    setDiary(true);
    setGraph(false);
  };
  const graphDateShow = () => {
    setInspection(false);
    setDiary(false);
    setGraph(true);
  };

  useEffect(() => {
    let pageOpen = false;
    const getPatientData = () => {
      api.get(common.baseURL + "patient/Id/" + loginInput.id).then((res) => {
        if (res.data.ok === true) {
          console.log(res.data, "patient/Id/");
          setCanSurvey(res.data);
        }
      });
    };
    if (!pageOpen) {
      if (
        loginInput.id === null ||
        loginInput.id === undefined ||
        loginInput.id === ""
      ) {
        navigate("/main", { replace: true });
      } else {
        getPatientData();
        // tab 눌렀을 때 실행 & 뒤로왔을 때 실행
        if (tabWhat.current === "check") {
          inspectionDateShow();
        } else if (tabWhat.current === "diary") {
          diaryDateShow();
        } else {
          graphDateShow();
        }
      }
    }

    return () => {
      pageOpen = true;
    };
  }, [loginInput.id, navigate, tabWhat]);
  console.log(inspectSep);
  return (
    <>
      {/* {loading ? <SkeletonLoading /> : ""} */}
      <div className="h-screen bg-[#F1F2F3] relative overflow-y-auto">
        <GobackPage children={"기록보기"} />
        <ReportTab
          inspection={inspection}
          diary={diary}
          graph={graph}
          inspectionDateShow={inspectionDateShow}
          thisyear={thisyear}
          setThisYear={setThisYear}
          diaryDateShow={diaryDateShow}
          graphDateShow={graphDateShow}
          setInspectJan={setInspectJan}
          setInspectFeb={setInspectFeb}
          setInspectMar={setInspectMar}
          setInspectApr={setInspectApr}
          setInspectMay={setInspectMay}
          setInspectJun={setInspectJun}
          setInspectJul={setInspectJul}
          setInspectAug={setInspectAug}
          setInspectNov={setInspectNov}
          setInspectOct={setInspectOct}
          setInspectSep={setInspectSep}
          setInspectDec={setInspectDec}
          setDiaryJan={setDiaryJan}
          setDiaryFeb={setDiaryFeb}
          setDiaryMar={setDiaryMar}
          setDiaryApr={setDiaryApr}
          setDiaryMay={setDiaryMay}
          setDiaryJun={setDiaryJun}
          setDiaryJul={setDiaryJul}
          setDiaryAug={setDiaryAug}
          setDiaryNov={setDiaryNov}
          setDiaryOct={setDiaryOct}
          setDiarySep={setDiarySep}
          setDiaryDec={setDiaryDec}
        />
        <div>
          {inspection || diary || graph ? (
            <DateList
              cansurvey={cansurvey}
              inspection={inspection}
              diary={diary}
              graph={graph}
              thisyear={thisyear}
              setThisYear={setThisYear}
              inspectJan={inspectJan}
              inspectFeb={inspectFeb}
              inspectMar={inspectMar}
              inspectApr={inspectApr}
              inspectMay={inspectMay}
              inspectJun={inspectJun}
              inspectJul={inspectJul}
              inspectAug={inspectAug}
              inspectNov={inspectNov}
              inspectOct={inspectOct}
              inspectSep={inspectSep}
              inspectDec={inspectDec}
              setInspectJan={setInspectJan}
              setInspectFeb={setInspectFeb}
              setInspectMar={setInspectMar}
              setInspectApr={setInspectApr}
              setInspectMay={setInspectMay}
              setInspectJun={setInspectJun}
              setInspectJul={setInspectJul}
              setInspectAug={setInspectAug}
              setInspectNov={setInspectNov}
              setInspectOct={setInspectOct}
              setInspectSep={setInspectSep}
              setInspectDec={setInspectDec}
              diaryJan={diaryJan}
              diaryFeb={diaryFeb}
              diaryMar={diaryMar}
              diaryApr={diaryApr}
              diaryMay={diaryMay}
              diaryJun={diaryJun}
              diaryJul={diaryJul}
              diaryAug={diaryAug}
              diaryNov={diaryNov}
              diaryOct={diaryOct}
              diarySep={diarySep}
              diaryDec={diaryDec}
              setDiaryJan={setDiaryJan}
              setDiaryFeb={setDiaryFeb}
              setDiaryMar={setDiaryMar}
              setDiaryApr={setDiaryApr}
              setDiaryMay={setDiaryMay}
              setDiaryJun={setDiaryJun}
              setDiaryJul={setDiaryJul}
              setDiaryAug={setDiaryAug}
              setDiaryNov={setDiaryNov}
              setDiaryOct={setDiaryOct}
              setDiarySep={setDiarySep}
              setDiaryDec={setDiaryDec}
            />
          ) : (
            ""
          )}
        </div>
      </div>

      <MainIcons />
    </>
  );
};

export default Report;
