import dayjs from "dayjs";
import "dayjs/locale/ko";
import React, { useEffect, useRef, useState } from "react";
import { Flip, toast, ToastContainer } from "react-toastify";
import { useMainContext } from "../../../../contexts/Provider";
import common, { api } from "../../../../data/common";
import { BtnBlue } from "../../admin_components/Buttons";
/*component*/
import Header2 from "../../admin_components/Header/Header2";
import { Loading } from "../../admin_components/Loading";
import TableBlue from "../../admin_components/TableBlue";
import { toastCommonProps } from "../../admin_components/Toast";

export function Inquiry() {
  const dataRef = useRef([]); // 표기할 정보 담기
  /*** 검색 관련 state, ref ***/
  const [data, setData] = useState([]); // 표기할 정보 담기
  const [search, setSearch] = useState("");
  const searchRef = useRef();
  const [loading, setLoading] = useState(false); // 비동기 처리
  const search_icon = "/images/admin/header_dash/search.svg";
  const showAllRef = useRef(false); // 전체보기 버튼 보여줄지 말지
  const { adminLogin } = useMainContext(); // 로그인 정보 Consumer
  console.log(adminLogin);
  // 검색버튼을 누르고 search 가 바뀌었을 시
  useEffect(() => {
    const newData = dataRef.current.filter((el) => {
      return el.question.content.includes(search);
    });
    if (search !== "") {
      if (newData.length === 0) {
        toast(
          <p>검색된 내용이 없습니다.</p>,
          toastCommonProps(
            2000,
            "top-right",
            "toast_common toast__search",
            Flip
          )
        );
        setSearch("");
        searchRef.current.value = ""; // 검색창 빈칸으로 만들기
      } else {
        setData(newData);
        showAllRef.current = true;
      }
    }
  }, [search]);
  // 전체 보기 클릭시
  const onClickShowAll = () => {
    setData(dataRef.current);
    setSearch("");
    searchRef.current.value = ""; // 검색창 빈칸으로 만들기
    showAllRef.current = false;
  };

  // 첫 랜더시 데이터 불러오기(비동기 처리)
  useEffect(() => {
    // 질문 불러오기
    async function fetchData() {
      setLoading(true);
      const response = await api.get(common.baseURL + "question");
      // console.log(response, "response");
      dataRef.current = response.data.questionList;
      setData(dataRef.current === undefined ? [] : dataRef.current);
      setLoading(false);
    }
    fetchData();
  }, []);

  /******************************* Table UI 관련 변수 & 함수들  ******************************/
  // Table에 들어갈 column명
  const columns = [
    {
      accessor: "idx",
      Header: "No",
      width: "5%",
    },
    {
      accessor: "category",
      Header: "분야",
      width: "8%",
    },
    {
      accessor: "wait",
      Header: "답변 여부",
      width: "5%",
    },
    {
      accessor: "title",
      Header: "제목",
      width: "10%",
    },
    {
      accessor: "content",
      Header: "내용",
      width: "15%",
    },
    {
      accessor: "writer",
      Header: "작성자",
      width: "12%",
    },
    {
      accessor: "newflag",
      Header: "구분",
      width: "10%",
    },
    {
      accessor: "updatedAt",
      Header: "문의일",
      width: "10%",
    },
    {
      accessor: "createdAt",
      Header: "처리일",
      width: "10%",
    },
  ];
  // Pagination위해 한페이지 당 보여줄 내용 다듬어서 내보냄

  function currentPosts(data) {
    console.log(data);
    if (data === undefined) data = [];

    let newData = [];
    let count = 1;

    let process = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].answer === undefined) process.push(i);
    }
    process = process.reverse();

    for (let i = 0; i < process.length; i++) {
      const item = data.splice(process[i], 1);
      data.splice(0, 0, item[0]);
      for (let k = 0; k < process.length; k++) process[k] = process[k] + 1;
    }

    newData = data.map((d, idx) => {
      return { ...d, idx: idx + count };
    });

    let currentPosts = newData;
    for (let i = 0; i < currentPosts.length; i++) {
      currentPosts[i].idx = currentPosts.length - i;
    }
    const category_list = [
      "서비스 문의",
      "장애 및 불편신고",
      "제안사항",
      "기타",
    ];
    console.log(currentPosts);
    return currentPosts.map((post, idx) => {
      let newCategory = "";
      let newContent =
        post.question.content.length > 20
          ? post.question.content.slice(-20)
          : post.question.content;
      let newWriter = `${post.question.patient.name}(${post.question.patient.patientID})`;
      let newUpdatedAt = dayjs(new Date(post.question.createdAt)).format(
        "YYYY.MM.DD"
      );
      let newCreatedAt = post.answer
        ? dayjs(new Date(post.answer.createdAt)).format("YYYY.MM.DD")
        : "-";
      let newflag = post.question.patient.belong.name;
      newCategory = category_list[post.question.category];
      return {
        ...post,
        category: newCategory,
        wait: 0,
        content: newContent,
        writer: newWriter,
        updatedAt: newUpdatedAt,
        createdAt: newCreatedAt,
        newflag: newflag,
        title: post.question.title,
      };
    });
  }

  return (
    <Header2 title={[{ title: "이용 문의", url: 0 }]}>
      <ToastContainer />
      <div className="admin flex flex-col grow p-[32px]">
        <div className="h-[80px] min-h-[80px]"></div>
        <label className="text-2xl font-extrabold leading-8 text-[#020A1B]">
          이용 문의
        </label>

        {loading ? (
          <Loading />
        ) : (
          <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-3 min-w-[975px]">
            <div className="flex items-center justify-end">
              {/* 검색하기 */}
              <div className="flex gap-x-2 ml-[10px]">
                <div className="flex items-center w-[290px] h-[38px] border border-[#CCCDD1] box-border px-6 py-2 rounded-[48px]">
                  <img
                    src={search_icon}
                    alt="search"
                    className="w-[16.5px] h-[16.6px] mr-[9.69px]"
                  />
                  <input
                    type="text"
                    className="w-[210px] h-[22px] bg-transparent text-[#4E535F] focus:outline-none placeholder:font-semibold placeholder:text-base placeholder:leading-[22px] placeholder:text-[#4E535F]"
                    placeholder="내용으로 검색하기"
                    ref={searchRef}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") setSearch(searchRef.current.value);
                    }}
                  />
                </div>
                <BtnBlue
                  onClick={() => setSearch(searchRef.current.value)}
                  context="검색"
                  style={{
                    width: "76px",
                    height: "38px",
                    borderRadius: "48px",
                  }}
                />
              </div>
            </div>
            {loading === false ? (
              <div className="mt-[20px] y-[10px]">
                <TableBlue
                  columns={columns}
                  data={currentPosts(data)}
                  pageMove={"/admin/main/inquiryid"}
                  sorting={false}
                  pagination={true}
                  showAll={showAllRef.current ? onClickShowAll : false}
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </Header2>
  );
}

export default Inquiry;
