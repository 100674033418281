import React from "react";
const PyodermaGangrene = ({ btn4, bigTitle }) => {
  const image4 = "/images/mo/jangrucont/피부합병증4.jpg";
  return (
    <div className="w-full flex flex-col  px-[16px] body_1_400_jangrucont wordbreak-keepall">
      <div className="pt-[72px] ">
        <div className="flex justify-center flex-col items-center w-full border-b">
          <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
          <h1 className="h2_800 pt-[4px] pb-[24px]">{btn4}</h1>
        </div>

        <div className=" w-full pt-[24px]">
          <div>
            <div className="subtitle_1_700 pb-[8px]">1) 정의</div>
            <div className="w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
              <img src={image4} alt="image4" className="h-full" />
            </div>
            <div className="pt-[8px]">
              <span className="inline-block">
                장루 주위의 붉은 상처와 보랏빛 피부의 모양의 궤양이 생긴 것을
                말합니다.
              </span>
            </div>
          </div>
          <div className="pt-[32px]">
            <div className="subtitle_1_700 pb-[8px]">2) 관리방법</div>
            <div className="pt-[8px]">
              <div className="flex  ">
                <span className="inline-block">1.&nbsp;</span>
                <div>병원을 방문하여 의사와 상담을 합니다.</div>
              </div>
              <div className="flex  ">
                <span className="inline-block">2. &nbsp;</span>
                <div>
                  상처부위에 국소적 스테로이드를 사용합니다. 장루용 파우더를
                  사용한 후 피부보호 스프레이를 사용합니다.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PyodermaGangrene;
