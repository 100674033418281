import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import "../style/landing.css";
import ExplainPolicy from "./admin/pages/Login/components/ExplainPolicyPopup";
import { baseURL } from "../data/common";

const Landing = () => {
  const [resize, setResize] = useState();
  const [selected, setSelected] = useState(1);
  const navigate = useNavigate();
  const [policyOpen, setPolicyOpen] = useState(false); // 이용약관 설명 popup open 여부
  const [clickPolicy, setClickPolicy] = useState(null);

  // 마운트시 화면 가로 크기 구함
  useEffect(() => {
    window.addEventListener("resize", () => {
      // addEventListener 새로고침 안된 상태에서 화면 넓이가 달라질 때 CSS 적용
      setResize(window.innerWidth);
    });

    // setTimeout 새로고침 시 처음에 화면 넓이를 가져옴
    const time = setTimeout(() => {
      //   console.log(window.innerWidth);
      setResize(window.innerWidth);
    }, 0);

    return () => {
      window.removeEventListener("resize", () => {
        setResize(window.innerWidth);
      });

      clearTimeout(time);
    };
  }, []);

  const section2Data = [
    {
      id: 1,
      title: "장루 모니터링",
      detail1: "나의 장루 상태를 체크하고",
      detail2: "장루 간호사의 피드백을 받아보세요!",
    },
    {
      id: 2,
      title: "건강 다이어리",
      detail1: "기록을 통해 나의 건강상태를",
      detail2: "바로 알 수 있어요.",
    },
    {
      id: 3,
      title: "장루의 모든 것",
      detail1: "장루에 관한 다양한 정보와",
      detail2: "새소식을 확인해요.",
    },
    // {
    //   id: 4,
    //   title: "커뮤니티",
    //   detail1: "더 이상 혼자가 아니에요.",
    //   detail2: "함께 해요!",
    // },
  ];

  const section4Data = [
    {
      id: 1,
      title: "Dashboard",
      detail1: "참여자 정보 요약 및 장루 점검 대상자의",
      detail2: "점검 완료 건수를 확인 할 수 있습니다.",
    },
    {
      id: 2,
      title: "관리자 등록/관리",
      detail1: "병원 내 관리자를 등록하고",
      detail2: "권한 설정을 할 수 있습니다.",
    },
    {
      id: 3,
      title: "대상자 등록/관리",
      detail1: "관리 대상자를 등록하고 정보관리",
      detail2: "및 모니터링 할 수 있습니다.",
    },
    {
      id: 4,
      title: "장루점검",
      detail1: "장루점검 및 상세기록, 환자 점검 보고서를",
      detail2: "출력하여 종합적인 점검기록을 관리할 수 있습니다.",
    },
    // {
    //   id: 5,
    //   title: "커뮤니티 관리",
    //   detail1: "커뮤니티 게시글 관리 및 댓글을",
    //   detail2: "작성할 수 있습니다.",
    // },
    {
      id: 6,
      title: "쪽지 관리",
      detail1: "대상자별 쪽지 발송 및 관리가 가능합니다.",
    },
  ];

  const section5Data = [
    {
      sort: "특허",
      title1: "장루환자 비대면 서비스",
      title2: "제공 방법 및 그 시스템",
      info: {
        출원인: "헤링스",
        출원번호: "10-2022-0041565",
        출원일: "2022.04.04",
        등록번호: "심사진행중",
      },
    },
    {
      sort: "소프트웨어 저작권",
      title1: "장루 모니터링 시스템",
      title2: "환자용 앱",
      info: {
        등록번호: "C-2023-011543",
        등록일: "2023.02.21",
      },
    },
    {
      sort: "소프트웨어 저작권",
      title1: "장루 모니터링 시스템",
      title2: "의료진용 웹",
      info: {
        등록번호: "C-2022-024267",
        등록일: "2022.06.16",
      },
    },
  ];

  const mobileTypeFn = () => {
    const uagent = navigator.userAgent.toLowerCase();
    const android_agent = uagent.search("android");
    const iphone = uagent.search("iphone");
    const ipad = uagent.search("ipad");
    const mac = uagent.search("mac");

    if (android_agent > -1) {
      //안드로이드
      return "android";
    } else if (iphone > -1 || ipad > -1 || mac > -1) {
      //아이폰
      return "iphone";
    } else return "pc";
    // return uagent;
  };

  const MoveAppStore = () => {
    // console.log(mobileTypeFn());
    if (mobileTypeFn() === "iphone") {
      window.location.replace(
        "https://apps.apple.com/kr/app/id1619734796",
        "_blank"
      );
      setTimeout(() => {
        window.close();
      }, 1000);
    } else {
      window.location.replace(
        `https://play.google.com/store/apps/details?id=com.herings.stoma`,
        "_blank"
      );
      setTimeout(() => {
        window.close();
      }, 1000);
    }
  };

  useEffect(() => {
    if (clickPolicy) {
      setPolicyOpen(true);
    }
  }, [clickPolicy]);

  if (resize > 1200) {
    return (
      <div>
        <ExplainPolicy
          policyOpen={policyOpen}
          setPolicyOpen={setPolicyOpen}
          clickPolicy={clickPolicy}
        />
        <header className="flex items-center justify-between px-[360px] h-20">
          <h2 className="text-[#1B5EDC] montserrat_700 text-[28px] leading-[34px]">
            Osto Care
          </h2>
          <button
            onClick={() => {
              navigate("/admin/serviceRegister");
            }}
            className="bg-[#3371E5] rounded-lg px-5 py-[18px] text-white text-[17px] font-medium leading-none hover:bg-[#1B5EDC]"
          >
            Osto Care 서비스신청
          </button>
        </header>
        <section className="h-[805px] bg-[#3371E5] relative overflow-hidden px-[360px]">
          <div className="flex flex-col text-white gap-7 mt-[230px] z-10 relative">
            <h2 className="text-[40px] font-GMS_Medium">
              장루 관리가 필요할 땐
            </h2>
            <h2 className="text-[80px] font-GMS_Bold">오스토 케어</h2>
          </div>
          <div className="mt-[54px] leading-none flex gap-4 z-10 relative font-semibold ">
            <button
              className="bg-white text-[#1B5EDC] px-6 py-[18px] rounded-lg hover:opacity-80"
              onClick={MoveAppStore}
            >
              앱서비스 바로가기
            </button>
            <button
              className="bg-white text-[#1B5EDC] px-6 py-[18px] rounded-lg hover:opacity-80"
              onClick={() => navigate("/admin")}
            >
              웹관리자 바로가기
            </button>
          </div>
          <img
            src="/images/landing/section1_re.png"
            alt="오스토케어"
            className="absolute bottom-0 min-w-[1344px] h-[1332px] -z-0 -top-36 mx-auto pl-10"
          />
        </section>
        <section className="h-[1280px] bg-[#CEDFFF] py-[128px] flex flex-col items-center">
          <img
            src="/images/landing/pocketboy.png"
            alt="pocketboy"
            className="mx-auto mb-4"
          />
          <h2 className="text-[#1B5EDC] montserrat_700 text-[40px] leading-[34px] text-center mb-[30px]">
            Osto Care
          </h2>
          <p className="lg:mx-[360px] text-center px-12 font-semibold leading-[36px] text-[22px] mb-24 min-w-[960px]">
            오스토케어는 장루담당 의료진이 장루환자 비대면 관리를 쉽게 하기 위해
            개발된 플랫폼입니다. <br />
            서울대학교병원의 자문과 고찰을 기초하여 개발되었으며,
            재택의료시범사업에 참여하는 병원들에게 <br />
            사업기간 동안 모두 무료로 제공하고 있습니다.
          </p>
          <div className="flex gap-[28px] mx-auto w-fit">
            {section2Data.map(({ id, title, detail1, detail2 }) => (
              <div key={id} className="flex flex-col items-center w-64 gap-4">
                <img
                  src={`/images/landing/section2_${id}.png`}
                  alt={title}
                  className="w-[200px]"
                />
                <div className="font-GMS_Bold text-[22px] text-[#1B5EDC]">
                  {title}
                </div>
                <div>
                  <p className="text-center text-[#4E535F]">{detail1}</p>
                  <p className="text-center text-[#4E535F]">{detail2}</p>
                </div>
              </div>
            ))}
          </div>
        </section>
        <section className="h-[1180px] bg-[#00274B] box-border py-[200px]">
          <div className="font-GMS_Bold text-[32px] text-[#EFF2F6] mx-auto w-fit text-center mb-11">
            <p>
              <span
                className="text-[#FDF1B5]"
                style={{ textEmphasis: "filled", textEmphasisColor: "#FDF1B5" }}
              >
                장루교체
              </span>
              가 어려우신 분들 주목!
            </p>
            <p>
              오스토케어가&nbsp;
              <span
                className="text-[#FDF1B5]"
                style={{ textEmphasis: "filled", textEmphasisColor: "#FDF1B5" }}
              >
                바로
              </span>
              &nbsp;알려드릴게요!
            </p>
          </div>
          <div className="mx-auto w-fit border-8 border-[#F3F5FA] rounded-[28px] relative cursor-pointer ">
            <iframe
              width="920"
              height="540"
              src="https://www.youtube.com/embed/GgveXSRIR0E"
              title="[오스토미 케어] 원피스형 장루 교체 방법"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className="rounded-[20px]"
            ></iframe>
          </div>

          <div
            className="text-[#00274B] font-semibold text-[28px] leading-[50px] flex items-center gap-4 bg-white w-[610px] mx-auto justify-center py-2 rounded-2xl mt-[70px] cursor-pointer"
            onClick={() => {
              window.open(
                `https://www.youtube.com/watch?v=GgveXSRIR0E`,
                "_blank"
              );
            }}
          >
            <img
              src="/images/landing/youtube_logo.svg"
              alt="유튜브"
              className="h-8 w-11 "
            />
            유튜브채널 바로가기
          </div>
        </section>
        <section className="h-[1350px] bg-[#CEDFFF] py-[128px] box-border">
          <>
            <img
              src="/images/landing/flatboy.png"
              alt="flatboy"
              className="mx-auto mb-4"
            />
            <h2 className="text-[#1B5EDC] montserrat_700 text-[40px] leading-[34px] text-center mb-[30px]">
              Osto Care 관리자
            </h2>
            <p className="mx-auto text-center font-medium  leading-[40px] text-2xl mb-24 min-w-[920px]">
              오스토케어 관리자는 Osto Care(오스토 케어)APP을 통한 장루 환자들의
              상태를 주기적으로 확인하고 <br /> 장루점검 및 상세기록을 통합관리
              할 수 있는 서비스 입니다.
            </p>
          </>
          <div className="flex mx-auto w-fit gap-[100px]">
            <ul className="flex flex-col gap-5 mt-[40px] cursor-pointer">
              {section4Data.map(({ id, title }) => (
                <li
                  key={id}
                  className={`flex gap-[10px] items-center rounded-[14px] w-[260px] h-[64px] px-[10px] box-border border-[3px] border-white ${
                    selected === id ? "bg-white" : "bg-transparent"
                  }`}
                  onClick={() => setSelected(id)}
                >
                  <img src={`/images/landing/section4_${id}.svg`} alt={title} />
                  <p className="text-[#1B5EDC] font-medium font-GMS_Bold ">
                    {title}
                  </p>
                </li>
              ))}
            </ul>

            <div className="relative">
              <img src="/images/landing/iMac.png" alt="" className="" />
              {section4Data.map(
                ({ id, title, detail1, detail2 }) =>
                  selected === id && (
                    <div key={id}>
                      <img
                        src={`/images/landing/full_screen_${id}_re.png`}
                        alt={title}
                        className="absolute top-[26px] left-[24px] w-[638px] h-[350px]"
                      />
                      <div className="absolute top-[600px] left-1/2 -translate-x-1/2 text-center text-[#4E535F] font-semibold text-[22px] w-full">
                        <p>{detail1}</p>
                        <p>{detail2}</p>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        </section>
        <section className="bg-[#F4F7FF] py-[128px] box-border">
          <h2 className="text-[#1B5EDC] montserrat_700 text-[40px] leading-[50px] text-center mb-4">
            Osto Care 연구
          </h2>
          <p className="mx-auto text-center font-medium leading-[40px] text-2xl mb-24 min-w-[920px]">
            오스토케어는 2022년 장루 모니터링 시스템 앱, 웹의 소프트웨어
            저작권을 등록했습니다. <br /> 장루환자 비대면 서비스 제공 방법 및
            시스템 특허를 출원하여 지속적인 원격 환자 모니터링 연구를 수행하고
            있습니다.
          </p>
          <div className="flex mx-auto w-fit gap-9 ">
            {section5Data.map(({ sort, title1, title2, info }) => (
              <div className="p-6 bg-white rounded-xl border border-[#E8E8E8] flex flex-col justify-between gap-11 ">
                <div>
                  <h4
                    className={`text-base font-semibold mb-[6px] ${
                      sort === "특허" ? "text-[#FF8A00]" : "text-[#003BD2]"
                    }`}
                  >
                    {sort}
                  </h4>
                  <p className="text-[#393939] text-xl leading-8 font-bold">
                    {title1} <br />
                    {title2}
                  </p>
                </div>
                <div className="grid grid-cols-2 text-sm gap-x-5 gap-y-1 w-[370px]">
                  {Object.keys(info)?.map((key) => (
                    <p className="flex">
                      <span className="w-14 text-[#444444] font-semibold">
                        {key}
                      </span>
                      <span>{info[key]}</span>
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>
        <section className="relative ">
          <img
            src="/images/landing/section5_bg.png"
            alt="배경이미지"
            className="h-[440px]"
          />
          <div className="absolute z-10 flex flex-col text-[#3371E5] left-[360px] top-[117px]">
            <h2 className="text-[22px] font-GMS_Medium">
              장루 관리가 필요할 땐
            </h2>
            <h2 className="text-[80px] font-GMS_Bold leading-none mt-3 mb-8">
              오스토 케어
            </h2>
            <div className="flex gap-[10px] font-semibold leading-none">
              <button
                className="bg-[#1F2125] text-white px-6 py-[18px] rounded-lg hover:opacity-80"
                onClick={MoveAppStore}
              >
                앱서비스 바로가기
              </button>
              <button
                className="bg-[#1F2125] text-white px-6 py-[18px] rounded-lg hover:opacity-80"
                onClick={() => navigate("/admin")}
              >
                웹관리자 바로가기
              </button>
            </div>
          </div>
        </section>
        <footer className="flex justify-between items-center px-[360px] py-[50px] ">
          <div className="flex items-center gap-3 mr-3">
            <img
              src="/images/admin/header_dash/herings_logo.svg"
              alt="오스토케어"
              className="w-14 h-14"
            />
            <div className="flex justify-start font-extrabold text-[#1B5EDC] text-2xl mb-1 ml-1 whitespace-nowrap">
              OstoCare
            </div>
            <div className="hidden w-full ml-3 xl:block">
              <p className="text-sm">서울시 강남구 언주로 560, 14층</p>
              <p className="text-[10px] font-normal">
                Copyright © 2022 HERINGS. All rights reserved.{`   `}
                <span
                  className="cursor-pointer hover:text-[#020A1B] hover:font-bold"
                  onClick={() => {
                    setClickPolicy("service");
                  }}
                >
                  이용약관
                </span>
                {`  |  `}
                <span
                  className="cursor-pointer hover:text-[#020A1B] hover:font-bold"
                  onClick={() => {
                    setClickPolicy("personalInfo");
                  }}
                >
                  개인정보처리방침
                </span>
              </p>
            </div>
          </div>
          <button
            className="seviceBtn flex justify-center items-center h-[64px] px-[10px] min-w-fit py-[21px] text-base text-[#1B5EDC] leading-[22px] font-[700] border border-[#1B5EDC] rounded-[1px] hover:bg-[#1B5EDC] hover:text-[#FFFFFF]"
            onClick={() => {
              navigate("/admin/serviceRegister");
            }}
          >
            OstoCare 서비스 신청 ﹥
          </button>
        </footer>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col items-center w-screen">
        <section
          className="h-[600px] bg-[#3371E5] relative min-w-full"
          style={{
            backgroundImage: "url('/images/landing/section1_re.png')",
            backgroundPosition: "50% 35%",
            backgroundSize: "250%",
          }}
        >
          <div className="absolute -bottom-40 bg-[#3371E5] w-full py-5 flex flex-col items-center mx-auto">
            <div className="z-10 text-center text-white gap-7">
              <h2 className="text-[24px] font-GMS_Medium">
                장루 관리가 필요할 땐
              </h2>
              <h2 className="text-[40px] font-GMS_Bold">오스토 케어</h2>
            </div>
            <button
              className="bg-white text-[#1B5EDC] px-6 py-[18px] rounded-lg hover:opacity-80 font-semibold leading-none mx-auto w-[320px] my-6"
              onClick={MoveAppStore}
            >
              앱서비스 바로가기
            </button>
          </div>
        </section>
        <section className="mt-[160px] bg-[#CEDFFF] py-[40px] flex flex-col gap-4 max-w-sm min-w-full">
          <img
            src="/images/landing/pocketboy.png"
            alt="pocketboy"
            className="w-20 mx-auto"
          />
          <h2 className="text-[#1B5EDC] montserrat_700 text-[36px] leading-[26x] text-center ">
            Osto Care
          </h2>
          <p className="text-center font-semibold leading-[24px] text-[16px]">
            오스토케어는 장루담당 의료진이 <br /> 장루환자 비대면 관리를 <br />
            쉽게 하기 위해 개발된 플랫폼입니다.
          </p>
          <p className="text-center font-semibold leading-[22px] text-[13px] text-[#686E7C]">
            서울대학교병원의 자문과 고찰을 기초하여 개발되었으며,
            <br />
            재택의료시범사업에 참여하는 병원들에게
            <br /> 사업기간 동안 모두 무료로 제공하고 있습니다.
          </p>
          <Swiper
            modules={[Pagination]}
            centeredSlides={true} // 슬라이드 가운데 정렬
            loop={false} // 무한루프 (인덱스 이상함)
            loopedSlides={4} // 슬라이드 수를 지정해서 루프 사용
            slidesPerView={1} // 한 페이지에 슬라이드 출력 개수
            className="relative flex pb-20 my-4 section4"
            pagination={{
              clickable: true,
              bulletClass: "swiper-bullet",
              bulletActiveClass: "swiper-bullet-active",
            }}
          >
            {section2Data.map(({ id, title, detail1, detail2 }) => (
              <SwiperSlide
                key={id}
                className="flex flex-col items-center gap-3"
              >
                <img
                  src={`/images/landing/section2_mobile_${id}.png`}
                  alt={title}
                  className="mx-auto w-[200px]"
                />
                <h3 className="font-GMS_Bold text-[20px] text-[#1B5EDC]">
                  {title}
                </h3>
                <div>
                  <p className="text-center text-[#4E535F]">{detail1}</p>
                  <p className="text-center text-[#4E535F]">{detail2}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
        <section className="bg-[#00274B] py-[140px] flex flex-col gap-[45px] max-w-sm min-w-full">
          <div className="font-GMS_Bold text-[22px] text-[#EFF2F6] mx-auto text-center ">
            <p>
              <span
                className="text-[#FDF1B5]"
                style={{ textEmphasis: "filled", textEmphasisColor: "#FDF1B5" }}
              >
                장루교체
              </span>
              가 어려우신 분들 주목!
            </p>
            <p>
              오스토케어가&nbsp;
              <span
                className="text-[#FDF1B5]"
                style={{ textEmphasis: "filled", textEmphasisColor: "#FDF1B5" }}
              >
                바로
              </span>
              &nbsp;알려드릴게요!
            </p>
          </div>
          <div className="mx-auto w-fit border-8 border-[#F3F5FA] rounded-[28px] relative cursor-pointer">
            <iframe
              width="320"
              height="180"
              src="https://www.youtube.com/embed/GgveXSRIR0E"
              title="[오스토미 케어] 원피스형 장루 교체 방법"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className="rounded-[20px]"
            ></iframe>
          </div>

          <div
            className="text-[#00274B] font-semibold text-[16px] leading-[23px] flex items-center justify-center w-[320px] gap-4 py-5 mx-auto bg-white rounded-lg"
            onClick={() => {
              window.open(
                `https://www.youtube.com/watch?v=GgveXSRIR0E`,
                "_blank"
              );
            }}
          >
            <img
              src="/images/landing/youtube_logo.svg"
              alt="유튜브"
              className="h-8 w-11 "
            />
            유튜브채널 바로가기
          </div>
        </section>
        <section className="bg-[#CEDFFF] py-[50px] box-border flex flex-col gap-5 max-w-sm min-w-full">
          <img
            src="/images/landing/flatboy.png"
            alt="flatboy"
            className="w-20 mx-auto"
          />
          <h2 className="text-[#1B5EDC] montserrat_700 text-[36px] leading-[26px] text-center">
            Osto Care 관리자
          </h2>
          <p className=" text-center font-semibold leading-[24px] text-[16px]">
            오스토케어 관리자는 <br />
            장루 환자들의 상태를 주기적으로 확인하고
            <br />
            장루점검 및 상세기록을 통합관리 할 수 있는
            <br />
            서비스 입니다.
          </p>

          <p className="mx-auto text-[14px] font-semibold text-[#686E7C]">
            Osto Care관리자 페이지는 PC에 최적화 되어 있습니다.
          </p>

          <Swiper
            modules={[Pagination]}
            centeredSlides={true} // 슬라이드 가운데 정렬
            loop={false} // 무한루프 (인덱스 이상함)
            loopedSlides={4} // 슬라이드 수를 지정해서 루프 사용
            slidesPerView={1} // 한 페이지에 슬라이드 출력 개수
            className="relative flex pb-20 my-5 section4"
            pagination={{
              clickable: true,
              bulletClass: "swiper-bullet-section4",
              bulletActiveClass: "swiper-bullet-section4-active",
              horizontalClass: "swiper-section4-pagination",
            }}
          >
            {section4Data.map(({ id, title, detail1, detail2 }) => (
              <SwiperSlide
                key={id}
                className="flex flex-col items-center gap-5"
              >
                <img
                  src={`/images/landing/section4_mobile_${id}_re.png`}
                  alt={title}
                  className="w-[300px] mx-auto"
                />
                <div className="flex items-center gap-2 ">
                  <img
                    src={`/images/landing/section4_${id}.svg`}
                    alt={title}
                    className="w-5 mx-auto"
                  />
                  <h3 className="font-GMS_Bold text-[14px] text-[#1B5EDC]">
                    {title}
                  </h3>
                </div>
                <div className="text-sm font-bold">
                  <p className="text-center text-[#4E535F]">{detail1}</p>
                  <p className="text-center text-[#4E535F]">{detail2}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
        <section className="bg-[#F4F7FF] py-[50px] box-border flex flex-col gap-5 max-w-sm min-w-full">
          <h2 className="text-[#1B5EDC] montserrat_700 text-[36px] leading-[26px] text-center">
            Osto Care 연구
          </h2>

          <p className="text-center font-semibold leading-[24px] text-[16px]">
            오스토케어는 2022년 장루 모니터링 시스템
            <br />
            앱, 웹의 소프트웨어 저작권을 등록했습니다.
          </p>

          <p className="mx-auto text-[14px] text-[#737A89] text-center">
            장루환자 비대면 서비스 제공 방법 및 시스템 특허를
            <br />
            출원하여 지속적인 원격 환자 모니터링 연구를
            <br />
            수행하고 있습니다.
          </p>
          <div className="flex flex-col mt-7 mx-14 gap-9 mb-14">
            {section5Data.map(({ sort, title1, title2, info }) => (
              <div className="p-6 bg-white rounded-xl border border-[#E8E8E8] flex flex-col justify-between gap-5 max-w-xl w-full mx-auto">
                <div>
                  <h4
                    className={`text-sm font-semibold mb-[6px] ${
                      sort === "특허" ? "text-[#FF8A00]" : "text-[#003BD2]"
                    }`}
                  >
                    {sort}
                  </h4>
                  <p className="text-[#393939] leading-[25px] font-bold">
                    {title1} <br />
                    {title2}
                  </p>
                </div>
                <div className="flex flex-col gap-2 text-xs">
                  {Object.keys(info)?.map((key) => (
                    <p className="flex gap-2">
                      <span className="w-14 text-[#444444] font-semibold">
                        {key}
                      </span>
                      <span>{info[key]}</span>
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>
        <section
          className=" h-[500px] justify-center flex flex-col gap-8 items-center max-w-sm min-w-full"
          style={{
            backgroundImage: "url('/images/landing/section5_bg.png')",
            backgroundPositionX: "20%",
            // backgroundPositionY: "top 1px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <h2 className="text-[#3371E5] font-GMS_Bold leading-none text-5xl text-center">
            오스토 케어
          </h2>

          <button
            className="bg-[#33363C] text-white px-6 py-[18px] rounded-lg w-[320px]"
            onClick={MoveAppStore}
          >
            앱서비스 바로가기
          </button>
        </section>
        <footer className="max-w-sm p-6">
          <div className="">
            <p className="text-sm">서울시 강남구 언주로 560, 14층</p>
            <p className="text-[10px] font-normal">
              Copyright © 2022 HERINGS. All rights reserved.{`   `}
              {/* <span
                className="cursor-pointer hover:text-[#020A1B] hover:font-bold"
                onClick={() => {
                  setClickPolicy("service");
                }}
              >
                이용약관
              </span>
              {`  |  `}
              <span
                className="cursor-pointer hover:text-[#020A1B] hover:font-bold"
                onClick={() => {
                  setClickPolicy("personalInfo");
                }}
              >
                개인정보처리방침
              </span> */}
            </p>
          </div>
          <div className="flex items-center justify-between gap-5 mt-6">
            <div className="flex gap-2 items-center font-extrabold text-[#1B5EDC] whitespace-nowrap">
              <img
                src="/images/admin/header_dash/herings_logo.svg"
                alt="오스토케어"
                className="w-7"
              />
              OstoCare
            </div>
            {/* 서비스 신청 버튼 */}
            <button
              className="flex justify-center items-center h-[64px] px-[10px] py-[21px] text-[#1B5EDC] leading-[22px] font-[700] border border-[#1B5EDC] rounded-[1px] hover:bg-[#1B5EDC] hover:text-[#FFFFFF] min-w-fit"
              onClick={() => {
                navigate("/admin/serviceRegister");
              }}
            >
              OstoCare 서비스 신청 ﹥
            </button>
          </div>
        </footer>
      </div>
    );
  }
};

export default Landing;
