import React from "react";

const SkinAndMucosa = ({ bigTitle, btn4 }) => {
  const image13 = "/images/mo/jangrucont/장루합병증13.jpg";
  const image13_1 = "/images/mo/jangrucont/장루합병증13_1.jpg";
  const image13_2 = "/images/mo/jangrucont/장루합병증13_2.jpg";
  return (
    <div className="w-full flex flex-col  px-[16px] body_1_400 wordbreak-keepall">
      <div className="pt-[72px] ">
        <div className="flex justify-center flex-col items-center w-full border-b">
          <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
          <h1 className="h2_800 pt-[4px] pb-[24px]">{btn4}</h1>
        </div>
        <div className="w-full pt-[24px]">
          <div>
            <div className="subtitle_1_700 pb-[8px]">1) 정의</div>
            <div className="w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
              <img src={image13} alt="image13" className="h-full" />
            </div>
            <div className="pt-[8px]">
              장루 주위의 피부와 장점막이 분리되는 것을 말합니다.
            </div>
          </div>
          <div className="pt-[32px] body_1_400_jangrucont">
            <div className="subtitle_1_700 pb-[8px]">2) 관리방법</div>

            <div className="pt-[8px]">
              <div className="flex justify-end  ">
                <span className="inline-block">1.&nbsp;</span>
                <div>
                  장점막과 피부 사이를 세척한 후 장루용 파우더로 채워 넣습니다.
                  그 위에 피부 보호 스프레이를 적용합니다. 피부보호 링으로
                  분리된 부분을 가린 후 피부 보호판을 적용합니다.
                </div>
              </div>

              <span className="inline-block">
                2. 가능한 대변이 장점막과 피부 사이에 들어가지 않도록 합니다.
              </span>
            </div>
            <div className="mt-[8px] w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
              <img src={image13_1} alt="image13_1" className="h-full" />
            </div>
            <div className="mt-[8px] w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
              <img src={image13_2} alt="image13_2" className="h-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkinAndMucosa;
