import React from "react";
import { useNavigate } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import dayjs from "dayjs";
import GraphDefault from "./GraphDefault";
import ChartDataLabels from "chartjs-plugin-datalabels";

const ReportGraph = ({ diarydata }) => {
  console.log(diarydata);
  const navigate = useNavigate();
  // graph option
  let dayList = [];
  let weightList = [];
  let waterList = [];
  let poopList = [];
  let jangruChangeList = [];
  let maxWeight = 0; // 체중 최고 수치
  let maxWater = 0;
  let maxPoop = 0;

  if (diarydata.length > 7) {
    for (let i = 0; i < diarydata.length; i++) {
      dayList.unshift(
        dayjs(new Date(diarydata[i].diary.writeDate)).format("MM/DD")
      );
      weightList.unshift(diarydata[i].diary.weight);
      waterList.unshift(diarydata[i].totalWater);
      poopList.unshift(diarydata[i].totalToilet);
      jangruChangeList.unshift(diarydata[i].diary.jangruChange);
      // 최고 체중/수분량/배변량 계산
      if (diarydata[i].diary.weight > maxWeight)
        maxWeight = diarydata[i].diary.weight;
      if (diarydata[i].totalWater > maxWater)
        maxWater = diarydata[i].totalWater;
      if (diarydata[i].totalToilet > maxPoop)
        maxPoop = diarydata[i].totalToilet;
    }
  } else {
    for (let i = 0; i < diarydata.length; i++) {
      dayList.unshift(
        dayjs(new Date(diarydata[i].diary.writeDate)).format("MM/DD")
      );
      weightList.unshift(diarydata[i].diary.weight);
      waterList.unshift(diarydata[i].totalWater);
      poopList.unshift(diarydata[i].totalToilet);
      jangruChangeList.unshift(diarydata[i].diary.jangruChange);
      // 최고 체중/수분량/배변량 계산
      if (diarydata[i].diary.weight > maxWeight)
        maxWeight = diarydata[i].diary.weight;
      if (diarydata[i].totalWater > maxWater)
        maxWater = diarydata[i].totalWater;
      if (diarydata[i].totalToilet > maxPoop)
        maxPoop = diarydata[i].totalToilet;
    }
    for (let i = 0; i < 7 - diarydata.length; i++) {
      dayList.unshift("");
      weightList.unshift("");
      waterList.unshift("");
      poopList.unshift("");
      jangruChangeList.unshift("");
    }
  }
  // console.log(jangruChangeList, ">>jangruchange");

  const weightData = {
    labels: dayList.length !== 0 ? dayList.slice(0, 7).reverse() : [],
    datasets: [
      {
        label: "",
        data: weightList.length !== 0 ? weightList.reverse() : [],
        backgroundColor: ["#5FC17B"],
        borderWidth: 1,
        borderColor: "#5FC17B",
        borderRadius: 2,
      },
    ],
  };
  const waterData = {
    labels: dayList.length !== 0 ? dayList.slice(0, 7).reverse() : [],
    datasets: [
      {
        label: "",
        data: waterList.length !== 0 ? waterList.reverse() : [],
        backgroundColor: ["#1B5EDC"],
        borderWidth: 1,
        borderColor: "#1B5EDC",
        borderRadius: 2,
      },
    ],
  };
  const poopData = {
    labels: dayList.length !== 0 ? dayList.slice(0, 7).reverse() : [],
    datasets: [
      {
        label: "",
        data: poopList.length !== 0 ? poopList.reverse() : [],
        backgroundColor: ["#F7CE4B"],
        borderWidth: 1,
        borderColor: "#F7CE4B",
        borderRadius: 2,
      },
    ],
  };

  // 체중, 수분섭취량, 배변량 options 공통 plugins
  const commonPlugins = (max) => {
    return {
      plugins: {
        tooltip: {
          enabled: false,
        },
        datalabels: {
          color: function (context) {
            if (
              context.dataset.data[context.dataIndex] === 0 ||
              context.dataset.data[context.dataIndex] < Math.floor(max / 8)
            )
              return context.dataset.backgroundColor[0];
            else return "#FAFBFC";
          },
          anchor: function (context) {
            if (
              context.dataset.data[context.dataIndex] === 0 ||
              context.dataset.data[context.dataIndex] < Math.floor(max / 8)
            )
              return "end";
            else return "end";
          },
          align: function (context) {
            if (
              context.dataset.data[context.dataIndex] === 0 ||
              context.dataset.data[context.dataIndex] < Math.floor(max / 8)
            )
              return "end";
            else return "bottom";
          },
          font: function (context) {
            if (
              context.dataset.data[context.dataIndex] === 0 ||
              context.dataset.data[context.dataIndex] < Math.floor(max / 8)
            ) {
              return { lineHeight: 1.2, size: 12 };
            } else return { lineHeight: 1.5, size: 12 };
          },
        },
        legend: {
          display: false,
        },
      },
    };
  };

  const weightOptions = Object.assign(
    {
      responsive: true,
      indexAxis: "x",
      scales: {
        y: {
          beginAtZero: true,
          ticks: { display: false, stepSize: Math.floor(maxWeight / 4) },
        },
        x: {
          grid: { display: false },
          ticks: {
            autoskip: true,
          },
        },
      },
    },
    commonPlugins(maxWeight)
  );

  const waterOptions = Object.assign(
    {
      responsive: true,
      indexAxis: "x",
      scales: {
        y: {
          beginAtZero: true,
          ticks: { display: false, stepSize: Math.floor(maxWater / 4) },
        },
        x: {
          grid: { display: false },
        },
      },
    },
    commonPlugins(maxWater)
  );

  const poopOptions = Object.assign(
    {
      responsive: true,
      indexAxis: "x",
      scales: {
        y: {
          beginAtZero: true,
          ticks: { display: false, stepSize: Math.floor(maxPoop / 4) },
        },
        x: {
          grid: { display: false },
        },
      },
    },
    commonPlugins(maxPoop)
  );

  return (
    <>
      {diarydata.length > 0 ? (
        <div>
          <div className="mb-[24px] py-[16px] bg-[#FAFCFF] rounded-lg border">
            <div className="flex justify-between px-[24px] pb-[8px]">
              <strong className="h3_800">체중</strong>
              <span className="caption_1_600 text-[#999CA3]">kg</span>
            </div>
            <div className="weightgraph border-t bg-[#F1F2F3] relative mx-[16px] py-[20px] pr-2">
              <div className="absolute z-1 top-0 left-0 w-4 h-full bg-[#F1F2F3]"></div>
              <Bar
                options={weightOptions}
                data={weightData}
                plugins={[ChartDataLabels]}
                className="h-full flex p-[15px] cursor-pointer"
                onClick={() => {
                  navigate("/report/graphfull", {
                    state: { data: diarydata, title: "체중" },
                  });
                  // console.log(diarydata);
                }}
              />
              <div className="absolute z-1 top-0 right-0 w-4 h-full bg-[#F1F2F3]"></div>
            </div>
          </div>
          <div className="mb-[24px] py-[16px] bg-[#FAFCFF] rounded-lg border">
            <div className="flex justify-between px-[24px] pb-[8px]">
              <strong className="h3_800">수분섭취량</strong>
              <span className="caption_1_600 text-[#999CA3]">ml</span>
            </div>
            <div className="watergraph border-t bg-[#F1F2F3] relative mx-[16px] py-[20px] pr-2">
              <div className="absolute z-1 top-0 left-0 w-4 h-full bg-[#F1F2F3]"></div>
              <Bar
                options={waterOptions}
                data={waterData}
                plugins={[ChartDataLabels]}
                className="h-full flex p-[15px] cursor-pointer"
                onClick={() => {
                  navigate("/report/graphfull", {
                    state: { data: diarydata, title: "수분섭취량" },
                  });
                }}
              />
              <div className="absolute z-1 top-0 right-0 w-4 h-full bg-[#F1F2F3]"></div>
            </div>
          </div>
          <div className="mb-[24px] py-[16px] bg-[#FAFCFF] rounded-lg border">
            <div className="flex justify-between px-[24px] pb-[8px]">
              <strong className="h3_800">배변량</strong>
              <span className="caption_1_600 text-[#999CA3]">ml</span>
            </div>
            <div className="watergraph border-t bg-[#F1F2F3] relative mx-[16px] py-[20px] pr-2">
              <div className="absolute z-1 top-0 left-0 w-4 h-full bg-[#F1F2F3]"></div>
              <Bar
                options={poopOptions}
                data={poopData}
                plugins={[ChartDataLabels]}
                className="h-full flex p-[15px] cursor-pointer"
                onClick={() => {
                  navigate("/report/graphfull", {
                    state: { data: diarydata, title: "배변량" },
                  });
                }}
              />
              <div className="absolute z-1 top-0 right-0 w-4 h-full bg-[#F1F2F3]"></div>
              <div className="absolute z-1 bottom-[23px] left-0 w-full h-4 bg-[#F1F2F3]"></div>
              {/* <div className="absolute z-1 bottom-[50px] left-0 px-[16px] flex gap-x-3 justify-between w-full">
                {poopList.slice(0, 7).map((el, index) => (
                  <div
                    className={
                      el === 0
                        ? "w-1-7 flex justify-center text-center caption_2 text-[#F7CE4B] font-semibold"
                        : "w-1-7 flex justify-center text-center caption_2 text-white font-semibold"
                    }
                    key={index}
                  >
                    {el}
                  </div>
                ))}
              </div> */}
              <div className="absolute z-2 bottom-[23px] left-0 px-[16px] flex gap-x-1 justify-between w-full">
                {dayList
                  .slice(0, 7)
                  .reverse()
                  .map((el, index) => (
                    <div
                      className="w-1-7 flex justify-center text-center caption_2 text-[#343B48]"
                      key={index}
                    >
                      {el}
                    </div>
                  ))}
              </div>
              <div className="absolute z-1 bottom-[22px] left-0 px-[16px] flex gap-x-1 justify-between w-full">
                {jangruChangeList
                  .slice(0, 7)
                  .reverse()
                  .map((el, index) => (
                    <div
                      className={
                        el === 0 ? "w-1-7 h-5 bg-[#FAE38E]" : "w-1-7 h-4"
                      }
                      key={index}
                    ></div>
                  ))}
              </div>
            </div>
            <div className=" px-[16px] pt-[8px] flex items-center">
              <span className="w-[32px] h-[16px] bg-[#FAE38E] inline-block"></span>
              <span className="caption_2 pl-[4px]">장루주머니 교체일</span>
            </div>
          </div>
          <div className="h-[68px]"></div>
        </div>
      ) : (
        <GraphDefault />
      )}
    </>
  );
};

export default ReportGraph;
