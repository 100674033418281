import React from "react";

const LifeInManage3 = ({ btn3, bigTitle }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400 wordbreak-keepall">
      <div className="pt-[72px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
        <h1 className="h2_800 pt-[4px] pb-[24px]">{btn3}</h1>
      </div>
      <div className="w-full pt-[24px] body_1_400_jangrucont">
        <div className="flex  ">
          <span className="inline-block">•&nbsp;</span>
          <div>간단한 외출이더라도 장루 제품은 꼭 하나씩 휴대하고다니세요.</div>
        </div>
        <div className="flex ">
          <span className="inline-block">•&nbsp;</span>
          <div>업무로 복귀하는 시점은 개인의 회복 수준에 따라 달라집니다.</div>
        </div>
        <div className="flex ">
          <span className="inline-block">•&nbsp;</span>
          <div>
            무거운 물건을 반복해서 들어올리는 일 이외에는 일상적인 직장으로
            복귀가 가능합니다.
          </div>
        </div>
        <div className="flex pt-[32px]">
          <span className="inline-block">•&nbsp;</span>
          <div>사회활동시 장루에 관한 몇가지 팁</div>
        </div>
        <div className="flex">
          <span className="inline-block">-&nbsp;</span>
          <div>
            대변 누출, 주머니 교체가 필요할 시를 대비하여 물품을 보관해 놓고
            여분의 옷도 준비하세요.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block">-&nbsp;</span>
          <div>
            장루주머니 관리를 위한 최적의 화장실이나 장소를 찾고 냄새제거제 등의
            사용을 하여 냄새를 줄이도록 합니다.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block">-&nbsp;</span>
          <div>
            오래 앉아 있는 일의 경우 느슨한 옷을 입고, 활동적인 일을 할 경우에는
            장루용 벨트를 착용합니다.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block">-&nbsp;</span>
          <div>장루에서 나는 소음에 너무 신경 쓰지 않아도 됩니다.</div>
        </div>
        <div className="flex">
          <span className="inline-block">-&nbsp;</span>
          <div>수분을 적절히 섭취하세요.</div>
        </div>
        <div className="flex">
          <span className="inline-block">-&nbsp;</span>
          <div>
            장루에 대한 협회와 연관된 지지모임을 알아보고 조언과 정보를 구하면
            좋습니다.
          </div>
        </div>
      </div>
    </div>
  );
};

export default LifeInManage3;
