import React, { useState } from "react";
import ImageModal from "../../../mobilecomponent/ImageModal";

const Check10Images = ({ el }) => {
  const [imageModal, setImageModal] = useState(false);
  const showImageModal = () => {
    setImageModal(!imageModal);
  };
  return (
    <div className="w-[80px] h-[80px] overflow-hidden">
      <div className="w-[300px]">
        <img
          src={el}
          alt={el}
          key={el}
          className="w-full cursor-pointer"
          onClick={showImageModal}
        />
      </div>

      {imageModal ? (
        <ImageModal showImageModal={showImageModal} imageurl={el} />
      ) : (
        ""
      )}
    </div>
  );
};

export default Check10Images;
