import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import common, { api } from "../../../../../data/common";
const NoticeList = ({ el }) => {
  const righticon = "/images/mo/right_unactive.svg";
  console.log(el.news.title.length);
  const navigate = useNavigate();
  console.log(el, ">>>el");
  return (
    <>
      <li
        className="flex items-center justify-between py-[17px] cursor-pointer"
        onClick={() => {
          api
            .get(common.baseURL + "news/newsReadId/" + el.id + "/1")
            .then((res) => {
              if (res.data.ok === true) {
                navigate("/myinfo/eachnews", { state: el.news });
              } else {
                console.log(res);
              }
            })
            .catch((error) => console.log(error));
        }}
      >
        <div className="w-full">
          <span className="text-[#999CA3] block caption_1_400">
            {dayjs(el.news.createdAt).format("YYYY.MM.DD")}{" "}
            {dayjs(el.news.createdAt).format("A") === "PM" ? "오후" : "오전"}
            {dayjs(el.news.createdAt).format("H") > 12
              ? dayjs(el.news.createdAt).format("H") - 12
              : dayjs(el.news.createdAt).format("H")}
            시
          </span>
          <div className="flex items-center pt-[4px]">
            <span className="block body_1_600 pr-[8px] ">
              {el.news.category === 1
                ? "장루소식"
                : el.news.category === 2
                ? "앱소식"
                : el.news.category
                ? "커뮤니티"
                : ""}
            </span>
            <span className="block pl-[8px] border-l border-black pr-[4px]">
              {el.news.title.length > 16
                ? el.news.title.slice(0, 16)
                : el.news.title}
              {el.news.title.length > 16 ? "..." : ""}
            </span>
            {el.read === 0 ? (
              <span className="bg-[#F9D867] inline-block rounded-full w-[20px] h-[20px] flex justify-center items-center">
                N
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <img src={righticon} alt="righticon" />
      </li>
    </>
  );
};

export default NoticeList;
