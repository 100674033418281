import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMainContext } from "../contexts/Provider";
import { getApiDecrypt, getApiEncryt } from "../data/common";

const Layout = ({ children }) => {
  const location = useLocation();
  const path = location.pathname;
  const {
    adminHistory,
    setAdminHistory,
    historyPrevIdx,
    setHistoryPrevIdx,
    mobhistoryPrevIdx,
    setMobHistoryPrevIdx,
    mobileHistory,
    setMobileHistory,
  } = useMainContext();

  const navigate = useNavigate();
  /******************************* adminHistory 사용 ****************************/
  // onpopstate로 인한 페이지 이동이면 true 아니면 false
  const onpopstateRef = useRef(false);

  window.onpopstate = function () {
    // admin에서
    if (path.includes(`/admin/main`)) {
      onpopstateRef.current = true;
      let arr = adminHistory;
      // window 앞으로 가기 버튼 눌렀을 때
      if (
        historyPrevIdx < window.history.state.idx &&
        adminHistory[adminHistory.length - 1] !== path
      ) {
        arr.push(path);
        setAdminHistory(arr);
      }
      // window 뒤로 가기 버튼 눌렀을 때
      else if (historyPrevIdx > window.history.state.idx) {
        arr.pop();
        setAdminHistory(arr);
      }
      window.sessionStorage.setItem("adminHistory", JSON.stringify(arr));

      onpopstateRef.current = false;
    }
  };

  useEffect(() => {
    if (path.includes(`/admin/main`)) {
      // 현재의 history idx 저장
      setHistoryPrevIdx(window.history.state.idx);

      // onpopstate로 인한 페이지 이동이 아닐 때
      if (!onpopstateRef.current) {
        // 맨마지막에 저장된 history url과 현재 페이지 url이 다를 때
        if (adminHistory && adminHistory[adminHistory.length - 1] !== path) {
          let arr = adminHistory;
          arr.push(path);
          setAdminHistory(arr);

          window.sessionStorage.setItem("adminHistory", JSON.stringify(arr));
          // console.log(
          //   window.sessionStorage.getItem("adminHistory"),
          //   ">>> adminHistory"
          // );
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  //**************************************************************************//

  /******************************* mobileHistory 사용 ****************************/
  // onpopstate로 인한 페이지 이동이면 true 아니면 false
  const mobonpopstateRef = useRef(false);

  window.onpopstate = function () {
    // mobile
    if (path.includes(`/main`)) {
      mobonpopstateRef.current = true;
      let arr = mobileHistory;
      // window 앞으로 가기 버튼 눌렀을 때
      if (mobhistoryPrevIdx < window.history.state.idx) {
        arr.push(path);
        setMobileHistory(arr);
      }
      // window 뒤로 가기 버튼 눌렀을 때
      else if (mobhistoryPrevIdx > window.history.state.idx) {
        arr.pop();
        setMobileHistory(arr);
      }
      window.sessionStorage.setItem("mobileHistory", JSON.stringify(arr));

      mobonpopstateRef.current = false;
    }
  };

  useEffect(() => {
    window.movePage = (params) => {
      // const object = JSON.parse(params);
      navigate(params);
    };
  });

  useEffect(() => {
    if (path === "/main") {
      // 현재의 history idx 저장
      setMobHistoryPrevIdx(window.history.state.idx);

      // onpopstate로 인한 페이지 이동이 아닐 때
      if (!mobonpopstateRef.current) {
        // 맨마지막에 저장된 history url과 현재 페이지 url이 다를 때
        if (mobileHistory && mobileHistory[mobileHistory.length - 1] !== path) {
          let arr = mobileHistory;
          arr.push(path);
          setMobileHistory(arr);

          window.sessionStorage.setItem("mobileHistory", JSON.stringify(arr));
          // console.log(
          //   window.sessionStorage.getItem("mobileHistory"),
          //   ">>> mobileHistory"
          // );
        }
      }

      // 바뀐 history 확인
      setTimeout(() => {
        console.log(mobileHistory, " ~ mobileHistory");
      }, [500]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  //**************************************************************************//
  return (
    <>
      {/* {path.includes(`/admin`) ? 
      (
        <div className="admin w-full h-full text-[#4e535f] antialiased">
            {children}
          </div>
      
      ):(
         <div className=" max-w-md mx-auto flex flex-col text-base text-[#343B48] bg-[#FAFBFC] antialiased overflow-hidden">
          {children}
        </div>
      )  } */}
      <div className="w-full h-full text-[#4e535f] antialiased">{children}</div>
    </>
  );
};

export default Layout;
