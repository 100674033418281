import React from "react";
import { useNavigate } from "react-router-dom";
const FoodManage2 = ({ bigTitle, btn2 }) => {
  const navigate = useNavigate();
  return (
    <div className="w-full flex flex-col px-[16px]">
      <div className="pt-[72px] w-full">
        <span className="text-[#679DEE] caption_1_400"> {bigTitle}</span>
        <h1 className="h2_800  pt-[4px] pb-[24px] ">{btn2}</h1>
        <span className="text-[#676C76] body_1_400">
          아래 버튼을 눌러 상세한 정보를 확인할 수 있어요!
        </span>
        <div className="pt-[152px] button_700">
          <span
            className="mb-[16px] w-full text-center inline-block border border-[#1B5EDC] p-[16px] text-[#1B5EDC] bg-[#FAFCFF] rounded-sm active:bg-[#F2F6FC] active:text-[#18428E] ease-in-out duration-300 cursor-pointer"
            onClick={(e) => {
              navigate("/more/sub/list", {
                state: {
                  bigTitle: bigTitle,

                  btn2: btn2,
                  btn2_1: e.target.innerText
                }
              });
            }}
          >
            식사요법 지침
          </span>
          <span
            className="mb-[16px] w-full text-center inline-block border border-[#1B5EDC] p-[16px] text-[#1B5EDC] bg-[#FAFCFF] rounded-sm active:bg-[#F2F6FC] active:text-[#18428E] ease-in-out duration-300 cursor-pointer"
            onClick={(e) => {
              navigate("/more/sub/list", {
                state: {
                  bigTitle: bigTitle,

                  btn2: btn2,
                  btn2_2: e.target.innerText
                }
              });
            }}
          >
            회장루 식사관리
          </span>
          <span
            className="mb-[16px] w-full text-center inline-block border border-[#1B5EDC] p-[16px] text-[#1B5EDC] bg-[#FAFCFF] rounded-sm active:bg-[#F2F6FC] active:text-[#18428E] ease-in-out duration-300 cursor-pointer"
            onClick={(e) => {
              navigate("/more/sub/list", {
                state: {
                  bigTitle: bigTitle,

                  btn2: btn2,
                  btn2_3: e.target.innerText
                }
              });
            }}
          >
            결장루 식사관리
          </span>
        </div>
      </div>
    </div>
  );
};

export default FoodManage2;
