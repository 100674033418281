import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useMainContext } from "../../../../contexts/Provider";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/css";
import { ToastContainer, toast } from "react-toastify";
/*component*/
import CommentList from "./components/CommentList";

import common, { api } from "../../../../data/common";
import PostEdit from "./components/PostEdit";
import WarnBlockPost from "./components/WarnBlockPost";

SwiperCore.use([Autoplay, Pagination]);
const Post = () => {
  const navigate = useNavigate();
  const leftarrow = "/images/mo/leftarrow.svg";
  const { loginInput } = useMainContext();
  const locationPostingID = useLocation().state;

  const href = new URL(window.location.href);
  const urlParams = href.searchParams;
  const FCM = urlParams.get("postingID");
  const TOKEN = urlParams.get("token");
  const idTOKEN = urlParams.get("id");
  const postingid = FCM ? FCM : locationPostingID;
  const patientID = idTOKEN ? idTOKEN : loginInput.id;

  const [commentusers, setCommentUsers] = useState([]);
  const [userData, setUserData] = useState([]);
  const [commentUserId, setCommentUserId] = useState();
  const [patientnick, setPatientnick] = useState();
  const [photo, setPhoto] = useState();
  const [savephotoURL, setSavePhotoURL] = useState([]);
  const [commentInput, setCommentInput] = useState({
    contents: "",
    posting: postingid,
    patient: patientID,
    public: 0,
  });
  const [cheerup, setCheerup] = useState([]);
  const [postedit, setPostedit] = useState(false);
  const [warnBlock, setWarnBlock] = useState(false);
  const [myprofile, setMyprofile] = useState([]);
  const [thumbup, setThumpup] = useState(false);
  const threedots_nopad = "/images/mo/threedots_nopad.svg";
  const messagebol = "/images/mo/messagebol.svg";
  const cheerupicon = "/images/mo/cheerup.svg";
  const cheerupicon_col = "/images/mo/cheerup_col.svg";
  const defaultimage = "/images/mo/defaultimage.svg";

  //2차개발
  // window.onpopstate = function () {
  //   console.log("작동");
  //   navigate(1);
  // };
  useEffect(() => {
    let pageOpen = false;
    const getComment = async () => {
      await api

        .get(common.baseURL + `community/posting/${postingid}/${loginInput.id}`)
        .then((res) => {
          if (res.data.ok === true) {
            setCommentUsers(res.data.commentList);
            setUserData(res.data.posting);
            setCommentUserId(res.data.posting.patient.id);
            setPatientnick(res.data.posting.patient.nickName);
            setPhoto(res.data.posting.patient.profileURL);
            setSavePhotoURL(res.data.posting.photoURL);
          } else {
            alert("댓글을 불러오지 못했습니다");
          }
        });
    };
    const getCheerup = () => {
      api
        .get(common.baseURL + `community/posting/${postingid}/${loginInput.id}`)
        .then((res) => {
          if (res.data.ok === true) {
            setCheerup(res.data.cheerupList);
            if (res.data.cheerupList.length > 0) {
              let minecheerup = res.data.cheerupList.filter(
                (el) => el.patient !== null && el.patient.id === patientID
              );
              console.log(minecheerup);
              if (minecheerup.length > 0) {
                setThumpup(true);
              } else {
                setThumpup(false);
              }
            }
          }
        });
    };
    const getMyProfile = () => {
      api.get(common.baseURL + "patient/Id/" + patientID).then((res) => {
        if (res.data.ok === true) {
          setMyprofile(res.data.patient.profileURL);
        }
      });
    };
    if (!pageOpen) {
      if (
        loginInput.id === null ||
        loginInput.id === undefined ||
        loginInput.id === ""
      ) {
        navigate("/main", { replace: true });
      } else {
        getComment();
        getCheerup();
        getMyProfile();
      }
    }
    return () => {
      pageOpen = true;
    };
  }, [loginInput.id, postingid, patientID, navigate]);
  const onChangeComment = (e) => {
    const { name, value } = e.target;

    setCommentInput({ ...commentInput, [name]: value.slice(0, 81) });
  };

  const submitComment = async () => {
    await api
      .post(common.baseURL + "community/comment", commentInput)
      .then((res) => {
        console.log(res, ">>>여기서 post page id를 찾아야함");
        if (res.data.ok === true) {
          toast(`댓글이 저장되었습니다.`, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: "transparent toastbottom",
          });
          api
            .get(
              common.baseURL + `community/posting/${postingid}/${loginInput.id}`
            )
            .then((getRes) => {
              if (getRes.data.ok === true) {
                setCommentUsers(getRes.data.commentList);
                setCommentInput({
                  contents: "",
                  posting: postingid,
                  patient: patientID,
                  public: 0,
                });
              }
            });
        } else {
          alert("댓글 저장에 오류가 있습니다");
        }
      })
      .catch((error) => console.log(error));
  };

  const cheerUpThumb = (e) => {
    api
      .get(common.baseURL + `community/posting/${postingid}/${loginInput.id}`)
      .then((res) => {
        if (res.data.ok === true) {
          const cheerupdata = res.data.cheerupList;
          //응원하지 x
          const mycheerup0 = cheerupdata.filter(
            (el) =>
              el.patient !== null &&
              el.patient.id === patientID &&
              el.status === 0
          );
          //응원 O
          const mycheerup1 = cheerupdata.filter(
            (el) =>
              el.patient !== null &&
              el.patient.id === patientID &&
              el.status === 1
          );
          let body1 = {
            status: 1,
            posting: postingid,
            patient: patientID,
          };
          let body0 = {
            status: 0,
            posting: postingid,
            patient: patientID,
          };
          if (
            (mycheerup0.length === 0 && mycheerup1.length === 0) ||
            mycheerup0.length > 0
          ) {
            api
              .post(common.baseURL + "community/cheerup", body1)
              .then((res) => {
                if (res.data.ok === true) {
                  console.log(res.data, ">>>>>>>>resdata");
                  api
                    .get(
                      common.baseURL +
                        `community/posting/${postingid}/${loginInput.id}`
                    )
                    .then((getRes) => {
                      if (getRes.data.ok === true) {
                        setCheerup(getRes.data.cheerupList);
                        console.log(getRes.data);
                        if (getRes.data.cheerupList.length > 0) {
                          let minecheerup = getRes.data.cheerupList.filter(
                            (el) =>
                              el.patient !== null && el.patient.id === patientID
                          );
                          console.log(minecheerup);
                          if (minecheerup.length > 0) {
                            setThumpup(true);
                          } else {
                            setThumpup(false);
                          }
                        } else {
                          setThumpup(false);
                        }
                      }
                    });
                }
              });
          } else {
            setThumpup(false);

            api
              .post(common.baseURL + "community/cheerup", body0)
              .then((res) => {
                if (res.data.ok === true) {
                  console.log(res.data, ">>>>>>>>resdata");
                  api
                    .get(
                      common.baseURL +
                        `community/posting/${postingid}/${loginInput.id}`
                    )
                    .then((getRes) => {
                      if (getRes.data.ok === true) {
                        setCheerup(getRes.data.cheerupList);
                        console.log(getRes.data);
                      }
                    });
                }
              });
          }
        }
      });
  };
  const goback = () => {
    navigate("/community");
  };

  return (
    <>
      <div className="h-screen bg-[#F1F2F3] relative overflow-y-auto">
        <div className="fixed top-0 bg-[#FAFCFF] left-0 w-full bg-[#FAFCFF] z-3 subtitle_1_400">
          <div className="flex items-center justify-between border-b">
            <img
              className="inline-block cursor-pointer "
              src={leftarrow}
              alt="leftarrow"
              onClick={goback}
            />
            <span className="inline-block">게시글</span>
            <span className="inline-block w-[48px] h-[48px]"></span>
          </div>
        </div>
        <div className="bg-[#FAFCFF] pt-[48px] px-[24px]">
          <div className="flex border-b pt-[24px] pb-[16px] justify-between items-center cursor-pointer">
            <div className="flex items-center">
              {photo ? (
                <span
                  className="inline-block border rounded-full w-[40px] h-[40px]
          flex justify-center items-center overflow-hidden"
                >
                  <img
                    src={common.networkImg + encodeURIComponent(photo)}
                    alt={photo}
                  />
                </span>
              ) : (
                <span className="inline-block border rounded-full flex justify-center items-center h-[40px] w-[40px] bg-no-repeat bg-center">
                  <img src={defaultimage} alt="defaultimage" />
                </span>
              )}
              <div className="flex flex-col justify-center pl-[16px]">
                <span className="subtitle_2">{patientnick}</span>
                <span className="text-[#676C76] caption_2 pt-[4px]">
                  {dayjs(userData.createdAt).format("YYYY/MM/DD")}
                </span>
              </div>
            </div>
            {commentUserId === patientID ? (
              <img
                src={threedots_nopad}
                alt="threedots"
                className="cursor-pointer"
                onClick={() => {
                  setPostedit(true);
                }}
              />
            ) : commentUserId !== patientID ? (
              <img
                src={threedots_nopad}
                alt="threedots"
                className="cursor-pointer"
                onClick={() => {
                  setWarnBlock(true);
                }}
              />
            ) : (
              ""
            )}
          </div>
          <div className="py-[16px]">
            <div>
              {savephotoURL && savephotoURL.length > 0
                ? savephotoURL.map((el) => (
                    <div
                      key={el}
                      className="w-full rounded-xl overflow-hidden mb-[8px]"
                    >
                      <img
                        src={common.networkImg + encodeURIComponent(el)}
                        className="w-full"
                        alt={el}
                      />
                    </div>
                  ))
                : ""}
            </div>
            <p className="break-all whitespace-pre-line body_1_400">
              {userData.contents}
            </p>

            <div className="flex items-center pt-4 caption_1_400">
              <div className="flex w-[100px] ">
                {thumbup === true ? (
                  <img
                    src={cheerupicon_col}
                    alt="cheerupicon_col"
                    className="pr-1"
                  />
                ) : (
                  <img src={cheerupicon} alt="cheerupicon" className="pr-1" />
                )}
                <span
                  onClick={cheerUpThumb}
                  className="inline-block cursor-pointer"
                >
                  응원해요 {cheerup.length}
                </span>
              </div>
              <div className="flex itmes-center w-[100px]">
                <img src={messagebol} alt="messagebol" />
                <span>
                  댓글 {commentusers !== undefined ? commentusers.length : 0}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="px-[24px] pt-[16px]">
          {commentusers.map((comment, index) => (
            <CommentList
              comment={comment}
              key={index}
              postingid={postingid}
              setCommentUsers={setCommentUsers}
              TOKEN={TOKEN}
            />
          ))}
        </div>
        <div className=" border-t border-[#679DEE] bg-[#FAFCFF] fixed bottom-0 left-1/2 transform-x-50 w-full max-w-md mx-auto z-2 px-[24px] py-2">
          <div className="flex items-center justify-between ">
            <img
              src={
                myprofile !== null && encodeURIComponent(myprofile)
                  ? common.networkImg + encodeURIComponent(myprofile)
                  : defaultimage
              }
              alt="myprofile"
              className="w-[24px] h-[24px] rounded-full overflow-hidden border"
            />

            <textarea
              rows={parseInt(commentInput.contents.length / 20) + 1}
              cols="20"
              wrap="hard"
              className="w-[280px] min-w-[250px] py-[16px] focus:outline-none bg-transparent"
              placeholder="댓글을 남겨보세요.(총 80자)"
              maxLength={80}
              name="contents"
              value={commentInput.contents}
              onChange={onChangeComment}
            />
            {commentInput.contents.length !== 0 ? (
              <span
                className="inline-block text-[#1B5EDC] hover:text-[#18428E] ease-in-out duration-300 button_700 cursor-pointer"
                onClick={submitComment}
              >
                입력
              </span>
            ) : (
              <span className="inline-block text-[#679DEE] button_700 ">
                입력
              </span>
            )}
          </div>
        </div>
        {postedit ? (
          <PostEdit
            setPostedit={setPostedit}
            postinguserid={userData.patient.id}
            postingid={userData.id}
          />
        ) : (
          ""
        )}
        {warnBlock ? (
          <WarnBlockPost
            setWarnBlock={setWarnBlock}
            postingid={postingid}
            commentUserId={commentUserId}
          />
        ) : (
          ""
        )}
        <div className="h-[124px]"></div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        bodyClassName={() =>
          "w-[175px] p-3 rounded-full overflow-hidden bg-[#CBDFFC] text-black text-sm font-normal"
        }
      ></ToastContainer>
    </>
  );
};

export default Post;
