import React from "react";

const FoodManage1 = ({ bigTitle }) => {
  const image1 = "/images/mo/jangrucont/stoma_1.svg";
  const image2 = "/images/mo/jangrucont/stoma_2.svg";
  const image3 = "/images/mo/jangrucont/stoma_3.svg";
  const image4 = "/images/mo/jangrucont/stoma_4.svg";

  return (
    <div className="w-full flex flex-col px-[16px] body_1_400 wordbreak-keepall">
      <div className="pt-[72px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
        <div className="pt-[4px] pb-[24px] h2_800">
          <h1 className=" text-center">장루로 인한 영양문제</h1>
          <h1 className=" text-center">&amp; 장루식사요법의 목표</h1>
        </div>
      </div>
      <div className="w-full pt-[72px]">
        <div>
          <span className="inline-block subtitle_1_700 pb-[8px]">
            1) 장루로 인한 영양문제
          </span>
          <div className="bg-[#65AEE0] rounded-[12px] p-[16px] w-full  grid grid-cols-2 gap-[12px]">
            <div className="bg-[#FAFCFF] rounded-[12px] p-[12px] ">
              <div className="h-[75px] flex justify-center">
                <img src={image1} alt="image1" />
              </div>

              <div className="pt-[4px] flex justify-center flex-col text-center">
                <span className="block">과량의 배설량</span>
                <span className="block">체중감소</span>
              </div>
            </div>
            <div className="bg-[#FAFCFF] rounded-[12px] p-[12px]">
              <div className="h-[75px] flex justify-center">
                <img src={image2} alt="image2" />
              </div>

              <div className="pt-[4px] flex justify-center flex-col text-center">
                <span className="block">수분과</span>
                <span className="block">전해질 손실</span>
              </div>
            </div>
            <div className="bg-[#FAFCFF] rounded-[12px] p-[12px] ">
              <div className="h-[75px] flex justify-center">
                <img src={image3} alt="image3" />
              </div>

              <div className="pt-[4px] h-[48px] flex justify-center flex-col text-center">
                장루 막힘
              </div>
            </div>
            <div className="bg-[#FAFCFF] rounded-[12px] p-[12px] ">
              <div className="h-[75px] flex justify-center">
                <img src={image4} alt="image4" />
              </div>

              <div className="pt-[4px] h-[48px] flex justify-center flex-col text-center">
                냄새와 가스 발생
              </div>
            </div>
          </div>
        </div>
        <div className="pt-[32px] body_1_400_jangrucont">
          <span className="inline-block subtitle_1_700 pb-[8px]">
            2) 장루 수술 후 식사요법의 목표
          </span>
          <div className="flex">
            <span className="inline-block">1.&nbsp;</span>
            <span className="inline-block ">
              적절한 열량과 단백질 섭취를 통한 좋은 영양상태 유지 및 상처치료
              회복
            </span>
          </div>
          <div className="flex">
            <span className="inline-block">2.&nbsp;</span>
            <span className="inline-block ">
              충분한 수분섭취를 통한 탈수 예방
            </span>
          </div>
          <div className="flex">
            <span className="inline-block">3.&nbsp;</span>
            <span className="inline-block">
              장루를 통한 가스, 냄새 등의 불편감을 줄이고 장루 막힘을 예방
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoodManage1;
