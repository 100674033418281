// Axios로 데이타 요청하는 함수

import { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import commonUrl, { api } from "../../../../../data/common";
import { useMainContext } from "../../../../../contexts/Provider";
import { toast } from "react-toastify";
import {
  findToastPosition,
  toastCommonProps,
} from "../../../admin_components/Toast";
import { handlingError } from "../../../admin_components/Error";

function SetNewPwd({ setToastPosition }) {
  const navigate = useNavigate();
  const { adminLogin, setAdminLogin } = useMainContext();
  const [loading, setLoading] = useState(false);
  // 새비번 입력 값
  const [newPwd, setNewPwd] = useState({
    newPwd: "",
    againNewPwd: "",
  });
  const pwdInput = useRef(); // pwd input 창
  const [msg, setMsg] = useState(""); // 비밀번호 일치관련 안내 메세지

  // 확인 버튼 클릭 대신 엔터키 눌렀을 경우
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      submit();
    }
  };

  // input 값 변경
  const change = (e) => {
    const { name, value } = e.target;
    setNewPwd({ ...newPwd, [name]: value.slice(0, 13) });
  };

  // 숫자, 문자, 특수문자 포함 8~12자리 체크
  const checkPwd = useCallback(() => {
    const specialRule = /[`~!@#$%^&*|\\'";:/?]/;
    const num = /[0-9]/;
    const char = /[a-zA-Z]/;

    if (
      specialRule.test(newPwd.newPwd) &&
      num.test(newPwd.newPwd) &&
      char.test(newPwd.newPwd) &&
      newPwd.newPwd.length >= 8 &&
      newPwd.newPwd.length <= 12
    ) {
      return true;
    }
    return false;
  }, [newPwd.newPwd]);

  // 저장하기 버튼 눌렀을 때
  const submit = () => {
    // 토스트창 위치 설정
    setToastPosition(findToastPosition("top"));

    if (newPwd.newPwd === "" && newPwd.againNewPwd === "") {
      pwdInput.current.focus();
    } else {
      if (msg === "비밀번호가 일치합니다.") {
        // axios
        async function fetchData() {
          setLoading(true);
          const res = await api({
            method: "post",
            url: commonUrl.admin + "changePwd",
            data: { ...newPwd, name: adminLogin.name, email: adminLogin.email },
          }).catch((error) => {
            handlingError(error, "top-center", "toast_common toast__login");
          });
          console.log(res.data, ">>> Axios admin/changePwd");
          setLoading(false);
          // axios 성공 시
          if (res.data.ok) {
            setTimeout(() => {
              setAdminLogin({
                ...adminLogin,
                email: "",
                password: "",
                name: "",
              });
              navigate(-1);
            }, 2000);
            toast(
              <p>저장되었습니다.</p>,
              toastCommonProps(1500, "top-center", "toast_common toast__login")
            );
          }
          // axios 실패 시
          else {
            toast(
              <p>저장에 실패하였습니다.</p>,
              toastCommonProps(1500, "top-center", "toast_common toast__login")
            );
          }
        }
        fetchData();
      }
    }
  };

  // 비밀번호 양식에 맞는지 + 재확인 일치 체크
  useEffect(() => {
    // 둘다 빈칸일 경우
    if (newPwd.newPwd === "" && newPwd.againNewPwd === "") setMsg("");
    // 윗칸만 빈칸이 아닐 경우
    else if (newPwd.newPwd !== "" && newPwd.againNewPwd === "") {
      if (!checkPwd())
        setMsg("숫자, 문자, 특수문자 포함 8~12자리를 입력하세요");
      else setMsg("");
    }
    // 밑칸만 빈칸이 아닐 경우
    else if (newPwd.newPwd === "" && newPwd.againNewPwd !== "")
      setMsg("비밀번호가 일치하지 않습니다.");
    // 둘다 빈칸이 아닐 경우
    else if (newPwd.newPwd !== "" && newPwd.againNewPwd !== "") {
      if (!checkPwd())
        setMsg("숫자, 문자, 특수문자 포함 8~12자리를 입력하세요");
      else if (newPwd.newPwd !== newPwd.againNewPwd)
        setMsg("비밀번호가 일치하지 않습니다.");
      else setMsg("비밀번호가 일치합니다.");
    }
    // 예외
    else {
      setMsg("");
    }
  }, [checkPwd, newPwd]);

  return (
    <>
      <div className="absolute w-[385px] h-[380px] inset-0 m-auto ">
        <h3 className="flex justify-center text-center font-bold text-xl mt-[15px] mb-[35px] text-[#1B5EDC]">
          새 비밀번호 입력
        </h3>
        <p className="text-[15px] mb-[20px]">
          <span className="text-[#1B5EDC] font-bold">새 비밀번호</span>를 입력해
          주세요.
        </p>
        {/* 새 비밀번호 & 비밀번호 재입력 */}
        <div className="flex flex-between border-x-0 border-t-0 border-b">
          <label className="flex w-[120px] justify-start items-center  text-[15px]">
            새 비밀번호 입력
          </label>
          <input
            className="w-[300px] p-[10px] placeholder:text-slate-400 placeholder:text-[12px] focus:outline-none"
            type="password"
            name="newPwd"
            value={newPwd.newPwd}
            onChange={change}
            ref={pwdInput}
            placeholder="숫자, 문자, 특수문자 포함 8~12자리"
            onKeyPress={onKeyPress}
            autoFocus
          />
        </div>
        <div className="flex flex-between border-x-0 border-t-0 border-b mt-[10px]">
          <label className="flex w-[120px] justify-start items-center  text-[15px] focus:outline-none">
            비밀번호 재입력
          </label>
          <input
            className="w-[300px] p-[10px] focus:outline-none"
            type="password"
            name="againNewPwd"
            value={newPwd.againNewPwd}
            onChange={change}
            onKeyPress={onKeyPress}
          />
        </div>
        {msg !== "" ? (
          msg === "비밀번호가 일치합니다." ? (
            <p className="text-[12px] text-blue-500 mt-[5px]">{msg}</p>
          ) : (
            <p className="text-[12px] text-red-500 mt-[5px]">{msg}</p>
          )
        ) : null}

        <button
          className="toast_standard flex justify-center items-center w-full h-[60px] bg-[#679dee] ease-in-out duration-300 rounded-sm text-xl text-white font-semibold mt-[35px] hover:bg-[#1B5EDC]"
          onClick={submit}
        >
          {loading ? (
            <svg
              className="animate-spin h-[20px] w-[20px] my-[2px] rounded-full border-2 border-white border-t-[#679dee] border-r-[#679dee]"
              viewBox="0 0 24 24"
            />
          ) : (
            "저장하기"
          )}
        </button>

        <button
          className="flex justify-center my-[20px] inset-x-0 mx-auto border-b leading-5 text-sm"
          onClick={() => {
            navigate(-1);
          }}
        >
          홈으로
        </button>
      </div>
    </>
  );
}

export default SetNewPwd;
