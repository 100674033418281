import React from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
const AppUserList = ({ el }) => {
  console.log(el, ">>thisis");
  const navigate = useNavigate();

  return (
    <div
      className="px-[24px] border-b-[1px] py-[18px] cursor-pointer"
      onClick={() => {
        navigate("/myinfo/appusecont", {
          state: el.id
        });
      }}
    >
      {el.status === 1 ? (
        <span className="inline-block px-[6px] py-[2px] caption_2 text-white rounded-sm bg-[#1B5EDC] caption_2">
          답변완료
        </span>
      ) : (
        <span className="inline-block px-[6px] py-[2px] caption_2 border rounded-sm border-[#999CA3] text-[#999CA3] caption_2">
          답변대기
        </span>
      )}
      <div className="pt-[6px]">
        <span className="body_1_600">
          [
          {el.category === 0
            ? "서비스 문의"
            : el.category === 1
            ? "장애 및 불편신고"
            : el.category === 2
            ? "제안사항"
            : el.category === 3
            ? "기타"
            : ""}
          ]
        </span>
        <span className="text-[#343B48] pl-2">{el.title}</span>
      </div>
      <div className="text-[#999CA3] caption_1_400 pt-[4px]">
        {dayjs(el.createdAt).format("YYYY.MM.DD")}{" "}
        {dayjs(el.createdAt).format("A") === "AM" ? "오전" : "오후"}{" "}
        {dayjs(el.createdAt).format("H") > 12
          ? dayjs(el.createdAt).format("H") - 12
          : dayjs(el.createdAt).format("H")}
        시
      </div>
    </div>
  );
};

export default AppUserList;
