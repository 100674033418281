import React from "react";

const FoodManage2IleumMeal = ({ bigTitle, btn2, btn2_2 }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400 wordbreak-keepall">
      <div className="py-[24px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">
          {bigTitle} &gt; {btn2}
        </span>
        <h1 className="h2_800 pt-[4px]">{btn2_2}</h1>
      </div>
      <div className="w-full pt-[24px] body_1_400_jangrucont">
        <div className="pb-[32px]">
          <div className="flex subtitle_1_700 ">1) 수분 섭취 방법</div>
          <div className="pt-[8px]">
            <div className="flex items-center">
              <span className="inline-block">1.&nbsp;</span>
              <span className="inline-block ">
                하루 8~10컵 정도의 충분한 수분을 섭취 합니다.
              </span>
            </div>
            <div className="flex items-center">
              <span className="inline-block">2.&nbsp;</span>
              <span className="inline-block ">
                <strong className="text-[#245BBF]">조금씩 자주</strong>{" "}
                마십니다.
              </span>
            </div>
            <div className="flex items-center">
              <span className="inline-block">3.&nbsp;</span>
              <span className="inline-block ">
                가급적{" "}
                <strong className="text-[#245BBF]">
                  식사와 식사 사이, 빈 시간
                </strong>
                을 이용하여 섭취합니다.
              </span>
            </div>
            <div className="flex ">
              <span className="inline-block">4.</span>
              <div>
                <span className="inline-block">
                  <strong className="text-[#245BBF]">
                    &nbsp;체온이나 실온 정도
                  </strong>
                  의 물을 섭취합니다.
                </span>
                <span className="inline-block">
                  - 생수, 보리차, 누룽지 국물(숭늉), 수프, 수분보급 젤리 등
                </span>
              </div>
            </div>
            <div className="flex justify-end ">
              <span className="inline-block ">5.</span>
              <div className="pl-[4px]">
                <span className="inline-block ">
                  장루 배설량을 증가시킬 수 있는 종류는 섭취량을 조절합니다.
                </span>
                <span className="inline-block ">
                  - 카페인을 포함하고 있거나, 단맛이 강한 음료수, 유제품, 두유
                  등
                </span>
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <span className="inline-block ">6.&nbsp;</span>
            <div>
              카페인 음료 (커피, 녹차, 콜라, 홍차 등)은 이뇨 효과가 있어
              수분섭취량에 포함시키지 않습니다.
            </div>
          </div>
          <div className="flex justify-end">
            <span className="inline-block ">7. &nbsp;</span>
            <div>
              노인 환자의 경우 요실금이나 연하곤란에 대한 우려로 의도적인
              수분섭취 제한을 하지 않도록 합니다.
            </div>
          </div>
        </div>
        <div className="pb-[32px]">
          <div className="flex subtitle_1_700">2) 전해질 보충 방법</div>
          <div className="pt-[8px]">
            <div className="flex ">
              <span className="inline-block">1.&nbsp; </span>
              <div>
                나트륨 칼륨처럼 전해질이 포함된 음료를 마십니다. 스포츠 이온
                음료처럼 당분함량이 높은 경우에는 설사가 생기지 않도록 물로
                희석하여 당 농도를 낮춥니다.
              </div>
            </div>
            <div className="flex ">
              <span className="inline-block">2.</span>
              <div>
                수박, 토마토, 복숭아, 상추와 같은 수분함량이 높은 음식을 식단에
                포함시킵니다.
              </div>
            </div>
            <div className="flex ">
              <span className="inline-block">3. &nbsp;</span>
              <div>
                <span className="inline-block">
                  전해질균형 유지에 도움이 되는 식품
                </span>
                <span className="inline-block">
                  - 코코넛워터, 저당이온음료, 과일&middot;채소 주스
                </span>
              </div>
            </div>
            <div className="flex ">
              <span className="inline-block">4. </span>
              <div>
                <span className="inline-block">&nbsp;전해질보충 제품</span>
                <span className="inline-block ">
                  - 메디푸드 수분보급젤리, 링거라이트 솔루션 (담당의사 선생님과
                  상의하세요)
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex subtitle_1_700">3) 종합비타민 보충</div>
          <div className="pt-[8px]">
            장루 배설의 불편감 때문에 식품섭취 기피 등으로 인해 불균형한 식사의
            가능성이 높고 장루 배설로 인한 비타민 B12를 포함한 영양소 흡수
            손실의 가능성이 있습니다. 따라서, 흡수 능력 적응 기간 약 1년 정도는
            종합 비타민 및 무기질 보충제 섭취를 권장합니다.
          </div>
        </div>
        <div className="h-[80px]"></div>
      </div>
    </div>
  );
};

export default FoodManage2IleumMeal;
