import React from "react";
import common, { api } from "../../../../../../data/common";

const Style6_1 = ({ title2, bigTitle }) => {
  const videoskin = "/images/mo/pngjpg/장루교체_스킨.png";
  console.log(common.networkCont + "stomareplace.mp4");
  return (
    <div className="px-[16px] body_1_400 wordbreak-keepall">
      <div className="pt-[24px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">{title2}</span>
        <h1 className="h2_800 pt-[4px] pb-[24px]">{bigTitle}</h1>
      </div>
      <div className="pt-[24px] body_1_400_jangrucont">
        <video
          src={common.networkCont + "stomareplace.mp4"}
          width="100%"
          height="100%"
          controls
          poster={videoskin}
        />

        <div className="pt-[16px] wordbreak-keepall">
          <span className="block">장루 교체 방법편 동영상입니다.</span>
          <span className="block">
            영상을 따라 장루 교체 방법을 익혀보세요.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Style6_1;
