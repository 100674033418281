import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMainContext } from "../../../contexts/Provider";

const GobackPage = ({
  children,
  understanded,
  setCantgoback,
  ReplyM,
  jangruReplyNurse,
}) => {
  const navigate = useNavigate();
  const leftarrow = "/images/mo/leftarrow.svg";
  const pathname = useLocation().pathname;
  const { tabWhat } = useMainContext();

  // console.log(pathname === "/myinfo/appuse");
  // console.log(ReplyM);
  // console.log(understanded, "understanded");
  // console.log(jangruReplyNurse === undefined || understanded);

  return (
    <div className="fixed top-0 bg-[#FAFCFF] left-0 w-full z-3 subtitle_1_400">
      <div
        className={
          pathname === "/report" ||
          pathname === "/report/diary" ||
          pathname === "/report/inspection"
            ? "w-full flex justify-between items-center px-[8px] "
            : "w-full border-b-[1px] flex justify-between items-center px-[8px]"
        }
      >
        <img
          className="inline-block cursor-pointer "
          src={leftarrow}
          alt="leftarrow"
          onClick={() => {
            if (pathname === "/report/inspection") {
              if (understanded === undefined && ReplyM) {
                // setCantgoback(true); 이해도 체크 안해도 뒤로가기 가능하게끔 주석처리
                navigate(-1);
              } else {
                tabWhat.current = "check";
                // navigate("/report");
                navigate(-1);
              }
            } else if (pathname === "/community") {
              // 커뮤니티에선 홈으로 이동
              navigate("/main");
            } else {
              navigate(-1);
            }
          }}
        />
        <span className="inline-block">{children}</span>
        <span className="inline-block w-[48px] h-[48px]"></span>
      </div>
    </div>
  );
};

export default GobackPage;
