import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import common, { api } from "../../../../../data/common";
import CenterModal from "../../../mobilecomponent/CenterModal";
import { useMainContext } from "../../../../../contexts/Provider";
import PersonalPg from "../../Main/components/PersonalPg";
import ServicePg from "../../Main/components/ServicePg";

const FirstAgreeModal = ({ setShowMain }) => {
  const { loginInput } = useMainContext();
  const pocketcover = "/images/mo/pocketcover.svg";
  const checkon = "/images/mo/checkon.svg";
  const checkoff = "/images/mo/checkoff.svg";
  const right_gray = "/images/mo/right_gray.svg";
  const [service, setService] = useState(false);
  const [personal, setPersonal] = useState(false);
  const [checkall, setCheckAll] = useState(false);
  const [servicepg, setServicePg] = useState(false);
  const [personalpg, setPersonalPg] = useState(false);
  const navigate = useNavigate();
  const onClickService = () => {
    setService(!service);
  };
  const onClickPersonal = () => {
    setPersonal(!personal);
  };
  const onClickAgree = () => {
    let body = {
      id: loginInput.id,
      agree: true,
    };
    api
      .post(common.baseURL + "patient/agree", body)
      .then((res) => {
        console.log(res);
        if (res.data.ok === true) {
          console.log(res);
          navigate("/main");
        }
      })
      .catch((error) => console.log(error));
  };
  const onClickAgreeCancel = () => {
    setShowMain(false);
  };
  const showService = () => {
    setServicePg(!servicepg);
  };
  const showPersonalData = () => {
    setPersonalPg(!personalpg);
  };
  const onClickCheckOn = () => {
    setCheckAll(false);
    setService(false);
    setPersonal(false);
  };
  const onClickCheckOff = () => {
    setCheckAll(true);
    setService(true);
    setPersonal(true);
  };
  return (
    <>
      <div className="w-full h-full fixed top-0 left-0 z-10 bg-[#020A1B] opacity-[85%]"></div>
      <CenterModal>
        <div className="flex items-center gap-x-[4px] ">
          <img
            src={pocketcover}
            alt="pocketcover"
            className="w-[24px] h-[24px]"
          />
          <span className="montserrat_700">OstoCare</span>
        </div>
        <div className="pt-[33px] body_1_400">
          <span className="block">오스토케어를 이용하시기에 앞서</span>
          <span className="block">아래의 서비스 동의를 진행해 주세요.</span>
          <span className="block">미 동의시 앱 이용이 불가능 합니다.</span>
        </div>
        <div>
          <div className="flex items-center gap-x-[8px] pt-[52px] pb-[16px] border-b">
            {checkall ? (
              <img
                src={checkon}
                alt="checkon"
                className="cursor-pointer"
                onClick={onClickCheckOn}
              />
            ) : (
              <img
                src={checkoff}
                alt="checkoff"
                className="cursor-pointer"
                onClick={onClickCheckOff}
              />
            )}

            <span>서비스내용 전체 동의하기</span>
          </div>
          <div className="pt-[12px]">
            <div className="pt-[10px] flex justify-between cursor-pointer">
              <div className="flex gap-x-[8px] " onClick={onClickService}>
                {service ? (
                  <img src={checkon} alt="checkon" />
                ) : (
                  <img src={checkoff} alt="checkoff" />
                )}
                <span>[필수] 서비스 이용약관</span>
              </div>

              <img
                src={right_gray}
                alt="right_gray"
                className="cursor-pointer"
                onClick={showService}
              />
            </div>
            <div className="pt-[10px] flex justify-between cursor-pointer">
              <div className="flex gap-x-[8px] " onClick={onClickPersonal}>
                {personal ? (
                  <img src={checkon} alt="checkon" />
                ) : (
                  <img
                    src={checkoff}
                    alt="checkoff"
                    className="cursor-pointer"
                  />
                )}
                <span>[필수] 개인정보 처리방침</span>
              </div>

              <img
                src={right_gray}
                alt="right_gray"
                className="cursor-pointer"
                onClick={showPersonalData}
              />
            </div>
          </div>
        </div>

        {checkall || (service && personal) ? (
          <div
            className="mt-[33px] border border-[#1B5EDC] bg-[#FAFCFF] text-[#1B5EDC] button_700 hover:border-[#18428E] hover:text-[#18428E] hover:bg-[#F2F6FC] rounded text-center p-[20px] cursor-pointer  ease-in-out duration-300"
            onClick={onClickAgree}
          >
            확인
          </div>
        ) : (
          <div className="mt-[33px] border border-[#679DEE] bg-[#FAFCFF] text-[#679DEE] button_700 rounded text-center p-[20px]">
            확인
          </div>
        )}
        <span
          className="text-white absolute bottom-[-40px] button_700 border-b px-[8px] left-1/2 transform-x-50 z-20 cursor-pointer"
          onClick={onClickAgreeCancel}
        >
          취소하기
        </span>
      </CenterModal>
      {personalpg ? (
        <PersonalPg showPersonalData={showPersonalData} />
      ) : servicepg ? (
        <ServicePg showService={showService} />
      ) : (
        ""
      )}
    </>
  );
};

export default FirstAgreeModal;
