import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../../../contexts/Provider";
import common, { api } from "../../../../../data/common";

const ToggleBtn = () => {
  const [pushok, setPushOk] = useState();
  const { loginInput } = useMainContext();
  const goPushOn = () => {
    let body = {
      id: loginInput.id,
      pushOX: 0,
    };
    api
      .post(common.baseURL + "patient/changePushOX", body)
      .then((res) => {
        if (res.data.ok === true) {
          // console.log(res);
          const getPushOk = () => {
            api
              .get(common.baseURL + "patient/Id/" + loginInput.id)
              .then((res) => {
                console.log(res);
                if (res.data.ok === true) {
                  setPushOk(res.data.patient.pushOX);
                }
              })
              .catch((error) => console.log(error));
          };
          getPushOk();
        }
      })
      .catch((error) => console.log(error));
  };
  const goPushOff = () => {
    let body = {
      id: loginInput.id,
      pushOX: 1,
    };
    api
      .post(common.baseURL + "patient/changePushOX", body)
      .then((res) => {
        if (res.data.ok === true) {
          console.log(res);
          const getPushOk = () => {
            api
              .get(common.baseURL + "patient/Id/" + loginInput.id)
              .then((res) => {
                console.log(res);
                if (res.data.ok === true) {
                  setPushOk(res.data.patient.pushOX);
                }
              })
              .catch((error) => console.log(error));
          };
          getPushOk();
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    api
      .get(common.baseURL + "patient/Id/" + loginInput.id)
      .then((res) => {
        console.log(res);
        if (res.data.ok === true) {
          setPushOk(res.data.patient.pushOX);
        }
      })
      .catch((error) => console.log(error));
  }, [loginInput.id]);
  console.log(pushok);
  return (
    <div className="w-[56px] h-[32px] border rounded-full  flex items-center relative">
      {pushok === 0 ? (
        <span
          className="inline-block w-[24px] h-[24px] rounded-full bg-[#FAFCFF] border ml-16 cursor-pointer absolute right-1 z-1"
          onClick={goPushOff}
        ></span>
      ) : (
        <span
          className="inline-block w-[24px] h-[24px] rounded-full bg-[#FAFCFF] border cursor-pointer absolute left-1 z-1"
          onClick={goPushOn}
        ></span>
      )}
      <div
        className={
          pushok === 0
            ? "w-full h-full rounded-full flex bg-[#5FC17B]"
            : "w-full h-full rounded-full flex bg-[#E5E6E7] "
        }
      >
        <span className="flex items-center justify-center inline-block w-1/2 "></span>
        <span className="flex items-center justify-center inline-block w-1/2 "></span>
      </div>
    </div>
  );
};

export default ToggleBtn;
