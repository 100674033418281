import { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import commonUrl, { api } from "../../../../../data/common";
import Popup from "reactjs-popup";
import { BtnBlack } from "../../../admin_components/Buttons";
import { useMainContext } from "../../../../../contexts/Provider";
import { ToastContainer, toast } from "react-toastify";
import { toastCommonProps } from "../../../admin_components/Toast";
import { handlingError } from "../../../admin_components/Error";

const PopUp = ({ data, open, onClose, updateData }) => {
  const cancel = "/images/admin/cancel.svg";
  const { adminLogin } = useMainContext(); // 로그인 정보 Consumer
  const [check, setCheck] = useState({
    id: data.id,
    admin: adminLogin.id,
    jangruSurvey: data.jangruSurvey,
    jangruComplicationsText: data.jangruComplicationsText,
    skinComplicationsText: data.skinComplicationsText,
  });
  const [loading, setLoading] = useState(false);
  // 장루 합병증 리스트
  const jangruComList = [
    "문제 없음",
    "출혈",
    "탈출",
    "탈장",
    "괴사",
    "허혈",
    "협착",
    "함몰",
    "장폐쇄",
    "누관형성",
    "장점막피부경계분리",
  ];
  // 피부 합병증 리스트
  const skinComList = [
    "문제 없음",
    "자극성접촉피부염",
    "알러지성피부염",
    "모낭염",
    "칸디다증",
    "괴저성 높이증",
    "장루주위 정맥류",
    "연화/침윤",
    "표피비대",
    "물리적 손상",
    "기타",
  ];
  const [jangruCheck, setJangruCheck] = useState({
    0: false, // 문제없음
    1: false, // 출혈
    2: false, // 탈출
    3: false, // 탈장
    4: false, // 괴사
    5: false, // 허혈
    6: false, // 협착
    7: false, // 함몰
    8: false, // 장폐쇄
    9: false, // 누관형성
    10: false, // 장점막피부경계분리
    11: false, // 기타
  });
  const [skinCheck, setSkinCheck] = useState({
    0: false, // 문제없음
    1: false, // 자극성접촉피부염
    2: false, // 알러지성피부염
    3: false, // 모낭염
    4: false, // 칸디다즘
    5: false, // 괴저성높이증
    6: false, // 장루 주위 정맥류
    7: false, // 연화/침윤
    8: false, // 표피비대
    9: false, // 물리적 손상
    10: false, // 기타
  });
  const [entireCheck, setEntireCheck] = useState({
    0: false, // 추적이 필요합니다. 다음 정기...
    1: false, // 병원 방문이 필요합니다.
  });

  // 저장하기버튼 누를 시
  const save = () => {
    let newData = {};
    let newJC = [];
    let newSC = [];
    let newFull = -1;

    // 장루 합병증
    for (const [key, value] of Object.entries(jangruCheck)) {
      if (value) newJC.push(Number(key));
    }

    // 피부 합병증
    for (const [key, value] of Object.entries(skinCheck)) {
      if (value) newSC.push(Number(key));
    }

    // 종합평가
    if (entireCheck[0]) newFull = 1;
    else newFull = 2;

    // 선택 안한 항목이 있을 경우
    if (newJC.length === 0 || newSC.length === 0 || newFull === -1) {
      toast(
        <p>누락된 항목이 있습니다.</p>,
        toastCommonProps(1500, "top-center", "toast_common toast__check")
      );
      return;
    }
    // 기타내용이 비었을 경우
    else if (jangruCheck[11] && check.jangruComplicationsText.trim() === "") {
      toast(
        <p>기타 내용을 작성해주세요.</p>,
        toastCommonProps(1500, "top-center", "toast_common toast__check")
      );
      return;
    } else if (skinCheck[10] && check.skinComplicationsText.trim() === "") {
      toast(
        <p>기타 내용을 작성해주세요.</p>,
        toastCommonProps(1500, "top-center", "toast_common toast__check")
      );
      return;
    }

    newData = {
      id: check.id, // replyID
      jangruSurvey: check.jangruSurvey, // surveyID
      admin: check.admin, // adminID
      jangruComplications: newJC, // 장루 합병증
      skinComplications: newSC, // 피부 합병증
      fullEvaluation: newFull, // 종합 평가
      jangruComplicationsText: jangruCheck[11] // 기타
        ? check.jangruComplicationsText
        : "",
      skinComplicationsText: skinCheck[10] ? check.skinComplicationsText : "",
    };
    // console.log(newData, ">>> newData");

    // 저장하는 함수
    let response = {};
    async function fetchData() {
      setLoading(true);
      response = await api({
        method: "post",
        url: commonUrl.baseURL + "jangruCheck",
        data: newData,
      }).catch((error) => {
        handlingError(error);
      });
      console.log(response.data, ">>> Axios jangruCheck");
      if (response.data.ok) {
        toast(
          <p>저장 되었습니다.</p>,
          toastCommonProps(1500, "top-center", "toast_common toast__check")
        );
        setTimeout(() => {
          setJangruCheck({ ...false });
          setSkinCheck({ ...false });
          onClose();
          updateData();
        }, 2000);
      }
      setLoading(false);
    }
    fetchData();
  };

  // 이전에 작성된 데이터를 가져와 UI에 맞게 수정
  useEffect(() => {
    const {
      jangruComplications, // 장루 합병증
      skinComplications, // 피부 합병증
      fullEvaluation, // 종합 평가
    } = data;

    // 이미 작성된 답변이 있었다면
    if (jangruComplications !== undefined) {
      let newJC = {};
      let newSC = {};

      // 장루 합병증 미리 체크
      if (jangruComplications)
        jangruComplications.forEach((el) => {
          newJC = Object.assign(newJC, { [el]: true });
        });
      setJangruCheck({
        ...newJC,
      });

      // 피부 합병증 미리 체크
      if (skinComplications)
        skinComplications.forEach((el) => {
          newSC = Object.assign(newSC, { [el]: true });
        });
      setSkinCheck({
        ...newSC,
      });

      // 종합 평가 미리 체크
      if (fullEvaluation === 1)
        setEntireCheck({
          0: true,
          1: false,
        });
      else if (fullEvaluation === 2)
        setEntireCheck({
          0: false,
          1: true,
        });
      else
        setEntireCheck({
          0: false,
          1: false,
        });
    }
    setCheck({
      id: data.id,
      admin: adminLogin.id,
      jangruSurvey: data.jangruSurvey,
      jangruComplicationsText: data.jangruComplicationsText,
      skinComplicationsText: data.skinComplicationsText,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <style jsx="true">{`
        .popup-overlay.jangrucheck__popup-overlay {
          overflow: auto;
        }
        .popup-content.jangrucheck__popup-content {
          border-radius: 8px;
          width: 685px;
          box-sizing: border-box;
          padding: 24px;
        }
        .carousel-root.popupCarousel .carousel-slider {
          height: 264px;
        }
        .carousel-root.popupCarousel .slider-wrapper {
          height: 240px;
        }

        /* 화살표 customizing */
        .carousel .control-next.control-arrow,
        .carousel .control-next.control-arrow:hover {
          background-color: transparent;
          right: 85px;
        }
        .carousel .control-prev.control-arrow,
        .carousel .control-prev.control-arrow:hover {
          background-color: transparent;
          left: 85px;
        }
        .carousel-root.popupCarousel .control-arrow.control-prev:before {
          content: url(/images/admin/carousel_left.svg);
          color: black;
          width: 70px;
          min-width: 70px;
        }
        .carousel-root.popupCarousel .control-arrow.control-next:before {
          content: url(/images/admin/carousel_right.svg);
          width: 70px;
          min-width: 70px;
        }
        .carousel-root.popupCarousel .control-dots {
          bottom: -4px;
          margin: 16px 0 0;
        }
        .carousel-root.popupCarousel .control-dots li {
          background-color: grey;
          width: 8px;
          height: 8px;
        }
        .carousel-root.popupCarousel li {
          height: 100%;
          margin: auto 0;
        }
        .Toastify__toast {
          top: 20px;
        }
      `}</style>
      <Popup
        open={open}
        closeOnDocumentClick
        onClose={onClose}
        className="jangrucheck__popup "
      >
        <ToastContainer className="admin" />
        <div className="admin">
          <div className="flex items-center justify-between">
            <label className="modal font-extrabold text-2xl leading-8 text-[#343B48]">
              장루·피부 합병증 평가하기
            </label>
            <img
              src={cancel}
              alt="cancel"
              className="w-[18px] h-[18px] cursor-pointer"
              onClick={onClose}
            />
          </div>
          <p className=" text-bas leading-[22px] font-normal text-[#4E535F]">
            {adminLogin.belong === 1 && adminLogin.power === 1
              ? "헤링스 일반관리자는 평가 권한이 없습니다."
              : "해당되는 항목을 선택해주세요(중복가능)."}
          </p>
          {/* 사진들 Carousel */}
          <Carousel
            className="mt-6 mb-5 popupCarousel"
            infiniteLoop
            showArrows
            showStatus={false}
            showIndicators
            showThumbs={false}
          >
            {data.imageURL.length !== 0 ? (
              data.imageURL.map((el, idx) => {
                return (
                  <div
                    className="w-full mx-auto max-w-[240px] max-h-[240px] "
                    key={idx}
                  >
                    <img
                      className="w-full mx-auto max-w-[240px] max-h-[240px] object-contain"
                      src={
                        commonUrl.networkImg +
                        encodeURIComponent(data.imageURL[idx])
                      }
                      alt="사진"
                      style={{ pointerEvents: "auto" }}
                    />
                  </div>
                );
              })
            ) : (
              <div className="box-border rounded-[5px] w-full mx-auto max-w-[400px] max-h-[300px]">
                <span className="material-icons border-2 border-slate-30 border-2 border-slate-300 rounded-[3px] box-border text-[290px] text-slate-500">
                  no_photography
                </span>
              </div>
            )}
          </Carousel>
          {/* 장루 합병증 평가 */}
          <label className="font-extrabold text-xl leading-[26px] text-[#343B48]">
            장루 합병증
          </label>
          <div className="flex flex-wrap items-start mt-2 mb-4 gap-x-2 gap-y-2">
            {jangruComList.map((el, idx) => {
              return (
                <button
                  key={idx}
                  className="flex items-center justify-center px-4 py-1 border border-[#4E535F] bg-[#4E535F] rounded-[18px] font-normal text-lg text-[#4E535F] leading-[22px]"
                  onClick={(e) => {
                    // 문제없음을 눌렀을 경우
                    if (idx === 0 && !jangruCheck[0]) {
                      setJangruCheck({
                        0: true,
                      });
                    } else {
                      setJangruCheck({
                        ...jangruCheck,
                        [idx]: !jangruCheck[idx],
                      });
                    }
                  }}
                  style={
                    jangruCheck[0]
                      ? idx === 0
                        ? {
                            backgroundColor: `#4E535F`, // 어두운 배경
                            color: "#FAFBFC", // 밝은 글씨
                          }
                        : {
                            backgroundColor: "#f7f7f8", // 회색 배경
                            color: "#DADBDC", // 회색 글씨
                            borderColor: "#DADBDC", // 회색 테두리
                          }
                      : jangruCheck[idx]
                      ? {
                          backgroundColor: `#4E535F`,
                          color: "#FAFBFC",
                        }
                      : {
                          backgroundColor: `#FAFBFC`,
                          color: "#4E535F",
                        }
                  }
                  disabled={
                    // 헤링스 일반관리자라면 disabled
                    !(adminLogin.belong === 1 && adminLogin.power === 1)
                      ? // 문제없음이 체크되었으면
                        jangruCheck[0]
                        ? // 문제없음 이외 다른 버튼들 disabled
                          idx !== 0
                          ? true
                          : false
                        : false
                      : true
                  }
                >
                  {el}
                </button>
              );
            })}
            {/* 장루 합병증 기타 */}
            <div className="flex gap-x-2">
              <button
                className="flex items-center justify-center px-4 py-1 border border-[#4E535F] bg-[#4E535F] rounded-[18px] font-normal text-lg text-[#4E535F] leading-[22px]"
                onClick={() => {
                  setJangruCheck({
                    ...jangruCheck,
                    11: !jangruCheck[11],
                  });
                }}
                style={
                  jangruCheck[0]
                    ? {
                        backgroundColor: "#f7f7f8",
                        color: "#DADBDC",
                        borderColor: "#DADBDC",
                      }
                    : jangruCheck[11]
                    ? {
                        backgroundColor: `#4E535F`,
                        color: "#FAFBFC",
                      }
                    : {
                        backgroundColor: `#FAFBFC`,
                        color: "#4E535F",
                      }
                }
                disabled={
                  // 일반관리자가 아니고
                  !(adminLogin.belong === 1 && adminLogin.power === 1)
                    ? jangruCheck[0]
                      ? true
                      : false
                    : true
                }
              >
                기타
              </button>
              <input
                className="max-w-[160px] px-[15px] items-center justify-center px-4 py-1 border border-[#4E535F] rounded-[18px] font-normal text-lg text-[#4E535F] leading-[22px] focus:outline-none placeholder:text-sm"
                type="text"
                value={
                  check.jangruComplicationsText
                    ? check.jangruComplicationsText
                    : ""
                }
                onChange={(e) => {
                  setCheck({
                    ...check,
                    jangruComplicationsText: e.target.value,
                  });
                }}
                style={
                  jangruCheck[0]
                    ? {
                        borderColor: "#DADBDC",
                        backgroundColor: "#f7f7f8",
                        color: "#DADBDC",
                      }
                    : jangruCheck[11]
                    ? null
                    : { color: "#DADBDC" }
                }
                disabled={
                  !(adminLogin.belong === 1 && adminLogin.power === 1)
                    ? jangruCheck[11]
                      ? false
                      : true
                    : true
                }
                placeholder={jangruCheck[11] ? "기타 내용 작성" : ""}
              />
            </div>
          </div>
          <div className="h-[1px] w-full bg-[#CCCDD1] mb-4"></div>

          {/* 피부 합병증 평가 */}
          <label className="font-extrabold text-xl leading-[26px] text-[#343B48]">
            피부 합병증
          </label>
          <div className="flex flex-wrap items-start mt-2 mb-4">
            {skinComList.map((el, idx) => {
              return (
                <button
                  key={idx}
                  className="flex items-center justify-center px-4 py-1 mb-2 mr-2 border border-[#4E535F] bg-[#4E535F] rounded-[18px] font-normal text-lg text-[#4E535F] leading-[22px]"
                  onClick={(e) => {
                    if (idx === 0 && !skinCheck[0]) {
                      // 문제없음을 눌렀을 경우
                      setSkinCheck({
                        0: true,
                      });
                    } else {
                      setSkinCheck({
                        ...skinCheck,
                        [idx]: !skinCheck[idx],
                      });
                    }
                  }}
                  style={
                    skinCheck[0]
                      ? idx === 0
                        ? {
                            backgroundColor: `#4E535F`,
                            color: "#FAFBFC",
                          }
                        : {
                            backgroundColor: "#f7f7f8",
                            color: "#DADBDC",
                            borderColor: "#DADBDC",
                          }
                      : skinCheck[idx]
                      ? {
                          backgroundColor: `#4E535F`,
                          color: "#FAFBFC",
                        }
                      : {
                          backgroundColor: `#FAFBFC`,
                          color: "#4E535F",
                        }
                  }
                  disabled={
                    !(adminLogin.belong === 1 && adminLogin.power === 1)
                      ? skinCheck[0]
                        ? idx !== 0
                          ? true
                          : false
                        : false
                      : true
                  }
                >
                  {el}
                </button>
              );
            })}
            <input
              className="min-w-[300px] px-[15px] items-center justify-center px-4 py-1 border border-[#4E535F] rounded-[18px] font-normal text-lg text-[#4E535F] leading-[22px] focus:outline-none placeholder:text-sm"
              type="text"
              value={
                check.skinComplicationsText ? check.skinComplicationsText : ""
              }
              onChange={(e) => {
                setCheck({ ...check, skinComplicationsText: e.target.value });
              }}
              style={
                skinCheck[0]
                  ? {
                      borderColor: "#DADBDC",
                      backgroundColor: "#f7f7f8",
                      color: "#DADBDC",
                    }
                  : skinCheck[10]
                  ? null
                  : { color: "#DADBDC" }
              }
              disabled={
                !(adminLogin.belong === 1 && adminLogin.power === 1)
                  ? skinCheck[10]
                    ? false
                    : true
                  : true
              }
              placeholder={skinCheck[10] ? "기타 내용 작성" : ""}
            />
          </div>
          <div className="h-[1px] w-full bg-[#CCCDD1] mb-3"></div>

          {/* 종합 평가 */}
          <label className="font-extrabold text-xl leading-[26px] text-[#343B48]">
            종합 평가
          </label>
          <div className="flex flex-wrap items-start mt-2 mb">
            <button
              className="flex items-center justify-center px-4 py-1 mb-2 mr-2 border border-[#4E535F] bg-[#4E535F] rounded-[18px] font-normal text-lg text-[#4E535F] leading-[22px]"
              value={entireCheck[0]}
              onClick={() => {
                setEntireCheck({
                  0: entireCheck[0] ? false : true,
                  1: entireCheck[0] ? true : false,
                });
              }}
              style={
                entireCheck[0]
                  ? {
                      backgroundColor: `#4E535F`,
                      color: "#FAFBFC",
                    }
                  : {
                      backgroundColor: `#FAFBFC`,
                      color: `#4E535F`,
                    }
              }
              disabled={
                !(adminLogin.belong === 1 && adminLogin.power === 1) !== 1
                  ? false
                  : true
              }
            >
              추적 관찰이 필요합니다. 다음 정기 점검 때 재관찰하겠습니다.
            </button>
            <div className="flex justify-between grow-1">
              <button
                className="flex items-center justify-center px-4 py-1 mb-2 mr-2 border border-[#4E535F] bg-[#4E535F] rounded-[18px] font-normal text-lg text-[#4E535F] leading-[22px]"
                value={entireCheck[1]}
                onClick={() => {
                  setEntireCheck({
                    1: entireCheck[1] ? false : true,
                    0: entireCheck[1] ? true : false,
                  });
                }}
                style={
                  entireCheck[1]
                    ? {
                        backgroundColor: `#4E535F`,
                        color: "#FAFBFC",
                      }
                    : {
                        backgroundColor: `#FAFBFC`,
                        color: "#4E535F",
                      }
                }
                disabled={
                  !(adminLogin.belong === 1 && adminLogin.power === 1)
                    ? false
                    : true
                }
              >
                병원 방문이 필요합니다.
              </button>
            </div>
          </div>
          <div className="flex justify-end">
            {!(adminLogin.belong === 1 && adminLogin.power === 1) ? (
              <BtnBlack
                context="저장하기"
                onClick={save}
                loading={loading}
                style={{ width: "88px" }}
              />
            ) : null}
          </div>
        </div>
      </Popup>
    </>
  );
};

export default PopUp;
