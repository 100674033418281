import React from "react";

const WelfareOrganize = ({ bigTitle, btn2 }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400 wordbreak-keepall">
      <div className="pt-[72px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
        <h1 className="h2_800 pt-[4px] pb-[24px]">{btn2}</h1>
      </div>

      <div className="w-full pt-[24px] body_1_400_jangrucont">
        <div className="border">
          <div className="border-b p-[8px] bg-[#F2F6FC] subtitle_2 text-center">
            장애 등급에 따른 분류
          </div>
          <div className="flex border-b">
            <div className="w-[80px] bg-[#F2F6FC] subtitle_2 flex justify-center items-center border-r">
              2급1호
            </div>
            <span className="w-[310px] p-[8px] inline-block">
              장루와 함께 요루 또는 방광루를 가지고 있으며, 그 중 하나 이상의
              루에 합병증으로 장피누공 또는 배뇨기능장애가 있는 사람
            </span>
          </div>
          <div className="flex border-b">
            <div className="w-[80px] bg-[#F2F6FC] subtitle_2 flex justify-center items-center border-r">
              2급2호
            </div>
            <span className="w-[310px] p-[8px] inline-block">
              장루 또는 요루를 가지고 있으며, 합병증으로 장피누공과
              배뇨기능장애가 모두 있는 사람
            </span>
          </div>
          <div className="flex border-b">
            <div className="w-[80px] bg-[#F2F6FC] subtitle_2 flex justify-center items-center border-r">
              2급3호
            </div>
            <span className="w-[310px] p-[8px] inline-block">
              배변을 위한 말단 공장루를 가지고 있는 사람
            </span>
          </div>
          <div className="flex border-b">
            <div className="w-[80px] bg-[#F2F6FC] subtitle_2 flex justify-center items-center border-r">
              3급1호
            </div>
            <span className="w-[310px] p-[8px] inline-block">
              장루와 함께 요루 또는 방광루를 가지고 있는 사람
            </span>
          </div>
          <div className="flex border-b">
            <div className="w-[80px] bg-[#F2F6FC] subtitle_2 flex justify-center items-center border-r">
              3급2호
            </div>
            <span className="w-[310px] p-[8px] inline-block">
              장루 또는 요루를 가지고 있으며, 합병증으로 장피누공 또는
              배뇨기능장애가 있는 사람
            </span>
          </div>
          <div className="flex border-b">
            <div className="w-[80px] bg-[#F2F6FC] subtitle_2 flex justify-center items-center border-r">
              4급1호
            </div>
            <span className="w-[310px] p-[8px] inline-block">
              장루 또는 요루를 가진 사람
            </span>
          </div>
          <div className="flex border-b">
            <div className="w-[80px] bg-[#F2F6FC] subtitle_2 flex justify-center items-center border-r">
              4급2호
            </div>
            <span className="w-[310px] p-[8px] inline-block">
              방광루를 가지고 있으며, 합병증으로 장피누공이 있는 사람
            </span>
          </div>
          <div className="flex">
            <div className="w-[80px] bg-[#F2F6FC] subtitle_2 flex justify-center items-center border-r">
              5급
            </div>
            <span className="w-[310px] p-[8px] inline-block">
              방광루를 가진 사람
            </span>
          </div>
        </div>
      </div>
      <div className="h-[70px]"></div>
    </div>
  );
};

export default WelfareOrganize;
