import React, { useEffect, useRef, useState } from "react";
import { useMainContext } from "../../../../../contexts/Provider";
import common, { api } from "../../../../../data/common";
import { useNavigate } from "react-router-dom";
import { Flip, toast, ToastContainer } from "react-toastify";
import ReactQuill from "react-quill";
import TextEditorToolbar, {
  modules,
  formats,
} from "../../../admin_components/TextEditorToolbar";
import { BtnBlack } from "../../../admin_components/Buttons";
import { toastCommonProps } from "../../../admin_components/Toast";
let index_count = 0;
let cur_data = "";
let scroll_count = 0;
let current_spot = 0;
const MessageSend = () => {
  const profile = "/images/admin/profile.svg";
  const navigate = useNavigate();
  const [catch_w, setCatch_w] = useState("");
  const [loading] = useState(false);
  const { adminLogin } = useMainContext();
  const { members, setMembers } = useMainContext();
  const [text, setText] = useState("");
  const patientRef = useRef([]); // 표기할 정보 담기
  const [patients, setPatients] = useState([]);
  const [contents, setContents] = useState("");
  const [val_in, setVal_in] = useState(true);
  const [press, setPress] = useState();
  const [toastElPosition, setToastElPosition] = useState({ top: 0, left: 0 }); // toast창 위치 값
  const [search, setSearch] = useState([]);
  const [input_clk, setInput_clk] = useState(false);
  const [effec, setEffec] = useState({ keyCode: "0", make: "who?" });
  const len = search ? search.length : 60;
  const listHeight = 300;
  const currentScroll = useRef({ scrollTop: 0, scrollBottom: listHeight });
  const containerRef = useRef();

  const toast_func = (text) => {
    console.log(text);
    toast(
      <p>{text}</p>,
      toastCommonProps(3000, "top-left", "toast_common toast__normal", Flip)
    );
  };

  const onChangeContents = (e) => {
    setContents(e);
  };
  var send_id = [];
  for (let i = 0; i < members.length; i++) {
    if (members[i].includes(" (") && members[i].includes(")")) {
      const tmp = members[i].split(" (");
      const tmp_ = tmp[1].split(")");
      send_id[i] = tmp_[0];
    }
  }
  useEffect(() => {
    const keyPress = (e) => {
      //⬇ = 40
      if (e.key === "ArrowDown" || index_count === -1) {
        goDown();
        setEffec({ keyCode: 40, make: "me" });
        handleKeyPress(effec);
      }
      //⬆ = 38
      else if (e.key === "ArrowUp") {
        goUp();
        setEffec({ keyCode: 38, make: "me" });
        handleKeyPress(effec);
      }
    };
    window.addEventListener("keydown", keyPress);
    return () => {
      window.removeEventListener("keydown", keyPress);
    };
  });

  const onScroll = (e) => {
    if (current_spot === 1)
      currentScroll.current = {
        scrollTop: e.target.scrollTop,
        scrollBottom: e.target.scrollTop + listHeight,
      };
  };
  const goUp = () => {
    console.log((len - 10) * 50, scroll_count, index_count);
    if ((index_count + 1) * 50 === scroll_count) {
      if (index_count >= 0) {
        current_spot = 1;
        scroll_count -= 50;
        containerRef.current.scrollTo(0, scroll_count);
      }
    }
  };

  /****** [5] ******/
  const goDown = () => {
    console.log((len - 10) * 50, scroll_count, index_count);
    if (index_count - 1 - scroll_count / 50 === 9) {
      current_spot = 10;
      scroll_count += 50;
      containerRef.current.scrollTo(0, scroll_count);
    }
  };

  const handleKeyPress = (e) => {
    //쪽지 받는사람 입력 후 엔터 클릭 시
    if (e.keyCode === 13) {
      if (members.length >= 10) {
        toast_func("최대 10명까지 발송이 가능합니다.");

        return;
      } else if (index_count >= 0 && search.length > 0) {
        members.push(search[index_count].name);
        setSearch([]);
        setText("");
      } else {
        const toastEl = document.querySelector(".toast_standard");

        setToastElPosition({
          top: toastEl.getBoundingClientRect().top,
          left: toastEl.getBoundingClientRect().left,
        });
        let input_data = result.filter((i) =>
          i.name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        // 전체 대상자 목록에 포함되어 있고, 이미 입력한 사람이 아닐 시,
        if (input_data.length === 1)
          setMembers([...members, input_data[0].name]);
        else if (input_data.length > 1) return;
        else setMembers([...members, text + "&"]);

        setSearch([]);
        setText("");
      }
      setPress(0);

      index_count = -1;
      scroll_count = 0;
      current_spot = -1;
    }
    //방향키 or backspace 클릭 시
    else if (
      e.keyCode === 40 || // ⬇
      e.keyCode === 38 || // ⬆
      e.keyCode === 37 || // ⬅
      e.keyCode === 39 || // ➡
      e.keyCode === 8 // backspace
    ) {
      setPress(e.keyCode);
      if (e.make === undefined) {
        if (e.keyCode === 40) {
          index_count++;
          current_spot++;
        } else if (e.keyCode === 38) {
          index_count--;
          current_spot--;
        }
      }
      if (index_count < 0) {
        index_count = 0;
      }
      if (index_count > search.length - 1) index_count = search.length - 1;

      const len = members.length - 1;

      if (e.keyCode === 8 && text.length === 0) {
        // 아무것도 없는 상태에서 Backspace 한번 더 눌렀을 때
        setMembers(members.filter((member) => members[len] !== member));
        setPress(0);
        setSearch([]);
      } else if (text.length === 0) {
        // 아무것도 없고 스크롤 눌렀을 때
        if (e.keyCode === 40) setSearch(result);
        setCatch_w("");
      } else if (text.length > 0) {
        setPress(e.keyCode);
        return;
      }
    } else {
      index_count = -1;
      scroll_count = 0;
      current_spot = -1;
    }
    /*
    Enter = 13
    backspace = 8
    ➡ = 39
    ⬅ = 37
    ⬆ = 38
    ⬇ = 40
    */
  };
  const onSubmitContents = () => {
    const toastEl = document.querySelector(".toast_standard");
    setToastElPosition({
      top: toastEl.getBoundingClientRect().top,
      left: toastEl.getBoundingClientRect().left,
    });
    let esend = false;
    for (let i = 0; i < members.length; i++) {
      if (members[i].includes("&")) esend = true;
    }
    let body = {
      content: contents,
      admin: adminLogin.id,
      patientList: send_id,
    };
    if (
      body.content.replace(/(\s*)/g, "") === "<p></p>" ||
      body.content === "<p><br></p>" ||
      body.content === "" ||
      members.length === 0
    ) {
      toast_func("받는사람과 내용을 입력해주세요.");
    } else if (esend) {
      alert(
        "‘받는사람’을 인식할 수 없습니다. 형태가 모두 올바른지 확인하세요."
      );
    } else {
      api
        .post(common.baseURL + "message", body)
        .then((res) => {
          if (res.data.ok === true) {
            toast_func("발송되었습니다.");

            setTimeout(() => navigate("/admin/main/message"), 3000);
          } else toast_func("error");
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    let pageOpen = false;

    async function fetchData() {
      const response = await api.get(
        common.baseURL + "patient/all/" + adminLogin.belong
      );
      patientRef.current = response.data.patientList;
      setPatients(patientRef.current); // table data 불러오기
    }
    if (!pageOpen) {
      fetchData();
    }
    return () => {
      pageOpen = true;
    };
  }, [adminLogin.belong]);
  let result = [];

  result =
    patients === undefined
      ? ""
      : patients.map((e) => {
          return {
            name: e.name + " (" + e.patientID + ")",
            url: e.profileURL,
            realname: e.name,
            code: e.patientID,
            gender: e.gender,
          };
        });

  //// input 입력할 떄마다 검색
  const updateChage = (e) => {
    cur_data = e.target.value;
    setCatch_w(cur_data);
    if (result !== undefined && result.length > 0) {
      let filterData = result.filter((i) =>
        i.name.toLowerCase().includes(cur_data.toLowerCase())
      );
      if (cur_data.length === 0) {
        filterData = [];
      }
      setSearch(filterData);
    }
    setText(e.target.value);
  };
  return (
    <>
      <style jsx="true">{`
        .Toastify__toast {
          top: ${toastElPosition.top - 20}px;
          left: ${toastElPosition.left - 285}px;
        }
        .ql-toolbar.ql-snow {
          border-top-width: 0 !important;
          border-left-width: 0 !important;
          border-right-width: 0 !important;
          border-bottom-width: 2px !important;
          border-color: #e5e6e7 !important;
        }
        .ql-container.ql-snow {
          border: none !important;
        }
        .ql-container.ql-snow .ql-editor {
          padding: 0px !important;
        }
      `}</style>
      <div className="admin flex flex-col grow py-[42px] px-[12px]">
        <label className="text-2xl font-extrabold leading-8 text-[#020A1B]">
          쪽지 작성
        </label>
        <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-3 min-w-[975px]">
          <div
            className="grid grid-cols-5 min-w-[915px] text-center border border-[#E5E6E7] bg-[#fafbfc] "
            onClick={() => {
              setSearch([]);
            }}
          >
            <label
              className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-lg leading-[22px] text-[#4E535F] box-border"
              onClick={() => {
                setVal_in(true);
                setInput_clk(false);
              }}
            >
              받는사람
            </label>

            <label
              onClick={() => {
                setInput_clk(true);
              }}
              className="flex flex-wrap bg-white justify-start items-center p-2 py-[7px] pb-[10px] border border-[#E5E6E7] font-semibold text-lg leading-[22px] text-[#020A1B] box-border col-span-4"
            >
              {members //member = 현재 보내기로 입력되어 있는 환자 List 배열
                ? members.map((el, idx) => (
                    <div className="flex " key={idx}>
                      <span
                        key={el}
                        className={
                          el.includes("&")
                            ? "w-auto border rounded-2xl mr-1 px-2 flex items-center bg-red-500 mt-1 text-base text-white border"
                            : "w-auto border rounded-2xl mr-1 px-2 flex items-center hover:bg-gray-100 mt-1 text-base text-gray-600"
                        }
                      >
                        {el.includes("&") ? el.substr(0, el.length - 1) : el}
                        <span
                          className="flex items-center w-auto text-xl text-gray-400 cursor-pointer material-icons hover:text-black "
                          key={idx}
                          id={el}
                          onClick={(el) => {
                            setMembers(
                              members.filter(
                                (member) => member !== el.target.id
                              )
                            );
                          }}
                        >
                          clear
                        </span>
                      </span>
                    </div>
                  ))
                : ""}
              <div>
                {val_in ? (
                  <input
                    placeholder={
                      members.length === 0 ? "받는사람을 입력하세요." : ""
                    }
                    autoComplete="off"
                    id="input"
                    type="text"
                    key="input_id"
                    value={text}
                    onChange={(e) => updateChage(e)}
                    className="relative w-5/6 h-full mt-1  focus:outline-none"
                    onKeyDown={handleKeyPress}
                  />
                ) : (
                  ""
                )}
                {/* 검색창 띄우기 */}
                {
                  <div
                    className="fixed top-58 max-h-[500px] overflow-auto text-left border bg-white shadow-[0_1px_4px_rgba(0,0,0,0.25)] rounded-[6px] z-40"
                    key="search"
                    ref={containerRef}
                    onScroll={onScroll}
                  >
                    {input_clk && search.length > 0 ? (
                      search.map((item, idx) => {
                        const new_id = item.code.split("");
                        let tmp = catch_w
                          ? item.code
                              .toLowerCase()
                              .indexOf(catch_w.toLowerCase())
                          : -1;
                        let count = 0;
                        let ss;
                        let len = catch_w.length;
                        return (
                          <div
                            key={idx}
                            className={
                              (press === 40 && idx === index_count) ||
                              (press === 38 && idx === index_count)
                                ? "bg-gray-200 text-base font-medium w-60 px-3 py-[7px]"
                                : "hover:bg-blue-100 text-base font-medium w-60 px-3 py-[7px]"
                            }
                            onClick={(e) => {
                              setMembers([...members, item.name]);
                              setText("");
                            }}
                          >
                            <div className="flex items-center">
                              <img
                                src={
                                  item.url === null
                                    ? profile
                                    : common.networkImg + item.url
                                }
                                className="mr-2 border rounded-full w-7 h-7 "
                                alt="profile"
                              />
                              <div>
                                <div className="flex text-sm font-normal">
                                  {item.realname}
                                </div>
                                <div className="flex items-center">
                                  {new_id.map((word, cnt) => {
                                    if (cnt === tmp + count) {
                                      ss = cnt;
                                      if (len > 1) {
                                        count++;
                                        len--;
                                      }
                                    }
                                    return (
                                      <p
                                        key={cnt}
                                        className={
                                          cnt === ss
                                            ? "text-xs font-medium text-black "
                                            : "text-xs font-light text-gray-500"
                                        }
                                      >
                                        {word}
                                      </p>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                }
              </div>
            </label>
            <label
              onClick={() => {
                setInput_clk(false);
              }}
              className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-lg leading-[22px] text-[#4E535F] box-border"
            >
              내용
            </label>
            <div
              onClick={() => {
                setInput_clk(false);
              }}
              className="w-full bg-white vertical-middle border border-[#E5E6E7] col-span-4 overflow-auto"
            >
              <TextEditorToolbar />
              <ReactQuill
                className="w-full h-40 p-6"
                theme="snow"
                placeholder="내용을 입력하세요(최대 500자)"
                modules={modules}
                formats={formats}
                value={contents}
                onChange={onChangeContents}
              />
            </div>
          </div>
          <div className="flex w-full justify-end items-center mt-[18px]">
            <div className="flex gap-x-2">
              <BtnBlack
                context="발송하기"
                onClick={onSubmitContents}
                loading={loading}
                width="88px"
                standard="toast_standard"
              />
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default MessageSend;
