import { useRef, useState } from "react";
import { BtnBlack, BtnWhite } from "../../../admin_components/Buttons";
import autoInputPhone from "../../../admin_components/AutoInputPhone";
import commonUrl, { api } from "../../../../../data/common";
import { ToastContainer, toast } from "react-toastify";
import Header2 from "../../../admin_components/Header/Header2";
import { useNavigate } from "react-router-dom";
import {
  findToastPosition,
  toastCommonProps,
} from "../../../admin_components/Toast";
import Popup from "reactjs-popup";
import Postcode from "../components/Postcode";
import { handlingError } from "../../../admin_components/Error";
import { useMainContext } from "../../../../../contexts/Provider";

function Register() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [toastElPosition, setToastElPosition] = useState({ top: 0, left: 0 }); // toast창 위치 값
  const { setBelongs } = useMainContext();
  // 병원 등록 정보
  const [hospital, setHospital] = useState({
    name: "", // 병원 명
    employeeName: "", // 담당자 이름
    tel: "", // 담당자 휴대폰번호
    address: "", // 주소
    addressDetail: "", // 상세 주소
    email: "", // 이메일 주소
    status: 0, // 상태 변경(활성여부) : 입력칸은 없으나 default 값으로 back-end에 넘겨줘야 함
  });
  const [open, setOpen] = useState(false); // 주소찾기 팝업 오픈 여부
  const phoneRef = useRef(); // 휴대폰 번호 입력칸 ref

  // input 값 변경
  const change = (e) => {
    const { name, value } = e.target;
    // 휴대폰 번호 - 자동입력
    if (name === "tel") {
      autoInputPhone(
        hospital.tel,
        "tel",
        value,
        setHospital,
        hospital,
        phoneRef
      );
    }
    // 휴대폰 번호 제외한 변수들
    else setHospital({ ...hospital, [name]: value });
  };

  // 제줄 전에 제출 형식에 맞는 지 확인
  const checkForSave = () => {
    let msg = ""; // 형식 오류 시 보여줄 메세지

    // 정규 표현식
    const regPhone = /^01([0|1|6|7|8|9])-([0-9]{4})-([0-9]{4})$/;
    const regEmail =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    // 누락된 칸 체크
    if (
      hospital.name.trim() === "" ||
      hospital.address.trim() === "" ||
      hospital.email.trim() === "" ||
      hospital.employeeName.trim() === "" ||
      hospital.tel.trim() === ""
    )
      msg = "누락된 칸이 있습니다.";
    // 휴대폰 번호 형식 체크
    else if (!regPhone.test(hospital.tel))
      msg = "휴대폰 번호 형식이 알맞지 않습니다.";
    // 이메일 체크
    else if (!regEmail.test(hospital.email))
      msg = "이메일 형식이 알맞지 않습니다.";

    // 모두 통과
    if (msg === "") return true;
    // 형식 오류 있을 때
    else {
      toast(
        <p>{msg}</p>,
        toastCommonProps(1500, "top-left", "toast_common toast__normal")
      );
      return false;
    }
  };

  // 저장하기
  const save = () => {
    // toast창 위치 설정
    setToastElPosition({
      top: findToastPosition("top"),
      left: findToastPosition("left"),
    });

    // 데이타 제출 전에 형식에 맞는지 체크
    if (checkForSave()) {
      // Axios
      async function fetchData() {
        setLoading(true);
        const res = await api({
          method: "post",
          url: commonUrl.baseURL + "belong",
          data: {
            ...hospital,
            name: hospital.name.trim(), // 검색 위해
          },
        }).catch((error) => {
          handlingError(error, "top-left", "toast_common toast__normal");
        });
        console.log(res.data, ">>> Axios belong");

        // Axios admin 성공
        if (res.data.ok) {
          toast(
            <p>저장 되었습니다.</p>,
            toastCommonProps(1500, "top-left", "toast_common toast__normal")
          );
          const response = await api
            .get(commonUrl.baseURL + "belong")
            .catch((error) => {
              handlingError(
                error,
                "top-center",
                "toast_common toast__login rounded-[48px]"
              );
            });
          // belong 데이터 axios 성공시
          if (response.data.ok) {
            console.log(response.data, ">>> belong");
            setBelongs(response.data.belongList);
          }
          setTimeout(() => {
            setLoading(false); // 더 일찍 하면 두번 저장될 수 있음

            navigate("/admin/main/hospital/", {
              replace: true,
            });
          }, 2000);
        }
        // Axios admin 실패
        else {
          toast(
            <p className="text-sm">{res.data.error}</p>,
            toastCommonProps(1500, "top-left", "toast_common toast__normal")
          );
        }
      }

      fetchData();
    }
  };

  return (
    <Header2
      title={[
        { title: "병원 등록/관리", url: -1 },
        { title: "병원 등록", url: 0 },
      ]}
    >
      <style jsx="true">{`
        .Toastify__toast {
          top: ${toastElPosition.top - 20}px;
          left: ${toastElPosition.left - 285}px;
        }
      `}</style>
      <ToastContainer />
      {/* 주소 검색 창 팝업*/}
      <Popup
        open={open}
        closeOnDocumentClick
        onClose={() => {
          setOpen(false);
        }}
      >
        <Postcode
          place={hospital}
          setPlace={setHospital}
          onClose={() => {
            setOpen(false);
          }}
        />
      </Popup>
      {/* 병원등록 페이지 */}
      <div className="admin flex flex-col grow p-[32px]">
        <div className="h-[80px] min-h-[80px]"></div>
        <label className="text-2xl font-extrabold leading-8 text-[#020A1B] mb-3">
          병원 등록
        </label>
        <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] min-w-[975px]">
          {/* 내용 입력 창 */}
          <div className="grid grid-cols-[15%,30%,15%,40%] border border-[#E5E6E7]">
            <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
              병원 명
            </label>
            <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
              <input
                type="text"
                className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-base"
                name="name"
                onChange={change}
                placeholder="병원명을 입력해 주세요."
              />
            </div>
            <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border row-span-2">
              병원 주소
            </label>
            <div className="flex flex-col gap-y-[6px] p-[4px] w-full border border-[#E5E6E7] font-normal box-border row-span-2">
              <div className="flex gap-x-1">
                <input
                  type="text"
                  className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-base"
                  name="address"
                  onChange={change}
                  value={hospital.address}
                  placeholder="주소를 입력해 주세요."
                />
                <BtnBlack
                  context="주소찾기"
                  style={{
                    minHeight: "35px",
                    width: "75px",
                    minWidth: "75px",
                    padding: "0 5px",
                  }}
                  onClick={() => {
                    setOpen(true);
                  }}
                />
              </div>
              <input
                type="text"
                className="flex mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-base"
                name="addressDetail"
                onChange={change}
                placeholder="상세주소를 입력해주세요."
              />
            </div>
            <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
              담당자 이름
            </label>
            <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
              <input
                type="text"
                className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-base"
                name="employeeName"
                onChange={change}
                placeholder="이름을 입력해 주세요."
              />
            </div>
            <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
              담당자 휴대폰번호
            </label>
            <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
              <input
                type="text"
                className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-base"
                name="tel"
                onChange={change}
                placeholder="예) 010-1234-5678"
                ref={phoneRef}
              />
            </div>
            <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
              이메일 주소
            </label>
            <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
              <input
                type="text"
                className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-base"
                name="email"
                onChange={change}
                placeholder="이메일 주소를 입력해 주세요."
              />
            </div>
          </div>
          {/* 목록 & 저장하기 버튼 */}
          <div className="flex justify-end mt-[20px]">
            <div className="flex justify-between gap-x-3">
              <BtnWhite
                context="목록"
                onClick={() => {
                  navigate(-1);
                }}
                style={{ width: "90px" }}
                standard="toast_standard"
              />
              <BtnBlack
                context="저장하기"
                onClick={save}
                loading={loading}
                style={{ width: "88px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </Header2>
  );
}

export default Register;
