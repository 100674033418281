import Popup from "reactjs-popup";
import { useEffect, useRef, useState } from "react";
import { servicePolicy, personalInfoPolicy } from "./policy";

const ExplainPolicy = ({ policyOpen, setPolicyOpen, clickPolicy }) => {
  const cancel = "/images/admin/cancel.svg";
  const [clickedBtn, setClickedBtn] = useState(clickPolicy); // 선택된 버튼 무엇인지("service"||"personalInfo")
  const serviceRef = useRef(); // 서비스 이용약관 버튼 ref
  const personalInfoRef = useRef(); // 개인정보처리방침 버튼 ref

  // 로그인 페이지에서 클릭하는 버튼(=click) 바뀔 시 버튼 상태 변경
  useEffect(() => {
    setClickedBtn(clickPolicy);
  }, [clickPolicy]);

  // 스크롤 맨 위로 끌어올리기
  useEffect(() => {
    if (serviceRef.current || personalInfoRef.current) {
      if (clickedBtn === "service") {
        serviceRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } else {
        personalInfoRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [clickedBtn]);

  return (
    <>
      <style jsx="true">{`
        .popup-overlay.policy_popup-overlay {
          position: absolute !important; // 원래 fixed인데 스크롤이 안되서 absol로 바꿈
          overflow: auto;
          min-width: 870px !important;
          min-height: 100vh; // scroll이 생겨도 밑에 빈공간이 생기지 않음
        }
        .popup-content.policy_popup-content {
          font-family: Pretendard !important;
          width: 700px !important;
          min-width: 700px !important;
          box-sizing: border-box;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          background: #fafbfc;
          padding: 0px;
          border: none;
        }
      `}</style>
      <Popup
        className="policy_popup"
        open={policyOpen}
        closeOnDocumentClick={false}
        onClose={() => {
          setPolicyOpen(false);
        }}
      >
        <div className="flex flex-col w-[700px] h-[700px] max-w-[700px] max-h-[700px] bg-[#FAFBFC] p-4">
          {/* 이용약관 선택 & 닫기 */}
          <div className="flex items-start justify-between">
            {/* 이용약관 선택 */}
            <div className="flex gap-x-4">
              <button
                className="flex justify-center items-center p-1 border-b-[1px] font-normal leading-[22px] text-sm focus:outline-none"
                style={{
                  color: clickedBtn === "service" ? "#020A1B" : "#676C76",
                  borderBottomColor:
                    clickedBtn === "service" ? "#020A1B" : "#999CA3",
                }}
                onClick={() => {
                  setClickedBtn("service");
                }}
              >
                서비스 이용약관
              </button>
              <button
                className="flex justify-center items-center p-1 border-b-[1px] font-normal leading-[22px] text-sm focus:outline-none"
                style={{
                  color: clickedBtn === "personalInfo" ? "#020A1B" : "#676C76",
                  borderBottomColor:
                    clickedBtn === "personalInfo" ? "#020A1B" : "#999CA3",
                }}
                onClick={() => {
                  setClickedBtn("personalInfo");
                }}
              >
                개인정보처리방침
              </button>
            </div>
            {/* 닫기 */}
            <img
              className="w-[20px] cursor-pointer"
              src={cancel}
              alt="cancel"
              onClick={() => {
                setPolicyOpen(false);
              }}
            />
          </div>
          {/* 약관내용 */}
          <div className="p-3 mt-2 text-sm font-normal h-[615px] overflow-auto">
            {clickedBtn === "service" ? (
              <div ref={serviceRef}>
                <br />
                <p className="text-sm text-[#4E535F] font-semibold">
                  서비스 이용약관
                </p>
                <br />
                {servicePolicy.map((el, idx) => {
                  return el.map((el2, idx2) => {
                    return (
                      <div key={idx * 10 + idx2}>
                        <p
                          className="text-sm text-[#4E535F]"
                          style={{
                            fontWeight: idx2 === 0 ? "600" : "normal",
                          }}
                        >
                          {el2}
                        </p>
                        <br />
                      </div>
                    );
                  });
                })}
              </div>
            ) : (
              <div ref={personalInfoRef}>
                <br />
                {personalInfoPolicy.map((el, idx) => {
                  return el.map((el2, idx2) => {
                    return (
                      <div key={idx * 10 + idx2}>
                        <p
                          className="text-sm text-[#4E535F]"
                          style={{
                            fontWeight: idx2 === 0 ? "600" : "normal",
                          }}
                        >
                          {el2}
                        </p>
                        {idx === 1 && idx2 === 8 ? <PersonlInfoTable1 /> : null}
                        {idx === 2 && idx2 === 3 ? <PersonlInfoTable2 /> : null}
                        {idx === 2 && idx2 === 4 ? <PersonlInfoTable3 /> : null}
                        {idx === 3 && idx2 === 5 ? <PersonlInfoTable4 /> : null}
                        {idx === 4 && idx2 === 1 ? <PersonlInfoTable5 /> : null}
                        {idx === 4 && idx2 === 2 ? <PersonlInfoTable6 /> : null}
                        <br />
                      </div>
                    );
                  });
                })}
              </div>
            )}
          </div>
        </div>
      </Popup>
    </>
  );
};

const PersonlInfoTable1 = () => {
  return (
    <div className="border text-center break-all text-sm text-[#4E535F]">
      <div className="flex border-b">
        <div className="w-1/5 border-r">구분</div>
        <div className="w-3/5 border-r">처리항목</div>
        <div className="w-2/5">처리목적</div>
      </div>
      <div className="flex border-b ">
        <div className="flex flex-col w-1/5 border-r">
          <div className="border-b h-[60px] flex items-center ">
            이메일로 가입(개인)
          </div>
          <div className="border-b h-[60px] flex items-center">
            카카오 계정으로 가입(개인)
          </div>
          <div className="border-b h-[60px] flex items-center">
            페이스북 계정으로 가입(개인)
          </div>
          <div className="border-b h-[60px] flex items-center">
            구글 계정으로 가입(개인)
          </div>
          <div className="h-[60px] flex items-center">
            애플 계정으로 가입(개인)
          </div>
        </div>
        <div className="w-3/5 border-r">
          <div className="border-b h-[60px] flex items-center justify-center">
            (필수)이름, 이메일, 비밀번호
          </div>
          <div className="border-b h-[60px] flex items-center justify-center">
            (필수)카카오 연동 ID, 페이스북 토큰, 성명, 프로필 사진
          </div>
          <div className="border-b h-[60px] flex items-center justify-center">
            (필수)카카오 연동 ID, 페이스북 토큰, 성명, 프로필 사진
          </div>
          <div className="border-b h-[60px] flex items-center justify-center">
            (필수)구글 연동ID, 구글 토큰
          </div>
          <div className=" h-[60px] flex items-center justify-center">
            (필수) 성명, 이메일, 애플 연동ID
          </div>
        </div>
        <div className="flex items-center justify-center w-2/5">
          회원 가입의사 확인, 식별∙인증, 회원자격 유지∙관리, 본인확인, 서비스
          부정이용 확인 및 방지, 각종 고지∙통지, 고충처리, 서비스 제공 및 상담,
          만 14세 이상 확인, 만족도 조사
        </div>
      </div>
      <div className="flex border-b ">
        <div className="flex items-center justify-center w-1/5 border-r">
          회원가입(병원회원)
        </div>
        <div className="flex items-center justify-center w-3/5 border-r">
          성명, 휴대전화번호, 대표전화번호, 이메일, 비밀번호, 의료인
          정보(병원명, 병원 전화번호, 사업자등록번호, 요양기관번호, 주소,
          대표자명)
        </div>
        <div className="flex items-center justify-center w-2/5">
          회원 가입의사 확인, 식별∙인증, 회원자격 유지∙관리, 본인확인, 서비스
          부정이용 확인 및 방지, 각종 고지∙통지, 고충처리, 서비스 제공 및 상담,
          만 14세 이상 확인, 만족도 조사
        </div>
      </div>
      <div className="flex border-b ">
        <div className="flex items-center justify-center w-1/5 border-r">
          비대면 진료 및 건강관리 서비스
        </div>
        <div className="flex items-center justify-center w-3/5 border-r">
          이름, 주민등록번호, 휴대폰번호, 자녀이름, 자녀 주민등록번호, 사전문진
          정보, 증상사진 등 참고자료, 내외국인 여부, 개인위치정보(경도, 위도),
          처방전 등 진료 후 정보
        </div>
        <div className="flex items-center justify-center w-2/5">
          비대면진료, 건강상담, 건강관리 등 제반 서비스 이용 시 본인 인증 및
          의료인/의료기관에 정보 제공
        </div>
      </div>
      <div className="flex border-b ">
        <div className="flex items-center justify-center w-1/5 border-r">
          나의 건강정보
        </div>
        <div className="flex items-center justify-center w-3/5 border-r">
          생년월일, 성별, 사전문진정보
        </div>
        <div className="flex items-center justify-center w-2/5">
          비대면진료, 건강상담, 건강관리 등 제반 서비스 이용 시 본인 인증 및
          의료인/의료기관에 정보 제공
        </div>
      </div>
      <div className="flex border-b ">
        <div className="flex items-center justify-center w-1/5 border-r">
          서비스 이용 시 자동 생성되는 정보
        </div>
        <div className="flex items-center justify-center w-3/5 border-r">
          서비스 이용 기록, 접속 로그, 쿠키, 접속IP 주소, 기기고유번호(등록번호,
          디바이스 아이디 또는 IMEI), 불량 이용 기록, 광고식별자, 사용
          이동통신사
        </div>
        <div className="flex items-center justify-center w-2/5">
          서비스/상품 이용 정보 통계 및 분석
        </div>
      </div>
      <div className="flex border-b ">
        <div className="flex items-center justify-center w-1/5 border-r">
          병원/약국 찾기 서비스 이용 시
        </div>
        <div className="flex items-center justify-center w-3/5 border-r">
          개인위치정보(경도, 위도)
        </div>
        <div className="flex items-center justify-center w-2/5">
          병원찾기 서비스 제공
        </div>
      </div>
      <div className="flex border-b ">
        <div className="flex items-center justify-center w-1/5 border-r">
          이벤트 응모 및 상담신청
        </div>
        <div className="flex items-center justify-center w-3/5 border-r">
          (필수) 이름, 휴대전화번호 (선택) 성별, 생년월일
        </div>
        <div className="flex items-center justify-center w-2/5">
          이벤트 및 혜택 알림 정보 등 마케팅 ∙ 광고 활용, 서비스/신상품이나
          이벤트 정보 안내, 이벤트 경품 배송
        </div>
      </div>
      <div className="flex border-b ">
        <div className="flex items-center justify-center w-1/5 border-r">
          재외국민 비대면진료 서비스
        </div>
        <div className="flex items-center justify-center w-3/5 border-r">
          여권번호, 휴대폰번호
        </div>
        <div className="flex items-center justify-center w-2/5">
          재외국민 본인 인증
        </div>
      </div>
    </div>
  );
};
const PersonlInfoTable2 = () => {
  return (
    <div className="border text-center text-sm text-[#4E535F]">
      <div className="flex border-b">
        <div className="w-1/3 border-r">구분</div>
        <div className="w-1/3 border-r">항목</div>
        <div className="w-1/3 border-r">기간</div>
      </div>
      <div className="flex">
        <div className="flex items-center justify-center w-1/3 border-r">
          회사 내부 정책 (부정거래의 배제 등 회사 방침에 의한 보존)
        </div>
        <div className="flex items-center justify-center w-1/3 border-r">
          아이디, DI (Duplication Information)
        </div>
        <div className="flex items-center justify-center w-1/3 border-r">
          회원탈퇴 후 1년
        </div>
      </div>
    </div>
  );
};
const PersonlInfoTable3 = () => {
  return (
    <div className="border text-center text-sm text-[#4E535F]">
      <div className="flex border-b">
        <div className="w-1/3 border-r">근거법령</div>
        <div className="w-1/3 border-r">보존항목</div>
        <div className="w-1/3 border-r">보존기간</div>
      </div>
      <div className="flex border-b">
        <div className="flex items-center justify-center w-1/3 border-r">
          신용정보의 이용 및 보호에 관한 법률
        </div>
        <div className="flex items-center justify-center w-1/3 border-r">
          신용정보의 수집/처리 및 이용 등에 관한 기록
        </div>
        <div className="flex items-center justify-center w-1/3 border-r">
          3년
        </div>
      </div>
      <div className="flex">
        <div className="flex items-center justify-center w-1/3 border-r">
          통신비밀보호법
        </div>
        <div className="flex items-center justify-center w-1/3 border-r">
          서비스 방문 기록
        </div>
        <div className="flex items-center justify-center w-1/3 border-r">
          3개월
        </div>
      </div>
    </div>
  );
};
const PersonlInfoTable4 = () => {
  return (
    <div className="border text-center mt-[8px] text-sm text-[#4E535F]">
      <div className="flex border-b">
        <div className="w-1/3 border-r">제공 받는자</div>
        <div className="w-1/3 border-r">제공 목적</div>
        <div className="w-1/3 border-r">제공 정보</div>
      </div>
      <div className="flex border-b">
        <div className="flex items-center justify-center w-1/3 border-r">
          병원회원
        </div>
        <div className="flex items-center justify-center w-1/3 border-r">
          비대면 진료 및 건강관리 서비스 제공
        </div>
        <div className="flex items-center justify-center w-1/3 border-r">
          이름, 주민등록번호, 휴대폰번호, 자녀이름, 자녀 주민등록번호, 사전문진
          정보, 증상사진 등 참고자료, 내외국인 여부, 개인위치정보(경도, 위도),
          처방전 등 진료 후 정보
        </div>
      </div>
    </div>
  );
};
const PersonlInfoTable5 = () => {
  return (
    <div className="border text-center mt-[8px] text-sm text-[#4E535F]">
      <div className="flex border-b">
        <div className="w-1/4 border-r">수탁업체</div>
        <div className="w-1/4 border-r">위탁업무</div>
        <div className="w-1/4 border-r">위탁정보</div>
        <div className="w-1/4 border-r">보유기간</div>
      </div>
      <div className="flex border-b">
        <div className="flex items-center justify-center w-1/4 border-r">
          헤링스
        </div>
        <div className="flex items-center justify-center w-1/4 border-r">
          휴대폰 본인 확인
        </div>
        <div className="flex items-center justify-center w-1/4 border-r">
          이름, 생년월일, 통신사, 성별, 휴대폰번호
        </div>
        <div className="flex items-center justify-center w-1/4 border-r">
          이용 목적 달성 시까지
        </div>
      </div>
    </div>
  );
};
const PersonlInfoTable6 = () => {
  return (
    <div className="border text-center mt-[8px] text-sm text-[#4E535F]">
      <div className="flex border-b">
        <div className="flex items-center justify-center w-1/5 border-r">
          수탁업체
        </div>
        <div className="flex items-center justify-center w-1/5 border-r">
          위탁업무
        </div>
        <div className="flex items-center justify-center w-1/5 border-r">
          이전국가
        </div>
        <div className="flex items-center justify-center w-1/5 border-r">
          이전 일시 및 방법
        </div>
        <div className="flex items-center justify-center w-1/5 border-r">
          보유기간
        </div>
      </div>
      <div className="flex border-b">
        <div className="flex items-center justify-center w-1/5 border-r">
          AWS
        </div>
        <div className="flex items-center justify-center w-1/5 border-r">
          서비스 이용 기록 또는 수집된 개인정보
        </div>
        <div className="flex items-center justify-center w-1/5 border-r">
          한국
        </div>
        <div className="flex items-center justify-center w-1/5 border-r">
          서비스 이용 시점에 네트워크를 통한 전송
        </div>
        <div className="flex items-center justify-center w-1/5 border-r">
          클라우드 서비스 이용 변경 시까지
        </div>
      </div>
    </div>
  );
};

export default ExplainPolicy;
