import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMainContext } from "../../../../contexts/Provider";

/*component*/
import Close from "../../mobilecomponent/Close";
const Check = () => {
  const navigate = useNavigate();

  const jangruboy = "/images/mo/jangruboy.svg";
  const { setFileArray, setFiles, setSelectedImages, loginInput } =
    useMainContext();

  useEffect(() => {
    let pageOpen = false;
    if (!pageOpen) {
      if (
        loginInput.id === null ||
        loginInput.id === undefined ||
        loginInput.id === ""
      ) {
        navigate("/main", { replace: true });
      }
    }
    return () => {
      pageOpen = true;
    };
  }, [setFileArray, setFiles, setSelectedImages, loginInput.id, navigate]);
  const goCheck1 = () => {
    navigate("/check/1");
  };
  return (
    <div className="h-screen bg-[#FAFCFF] px-[24px] relative overflow-y-auto body_1_400">
      <Close />
      <div className=" pt-[72px]">
        <div className="w-full flex justify-center">
          <img src={jangruboy} alt="jangruboy" className="w-[72.27px]" />
        </div>
        <span className="block pt-[32px]">안녕하세요.</span>
        <span className="block">
          장루 점검은
          <span className="font-semibold"> 매월 2회 </span>
          정기적으로 진행됩니다.
        </span>
        <span className="block pt-6">
          장루점검 목적은
          <br />
          장루 관리 상태를 정기적으로 확인 및 점검하여, 보다 편안한 삶을
          영위하실 수 있도록 돕기 위함입니다.
        </span>
        <span className="block pt-6">
          현재 상태를 자세히 기록해주시면 장루 간호사의 안내를 받으실 수
          있습니다.
        </span>
        <span className="block pt-6">
          점검할 준비가 되셨다면
          <br />
          시작하기 버튼을 선택해 주세요.
        </span>
      </div>

      <button
        className="mt-[56px] px-[24px] w-full bg-[#1B5EDC] py-[20px] text-white button_700 active:bg-[#18428E] ease-in-out duration-300  cursor-pointer"
        onClick={goCheck1}
      >
        시작하기
      </button>
    </div>
  );
};

export default Check;
