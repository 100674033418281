import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import "tailwindcss/tailwind.css";
import Layout from "./common/Layout";
import "./style/App.css";
/*mobile*/
import Login from "./screen/mobile/pages/Login";
import Main from "./screen/mobile/pages/Main";
import Tutorial from "./screen/mobile/pages/Main/Tutorial";
import MessagePage from "./screen/mobile/pages/Main/MessagePage";
import EachMessagePage from "./screen/mobile/pages/Main/EachMessagePage";
import Check from "./screen/mobile/pages/Check";
import CheckId from "./screen/mobile/pages/Check/[id]";
import Myinfo from "./screen/mobile/pages/MyInfo";
import DataEdit from "./screen/mobile/pages/MyInfo/DataEdit";
import HealthDiary from "./screen/mobile/pages/HealthDiary";
import Report from "./screen/mobile/pages/Report";
import GraphFull from "./screen/mobile/pages/Report/GraphFull";
import Community from "./screen/mobile/pages/Community";
import Post from "./screen/mobile/pages/Community/Post";
import Write from "./screen/mobile/pages/Community/Write";
import CommunityNotice from "./screen/mobile/pages/Community/CommunityNotice";
import More from "./screen/mobile/pages/More";
import SubPage from "./screen/mobile/pages/More/SubPage";
import SubPageList from "./screen/mobile/pages/More/SubPageList";
import Inspection from "./screen/mobile/pages/Report/Inspection";
import Diary from "./screen/mobile/pages/Report/Diary";
import Notice from "./screen/mobile/pages/MyInfo/Notice";
import Appuse from "./screen/mobile/pages/MyInfo/AppUse";
import AppUseWrite from "./screen/mobile/pages/MyInfo/AppUseWrite";
import AppUseContent from "./screen/mobile/pages/MyInfo/AppUseContent";
import EachNews from "./screen/mobile/pages/MyInfo/EachNews";
import AllJangru from "./screen/mobile/pages/AllJangru";
/*admin*/
import ALogin from "./screen/admin/pages/Login/Login";
import AFindId from "./screen/admin/pages/Login/pages/FindId";
import AFindPwd from "./screen/admin/pages/Login/pages/FindPwd";
import AMyInfo from "./screen/admin/pages/MyInfo/MyInfo";
import ADashboard from "./screen/admin/pages/Dashboard/Dashboard";
import ADashboardMore from "./screen/admin/pages/Dashboard/pages/More";
import AHospital from "./screen/admin/pages/Hospital/Hospital";
import AHospitalRegister from "./screen/admin/pages/Hospital/pages/Register";
import AHospitalModify from "./screen/admin/pages/Hospital/pages/Modify";
import AManager from "./screen/admin/pages/Manager/Manager";
import AManagerRegister from "./screen/admin/pages/Manager/pages/Register";
import AManagerModify from "./screen/admin/pages/Manager/pages/Modify";
import AClient from "./screen/admin/pages/Client/Client";
import AClientRegister from "./screen/admin/pages/Client/pages/Register";
import AClientModify from "./screen/admin/pages/Client/pages/Modify";
import ACheckAllList from "./screen/admin/pages/Check/AllList";
import ACheckDetail from "./screen/admin/pages/Check/Detail";
import ACheckJangruCheck from "./screen/admin/pages/Check/pages/JangruCheck";
import ACheckReply from "./screen/admin/pages/Check/pages/Reply";
import ACheckHealthDiary from "./screen/admin/pages/Check/pages/HealthDiary";
import ACheckGraphAndPicture from "./screen/admin/pages/Check/pages/GraphAndPicture";
import AContents from "./screen/admin/pages/Contents/Contents";
import ACommunity from "./screen/admin/pages/Community";
import ACommunityId from "./screen/admin/pages/Community/CommunityId";
import AInquiry from "./screen/admin/pages/Inquiry/index";
import AInquiryId from "./screen/admin/pages/Inquiry/InquiryId";
import ANews from "./screen/admin/pages/News";
import ANewsId from "./screen/admin/pages/News/NewsId";
import ANewsWrite from "./screen/admin/pages/News/Write";
import AMessage from "./screen/admin/pages/Message/index";
import AMessageId from "./screen/admin/pages/Message/MessageId";
import AMessageWrite from "./screen/admin/pages/Message/Write";
import AService from "./screen/admin/pages/Service/Service";
import AServiceDetail from "./screen/admin/pages/Service/pages/Detail";
import AServiceRegister from "./screen/admin/pages/Login/pages/ServiceRegister";
import APDF from "./screen/admin/admin_components/PDF";
import AAgree from "./screen/admin/pages/Login/pages/Agree";
import { useMainContext } from "./contexts/Provider";
import AutoLogin from "./screen/mobile/pages/Login/AutoLogin";
import Landing from "./screen/Landing";
import AppDownLoad from "./screen/AppDownLoad";

function App() {
  if (process.env.NODE_ENV === "production") {
    console = window.console || {};
    console.log = function no_console() {};
    console.warn = function no_console() {};
    console.error = function () {};
  }

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/admin" element={<ALogin />} />
          <Route path="/admin/findId" element={<AFindId />} />
          <Route path="/admin/findPwd" element={<AFindPwd />} />
          <Route path="/admin/serviceRegister" element={<AServiceRegister />} />
          <Route path="/admin/agree" element={<AAgree />} />

          <Route path="admin/main/myinfo" element={<AMyInfo />} />
          <Route path="/admin/main/dashboard" element={<ADashboard />} />
          <Route
            path="/admin/main/dashboard/more"
            element={<ADashboardMore />}
          />
          <Route path="/admin/main/hospital" element={<AHospital />} />
          <Route
            path="/admin/main/hospital/register"
            element={<AHospitalRegister />}
          />
          <Route
            path="/admin/main/hospital/modify"
            element={<AHospitalModify />}
          />
          <Route path="/admin/main/manager" element={<AManager />} />
          <Route
            path="/admin/main/manager/register"
            element={<AManagerRegister />}
          />
          <Route
            path="/admin/main/manager/modify"
            element={<AManagerModify />}
          />
          <Route path="/admin/main/client" element={<AClient />} />
          <Route
            path="/admin/main/client/register"
            element={<AClientRegister />}
          />
          <Route path="/admin/main/client/modify" element={<AClientModify />} />
          <Route path="/admin/main/check" element={<ACheckAllList />} />
          <Route path="/admin/main/check/detail" element={<ACheckDetail />} />
          <Route
            path={"/admin/main/check/jangrucheck"}
            element={<ACheckJangruCheck />}
          />
          <Route path={"/admin/main/check/reply"} element={<ACheckReply />} />
          <Route
            path={"/admin/main/check/healthdiary"}
            element={<ACheckHealthDiary />}
          />
          <Route
            path={"/admin/main/check/graphandpicture"}
            element={<ACheckGraphAndPicture />}
          />
          <Route
            path="/admin/main/pdf/:surveyId/:checkId/:replyId"
            element={<APDF />}
          />
          <Route path="/admin/main/contents" element={<AContents />} />
          <Route path="/admin/main/community" element={<ACommunity />} />
          <Route path="/admin/main/communityid" element={<ACommunityId />} />
          <Route path="/admin/main/inquiry" element={<AInquiry />} />
          <Route path="/admin/main/news" element={<ANews />} />
          <Route path="/admin/main/newsid" element={<ANewsId />} />
          <Route path="/admin/main/newswrite" element={<ANewsWrite />} />
          <Route path="/admin/main/message" element={<AMessage />} />
          <Route path="/admin/main/messageId" element={<AMessageId />} />
          <Route path="/admin/main/messagewrite" element={<AMessageWrite />} />
          <Route path="/admin/main/inquiryid" element={<AInquiryId />} />
          <Route path="/admin/main/service" element={<AService />} />
          <Route
            path="/admin/main/service/detail"
            element={<AServiceDetail />}
          />

          {/* mobile */}
          <Route path="/app" element={<AutoLogin />} />
          <Route path="/login" element={<Login />} />
          <Route path="/main" element={<Main />} />
          <Route path="/main/tutorial" element={<Tutorial />} />
          <Route path="/main/message" element={<MessagePage />} />
          <Route path="/main/eachmessage" element={<EachMessagePage />} />
          <Route path="/check" element={<Check />} />
          <Route path="/check/:id" element={<CheckId />} />
          <Route path="/myinfo" element={<Myinfo />} />
          <Route path="/myinfo/dataedit" element={<DataEdit />} />
          <Route path="/myinfo/notice" element={<Notice />} />
          <Route path="/myinfo/appuse" element={<Appuse />} />
          <Route path="/myinfo/appusewrite" element={<AppUseWrite />} />
          <Route path="/myinfo/appusecont" element={<AppUseContent />} />
          <Route path="/myinfo/eachnews" element={<EachNews />} />
          <Route path="/alljangru" element={<AllJangru />} />
          <Route path="/healthdiary" element={<HealthDiary />} />
          <Route path="/report" element={<Report />} />
          <Route path="/report/inspection" element={<Inspection />} />
          <Route path="/report/diary" element={<Diary />} />
          <Route path="/report/graphfull" element={<GraphFull />} />
          <Route path="/community" element={<Community />} />
          <Route path="/community/post" element={<Post />} />
          <Route path="/community/write" element={<Write />} />
          <Route path="/community/notice" element={<CommunityNotice />} />
          <Route path="/more" element={<More />} />
          <Route path="/more/sub" element={<SubPage />} />
          <Route path="/more/sub/list" element={<SubPageList />} />
          <Route path="/appdownload" element={<AppDownLoad />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
