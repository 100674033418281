import React from "react";
import { useParams } from "react-router-dom";
const LoadingBar = () => {
  const paramId = useParams().id;
  console.log(paramId);
  return (
    <>
      <div
        className={
          Number(paramId) === 10 || Number(paramId) === 11
            ? ""
            : "h-[6px] fixed top-[48px] left-0 z-2 bg-[#E5E6E7] w-full"
        }
      ></div>

      {Number(paramId) === 10 || Number(paramId) === 11 ? (
        ""
      ) : (
        <div
          className={
            paramId
              ? `w-${paramId}-9 h-[6px] fixed top-[48px] left-0 z-2 bg-[#679DEE]`
              : ""
          }
        ></div>
      )}
    </>
  );
};

export default LoadingBar;
