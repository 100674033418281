import { toast } from "react-toastify";
import { toastCommonProps } from "./Toast";

const handlingError = (error, position, className) => {
  // position과 className이 사용자 지정으로 들어온 경우에만 해당 위치에 뜨고
  // undefined로 넘어온 경우에는 오른 쪽 상단에 뜸
  if (error.response) {
    // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
    toast(
      <p>{error.response.status}에러 - 서버에서 정보를 찾을 수 없습니다.</p>,
      toastCommonProps(
        2000,
        position ? position : "top-right",
        className ? className : "toast_common !top-[80px] !left-0"
      )
    );
    // console.log(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);
  } else if (error.request) {
    // 요청이 이루어 졌으나 응답을 받지 못했습니다.
    // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
    // Node.js의 http.ClientRequest 인스턴스입니다.
    toast(
      <p>에러 - 서버로부터 응답을 받지 못했습니다.</p>,
      toastCommonProps(
        2000,
        position ? position : "top-right",
        className ? className : "toast_common !top-[80px] !left-0"
      )
    );
  } else {
    toast(
      <p>에러 - 요청을 설정하는 중 문제가 발생하였습니다.</p>,
      toastCommonProps(
        2000,
        position ? position : "top-right",
        className ? className : "toast_common !top-[80px] !left-0"
      )
    );
    // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
    // console.log("Error", error.message);
  }
  // console.log(error.config);
};

export { handlingError };
