import { useState } from "react";
import { useNavigate } from "react-router-dom";
import commonUrl, { api } from "../../../../../data/common";
import { useMainContext } from "../../../../../contexts/Provider";
import { toast } from "react-toastify";
import {
  toastCommonProps,
  findToastPosition,
} from "../../../admin_components/Toast";
import { LoadingBlue } from "../../../admin_components/Loading";
import { handlingError } from "../../../admin_components/Error";

function UserAuth({ setShowSetNewPwd, setToastPosition }) {
  const navigate = useNavigate();
  const { adminLogin, setAdminLogin } = useMainContext();
  const [loading, setLoading] = useState(false);
  // 비밀번호 찾기 입력
  const [findPwd, setFindPwd] = useState({
    name: "",
    email: "",
  });

  // 확인 버튼 클릭 대신 엔터키 눌렀을 경우
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      submit();
    }
  };

  // input 값 변경
  const change = (e) => {
    const { name, value } = e.target;
    setFindPwd({ ...findPwd, [name]: value });
  };

  // 확인버튼 누를 시
  const submit = () => {
    setToastPosition(findToastPosition("top")); // 토스트창 위치 설정

    // axios
    async function fetchData() {
      setLoading(true);
      const res = await api({
        method: "post",
        url: commonUrl.admin + "findPwd",
        data: { name: findPwd.name.trim(), email: findPwd.email.trim() },
      }).catch((error) => {
        handlingError(error, "top-center", "toast_common toast__login");
      });
      console.log(res.data, ">>> Axios admin/findPwd");
      setLoading(false);
      // axios 성공 시
      if (res.data.ok) {
        setAdminLogin({
          ...adminLogin,
          name: findPwd.name,
          email: findPwd.email,
        });
        setTimeout(() => {
          setShowSetNewPwd(true);
        }, [300]);
      }
      // axios 실패 시
      else {
        setFindPwd({ name: "", email: "" });
        toast(
          <p>일치하는 정보가 없습니다.</p>,
          toastCommonProps(1500, "top-center", "toast_common toast__login")
        );
      }
    }
    fetchData();
  };

  return (
    <>
      <div className="absolute w-[385px] h-[380px] inset-0 m-auto ">
        <h3 className="flex justify-center text-center font-bold text-xl mt-[15px] mb-[35px] text-[#1B5EDC]">
          비밀번호 찾기
        </h3>
        <p className="text-[15px] mb-[20px]">
          <span className="text-blue-600 font-bold">
            이름, 아이디(이메일 주소)
          </span>
          를 입력해 주세요.
        </p>
        {/* 이름 & 휴대폰번호 */}
        <div className="flex flex-between border-x-0 border-t-0 border-b">
          <label className="flex w-[90px] justify-start items-center font-bold">
            이름
          </label>
          <input
            className="w-[300px] p-[10px] focus:outline-none"
            type="text"
            name="name"
            value={findPwd.name}
            onChange={change}
            onKeyPress={onKeyPress}
            autoFocus
          />
        </div>
        <div className="flex flex-between border-x-0 border-t-0 border-b mt-[10px]">
          <label className="flex w-[90px] justify-start items-center font-bold">
            이메일 주소
          </label>
          <input
            className="w-[300px] p-[10px] focus:outline-none"
            type="text"
            name="email"
            value={findPwd.email}
            onChange={change}
            onKeyPress={onKeyPress}
          />
        </div>
        <button
          className="toast_standard flex justify-center items-center w-full h-[60px] bg-[#679dee] ease-in-out duration-300 rounded-sm text-xl text-white font-semibold mt-[45px] hover:bg-[#1B5EDC]"
          onClick={submit}
        >
          {loading ? <LoadingBlue /> : "확인"}
        </button>
        <button
          className="flex justify-center my-[20px] inset-x-0 mx-auto border-b leading-5 text-sm"
          onClick={() => {
            navigate(-1);
          }}
        >
          홈으로
        </button>
      </div>
    </>
  );
}

export default UserAuth;
