import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMainContext } from "../../../../contexts/Provider";
import common, { api } from "../../../../data/common";
import AlamEachList from "./components/AlamEachList";

const MessagePage = () => {
  const { loginInput, setAlarmList, alarmList } = useMainContext();
  const leftarrow = "/images/mo/leftarrow.svg";
  const downicon = "/images/mo/down_blue.svg";

  const [listnum, setListNum] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    let pageOpen = false;
    const getAlarm = () => {
      api.get(common.baseURL + "alarm/pid/" + loginInput.id).then((res) => {
        if (res.data.ok === true) {
          setAlarmList(res.data.alarmList);
        }
      });
    };
    if (!pageOpen) {
      if (
        loginInput.id === null ||
        loginInput.id === undefined ||
        loginInput.id === ""
      ) {
        navigate("/main", { replace: true });
      } else {
        getAlarm();
      }
    }
    return () => {
      pageOpen = true;
    };
  }, [loginInput.id, setAlarmList, navigate]);

  return (
    <div className="w-full h-screen fixed top-0 left-0 bg-[#FAFCFF] z-5 overflow-y-auto">
      <div className="flex flex-col max-w-md mx-auto antialiased text-gray-600">
        <div className="w-full border-b-[1px] flex justify-between items-center px-[8px] subtitle_1_400">
          <img
            className="inline-block cursor-pointer "
            src={leftarrow}
            alt="leftarrow"
            onClick={() => navigate(-1)}
          />
          <span className="inline-block">내 알림</span>
          <span className="inline-block w-[48px] h-[48px]"></span>
        </div>

        <div className=" px-[24px] cursor-pointer">
          {alarmList.length > 0
            ? alarmList
                .slice(0, listnum * 8)
                .map((el, index) => <AlamEachList el={el} key={index} />)
            : ""}
          {alarmList.length > 8 && alarmList.length > listnum * 8 ? (
            <div className="pt-[48px]">
              <div
                className="flex justify-center gap-x-[8px]"
                onClick={() => setListNum(listnum + 1)}
              >
                <span className="subtitle_1_600 text-[#1B5EDC]">
                  알림 더보기
                </span>
                <img src={downicon} alt="downicon" />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="h-[80px]"></div>
      </div>
    </div>
  );
};

export default MessagePage;
