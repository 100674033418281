import dayjs from "dayjs";
import ToggleBtnComment from "./ToggleBtnComment";
import React, { useState, useEffect } from "react";

import common, { api } from "../../../../../data/common";
import { ToastContainer } from "react-toastify";
import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";

const CommunityInfoComment = ({ commentcont, commudata, index }) => {
  const [reportList, setReportdata] = useState([]);
  const [open, setOpen] = useState(false); // popup open 여부
  const closeModal = () => setOpen(false); // popup 닫을 시 실행하는 함수
  const report_flag = commentcont.report.length > 0 ? true : false;
  const [sort, setSort] = useState(""); // 사진 구분
  const navigate = useNavigate();
  const [info_patient, setInfo_patient] = useState([]);
  const flag = commentcont.admin !== null ? true : false;

  let api_call_id = flag ? commentcont.admin.id : commentcont.patient.id;
  let patient_admin = flag
    ? "/admin/main/manager/modify"
    : "/admin/main/client/modify";
  let api_adress = flag ? "admin/Id/" : "patient/Id/";

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(
        common.baseURL + "community/comment/showreport/" + commentcont.id
      );
      if (response.data.ok) setReportdata(response.data.reportList);

      const patient_info = await api.get(
        common.baseURL + api_adress + api_call_id
      );
      setInfo_patient(
        flag ? patient_info.data.admin : patient_info.data.patient
      );
    }
    fetchData();
  }, []);

  const PopUp = ({ open, onClose }) => {
    const number_id = [1, 2, 3, 4, 5];
    return (
      <>
        {
          <style jsx="true">{`
            .popup-content.jangrucheck__popup-content {
              box-sizing: border-box;
              border-radius: 16px;
              width: 373px;
              height: auto;
              display: flex;
              flex-direction: column;
              padding: 0px;
              position: relative;
              background: #fafbfc;
              border: 2px solid black;
            }
          `}</style>
        }
        <ToastContainer className="admin" />
        <Popup
          key={number_id.toString()}
          open={open}
          closeOnDocumentClick
          onClose={onClose}
          className="jangrucheck__popup"
        >
          {/* icon */}
          <div className="flex">
            <span
              className={
                "material-icons absolute text-gray-500 cursor-pointer rounded-full text-3xl right-0 m-4"
              }
              onClick={onClose}
            >
              clear
            </span>
          </div>

          {
            // 신고 popup
            <>
              <div className="w-full h-full">
                <p className="font-bold text-base text-starttext-[#020A1B] mx-6 my-6 text-xl">
                  신고 내용
                </p>
                {reportList.map((e, idx) => (
                  <div className="flex mt-4" key={idx}>
                    <span
                      className="mx-6 text-xl text-gray-500 rounded-full cursor-pointer material-icons"
                      onClick={onClose}
                    >
                      check
                    </span>
                    <p>
                      {e[0]} - {e[1]}건
                    </p>
                  </div>
                ))}
              </div>
              <div className="bottom-0 flex items-center justify-center my-5">
                <button
                  onClick={(e) => {
                    onClose();
                  }}
                  className="flex items-center justify-center w-24 h-8 bg-[#1B5EDC] text-white rounded-[4px] border-black "
                >
                  확인
                </button>
              </div>
            </>
          }
        </Popup>
      </>
    );
  };

  const belong =
    commentcont.admin === null
      ? commentcont.patient.belong.name
      : commentcont.admin.belong.name;
  return (
    <>
      <PopUp open={open} onClose={closeModal} />
      <ToastContainer />
      <div className="grid grid-cols-10 min-w-[915px] text-center border border-[#E5E6E7] bg-[#fafbfc] ">
        {/* 첫번째 줄 */}
        <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-1">
          댓글내용
        </label>
        <label className="flex justify-start bg-white items-center text-left px-4 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-6">
          {commentcont.contents}
        </label>
        <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border ">
          신고
        </label>
        <label className="flex justify-center bg-white items-center px-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-2">
          <button
            className={
              report_flag
                ? "border bg-red-500 px-6 py-1 rounded-[6px] "
                : "border bg-gray-300 px-6 py-1 rounded-[6px] cursor-default"
            }
            onClick={() => {
              report_flag ? setOpen(true) : setOpen(false);
              setSort("report");
            }}
          >
            <p className="text-sm font-bold text-white">신고</p>
          </button>
        </label>
        {/* 두번째 줄 */}
        <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-1">
          작성일
        </label>
        <label className="flex justify-center bg-white items-center px-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-1">
          {dayjs(new Date(commentcont.createdAt)).format("YY.MM.DD")}
        </label>
        <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-1">
          작성자
        </label>
        <label className="flex justify-start bg-white items-center text-left px-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-2">
          <p
            onClick={() =>
              navigate(patient_admin, {
                state: {
                  data: info_patient,
                },
              })
            }
            className="cursor-pointer  hover:underline"
          >
            {commentcont.patient
              ? `${commentcont.patient.nickName}(${commentcont.patient.patientID})`
              : commentcont.admin
              ? `${commentcont.admin.name}`
              : ""}
          </p>
        </label>
        <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-1">
          소속
        </label>
        <label className="flex justify-center bg-white items-center px-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-1">
          {belong.substr(0, 3)}
        </label>

        <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-1">
          공개여부
        </label>
        <label className="flex justify-center bg-white items-center px-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-2">
          <ToggleBtnComment
            commentcont={commentcont}
            commudata={commudata}
            index={index}
          />
        </label>
      </div>
      <br></br>
    </>
  );
};

export default CommunityInfoComment;
