import React from "react";

const LifeInManage1 = ({ bigTitle, btn1 }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400 wordbreak-keepall">
      <div className="pt-[72px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
        <h1 className="h2_800 pt-[4px] pb-[24px]">{btn1}</h1>
      </div>
      <div className="w-full pt-[24px] body_1_400_jangrucont">
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>

          <span className="inline-block ">
            장루 이전의 옷차림과 똑같이 입어도 되며 벨트 등도 특별한 불편감이
            없다면 착용이 가능합니다.
          </span>
        </div>
        <div className="flex ">
          <span className="inline-block">•&nbsp;</span>
          <div>
            여성의 경우 팬티 스타킹과 보정 속옷 등 꽉 조이는 옷은 부드럽고
            신축성만 좋다면 착용이 가능합니다.
          </div>
        </div>
      </div>
    </div>
  );
};

export default LifeInManage1;
