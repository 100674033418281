import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMainContext } from "../../../../../contexts/Provider";
/*component*/
import SelectAppUse from "../../MyInfo/components/SelectAppUse";
const Check1 = ({ checkInfo, setCheckInfo, onChangeCheck }) => {
  const [state1, setState1] = useState(false);
  const navigate = useNavigate();
  const options = [
    "배우자",
    "부모",
    "자녀",
    "형제·자매",
    "친척",
    "간병인",
    "기타"
  ];
  console.log(checkInfo, ">>");
  const { setSelectedText, selectedText } = useMainContext();
  const onClickState1 = () => {
    setCheckInfo({ ...checkInfo, writer: "본인" });
    setSelectedText("환자와의 관계를 선택해주세요.");
    setState1(true);
  };
  const goCheck2 = () => {
    if (checkInfo.writer === "본인") {
      setCheckInfo({ ...checkInfo, writer: "본인" });
    } else {
      setCheckInfo({ ...checkInfo, writer: selectedText });
    }

    navigate(`/check/2`);
  };

  useEffect(() => {
    let pageOpen = false;
    if (!pageOpen) {
      if (
        !(
          checkInfo.writer === "" ||
          checkInfo.writer === "환자와의 관계를 선택해주세요."
        )
      ) {
        if (checkInfo.writer !== "본인") {
          setSelectedText(checkInfo.writer);
        }
      }
    }
    return () => {
      pageOpen = true;
    };
  }, []);

  return (
    <>
      <div className="pt-[94px] px-[24px]">
        <h1 className="h3_800 pb-[80px] ">작성자를 선택해 주세요.</h1>
        <div
          className={
            state1 || checkInfo.writer === "본인"
              ? "mb-[12px] border rounded w-full px-[24px] py-[16px] cursor-pointer bg-[#CBDFFC] border-[#1B5EDC] text-[#4E535F] body_1_600"
              : "mb-[12px] border rounded w-full px-[24px] py-[16px] cursor-pointer bg-[#F2F6FC] border-[#9DC4FA] active:bg-[#CBDFFC] text-[#4E535F]"
          }
          onClick={onClickState1}
        >
          앱 가입자(본인)
        </div>

        <SelectAppUse
          options={options}
          setCheckInfo={setCheckInfo}
          checkInfo={checkInfo}
          setState1={setState1}
        />
        <span className="block text-[#999CA3] caption_1_400 pt-[4px]">
          환자와의 관계를 작성해 주세요.
        </span>
      </div>
      {checkInfo.writer === "본인" ||
      !(selectedText === "환자와의 관계를 선택해주세요.") ? (
        <div className="fixed w-full bottom-0 left-0 flex gap-x-[8px] bg-[#FAFCFF]">
          <div
            className="w-full py-[20px] bg-[#1B5EDC] button_700 flex justify-center items-center text-white button_700 active:bg-[#18428E] cursor-pointer  ease-in-out duration-300"
            onClick={goCheck2}
          >
            다음
          </div>
        </div>
      ) : (
        <div className="fixed w-full bottom-0 left-0 w-full flex gap-x-[8px] bg-[#FAFCFF]">
          <div className="w-full py-[20px] bg-[#9DC4FA] button_700 flex justify-center items-center text-white button_700">
            다음
          </div>
        </div>
      )}
    </>
  );
};

export default Check1;
