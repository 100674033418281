import React from "react";

const FeelAnxiety = ({ bigTitle, btn3 }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400 wordbreak-keepall">
      <div className="pt-[72px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
        <h1 className="h2_800 pt-[4px] pb-[24px]">{btn3}</h1>
      </div>
      <div className="w-full pt-[24px] body_1_400_jangrucont">
        <span className="inline-block ">
          • 검사나 치료 전 충분히 설명을 듣습니다.
        </span>

        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            불안을 악화시키는 요인(수면부족, 불충분한 통증 조절, 많은 양의
            카페인 섭취, 니코틴 금단 증상 등) 제거합니다.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            가족이나 주위 사람들에게 감정을 솔직하게 이야기 합니다. 두려움,
            슬픔, 외로움 등 어떤 감정이든 괜찮습니다.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            힘든 점을 이야기하고 서로를 위해 할 수 있는 일들을 찾고 이해하도록
            노력합니다.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            대화를 하도록 노력하되 강요하지는 않습니다.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            면담이나 정신적 공감을 줄 수 있는 사람이나 성직자, 단체를
            찾아봅니다.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            불안하고 두려울 때 자기 자신이나 다른 사람을 비난하지 마십시오. 대신
            불안하고 두려운 원인을 찾아보고 그 이유에 대해 대화하세요.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            하루에 여러 번 깊은 심호흡을 하거나 이완을 시도해봅니다. 심호흡을
            시도 할 때에는 들숨보다 날숨의 길이를 길게 해봅니다. 잘 되지 않을
            때에는 호흡에 집중하는 것만으로도 도움이 됩니다.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">-&nbsp;</span>
          <span className="inline-block ">
            이완하는 법: 눈을 감고 심호흡을 하고 몸의 각 부분에 집중했다가
            하나씩 근육을 이완시켜 봅니다. 충분히 온 몸이 편안하게 이완되었을 때
            이른 아침의 해변가나 수목원 등 즐거운 곳에 있다고 상상해 봅니다.
          </span>
        </div>

        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            조용한 음악이나 미술, 독서나 영화 보기가 불안을 감소시킬 수
            있습니다.
          </div>
        </div>

        <span className="inline-block ">• 보호자가 도와줄 수 있는 방법</span>
        <div className="flex">
          <span className="inline-block ">-&nbsp;</span>
          <span className="inline-block ">
            환자의 불안감이나 두려움, 우울한 느낌에 대해서 부드럽게 접근합니다.
          </span>
        </div>
        <div>
          <span className="inline-block ">
            - 환자가 준비되기 전에 이야기를 강요하지 않습니다.
          </span>
          <div className="flex">
            <span className="inline-block ">-</span>
            <span className="inline-block pl-[4px]">
              환자의 감정이나 느낌을 판단하려 하지 말고 조용히 주의 깊게
              들어줍니다. 부정적인 생각을 지적하는 정도는 괜찮습니다.
            </span>
          </div>
          <div className="flex">
            <span className="inline-block ">-</span>
            <span className="inline-block pl-[4px]">
              극심한 불안이나 우울, 공포를 느낄 때 설득이나 반박은 도움이 되지
              않습니다. 그대로 들어주시고 의료진에게 알려주세요.
            </span>
          </div>

          <span className="inline-block ">
            &nbsp;- 힘든 점을 환자와 대화하고 공유하고 지지하도록 노력하세요.
          </span>
          <div className="flex">
            <span className="inline-block ">-&nbsp;</span>
            <span className="inline-block ">
              보호자도 스트레스를 많이 받을 수 있습니다. 당신이 쉴 수 있는
              시간을 가지세요.
            </span>
          </div>
        </div>
      </div>
      <div className="h-[70px]"></div>
    </div>
  );
};

export default FeelAnxiety;
