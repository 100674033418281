import React from "react";

const BasicLoading = () => {
  return (
    <div className="w-full h-screen fixed top-0 left-0 bg-[#F1F2F3] flex justify-center items-center z-100 opacity-80">
      <div className="w-[80px] h-[80px] basic-rotate"></div>
    </div>
  );
};

export default BasicLoading;
