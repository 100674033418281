import { useEffect, useRef, useState } from "react";
import { BtnBlack, BtnWhite } from "../../../admin_components/Buttons";
import Header2 from "../../../admin_components/Header/Header2";
import { useMainContext } from "../../../../../contexts/Provider";
import { useLocation, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import commonUrl, { api } from "../../../../../data/common";
import { ToastContainer, toast } from "react-toastify";
import TextEditorToolbar, {
  modules,
  formats,
} from "../../../admin_components/TextEditorToolbar";
import { convertRole } from "../../../admin_components/ConvertAdmin";
import { toastCommonProps } from "../../../admin_components/Toast";
import dayjs from "dayjs";
import { Loading } from "../../../admin_components/Loading";
import { handlingError } from "../../../admin_components/Error";

const ServiceDetail = () => {
  const radio_check = "/images/admin/radio_check.svg";
  const navigate = useNavigate();
  const { state } = useLocation();
  const { adminLogin } = useMainContext();
  const [loading, setLoading] = useState({ first: true, second: false });
  // 답변 내용
  const [answer, setAnswer] = useState({
    id: null,
    admin: {
      name: "",
      role: "",
    },
    content: "",
    createdAt: "",
    attachedFileURL: [],
  });
  // 등록 정보
  const [registerData, setRegisterData] = useState({
    id: null,
    name: "",
    inquiryDate: "",
    createdAt: "",
    employeeName: "",
    tel: "",
    email: "",
    inquiry: "",
  });
  const [files, setFiles] = useState([]); // 첨부파일
  const [toastElPosition, setToastElPosition] = useState({ top: 0, left: 0 }); // toast창 위치 값
  const [noAnswer, setNoAnswer] = useState(false);
  const fileInputRef = useRef();
  const answerExistsRef = useRef(false);

  // 카메라 아이콘은 input file과 연동
  const clickFileInputBtn = (e) => {
    fileInputRef.current.click();
  };

  // 선택한 사진이 바뀌었을 시 실행
  const fileChange = async (e) => {
    let fileList = [];
    for (const value of Object.entries(e.target.files)) {
      fileList.push(value[1]);
    }
    setFiles([...files, ...fileList]);
  };

  // 저장하기 버튼 눌렀을때 저장
  const save = () => {
    // toast창 위치 설정 위해 toast창 띄우기 전, 저장하기 버튼 위치 알아내기
    const toastEl = document.querySelector(".toast_standard");
    setToastElPosition({
      top: toastEl.getBoundingClientRect().top,
      left: toastEl.getBoundingClientRect().left,
    });

    let newFileUrl = []; // 파일 url들
    // 파일 url들 (기존에 있던 것 + 새로 첨부하는 것)
    if (answer.attachedFileURL) {
      answer.attachedFileURL.forEach((el) => {
        newFileUrl.push(el);
      });
    }
    if (files.length !== 0) {
      files.forEach((el) => {
        newFileUrl.push(el.lastModified + el.name);
      });
    }

    // 태그 떼고 내용 있는 지 확인(빈칸만 입력된 경우 거르기)
    const contentList = [];
    const htmlparser2 = require("htmlparser2");
    const parser = new htmlparser2.Parser({
      ontext(text) {
        contentList.push(text.trim());
      },
    });
    parser.write(answer.content);
    parser.end();
    const tempContent = contentList.join("");

    // 내용 입력 안한 경우
    if (!noAnswer && (tempContent === "" || !answer.content)) {
      toast(
        <p>내용을 입력해주세요.</p>,
        toastCommonProps(1500, "top-left", "toast_common toast__normal")
      );
      return;
    }
    // 이미지 갯수 초과된 경우
    else if (newFileUrl.length > 5) {
      toast(
        <p>파일은 최대 5개까지 추가 가능합니다.</p>,
        toastCommonProps(1500, "top-left", "toast_common toast__normal")
      );
      return;
    }
    // 사진이 15MB 이상이면
    else if (files[0]) {
      let toastShow = false;
      for (let el in files) {
        if (el.size / 1000000 > 15) {
          toastShow = true;
        }
      }
      if (toastShow) {
        toast(
          <p>15MB 이하의 파일만 추가 가능합니다.</p>,
          toastCommonProps(3000, "top-left", "toast_common toast__normal")
        );
        return;
      }
    }

    // axios로 보낼 데이타
    let newData = {
      admin: { id: adminLogin.id },
      serviceApply: { id: registerData.id },
      content: noAnswer ? undefined : answer.content,
      attachedFileURL: newFileUrl, // 첨부하는 파일
    };
    console.log(newData, ">>> newData");

    // 비동기 함수
    async function fetchData() {
      setLoading({ ...loading, second: true });
      const res = await api({
        method: "post",
        url: commonUrl.baseURL + "service/answer",
        data: newData,
      }).catch((error) => {
        handlingError(error);
      });
      console.log(res.data, ">>> Axios service/answer");
      // axios 성공
      if (res.data.ok) {
        toast(
          <p>저장되었습니다.</p>,
          toastCommonProps(1500, "top-left", "toast_common toast__normal")
        );
        setTimeout(() => {
          fetchUpdatedData();
        }, [2000]);
      }
      // axios 실패
      else {
        toast(
          <p>저장에 실패했습니다.</p>,
          toastCommonProps(1500, "top-left", "toast_common toast__normal")
        );
      }
    }
    fetchData();
  };

  async function fetchUpdatedData() {
    setLoading({ ...loading, second: true });
    const res = await api
      .get(commonUrl.baseURL + "service/apply/" + state.id)
      .catch((error) => {
        handlingError(error);
      });

    console.log(res.data, ">>> Axios service/apply/id");
    setLoading({ ...loading, second: false });
    if (res.data.ok) {
      const {
        id,
        name,
        createdAt,
        address,
        tel,
        email,
        employeeName,
        inquiry,
        serviceAnswer,
      } = res.data.serviceApply;
      setRegisterData({
        id,
        name,
        createdAt: dayjs(new Date(createdAt)).format("YYYY-MM-DD"),
        address,
        tel,
        email,
        employeeName,
        inquiry,
      });
      // 답변한 이력이 있다면
      if (serviceAnswer) {
        answerExistsRef.current = true;
        if (!serviceAnswer.content) {
          // setAnswer(serviceAnswer);
          setAnswer({
            ...serviceAnswer,
            content: "<p>답변 없이 처리되었습니다</p>",
          });
        } else {
          setAnswer(serviceAnswer);
        }
      }
    }
    // 답변등록에 실패한 경우
    else {
      toast(
        <p>정보 불러오기에 실패했습니다.</p>,
        toastCommonProps(1500, "top-left", "toast_common toast__normal")
      );
    }
  }

  // 첫 랜더시 정보 불러오기
  useEffect(() => {
    async function fetchData() {
      const res = await api
        .get(commonUrl.baseURL + "service/apply/" + state.id)
        .catch((error) => {
          handlingError(error);
        });

      console.log(res.data, ">>> Axios service/apply/id");
      setLoading({ ...loading, first: false });
      if (res.data.ok) {
        const {
          id,
          name,
          createdAt,
          address,
          tel,
          email,
          employeeName,
          inquiry,
          serviceAnswer,
        } = res.data.serviceApply;
        setRegisterData({
          id,
          name,
          createdAt: dayjs(new Date(createdAt)).format("YYYY-MM-DD"),
          address,
          tel,
          email,
          employeeName,
          inquiry,
        });
        // 답변한 이력이 있다면
        if (serviceAnswer) {
          answerExistsRef.current = true;
          if (!serviceAnswer.content) {
            setAnswer({
              ...serviceAnswer,
              content: "<p>답변 없이 처리되었습니다.</p>",
            });
          } else setAnswer(serviceAnswer);
        }
      }
      // 답변등록에 실패한 경우
      else {
        toast(
          <p>정보 불러오기에 실패했습니다.</p>,
          toastCommonProps(1500, "top-left", "toast_common toast__normal")
        );
      }
    }
    fetchData();

    return () => setLoading({ ...loading, second: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 파일 첨부 하면 제일 밑으로 자동 스크롤 되도록
  useEffect(() => {
    // 답변 내용 작성하기 중이고 파일이 추가 되었을 때
    if (files.length > 0) {
      setTimeout(() => {
        const adminScroll = document
          .querySelector(".adminScroll")
          .getBoundingClientRect().height;
        window.scrollBy(0, adminScroll);
      }, [300]);
    }
  }, [files]);

  useEffect(() => {
    // 답변없이 처리
    if (noAnswer) {
      setAnswer({ ...answer, content: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noAnswer]);

  return (
    <>
      <style jsx="true">{`
        .Toastify__toast {
          top: ${toastElPosition.top - 20}px;
          left: ${toastElPosition.left - 285}px;
        }
        .ql-toolbar.ql-snow {
          border-top-width: 0 !important;
          border-left-width: 0 !important;
          border-right-width: 0 !important;
          border-bottom-width: 2px !important;
          border-color: #e5e6e7 !important;
        }
        .ql-container.ql-snow {
          border: none !important;
        }
        .ql-container.ql-snow .ql-editor {
          padding: 0px !important;
        }
      `}</style>
      <Header2
        title={[
          { title: "서비스 신청", url: -1 },
          {
            title: "서비스 신청 상세보기",
            url: 0,
          },
        ]}
      >
        <div className="admin p-[32px]">
          <ToastContainer />
          <div className="h-[80px] min-h-[80px]"></div>
          {loading.first ? (
            <Loading />
          ) : (
            <>
              {/* 등록 정보 */}
              <div className="flex flex-col p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] ">
                <div className="text-left text-xl leading-[26px] font-extrabold text-[#343B48] mb-[16px]">
                  등록정보
                </div>
                {/* 표 */}
                <div className="grid grid-cols-[13%_18%_14%_19%_14%_22%] min-w-[915px] text-center border border-[#E5E6E7] bg-[#fafbfc] ">
                  <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                    병원 명
                  </label>
                  <label className="flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7]  font-normal text-base leading-[22px] text-[#020A1B] box-border col-span-3 truncate">
                    {registerData.name}
                  </label>
                  <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border ">
                    문의일
                  </label>
                  <label className="flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7]  font-normal text-base leading-[22px] text-[#020A1B] box-border truncate">
                    {registerData.createdAt}
                  </label>
                  <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border ">
                    담당자 이름
                  </label>
                  <label className="flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7]  font-normal text-base leading-[22px] text-[#020A1B] box-border truncate">
                    {registerData.employeeName}
                  </label>
                  <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border ">
                    담당자 휴대폰번호
                  </label>
                  <label className="flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7]  font-normal text-base leading-[22px] text-[#020A1B] box-border truncate">
                    {registerData.tel}
                  </label>
                  <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                    이메일 주소
                  </label>
                  <label className="pl-6 py-[7px] border border-[#E5E6E7] text-left font-normal text-base leading-[22px] text-[#020A1B] box-border truncate">
                    {registerData.email}
                  </label>
                  <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border ">
                    내용
                  </label>
                  <label className="flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7] font-normal text-base leading-[22px] text-[#020A1B] box-border col-span-5">
                    {registerData.inquiry}
                  </label>
                </div>
              </div>

              {/* 답변 작성하기*/}
              <div className="flex flex-col p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-6">
                <div className="text-left text-xl leading-[26px] font-extrabold text-[#343B48] mb-[16px]">
                  {answerExistsRef.current ? "답변 내용" : "답변 작성하기"}
                </div>
                {/* 표 */}
                {answer ? (
                  <div className="grid grid-cols-5 min-w-[915px] text-center border border-[#E5E6E7] bg-[#fafbfc] ">
                    {/* 첫번째 줄 */}
                    <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                      작성자
                    </label>
                    <label className="flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7] bg-[#F1F2F3] font-normal text-base leading-[22px] text-[#020A1B] box-border col-span-4">
                      {answerExistsRef.current
                        ? answer.admin.name +
                          " " +
                          convertRole(answer.admin.role)
                        : adminLogin.name + " " + convertRole(adminLogin.role)}
                    </label>
                    <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                      내용
                    </label>
                    <div
                      className="w-full overflow-auto vertical-middle border border-[#E5E6E7] col-span-4 overflow-auto"
                      style={{
                        backgroundColor: noAnswer ? "#F1F2F3" : "transparent",
                      }}
                    >
                      {answerExistsRef.current || noAnswer ? null : (
                        <TextEditorToolbar />
                      )}
                      <ReactQuill
                        className="w-full p-6 !h-[300px]"
                        theme="snow"
                        modules={
                          answerExistsRef.current || noAnswer
                            ? {
                                toolbar: false,
                              }
                            : modules
                        }
                        formats={formats}
                        value={answer.content}
                        onChange={(content, delta, source, editor) => {
                          // 작성하기일 때
                          if (!answerExistsRef.current) {
                            setAnswer({ ...answer, content: editor.getHTML() });
                          }
                        }}
                        readOnly={
                          answerExistsRef.current || noAnswer ? true : false
                        }
                      />
                    </div>
                    <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                      첨부 파일
                    </label>
                    {answerExistsRef.current ? (
                      <div
                        className="flex flex-col items-start gap-y-2 p-6 border border-[#E5E6E7] text-base leading-[22px] font-normal text-[#020A1B] col-span-4 "
                        style={{
                          backgroundColor: noAnswer ? "#F1F2F3" : "transparent",
                        }}
                      >
                        {answer.attachedFileURL
                          ? answer.attachedFileURL.map((el, idx) => {
                              return (
                                <label
                                  className="underline cursor-pointer font-normal text-base leading-[22px] text-[#020A1B]"
                                  key={idx}
                                  onClick={() => {
                                    window.open(
                                      `${
                                        commonUrl.networkImg
                                      }${encodeURIComponent(el)}`
                                    );
                                  }}
                                >
                                  {`${el}\n`}
                                </label>
                              );
                            })
                          : ""}
                      </div>
                    ) : (
                      <div
                        className="flex flex-col flex-wrap p-6 border border-[#E5E6E7] font-normal col-span-4 "
                        style={{
                          backgroundColor: noAnswer ? "#F1F2F3" : "transparent",
                        }}
                      >
                        <input
                          ref={fileInputRef}
                          type="file"
                          name="profileURL"
                          onChange={fileChange}
                          accept=".jpeg, .jpg, .png, .pdf"
                          multiple
                          hidden
                        />
                        <button
                          className="w-[88px] h-[38px] px-4 py-2 bg-[#4E535F] text-[#FFFFFF] font-bold text-base leading-[22px] rounded-[6px] mb-2"
                          onClick={() => {
                            if (!noAnswer) clickFileInputBtn();
                          }}
                          disabled={noAnswer ? true : false}
                        >
                          파일첨부
                        </button>
                        <p className="text-left text-[14px] font-normal leading-[18px] text-[#676C76] mb-2">
                          *첨부할 파일이 있는 경우 버튼을 클릭하여 등록 하세요.
                          (이미지는 최대 5개)
                        </p>
                        <div className="flex flex-col items-start pl-[5px]">
                          {answerExistsRef.current ? (
                            answer.attachedFileURL.map((el, idx) => {
                              return (
                                <label
                                  className="underline cursor-pointer font-normal text-base leading-[22px] text-[#020A1B]"
                                  key={idx}
                                  onClick={() => {
                                    window.open(
                                      `${
                                        commonUrl.networkImg
                                      }${encodeURIComponent(el)}`
                                    );
                                  }}
                                >
                                  {`${el}\n`}
                                </label>
                              );
                            })
                          ) : (
                            <div className="flex flex-col items-start gap-y-2">
                              {files.length !== 0
                                ? files.map((el, idx) => {
                                    return (
                                      <div
                                        className="flex justify-center items-center mr-[15px]"
                                        key={idx}
                                      >
                                        <span
                                          className="material-icons text-[15px] mr-[5px] cursor-pointer"
                                          onClick={() => {
                                            let tempFiles = files;
                                            tempFiles.splice(idx, 1); // 삭제
                                            setFiles([...tempFiles]);
                                          }}
                                        >
                                          cancel
                                        </span>
                                        <label className="text-[15px]">
                                          {files[idx].name}
                                        </label>
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                      작성일
                    </label>
                    <label
                      className="flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7] font-normal text-base leading-[22px] text-[#020A1B] box-border col-span-4"
                      style={{
                        backgroundColor: noAnswer ? "#F1F2F3" : "transparent",
                      }}
                    >
                      {answerExistsRef.current
                        ? dayjs(new Date(answer.createdAt)).format("YYYY-MM-DD")
                        : dayjs(new Date()).format("YYYY-MM-DD")}
                    </label>
                  </div>
                ) : null}

                {/* 저장하기 버튼 */}
                <div className="flex justify-between items-center mt-[18px]">
                  {/* 답변없이 처리 체크 */}
                  <div className="flex items-center gap-x-2">
                    {answerExistsRef.current ? null : noAnswer ? (
                      <>
                        <div
                          className="flex justify-center items-center w-[16px] h-[16px] border border-[#4E535F] rounded-[8px] bg-[#4E535F] cursor-pointer "
                          onClick={() => {
                            setNoAnswer(false);
                          }}
                        >
                          <img src={radio_check} alt="radio_check" />
                        </div>
                        <label className="flex text-base font-semibold leading-[22px] text-[#343B48]">
                          답변 없이 처리
                        </label>
                      </>
                    ) : (
                      <>
                        <div
                          className="flex justify-center items-center w-[16px] h-[16px] border border-[#4E535F] rounded-[8px] cursor-pointer "
                          onClick={() => {
                            setNoAnswer(true);
                          }}
                        ></div>
                        <label className="flex text-base font-semibold leading-[22px] text-[#343B48]">
                          답변 없이 처리
                        </label>
                      </>
                    )}
                  </div>
                  {/* 목록 & 저장 버튼 */}
                  <div className="flex gap-x-2">
                    <BtnWhite
                      context="목록"
                      onClick={() => {
                        navigate(-1);
                      }}
                      style={{ width: "90px" }}
                      standard="toast_standard"
                    />
                    {!answerExistsRef.current ? (
                      <BtnBlack
                        context="저장하기"
                        onClick={save}
                        loading={loading.second}
                        style={{ width: "88px" }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Header2>
    </>
  );
};

export default ServiceDetail;
