import { Flip } from "react-toastify";

// toast() 함수에 들어가는 공통 props를 전달
function toastCommonProps(autoClose, position, className, transition) {
  return {
    closeOnClick: false,
    position: position,
    autoClose: autoClose,
    hideProgressBar: true,
    limit: 1,
    transition: Flip,
    newestOnTop: false,
    className: className,
  };
}

// toast_standard라는 classname을 가진 버튼의 위치를 내보냄
const findToastPosition = (direction) => {
  const toastEl = document.querySelector(".toast_standard");
  if (toastEl) {
    if (direction === "top") {
      return toastEl.getBoundingClientRect().top;
    } else if (direction === "left") {
      return toastEl.getBoundingClientRect().left;
    } else if (direction === "right") {
      return toastEl.getBoundingClientRect().right;
    } else {
      return toastEl.getBoundingClientRect().bottom;
    }
  }
};

export { toastCommonProps, findToastPosition };
