import { useNavigate } from "react-router-dom";
import { useTable, useSortBy, usePagination } from "react-table";

function DashJangruCheckTable({
  columns,
  data,
  pageMove,
  sorting, // sorting 기능이 필요한 테이블이면 true
  pagination, // pagination이 필요한 테이블이면 true
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 5 } },
    useSortBy,
    usePagination
  );
  const navigate = useNavigate();
  const paginationBtns = new Array(pageCount).fill(0).map((el, idx) => idx + 1);

  // pagination UI
  const paginationUI = () => {
    const arrow_left = "/images/admin/carousel_left.svg";
    const arrow_right = "/images/admin/carousel_right.svg";

    return (
      <div className="flex justify-center items-center mt-[25px]">
        <button
          className="px-[17.6px]"
          onClick={() => {
            previousPage();
          }}
          disabled={!canPreviousPage}
        >
          <img
            src={arrow_left}
            alt="arrow_left"
            className="w-[6.4px] h-[11.2px]"
            style={{
              filter: !canPreviousPage
                ? "brightness(0) saturate(100%) invert(48%) sepia(0%) saturate(5417%) hue-rotate(195deg) brightness(134%) contrast(82%)"
                : "brightness(0) saturate(100%) invert(3%) sepia(29%) saturate(7499%) hue-rotate(218deg) brightness(99%) contrast(100%)",
            }}
          />
        </button>
        <div className="flex gap-x-1">
          {paginationBtns.map((el, idx) => {
            if (
              idx >= Math.floor(pageIndex / 5) * 5 &&
              idx <= Math.floor(pageIndex / 5) * 5 + 4
            ) {
              return (
                <button
                  key={idx}
                  className="flex justify-center items-center border w-[32px] h-[32px] text-center text-base text-[#020A1B] font-semibold leading-5 rounded-[28px]"
                  onClick={() => {
                    gotoPage(idx);
                  }}
                  style={{
                    borderColor: pageIndex === idx ? "#020A1B" : "transparent",
                  }}
                >
                  {idx + 1}
                </button>
              );
            } else return null;
          })}
        </div>
        <button
          className="px-[17.6px]"
          onClick={() => {
            nextPage();
          }}
          disabled={!canNextPage}
        >
          <img
            src={arrow_right}
            alt="arrow_right"
            className="w-[6.4px] h-[11.2px]"
            style={{
              filter: !canNextPage
                ? "brightness(0) saturate(100%) invert(48%) sepia(0%) saturate(5417%) hue-rotate(195deg) brightness(134%) contrast(82%)"
                : "brightness(0) saturate(100%) invert(3%) sepia(29%) saturate(7499%) hue-rotate(218deg) brightness(99%) contrast(100%)",
            }}
          />
        </button>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-col w-full">
        <table className="w-full table-fixed">
          <thead {...getTableProps()}>
            {headerGroups.map((headerGroup) => {
              return (
                <tr key={headerGroup.headers}>
                  {headerGroup.headers.map((col, idx) => {
                    return (
                      <th
                        key={col.id}
                        className="relative first:rounded-tl-2xl last:rounded-tr-2xl align-middle p-4 bg-[#CBDFFC] text-base text-[#020A1B] leading-[22px] font-semibold"
                        {...col.getHeaderProps(
                          idx === 0
                            ? {
                                style: { width: col.width },
                              }
                            : sorting
                            ? col.getSortByToggleProps({
                                style: { width: col.width },
                              })
                            : {
                                style: { width: col.width },
                              }
                        )}
                      >
                        {col.render("Header")}
                        <span>
                          {sorting
                            ? col.isSorted
                              ? col.isSortedDesc
                                ? " ▼"
                                : " ▲"
                              : idx === 0
                              ? ""
                              : ""
                            : null}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, rowIdx) => {
              prepareRow(row);
              return (
                <tr
                  key={row.id}
                  {...row.getRowProps()}
                  className={
                    rowIdx % 2 === 0
                      ? "cursor-pointer bg-[#F2F6FC] hover:bg-[#DDE9FA]"
                      : "cursor-pointer bg-[#FAFCFF] hover:bg-[#DDE9FA]"
                  }
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        key={cell.row.id}
                        className={
                          page.length - 1 === rowIdx
                            ? "align-middle p-4 first:rounded-bl-2xl last:rounded-br-2xl border-t border-[#CBDFFC] text-base text-[#020A1B] leading-[22px] font-normal"
                            : "align-middle p-4 border-t border-[#CBDFFC] text-base text-[#020A1B] leading-[22px] font-normal "
                        }
                        {...cell.getCellProps({
                          style: { width: cell.column.width },
                        })}
                        onClick={() => {
                          navigate(pageMove, {
                            state: row.original,
                          });
                        }}
                      >
                        {
                          <div
                            className="w-full text-center truncate"
                            id={cell.column.Header}
                            style={
                              cell.row.original.checkOX === "미신청"
                                ? { color: cell.column.color }
                                : null
                            }
                          >
                            {cell.render("Cell")}
                          </div>
                        }
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* pagenation */}
        {pagination && pageOptions.length > 1 ? paginationUI() : null}
      </div>
    </>
  );
}

export default DashJangruCheckTable;
