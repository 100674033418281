// 미 처리 수
const UnprocessedPerson = ({ data }) => {
  const unprocessed = "/images/admin/header_dash/unprocessed.svg";
  return (
    <div className="flex w-full justify-center p-6 rounded-3xl bg-[#9BDDAE] shadow-[0_1px_4px_rgba(0,0,0,0.25)] text-[#020A1B] ">
      <div className="flex flex-col justify-between w-full h-full">
        <div className="flex gap-x-[7px]">
          <img
            src={unprocessed}
            alt="secondLine_3"
            className="w-[20px] h-[20px]"
          />
          <label className="text-[18px] font-extrabold leading-[22px]">
            미 처리 수
          </label>
        </div>
        <p className="flex justify-end items-center w-full text-lg leading-[22px] font-semibold">
          <span className="text-2xl font-extrabold mr-1">
            {data[3].notTreat}
          </span>
          건
        </p>
      </div>
    </div>
  );
};

export default UnprocessedPerson;
