import Header2 from "../../../admin_components/Header/Header2";
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import Popup from "reactjs-popup";

import commonUrl, { api } from "../../../../../data/common";
import MorePageTable from "../components/MorePageTable";
import { useMainContext } from "../../../../../contexts/Provider";
import { Loading } from "../../../admin_components/Loading";
import { handlingError } from "../../../admin_components/Error";
import ExcelDownload from "../components/ExcelDownload";

export default function DashboardMore() {
  const more_left = "/images/admin/header_dash/more_left.svg";
  const more_right = "/images/admin/header_dash/more_right.svg";
  const more_today = "/images/admin/header_dash/more_today.svg";
  const more_check = "/images/admin/header_dash/more_check.svg";
  const cancel = "/images/admin/cancel.svg";
  const { adminLogin } = useMainContext();
  const [loading, setLoading] = useState(false); //
  const [getMoment, setMoment] = useState(moment()); // 달력에 보여줄 날짜들 셋팅
  // 이번 달 첫주가 1년 53주 중 몇번 째인지
  const firstWeek = useMemo(() => {
    return getMoment.clone().startOf("month").week();
  }, [getMoment]);
  // 이번 달 마지막 주가 1년 53주 중 몇번 째인지(1로 나오면 12월이 마지막주 라는 뜻 => 53으로 여김)
  const lastWeek = useMemo(() => {
    if (getMoment.clone().endOf("month").week() === 1) return 53;
    else return getMoment.clone().endOf("month").week();
  }, [getMoment]);
  const weekName = ["일", "월", "화", "수", "목", "금", "토"];
  const [dataList, setDataList] = useState([]); // 달력에 띄울 처리수 데이타
  const [completeNum, setCompleteNum] = useState(0); // 완료 건수
  // 각 날짜 선택시 오픈되는 팝업창의 open props (한달치 달력에 보여지는 최대일수 : 42일)
  const [popupOpen, setPopupOpen] = useState(new Array(42).fill(false));
  // 각 날짜 선택시 뜨는 팝업의 top값 & height 값
  const [popupPosition, setPopupPosition] = useState({
    top: null,
    height: null,
  });

  // 처리완료 환자 팝업 닫기
  const closePopup = () => {
    setPopupOpen(new Array(62).fill(false));
  };

  // 설정된 월이 바뀔 때마다 해당하는 정보를 가져옴
  useEffect(() => {
    const startDay = getMoment
      .clone()
      .startOf("year")
      .week(firstWeek)
      .startOf("week");
    const endDay = getMoment
      .clone()
      .startOf("year")
      .week(lastWeek)
      .endOf("week");

    // axios
    async function fetchData() {
      setLoading(true);
      const response = await api
        .get(
          `${commonUrl.patient}recentMonth/${startDay.format(
            "YYYY-MM-DD"
          )}/${endDay.format("YYYY-MM-DD")}/${adminLogin.belong}`
        )
        .catch((error) => {
          handlingError(error);
        });
      console.log(
        response.data,
        `>>> Axios recentMonth/${startDay.format("YYYY-MM-DD")}/${endDay.format(
          "YYYY-MM-DD"
        )}/${adminLogin.belong}`
      );
      // axios 성공 시
      if (response.data.ok) {
        setLoading(false);
        setDataList(response.data.surveyList);
        setCompleteNum(response.data.replyNum);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstWeek, getMoment, lastWeek]);

  return (
    <>
      <style jsx="true">{`
        .popup-overlay.calender__popup-overlay {
          background-color: transparent;
        }
        .popup-content.calender__popup-content {
          top: ${popupPosition.height !== null
            ? // 팝업의 마지막 페이지 리스트가 5개 미만일 경우
              `${popupPosition.top + popupPosition.height}px !important`
            : // 팝업의 마지막 페이지가 아님 = 페이지의 리스트가 5개 꽉차있는 경우
              "auto"};
          background-color: #f1f2f3;
          border: 1px solid transparent;
          border-radius: 8px;
          width: 213px;
          padding: 0px;
          filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
        }
      `}</style>
      <Header2
        title={[
          { title: "Dashboard", url: -1 },
          { title: "장루 점검 완료 건수", url: 0 },
        ]}
      >
        <div className="flex flex-col m-[50px]">
          <div className="h-[60px] min-h-[60px]"></div>
          <div className="flex flex-col gap-y-3">
            <div className="flex items-center justify-between grow">
              {/* 캘린더 월 이동 */}
              <div className="flex items-center gap-x-4 min-w-[515px]">
                <span className="font-extrabold text-2xl text-[#020A1B] leading-8">
                  {getMoment.format("YYYY년 ") +
                    Number(getMoment.format("MM")) +
                    "월"}
                </span>
                <img
                  src={more_left}
                  alt="more_left"
                  className="w-[40px] h-[40px] cursor-pointer"
                  onClick={() => {
                    setMoment(getMoment.clone().subtract(1, "month"));
                  }}
                />
                <img
                  src={more_right}
                  alt="more_right"
                  className="w-[40px] h-[40px] cursor-pointer"
                  onClick={() => {
                    setMoment(getMoment.clone().add(1, "month"));
                  }}
                />
                <img
                  src={more_today}
                  alt="more_today"
                  className="w-[76px] h-[40px] cursor-pointer"
                  onClick={() => {
                    setMoment(moment());
                  }}
                />
                {/* <ExcelDownload
                  year={getMoment.format("YYYY")}
                  month={getMoment.format("MM")}
                  belongId={adminLogin.belong}
                /> */}
              </div>
              {/* 총 처리 완료 건수 */}
              <div className="flex justify-center items-center h-[40px] bg-[#5FC17B] py-2 px-4 rounded-[28px]">
                <div className="flex items-center gap-x-[6px] font-semibold text-base leading-5 text-[#FAFBFC]">
                  <img
                    src={more_check}
                    alt="more_check"
                    className="w-[20px] h-[20px] "
                  />
                  완료
                </div>
                <div className="h-[24px] w-[1px] bg-[#FAFBFC] mx-2"></div>
                <p className="flex items-center font-semibold text-base text-[#FAFBFC] leading-[22px]">
                  총
                  <span className="ml-1 text-lg font-extrabold">
                    {completeNum}
                  </span>
                  건
                </p>
              </div>
            </div>
            <p className="flex justify-start font-normal text-base leading-[22px] text-[#4E535F]">
              * 본 장루 점검 완료 건수는 환자가 이해도 평가를 완료한 시점을
              기준으로 작성되었습니다.
            </p>
          </div>

          {/* 달력 */}
          {loading ? (
            <Loading />
          ) : (
            <div className="flex h-full w-full min-w-[875px] min-h-[550px] mt-[20px]">
              <div
                // 한달 달력의 주가 4주일 경우 | 5주일 경우 | 6주일 경우에 따라 row 갯수 변경
                className={
                  lastWeek - firstWeek < 5
                    ? lastWeek - firstWeek < 4
                      ? "grid grid-cols-7 grid-rows-[0.5fr_2fr_2fr_2fr_2fr] w-full mb-[62px] border border-[#E5E6E7]" // 4주 이하일 떄
                      : "grid grid-cols-7 grid-rows-[0.5fr_2fr_2fr_2fr_2fr_2fr] w-full mb-[62px] border border-[#E5E6E7]" // 5주 이하일 때
                    : "grid grid-cols-7 grid-rows-[0.5fr_2fr_2fr_2fr_2fr_2fr_2fr] w-full mb-[62px] border border-[#E5E6E7]" // 6주 이상
                }
              >
                {/* 요일 헤더 한 줄 */}
                {weekName.map((el, idx) => {
                  let color = "#020A1B";
                  if (el === "일") color = "#FB793B";
                  else if (el === "토") color = "#1B5EDC";
                  else color = "#020A1B";
                  return (
                    <label
                      className="flex justify-center items-center py-[13px] border border-[#E5E6E7] bg-[#FAFBFC] text-xl font-extrabold leading-[22px]"
                      key={idx}
                      style={{ color: color }}
                    >
                      {el}
                    </label>
                  );
                })}

                {/* 요일 Header 제외한 일자(Day) 칸들 */}
                {dataList.map((el, idx) => {
                  let color = "black";
                  let background;
                  // 오늘인 경우
                  if (moment().format("YYYY-MM-DD") === el.date) {
                    background = "#5FC17B";
                    color = "white";
                  }
                  // 일요일일 경우
                  else if (
                    moment(el.date.substring(0, 10).replace(/-/gi, "")).format(
                      "dddd"
                    ) === "Sunday"
                  )
                    color = "#FB793B";
                  // 토요일일 경우
                  else if (
                    moment(el.date.substring(0, 10).replace(/-/gi, "")).format(
                      "dddd"
                    ) === "Saturday"
                  )
                    color = "#1B5EDC";
                  // 그 외
                  else color = "#020A1B";

                  // 이번달이 아닌 경우
                  if (el.date.substring(5, 7) !== getMoment.format("MM"))
                    color = "#999CA3";

                  return (
                    <div key={idx}>
                      <style jsx="true">{`
                        .popup-arrow.calender__popup-arrow {
                          visibility: hidden;
                        }
                      `}</style>
                      <div
                        className="flex flex-col h-full  p-4 border border-[#E5E6E7] bg-[#FAFBFC] "
                        key={idx}
                      >
                        <label
                          className="font-bold text-[17px] w-[30px] h-[30px] rounded-full flex items-center justify-center"
                          style={{ color: color, backgroundColor: background }}
                        >
                          {Number(el.date.substring(8, 10))}
                        </label>
                        <label className="flex justify-end">
                          {/* 해당날짜에 처리된 완료 건수가 있을 경우 */}
                          {el.num !== 0 ? (
                            <button
                              className="flex justify-center items-center h-[40px] bg-[#5FC17B] py-[10px] px-[14px] rounded-[28px]"
                              id={`more_popup_${idx}`}
                              onClick={() => {
                                let newPopupList = new Array(42).fill(false);
                                newPopupList[idx] = true;
                                setPopupOpen(newPopupList);

                                // 이전에 열었던 팝업의 마지막 페이지 리스트가 5개 미만이었을 경우
                                // height가 null이 아닐 것이기 때문에 팝업의 위치가 변하는 모양이 눈에 띌 수 있음
                                // 따라서 popup height를 null로 초기화
                                setPopupPosition({
                                  ...popupPosition,
                                  height: null,
                                });
                              }}
                              style={{ color: color }}
                            >
                              <img
                                src={more_check}
                                alt="more_check"
                                className="w-[20px] h-[20px] cursor-pointer"
                              />
                              <p className="flex items-center font-semibold text-base text-[#FAFBFC] leading-[22px]">
                                <span className="ml-1 text-lg font-extrabold">
                                  {el.num}
                                </span>
                                건
                              </p>
                            </button>
                          ) : null}
                          {/* 팝업창 */}
                          <Popup
                            className="calender__popup"
                            closeButton={false}
                            trigger={<div></div>} //trigger가 있어야 nested가 됨
                            open={popupOpen[idx]}
                            onClose={closePopup}
                            position={
                              // 첫째주일 경우 팝업이 아랫쪽으로,  그 외에는 위쪽으로 뜨도록
                              idx < 7
                                ? // 토요일일 경우에는 왼쪽으로 뜨도록
                                  (idx + 1) % 7 === 0
                                  ? "bottom right"
                                  : "bottom center"
                                : (idx + 1) % 7 === 0
                                ? "top right"
                                : "top center"
                            }
                            nested
                          >
                            {(close) => (
                              <div className="flex flex-col w-full admin">
                                <div className="flex justify-between items-center w-full px-4 py-2 bg-[#5FC17B] border border-[#5FC17B] rounded-t-[8px] ">
                                  {/* 오늘 날짜 */}
                                  <p className="text-[#FAFBFC] text-sm leading-[18px] font-semibold ">
                                    {`${Number(
                                      el.date.substring(5, 7)
                                    )}월 ${el.date.substring(8, 10)}일`}
                                  </p>
                                  {/* 삭제 버튼 */}
                                  <img
                                    src={cancel}
                                    alt="cancel"
                                    onClick={close}
                                    className="w-[10.67px] h-[10.67px] cursor-pointer"
                                    style={{
                                      filter:
                                        "brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(732%) hue-rotate(174deg) brightness(100%) contrast(97%)",
                                    }}
                                  />
                                </div>
                                {/* popup내의 테이블 */}
                                <div className="flex flex-col w-full p-4 gap-y-2">
                                  <MorePageTable
                                    columns={[{ Header: "patient" }]}
                                    data={el.patientList}
                                    popupPosition={popupPosition}
                                    setPopupPosition={setPopupPosition}
                                    nested={idx < 7 ? "bottom" : "top"}
                                    // 테이블 뜨는 위치 조정을 이 컴포넌트에서 하기 때문에 props로 nested가 어디로 설정되었는지 보내줘야함
                                  />
                                </div>
                              </div>
                            )}
                          </Popup>
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </Header2>
    </>
  );
}
