import dayjs from "dayjs";
import "dayjs/locale/ko";
import { useEffect, useRef, useState } from "react";
import commonUrl, { api } from "../../../../data/common";
import TableYellow from "../../admin_components/TableYellow";
import { BtnBlue } from "../../admin_components/Buttons";
import Header2 from "../../admin_components/Header/Header2";
import { useMainContext } from "../../../../contexts/Provider";
import { Flip, toast, ToastContainer } from "react-toastify";
import { toastCommonProps } from "../../admin_components/Toast";
import { Loading } from "../../admin_components/Loading";

export function Community() {
  const dataRef = useRef([]); // 표기할 정보 담기
  const { Apostings, setApostings } = useMainContext(); // 로그인 정보 Consumer
  /*** 검색 관련 state, ref ***/
  const [search, setSearch] = useState("");
  const searchRef = useRef();
  const [loading, setLoading] = useState(false);
  const search_icon = "/images/admin/header_dash/search.svg";
  const showAllRef = useRef(false); // 전체보기 버튼 보여줄지 말지
  const toast_func = (text) => {
    console.log(text);
    toast(
      <p>{text}</p>,
      toastCommonProps(2000, "top-right", "toast_common toast__search", Flip)
    );
  };
  // 검색버튼을 누르고 search 가 바뀌었을 시
  useEffect(() => {
    if (search !== "") {
      const newData = dataRef.current.filter((el) => {
        return el.contents.includes(search);
        // return el.posting.contents.includes(search);
      });
      if (newData.length === 0) {
        toast_func("검색된 내용이 없습니다."); //toast 표출
        setSearch(""); //검색 변수 초기화
        searchRef.current.value = ""; // 검색창 빈칸으로 만들기
      } else {
        setApostings(newData);
        showAllRef.current = true;
      }
    }
  }, [search, setApostings]);
  console.log(dataRef, "dataRefdataRef");

  // 전체 보기 클릭시
  const onClickShowAll = () => {
    setApostings(dataRef.current);
    setSearch("");
    searchRef.current.value = ""; // 검색창 빈칸으로 만들기
    showAllRef.current = false;
  };
  // 첫 랜더시 데이터 불러오기(비동기 처리)
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await api.get(commonUrl.community);
      dataRef.current = response.data.postingList;
      setApostings(dataRef.current);
      setLoading(false);
    }
    fetchData();
  }, [setApostings]);

  /******************************* Table UI 관련 변수 & 함수들  ******************************/
  // Table에 들어갈 column명
  const columns = [
    {
      accessor: "idx",
      Header: "No",
      width: "5%",
    },
    {
      accessor: "contents",
      Header: "내용",
      width: "25%",
    },
    {
      accessor: "name",
      Header: "작성자(등록번호)",
      width: "15%",
    },
    {
      accessor: "createdAt",
      Header: "작성일",
      width: "15%",
    },
    {
      accessor: "flag",
      Header: "구분",
      width: "10%",
    },
    {
      accessor: "status",
      Header: "공개여부",
      width: "10%",
    },
    {
      accessor: "report",
      Header: "신고관리",
      width: "10%",
    },
  ];

  // Pagination위해 한페이지 당 보여줄 내용 다듬어서 내보냄
  function currentPosts(data) {
    let newData = [];
    let count = 1;
    newData =
      data === undefined
        ? ""
        : data.map((d, idx) => {
            return { ...d, idx: idx + count }; // idx 붙이기
          });

    for (let i = 0; i < newData.length; i++) {
      newData[i].idx = newData.length - i;
    }
    return newData.map((post) => {
      let newContens = post.contents;
      let newName = post.patient.name;
      let newPatientID = post.patient.patientID;
      let newCreatedAt = dayjs(new Date(post.createdAt)).format("YYYY.MM.DD");
      let newStatus = post.public === 0 ? "공개" : "비공개";
      let newflag = post.patient.belong.name;
      return {
        ...post,
        contents: newContens,
        name: `${newName}(${newPatientID})`,
        createdAt: newCreatedAt,
        status: newStatus,
        flag: newflag,
        report: post.report.length > 0 ? "신고" : "-",
      };
    });
  }

  return (
    <Header2 title={[{ title: "커뮤니티 관리", url: 0 }]}>
      <ToastContainer />
      <div className="admin flex flex-col grow p-[32px]">
        <div className="h-[80px] min-h-[80px]"></div>
        <div>
          <label className="text-2xl font-extrabold leading-8 text-[#020A1B] cursor-pointer">
            커뮤니티 관리
          </label>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-3 min-w-[975px]">
            <div className="flex items-center justify-end">
              {/* 검색하기 */}
              <div className="flex gap-x-2 ml-[10px]">
                <div className="flex items-center w-[290px] h-[38px] border border-[#CCCDD1] box-border px-6 py-2 rounded-[48px]">
                  <img
                    src={search_icon}
                    alt="search"
                    className="w-[16.5px] h-[16.6px] mr-[9.69px]"
                  />
                  <input
                    type="text"
                    className="w-[210px] h-[22px] bg-transparent text-[#4E535F] focus:outline-none placeholder:font-semibold placeholder:text-base placeholder:leading-[22px] placeholder:text-[#4E535F]"
                    placeholder="내용으로 검색하기"
                    ref={searchRef}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") setSearch(searchRef.current.value);
                    }}
                  />
                </div>
                {/* 검색버튼 클릭 */}
                <BtnBlue
                  onClick={() => setSearch(searchRef.current.value)}
                  context="검색"
                  style={{
                    width: "76px",
                    height: "38px",
                    borderRadius: "48px",
                  }}
                />
              </div>
            </div>
            {loading === false ? (
              <div className="mt-[20px] y-[10px]">
                <TableYellow
                  columns={columns}
                  data={currentPosts(Apostings)}
                  pageMove={"/admin/main/communityid"}
                  pageSize={10}
                  sorting={false}
                  pagination={true}
                  showAll={showAllRef.current ? onClickShowAll : false}
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </Header2>
  );
}

export default Community;
