import React from "react";

const FeelSad = ({ bigTitle, btn4 }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400 wordbreak-keepall">
      <div className="pt-[72px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
        <h1 className="h2_800 pt-[4px] pb-[24px]">{btn4}</h1>
      </div>
      <div className="w-full pt-[24px] body_1_400_jangrucont">
        <span className="inline-block ">
          • 자신의 감정에 대해 의료진 및 가족, 보호자와 대화를 나누세요.
        </span>

        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            암환자에서 우울이 드문 일이 아니며 우울 증상을 관리하여 삶의 질
            향상, 환자와 의료진의 커뮤니케이션 향상, 치료 순응도 향상을 통해
            결과를 좋게 할 수 있습니다.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            주위 사람들에게 감정을 솔직하게 이야기 합니다. 두려움, 슬픔, 외로움
            등 어떤 감정이나 다 좋습니다.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            충분한 휴식을 취하고 규칙적으로 몸을 움직이세요. 가벼운 신체 활동은
            심신의 에너지를 높여줍니다.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            복용하는 약물의 목록을 작성한 후 추후 의료진에게 보여주세요.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            담당 의료진에게 암과 치료에 의한 부작용에 대해 확인하세요. 그리고
            부작용 관리에 도움이 되는 사항을 배우세요.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            균형 잡힌 식사를 하고 체중을 줄이지 마세요. 음식에 있는 많은
            영양소는 건강을 유지하기 위해 매우 중요합니다.
          </div>
        </div>
        <span className="inline-block ">
          • 음주를 피하세요. 술은 당신을 우울하게 합니다.
        </span>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            우울은 금방 좋아지지 않을 수 있습니다. 개선될 때까지 몇 주에서 몇
            달이 걸릴 수 있습니다.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            만약 처방된 약이 있다면 약물복용을 중단하지 마세요. 우울이 다시 생길
            수도 있습니다. 무엇을 느끼는지 안다면 그것을 인지 할 수 있다는
            것입니다.
          </div>
        </div>

        <span className="inline-block text-[#245BBF]">
          • 호흡 및 명상을 해보세요.
        </span>
      </div>
      <div className="h-[70px]"></div>
    </div>
  );
};

export default FeelSad;
