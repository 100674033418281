import React from "react";

const Style2A = ({ title, bigTitle }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400 wordbreak-keepall">
      <div className="pt-[24px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">{title}</span>
        <h1 className="h2_800 pt-[4px] pb-[24px]">{bigTitle}</h1>
      </div>
      <div className="px-[16px] w-full pt-[30px] body_1_400_jangrucont">
        <div>
          퇴원 후 아래와 같은 증상이 지속될 경우 병원에 방문해야 합니다!
        </div>
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <span className="inline-block ">
            장루의 색이 보라색이나 검정색으로 변한 경우
          </span>
        </div>
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <span className="inline-block ">
            복통∙구토가 2시간 이상 지속되는 경우
          </span>
        </div>
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <span className="inline-block ">
            장루에서 지속적으로 과다한 출혈이 지속되는 경우
          </span>
        </div>
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <span className="inline-block ">
            탈수: 설사가 24시간 이상 지속되거나 경련, 현기증, 소변량 감소와 같은
            증상이 있을 때 (24시간 이내 2000ml 이상 회장루에서 변이 나오면
            과도한 정도이며, 탈수의 위험이 있으며 적절한 수분 공급이 필요함)
          </span>
        </div>

        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <span className="inline-block ">
            과도한 탈장이나 장탈출이 있는 경우
          </span>
        </div>
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <span className="inline-block ">
            매일 피부 보호판이 떨어지는 경우
          </span>
        </div>
      </div>
      <div className="h-[70px]"></div>
    </div>
  );
};

export default Style2A;
