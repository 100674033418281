import React, { useEffect } from 'react'
import common, { api } from '../../../../data/common'
import { useNavigate } from 'react-router-dom';
import { useMainContext } from '../../../../contexts/Provider';

const AutoLogin = () => {
  const { loginInput,setLoginInput } = useMainContext();
  const FCM = new URL(window.location.href).searchParams.get("token") || loginInput.fcmToken ;
  const navigate = useNavigate()

  useEffect(() => {
    if(FCM){
      api.post(common.baseURL + "patient/autologin/" + `${FCM}`).then(res => {
        if(res.data.ok){
          setLoginInput({
            isLoggedIn: true,
            patientID: res.data.patient.patientID,
            id: res.data.patient.id,
            name: res.data.patient.name,
            nickName: res.data.patient.nickName,
            createdAt: res.data.patient.createdAt,
            belongid: res.data.patient.belong.id,
            fcmToken: FCM,
          });
          navigate("/main")
        }else{
          navigate("/login", {
            state: FCM,
          });
        }
      })
    }else{
      navigate("/login", {
        state: FCM,
      });
    }
  }, [])
  
  return null;
}

export default AutoLogin