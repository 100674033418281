import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  BtnBlack,
  BtnWhite,
  BtnWhiteBack,
} from "../../../admin_components/Buttons";
import Header2 from "../../../admin_components/Header/Header2";
import { useMainContext } from "../../../../../contexts/Provider";
import { useLocation, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import commonUrl, { api } from "../../../../../data/common";
import { ToastContainer, toast } from "react-toastify";
import TextEditorToolbar, {
  modules,
  formats,
} from "../../../admin_components/TextEditorToolbar";
import { convertRole } from "../../../admin_components/ConvertAdmin";
import { toastCommonProps } from "../../../admin_components/Toast";
import { handlingError } from "../../../admin_components/Error";
import common from "../../../../../data/common";

const Reply = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { adminLogin } = useMainContext();
  const [loading, setLoading] = useState(false);

  /* 상담 작성 내용 관련 state */
  const [counsel, setCounsel] = useState({
    id: state.data.id,
    admin: adminLogin.id,
    writer: state.data.admin,
    jangruSurvey: state.data.jangruSurvey,
    reply: state.data.reply || "",
    serviceText: state.data.serviceText || "",
    attachedFileURL: state.data.attachedFileURL,
  });
  /* 상담 내용에 첨부/삭제하는 파일 관련 변수 */
  const [files, setFiles] = useState([]);
  const deleteFilesRef = useRef([]); // 삭제할 파일

  /* 서비스 제공 내용 버튼 관련 변수들 */
  const [serviceCheck, setServiceCheck] = useState({
    0: false, // 장루 기능 상태 확인
    1: false, // 올바른 장루 교환
    2: false, // 장루 및 피부 상태 점검
    3: false, // 합병증 예방
    4: false, // 병원 방문
    5: false, // 식생활/영양/체중 관리
    6: false, // 일상생활 관리
    7: false, // 정신건강 관리
    8: false, // 기타
  });
  const service = [
    "장루 기능 상태 확인",
    "올바른 장루 교환 수행 여부 확인",
    "장루 및 피부 상태 점검",
    "합병증 예방 관리",
    "병원 방문이 필요한 상황",
    "식생활/영양/체중 관리",
    "일상생활 관리",
    "정신건강 관리",
  ];
  // toast창 위치 값을 담음
  const [toastElPosition, setToastElPosition] = useState({ top: 0, left: 0 }); // toast창 위치 값
  const fileInputRef = useRef();

  // 카메라 아이콘은 input file과 연동
  const clickFileInputBtn = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  // 선택한 사진이 바뀌었을 시 실행
  const fileChange = async (e) => {
    // 파일 변수에 넣기
    let fileList = [];
    for (const value of Object.entries(e.target.files)) {
      fileList.push(value[1]);
    }
    setFiles([...files, ...fileList]);
  };

  // 저장하기 버튼 눌렀을때 저장
  const save = () => {
    // toast창 위치 설정 위해 toast창 띄우기 전, 저장하기 버튼 위치 알아내기
    const toastEl = document.querySelector(".toast_standard");

    setToastElPosition({
      top: toastEl.getBoundingClientRect().top,
      left: toastEl.getBoundingClientRect().left,
    });

    let newService = []; // 서비스
    let newFileUrl = []; // 파일 url들

    // 서비스
    for (const [key, value] of Object.entries(serviceCheck)) {
      if (value) newService.push(Number(key));
    }

    // 파일 url들 (기존에 있던 것 + 새로 첨부하는 것)
    if (counsel.attachedFileURL) {
      counsel.attachedFileURL.forEach((el) => {
        newFileUrl.push(el);
      });
    }
    if (files.length !== 0) {
      files.forEach((el) => {
        newFileUrl.push(el.lastModified + el.name.replaceAll(" ", ""));
      });
    }

    // 서비스 선택 안한 경우
    const replyList = [];
    const htmlparser2 = require("htmlparser2");
    const parser = new htmlparser2.Parser({
      ontext(text) {
        replyList.push(text.trim());
      },
    });
    parser.write(counsel.reply);
    parser.end();
    const tempReply = replyList.join("");
    if (tempReply === "" || !counsel.reply) {
      toast(
        <p>내용을 입력해주세요.</p>,
        toastCommonProps(1500, "top-left", "toast_common toast__normal")
      );
      return;
    }
    // 서비스 선택 안한 경우
    else if (newService.length === 0) {
      toast(
        <p>서비스 제공 내용을 선택해주세요.</p>,
        toastCommonProps(1500, "top-left", "toast_common toast__normal")
      );
      return;
    }
    // 이미지 갯수 초과된 경우
    else if (newFileUrl.length > 5) {
      toast(
        <p>파일은 최대 5개까지 추가 가능합니다.</p>,
        toastCommonProps(1500, "top-left", "toast_common toast__normal")
      );
      return;
    }
    // 이미지 갯수 초과된 경우
    else if (serviceCheck[9] && counsel.serviceText.trim() === "") {
      toast(
        <p>기타 내용을 작성해주세요.</p>,
        toastCommonProps(1500, "top-left", "toast_common toast__normal")
      );
      return;
    }
    // 사진이 15MB 이상이면
    else if (files[0]) {
      let toastShow = false;
      files.forEach((element) => {
        if (element.size / 1000000 > 15) {
          toastShow = true;
        }
      });
      if (toastShow) {
        toast(
          <p>15MB 이하의 파일만 추가 가능합니다.</p>,
          toastCommonProps(3000, "top-left", "toast_common toast__normal")
        );
        return;
      }
    }

    // axios로 보낼 데이타
    let newData = {
      id: counsel.id ? counsel.id : undefined,
      jangruSurvey: counsel.jangruSurvey,
      admin: adminLogin.id, // adminID
      reply: counsel.reply, // 상담 내용
      service: newService, // 서비스
      serviceText: counsel.serviceText,
      attachedFileURL: newFileUrl, // 첨부하는 파일
    };
    // console.log(newData, ">>> newData");

    // 비동기 함수
    async function fetchData() {
      setLoading(true);
      const res = await api({
        method: "post",
        url: commonUrl.jangruReply + "nurse",
        data: newData,
      }).catch((error) => {
        handlingError(error);
      });
      // console.log(res.data, ">>> Axios jangruReply/nurse");
      if (res.data.ok) {
        // 사진 삭제
        // if (deleteFilesRef.current.length !== 0) {
        //   const response = await api({
        //     method: "post",
        //     url: common.baseURL + "upload/delete",
        //     data: { files: deleteFilesRef.current },
        //   }).catch((error) => {
        //     handlingError(error);
        //   });
        //   console.log(response.data, ">>> fileUploadDelete");
        // }
        console.log(files);
        // 사진 추가
        if (files.length !== 0) {
          for (let i = 0; i < files.length; i++) {
            let formData = new FormData();
            formData.append("file", files[i]);
            formData.append(
              "name",
              files[i].lastModified + files[i].name.replaceAll(" ", "")
            );

            // 파일들 서버에 보내기
            // const response = await api({
            //   method: "post",
            //   url: commonUrl.fileUploadMulti,
            //   data: formData,
            // }).catch((error) => {
            //   handlingError(error);
            // });
            const response = await api.post(
              common.baseURL + "upload/oneNew",
              formData
            );
            console.log(response.data, ">>> Axios fileUploadMulti");
            if (response.data.ok) {
              if (i === files.length - 1) {
                toast(
                  <p>저장 되었습니다.</p>,
                  toastCommonProps(
                    1500,
                    "top-left",
                    "toast_common toast__normal"
                  )
                );
                setTimeout(() => {
                  setLoading(false);
                  navigate(-1);
                }, 1700);
              }
            } else {
              // 사진 서버에 올리는 것 실패했을 시
              toast(
                <p>사진등록에 실패하였습니다.</p>,
                toastCommonProps(1500, "top-left", "toast_common toast__normal")
              );
            }
          }
          // const formData = new FormData();
          // files.forEach((file) => {
          //   formData.append("files", file, file.lastModified + file.name);
          // });
        }
        // 업데이트할 사진 없을 때
        else {
          toast(
            <p>저장 되었습니다.</p>,
            toastCommonProps(1500, "top-left", "toast_common toast__normal")
          );
          setTimeout(() => {
            setLoading(false);
            navigate(-1);
          }, 2000);
        }
      }
      // 답변등록에 실패한 경우
      else {
        setLoading(false);
        toast(
          <p>답변등록에 실패했습니다.</p>,
          toastCommonProps(1500, "top-left", "toast_common toast__normal")
        );
      }
    }
    fetchData();
  };

  // 첫랜더시 데이터 서비스 내용 미리 체크 해두기
  useEffect(() => {
    const { service } = state.data;
    // 이미 작성된 답변이 있었다면 service 선택란 미리 체크해두기
    if (service) {
      let newService = {};
      // 서비스 미리 체크
      service.forEach((el) => {
        newService = Object.assign(newService, { [el]: true });
      });
      setServiceCheck({
        ...serviceCheck,
        ...newService,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 파일 첨부 하면 제일 밑으로 자동 스크롤 되도록
  useEffect(() => {
    if (files.length > 0) {
      console.log("파일 추가됨");
      setTimeout(() => {
        const adminScroll = document
          .querySelector(".adminScroll")
          .getBoundingClientRect().height;
        window.scrollBy(0, adminScroll);
      }, [300]);
    }
  }, [files]);

  return (
    <>
      <style jsx="true">{`
        .Toastify__toast {
          top: ${toastElPosition.top - 20}px;
          left: ${toastElPosition.left - 285}px;
        }
        .ql-toolbar.ql-snow {
          border-top-width: 0 !important;
          border-left-width: 0 !important;
          border-right-width: 0 !important;
          border-bottom-width: 2px !important;
          border-color: #e5e6e7 !important;
        }
        .ql-container.ql-snow {
          border: none !important;
        }
        .ql-container.ql-snow .ql-editor {
          padding: 0px !important;
        }
      `}</style>
      <Header2
        title={[
          { title: "장루점검", url: "/admin/main/check" },
          {
            title: "대상자 상세보기",
            url: -2,
            state: state.detailPageId
              ? { data: { id: state.detailPageId, prevPath: undefined } }
              : undefined,
            replace: true,
          },
          { title: "장루 상태 평가", url: -1 },
          { title: "상담내용 작성하기", url: 0 },
        ]}
      >
        <div className="admin p-[32px]">
          <ToastContainer />
          <div className="h-[80px] min-h-[80px]"></div>
          {/* 뒤로가기 버튼 */}

          <BtnWhiteBack to={-1} />
          <div className="flex flex-col p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-3">
            <div className="text-left text-xl leading-[26px] font-extrabold text-[#343B48] mb-[25px]">
              상담내용
            </div>
            {/* 표 */}
            <div className="grid grid-cols-5 min-w-[915px] text-center border border-[#E5E6E7] bg-[#fafbfc] ">
              {/* 첫번째 줄 */}
              <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                작성자
              </label>
              <label className="flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7] bg-[#F1F2F3] font-normal text-base leading-[22px] text-[#020A1B] box-border col-span-4">
                {adminLogin.name + " " + convertRole(adminLogin.role)}
              </label>
              <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                내용
              </label>
              <div className="w-full overflow-auto vertical-middle border border-[#E5E6E7] col-span-4 overflow-auto">
                <TextEditorToolbar />
                <ReactQuill
                  className="w-full p-6"
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  value={counsel.reply}
                  onChange={(content, delta, source, editor) => {
                    setCounsel({ ...counsel, reply: editor.getHTML() });
                  }}
                />
              </div>

              <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                첨부 파일
              </label>
              <div className="flex flex-col flex-wrap p-6 border border-[#E5E6E7] font-normal col-span-4 ">
                <input
                  ref={fileInputRef}
                  type="file"
                  name="profileURL"
                  onChange={fileChange}
                  accept=".jpeg, .jpg, .pdf, .png"
                  multiple
                  hidden
                />
                <button
                  className=" w-[88px] h-[38px] px-4 py-2 bg-[#4E535F] text-[#FFFFFF] font-bold text-base leading-[22px] rounded-[6px] mb-2"
                  onClick={clickFileInputBtn}
                >
                  파일첨부
                </button>
                <p className="text-left text-[14px] font-normal leading-[18px] text-[#676C76] mb-2">
                  *첨부할 파일이 있는 경우 버튼을 클릭하여 등록 하세요.
                  (이미지는 최대 5개)
                </p>
                <div className="flex flex-col items-start pl-[5px]">
                  {counsel.attachedFileURL
                    ? counsel.attachedFileURL.map((el, idx) => {
                        return (
                          <div
                            className="flex justify-center items-center mr-[15px] mb-[3px]"
                            key={idx}
                          >
                            <span
                              className="material-icons text-[15px] mr-[5px] cursor-pointer"
                              onClick={() => {
                                let tempFiles = counsel.attachedFileURL;
                                deleteFilesRef.current.push(tempFiles[idx]); // 삭제할 이름 저장
                                tempFiles.splice(idx, 1); // 삭제
                                setCounsel({
                                  ...counsel,
                                  attachedFileURL: tempFiles,
                                });
                              }}
                            >
                              cancel
                            </span>
                            <label className="text-[15px]">
                              {counsel.attachedFileURL[idx]}
                            </label>
                          </div>
                        );
                      })
                    : null}
                  <div className="flex flex-col items-start gap-y-2">
                    {files.length !== 0
                      ? files.map((el, idx) => {
                          return (
                            <div
                              className="flex justify-center items-center mr-[15px]"
                              key={idx}
                            >
                              <span
                                className="material-icons text-[15px] mr-[5px] cursor-pointer"
                                onClick={() => {
                                  let tempFiles = files;
                                  tempFiles.splice(idx, 1); // 삭제
                                  setFiles([...tempFiles]);
                                }}
                              >
                                cancel
                              </span>
                              <label className="text-[15px]">
                                {files[idx].name}
                              </label>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
              {/* 서비스 제공내용 */}
              <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                *서비스 제공 내용
              </label>
              <div className="border border-[#E5E6E7] col-span-4">
                <div className="flex flex-wrap items-center p-6 gap-x-2 gap-y-2">
                  {service.map((el, idx) => {
                    return (
                      <button
                        key={idx}
                        className="flex justify-center items-center py-1 px-4 bg-[#4E535F] text-base leading-[22px] font-normal text-[#FAFBFC] border border-[#4E535F] rounded-[18px]"
                        onClick={(e) => {
                          if (idx === 0 && serviceCheck[0] === false) {
                            setServiceCheck({
                              0: true,
                            });
                            setCounsel({ ...counsel, serviceText: "" });
                          } else {
                            setServiceCheck({
                              ...serviceCheck,
                              [idx]: !serviceCheck[idx],
                            });
                          }
                        }}
                        style={
                          serviceCheck[0]
                            ? idx === 0
                              ? {
                                  backgroundColor: `#4E535F`,
                                  color: "#FAFBFC",
                                }
                              : {
                                  backgroundColor: "#f7f7f8",
                                  color: "#DADBDC",
                                  borderColor: "#DADBDC",
                                }
                            : serviceCheck[idx]
                            ? {
                                backgroundColor: `#4E535F`,
                                color: "#FAFBFC",
                              }
                            : {
                                backgroundColor: `#FAFBFC`,
                                color: "#4E535F",
                              }
                        }
                        disabled={
                          serviceCheck[0] ? (idx !== 0 ? true : false) : false
                        }
                      >
                        {el}
                      </button>
                    );
                  })}
                  {/* 서비스 제공 내용 기타 */}
                  <div className="flex w-auto gap-x-2">
                    <button
                      className="flex justify-center items-center py-1 px-4 bg-[#4E535F] text-base leading-[22px] font-normal text-[#FAFBFC] border border-[#4E535F] rounded-[18px]"
                      onClick={() => {
                        if (serviceCheck[9])
                          setCounsel({ ...counsel, serviceText: "" });
                        setServiceCheck({
                          ...serviceCheck,
                          9: !serviceCheck[9],
                        });
                      }}
                      style={
                        serviceCheck[0]
                          ? {
                              backgroundColor: "#f7f7f8",
                              color: "#DADBDC",
                              borderColor: "#DADBDC",
                            }
                          : serviceCheck[9]
                          ? {
                              backgroundColor: `#4E535F`,
                              color: "#FAFBFC",
                            }
                          : {
                              backgroundColor: `#FAFBFC`,
                              color: "#4E535F",
                            }
                      }
                      disabled={serviceCheck[0] ? true : false}
                    >
                      기타
                    </button>
                    <input
                      className="w-3/5 flex justify-center items-center py-1 px-4 text-base leading-[22px] font-normal text-[#4E535F] border border-[#4E535F] rounded-[18px] focus:outline-none"
                      type="text"
                      disabled={serviceCheck[9] ? false : true}
                      value={counsel.serviceText}
                      onChange={(e) => {
                        setCounsel({ ...counsel, serviceText: e.target.value });
                      }}
                      style={
                        serviceCheck[0]
                          ? {
                              borderColor: "#DADBDC",
                              backgroundColor: "#f7f7f8",
                              color: "#DADBDC",
                            }
                          : serviceCheck[9]
                          ? null
                          : { color: "#DADBDC" }
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* 저장하기 버튼 */}
            <div className="flex justify-end items-center mt-[18px]">
              <div className="flex gap-x-2">
                <BtnWhite
                  context="취소하기"
                  onClick={() => {
                    navigate(-1);
                  }}
                  style={{ width: "90px" }}
                  standard="toast_standard"
                />
                <BtnBlack
                  context="저장하기"
                  onClick={save}
                  loading={loading}
                  style={{ width: "88px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </Header2>
    </>
  );
};

export default Reply;
