import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { StomaManageText } from "../data/StomaManageText";
import { useNavigate } from "react-router-dom";
SwiperCore.use([Autoplay, Pagination]);
const MainBanner = () => {
  const navigate = useNavigate();
  const appDownload = () => {
    const uagent = navigator.userAgent.toLowerCase();
    const android_agent = uagent.search("android");
    const iphone = uagent.search("iphone");
    const ipad = uagent.search("ipad");
    if (android_agent > -1) {
      //안드로이드
      window.flutter_inappwebview.callHandler(
        "linkUrl",
        `https://play.google.com/store/apps/details?id=com.herings.healiary`
      );
      // window.open(
      //   `https://play.google.com/store/apps/details?id=com.herings.healiary`,
      //   "_blank"
      // );

      return;
    } else if (iphone > -1 || ipad > -1) {
      //아이폰
      window.flutter_inappwebview.callHandler(
        "linkUrl",
        "https://apps.apple.com/kr/app/id1636424503"
      );
      // window.open("https://apps.apple.com/kr/app/id1636424503", "_blank");
      return;
    }
  };

  return (
    <div className="mainbanner pt-[24px]  mx-[24px]">
      <Swiper
        spaceBetween={8}
        pagination={true}
        loop={true}
        slidesPerView={1}
        speed={1000}
        autoplay={{ delay: 2500, disableOnInteraction: false }}
      >
        <SwiperSlide>
          <div
            className={` h-[112px] banner1 text-white subtitle_2 flex items-end rounded overflow-hidden cursor-pointer`}
            onClick={() => {
              navigate("/more", {
                state: {
                  title: StomaManageText[12].title,
                  title2: StomaManageText[12].title2,
                  btn1: StomaManageText[12].btn1,
                  btn2: StomaManageText[12].btn2,
                  btn3: StomaManageText[12].btn3,
                  btn4: StomaManageText[12].btn4,
                  btn5: StomaManageText[12].btn5,
                  btn6: StomaManageText[12].btn6,
                  svgurl: StomaManageText[12].svgurl,
                  bigTitle: StomaManageText[12].bigTitle,
                  writeDate: StomaManageText[12].writeDate,
                },
              });
            }}
          >
            <div className="relative w-full h-full ">
              <div className="absolute top-0 left-0 z-1 w-full h-full px-[20px] pb-6 flex flex-col justify-end">
                <span className="block">{StomaManageText[12].slideTitle1}</span>
                <span className="block">{StomaManageText[12].slideTitle2}</span>
              </div>
              <div className="absolute top-0 left-0 w-full h-full bg-black opacity-40"></div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className={` h-[112px] banner3 text-white subtitle_2 flex items-end rounded overflow-hidden cursor-pointer`}
            onClick={() => {
              navigate("/more", {
                state: {
                  title: StomaManageText[16].title,
                  title2: StomaManageText[16].title2,
                  btn1: StomaManageText[16].btn1,
                  btn2: StomaManageText[16].btn2,
                  btn3: StomaManageText[16].btn3,
                  btn4: StomaManageText[16].btn4,
                  btn5: StomaManageText[16].btn5,
                  btn6: StomaManageText[16].btn6,
                  svgurl: StomaManageText[16].svgurl,
                  bigTitle: StomaManageText[16].bigTitle,
                  writeDate: StomaManageText[16].writeDate,
                },
              });
            }}
          >
            <div className="relative w-full h-full ">
              <div className="absolute top-0 left-0 z-1 w-full h-full px-[20px] pb-6 flex flex-col justify-end">
                <span className="block">{StomaManageText[16].slideTitle1}</span>
                <span className="block">{StomaManageText[16].slideTitle2}</span>
              </div>
              <div className="absolute top-0 left-0 w-full h-full bg-black opacity-40"></div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className={` h-[112px] banner2 text-white subtitle_2 flex items-end rounded overflow-hidden cursor-pointer`}
            onClick={() => {
              navigate("/more", {
                state: {
                  title: StomaManageText[3].title,
                  title2: StomaManageText[3].title2,
                  btn1: StomaManageText[3].btn1,
                  btn2: StomaManageText[3].btn2,
                  btn3: StomaManageText[3].btn3,
                  btn4: StomaManageText[3].btn4,
                  btn5: StomaManageText[3].btn5,
                  btn6: StomaManageText[3].btn6,
                  svgurl: StomaManageText[3].svgurl,
                  bigTitle: StomaManageText[3].bigTitle,
                  writeDate: StomaManageText[3].writeDate,
                },
              });
            }}
          >
            <div className="relative w-full h-full ">
              <div className="absolute top-0 left-0 z-1 w-full h-full px-[20px] pb-6 flex flex-col justify-end">
                <span className="block">{StomaManageText[3].slideTitle1}</span>
                <span className="block">{StomaManageText[3].slideTitle2}</span>
              </div>
              <div className="absolute top-0 left-0 w-full h-full bg-black opacity-40"></div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className={` h-[112px] banner4 text-white subtitle_2 flex items-end rounded overflow-hidden cursor-pointer`}
            onClick={appDownload}
          ></div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default MainBanner;
