import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import common, { api } from "../../../../data/common";
import { useMainContext } from "../../../../contexts/Provider";
/*component*/
import GobackPage from "../../mobilecomponent/GoBackPage";
import MainIcons from "../../mobilecomponent/MainIcons";
import NoticeList from "./components/NoticeList";

const Notice = () => {
  const [news, setNews] = useState([]);
  const [noNews, setNoNews] = useState(false);
  const { loginInput } = useMainContext();
  const navigate = useNavigate();
  const pocketandjangruboys = "/images/mo/pngjpg/pocketandjangruboys.png";

  useEffect(() => {
    let pageOpen = false;
    const getNews = () => {
      api
        .get(common.baseURL + "news/pid/" + loginInput.id)
        .then((res) => {
          if (res.data.ok === true) {
            console.log(res);
            setNews(res.data.newsReadList);
            if (res.data.newsReadList.length === 0) {
              setNoNews(true);
            }
          }
        })
        .catch((error) => console.log(error));
    };
    if (!pageOpen) {
      if (
        loginInput.id === null ||
        loginInput.id === undefined ||
        loginInput.id === ""
      ) {
        navigate("/main", { replace: true });
      } else {
        getNews();
      }
    }
    return () => {
      pageOpen = true;
    };
  }, [loginInput.id, navigate]);

  return (
    <>
      <div className="h-screen bg-[#FAFCFF] relative overflow-y-auto">
        <GobackPage>새소식</GobackPage>
        <ul className="mt-[48px] px-[24px] ">
          {news?.map((el, index) => (
            <NoticeList el={el} key={index} />
          ))}
          {noNews ? (
            <div className="pt-[140px] flex justify-center items-center flex-col body_1_400">
              <img src={pocketandjangruboys} alt="pocketsandjangruboys" />
              <div className="pt-[24px]">아직 새소식이 등록되지 않았어요.</div>
              <div>조금만 기다려주세요 :&#41;</div>
            </div>
          ) : null}
        </ul>

        <div className="h-[80px]"></div>
      </div>

      <MainIcons />
    </>
  );
};

export default Notice;
