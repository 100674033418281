import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
SwiperCore.use([Autoplay]);
const StomaStricture = ({ bigTitle, btn6 }) => {
  const image17 = "/images/mo/jangrucont/장루합병증17.jpg";
  const image18 = "/images/mo/jangrucont/장루합병증18.jpg";
  return (
    <div className="w-full flex flex-col  px-[16px] body_1_400 wordbreak-keepall">
      <div className="pt-[72px] ">
        <div className="flex justify-center flex-col items-center w-full border-b">
          <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
          <h1 className="h2_800 pt-[4px] pb-[24px]">{btn6}</h1>
        </div>
        <div className="w-full pt-[24px] body_1_400_jangrucont">
          <div>
            <div className="subtitle_1_700 pb-[8px]">1) 정의</div>
            <Swiper
              loop={true}
              spaceBetween={8}
              slidesPerView={1}
              autoplay={{ delay: 1000, disableOnInteraction: false }}
            >
              <SwiperSlide>
                <div className="w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
                  <img src={image17} alt="image17" className="h-full" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
                  <img src={image18} alt="image18" className="h-full" />
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="pt-[8px]">
              <span className="inline-block">
                정상적인 배변 기능에 위협을 줄 정도로 장루가 작아지는 것을
                말합니다.
              </span>
            </div>
          </div>
          <div className="pt-[32px]">
            <div className="subtitle_1_700 pb-[8px]">2) 관리방법</div>

            <div className="pt-[8px]">
              <div className="flex  ">
                <span className="inline-block">1.&nbsp;</span>
                <div>
                  손가락에 젤리를 묻혀 손가락의 한마디 정도를 장루 개구부에 넣어
                  이완시키는 수지 확장법을 시행합니다.
                </div>
              </div>
              <div className="flex  ">
                <span className="inline-block">2.&nbsp;</span>
                <div>
                  섬유소가 많거나 찌꺼기가 낄 수 있는 도정되지 않은 곡류, 날것의
                  채소나 과일, 유제품 등을 피합니다.
                </div>
              </div>
              <div className="flex  ">
                <span className="inline-block">3.&nbsp;</span>
                <div>
                  <span className="inline-block">수분 섭취를 많이 하세요.</span>
                </div>
              </div>
              <div className="flex  ">
                <span className="inline-block">4.&nbsp;</span>
                <div>
                  <span className="inline-block">
                    복통, 대변 배출이 안 될 경우는 응급실을 방문하세요.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StomaStricture;
