import Header2 from "../../admin_components/Header/Header2";
import { useMainContext } from "../../../../contexts/Provider";
import commonUrl, { api } from "../../../../data/common";
import { useEffect, useState } from "react";

import "dayjs/locale/ko";
import { Loading } from "../../admin_components/Loading";
import DashJangruCheckTable from "./components/DashJangruCheckTable";
import DashOnlyHerings from "./components/DashOnlyHerings";
import GenderGraph from "./components/GenderGraph";
import JangruGraph from "./components/JangruGraph";
import RecentSeven from "./components/RecentSeven";
import ParticipantOX from "./components/ParticipantOX";
import UnappliedPerson from "./components/UnappliedPerson";
import UnprocessedPerson from "./components/UnprocessedPerson";
import TodayProcessedPerson from "./components/TodayProcessedPerson";
import { handlingError } from "../../admin_components/Error";

export function Dashboard() {
  const dash_jangrucheck = "/images/admin/header_dash/header_client.svg";
  const { adminLogin } = useMainContext(); // 로그인 정보 Consumer
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]); // 대시보드에 보여줄 데이타
  const [tableDataList, setTableDataList] = useState([]); // 장루 점검 대상자 목록
  // window 가로 길이 따른 그래프 컴포넌트의 Height 조절
  const [resize, setResize] = useState(() => {
    if (window.innerWidth > 1650) return 334;
    else if (window.innerWidth > 1540) return 304;
    else if (window.innerWidth > 1440) return 274;
    else return 254;
  });
  // 그래프 구성 변경 : viewport가 너비 1400 이하일 때 flex-row -> flex-col
  const [flexCol, setFlexCol] = useState(() => {
    if (window.innerWidth <= 1400) return true;
    else return false;
  });

  // resize시 그래프 컴포넌트의 Height 변경
  const handleResize = () => {
    if (window.innerWidth > 1680) setResize(334);
    else if (window.innerWidth > 1540) setResize(304);
    else if (window.innerWidth > 1440) setResize(274);
    else setResize(254);

    // flexCol
    if (window.innerWidth <= 1400) setFlexCol(true);
    else setFlexCol(false);
  };

  // data[3] = 장루점검 대상자 목록 data handling
  const handleData = (data) => {
    let newDataList = data.patientList.map((el, idx) => {
      // 만나이 계산
      const birth = el.patient.birth;
      const newAge = new Date().getFullYear() - birth.substring(0, 4) + 1;

      return {
        ...el.patient,
        idx: data.patientList.length - idx,
        checkOX: el.checkOX,
        checkDay: el.checkDay,
        process: el.process,
        age: newAge,
        gender: el.patient.gender === "M" ? "남" : "여",
      };
    });
    console.log(newDataList);
    setTableDataList(newDataList);
  };

  // 첫랜더 이후
  useEffect(() => {
    // 정보 불러오기
    const fetchData = async () => {
      setLoading(true);
      let data = [null, null, null, null];
      //성별, 장루 분포별 환자수
      data[0] = await api
        .get(commonUrl.patient + "genderTypeNum/" + adminLogin.belong)
        .catch((error) => {
          handlingError(error);
        });
      // 최근 7일 장루 점검 건수
      data[1] = await api
        .get(commonUrl.patient + "recent7/" + adminLogin.belong)
        .catch((error) => {
          handlingError(error);
        });
      // 참여중, 참여완료 환자수
      data[2] = await api
        .get(commonUrl.patient + "participate/" + adminLogin.belong)
        .catch((error) => {
          handlingError(error);
        });
      // 미신청자 건수, 표 + 장루 점검 처리 (해당 월, 금일)
      data[3] = await api
        .get(commonUrl.patient + "whoSubmitted/" + adminLogin.belong)
        .catch((error) => {
          handlingError(error);
        });
      if (data[3].data.ok) handleData(data[3].data);
      setLoading(false);
      console.log(data[0].data, ">>> Axios genderTypeNum");
      console.log(data[1].data, ">>> Axios recent7");
      console.log(data[2].data, ">>> Axios participate");
      console.log(data[3].data, ">>> Axios whoSubmitted");
      setData(data.map((res) => res.data));
    };
    fetchData();

    // resize detect
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /******************************* Chart UI 관련 변수 & 함수들  ******************************/
  // 성별 분포 Dounut Chart 데이타
  const genderData = {
    labels: ["남자", "여자"],
    datasets: [
      {
        label: "",
        data: data[0] ? [data[0].male, data[0].female] : [0, 0],
        backgroundColor: ["#8E9AFF", "#FFBBBB"],
        borderWidth: 3,
        hoverOffset: 4,
      },
    ],
  };

  // 장루 분포 Dounut Chart 데이타
  const jangruData = {
    labels: ["공장루", "횡행결장루", "하행/구불결장루", "상행결장루", "회장루"],
    datasets: [
      {
        label: "",
        data: data[0]
          ? [
              data[0].type1,
              data[0].type2,
              data[0].type3,
              data[0].type4,
              data[0].type5,
            ]
          : [0, 0, 0, 0, 0],
        backgroundColor: [
          "#8E9AFF",
          "#FFBBBB",
          "#94DBCA",
          "#FADD79",
          "#F9BC60",
        ],
        borderWidth: 3,
        hoverOffset: 4,
      },
    ],
  };

  // 최근 장루 점검 건수
  const recentData = {
    labels: data[1]
      ? data[1].surveyList.map(
          (el) =>
            Number(el.date.substring(5, 7)) +
            "/" +
            Number(el.date.substring(8, 10))
        )
      : [],
    datasets: [
      {
        data: data[1]
          ? [
              data[1].surveyList[0].num,
              data[1].surveyList[1].num,
              data[1].surveyList[2].num,
              data[1].surveyList[3].num,
              data[1].surveyList[4].num,
              data[1].surveyList[5].num,
              data[1].surveyList[6].num,
            ]
          : [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: ["#5FC17B"],
        borderColor: ["#5FC17B"],
        borderWidth: 3,
      },
    ],
  };

  // 성별 분포 & 장루 분포 Chart Options
  const options = {
    cutout: "60%",
    responsive: true,
    maintainAspectRatio: false, // 원래 비율 없앰
    plugins: {
      legend: {
        display: false,
      },
    },
    layout: {
      margin: 0,
    },
  };

  // 최근 장루 점검 건수 Options
  const recentOptions = {
    responsive: true,
    maintainAspectRatio: false, // 원래 비율 없앰
    indexAxis: "x",
    scales: {
      x: {
        display: true,
        grid: {
          color: "transparent",
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "#E5E6E7",
          borderColor: "transparent",
          tickColor: "transparent",
        },
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  //Table columns
  const columns = [
    {
      accessor: "idx",
      Header: "NO.",
      width: "5%",
    },
    {
      accessor: "name",
      Header: "이름",
      width: "10%",
    },
    {
      accessor: "patientID",
      Header: "등록번호",
      width: "10%",
    },
    {
      accessor: "gender",
      Header: "성별",
      width: "7%",
    },
    {
      accessor: "age",
      Header: "만나이",
      width: "8%",
    },
    {
      accessor: "jangruType",
      Header: "장루유형",
      width: "15%",
    },
    {
      accessor: "checkDay",
      Header: "정기 점검일",
      width: "15%",
    },
    {
      accessor: "checkOX",
      Header: "설문 작성일",
      color: "red",
      width: "15%",
    },
    {
      accessor: "process",
      Header: "장루 평가일",
      width: "15%",
    },
  ];

  // resize 시마다 세로길이 변경되는 그래프 Component
  const ResizedGraph = ({ children, height }) => {
    return (
      <div
        className="flex flex-col p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)]"
        style={{ height: height }} // minmax는 grid-row-[]로 조절
      >
        {children}
      </div>
    );
  };

  return (
    <Header2 title={[{ title: "Dashboard", url: 0 }]}>
      <div className="admin p-[24px]">
        <div className="h-[80px] min-h-[80px]"></div>
        {loading ? (
          <Loading />
        ) : (
          <>
            {/* viewport의 가로 너비 <= 1400px일 경우 flexCol = true */}
            {flexCol ? (
              <div className="grid grid-cols-[minmax(1008px,1205px)]">
                <div className="grid grid-cols-[minmax(492px,592.5px)_minmax(492px,592.5px)] gap-x-6 mb-6">
                  {/* 성별분포 & 장루분포 & 참여자 수 */}
                  <div className="grid grid-rows-[minmax(268px,268px)_minmax(268px,268px)_minmax(158px,158px)] gap-y-6 max-h-[742px]">
                    {/* 성별 분포 */}
                    {data[0] ? (
                      <ResizedGraph height={268}>
                        <GenderGraph
                          flexCol={flexCol}
                          graphData={genderData}
                          options={options}
                          data={data}
                        />
                      </ResizedGraph>
                    ) : null}
                    {/* 장루 분포 */}
                    {data[0] ? (
                      <ResizedGraph height={268}>
                        <JangruGraph
                          flexCol={flexCol}
                          graphData={jangruData}
                          options={options}
                          data={data}
                        />
                      </ResizedGraph>
                    ) : null}
                    {/* 총 참여자, 참여 종료자 */}
                    {!data[2] ? null : <ParticipantOX data={data} />}
                  </div>
                  {/* 최근 7일 & 미처리 건수/ 금일 처리 건수 & 미신청자 수 */}
                  <div className="grid grid-cols-[minmax(492px,592.5px)] grid-rows-[minmax(363px,363px)_minmax(173px,173px)_minmax(158px,158px)] gap-y-6 max-h-[742px]">
                    {/* 최근 장루 점검 건수 */}
                    {!data[1] ? null : (
                      <ResizedGraph height={363}>
                        <RecentSeven
                          graphData={recentData}
                          options={recentOptions}
                        />
                      </ResizedGraph>
                    )}
                    <div className="flex grow gap-x-6 min-h-[173px]">
                      {/* 미 처리 수 */}
                      {!data[3] ? null : <UnprocessedPerson data={data} />}
                      {/* 금일 처리 */}
                      {!data[3] ? null : <TodayProcessedPerson data={data} />}
                    </div>

                    {/* 미 신청자 수*/}
                    <div className="h-[158px] min-h-[158px]">
                      {!data[3] ? null : <UnappliedPerson data={data} />}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {/******** 첫째줄 그래프들 ********/}
                <div className="grid grid-rows-[minmax(254px_334px)] grid-cols-[minmax(314px,586px)_minmax(403px,675px)_minmax(407px,743px)] gap-x-6 mb-6">
                  {data[0] == null ? null : (
                    <>
                      {/* 성별 분포 */}
                      <ResizedGraph height={resize}>
                        <GenderGraph
                          flexCol={flexCol}
                          graphData={genderData}
                          options={options}
                          data={data}
                        />
                      </ResizedGraph>
                      {/* 장루 분포 */}
                      <ResizedGraph height={resize}>
                        <JangruGraph
                          flexCol={flexCol}
                          graphData={jangruData}
                          options={options}
                          data={data}
                        />
                      </ResizedGraph>
                    </>
                  )}
                  {/* 최근 장루 점검 건수 */}
                  {!data[1] ? null : (
                    <ResizedGraph height={resize}>
                      <RecentSeven
                        graphData={recentData}
                        options={recentOptions}
                      />
                    </ResizedGraph>
                  )}
                </div>
                {/******** 둘째줄 건수들 ********/}
                <div className="grid grid-cols-[minmax(352px,640.8px)_minmax(249px,318.4px)_minmax(249px,318.4px)_minmax(249px,318.4px)] gap-x-6 mb-6">
                  {/* 총 참여자, 참여 종료자 */}
                  {!data[2] ? null : <ParticipantOX data={data} />}
                  {/* 미 신청자 수*/}
                  {!data[3] ? null : <UnappliedPerson data={data} />}
                  {/* 미 처리 수 */}
                  {data[3] == null ? null : <UnprocessedPerson data={data} />}
                  {/* 금일 처리 */}
                  {!data[3] ? null : <TodayProcessedPerson data={data} />}
                </div>
              </>
            )}

            {/******** 셋째 줄 점검 대상자 목록 ********/}
            <div className="flex flex-col min-w-[1008px] p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)]">
              <div className="flex grow gap-x-2 mb-[25px]">
                <img
                  src={dash_jangrucheck}
                  alt="dash_jangrucheck"
                  className="w-[20px] h-[20px] cursor-pointer "
                />
                <label className="text-[18px] font-bold leading-[22px] text-[#343B48]">
                  장루 점검 대상자 목록
                </label>
              </div>
              {/* 장루 점검 대상자 테이블 */}
              <div className="max-h-[482px] w-full rounded-2xl overflow-hidden">
                {tableDataList.length === 0 ? (
                  <label className=" text-base font-normal leading-[20px] text-[#A0A3AB] mt-2 ">
                    점검 대상자가 없습니다
                  </label>
                ) : (
                  <DashJangruCheckTable
                    columns={columns}
                    data={tableDataList}
                    pageMove={"/admin/main/check/detail"}
                    pagination={true}
                    sorting={true}
                  />
                )}
              </div>
            </div>
          </>
        )}
        {/******** 넷째 줄 커뮤니티 & 앱 이용문의 관리 (헤링스 관리자만) ********/}
        {adminLogin.role === 0 ? <DashOnlyHerings /> : null}
      </div>
    </Header2>
  );
}

export default Dashboard;
