import { Line } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";

// 최근 7일 그래프
const RecentSeven = ({ graphData, options }) => {
  const datepicker = "/images/admin/header_dash/datepicker.svg";
  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-between grow ">
        <p className="font-extrabold text-[18px]">최근 7일 점검 완료 건수</p>
        {/* <p
          className="flex items-center text-base leading-[22px] text-[#020A1B] font-normal cursor-pointer"
          onClick={() => {
            navigate("/admin/main/dashboard/more");
          }}
        >
          전체보기
          <img
            src={datepicker}
            alt="datepicker"
            className="w-[16.7px] h-[16.7px] ml-[5.5px] cursor-pointer "
          />
        </p> */}
      </div>
      <p className="flex justify-end text-base leading-[22px] font-normal text-[#343B48] my-4">
        단위: 명
      </p>
      <div className="h-full min-h-[114px] max-h-[194px]">
        <Line
          options={options}
          data={graphData}
          className="min-h-[230] min-w-[450] max-h-[300px] max-w-[600px]"
        />
      </div>
    </>
  );
};

export default RecentSeven;
