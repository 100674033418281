import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../../../contexts/Provider";
import common, { api } from "../../../../../data/common";
import { useLocation } from "react-router-dom";
const SelectAppUse = ({
  options,
  questionId,
  setCheckInfo,
  checkInfo,
  setState1,
}) => {
  const downicon = "/images/mo/down.svg";
  const [optionActive, setOptionActive] = useState(false);
  const { setSelectedText, selectedText } = useMainContext();
  // const [selectedText, setSelectedText] = useState("문의 유형을 선택해주세요.");
  const pathname = useLocation().pathname;
  console.log(selectedText, ">>selectedText");
  console.log(questionId, "questionId");
  useEffect(() => {
    let pageOpen = false;
    if (!pageOpen) {
      const getQuestionId = () => {
        api.get(common.baseURL + "question/id/" + questionId).then((res) => {
          console.log(res, "selectappuse.js res~~");
          if (res.data.ok === true) {
            if (res.data.question.category === 0) {
              setSelectedText("서비스 문의");
            } else if (res.data.question.category === 1) {
              setSelectedText("장애 및 불편신고");
            } else if (res.data.question.category === 2) {
              setSelectedText("제안사항");
            } else {
              setSelectedText("기타");
            }

            console.log(res.data);
          }
        });
      };
      if (pathname === "/myinfo/appusewrite") {
        if (questionId) {
          getQuestionId();
        } else {
          setSelectedText("문의 유형을 선택해주세요.");
        }
      } else {
        setSelectedText("환자와의 관계를 선택해주세요.");
      }
    }
    return () => {
      pageOpen = true;
    };
  }, [setSelectedText, questionId]);

  return (
    <div
      className={
        pathname === "/myinfo/appusewrite"
          ? "w-full text-[#999CA3] z-5 body_1_400 relative"
          : "w-full cursor-pointer bg-[#F2F6FC]  hover:bg-[#CBDFFC] relative"
      }
    >
      <div
        className={
          pathname === "/myinfo/appusewrite"
            ? "flex justify-between px-[12px] py-[13px] rounded border cursor-pointer"
            : pathname === "/check/1" &&
              !(
                selectedText === "환자와의 관계를 선택해주세요." ||
                selectedText === ""
              )
            ? "bg-[#CBDFFC] border border-[#1B5EDC] rounded px-[24px] py-[16px] flex justify-between body_1_600"
            : "bg-[#F2F6FC] border border-[#9DC4FA] rounded px-[24px] py-[16px] flex justify-between"
        }
        onClick={() => {
          if (pathname === "/check/1") {
            setState1(false);
          }
          setOptionActive(!optionActive);
        }}
      >
        <span>{selectedText}</span>
        <img
          src={downicon}
          alt="downicon"
          className={optionActive ? "noticeup" : "noticedown"}
        />
      </div>
      {optionActive && options !== null ? (
        <>
          <ul
            className={
              pathname === "/myinfo/appusewrite"
                ? "absolute top-[48px] px-[12px] pb-[6px] border-l border-r border-b rounded-bl-md rounded-br-md w-full bg-[#FAFCFF] left-0 flex flex-col cursor-pointer"
                : "absolute top-[48px] px-[12px] pb-[6px] border-l border-r border-b border-[#9DC4FA] rounded-bl-md rounded-br-md w-full bg-[#F2F6FC] left-0 flex flex-col cursor-pointer"
            }
          >
            {pathname === "/myinfo/appusewrite"
              ? options.map((option, index) => (
                  <li
                    key={index}
                    className={
                      index === 0
                        ? "bg-[#FAFBFC] hover:bg-[#F2F6FC] ease-in-out duration-300 px-[8px] py-[13px] border-t"
                        : "bg-[#FAFBFC] hover:bg-[#F2F6FC] ease-in-out duration-300 px-[8px] py-[13px]"
                    }
                    onClick={() => {
                      setOptionActive(false);
                      setSelectedText(option);
                    }}
                  >
                    {option}
                  </li>
                ))
              : options.map((option, index) => (
                  <li
                    key={index}
                    className={
                      index === 0
                        ? "bg-[#F2F6FC] hover:bg-[#CBDFFC] ease-in-out duration-300 px-[8px] py-[13px] border-t border-[#9DC4FA]"
                        : "bg-[#F2F6FC] hover:bg-[#CBDFFC] ease-in-out duration-300 px-[8px] py-[13px]"
                    }
                    onClick={() => {
                      setOptionActive(false);
                      setSelectedText(option);
                      setCheckInfo({ ...checkInfo, writer: selectedText });
                    }}
                  >
                    {option}
                  </li>
                ))}
          </ul>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default SelectAppUse;
