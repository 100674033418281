import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useMainContext } from "../../../../contexts/Provider";
/*component*/

import common, { api } from "../../../../data/common";
const EachMessage = () => {
  const { loginInput } = useMainContext();
  const closeicon = "/images/mo/closebasic.svg";
  const defaultimage = "/images/mo/defaultimage.svg";
  const messageState = useLocation().state;
  const navigate = useNavigate();
  const [getMessage, setGetMessage] = useState([]);
  const [admin, setAdmin] = useState([]);
  const href = new URL(window.location.href);
  const urlParams = href.searchParams;
  const tokenMsgID = urlParams.get("messageID");
  const messageID = tokenMsgID ? tokenMsgID : messageState.id;
  let codes = getMessage.content;

  useEffect(() => {
    let pageOpen = false;
    const getMessage = () => {
      api
        .get(common.baseURL + "message/id/" + messageID)
        .then((res) => {
          console.log(res);
          if (res.data.ok === true) {
            setGetMessage(res.data.m);
            setAdmin(res.data.m.admin);
          }
        })
        .catch((error) => console.log(error));
    };
    if (!pageOpen) {
      if (
        loginInput.id === null ||
        loginInput.id === undefined ||
        loginInput.id === ""
      ) {
        navigate("/main", { replace: true });
      } else {
        getMessage();
      }
    }
    return () => {
      pageOpen = true;
    };
  }, [loginInput.id, messageID, navigate]);
  console.log(admin.profileUrl);
  return (
    <div className="h-screen bg-[#FAFCFF]">
      <div className="flex justify-between items-center border-b subtitle_1_400">
        <span className="w-[48px] h-[48px]"></span>
        <span>쪽지 확인</span>
        <img
          src={closeicon}
          alt="basicclose"
          className="cursor-pointer"
          onClick={() => {
            navigate(-1);
          }}
        />
      </div>
      <div className="pt-[24px] px-[24px]">
        <div className="border-b pb-[16px] flex ">
          <img
            src={
              admin && admin.profileUrl !== undefined
                ? common.networkImg + encodeURIComponent(admin.profileUrl)
                : defaultimage
            }
            alt="adminprofile"
            className="w-[40px] h-[40px] rounded-full"
          />
          <div className="pl-[16px]">
            <span className="block subtitle_2">{admin.name}</span>
            <span className="block text-[#676C76] caption_2">
              {dayjs(getMessage.createdAt).format("YYYY.MM.DD")}
            </span>
          </div>
        </div>
        <div
          className="py-[16px] body_1_400"
          dangerouslySetInnerHTML={{ __html: codes }}
        ></div>
      </div>
    </div>
  );
};

export default EachMessage;
