import React from "react";
const AlergyDamage = ({ btn2, bigTitle }) => {
  const image1 = "/images/mo/jangrucont/피부합병증1.jpg";
  return (
    <div className="w-full flex flex-col  px-[16px] body_1_400 wordbreak-keepall">
      <div className="pt-[72px] ">
        <div className="flex justify-center flex-col items-center w-full border-b">
          <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
          <h1 className="h2_800 pt-[4px] pb-[24px]">{btn2}</h1>
        </div>

        <div className="w-full pt-[24px] body_1_400_jangrucont">
          <div>
            <div className="subtitle_1_700 pb-[8px]">1) 정의</div>
            <div className="w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
              <img src={image1} alt="image1" className="h-full" />
            </div>
            <div className="pt-[8px]">
              <span className="inline-block">
                피부 보호판 등 제품에 대한 과민 반응으로 제품이 닿는 부위가
                발적과 가려움증이 나타나는 것을 말합니다.
              </span>
            </div>
          </div>
          <div className="pt-[32px]">
            <div className="subtitle_1_700 pb-[8px]">2) 관리방법</div>
            <div className="pt-[8px]">
              <div className="flex  ">
                <span className="inline-block">1. &nbsp;</span>
                <div>
                  알러지를 일으키는 원인 제품이 무엇인지 파악하여 그 제품의
                  사용을 피합니다.
                </div>
              </div>
              <div className="flex justify-end  ">
                <span className="inline-block">2. &nbsp;</span>
                <div>
                  가려움 등의 증상이 심할 때는 병원을 방문하여 스테로이드 연고
                  등을 처방 받아 사용합니다.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlergyDamage;
