import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const Check9 = ({ checkInfo, onChangeCheck }) => {
  console.log(checkInfo.imageURL);
  const navigate = useNavigate();
  const sectionRef = useRef();
  const handleClick = () => {
    sectionRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };
  const goCheck8 = () => {
    navigate(`/check/8`);
  };
  const goCheck10 = () => {
    navigate(`/check/10`);
  };
  console.log(checkInfo.writer, ">>>>writer");
  return (
    <>
      <div className="pt-[94px] px-[24px]">
        <h1 className="h3_800 pb-[80px] wordbreak-keepall">
          장루관리를 하면서 궁금한 점을 작성해 주세요.
        </h1>
        <textarea
          type="text"
          className="surveycheck mb-4 border rounded w-full h-[200px] px-6 py-3 cursor-pointer bg-[#F2F6FC] border-[#9DC4FA] active:bg-[#CBDFFC] focus:outline-none body_1_600"
          placeholder="장루 관리, 식사관리 등 장루와 관련된 궁금한 내용을 기록해 주세요. (200자 이내) 궁금한 점이 없는 경우에는 없음이라고 기록해 주세요."
          name="question"
          value={checkInfo.question}
          onChange={onChangeCheck}
          onClick={handleClick}
        />
        <div className="h-[120px]" ref={sectionRef}></div>
        <div className="fixed  bottom-0 left-0 w-full font-bold flex ">
          <div
            className="border w-2/5 py-[20px] flex justify-center items-center cursor-pointer 
            bg-[#FAFCFF] active:bg-[#F2F6FC] border-[#679DEE] active:border-[#18428E] text-[#679DEE] button_700 active:text-[#18428E] ease-in-out duration-300"
            onClick={goCheck8}
          >
            이전
          </div>
          {checkInfo.question !== "" ? (
            <div
              className=" w-3/5 py-[20px]  flex justify-center items-center cursor-pointer bg-[#1B5EDC] text-white active:bg-[#18428E] button_700 ease-in-out duration-300"
              onClick={goCheck10}
            >
              마치기
            </div>
          ) : (
            <div className=" w-3/5 py-[20px]  flex justify-center items-center bg-[#9DC4FA] text-white button_700 ">
              마치기
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Check9;
