// 미 신청자 수
const UnappliedPerson = ({ data }) => {
  const unapplied = "/images/admin/header_dash/unapplied.svg";
  return (
    <div className="flex h-full justify-center p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] text-[#343B48] ">
      <div className="flex flex-col justify-between w-full h-full">
        <div className="flex gap-x-[7px]">
          <img src={unapplied} alt="unapplied" className="w-[20px] h-[20px]" />
          <label className="text-[18px] font-extrabold leading-[22px]">
            미 신청자 수
          </label>
        </div>
        <p className="flex justify-end items-center w-full text-lg leading-[22px] font-semibold">
          <span className="text-2xl font-extrabold mr-1">{data[3].XNum}</span>건
        </p>
      </div>
    </div>
  );
};

export default UnappliedPerson;
