import React from "react";

const Style2B = ({ title2, bigTitle }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400_jangrucont">
      <div className="flex justify-center flex-col items-center w-full border-b py-[24px]">
        <span className="text-[#679DEE] caption_1_400 ">{title2}</span>
        <h1 className="h2_800 pt-[4px] ">{bigTitle}</h1>
      </div>
      <div className="w-full pt-[30px]">
        <div className="subtitle_1_700">1) 교환 시간</div>
        <div className="pt-[8px]">
          <span className="inline-block">
            장루제품 교환은 장루의 활동이 활발하지 않은 자기 전이나 식사를 하지
            않는 시간대를 선택하여 교환합니다.
          </span>
        </div>
        <div className="subtitle_1_700 pt-[32px]">2) 교환 주기</div>
        <div className="flex pt-[8px]">
          <span className="inline-block">1. &nbsp;</span>
          <span className="inline-block">
            장루제품 교환 주기는 장루나 배출되는 배출물에 따라 다릅니다.
          </span>
        </div>
        <div>2. 개인마다 적절한 교체시기를 찾도록 합니다.</div>
        <div className="flex ">
          <span className="inline-block">3.&nbsp;</span>
          <span className="inline-block">
            피부 보호판 밑으로 배설물이 흘러내리거나 따갑고 화끈거리는 증상이
            있으면 즉시 새 것으로 교체합니다.
          </span>
        </div>

        <div className="subtitle_1_700 pt-[32px]">
          3) 장루 관련 제품 보관 및 사용법
        </div>
        <div className="flex pt-[8px]">
          <span className="inline-block">1.&nbsp;</span>

          <span className="inline-block">
            습하지 않고 환기가 잘 되는 상온(10-25°C)에서 보관하세요.
          </span>
        </div>

        <div>2. 여름철 자동차 트렁크내에 보관하지 마세요.</div>
        <div>3. 피부 보호판은 박스에 담긴 채로 보관하세요.</div>

        <div className="h-[80px]"></div>
      </div>
    </div>
  );
};

export default Style2B;
