import React from "react";
const PoopDamage = ({ bigTitle, btn1 }) => {
  const image5 = "/images/mo/jangrucont/피부합병증5.jpg";
  const image6 = "/images/mo/jangrucont/피부합병증6.jpg";
  return (
    <div className="w-full flex flex-col  px-[16px] wordbreak-keepall">
      <div className="pt-[72px] ">
        <div className="flex justify-center flex-col items-center w-full border-b">
          <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
          <h1 className="h2_800 pt-[4px] pb-[24px]">{btn1}</h1>
        </div>
        <div className=" w-full pt-[24px]">
          <div>
            <div className="subtitle_1_700 pb-[8px]">1) 정의</div>
            <div className="pt-[8px]">
              대변이 피부에 접촉되어 피부가 벗겨지고 통증이 생긴 상태를
              말합니다.
            </div>
          </div>
          <div className="pt-[32px] body_1_400_jangrucont">
            <div className="subtitle_1_700 pb-[8px]">2) 관리방법</div>

            <div className="pt-[8px]">
              <div className="flex  ">
                <span className="inline-block">1. &nbsp;</span>
                <div>피부가 손상된 원인을 알아내어 교정합니다.</div>
              </div>
              <div className="flex justify-end  ">
                <span className="inline-block">2. &nbsp;</span>
                <div>
                  피부 보호판을 너무 크게 오리지 않도록 하며 장루 크기에 맞춰서
                  적용합니다.
                </div>
              </div>
              <div className="flex justify-end  ">
                <span className="inline-block">3. &nbsp;</span>
                <div>
                  장루용 파우더를 한 후 피부보호 스프레이 적용을 한 후 링을
                  사용합니다.
                </div>
              </div>
              <div className="flex  ">
                <span className="inline-block">4.&nbsp;</span>
                <div>피부 보호판이 자주 떨어지면 장루간호사와 상담합니다.</div>
              </div>
            </div>
          </div>
          <div className="mt-[8px] w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
            <img src={image5} alt="image5" className="h-full" />
          </div>
          <div className="mt-[8px] w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
            <img src={image6} alt="image6" className="h-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoopDamage;
