import { useState, useRef, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useMainContext } from "../../../../contexts/Provider";
import commonUrl, { api } from "../../../../data/common";

import { toast, ToastContainer } from "react-toastify";
import {
  toastCommonProps,
  findToastPosition,
} from "../../admin_components/Toast";
import ExplainPolicy from "./components/ExplainPolicyPopup";
import { handlingError } from "../../admin_components/Error";

const Login = () => {
  const stoma_logo = "/images/admin/header_dash/herings_logo.svg";
  const paging_right_arrow = "/images/admin/paging_right_arrow.svg";
  const navigate = useNavigate();
  const { adminLogin, setAdminLogin, setBelongs } = useMainContext();
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState({ email: "", adminPwd: "" }); // 로그인 입력정보
  const [policyOpen, setPolicyOpen] = useState(false); // 이용약관 설명 popup open 여부
  const [clickPolicy, setClickPolicy] = useState(null); // 이용약관, 개인정보 버튼 중 뭐 눌렀는지
  const idInputBox = useRef(); // 아이디 input ref

  // input 박스내용 변경
  const change = (e) => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
  };

  // 작성된 로그인 정보 삭제
  const clean = () => {
    setAdminLogin({ ...adminLogin, email: "", password: "" });
  };

  // Enter 키 연결
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      submit();
    }
  };

  // 확인 버튼 클릭시
  const submit = () => {
    // axios
    async function fetchData() {
      setLoading(true);
      const res = await api({
        method: "post",
        url: commonUrl.admin + "login",
        data: {
          email: login.email.trim(),
          adminPwd: login.adminPwd,
        },
      }).catch((error) => {
        handlingError(
          error,
          "top-center",
          "toast_common toast__login rounded-[48px]"
        );
      });
      console.log(res.data, ">>> Axios admin/login");
      setLoading(false);

      // 로그인 성공
      if (res.data.ok) {
        // belong Axios
        const response = await api
          .get(commonUrl.baseURL + "belong")
          .catch((error) => {
            handlingError(
              error,
              "top-center",
              "toast_common toast__login rounded-[48px]"
            );
          });
        // belong 데이터 axios 성공시
        if (response.data.ok) {
          console.log(response.data, ">>> belong");
          const newBelong = response.data.belongList.map((el, idx) => {
            return { id: el.id, name: el.name };
          });
          setBelongs(newBelong);
        }
        // belong 데이터 axios 실패시
        else {
          toast(
            <p>정보를 불러오지 못했습니다.</p>,
            toastCommonProps(
              2000,
              "top-center",
              "toast_common toast__login rounded-[48px]"
            )
          );
          return;
        }

        // 로그인 정보 저장하기
        const { id, name, phone, role, power, belong, profileURL, agree } =
          res.data.admin;
        const newData = {
          id: id,
          name: name,
          email: login.email,
          phone: phone,
          belong: belong.id,
          belongName: belong.name,
          role: role,
          power: power,
          profileURL: profileURL,
        };

        // 정보 동의 알림 창 보여줘야하는 지 확인
        if (!agree) {
          setAdminLogin({ ...newData, isLoggedIn: false });
          navigate("/admin/agree");
        } else {
          setAdminLogin({ ...newData, isLoggedIn: true });
          navigate("/admin/main/dashboard");
        }
      }
      // 로그인 정보 잘못입력
      else {
        toast(
          <p>일치하는 정보가 없습니다.</p>,
          toastCommonProps(
            2000,
            "top-center",
            "toast_common toast__login rounded-[48px]"
          )
        );
        setTimeout(() => {
          setLogin({ email: "", adminPwd: "" });
          idInputBox.current.focus();
        }, [200]);
      }
    }
    fetchData();
  };

  // 정책 보기 버튼들 중 하나 눌렀을 때 (clickPlicy가 변한 다음에 실행되야함)
  useEffect(() => {
    if (clickPolicy) {
      setPolicyOpen(true);
    }
  }, [clickPolicy]);

  if (adminLogin.isLoggedIn) {
    return <Navigate to="/admin/main/dashboard" />;
  } else
    return (
      <>
        <style jsx="true">{`
          .Toastify__toast {
            top: ${findToastPosition("top") + 100}px;
            margin-left: 35px;
          }
          .seviceBtn:hover img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(90%)
              saturate(0%) hue-rotate(308deg) brightness(103%) contrast(103%);
          }
          .seviceBtn img {
            filter: brightness(0) saturate(100%) invert(25%) sepia(94%)
              saturate(2120%) hue-rotate(213deg) brightness(89%) contrast(93%);
          }
        `}</style>
        {/* 이용약관 설명 popup */}
        <ExplainPolicy
          policyOpen={policyOpen}
          setPolicyOpen={setPolicyOpen}
          clickPolicy={clickPolicy}
        />
        <div className="admin flex justify-center h-screen w-full min-w-[870px] bg-[#4472c4]">
          <ToastContainer />
          <div className="flex flex-col items-center justify-between w-full">
            <div className="h-[50px] w-full"></div>
            {/* 로그인 창 */}
            <div className="flex flex-col items-between">
              <label className="flex justify-start mb-1 ml-1 text-2xl font-extrabold text-white">
                OstoCare admin
              </label>
              <div className=" w-[550px] h-[430px] bg-white drop-shadow-md rounded-sm flex justify-center items-center">
                <div className="w-full px-[50px]">
                  <h3 className="flex justify-center text-center font-bold text-xl mt-[15px] mb-[50px] text-[#1B5EDC]">
                    로그인
                  </h3>
                  {/* 아이디 & 비밀번호 */}
                  <div className="flex items-center border-b">
                    <label className="w-1/5 font-semibold text-[#1B5EDC]">
                      아이디
                    </label>
                    <input
                      className="w-4/5 py-[10px] focus:outline-none"
                      type="text"
                      name="email"
                      value={login.email}
                      onChange={change}
                      onKeyPress={onKeyPress}
                      autoFocus
                      ref={idInputBox}
                    />
                  </div>
                  <div className="flex items-center border-b mt-[18px]">
                    <label className="w-1/5 font-semibold text-[#1B5EDC] ">
                      비밀번호
                    </label>
                    <input
                      className="w-4/5 py-[10px] focus:outline-none"
                      type="password"
                      name="adminPwd"
                      value={login.adminPwd}
                      onChange={change}
                      onKeyPress={onKeyPress}
                    />
                  </div>

                  {/* 자동 로그인, 아이디 & 비번 찾기 */}
                  <div className="flex justify-end w-full mt-[20px] items-center">
                    <div className="flex gap-x-[15px]">
                      <Link to={"/admin/findId"} onClick={clean}>
                        <button className="text-sm leading-5 border-b">
                          아이디 찾기
                        </button>
                      </Link>
                      <Link to={"/admin/findPwd"} onClick={clean}>
                        <button className="text-sm leading-5 border-b">
                          비밀번호 찾기
                        </button>
                      </Link>
                    </div>
                  </div>
                  <button
                    className="toast_standard flex justify-center items-center h-[60px] w-full py-[16px] bg-[#679dee] hover:bg-[#1B5EDC] ease-in-out duration-300 rounded-sm text-white text-xl font-semibold mt-[65px]"
                    onClick={submit}
                  >
                    {loading ? (
                      <svg
                        className="animate-spin h-[20px] w-[20px] my-[2px] rounded-full border-2 border-white border-t-[#679dee] border-r-[#679dee]"
                        viewBox="0 0 24 24"
                      />
                    ) : (
                      "로그인"
                    )}
                  </button>
                </div>
              </div>
              <div className="flex justify-center mt-3 text-sm text-white">
                이 페이지는 PC에 최적화되어 있습니다.
              </div>
            </div>
            {/* 서비스 신청 */}
            <div className="h-[50px] w-full"></div>
            <div className="flex justify-center w-full h-[100px] bg-[#FAFBFC]">
              <div className="flex justify-between items-center h-full w-[870px] min-w-[870px] px-4">
                <div className="flex items-center gap-x-3">
                  <img src={stoma_logo} alt="pocketboy" className="h-[50px] " />
                  <div className="flex items-center gap-x-3">
                    <div className="flex justify-start font-extrabold text-[#1B5EDC] text-2xl mb-1 ml-1 whitespace-nowrap">
                      OstoCare
                    </div>
                    <div className="w-full">
                      <p className="text-sm">서울시 강남구 언주로 560, 14층</p>
                      <p className="text-[10px] font-normal">
                        Copyright © 2022 HERINGS. All rights reserved.{`   `}
                        <span
                          className="cursor-pointer hover:text-[#020A1B] hover:font-bold"
                          onClick={() => {
                            setClickPolicy("service");
                          }}
                        >
                          이용약관
                        </span>
                        {`  |  `}
                        <span
                          className="cursor-pointer hover:text-[#020A1B] hover:font-bold"
                          onClick={() => {
                            setClickPolicy("personalInfo");
                          }}
                        >
                          개인정보처리방침
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                {/* 서비스 신청 버튼 */}
                <button
                  className="seviceBtn flex justify-center items-center h-[64px] w-[196] pl-[10px] pr-[0px] py-[21px] text-base text-[#1B5EDC] leading-[22px] font-[700] border border-[#1B5EDC] rounded-[1px] hover:bg-[#1B5EDC] hover:text-[#FFFFFF]"
                  onClick={() => {
                    navigate("/admin/serviceRegister");
                  }}
                >
                  OstoCare 서비스 신청
                  <img
                    src={paging_right_arrow}
                    alt="paging_right_arrow"
                    className="h-[35px]"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default Login;
