import React, { useState } from "react";
import common, { api } from "../../../../../data/common";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
/*component*/
import CenterModal from "../../../mobilecomponent/CenterModal";

const DeletePostModal = ({ postingid, setDeletemo, setPostedit }) => {
  const navigate = useNavigate();
  const [transbg, setTransBg] = useState(false);
  console.log(postingid);
  const deletePost = () => {
    setTransBg(true);
    api
      .post(common.baseURL + "community/posting/delete/" + postingid)
      .then((res) => {
        console.log(res);
        if (res.data.ok === true) {
          setDeletemo(false);
          setPostedit(false);
          toast(`삭제되었습니다.`, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: "transparent toastbottom",
          });
          let start = setTimeout(() => {
            navigate("/community");
            setTransBg(false);
          }, 1000);
          return () => {
            clearTimeout(start);
          };
        } else {
          alert("올바르지 않은 삭제 경로입니다");
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <>
      {transbg ? (
        <div className="fixed top-0 left-0 z-20 w-full h-full bg-transparent"></div>
      ) : (
        ""
      )}

      <CenterModal>
        <span className="pb-[24px] block text-center">
          게시글을 삭제하시겠습니까?
        </span>
        <div className="flex w-full gap-x-2">
          <span
            className="inine-block w-1/2 border border-[#1B5EDC] bg-[#FAFCFF] text-[#1B5EDC] hover:bg-[#F2F6FC] hover:border-[#18428E] hover:text-[#18428E] ease-in-out duration-300 p-[16px] text-center rounded-sm cursor-pointer"
            onClick={deletePost}
          >
            삭제
          </span>
          <span
            className="inine-block w-1/2 border border-[#1B5EDC] bg-[#FAFCFF] text-[#1B5EDC] hover:bg-[#F2F6FC] hover:border-[#18428E] hover:text-[#18428E] ease-in-out duration-300 p-[16px] text-center rounded-sm cursor-pointer"
            onClick={() => {
              setDeletemo(false);
              setPostedit(false);
            }}
          >
            취소
          </span>
        </div>
      </CenterModal>
    </>
  );
};

export default DeletePostModal;
