import React, { useState, useEffect, useRef } from "react";
import { useMainContext } from "../../../../contexts/Provider";
import { useNavigate, useLocation } from "react-router-dom";

import common, { api } from "../../../../data/common";
import { toast, ToastContainer } from "react-toastify";
/*component*/
import SelectAppUse from "./components/SelectAppUse";
import PhotoAlertModal from "../HealthDiary/component/PhotoAlertModal";
import BasicLoading from "../../mobilecomponent/BasicLoading";
import { resizeFile } from "../../../../resizeFile";
const AppUseWrite = () => {
  const {
    // setFileArray,
    // fileArray,
    // files,
    // setFiles,
    // selectedImages,
    // setSelectedImages,
    loginInput,
    selectedText,
    // mobileHistory,
  } = useMainContext();
  const [photoAlert, setPhotoAlert] = useState(false);
  const camera = "/images/mo/camera.svg";
  const closeicon = "/images/mo/closebasic.svg";
  const leftarrow = "/images/mo/leftarrow.svg";
  const userPid = loginInput.id;
  const navigate = useNavigate();
  const questionId = useLocation().state;
  const options = ["서비스 문의", "장애 및 불편신고", "제안사항", "기타"];
  // const [writeInput, setWriteInput] = useState({
  //   title: "",
  //   category: "",
  //   contents: "",
  //   photoURL: [],
  //   status: 0,
  //   patient: "",
  // });
  const [contents, setContents] = useState("");
  // 기존 포스트 수정시 원래 저장되어 있던 이미지(photoURL)
  const [originImages, setOriginImages] = useState([]);
  // 새 포스트 작성 혹은 기존 포스트 수정시 새로 추가하여 저장할 이미지(미리보기용)
  const [loadedImages, setLoadedImages] = useState([]);
  // 새 포스트 작성 혹은 기존 포스트 수정시 새로 추가하여 저장할 이미지(파일)
  const [fileArray, setFileArray] = useState([]);

  const [postQuestion, setPostQuestion] = useState([]);
  const [questionURL, setQuestionURL] = useState([]);
  const [isFocused, setIsFocused] = useState("");
  const header = useRef(null);
  const input1 = useRef(null);
  const input2 = useRef(null);
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    // let pageOpen = false;
    const getQuestionID = () => {
      api
        .get(common.baseURL + "question/Id/" + questionId)
        .then((res) => {
          if (res.data.ok === true) {
            console.log(questionId, "questionIdquestionId");

            console.log(res.data, "res.data -> question/Id");
            setQuestionURL(res.data.question.photoURL);
            setContents(res.data.question.content);
            setOriginImages(res.data.question.photoURL);
            setPostQuestion({
              title: res.data.question.title,
              category: res.data.question.category,
              status: res.data.question.status,
              patient: loginInput.id,
              id: res.data.question.id ? res.data.question.id : null,
            });
          } else {
            console.log(questionId);
          }
        })
        .catch((error) => console.log(error));
    };
    const getQuestionPosting = async () => {
      await api
        .get(common.baseURL + "question/Id/" + questionId)
        .then((res) => {
          if (res.data.ok === true) {
            console.log("getQuestionPosting 성공");
            console.log(questionId, "questionIdquestionIdquestionId");
            setContents(res.data.question.content);
            setOriginImages(res.data.question.photoURL);
          } else {
            console.log("false??");
          }
        })
        .catch((error) => console.log(error));
    };
    // if (!pageOpen) {
    //   if (
    //     loginInput.id === null ||
    //     loginInput.id === undefined ||
    //     loginInput.id === ""
    //   ) {
    //     navigate("/main", { replace: true });
    //   } else {
    //     if (questionId) {
    //       getQuestionID();
    //     }
    //   }
    // }
    getQuestionID();
    if (!userPid) {
      navigate("/main", { replace: true });
    }
    // return () => {
    //   pageOpen = true;
    // };
    if (questionId) {
      // questionId 가 있으면 기존글 수정하는 화면이므로, 기존 포스트를 불러와서 contens 와 originImages에 넣어줌
      console.log("여기오냐");
      getQuestionPosting();
    }
  }, []);
  const onChangeWriteInput = (e) => {
    const { value, name } = e.target;
    // setPostQuestion({ ...postQuestion, [name]: value });
    // setContents(value);
    if (name === "title") {
      setPostQuestion({ ...postQuestion, [name]: value.slice(0, 20) });
    } else {
      setContents(value.slice(0, 80));
    }
  };
  // const imageHandleChange = (e) => {
  //   const activeFiles = Array.from(e.target.files);
  //   //1.미리보기 가상의 주소 만들기
  //   const fileArrayURL = activeFiles.map((file) => URL.createObjectURL(file));
  //   //2.db파일로 넘길 주소 만들기
  //   let realFile = activeFiles.map((file) => file.lastModified + file.name);
  //   //3.dataTransfer로 이전파일 + 동적으로 가져온 파일 묶어서 하나의 묶음으로 만들기=>각각 delete하기 위한 목적
  //   const dataTranster = new DataTransfer();
  //   files
  //     .concat(activeFiles)
  //     .slice(0, 5)
  //     .forEach((file) => {
  //       dataTranster.items.add(file);
  //     });
  //   document.getElementById("file").files = dataTranster.files;

  //   //files:실제 FileList, FileArray: db저장될 생성된 이미지 주소, seletedImages: 미리보기로 보여지는 가상의 주소
  //   setFiles([...new Set(Array.from(dataTranster.files))].slice(0, 5));
  //   setFileArray([...new Set(fileArray.concat(realFile))].slice(0, 5));
  //   setSelectedImages(
  //     [...new Set(selectedImages.concat(fileArrayURL))].slice(0, 5)
  //   );

  //   if ([...new Set(selectedImages.concat(fileArrayURL))].length >= 6) {
  //     setPhotoAlert(true);
  //   } else {
  //     setPhotoAlert(false);
  //   }
  // };
  const setTimeoutScroll = (e) => {
    const forScrollEl = document.querySelector(".for__scroll");
    forScrollEl.scroll(0, 0);
  };
  // const setTimeoutScroll = () => {
  //   const forScrollEl = document.querySelector(".for__scroll");
  //   forScrollEl.scroll(0, forScrollEl.clientHeight);
  //   if (forScrollEl.scrollHeight >= forScrollEl.clientHeight) {
  //     console.log("true");
  //     console.log(forScrollEl.scrollHeight, forScrollEl.clientHeight);
  //     forScrollEl.scroll(0, forScrollEl.scrollHeight);
  //   }
  //   const onScroll = () => {
  //     if (forScrollEl.scrollHeight >= forScrollEl.clientHeight) {
  //       console.log("true");
  //       forScrollEl.scroll(0, forScrollEl.scrollHeight);
  //     }
  //   };
  //   window.addEventListener("scroll", onScroll);
  // };
  // window.addEventListener("scroll", () => {
  //   alert("스크롤발생");
  // });

  const imageHandleChange = async (e) => {
    let imageArr = [];
    if (
      e.target.files?.length + originImages?.length + loadedImages?.length >
      5
    ) {
      setPhotoAlert(true);
    } else {
      setPhotoAlert(false);
      await Promise.all(
        Array.from(e.target.files).map(async (image) => {
          let reader = new FileReader();
          let resize = await resizeFile(image);
          reader.readAsDataURL(resize);
          imageArr.push(resize);
          reader.onload = () => {
            setLoadedImages((current) => [...current, reader.result]);
          };
        })
      );

      setFileArray([...fileArray, ...imageArr]);
    }
    // setTimeoutScroll();
  };

  // const deleteOrigin = (el, index) => {
  //   //기존포스팅 이미지 삭제
  //   questionURL.splice(index, 1);
  //   setQuestionURL([...questionURL]);
  // };

  // const deleteSelected = (el, index) => {
  //   //기존미리보기 이미지 삭제
  //   console.log(el);
  //   selectedImages.splice(index, 1);
  //   files.splice(index, 1);
  //   fileArray.splice(index, 1);

  //   setSelectedImages([...selectedImages]);
  //   setFiles([...files]);
  //   setFileArray([...fileArray]);
  // };
  const deletePosting = (el, index, type) => {
    if (type === "origin") {
      // 기존 글에서 불러온 사진(미리보기 포함) 삭제
      originImages.splice(index, 1);
      setOriginImages([...originImages]);
    } else {
      // 새로업로드할 사진(미리보기 포함) 삭제
      loadedImages.splice(index, 1);
      setLoadedImages([...loadedImages]);
    }
  };

  const submitData = () => {
    setSaving(true);
    const newImage = {
      category:
        selectedText === "서비스 문의"
          ? 0
          : selectedText === "장애 및 불편신고"
          ? 1
          : selectedText === "제안사항"
          ? 2
          : selectedText === "기타"
          ? 3
          : null,
      title: postQuestion.title,
      content: contents,
      photoURL: fileArray.map((file) => file.lastModified + file.name),
      status: 0,
      patient: userPid,
    };
    const editImage = {
      // id: contents.id ? contents.id : null,
      id: questionId,
      category:
        selectedText === "서비스 문의"
          ? 0
          : selectedText === "장애 및 불편신고"
          ? 1
          : selectedText === "제안사항"
          ? 2
          : selectedText === "기타"
          ? 3
          : null,
      title: postQuestion.title,
      content: contents,
      photoURL: originImages.concat(
        fileArray.map((file) => file.lastModified + file.name)
      ),
      status: 0,
      patient: userPid,
    };
    // const originImage = {
    //   id: writeInput.id ? writeInput.id : null,
    //   category:
    //     selectedText === "서비스 문의"
    //       ? 0
    //       : selectedText === "장애 및 불편신고"
    //       ? 1
    //       : selectedText === "제안사항"
    //       ? 2
    //       : selectedText === "기타"
    //       ? 3
    //       : writeInput.category,

    //   title: writeInput.title ? writeInput.title : null,
    //   content: writeInput.contents ? writeInput.contents : null,
    //   photoURL: questionURL,
    //   status: 0,
    //   patient: userPid,
    // };

    const ToastAndNavigate = ({ questionpost }) => {
      toast(`👏 저장되었습니다.`, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        limit: 1,
        className: "transparent toastbottom",
      });
      let start = setTimeout(() => {
        setSaving(false);
        navigate("/myinfo/appusecont", {
          state: questionpost.data.messageNum,
        });
      }, 2000);

      return () => {
        clearTimeout(start);
      };
    };

    const ToastAndNavigateGetPosting = () => {
      toast(`👏 저장되었습니다.`, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        limit: 1,
        className: "transparent toastbottom",
      });
      let start = setTimeout(() => {
        navigate("/myinfo/appusecont", {
          state: questionId,
        });
      }, 2000);
      return () => {
        clearTimeout(start);
      };
    };
    // if (files.length > 0) {
    //   if (!(writeInput.contents === "" || writeInput.title === "")) {
    //     //이미지 새로 저장
    //     let num = 0;
    //     for (let i = 0; i < files.length; i++) {
    //       let formData = new FormData();
    //       formData.append("file", files[i]);
    //       formData.append("name", files[i].lastModified + files[i].name);
    //       //서버에저장
    //       api.post(common.baseURL + "upload/oneNew", formData).then((awsres) => {
    //         console.log(awsres);
    //         if (awsres.data.ok === true) {
    //           num = num + 1;
    //           if (num === files.length) {
    //             api
    //               .post(common.baseURL + "question", newImage)
    //               .then((questionpost) => {
    //                 console.log(questionpost);
    //                 if (questionpost.data.ok === true) {
    //                   console.log("이미지 수정후 사진 포함 수정되었습니다.");
    //                   ToastAndNavigate({ questionpost });
    //                 }
    //               })
    //               .catch((error) => console.log(error));
    //           }
    //         }
    //       });
    //     }
    //   }

    if (!questionId && fileArray.length > 0) {
      // 새글 포스팅, 사진있는 경우
      for (let i = 0; i < fileArray.length; i++) {
        let formData = new FormData();
        formData.append("file", fileArray[i]);
        formData.append("name", fileArray[i].lastModified + fileArray[i].name);

        //서버에저장
        api.post(common.baseURL + "upload/oneNew", formData).then((awsres) => {
          console.log(awsres);
          if (awsres.data.ok === true) {
            i = i + 1;
            if (i === fileArray.length) {
              api
                .post(common.baseURL + "question", newImage)
                .then((questionpost) => {
                  console.log(questionpost);
                  if (questionpost.data.ok === true) {
                    ToastAndNavigate({ questionpost });
                  }
                })
                .catch((error) => console.log(error));
            }
          }
        });
      }
    }
    // else {
    //     //기존이미지 있었으나 삭제후 새로저장
    //     let num = 0;
    //     for (let i = 0; i < files.length; i++) {
    //       let formData = new FormData();
    //       formData.append("file", files[i]);
    //       formData.append("name", files[i].lastModified + files[i].name);
    //       //서버에저장
    //       api.post(common.baseURL + "upload/oneNew", formData).then((awsres) => {
    //         console.log(awsres);
    //         if (awsres.data.ok === true) {
    //           num = num + 1;
    //           if (num === files.length) {
    //             api
    //               .post(common.baseURL + "question", editImage)
    //               .then((questionpost) => {
    //                 console.log(questionpost);
    //                 if (questionpost.data.ok === true) {
    //                   console.log("이미지 수정후 사진 포함 수정되었습니다.");
    //                   ToastAndNavigate({ questionpost });
    //                 }
    //               })
    //               .catch((error) => console.log(error));
    //           }
    //         }
    //       });
    //     }
    //   }
    // }
    else if (!questionId && fileArray.length === 0) {
      // 새글 포스팅, 사진없는 경우
      api
        .post(common.baseURL + "question", newImage)
        .then((questionpost) => {
          console.log(questionpost);
          if (questionpost.data.ok === true) {
            ToastAndNavigate({ questionpost });
          }
        })
        .catch((error) => console.log(error));
    } else if (fileArray.length > 0) {
      // 수정후 새로 업로드할 사진이 있을 경우
      for (let i = 0; i < fileArray.length; i++) {
        let formData = new FormData();
        formData.append("file", fileArray[i]);
        formData.append("name", fileArray[i].lastModified + fileArray[i].name);

        api.post(common.baseURL + "upload/oneNew", formData).then((awsres) => {
          if (awsres.data.ok === true) {
            i = i + 1;
            if (i === fileArray.length) {
              api
                .post(common.baseURL + "question", editImage)
                .then((questionpost) => {
                  console.log(questionpost);
                  if (questionpost.data.ok === true) {
                    ToastAndNavigateGetPosting();
                  }
                })
                .catch((error) => console.log(error));
            }
          }
        });
      }
      // else {
      //   //기존 서버 저장 이미지 수정
      //   api
      //     .post(common.baseURL + "question", originImage)
      //     .then((questionpost) => {
      //       if (questionpost.data.ok === true) {
      //         console.log("이전 사진 변경 및 다른 정보변경");
      //         ToastAndNavigate({ questionpost });
      //       }
      //     })
      //     .catch((error) => console.log(error));
      // }
    } else {
      // 수정 후 새로 업로드할 사진이 없는 경우
      api
        .post(common.baseURL + "question", editImage)
        .then((questionpost) => {
          console.log(questionpost);
          if (questionpost.data.ok === true) {
            ToastAndNavigateGetPosting();
          }
        })
        .catch((error) => console.log(error));
    }
  };
  const goback = () => {
    navigate(-1);
  };

  return (
    <>
      {saving ? <BasicLoading /> : ""}

      {/* <div className="h-screen bg-[#FAFCFF] relative overflow-y-auto "> */}
      <div
        className="w-full border-b-[1px] flex justify-between items-center pl-[8px] pr-[24px] bg-[#FAFCFF] subtitle_1_400 fixed top-0 inset-x-0 z-10"
        ref={header}
      >
        <img
          className="cursor-pointer inline-block pr-[20.25px]"
          src={leftarrow}
          alt="leftarrow"
          onClick={goback}
        />
        <span className="inline-block">문의하기</span>

        {selectedText !== "문의 유형을 선택해주세요." &&
        postQuestion.title !== "" &&
        contents !== "" ? (
          <span
            className="inline-block bg-[#1B5EDC] hover:bg-[#18428E]  ease-in-out duration-300 px-[8px] py-[6px] subtitle_2 text-white rounded-full cursor-pointer"
            onClick={submitData}
          >
            저장
          </span>
        ) : (
          <span className="inline-block bg-[#9DC4FA] px-[8px] py-[6px] subtitle_2 text-white rounded-full ">
            저장
          </span>
        )}
      </div>
      <div className="p-[24px] bg-[#FAFCFF] my-10 fixed top-2 bottom-10 inset-x-0 overflow-scroll">
        <SelectAppUse options={options} questionId={questionId} />
        <input
          className="border-b w-full py-[20px] mt-[8px] bg-transparent focus:outline-none"
          placeholder="제목을 입력해주세요.(최대20자)"
          value={postQuestion.title ? postQuestion.title : ""}
          name="title"
          onChange={onChangeWriteInput}
          onMouseDown={(e) => {
            if (isFocused !== "input1") {
              setIsFocused("input1");
              e.preventDefault();
              const __tempEl__ = document.createElement("input");
              __tempEl__.style.position = "absolute";
              __tempEl__.style.height = 0;
              __tempEl__.style.opacity = 0;
              document.body.appendChild(__tempEl__);
              __tempEl__.focus();
              setTimeout(function () {
                input1.current.focus({ preventScroll: true });
                document.body.removeChild(__tempEl__);
              }, 50);
            }
          }}
          ref={input1}
        />

        <textarea
          type="text"
          className="w-full overflow-y-auto pt-[16px] bg-transparent focus:outline-none"
          placeholder="문의 내용을 입력해주세요.(최대 800자)"
          value={contents ? contents : ""}
          name="contents"
          onChange={onChangeWriteInput}
          ref={input2}
          onMouseDown={(e) => {
            if (isFocused !== "input2") {
              setIsFocused("input2");
              e.preventDefault();
              input1.current.blur();
              const __tempEl__ = document.createElement("input");
              __tempEl__.style.position = "absolute";
              __tempEl__.style.height = 0;
              __tempEl__.style.opacity = 0;
              document.body.appendChild(__tempEl__);
              __tempEl__.focus();
              setTimeout(function () {
                input2.current.focus({ preventScroll: true });
                document.body.removeChild(__tempEl__);
              }, 50);
            }
          }}
        />
        <div className="pt-[19px] flex flex-col overflow-scroll">
          {/* 작성글 수정시 출력되는 페이지 */}
          {/* {questionURL.length > 0 ?  */}
          {originImages?.map((el, index) => (
            <div
              className="mb-[8px]  flex items-end rounded-sm overflow-hidden relative "
              key={index}
            >
              <span
                className="w-[24px] h-[24px] bg-[#E5E6E7] rounded-full absolute top-[12px] right-[12px] 
                    flex justify-center items-center
                    z-1 cursor-pointer"
                onClick={() => {
                  deletePosting(el, index, `origin`);
                }}
              >
                <img src={closeicon} alt="closeicon" className="relative " />
              </span>
              <img
                src={common.networkImg + encodeURIComponent(el)}
                alt={el}
                className="w-full rounded-3xl"
              />
            </div>
          ))}
          {loadedImages?.map((el, index) => (
            <div className="mb-[8px] w-full  relative " key={index}>
              <span
                className="w-[24px] h-[24px] bg-[#E5E6E7] rounded-full absolute top-[12px] right-[12px] 
                    flex justify-center items-center
                    z-1 cursor-pointer"
                onClick={() => {
                  deletePosting(el, index, `loaded`);
                }}
              >
                <img src={closeicon} alt="closeicon" className="relative" />
              </span>
              <img src={el} alt={el} className="w-full" />
            </div>
          ))}

          {/* : ""} */}
          {/* 사진이 빈값일때 출력되는 미리보기페이지 */}
          {/* {selectedImages.length > 0
              ? selectedImages.map((el, index) => (
                  <div
                    className="mb-[8px]  flex items-end rounded-sm overflow-hidden relative "
                    key={index}
                  >
                    <span
                      className="inline-block w-[24px] h-[24px] bg-[#E5E6E7] rounded-full absolute top-[12px] right-[12px] 
                  flex justify-center items-center
                  z-1 cursor-pointer"
                      onClick={() => {
                        deleteSelected(el, index);
                      }}
                    >
                      <img src={closeicon} alt="closeicon" />
                    </span>
                    <img className="rounded-3xl" src={el} alt={el} />
                  </div>
                ))
              : ""} */}
        </div>
      </div>
      {/* <div className="w-full h-[100px]"></div> */}
      {/* {questionURL.length <= 0 ? ( */}
      <div className="fixed z-1 bottom-0 py-[24px] left-0 w-full bg-[#FAFCFF]">
        <div className="flex items-center gap-x-[8px] max-w-md mx-auto px-[24px]">
          <div className="bg-[#F1F2F3] min-w-[285px] w-[500px] p-[8px] caption_1_400 flex justify-between">
            <span className="caption_1_400">사진을 첨부할 수 있어요!</span>

            <span className="text-black caption_1_600">
              {originImages.length + loadedImages.length} / 5
            </span>
          </div>
          <input
            type="file"
            accept=".jpg,.png,.jpeg"
            multiple
            id="file"
            hidden
            onChange={imageHandleChange}
          />
          <div className="cursor-pointer label-holder">
            <label htmlFor="file" className="label">
              <div className="w-[34px] h-[34px] border border-[#999CA3] flex justify-center items-center">
                <img src={camera} alt="camera" />
              </div>
            </label>
          </div>
        </div>
      </div>
      {/* </div> */}
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        bodyClassName={() =>
          "w-[175px] p-3 rounded-full overflow-hidden bg-[#CBDFFC] text-black text-sm font-normal"
        }
      ></ToastContainer>
      {photoAlert ? <PhotoAlertModal setPhotoAlert={setPhotoAlert} /> : ""}
    </>
  );
};

export default AppUseWrite;
