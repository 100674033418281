export const QuestionData = [
  {
    id: 1,
    title: "냄새가 날까봐 걱정이예요.",
    content:
      "냄새는 장루 환자의 공통적인 문제입니다. 냄새를 줄이는 방법으로는 냄새를 심하게 유발하는 음식을 피하거나 시중에 판매되는 냄새 제거 제품 또는 냄새를 감소시키는 파우더를 사용하기도 하니 파우더 사용을 고려해보세요. 최근에는 냄새를 없애주는 특정 식품이나 약품을 사용하기도 합니다.",
    category: "일상생활",
  },
  {
    id: 2,
    title: "항문에서 물이 흘러나와요.",
    content:
      "이 증상은 정상적인 증상이며 수술 직후보다 퇴원 후 갑자기 이런 증상을 호소하시는데요. 장루 수술을 해도 남아있는 항문 조직에서 정상적으로 점액이 분비되는데, 이 점액이 모여있다가 환자분의 활동이 늘어나면서 항문으로 나올 수 있습니다. 또, 분비물이 나오려고 할 때 변이 마려운 것처럼 항문 주위가 불편하고 때로는 아랫배에 통증을 호소하기도 합니다. 그러다가 분비물이 나오고 나면 통증이나 불편함이 사라질 수 있습니다. 그러나 선홍색 피가 섞이면서 분비물이 나온다면 병원을 방문하셔야 합니다.",
    category: "증상",
  },
  {
    id: 3,
    title: "낮보다 밤에 수면 중 장루주머니가 터져요.",
    content:
      "수면 중에 장루주머니가 터지는 것을 방지하기 위해서는 취짐 전에 장루주머니를 꼭 비우세요. 또한 취침 2~3시간 전에는 간식을 드시지 마세요. 수면 중간에 몇번은 일어나서 분비물과 가스를 비웁니다.",
    category: "일상생활",
  },
  {
    id: 4,
    title: "안전벨트를 매도 되나요?",
    content:
      "안전벨트를 안 매는 것은 더 위험하므로 장루의 위치를 피해서 안전벨트를 매도록 합니다. 조그만 쿠션을 벨트와 배 사이에 놓아 장루가 눌리지 않도록 하는 것도 좋은 방법입니다.",
    category: "증상",
  },
  {
    id: 5,
    title:
      "피부보호판을 계속 같은 것으로 사용했는데 최근에 변이 주변으로 새요.",
    content:
      "체중변화로 복부의 모양이 변하여 변이 샐 수 있습니다. 이런 경우 사용하는 피부보호판을 바꾸어 주면 간단히 해결될 수 있습니다. 만약 장루가 함몰이 되었다면 주변을 눌러 장루가 튀어나올 수 있도록 도와주는 함몰형 피부보호판으로 바꿀 필요가 있습니다. 또한 만약 늘어난 체중이 문제라면 환자분이 체중을 감량하도록 해야합니다. 제품 변경은 장루간호사와 상의하세요.",
    category: "장루",
  },
  {
    id: 6,
    title: "가스를 줄이는 방법은 없나요?",
    content:
      "가스 발생을 많게 하는 음식은 제한해야 합니다. 또한 공기가 입을 통해 장내로 들어가지 않도록 껌 씹기, 흡연, 빨대 사용 등의 행위를 자제하며, 음식을 먹을 때 입을 다물고 씹고, 호흡은 가급적 코로 해보세요. 특히 대장루(결장루)의 경우 소화가 안 된 탄수화물의 박테리아 작용에 의해 가스가 생기므로 음식물을 잘 씹어 먹도록 합니다. 근본적으로 가스가 안 나오게 하는 방법은 없으므로 가스가 나올 때 손바닥이나 팔꿈치 등으로 장루를 가볍게 눌러주면 소리가 약하게 들릴 수 있습니다.",
    category: "증상",
  },
  {
    id: 7,
    title: "장루가 있을 때 목욕이나 수영을 해도 괜찮나요?",
    content:
      "물은 장루에 해롭지 않으며 비누도 장루 자체에 자극을 주지 않습니다. 또한 장 내에 압력이 있어서 물이 장루 안으로 들어가지 않습니다. 그러므로 장루를 가진 환자도 목욕이나 샤워 또는 통목욕이 가능합니다. 다만 민물이나 바다에서 수영을 할 경우에는 위생상 딱 붙는 장루주머니를 차는 것이 좋습니다. 수영을 하기 전에는 주머니를 비우고 식사는 가볍게 하세요.",
    category: "일상생활",
  },
  {
    id: 8,
    title: "장루환자도 성생활과 임신이 가능한가요?",
    content:
      "대부분의 장루 환자들은 정상적인 성생활이 가능합니다. 장루를 가진 환자들이 성생활을 하지 못하는 가장 큰 이유는 장루를 지닌 자신의 외형적인 모습 때문에 자신감이 결여되고 열등감이 생기기 때문이므로 이를 극복하기 위해서는 배우자의 따뜻한 사랑이 필요합니다. 또한, 인공항문 수술 자체가 임신을 막는 요인이 아니므로 장루 수술을 받은 여자도 임신이 가능합니다. 그러나 건강상의 다른 문제들이 있을 수 있으므로 임신을 고려할 때는 담당 의사와 반드시 상의하세요.",
    category: "일상생활",
  },
  {
    id: 9,
    title: "장루주머니와 속옷은 어떻게 관리하나요?",
    content:
      "장루에 거들이 꼭 필요한 것은 아니지만 사용한다면 가볍고 양쪽으로 늘어나거나 큰 것이 편합니다. 장루에 지나친 압박을 하지 않는 정도의 복대로 장루를 덮어 옷맵시를 내도 좋아요. 환자의 선호도에 따라 다르지만 장루주머니를 넣을 수 있는 주머니를 팬티마다 만들어 붙일 수도 있고 주머니를 붙인 허리띠를 만들어 차고 다닐수도 있습니다.",
    category: "일상생활",
  },
  {
    id: 10,
    title: "장루환자도 운동이 가능한가요?",
    content:
      "운동 가능 여부는 수술 후 경과된 시간, 육체적 상태, 이전의 능력 등에 의해 좌우됩니다. 심한 충격을 줄 수 있거나 보조기가 미끄러져 인공항문에 손상을 줄 가능성이 있는 격렬한 운동과 지나친 스트레칭이 필요한 운동을 제외한다면 특별히 구애 받을 필요없이 운동할 수 있습니다. 역도처럼 복부에 힘이 들어가는 운동은 장루에 탈장을 야기시킬 수 있으므로 삼가 해야 하며, 골프의 경우는 회음부 수술 부위가 완전히 치유된 후에 하는 것이 좋습니다.",
    category: "운동",
  },
  {
    id: 11,
    title: "하루에 수분은 어느정도 섭취해야 하나요?",
    content:
      "장루로 배출되는 배액양은 하루에 약 1,200~1,500㎖입니다. 수분 섭취가 너무 적게 되면 탈수가 되기 쉽고, 소변 양이 적어지므로 비뇨기계 결석이 생길 수 있습니다. 그러나 너무 많은 수분 섭취는 또한 배액량의 증가를 초래하여 장루 관리의 불편함을 야기할 수 있으므로 하루 1,500~2,000ml의 수분 섭취가 적당합니다.",
    category: "식생활",
  },
  {
    id: 12,
    title: "장루환자에게 필요한 식단이 있을까요?",
    content:
      "장루 조성 후에는 고단백, 고탄수화물, 고칼로리의 식이를 해야 합니다. 비타민 A, 비타민 D, 비타민 E, 비타민 K, 비타민 B12의 보충제가 필요할 수 있습니다. 또한 설사나 불편감을 일으킬 수 있는 음식물은 되도록 피하도록 하고, 장폐색을 유발할 수 있는 음식은 수술 후 6주간 정도는 삼가도록 하세요. 좀 더 자세한 내용은 오스토 케어 앱의 <장루의 모든 것 - 식생활 관리>를 참조하세요.",
    category: "식생활",
  },
  {
    id: 13,
    title: "음식을 먹을 때 잘 씹어 먹어야 하는 이유가 있나요?",
    content:
      "장루를 가진 환자가 음식을 잘 씹어 먹어야 하는 이유는 음식물이 장을 통과하는 시간이 짧으므로 음식물을 잘 씹지 않게 되면 음식물이 소화되지 않은 채 통과하게 되어 영양 섭취가 정상적으로 이루어지지 않기 때문입니다.",
    category: "식생활",
  },
  {
    id: 14,
    title: "식사시간을 조절해야 하나요?",
    content:
      "특별히 식사 시간을 조절해야 한다고 하기 보다는 보통 섭취한 음식물은 4~6시간 안에 장루로 배설되게 되므로 잠자리에 들 시간에 많은 양의 식사를 하는 것은 좋지 않습니다.",
    category: "식생활",
  },
  {
    id: 15,
    title: "장루주머니 교환 순서가 헷갈려요.",
    content:
      "장루 부착물은 정기적으로 교환하는데, 배설물이 새면 즉시 교환하여야 합니다. 회장루나 일시적으로 장루를 달고 있는 경우는 4-5일에 한번씩, 결장루(영구적 장루)의 경우에는 5-7일에 한번씩 판을 교환하도록 권장합니다. 교환시기는 장루의 활동이 적은 식사 전이 좋습니다. 좀 더 자세한 내용은 오스토 케어 앱의 <장루의 모든 것 - 장루 관리방법과 장루교체방법>을 참조하세요.",
    category: "장루",
  },
  {
    id: 16,
    title: "장루주머니 교환할 때 필요한 물품은 무엇인가요?",
    content:
      "장루주머니를 교환하기 위해서는 다음과 같은 준비물품이 필요합니다.",
    category: "장루",
  },
  {
    id: 17,
    title: "장루판 부위가 가렵고 자꾸 헐어요.",
    content:
      "대변이 되게 나오면 피부에 별로 문제가 되지 않으나, 회장루, 횡행결장루 같이 대변이 묽은 경우에는 피부 자극이 많을 수 있어요. 장루판을 너무 크게 오려 배설물에 노출되어 피부손상이 있을 경우 장루주위 피부를 깨끗하게 닦고 장루용 파우더를 뿌린 후 여분의 분말은 털어내고 피부보호용 필름을 뿌리거나 바르고 장루판을 부착합니다. 장루판을 부착하기 전 장루주위의 털을 제거하면 모낭염을 예방할 수 있으며, 장루판 제거시 리무버를 사용하면 물리적인 자극을 예방할 수 있습니다. 피부보호 연고(틈새를 막는 연고)에는 알코올이 포함되어 있으므로 피부손상이 있을 시에는 알코올이 함유되어 있지 않은 막대기형 연고를 사용하면 따가운 통증을 예방할 수 있습니다. 통증이 심하거나 장루 주변에 문제가 지속될 경우 담당 의사와 반드시 상의하세요.",
    category: "장루",
  },
  {
    id: 18,
    title: "항문을 폐쇄해서 많이 불편해요.",
    content:
      "영구적 장루를 포함하여 항문을 폐쇄하는 경우 많은 불편이 따르기 마련입니다. 항문폐쇄 환자라면 수술 직후 좌욕을 실시하면 항문 주위의 상처 치유를 촉진시키고 불편감을 경감시킬 수 있습니다. 불편감이 수년간 있을 수 있는데 이런 경우에는 스폰지로 만든 방석을 이용하거나 장시간 앉은 자세를 피하는 것이 좋아요.",
    category: "증상",
  },
  {
    id: 19,
    title: "무거운 물건을 들어도 되나요?",
    content:
      "신체적인 무리를 하거나 무거운 물건을 들거나 아랫배에 힘을 주는 것을 조심해야 합니다. 복압을 상승시키는 일은 탈장을 유발할 수 있으므로 피해야 합니다.",
    category: "일상생활",
  },
  {
    id: 20,
    title: "물은 아무 물이나 마셔도 괜찮나요?",
    content:
      "마시는 물을 바꾸면 설사의 위험이 있으므로 익숙한 물을 미리 준비하거나 시판되는 생수를 마시도록 하는 것이 좋습니다.",
    category: "식생활",
  },
  {
    id: 21,
    title: "장루 주변에서 자꾸 피가 나요.",
    content:
      "장루 교체나 새는 변으로 인해 장루 주변에 염증이 생기고 피가 나는 경우가 있으나 이런 증상이 지속되거나 심해진다면 반드시 담당 의료진에게 연락을 하여 조치를 취하도록 하세요.",
    category: "증상",
  },
  {
    id: 22,
    title: "장루로 인해 사회활동에 제한받을까 두려워요.",
    content:
      "장루로 인해 사회활동에 제한을 받는 것은 신체적인 제한보다 정신적인 두려움 때문입니다. 그래서 의료진과 다른 경험자들의 조언을 받으며 긍정적인 마음으로 새로운 삶의 방식에 적응해 가는 것이 바람직합니다. ",
    // 오스토 케어 앱의 <커뮤니티>에서 다른 장루환자들과 소통하며 다양한 경험을 공유하며 용기를 가져보세요!
    category: "일상생활",
  },
  {
    id: 23,
    title: "장루는 왜 붉은색인가요?",
    content:
      "장루는 많은 모세혈관이 분포하여 육안으로 보기에 붉은색을 띱니다. 따라서 적은 마찰에도 출혈이 있을 수 있으므로 이럴 땐 출혈 부위를 거즈나 휴지 등으로 5분 정도 눌러 지혈을 해주세요. 만약 출혈이 멈추지 않는다면 병원에 방문하시기 바랍니다.",
    category: "장루",
  },
  {
    id: 24,
    title: "장루로 인한 장애 등록 절차는 어떻게 되나요?",
    content:
      "장애인으로 등록하고자 하는 사람은 동행정복지센터를 방문하여 장애인등록 및 서비스신청서 작성하여 제출하여야 합니다.(장애인등록 신청은 본인이 하는 것을 원칙으로 하되, 18세 미만의 아동과 거동이 불가능한 경우 등 본인이 신청하기 어려운 경우에는 보호자가 신청을 대행할 수 있음) 동행정복지센터에서 장애등급심사 구비서류를 신청자에게 교부하면 신청자는 의료기관의 전문의로부터 장애등급심사 구비서류에 기록된 대로 장애진단 및 검사를 통해 장애진단서와 장애상태 확인을 위한 장애유형별 검사자료, 진료기록지 등을 반드시 함께 발급 받아 주소지 동행정복지센터에 제출하여 장애등록을 합니다.(의료기관 우선 방문하여 진단서 제출가능, 이 경우 장애진단일로 부터 30일 이내에 구비서류를 관할 행정복지센터 제출하여야 함)",
    category: "혜택",
  },
  {
    id: 25,
    title: "장루를 평생 갖고 살아야 하는 경우와 아닌 경우를 알고 싶어요.",
    content:
      "보통 항문과 그 주위를 제거해야 하는 경우나 대장 전체를 절제했을 경우 평생 장루를 갖고 살아야 합니다. 반대로 일시적으로 장루를 조성한 경우는 장의 일부를 제거하였을 때 변이 장 밖으로 새는 경우를 막기 위해 일시적으로 장루를 만든 뒤 회복이 되면 다시 복원술을 하는 경우가 있습니다. 정확한 정보는 담당 의료진에게 문의해주세요.",
    category: "장루",
  },
  {
    id: 26,
    title:
      "장루주위 피부를 청결히 하려고 소독제로 닦으려는데 알콜제나 과산화수소 용액으로 닦아도 되나요?",
    content:
      "상처가 없는 경우에는 소독제가 필요하지 않으며 오히려 피부에 자극이 될 수 있으므로 생리식염수나 수돗물로 닦는 것이 좋습니다. 단, 잔여물이 남지 않도록 청결하게 유지해주시고 피부가 건조된 후 피부보호판을 부착합니다.",
    category: "장루",
  },
  {
    id: 27,
    title: "항문이 없는데도 배변과 가스가 나오는 느낌이 항문 주위에 있어요.",
    content:
      "항문을 절제한 후에도 수술 전의 상태처럼 배변이나 가스의 배출이 일어날 것 같은 느낌이 있을 수 있는데 이를 환상직장 또는 환상항문이라고 합니다. 이것은 사지가 절단된 후에도 발끝에 통증이나 저린감을 호소하는 것과 같습니다. 이러한 증세는 이상증상이 아니며, 가끔씩 미지근한 물을 이용한 좌욕이 통증을 완화시키고 해소하는데 도움을 줍니다.",
    category: "장루",
  },
  {
    id: 28,
    title: "배에 가스가 차는데 변이 8시간 동안 전혀 나오지 않아요.",
    content:
      "평소 변비가 생기지 않도록 물을 하루에 8-10잔씩 섭취를 하고 야채와 과일도 적정량 섭취해야 합니다. 배에 가스가 차있으나 배출이 잘 되지 않는 경우에는 배를 따뜻하게 해주고 부드럽게 시계방향으로 배를 쓸어 줍니다. 그래도 가스가 나오지 않는 경우에는 손에 비닐 장갑을 착용하고 새끼 손가락에 식용유를 바르고 장루에 살짝 집어 넣어 돌려 줍니다. 그렇게 해도 가스가 나오지 않고 변이 나오지 않으면 병원으로 가시는 것이 좋습니다.",
    category: "장루",
  },
  {
    id: 29,
    title: "아이스크림이나 생선회, 탄산음료를 먹으면 안되나요?",
    content:
      "수술 초기에 찬 음식이나 날 음식을 드시면 설사를 일으킬 수 있습니다. 그러므로 가능한 익힌 음식이나 따뜻한 음식을 섭취하는 것이 합병증 예방에 도움이 됩니다. 탄산음료를 드시면 가스가 많이 차게 되므로 삼가하시거나 적당량을 섭취하시는 것이 좋습니다.",
    category: "식생활",
  },
  {
    id: 30,
    title: "콘텐츠 출처 안내",
    content:
      "자주 묻는 질문에 제공되는 내용은 아래의 저작물을 참고하여 작성되었습니다.",
    category: "출처",
  },
];
