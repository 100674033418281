import React from "react";
import { useNavigate } from "react-router-dom";

const InnertextModalPass = ({ children, url, StomaManageText }) => {
  const navigate = useNavigate();

  const onClickGoMore = () => {
    const findCont = StomaManageText.filter((el) => el.title === children);
    navigate("/more", {
      state: {
        title: findCont[0].title,
        title2: findCont[0].title2,
        content: findCont[0].cont,
        conttext1: findCont[0].conttext1,
        conttext2: findCont[0].conttext2,
        svgurl: findCont[0].svgurl,
        btn1: findCont[0].btn1,
        btn2: findCont[0].btn2,
        btn3: findCont[0].btn3,
        btn4: findCont[0].btn4,
        btn5: findCont[0].btn5,
        btn6: findCont[0].btn6,
        video: findCont[0].video,
        bigTitle: findCont[0].bigTitle,
      },
    });
  };
  return (
    <div
      className="rounded bg-[#f5f6f7] hover:bg-[#e5e6e7] flex flex-col justify-center items-center gap-1 caption_1_600 text-[#676C76] cursor-pointer h-24"
      onClick={onClickGoMore}
    >
      <div className="flex items-center w-12 h-12">
        <img src={url} alt={children} className="m-auto" />
      </div>
      <span className="caption_1_600">{children}</span>
    </div>
  );
};

export default InnertextModalPass;
