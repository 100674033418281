import React from "react";
import Header2 from "../../admin_components/Header/Header2";
import NewsAddComponent from "./component/NewsAddComponent";
const Write = () => {
  return (
    <Header2
      title={[
        { title: "새소식", url: "/admin/main/news" },
        {
          title: "새소식 등록",
          url: 0,
        },
      ]}
    >
      <div className="w-full h-full ">
        <div className="px-[20px] py-[70px]">
          <NewsAddComponent />
        </div>
      </div>
    </Header2>
  );
};

export default Write;
