import React from "react";

import ShowSelectedImage from "./ShowSelectedImage";

const ReportDiaryImages = ({ diarydata }) => {
  return (
    <>
      <div className="result">
        {diarydata.imageURL.length >= 5 ? (
          <>
            <div className=" flex gap-x-[8px] ">
              {diarydata.imageURL.slice(0, 3).map((el, index) => (
                <ShowSelectedImage key={index} index={index} el={el} />
              ))}
            </div>
            <div className="flex gap-x-[8px] pt-[8px]">
              {diarydata.imageURL.slice(-2).map((el, index) => (
                <ShowSelectedImage key={index} index={index} el={el} />
              ))}
            </div>
          </>
        ) : (
          <div className="flex gap-x-[8px]">
            {diarydata.imageURL.map((el, index) => (
              <ShowSelectedImage key={index} index={index} el={el} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ReportDiaryImages;
