import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMainContext } from "../../../../contexts/Provider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import common, { api } from "../../../../data/common";
/*component*/
import MainIcons from "../../mobilecomponent/MainIcons";
import LogoutModal from "./components/LogoutModal";
import ManageInfo from "./components/ManageInfo";
import UseTerms from "./components/UseTerms";
import Notification from "./components/Notification";
import ChangePass from "./components/ChangePass";
import PersonalInformation from "./components/PersonalInformation";

const Myinfo = () => {
  const navigate = useNavigate();
  const { loginInput, surveyInfo } = useMainContext();
  const [logoutModal, setLogoutModal] = useState(false);
  const [manageInfo, setManageInfo] = useState(false);
  const [useTerms, setUseTerms] = useState(false);
  const [personalInfo, setPersonalInfo] = useState(false);
  const [notification, setNotification] = useState(false);
  const [changePass, setChangePass] = useState(false);
  const [myinfoImage, setMyinfoImage] = useState();
  const [myinfo, setMyinfo] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [alarmList, setAlarmList] = useState([]);

  const pen = "/images/mo/pen.svg";
  const blue_person = "/images/mo/blue_person.svg";
  const blue_bell = "/images/mo/blue_bell.svg";
  const blue_message = "/images/mo/blue_message.svg";
  const righticon = "/images/mo/right.svg";
  const defaultimage = "/images/mo/defaultimage.svg";
  const showLogout = () => {
    setLogoutModal(!logoutModal);
  };
  const showManageInfo = () => {
    setManageInfo(!manageInfo);
  };
  const showUseTerms = () => {
    setUseTerms(!useTerms);
  };
  const showPersonalInfo = () => {
    setPersonalInfo(!personalInfo);
  };
  const showNotification = () => {
    setNotification(!notification);
  };
  const showChangePass = () => {
    setChangePass(!changePass);
  };
  const goDataedit = () => {
    navigate("/myinfo/dataedit");
  };
  const goMainMessage = () => {
    navigate("/main/message");
  };
  const goAppuse = () => {
    navigate("/myinfo/appuse");
  };
  const goNotice = () => {
    navigate("/myinfo/notice");
  };
  useEffect(() => {
    let pageOpen = false;
    const getPatientId = () => {
      api.get(common.baseURL + "patient/Id/" + loginInput.id).then((res) => {
        if (res.data.ok === true) {
          setMyinfoImage(res.data.patient.profileURL);
          setMyinfo(res.data.patient);
        }
      });
    };
    const getNews = () => {
      api.get(common.baseURL + "news/pid/" + loginInput.id).then((res) => {
        if (res.data.ok === true) {
          const filternewsList = res.data.newsReadList.filter(
            (list) => list.read === 0
          );
          setNewsList(filternewsList);
        }
      });
    };
    const getPatientAlarm = () => {
      api.get(common.baseURL + "alarm/pid/" + loginInput.id).then((res) => {
        if (res.data.ok === true) {
          const filterAlarmList = res.data.alarmList.filter(
            (list) => list.read === 0
          );
          setAlarmList(filterAlarmList);
        }
      });
    };
    if (!pageOpen) {
      if (
        loginInput.id === null ||
        loginInput.id === undefined ||
        loginInput.id === ""
      ) {
        navigate("/main", { replace: true });
      } else {
        getPatientId();
        getNews();
        getPatientAlarm();
      }
    }
    return () => {
      pageOpen = true;
    };
  }, [loginInput.id, navigate]);

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        bodyClassName={() =>
          "w-[175px] p-3 rounded-full overflow-hidden bg-[#CBDFFC] text-black text-sm font-normal"
        }
      ></ToastContainer>
      {/* 내정보관리 */}
      {manageInfo ? <ManageInfo showManageInfo={showManageInfo} /> : ""}
      {/* 이용약관 */}
      {useTerms ? <UseTerms showUseTerms={showUseTerms} /> : ""}
      {/* 개인정보취급방침 */}
      {personalInfo ? (
        <PersonalInformation showPersonalInfo={showPersonalInfo} />
      ) : (
        ""
      )}
      {/* 알림설정 */}
      {notification ? <Notification showNotification={showNotification} /> : ""}
      {/* 비밀번호변경 */}
      {changePass ? <ChangePass showChangePass={showChangePass} /> : ""}
      {/* 로그아웃 */}
      {logoutModal ? <LogoutModal showLogout={showLogout} /> : ""}
      <div className="h-screen bg-[#F1F2F3] relative overflow-y-auto">
        <div className="w-full p-[24px] bg-[#FAFCFF] flex justify-between items-end">
          <div className="flex items-center">
            <span className="border inline-block w-[48px] h-[48px] rounded-full overflow-hidden bg-cover bg-no-repeat bg-center">
              <img
                src={
                  myinfoImage
                    ? common.networkImg + encodeURIComponent(myinfoImage)
                    : defaultimage
                }
                alt={myinfoImage}
                className="w-full h-full"
              />
            </span>

            <div className="flex flex-col pl-4">
              <span className="inline-block h2_800">{myinfo.name}님,</span>
              <p className="text-[#676C76]">빛나는 오늘을 응원합니다!</p>
            </div>
          </div>
          <img
            className="inline-block cursor-pointer"
            src={pen}
            alt="penicon"
            onClick={goDataedit}
          />
        </div>
        <div className="mt-2 p-[24px] bg-[#FAFCFF] flex justify-between">
          <div className="flex flex-col items-center">
            <img
              src={blue_person}
              alt="blue_person"
              className="cursor-pointer"
              onClick={showManageInfo}
            />
            <span className="inline-block pt-2 subtitle_2">내 정보 관리</span>
          </div>
          <div className="flex flex-col items-center" onClick={goMainMessage}>
            <img src={blue_bell} alt="blue_bell" className="cursor-pointer" />
            <div className="flex items-center pt-2">
              <span className="subtitle_2">내 알림</span>
              <span className="ml-2 bg-[#E5E6E7] text-[#1B5EDC] rounded-full w-[20px] h-[20px] flex justify-center items-center">
                {alarmList.length > 0 ? alarmList.length : 0}
              </span>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <img
              src={blue_message}
              alt="blue_message"
              className="cursor-pointer"
              onClick={goAppuse}
            />
            <span className="inline-block pt-2 subtitle_2">1:1문의</span>
          </div>
        </div>
        <div className="mt-2 py-5 px-7 bg-[#FAFCFF] ">
          <div
            className="flex justify-between py-3 cursor-pointer items center"
            onClick={goNotice}
          >
            <div className="flex items-center">
              <span className="subtitle_2">새소식</span>

              {newsList.length > 0 ? (
                <span className="ml-2 bg-[#E5E6E7] text-[#676C76] caption_1_400 rounded-full w-[20px] h-[20px] flex justify-center items-center">
                  {newsList.length}
                </span>
              ) : (
                ""
              )}
            </div>
            <img alt="righticon" src={righticon} />
          </div>
          <div
            className="flex justify-between py-3 cursor-pointer items center"
            onClick={showUseTerms}
          >
            <div className="flex items-center">
              <span className="subtitle_2">이용약관</span>
            </div>
            <img alt="righticon" src={righticon} />
          </div>
          <div
            className="flex justify-between py-3 cursor-pointer items center"
            onClick={showPersonalInfo}
          >
            <div className="flex items-center">
              <span className="subtitle_2">개인정보 취급 방침</span>
            </div>
            <img alt="righticon" src={righticon} />
          </div>
        </div>
        <div className="mt-2 mb-20 py-5 px-7 bg-[#FAFCFF]">
          <div
            className="flex justify-between py-3 cursor-pointer items center"
            onClick={showChangePass}
          >
            <div className="flex items-center">
              <span className="subtitle_2">비밀번호 변경</span>
            </div>
            <img alt="righticon" src={righticon} />
          </div>
          <div
            className="flex justify-between py-3 cursor-pointer items center"
            onClick={showNotification}
          >
            <div className="flex items-center">
              <span className="subtitle_2">알림설정</span>
            </div>
            <img alt="righticon" src={righticon} />
          </div>
          <div
            className="flex justify-between py-3 cursor-pointer items center"
            onClick={showLogout}
          >
            <div className="flex items-center">
              <span className="subtitle_2">로그아웃</span>
            </div>
            <img alt="righticon" src={righticon} />
          </div>
        </div>
      </div>

      <MainIcons />
    </>
  );
};

export default Myinfo;
