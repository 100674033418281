import { useEffect, useState } from "react";
import { BtnBlack, BtnWhite } from "../../../admin_components/Buttons";
import commonUrl, { api } from "../../../../../data/common";
import { ToastContainer, toast } from "react-toastify";
import { useMainContext } from "../../../../../contexts/Provider";
import Header2 from "../../../admin_components/Header/Header2";
import { useLocation, useNavigate } from "react-router-dom";
import {
  toastCommonProps,
  findToastPosition,
} from "../../../admin_components/Toast";
import { Loading } from "../../../admin_components/Loading";
import dayjs from "dayjs";
import { handlingError } from "../../../admin_components/Error";

function Modify() {
  const profile = "/images/admin/profile.svg";
  const navigate = useNavigate();
  const { state } = useLocation(); // 이전 페이지에서 넘겨준 띄울 사용자 정보 id값
  const { adminLogin, belongs } = useMainContext();
  const [toastElPosition, setToastElPosition] = useState({ top: 0, left: 0 }); // toast창 위치 값
  const [loading, setLoading] = useState({ first: true, save: false }); // 첫랜더 로딩,  저장 시 로딩
  // 관리자 등록 정보
  const [manager, setManager] = useState({
    name: "",
    email: "",
    phone: "",
    belong: "",
    role: "",
    power: "",
    license: "", // 면허번호
    subjectCode: "", // 전문 과목코드
    qualification: "", // 자격번호
    status: "",
    profileURL: "",
    updatedAt: "",
    recentEnter: "",
  });

  // input 값 변경
  const change = (e) => {
    const { name, value } = e.target;

    // 들어온 값이 휴대폰 번호(자동입력 기능)
    if (name === "phone") {
      // 자동작대기 넣기 위한 정규표현식
      const regPhone1 = /^([0-9]{3})$/;
      const regPhone2 = /^([0-9]{3})-([0-9]{4})$/;

      // 삭제중인 경우
      if (manager.phone.length > value.length) {
        setManager({ ...manager, [name]: value });
      }
      // 숫자가 아니고 + "-"도 아니고 + 현재까지의 값이 13자리 넘으면 넣지 말기
      else if (
        (isNaN(value.substring(value.length, value.length - 1)) &&
          value.substring(value.length, value.length - 1) !== "-") ||
        value.length > 13
      ) {
        setManager({
          ...manager,
          [name]: value.substring(0, value.length - 1),
        });
      }
      // 값을 저장해도 되면
      else {
        // 길이가 3이고 정규식에 부합 || 길이가 8이고 정규식에 부합 -> 작대기 넣기
        if (
          (value.length === 3 && regPhone1.test(value)) ||
          (value.length === 8 && regPhone2.test(value))
        ) {
          setManager({ ...manager, [name]: value + "-" });
        }
        // 그 밖의 경우
        else setManager({ ...manager, [name]: value });
      }
    }
    // 구분 간호사일경우 - 과목코드 05 저장
    else if (name === "role" && value === "2") {
      setManager({ ...manager, role: value, subjectCode: "05" });
    }
    // 휴대폰 번호 아닌 변수가 변한 경우
    else setManager({ ...manager, [name]: value });
  };

  // 구분이나 권한을 소속보다 먼저 클릭할 경우 처리
  const click = (e) => {
    if (manager.belong === "소속 선택") {
      toast(
        <p>소속 먼저 선택해주세요.</p>,
        toastCommonProps(1500, "top-left", "toast_common toast__normal")
      );
    }
    // 의사에서 구분 변경시 과목코드 초기화
    if (
      e.target.name === "role" &&
      manager.role !== 1 &&
      manager.subjectCode !== ""
    ) {
      setManager({ ...manager, subjectCode: "" });
    }
  };

  // 제출 전에 제출 형식에 맞는 지 확인
  const checkForSave = () => {
    let msg = ""; // 오류났을 시 메세지

    // 휴대폰 번호 | 이메일 주소 정규식
    const regPhone = /^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/;
    const regEmail =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    // 오직 문자열만 정규식
    const onlyString = /^[가-힣a-zA-Z]+$/;
    // 오직 숫자만 정규식
    const onlyNum = /^[0-9]*$/;

    // 누락된 칸이 있을 경우
    if (manager.name.trim() === "") {
      msg = "이름을 입력해 주세요.";
    } // 이름 형식 체크
    else if (!onlyString.test(manager.name)) {
      msg = "이름이 올바르지 않습니다.";
    }
    //todo
    else if (manager.email.trim() === "") {
      msg = "이메일을 입력해 주세요.";
    } else if (!regEmail.test(manager.email)) {
      msg = "이메일 형식이 알맞지 않습니다.";
    } else if (manager.phone.trim() === "") {
      msg = "휴대폰 번호를 입력해 주세요.";
    } else if (!regPhone.test(manager.phone)) {
      msg = "휴대폰 번호 형식이 알맞지 않습니다.";
    } else if (manager.adminPwd === "") {
      msg = "비밀번호를 입력해 주세요.";
    } else if (manager.belong === "소속 선택") {
      msg = "소속을 선택해 주세요.";
    } else if (manager.role.trim() === "구분 선택") {
      msg = "구분을 선택해 주세요.";
    } else if (manager.power.trim() === "권한 선택") {
      msg = "권한을 선택해 주세요.";
    } else if (manager.license.trim() === "") {
      msg = "면허번호를 입력해 주세요.";
    } else if (manager.license.length < 4 || !onlyNum.test(manager.license)) {
      msg = "면허번호가 잘못되었습니다.";
    } else if (manager.qualification.trim() === "") {
      msg = "자격번호를 입력해 주세요.";
    } else if (onlyNum.test(manager.qualification) === false) {
      msg = "자격번호가 잘못되었습니다.";
    } else if (
      manager.role.trim() === "1" && // 1 = 의사
      manager.subjectCode.trim() === ""
    ) {
      msg = "전문과목코드를 선택해 주세요.";
    }

    // 모두 통과
    if (msg === "") return true;
    // 형식 오류 있을 때
    else {
      toast(
        <p>{msg}</p>,
        toastCommonProps(1500, "top-left", "toast_common toast__normal")
      );
      return false;
    }
  };

  // 저장하기 버튼 눌렀을 시
  const save = () => {
    // toast창 위치 설정
    setToastElPosition({
      top: findToastPosition("top"),
      left: findToastPosition("left"),
    });

    // 제출 형식 체크 통과하면
    if (checkForSave()) {
      let newData = {
        name: manager.name.trim(),
        email: manager.email.trim(),
        phone: manager.phone,
        belong: Number(manager.belong),
        role: Number(manager.role),
        power: Number(manager.power),
        license: manager.license, // 면허번호
        subjectCode:
          manager.role === "2" && manager.subjectCode === ""
            ? "05"
            : manager.subjectCode, // 전문 과목코드
        qualification: manager.qualification, // 자격번호
        status: Number(manager.status),
        profileURL: manager.profileURL,
      };

      // Axios
      async function fetchData() {
        setLoading({ ...loading, save: true });
        const res = await api({
          method: "post",
          url: commonUrl.admin + "edit",
          data: newData,
        }).catch((error) => {
          handlingError(error, "top-left", "toast_common toast__normal");
        });
        console.log(res.data, ">>> Axios admin + edit");
        // axios 성공 시
        if (res.data.ok) {
          toast(
            <p>저장 되었습니다.</p>,
            toastCommonProps(1500, "top-left", "toast_common toast__normal")
          );
          setTimeout(() => {
            setLoading({ ...loading, save: false });
            navigate(-1);
          }, 2000);
        }
        // axios 실패 시
        else {
          setLoading({ ...loading, save: false });
          toast(
            <p>{res.data.error}</p>,
            toastCommonProps(1500, "top-left", "toast_common toast__normal")
          );
        }
      }
      fetchData();
    }
  };

  // 정보 가져와서 미리 띄우기
  useEffect(() => {
    // Axios
    async function fetchData() {
      const response = await api({
        method: "get",
        url: commonUrl.admin + "Id/" + state.id,
      }).catch((error) => {
        handlingError(error, "top-left", "toast_common toast__normal");
      });
      console.log(response.data, ">>> Axios admin/Id/ + id");
      setLoading({ ...loading, first: false });
      // axios 성공 시
      if (response.data.ok && response.data.admin) {
        const admin = response.data.admin;
        // 표에 적합한 형식으로 다듬어서 저장

        setManager({
          name: admin.name,
          email: admin.email,
          phone: admin.phone,
          belong: String(admin.belong.id),
          role: String(admin.role),
          power: String(admin.power),
          license: String(admin.license), // 면허번호
          subjectCode: String(admin.subjectCode), // 전문 과목코드
          qualification: String(admin.qualification), // 자격번호
          profileURL: admin.profileURL,
          status: String(admin.status),
          recentEnter: admin.recentEnter
            ? dayjs(new Date(admin.recentEnter)).format("YYYY.MM.DD")
            : "접속 기록 없음",
        });
      }
      // axios 실패시
      else {
        toast(
          <p>{response.data.error}</p>,
          toastCommonProps(1500, "top-center", "toast_common toast__normal")
        );
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 소속과 구분을 선택한 상태에서 소속만 바꿨을 경우 처리
  // (소속-병원 이면 구분-관리자 항목 안보이게 소속-헤링스 이면 구분-의사,간호사 안보이게)
  useEffect(() => {
    // 소속만 헤링스로 바꿨고, 이전에 구분이 의사나 간호사였을 때
    if (
      manager.belong === "1" &&
      (manager.role === "1" || manager.role === "2")
    )
      setManager({ ...manager, role: "구분 선택" });
    // 소속만 병원으로 바꿨고, 이전에 구분이 관리자였을 때
    else if (manager.belong !== "1" && manager.role === "0")
      setManager({ ...manager, role: "구분 선택" });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manager.belong]);

  return (
    <Header2
      title={[
        { title: "관리자 등록/관리", url: -1 },
        { title: "관리자 확인/수정", url: 0 },
      ]}
    >
      {/* toast 창 ui 변경 */}
      <style jsx="true">{`
        .Toastify__toast {
          top: ${toastElPosition.top - 20}px;
          left: ${toastElPosition.left - 285}px;
        }
      `}</style>
      <ToastContainer />
      <div className="admin flex flex-col grow p-[32px]">
        <div className="h-[80px] min-h-[80px]"></div>
        <label className="text-2xl font-extrabold leading-8 text-[#020A1B] mb-3">
          관리자 확인/수정
        </label>
        {loading.first ? (
          <Loading />
        ) : (
          <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] min-w-[975px]">
            {/* 사진 선택 */}
            <div className="flex justify-center h-[85px]">
              {manager.profileURL ? (
                <img
                  className="rounded-[50px] w-[85px] h-[85px] border object-cover"
                  src={
                    commonUrl.networkImg +
                    encodeURIComponent(manager.profileURL)
                  }
                  alt="profile"
                />
              ) : (
                <img
                  className="w-[85px] h-[85px] "
                  src={profile}
                  alt="default_profile"
                />
              )}
            </div>
            {/* 표 */}
            <div className="grid grid-cols-4 mt-[20px] text-center ">
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border  ">
                이름
              </label>
              <div className="flex justify-start items-center px-1 py-[7px] border border-[#E5E6E7] ] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                <input
                  type="text"
                  className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none "
                  name="name"
                  onChange={change}
                  value={manager.name}
                />
              </div>
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border ">
                이메일 주소
              </label>
              <label className="w-full border border-[#E5E6E7] font-normal p-[5px] text-[#020A1B] truncate">
                {manager.email}
              </label>
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border  ">
                휴대폰 번호
              </label>
              <div className="flex justify-start items-center px-1 py-[7px] border border-[#E5E6E7] ] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                <input
                  type="text"
                  className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none "
                  name="phone"
                  placeholder="010-1234-5678"
                  onChange={change}
                  value={manager.phone}
                />
              </div>
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border ">
                소속
              </label>
              {/* manager 관련 페이지는 power === 0 인 경우에만 접근 가능하므로 belong 으로 구분  */}
              {/* 헤링스 슈퍼관리자 */}
              {adminLogin.belong === 1 ? (
                <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                  <select
                    className="w-full mx-[1px] px-[5px] py-[3px] border border-[#E5E6E7] vertical-middle focus:outline-none"
                    name="belong"
                    value={manager.belong}
                    onChange={change}
                    disabled
                  >
                    <option value="소속 선택">소속 선택</option>
                    {belongs.map((el, idx) => {
                      return (
                        <option key={idx} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : (
                // 병원 슈퍼관리자
                <label className="border border-[#E5E6E7] font-normal p-[5px] text-[#020A1B]">
                  {adminLogin.belongName}
                </label>
              )}
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px]  text-[#4E535F] box-border ">
                구분
              </label>
              <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                {/* 헤링스 슈퍼관리자 */}
                {adminLogin.belong === 1 ? (
                  // 소속을 헤링스로 선택
                  manager.belong === "1" ? (
                    <select
                      className="w-full mx-[1px] px-[5px] py-[3px] border border-[#E5E6E7] vertical-middle focus:outline-none"
                      name="role"
                      value={manager.role}
                      onChange={change}
                      onClick={click}
                    >
                      <option value="구분 선택">구분 선택</option>
                      <option value="0">관리자</option>
                    </select>
                  ) : (
                    // 소속을 병원으로 선택
                    <select
                      className="w-full mx-[1px] px-[5px] py-[3px] border border-[#E5E6E7] vertical-middle focus:outline-none"
                      name="role"
                      value={manager.role}
                      onChange={change}
                      onClick={click}
                    >
                      <option value="구분 선택">구분 선택</option>
                      <option value="1">의사</option>
                      <option value="2">장루 간호사</option>
                    </select>
                  )
                ) : (
                  // 병원 슈퍼관리자
                  <select
                    className="w-full mx-[1px] px-[5px] py-[3px] border border-[#E5E6E7] vertical-middle focus:outline-none"
                    name="role"
                    value={manager.role}
                    onChange={change}
                    onClick={click}
                  >
                    <option value="구분 선택">구분 선택</option>
                    <option value="1">의사</option>
                    <option value="2">장루 간호사</option>
                  </select>
                )}
              </div>
              {/* 헤링스 슈퍼관리자 */}
              {adminLogin.belong === 1 || adminLogin.power === 0 ? (
                <>
                  <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px]  text-[#4E535F] box-border ">
                    권한 설정
                  </label>
                  <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                    <select
                      className="w-full mx-[1px] px-[5px] py-[3px] border border-[#E5E6E7] vertical-middle focus:outline-none"
                      name="power"
                      value={manager.power}
                      onChange={change}
                      onClick={click}
                    >
                      <option value="권한 선택">권한 선택</option>
                      <option value="0">수퍼 관리자</option>
                      <option value="1">일반 관리자</option>
                    </select>
                  </div>
                </>
              ) : null}
              {manager.belong !== "1" ? (
                <>
                  <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                    면허번호
                  </label>
                  <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                    <input
                      type="text"
                      className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none text-[#020A1B]"
                      name="license"
                      maxLength={5}
                      // placeholder=""
                      value={manager.license}
                      onChange={change}
                    />
                  </div>
                  <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                    자격번호
                  </label>
                  <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                    <input
                      type="text"
                      className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none text-[#020A1B]"
                      name="qualification"
                      maxLength={6}
                      placeholder=""
                      value={manager.qualification}
                      onChange={change}
                    />
                  </div>

                  <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                    전문과목코드
                  </label>
                  <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                    <select
                      className="w-full mx-[1px] px-[5px] py-[3px] border border-[#E5E6E7] vertical-middle focus:outline-none text-[#020A1B]"
                      name="subjectCode"
                      // defaultValue={manager.subjectCode}
                      value={manager.subjectCode}
                      onChange={change}
                      disabled={manager.role !== "1" ? true : false}
                    >
                      {manager.subjectCode === "05" || manager.role === 2 ? (
                        <option value="05">간호사(05)</option>
                      ) : (
                        <option value="선택">선택</option>
                      )}
                      <option value="010400">외과(010400)</option>
                      <option value="010401">간담췌외과(010401)</option>
                      <option value="010402">대장항문외과(010402)</option>
                      <option value="010403">소아외과(010403)</option>
                      <option value="010404">위장관외과(010404)</option>
                      <option value="010405">수부외과(010405)</option>
                      <option value="010406">중환자외과(010406)</option>
                      <option value="010407">외상외과(010407)</option>
                      <option value="010408">외과유방질환 분과(010408)</option>
                    </select>
                  </div>
                </>
              ) : null}

              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px]  text-[#4E535F] box-border ">
                상태 변경
              </label>
              <div className="flex items-center justify-evenly p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                <label>
                  <input
                    type="radio"
                    name="status"
                    value="1"
                    onChange={change}
                    className="mr-[5px]"
                    checked={manager.status === "1"}
                  />
                  비활성
                </label>
                <label>
                  <input
                    type="radio"
                    name="status"
                    value="0"
                    onChange={change}
                    className="mr-[5px]"
                    checked={manager.status === "0"}
                  />
                  활성
                </label>
              </div>
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px]  text-[#4E535F] box-border ">
                최근 접속일
              </label>
              <label className="border border-[#E5E6E7] font-normal p-[5px] text-[#020A1B] text-left pl-[15px]">
                {manager.recentEnter}
              </label>
            </div>
            {/* 목록 & 저장하기 버튼 */}
            <div className="flex justify-end mt-[20px]">
              <div className="flex justify-between gap-x-3">
                <BtnWhite
                  context="목록"
                  onClick={() => {
                    navigate(-1);
                  }}
                  style={{ width: "90px" }}
                  standard="toast_standard"
                />
                <BtnBlack
                  context="저장하기"
                  onClick={save}
                  loading={loading.save}
                  style={{ width: "88px" }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Header2>
  );
}

export default Modify;
