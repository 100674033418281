import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Style3 = ({
  title2,
  bigTitle,
  btn1,
  btn2,
  btn3,
  btn4,
  btn5,
  btn6,

  svgurl
}) => {
  const navigate = useNavigate();
  return (
    <div className="px-[16px]">
      <div className="pt-[20.5px]">
        <div className="flex items-center gap-x-[15px]">
          <img src={svgurl} alt="svgurl" className="w-[34px] h-[34px]" />
          <h1 className="h2_800">{bigTitle}</h1>
        </div>
        <span className="text-[#676C76] body_1_400 inline-block pt-[20.5px]">
          아래 버튼을 눌러 상세한 정보를 확인할 수 있어요!
        </span>
      </div>
      <div className="pt-[152px]">
        {btn1 !== undefined ? (
          <div
            className="mb-[16px] w-full text-center inline-block border border-[#1B5EDC] p-[16px] font-bold text-[#1B5EDC] bg-[#FAFCFF] rounded-sm active:bg-[#F2F6FC] active:text-[#18428E] ease-in-out duration-300 cursor-pointer"
            onClick={() => {
              if (btn1 === "장루로 인한 영양문제 & 장루식사요법의 목표") {
                //장루로 인한 영양문제~
                navigate("/more/sub", {
                  state: {
                    title2: title2,

                    bigTitle: bigTitle,
                    btn1: btn1
                  }
                });
              }
              if (btn1 === "옷은 어떻게 입을까?") {
                //옷은 어떻게 입을까?
                navigate("/more/sub", {
                  state: {
                    title2: title2,

                    bigTitle: bigTitle,
                    btn1: btn1
                  }
                });
              }
              if (btn1 === "장루 주위 탈장") {
                //장루 주위 탈장
                navigate("/more/sub", {
                  state: {
                    title2: title2,

                    bigTitle: bigTitle,
                    btn1: btn1
                  }
                });
              }
              if (btn1 === "대변에 의한 손상") {
                //대변에 의한 손상
                navigate("/more/sub", {
                  state: {
                    title2: title2,

                    bigTitle: bigTitle,
                    btn1: btn1
                  }
                });
              }
              if (btn1 === "기분이 나아지는 방법") {
                //기분이 나아지는 방법
                navigate("/more/sub", {
                  state: {
                    title2: title2,

                    bigTitle: bigTitle,
                    btn1: btn1
                  }
                });
              }
              if (btn1 === "복지혜택 참고사항") {
                //복지혜택 참고사항
                navigate("/more/sub", {
                  state: {
                    title2: title2,

                    bigTitle: bigTitle,
                    btn1: btn1
                  }
                });
              }
            }}
          >
            {btn1 === "장루로 인한 영양문제 & 장루식사요법의 목표" ? (
              <div className="flex flex-col">
                <span>장루로 인한 영양문제</span>
                <span>&amp; 장루식사요법의 목표</span>
              </div>
            ) : (
              <div>{btn1}</div>
            )}
          </div>
        ) : (
          ""
        )}
        {btn2 !== undefined ? (
          <span
            className="mb-[16px] w-full text-center inline-block border border-[#1B5EDC] p-[16px] font-bold text-[#1B5EDC] bg-[#FAFCFF] rounded-sm active:bg-[#F2F6FC] active:text-[#18428E] ease-in-out duration-300 cursor-pointer"
            onClick={() => {
              if (btn2 === "수술 후 첫 4-6주간 영양가이드") {
                //수술 후 첫4-6주간~
                navigate("/more/sub", {
                  state: {
                    title2,
                    title2,

                    bigTitle: bigTitle,
                    btn2: btn2
                  }
                });
              }
              if (btn2 === "여행시 고려사항은?") {
                //여행시 고려사항은?
                navigate("/more/sub", {
                  state: {
                    title2,
                    title2,

                    bigTitle: bigTitle,
                    btn2: btn2
                  }
                });
              }
              if (btn2 === "장루 주위 출혈") {
                //장루 주위 출혈
                navigate("/more/sub", {
                  state: {
                    title2,

                    bigTitle: bigTitle,
                    btn2: btn2
                  }
                });
              }
              if (btn2 === "알러지에 의한 손상") {
                //알러지에 의한 손상
                navigate("/more/sub", {
                  state: {
                    title2,
                    title2,

                    bigTitle: bigTitle,
                    btn2: btn2
                  }
                });
              }
              if (btn2 === "피로감이 느껴질 때") {
                //피로감이 느껴질 때
                navigate("/more/sub", {
                  state: {
                    title2,
                    title2,

                    bigTitle: bigTitle,
                    btn2: btn2
                  }
                });
              }
              if (btn2 === "복지혜택 정리") {
                //복지혜택 정리
                navigate("/more/sub", {
                  state: {
                    title2,
                    title2,

                    bigTitle: bigTitle,
                    btn2: btn2
                  }
                });
              }
            }}
          >
            {btn2 === "수술 후 첫 4-6주간 영양가이드" ? (
              <div className="flex flex-col">
                <span>수술 후 첫 4-6주간</span>
                <span>영양가이드</span>
              </div>
            ) : (
              <div>{btn2}</div>
            )}
          </span>
        ) : (
          ""
        )}
        {btn3 !== undefined ? (
          <span
            className="mb-[16px] w-full text-center inline-block border border-[#1B5EDC] p-[16px] font-bold text-[#1B5EDC] bg-[#FAFCFF] rounded-sm active:bg-[#F2F6FC] active:text-[#18428E] ease-in-out duration-300 cursor-pointer"
            onClick={() => {
              if (btn3 === "기타 고려사항") {
                //수술 후 첫4-6주간~
                navigate("/more/sub", {
                  state: {
                    title2: title2,

                    bigTitle: bigTitle,
                    btn3: btn3
                  }
                });
              }
              if (btn3 === "외출과 사회활동은?") {
                //외출과 사회활동은?
                navigate("/more/sub", {
                  state: {
                    title2: title2,

                    bigTitle: bigTitle,
                    btn3: btn3
                  }
                });
              }
              if (btn3 === "장탈출") {
                //장탈출
                navigate("/more/sub", {
                  state: {
                    title2: title2,

                    bigTitle: bigTitle,
                    btn3: btn3
                  }
                });
              }
              if (btn3 === "표피 비대") {
                //표피 비대
                navigate("/more/sub", {
                  state: {
                    title2: title2,

                    bigTitle: bigTitle,
                    btn3: btn3
                  }
                });
              }
              if (btn3 === "불안감이 느껴질 때") {
                //표피 비대
                navigate("/more/sub", {
                  state: {
                    title2: title2,

                    bigTitle: bigTitle,
                    btn3: btn3
                  }
                });
              }
              if (btn3 === "치료재료 급여 인정 기준") {
                //치료재료 급여 인정 기준
                navigate("/more/sub", {
                  state: {
                    title2: title2,

                    bigTitle: bigTitle,
                    btn3: btn3
                  }
                });
              }
            }}
          >
            {btn3}
          </span>
        ) : (
          ""
        )}
        {btn4 !== undefined ? (
          <span
            className="mb-[16px] w-full text-center inline-block border border-[#1B5EDC] p-[16px] font-bold text-[#1B5EDC] bg-[#FAFCFF] rounded-sm active:bg-[#F2F6FC] active:text-[#18428E] ease-in-out duration-300 cursor-pointer"
            onClick={() => {
              if (btn4 === "장루와 성생활은?") {
                //장루와 성생활은?
                navigate("/more/sub", {
                  state: {
                    title2: title2,

                    bigTitle: bigTitle,
                    btn4: btn4
                  }
                });
              }
              if (btn4 === "피부와 장점막 분리") {
                //장루와 성생활은?
                navigate("/more/sub", {
                  state: {
                    title2: title2,

                    bigTitle: bigTitle,
                    btn4: btn4
                  }
                });
              }
              if (btn4 === "괴저성 농피증") {
                //괴저성 농피증
                navigate("/more/sub", {
                  state: {
                    title2: title2,

                    bigTitle: bigTitle,
                    btn4: btn4
                  }
                });
              }
              if (btn4 === "우울감이 느껴질 때") {
                //우울감이 느껴질 때
                navigate("/more/sub", {
                  state: {
                    title2: title2,

                    bigTitle: bigTitle,
                    btn4: btn4
                  }
                });
              }
              if (btn4 === "교육 상담료 급여 기준") {
                //교육 상담료 급여 기준
                navigate("/more/sub", {
                  state: {
                    title2: title2,

                    bigTitle: bigTitle,
                    btn4: btn4
                  }
                });
              }
            }}
          >
            {btn4}
          </span>
        ) : (
          ""
        )}
        {btn5 !== undefined ? (
          <span
            className="mb-[16px] w-full text-center inline-block border border-[#1B5EDC] p-[16px] font-bold text-[#1B5EDC] bg-[#FAFCFF] rounded-sm active:bg-[#F2F6FC] active:text-[#18428E] ease-in-out duration-300 cursor-pointer"
            onClick={() => {
              if (btn5 === "함몰") {
                //함몰
                navigate("/more/sub", {
                  state: {
                    title2: title2,

                    bigTitle: bigTitle,
                    btn5: btn5
                  }
                });
              }
              if (btn5 === "복식호흡 하는 방법") {
                //복식호흡 하는 방법
                navigate("/more/sub", {
                  state: {
                    title2: title2,

                    bigTitle: bigTitle,
                    btn5: btn5
                  }
                });
              }
            }}
          >
            {btn5}
          </span>
        ) : (
          ""
        )}
        {btn6 !== undefined ? (
          <span
            className="mb-[16px] w-full text-center inline-block border border-[#1B5EDC] p-[16px] font-bold text-[#1B5EDC] bg-[#FAFCFF] rounded-sm active:bg-[#F2F6FC] active:text-[#18428E] ease-in-out duration-300 cursor-pointer"
            onClick={() => {
              if (btn6 === "협착") {
                //협착
                navigate("/more/sub", {
                  state: {
                    title2: title2,

                    bigTitle: bigTitle,
                    btn6: btn6
                  }
                });
              }
              if (btn6 === "명상/심상유도 방법") {
                //명상/심상유도 방법
                navigate("/more/sub", {
                  state: {
                    title2: title2,

                    bigTitle: bigTitle,
                    btn6: btn6
                  }
                });
              }
            }}
          >
            {btn6}
          </span>
        ) : (
          ""
        )}
      </div>
      <div className="h-[70px]"></div>
    </div>
  );
};

export default Style3;
