import { useEffect, useRef, useState } from "react";
import commonUrl, { api } from "../../../../data/common";
import TableBlue from "../../admin_components/TableBlue";
import Header2 from "../../admin_components/Header/Header2";
import { ToastContainer, toast, Flip } from "react-toastify";
import { Loading } from "../../admin_components/Loading";
import { BtnBlue } from "../../admin_components/Buttons";
import { toastCommonProps } from "../../admin_components/Toast";
import { useMainContext } from "../../../../contexts/Provider";
import { handlingError } from "../../admin_components/Error";
import { getAge } from "../Client/Client";

export function AllList() {
  const search_img = "/images/admin/search.svg";
  const [loading, setLoading] = useState(false); //
  const { adminLogin } = useMainContext();
  /********************** 검색 관련 state, ref ********************/
  const [data, setData] = useState([]); // 표기할 정보 담기
  const [search, setSearch] = useState(""); // 검색할 단어
  const dataRef = useRef([]); // 전체 정보 담ㄱ
  const showAllRef = useRef(false); // 전체보기 버튼 보여줄지 말지
  const searchRef = useRef();

  // 검색버튼과 엔터키 연결
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      searchClick();
    }
  };

  // 전체 보기 클릭시
  const onClickShowAll = () => {
    setData(dataRef.current);
    setSearch("");
    showAllRef.current = false;
  };

  // 검색 버튼 클릭했을 때
  const searchClick = () => {
    // 검색할 값이 있으면
    if (search !== "") {
      const newData = dataRef.current.filter((el) => {
        return el.name.includes(search) || el.patientID.includes(search);
      });
      // 검색된 내용 없으면
      if (newData.length === 0) {
        toast(
          <p>검색된 내용이 없습니다.</p>,
          toastCommonProps(
            15000,
            "top-right",
            "toast_common toast__search",
            Flip
          )
        );
        setSearch("");
      }
      // 검색된 내용 있으면
      else {
        setData(newData);
        showAllRef.current = true;
      }
    }
    // 검색한 값이 없으면
    else {
      setData(dataRef.current);
      setSearch("");
      showAllRef.current = false;
    }
  };

  // 첫 랜더시 데이터 불러오기
  useEffect(() => {
    // Axios
    async function fetchData() {
      setLoading(true);
      const res = await api
        .get(commonUrl.patient + "allinfo/" + adminLogin.belong)
        .catch((error) => {
          handlingError(error);
        });
      // console.log(res.data, ">>> Axios patient + allInfo");
      setLoading(false);
      // axios 성공 시
      if (res.data.ok && res.data.patientForMonitoringList) {
        // table에 띄우기 적합한 형태로 다듬어서 저장
        const newData = res.data.patientForMonitoringList.map((el, idx) => {
          return {
            ...el.patient,
            idx: res.data.patientForMonitoringList.length - idx,
            gender: el.patient.gender === "M" ? "남" : "여",
            age: getAge(el.patient.birth),
            status: el.patient.status === 0 ? "활성" : "비활성",
            recentSurveyDay: el.recentSurveyDay,
            replyDay: el.replyDay,
            progress: el.progress,
          };
        });
        dataRef.current = newData;
        setData(dataRef.current);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /******************************* Table UI 관련 변수 & 함수들  ******************************/
  // Table에 들어갈 column명
  const columns = [
    {
      accessor: "idx",
      Header: "NO.",
      width: "8%",
    },
    {
      accessor: "name",
      Header: "이름",
      width: "11%",
    },
    {
      accessor: "patientID",
      Header: "등록번호",
      width: "12%",
    },
    {
      accessor: "gender",
      Header: "성별",
      width: "8%",
    },
    {
      accessor: "age",
      Header: "만 나이",
      width: "10%",
    },
    {
      accessor: "jangruType",
      Header: "장루 유형",
      width: "17%",
    },
    {
      accessor: "recentSurveyDay",
      Header: "설문 작성일",
      width: "16%",
    },
    {
      accessor: "replyDay",
      Header: "장루 평가일",
      width: "17%",
    },
    {
      accessor: "progress",
      Header: "진행현황",
      width: "12%",
    },
    {
      accessor: "status",
      Header: "상태",
      width: "9%",
    },
  ];

  return (
    <Header2 title={[{ title: "장루점검", url: 0 }]}>
      <ToastContainer />
      <div className="admin flex flex-col grow p-[32px]">
        <div className="h-[80px] min-h-[80px]"></div>
        <label className="text-2xl font-extrabold leading-8 text-[#020A1B]">
          장루점검
        </label>
        {loading ? (
          <Loading />
        ) : (
          <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-3 min-w-[977px]">
            <div className="flex items-center justify-end">
              {/* 검색하기 */}
              <div className="flex gap-x-2 ml-[10px]">
                <div className="flex items-center w-[290px] h-[38px] border border-[#CCCDD1] box-border px-6 py-2 rounded-[48px]">
                  <img
                    src={search_img}
                    alt="search_img"
                    className="w-[16.5px] h-[16.6px] mr-[9.69px]"
                  />
                  <input
                    type="text"
                    className="w-[210px] h-[22px] bg-transparent text-[#4E535F] focus:outline-none placeholder:font-semibold placeholder:text-base placeholder:leading-[22px] placeholder:text-[#4E535F]"
                    placeholder="이름 또는 등록번호를 검색하세요."
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    ref={searchRef}
                    onKeyPress={onKeyPress}
                  />
                </div>
                <BtnBlue
                  onClick={searchClick}
                  context="검색"
                  style={{
                    width: "76px",
                    height: "38px",
                    borderRadius: "48px",
                  }}
                />
              </div>
            </div>
            {loading === false ? (
              <div className="flex mt-[20px] y-[10px]">
                <TableBlue
                  columns={columns}
                  data={data}
                  pageMove={"/admin/main/check/detail"}
                  sorting={true}
                  pagination={true}
                  showAll={showAllRef.current ? onClickShowAll : false}
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </Header2>
  );
}
export default AllList;
