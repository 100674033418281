import React, { useState } from "react";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast, Flip } from "react-toastify";
import common, { api } from "../../../../../data/common";

import { useMainContext } from "../../../../../contexts/Provider";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import TextEditorToolbar, {
  modules,
  formats,
} from "../../../admin_components/TextEditorToolbar";
import "react-quill/dist/quill.snow.css";
import { BtnBlack } from "../../../admin_components/Buttons";
import { toastCommonProps } from "../../../admin_components/Toast";

const InquiryAnswer = () => {
  const [toastElPosition, setToastElPosition] = useState({ top: 0, left: 0 }); // toast창 위치 값
  const [loading] = useState(false);
  const navigate = useNavigate();
  const { adminLogin } = useMainContext();
  const questionid = useLocation().state.question.id;
  const [fileon, setFileon] = useState(false);
  const [setfile, setSetfile] = useState([]);
  const [answer, setAnswer] = useState({
    admin: adminLogin.id,
    content: "",
    fileURL: [],
    question: questionid,
  });
  const newsFileArr = [];
  const [files, setFiles] = useState([]);
  const regex = /\s/gi;
  const bowl = [];

  const toast_func = (text) => {
    console.log(text);
    toast(
      <p>{text}</p>,
      toastCommonProps(3000, "top-left", "toast_common toast__normal", Flip)
    );
  };

  const onChangeFile = (e) => {
    const toastEl = document.querySelector(".toast_standard");

    setToastElPosition({
      top: toastEl.getBoundingClientRect().top,
      left: toastEl.getBoundingClientRect().left,
    });
    //공백제거
    const file = e.target.files;
    let cnt = 0;
    for (let i = 0; i < file.length; i++) {
      const file_form = file[i].name.slice(
        file[i].name.indexOf("."),
        file[i].name.length
      );
      if (file_form === ".jpg" || file_form === ".jpeg") {
        //확장자 구분
        cnt++;
        if (cnt > 5) {
          //이미지 5개 카운팅
          toast_func("이미지가 5개를 초과하였습니다.");

          break;
        }
      }
    }
    if (cnt < 6) {
      for (let i = 0; i < file.length; i++) {
        let text = file[i].lastModified + file[i].name;
        newsFileArr.push(text.replace(regex, ""));
        bowl.push(file[i].name);
      }

      setFiles(file);
      setAnswer({ ...answer, fileURL: newsFileArr });
      setFileon(true);
      setSetfile(bowl);
    }
  };

  const onContent = (value) => {
    setAnswer({ ...answer, content: value });
  };

  const submitData = () => {
    const toastEl = document.querySelector(".toast_standard");
    setToastElPosition({
      top: toastEl.getBoundingClientRect().top,
      left: toastEl.getBoundingClientRect().left,
    });

    let axiosnum = 0;
    if (
      answer.content === "" ||
      answer.content === "<p><br></p>" ||
      answer.content.replace(/(\s*)/g, "") === "<p></p>"
    )
      toast_func("누락된 항목이 있습니다.");
    //파일이 있을 떄
    else if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        let formData = new FormData();
        const filename = files[i].lastModified + files[i].name;
        formData.append("file", files[i]);
        formData.append("name", filename.replace(regex, ""));
        // eslint-disable-next-line no-loop-func
        api.post(common.baseURL + "upload/oneNew", formData).then((awsres) => {
          if (awsres.data.ok === true) {
            axiosnum += 1;
          } else toast_func("사진 등록이 실패하였습니다.");

          if (axiosnum === files.length) {
            api
              .post(common.baseURL + "answer", answer)
              .then((postRes) => {
                if (postRes.data.ok === true) {
                  toast_func("저장되었습니다.");

                  setTimeout(() => navigate("/admin/main/inquiry"), 3000);
                } else toast_func("누락된 항목이 있습니다.");
              })
              .catch((error) => console.log(error));
          }
        });
      }
    } else {
      //파일이 없을 때
      api
        .post(common.baseURL + "answer", answer)
        .then((postRes) => {
          if (postRes.data.ok === true) {
            toast_func("저장되었습니다.");

            setTimeout(() => navigate("/admin/main/inquiry"), 3000);
          } else {
            toast_func("누락된 항목이 있습니다.");
          }
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <>
      <style jsx="true">{`
        .Toastify__toast {
          top: ${toastElPosition.top - 20}px;
          left: ${toastElPosition.left - 285}px;
        }
        .ql-toolbar.ql-snow {
          border-top-width: 0 !important;
          border-left-width: 0 !important;
          border-right-width: 0 !important;
          border-bottom-width: 2px !important;
          border-color: #e5e6e7 !important;
        }
        .ql-container.ql-snow {
          border: none !important;
        }
        .ql-container.ql-snow .ql-editor {
          padding: 0px !important;
        }
      `}</style>
      <div className="text-left text-xl leading-[26px] font-extrabold text-[#343B48] mb-[25px]">
        답변 작성하기
      </div>
      {/* 표 */}
      <div className="grid grid-cols-5 min-w-[915px] text-center border border-[#E5E6E7] bg-[#fafbfc] ">
        <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border">
          작성자
        </label>
        <label className="flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7] bg-[#F1F2F3] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-4">
          {adminLogin.role === 0
            ? adminLogin.belongName + " 관리자"
            : adminLogin.role === 1
            ? adminLogin.belongName + " 의사"
            : adminLogin.belongName + " 간호사"}
        </label>
        <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border">
          내용
        </label>
        <div className="w-full bg-white vertical-middle border border-[#E5E6E7] col-span-4 overflow-auto">
          <TextEditorToolbar />
          <ReactQuill
            className="w-full h-40 p-6"
            theme="snow"
            onChange={onContent}
            placeholder={"내용을 적어주세요."}
            modules={modules}
            formats={formats}
          />
        </div>

        <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border">
          첨부파일
        </label>
        <div className="flex bg-white flex-col flex-wrap border border-[#E5E6E7] font-semibold col-span-4 ">
          <div className="inline-block w-full p-6">
            <div className="flex flex-col flex-wrap col-span-4 p-2 font-semibold ">
              {/*p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-3 min-w-[975px] */}
              <label
                htmlFor="input_file"
                className="w-[88px] h-[38px] px-4 py-2 bg-[#4E535F] hover:bg-gray-700 cursor-pointer text-[#FFFFFF] font-bold text-base leading-[22px] rounded-[6px] mb-2"
              >
                파일첨부
              </label>
              <input
                id="input_file"
                className="hidden w-full"
                type="file"
                label="Upload"
                multiple
                accept=".jpeg, .jpg, .png, .pdf"
                placeholder="첨부할 파일이 있는 경우 버튼을 클릭하여 등록하세요. (이미지는 최대 5개)"
                onChange={onChangeFile}
              />
              {fileon ? (
                <>
                  {setfile.map((e, i) => (
                    <p
                      className="w-auto mt-2 text-sm text-left text-gray-400"
                      key={i}
                    >
                      {e}
                    </p>
                  ))}
                </>
              ) : (
                <p className="mt-2 text-sm text-left text-gray-400">
                  *첨부 파일이 있는 경우 이곳을 클릭하여 등록 하세요.(이미지는
                  최대 5개)
                </p>
              )}
            </div>
          </div>
        </div>
        <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border">
          작성일
        </label>
        <label className="bg-white flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-4">
          {dayjs().format("YYYY.MM.DD")}
        </label>
      </div>
      <div className="flex w-full justify-end items-center mt-[18px]">
        <div className="flex gap-x-2">
          <BtnBlack
            context="저장하기"
            onClick={submitData}
            loading={loading}
            width="88px"
            standard="toast_standard"
          />
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default InquiryAnswer;
