import React from "react";

const Tutorial3 = () => {
  const tutorial3 = "/images/mo/pngjpg/tutorial3.png";
  const lefthand = "/images/mo/pngjpg/lefthand.png";
  return (
    <div>
      <div className="pt-[25.5px] flex justify-center ">
        <div className="w-[375px] h-[329px] flex items-center justify-center relative">
          <img src={tutorial3} alt="tutorial3" />
          <img
            src={lefthand}
            alt="lefthand"
            className="absolute top-32 right-8 tutohand34"
          />
        </div>
      </div>
      <div className="flex items-center flex-col pt-[50px]">
        <span className="block text-[#1B5EDC] h2_800">장루의 모든 것</span>
        <div className="text-[#343B48] text-center body_1_400 pt-[8px]">
          <div>장루에 관한</div>
          <div>분야 별 정보를 확인해 보세요.</div>
        </div>
      </div>
    </div>
  );
};

export default Tutorial3;
