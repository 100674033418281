import React, { useEffect, useState } from "react";
import { useMainContext } from "../../../../contexts/Provider";
import commonUrl, { api } from "../../../../data/common";

import Popup from "reactjs-popup";
import { Loading } from "../Loading";
import dayjs from "dayjs";
import { useTable, useSortBy, usePagination } from "react-table";
import { handlingError } from "../Error";

function HeaderAlertPopup({ alertOpen, setAlertOpen }) {
  const header_alertPopup = "/images/admin/header_dash/header_alertPopup.svg";
  const cancel_black = "/images/admin/header_dash/cancel_black.svg";
  const { adminLogin, setRedDot } = useMainContext();
  const closeAlertModal = () => setAlertOpen(false);

  const [checkXList, setCheckXList] = useState([]); // 미신청자 리스트
  const [loading, setLoading] = useState(false);

  const handleCheckXList = (data) => {
    const patientList = data.patientList;

    return patientList.map((el, idx) => {
      return {
        ...el[0],
        checkDay: dayjs(new Date(el[1])).format("YYYY-MM-DD"),
      };
    });
  };

  const columns = [{ Header: "patientInfo" }];

  // 알람버튼이 눌렸을 때
  useEffect(() => {
    if (alertOpen) {
      setLoading(true);
      // 알림에 띄울 미신청자 목록 axios
      const fetchData = async () => {
        const res = await api
          .get(commonUrl.alarm + "admin/id/" + adminLogin.id)
          .catch((error) => {
            handlingError(error);
          });
        console.log(res.data, ">>> admin/id/id (미신청자 목록)");
        if (res.data.ok) {
          let handledData = handleCheckXList(res.data);
          setCheckXList(handledData);
        }
        setLoading(false);
      };
      fetchData();
    }
  }, [alertOpen, adminLogin.id, setRedDot]);

  // 첫 랜더, 알림 읽어야할지 말아야할지 여부 표시
  useEffect(() => {
    // read 보내주는 axios
    const fetchData = async () => {
      const res = await api
        .get(commonUrl.alarm + "adminHeader/id/" + adminLogin.id)
        .catch((error) => {
          handlingError(error);
        });
      // console.log(
      //   res.data,
      //   ">>> Axios alarm/adminHeader/id/id"
      // );
      if (res.data.ok) {
        setRedDot(true);
      } else setRedDot(false);
    };
    if (adminLogin.id !== "") fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <style jsx="true">{`
        .popup-content.alert__popup-content {
          font-family: -apple-system, BlinkMacSystemFont, "Apple SD Gothic Neo",
            Pretendard, Roboto, "Noto Sans KR", "Segoe UI", "Malgun Gothic",
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif !important;
          position: absolute !important;
          right: 110px !important;
          top: 72px;
          width: 320px;
          box-sizing: border-box;
          max-width: 400px;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          background: #fafbfc;
          padding: 0px;
          border: none;
        }
      `}</style>
      <Popup
        className="alert__popup"
        open={alertOpen}
        closeOnDocumentClick
        onClose={closeAlertModal}
      >
        <div className="flex flex-col w-full">
          <div className="flex justify-between p-4">
            <div className="flex items-center gap-2">
              <img
                src={header_alertPopup}
                alt="header_alertPopup"
                className="h-[16px] w-[16px]"
              />
              <p className="w-[90px] text-sm font-normal text-[#020A1B] leading-[18px] ">
                미신청자 알림
              </p>
            </div>
            <img
              src={cancel_black}
              alt="cancel_black"
              className="h-[16px] w-[16px] cursor-pointer"
              onClick={() => {
                closeAlertModal();
              }}
            />
          </div>
          <div className="h-[1px] w-full bg-[#CCCDD1]"></div>
          {/* 신청자 목록 */}
          <div className="flex flex-col p-4 bg-[#F1F2F3]  rounded-b-[8px]">
            <div>
              <label className="block text-xs font-normal leading-4 tracking-[2%] text-[#1B5EDC]">{`오늘(${dayjs(
                new Date()
              ).format("MM월 DD일")})`}</label>
              <label className="font-semibold text-base leading-[22px] text-[#020A1B]">
                장루 정기점검 미신청자 목록
              </label>
            </div>
            <div className="h-[24px] w-full"></div>

            {loading ? (
              <Loading />
            ) : checkXList.length === 0 ? (
              <label className="w-full text-base font-semibold text-[#676C76]">
                미신청자가 없습니다.
              </label>
            ) : (
              <AlertTable columns={columns} data={checkXList} />
            )}
          </div>
        </div>
      </Popup>
    </>
  );
}

const AlertTable = ({ columns, data }) => {
  const {
    getTableBodyProps,
    page,

    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 4 } },
    useSortBy,
    usePagination
  );
  const paginationBtns = new Array(pageCount).fill(0).map((el, idx) => idx + 1);

  // pagination UI
  const PaginationUI = () => {
    const arrow_left = "/images/admin/carousel_left.svg";
    const arrow_right = "/images/admin/carousel_right.svg";

    return (
      <div className="flex justify-center items-center mt-[10px]">
        <button
          className="px-[17.6px]"
          onClick={() => {
            previousPage();
          }}
          disabled={!canPreviousPage}
        >
          <img
            src={arrow_left}
            alt="arrow_left"
            className="w-[6.4px] h-[11.2px]"
            style={
              !canPreviousPage
                ? {
                    filter:
                      "brightness(0) saturate(100%) invert(48%) sepia(0%) saturate(5417%) hue-rotate(195deg) brightness(134%) contrast(82%)",
                  }
                : {
                    filter:
                      "brightness(0) saturate(100%) invert(3%) sepia(29%) saturate(7499%) hue-rotate(218deg) brightness(99%) contrast(100%)",
                  }
            }
          />
        </button>
        <div className="flex gap-x-1">
          {paginationBtns.map((el, idx) => {
            if (
              idx >= Math.floor(pageIndex / 3) * 3 &&
              idx <= Math.floor(pageIndex / 3) * 3 + 2
            ) {
              return (
                <button
                  key={idx}
                  className="flex justify-center items-center border w-[32px] h-[32px] text-center text-base text-[#020A1B] font-semibold leading-5 rounded-[28px]"
                  onClick={() => {
                    gotoPage(idx);
                  }}
                  style={{
                    borderColor: pageIndex === idx ? "#020A1B" : "transparent",
                  }}
                >
                  {idx + 1}
                </button>
              );
            } else return null;
          })}
        </div>
        <button
          className="px-[17.6px]"
          onClick={() => {
            nextPage();
          }}
          disabled={!canNextPage}
        >
          <img
            src={arrow_right}
            alt="arrow_right"
            className="w-[6.4px] h-[11.2px]"
            style={
              !canNextPage
                ? {
                    filter:
                      "brightness(0) saturate(100%) invert(48%) sepia(0%) saturate(5417%) hue-rotate(195deg) brightness(134%) contrast(82%)",
                  }
                : {
                    filter:
                      "brightness(0) saturate(100%) invert(3%) sepia(29%) saturate(7499%) hue-rotate(218deg) brightness(99%) contrast(100%)",
                  }
            }
          />
        </button>
      </div>
    );
  };

  return (
    <>
      <style jsx="true">{`
        .table {
          border-spacing: 1px !important;
        }
      `}</style>
      <div className="flex flex-col w-full">
        <table
          className="w-full border-separate table-fixed "
          border="0"
          cellSpacing="0"
          cellPadding="0"
        >
          <tbody {...getTableBodyProps()}>
            {page.map((row, rowIdx) => {
              prepareRow(row);
              return (
                <tr key={row.id} {...row.getRowProps()} className="!p-0">
                  {row.cells.map((cell) => {
                    const el = cell.row.original;
                    const name = el.name;
                    const patientId = el.patientID;
                    const checkDay = el.checkDay;
                    const phone = el.phone;

                    return (
                      <td
                        key={cell.row.id}
                        className="flex justify-start h-[91px] min-h-[91px] w-full bg=[#FAFBFC] shadow-[0_2px_4px_rgba(0,0,0,0.1)] rounded-r-lg mb-2 bg-[#FAFBFC]"
                        style={{ border: "none" }}
                      >
                        <div className="h-full w-[2px] bg-[#3F82ED]"></div>
                        <div className="flex flex-col justify-start w-full p-2">
                          {/* 이름 & Id */}
                          <div className="flex items-end gap-x-2 ">
                            <label className="font-semibold text-sm leading-[18px] text-[#020A1B]">
                              {name}
                            </label>
                            <label className="font-normal text-xs leading-4 text-[#4E535F] tracking-[2%]">
                              {patientId}
                            </label>
                          </div>
                          {/* 점검일 & 연락처 */}
                          <div className="h-[1px] w-full bg-[#E5E6E7] my-2"></div>
                          <div className="flex flex-col gap-y-1">
                            <div className="flex items-center gap-x-4 text-[#676C76] font-normal text-xs leading-4 tracking-[2%]">
                              점검일
                              <span className="text-[#020A1B] font-normal text-sm leading-[18px]">
                                {checkDay}
                              </span>
                            </div>
                            <div className="flex items-center gap-x-4 text-[#676C76] font-normal text-xs leading-4 tracking-[2%]">
                              연락처
                              <span className="text-[#245BBF] font-normal text-sm leading-[18px]">
                                {phone}
                              </span>
                            </div>
                          </div>
                        </div>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* pagenation */}
        {pageOptions.length > 1 ? <PaginationUI /> : null}
      </div>
    </>
  );
};

export default HeaderAlertPopup;
