import React from "react";

const UserData2 = ({ user }) => {
  return (
    <ul>
      {/* <li className="flex mt-4 gap-x-2">
        <div className="w-1/2 flex items-center bg-[#FAFCFF] p-3 rounded-xl">
          <span className="block w-1/3 caption_1_600">키</span>
          <span className="block body_1_600">{user.height}cm</span>
        </div>
        <div className="w-1/2 flex items-center bg-[#FAFCFF] p-3 rounded-xl">
          <span className="block w-1/3 caption_1_600">몸무게</span>
          <span className="block body_1_600">{user.weight}kg</span>
        </div>
      </li>
      <li className="mt-4 bg-[#FAFCFF] p-3 rounded-xl flex items-center">
        <span className="block w-1/3 caption_1_600">BMI</span>
        <span className="block body_1_600">
          {user.bmi} ({String(user.bmi) >= 35 ? "3단계 비만" : ""}
          {String(user.bmi) < 35 && String(user.bmi) >= 30 ? "2단계 비만" : ""}
          {String(user.bmi) < 30 && String(user.bmi) >= 25 ? "1단계 비만" : ""}
          {String(user.bmi) < 25 && String(user.bmi) >= 23 ? "과체중" : ""}
          {String(user.bmi) < 23 && String(user.bmi) >= 18.5 ? "정상" : ""}
          {String(user.bmi) < 18.5 ? "저체중" : ""})
        </span>
      </li> */}
      <li className="mt-4 bg-[#FAFCFF] p-3 rounded-xl flex items-center">
        <span className="block w-1/3 caption_1_600">수술명</span>
        <span className="block body_1_600">
          {user.surgery !== undefined && user.surgery !== "선택"
            ? user.surgery
            : "-"}
        </span>
      </li>
      <li className="mt-4 bg-[#FAFCFF] p-3 rounded-xl flex items-center">
        <span className="block w-1/3 caption_1_600">장루 종류</span>
        <span className="block body_1_600">
          {user.jangruType !== undefined ? user.jangruType : "-"}
        </span>
      </li>
      <li className="mt-4 bg-[#FAFCFF] p-3 rounded-xl flex items-center">
        <span className="block w-1/3 caption_1_600">장루 수술일</span>
        <span className="block body_1_600">
          {user.jangruSurgeryDate !== undefined ? user.jangruSurgeryDate : "무"}
        </span>
      </li>
      <li className="mt-4 bg-[#FAFCFF] p-3 rounded-xl flex items-center">
        <div className="w-1/3 caption_1_600">
          <span className="block">장루 폐쇄술</span>
          <span className="block">시행여부</span>
        </div>
        <span className="block body_1_600">
          {user.jangruClosedSurgeryOX !== undefined &&
          user.jangruClosedSurgeryOX === "O"
            ? "유"
            : "무"}
        </span>
      </li>

      {user.jangruClosedSurgeryDate !== null ? (
        <li className="mt-4 bg-[#FAFCFF] p-3 rounded-xl flex items-center">
          <div className="w-1/3 caption_1_600">
            <span className="block">장루 폐쇄술</span>
            <span className="block">시행일</span>
          </div>
          <span className="block body_1_600">
            {user.jangruClosedSurgeryDate}
          </span>
        </li>
      ) : (
        ""
      )}
      <li className="flex items-center mt-4 gap-x-2">
        <div className="w-1/2 flex items-center bg-[#FAFCFF] p-3 rounded-xl">
          <span className="block w-1/2 caption_1_600">항암 치료</span>
          <span className="block body_1_600 ">
            {user.chemotherapyOX !== undefined && user.chemotherapyOX === "O"
              ? "유"
              : "무"}
          </span>
        </div>
        <div className="w-1/2 flex items-center bg-[#FAFCFF] p-3 rounded-xl">
          <span className="block w-1/2 caption_1_600">방사선 치료</span>
          <span className="block body_1_600">
            {user.radiationtherapyOX !== undefined &&
            user.radiationtherapyOX === "O"
              ? "유"
              : "무"}
          </span>
        </div>
      </li>
      <li className="mt-4 bg-[#FAFCFF] p-3 rounded-xl flex items-center">
        <span className="block w-1/3 caption_1_600">기저질환</span>
        <span className="block body_1_600">
          {user.disease && user.disease.length > 0
            ? user.disease?.map((disease, idx) => (
                <span>
                  {disease}
                  {idx === user.disease.length - 1 ? "" : <span>,&nbsp;</span>}
                </span>
              ))
            : "-"}
        </span>
      </li>
    </ul>
  );
};

export default UserData2;
