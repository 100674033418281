import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import common, { api } from "../../../../data/common";
import { useMainContext } from "../../../../contexts/Provider";

/*components*/
import AppUseList from "./components/AppUseList";
import MainIcons from "../../mobilecomponent/MainIcons";

const Appuse = () => {
  const { loginInput, mobileHistory } = useMainContext();
  const navigate = useNavigate();
  const [allquestion, setAllquestion] = useState([]);
  const [noQuestion, setNoQuestion] = useState(false);
  const whitepen = "/images/mo/whitepen.svg";
  const sirenicon = "/images/mo/siren_black.svg";
  const jangruboyhi = "/images/mo/jangruboyhi.svg";
  const leftarrow = "/images/mo/leftarrow.svg";
  //2차개발
  // window.onpopstate = function () {
  //   console.log("작동");
  //   navigate(1);
  // };
  useEffect(() => {
    let pageOpen = false;
    const getQuestion = () => {
      api.get(common.baseURL + "question/p/" + loginInput.id).then((res) => {
        if (res.data.ok === true) {
          setAllquestion(res.data.questionList);
          if (res.data.questionList.length === 0) {
            setNoQuestion(true);
          }
        }
      });
    };
    if (!pageOpen) {
      if (
        loginInput.id === null ||
        loginInput.id === undefined ||
        loginInput.id === ""
      ) {
        navigate("/main", { replace: true });
      } else {
        getQuestion();
        // if (mobileHistory[mobileHistory.length - 1] === "/myinfo/appusewrite") {
        //   navigate("/myinfo");
        // }
      }
    }
    return () => {
      pageOpen = true;
    };
  }, [loginInput.id, mobileHistory, navigate]);

  const goAppuseWrite = () => {
    navigate(
      "/myinfo/appusewrite"
      //  {
      //   state: {
      //     postState: true,

      //     // hospitalInfoList: hospitalInfoList,
      //   },
      // }
    );
  };

  const goMyinfo = () => {
    navigate("/myinfo");
  };
  return (
    <>
      <div className="h-screen bg-[#FAFCFF] relative overflow-y-auto">
        <div className="fixed top-0 bg-[#FAFCFF] left-0 w-full z-3 subtitle_1_400">
          <div className="w-full border-b-[1px] flex justify-between items-center px-[8px]">
            <img
              className="inline-block cursor-pointer "
              src={leftarrow}
              alt="leftarrow"
              onClick={goMyinfo}
            />
            <span className="inline-block">1:1 문의</span>
            <span className="inline-block w-[48px] h-[48px]"></span>
          </div>
        </div>
        <div className="mt-[48px] w-full bg-[#E5E6E7] px-[27px] py-[16px] flex items-start">
          <img src={sirenicon} alt="sirenicon" className="pr-[15px]" />
          <div className="subtitle_1_600">
            <span className="block ">답변 가능 시간</span>
            <span className="block ">평일 AM 9:00 ~ PM 6:00</span>
          </div>
        </div>

        {allquestion?.map((el, index) => (
          <AppUseList el={el} key={index} />
        ))}
        {noQuestion ? (
          <div className=" pt-[200px] flex justify-center items-center flex-col">
            <img src={jangruboyhi} alt={jangruboyhi} />
            <span className="block pt-[16px]">앱 이용문의를 </span>
            <span className="block">남겨보세요!</span>
          </div>
        ) : null}
        <div className="h-[62px]"></div>
      </div>
      <span
        className="fixed z-5 bottom-[48px] right-0 inline-block bg-[#1B5EDC] p-3 w-[48px] h-[48px] mb-6 mr-10 rounded-full cursor-pointer"
        onClick={goAppuseWrite}
      >
        <img src={whitepen} alt="whitepen" className="w-full h-full" />
      </span>

      <MainIcons />
    </>
  );
};

export default Appuse;
