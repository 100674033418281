import React from "react";
import CenterModal from "../../../mobilecomponent/CenterModal";

const PhotoAlertModal = ({ setPhotoAlert }) => {
  const closebasic = "/images/mo/closebasic.svg";

  return (
    <CenterModal setPhotoAlert={setPhotoAlert}>
      <img
        src={closebasic}
        alt={closebasic}
        className="absolute top-0 right-0 cursor-pointer"
        onClick={() => {
          setPhotoAlert(false);
        }}
      />
      <span className="pt-[16px]">사진은 5장까지만 첨부가능합니다.</span>
    </CenterModal>
  );
};

export default PhotoAlertModal;
