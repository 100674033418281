import React, { useState, useEffect } from "react";
import common, { api } from "../../../../../data/common";

import { useMainContext } from "../../../../../contexts/Provider";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
const ChangePass = ({ showChangePass }) => {
  const closeicon = "/images/mo/closebasic.svg";
  const { loginInput } = useMainContext();
  const [user, setUser] = useState([]);
  const [newPWinput, setNewPWinput] = useState({
    newpass: "",
    passconfirm: "",
  });

  const [errormsg, setErrorMsg] = useState("");
  const [numlettermsg, setNumletterMsg] = useState(false);
  const navigate = useNavigate();
  const onChangeNewPw = (e) => {
    const { value, name } = e.target;
    let numletterReg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,12}$/;
    // /^(?=.*?[0-9])(?=.*?[#?!@$%^&*-~()_+=|:";'/<>,.{}]).{8,}$/;
    setNewPWinput({ ...newPWinput, [name]: value.slice(0, 12) });

    if (name === "newpass") {
      if (value.match(numletterReg) && value.length >= 8) {
        setNumletterMsg("안전한 비밀번호입니다.");
      } else {
        setNumletterMsg(
          "숫자, 영문자, 특수문자 포함 8-12자리로 입력해 주세요."
        );
      }
    } else {
      if (
        (newPWinput.newpass !== "" || newPWinput.passconfirm !== "") &&
        e.target.value !== ""
      ) {
        if (newPWinput.newpass === e.target.value && e.target.value !== "") {
          setErrorMsg("일치합니다.");
        } else {
          setErrorMsg("비밀번호가 일치하지 않습니다.");
        }
      }
    }
  };

  const editPass = async () => {
    //userid값으로 axios 만들기
    let body = {
      name: user.name,
      birth: user.birth,
      patientID: loginInput.patientID,
      newPwd: String(newPWinput.passconfirm),
    };
    console.log(body);
    if (
      newPWinput.newpass !== "" &&
      newPWinput.passconfirm !== "" &&
      newPWinput.passconfirm === newPWinput.newpass
    ) {
      api
        .post(common.baseURL + "patient/changePwd", body)
        .then((res) => {
          console.log(res);
          if (res.data.ok === true) {
            toast(`저장되었습니다.`, {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              className: "transparent toastbottom",
            });
            let start = setTimeout(() => {
              navigate("/login");
            }, 2000);
            return () => {
              clearTimeout(start);
            };
          } else {
            toast(res.data.error, {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              className: "transparent toastbottom",
            });
          }
        })
        .catch((error) => console.log(error));
    } else {
      toast(`패스워드 빈값 확인 및 일치여부 확인해주세요.`, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "transparent toastbottom",
      });
    }
  };
  useEffect(() => {
    api
      .get(common.baseURL + "patient/Id/" + loginInput.id)
      .then((Res) => {
        if (Res.data.ok === true) {
          setUser(Res.data.patient);
        }
      })
      .catch((error) => console.log(error));
  }, [loginInput.id]);
  console.log(errormsg, numlettermsg);
  return (
    <>
      <div className="fixed top-0 left-0 z-10 w-full">
        <div className="max-w-md h-screen flex flex-col mx-auto antialiased bg-[#FAFCFF] relative">
          <div className="flex justify-end">
            <img
              src={closeicon}
              alt="closebasic"
              onClick={showChangePass}
              className="cursor-pointer"
            />
          </div>
          <div className="px-[16px]">
            <strong className="text-black h3_800">새 비밀번호 입력</strong>
            <p className="block pt-[4px]">
              <span className="text-[#1B5EDC]">사용하실 새 비밀번호</span>
              <span>를 입력해주세요.</span>
            </p>
            <div
              className={
                newPWinput.newpass.length > 12
                  ? "pt-[48px] border-b-[1px] border-[#F62828]"
                  : "pt-[48px] border-b-[1px]"
              }
            >
              <label htmlFor="name" className="block caption_1_600">
                새 비밀번호 입력
              </label>
              <input
                type="password"
                className="w-full py-[12px] focus:outline-none bg-transparent"
                placeholder="숫자, 영문자, 특수문자 포함 8-12자리"
                name="newpass"
                value={newPWinput.newpass}
                onChange={onChangeNewPw}
              />
            </div>

            {numlettermsg === "안전한 비밀번호입니다." ? (
              <span className="text-blue-500 caption_1_600">
                {numlettermsg}
              </span>
            ) : (
              <span className="text-red-500 caption_1_600">{numlettermsg}</span>
            )}
            <div
              className={
                errormsg === "비밀번호가 일치하지 않습니다."
                  ? "border-b-[1px] pt-[16px] border-[#F62828]"
                  : errormsg === "일치합니다."
                  ? "border-b-[1px] pt-[16px] border-[#1B5EDC]"
                  : "border-b-[1px] pt-[16px]"
              }
            >
              <label htmlFor="name" className="block caption_1_600 ">
                새 비밀번호 확인
              </label>
              <input
                type="password"
                className="w-full py-[12px] focus:outline-none bg-transparent"
                name="passconfirm"
                value={newPWinput.passconfirm}
                onChange={onChangeNewPw}
              />
            </div>
            {errormsg === "일치합니다." ? (
              <span className="text-blue-500 caption_1_600">{errormsg}</span>
            ) : (
              <span className="text-red-500 caption_1_600">{errormsg}</span>
            )}
          </div>
        </div>
        {errormsg === "일치합니다." &&
        numlettermsg === "안전한 비밀번호입니다." ? (
          <div
            className="w-full p-[20px] fixed bottom-0 left-0 bg-[#1B5EDC] flex justify-center items-center text-white button_700 hover:bg-[#18428E] cursor-pointer  ease-in-out duration-300"
            onClick={editPass}
          >
            저장하기
          </div>
        ) : (
          <div className="w-full p-[20px] fixed bottom-0 left-0 bg-[#9DC4FA] flex justify-center items-center text-white button_700 ">
            저장하기
          </div>
        )}
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        bodyClassName={() =>
          "w-[175px] p-3 rounded-full overflow-hidden bg-[#CBDFFC] text-black caption_1_600 font-normal"
        }
      ></ToastContainer>
    </>
  );
};

export default ChangePass;
