import React from "react";

const FeelBetter = ({ bigTitle, btn1 }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400 wordbreak-keepall">
      <div className="pt-[72px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
        <h1 className="h2_800 pt-[4px] pb-[24px]">{btn1}</h1>
      </div>
      <div className="w-full pt-[24px] body_1_400_jangrucont">
        <div>
          <span className="block ">1. 몸을 움직이세요!</span>
          <div className="pl-[4px] flex">
            <span className="inline-block">–</span>
            <div className="pl-[4px]">
              정해진 시간에 짧은 시간이라도 걷고 가벼운 스트레칭을 같이 해주면서
              최대한 많이 움직이세요.
            </div>
          </div>
        </div>

        <span className="inline-block">2. 좋은 음식을 선택하세요!</span>
        <div className="flex">
          <span className="inline-block">–</span>
          <div className="pl-[4px]">
            <span className="inline-block">
              건강에 도움이 된다고 생각하는 특정 음식을 추가하는 것에 신경을
              쓰고 이 음식을 먹음으로써 몸이 어떻게 반응하고 느낄지를
              생각합니다. 천천히 씹으면서 음식을 느끼면 즐겨보세요.
            </span>
          </div>
        </div>

        <span className="inline-block">3. 재밌는 일을 만들어 보세요!</span>
        <div className="flex">
          <span className="inline-block">–</span>
          <div className="pl-[4px]">
            <span className="inline-block">
              하기 두려워 하는 일보다는 즐길 수 있는 취미를 찾으세요. 운동이나
              스포츠에 참여 하는게 싫다면 춤이나 다른 좋아하는 취미거리를
              즐겨보세요.
            </span>
          </div>
        </div>

        <span className="inline-block">
          4. 자신을 위한 시간 계획을 짜보세요!
        </span>
        <div className="flex">
          <span className="inline-block">–</span>
          <span className="inline-block pl-[4px]">
            방해받지 않는 시간을 따로 마련하여 요가, 명상, 걷기 등 자신만의
            시간을 가져봅니다.
          </span>
        </div>
        <span className="inline-block ">
          5. 자신만의 보상이나 선물을 준비하세요!
        </span>
        <div className="flex">
          <span className="inline-block ">–</span>
          <div className="pl-[4px]">
            <span className="inline-block">
              하고자 하는 목표를 정하고 이를 달성한 후 마사지, 공연 티켓,
              운동복, 책 등의 선물을 자신을 위해 하는 것이
            </span>
            <span className="inline-block">
              자연스럽게 자기훈련을 하는데 도움이 될 거에요.
            </span>
          </div>
        </div>
      </div>
      <div className="h-[80px]"></div>
    </div>
  );
};

export default FeelBetter;
