import React from "react";
import { useNavigate } from "react-router-dom";

const FoodManage3 = ({ bigTitle, btn3 }) => {
  const navigate = useNavigate();
  return (
    <div className="w-full flex flex-col px-[16px]">
      <div className="pt-[72px] w-full">
        <span className="text-[#679DEE] caption_1_400"> {bigTitle}</span>
        <h1 className="h2_800 pt-[4px] pb-[24px] tracking-tight">{btn3}</h1>
        <span className="text-[#676C76] body_1_400">
          아래 버튼을 눌러 상세한 정보를 확인할 수 있어요!
        </span>
        <div className="pt-[152px]">
          <span
            className="mb-[16px] w-full text-center inline-block border border-[#1B5EDC] p-[16px] button_700 text-[#1B5EDC] bg-[#FAFCFF] rounded-sm active:bg-[#F2F6FC] active:text-[#18428E] ease-in-out duration-300 cursor-pointer"
            onClick={(e) => {
              navigate("/more/sub/list", {
                state: {
                  bigTitle: bigTitle,

                  btn3: btn3,
                  btn3_1: e.target.innerText
                }
              });
            }}
          >
            체중이 계속 감소할 때
          </span>
          <span
            className="mb-[16px] w-full text-center inline-block border border-[#1B5EDC] p-[16px] button_700 text-[#1B5EDC] bg-[#FAFCFF] rounded-sm active:bg-[#F2F6FC] active:text-[#18428E] ease-in-out duration-300 cursor-pointer"
            onClick={(e) => {
              navigate("/more/sub/list", {
                state: {
                  bigTitle: bigTitle,

                  btn3: btn3,
                  btn3_2: e.target.innerText
                }
              });
            }}
          >
            장루 복원 후 고려사항
          </span>
          <span
            className="mb-[16px] w-full text-center inline-block border border-[#1B5EDC] p-[16px] button_700 text-[#1B5EDC] bg-[#FAFCFF] rounded-sm active:bg-[#F2F6FC] active:text-[#18428E] ease-in-out duration-300 cursor-pointer"
            onClick={(e) => {
              navigate("/more/sub/list", {
                state: {
                  bigTitle: bigTitle,

                  btn3: btn3,
                  btn3_3: e.target.innerText
                }
              });
            }}
          >
            장루 복원 후 식사관리
          </span>
          <span
            className="mb-[16px] w-full text-center inline-block border border-[#1B5EDC] p-[16px] button_700 text-[#1B5EDC] bg-[#FAFCFF] rounded-sm active:bg-[#F2F6FC] active:text-[#18428E] ease-in-out duration-300 cursor-pointer"
            onClick={(e) => {
              navigate("/more/sub/list", {
                state: {
                  bigTitle: bigTitle,

                  btn3: btn3,
                  btn3_4: e.target.innerText
                }
              });
            }}
          >
            장루가 막혔을 때는?
          </span>
        </div>
      </div>
    </div>
  );
};

export default FoodManage3;
