import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMainContext } from "../../../../../contexts/Provider";

import common, { api } from "../../../../../data/common";
const Check5 = ({ checkInfo, setCheckInfo }) => {
  const [state1, setState1] = useState(false);
  const [state2, setState2] = useState(false);
  const navigate = useNavigate();
  const { loginInput } = useMainContext();
  useEffect(() => {
    let pageOpen = false;
    const changeSkinComplication = () => {
      if (checkInfo.skinComplicationsOX === "O") {
        setState1(true);
      }
      if (checkInfo.skinComplicationsOX === "X") {
        setState2(true);
      }
    };
    if (!pageOpen) {
      changeSkinComplication();
    }
    return () => {
      pageOpen = true;
    };
  }, [checkInfo]);
  const onClickState1 = (e) => {
    setState1(!state1);
    setState2(false);
    setCheckInfo({ ...checkInfo, skinComplicationsOX: "O" });
  };
  const onClickState2 = (e) => {
    setState2(!state2);
    setState1(false);
    setCheckInfo({ ...checkInfo, skinComplicationsOX: "X" });
  };
  const goCheck5 = () => {
    navigate(`/check/5`);
  };
  const goCheck7or8 = ({ loginInput }) => {
    api.get(common.baseURL + "patient/Id/" + loginInput.id).then((res) => {
      if (res.data.ok === true) {
        if (res.data.patient.jangruType === "회장루") {
          navigate(`/check/7`);
        } else {
          navigate("/check/8");
        }
      }
    });
  };
  const coloredBg =
    "mb-[12px] border rounded w-full px-[24px] py-[16px] cursor-pointer bg-[#CBDFFC] border-[#1B5EDC] body_1_600";
  const noneBg =
    "mb-[12px] border rounded w-full px-[24px] py-[16px] cursor-pointer bg-[#F2F6FC] border-[#9DC4FA] active:bg-[#CBDFFC]";
  return (
    <>
      <div className="pt-[94px]">
        <h1 className="h3_800 px-[24px] pb-[80px] wordbreak-keepall">
          지난 일주일 동안 장루 주변의 피부에 이상 증상 혹은 불편한 증상이
          있으셨나요?
        </h1>
        <div className="px-[24px] text-[#4E535F]">
          <div
            className={
              checkInfo.skinComplicationsOX === "O" ? coloredBg : noneBg
            }
            onClick={onClickState1}
          >
            ⭕️ &nbsp;있다
          </div>
          <div
            className={
              checkInfo.skinComplicationsOX === "X" ? coloredBg : noneBg
            }
            onClick={onClickState2}
          >
            ❌ &nbsp;없다
          </div>
        </div>
      </div>
      {checkInfo.skinComplicationsOX !== "" ? (
        <div className="fixed  bottom-0 left-0 w-full  flex ">
          <div
            className="border w-2/5 py-[20px] flex justify-center items-center cursor-pointer 
             bg-[#FAFCFF] active:bg-[#F2F6FC] border-[#679DEE] active:border-[#18428E] text-[#1B5EDC] active:text-[#18428E] button_700 ease-in-out duration-300"
            onClick={goCheck5}
          >
            이전
          </div>
          <div
            className=" w-3/5 py-[20px]  flex justify-center items-center cursor-pointer bg-[#1B5EDC] text-white button_700 active:bg-[#18428E] ease-in-out duration-300"
            onClick={() => {
              goCheck7or8({ loginInput });
            }}
          >
            다음
          </div>
        </div>
      ) : (
        <div className="fixed  bottom-0 left-0 w-full  flex ">
          <div
            className="border w-2/5  py-[20px] flex justify-center items-center cursor-pointer
            bg-[#FAFCFF] active:bg-[#F2F6FC] border-[#679DEE] active:border-[#18428E] text-[#1B5EDC] button_700 active:text-[#18428E] ease-in-out duration-300"
            onClick={goCheck5}
          >
            이전
          </div>
          <div className=" w-3/5 py-[20px]  flex justify-center items-center bg-[#9DC4FA] button_700 text-white ease-in-out duration-300">
            다음
          </div>
        </div>
      )}
    </>
  );
};

export default Check5;
