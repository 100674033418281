import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../../../contexts/Provider";

import common, { api } from "../../../../../data/common";
import "dayjs/locale/ko";
/*component*/
import MyInfoGoback from "./MyInfoGoback";
import TabBasic from "../../../mobilecomponent/TabBasic";
import UserData2 from "./UserData2";
import UserData1 from "./UserData1";
const ManageInfo = ({ showManageInfo }) => {
  const { loginInput } = useMainContext();
  const [user, setUser] = useState("");
  const [surveydayarr, setSureveyDayArr] = useState([]);
  const [page1, setPage1] = useState(false);
  const [page2, setPage2] = useState(false);
  useEffect(() => {
    let pageOpen = false;
    const getPatientId = () => {
      api
        .get(common.baseURL + "patient/Id/" + loginInput.id)
        .then((res) => {
          // console.log(res, "patiendInfo");
          if (res.data.ok === true) {
            setUser(res.data.patient);
            setSureveyDayArr(res.data.patient.surveyDay);
          }
        })
        .catch((error) => console.log(error));
    };
    if (!pageOpen) {
      setPage1(true);
      getPatientId();
    }
    return () => {
      pageOpen = true;
    };
  }, [loginInput.id]);

  const showPage1 = () => {
    setPage1(true);
    setPage2(false);
  };
  const showPage2 = () => {
    setPage2(true);
    setPage1(false);
  };

  return (
    <div className="w-full h-screen fixed top-0 left-0 z-1 overflow-hidden">
      <div className="max-w-md h-screen  mx-auto text-gray-600 antialiased bg-[#F1F2F3] relative overflow-y-auto">
        <MyInfoGoback showManageInfo={showManageInfo} title={"내 정보 관리"} />
        <div className=" pt-[48px] bg-[#FAFCFF] border-b-[1.5px]">
          <TabBasic
            tab1={"기본정보"}
            tab2={"건강정보"}
            showPage1={showPage1}
            showPage2={showPage2}
            page1={page1}
            page2={page2}
          />
        </div>
        <div className="px-4 pb-24">
          {page1 ? <UserData1 user={user} surveydayarr={surveydayarr} /> : ""}
          {page2 ? <UserData2 user={user} /> : ""}
        </div>
      </div>
    </div>
  );
};

export default ManageInfo;
