import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";

const InspectionDefault = ({ cansurvey }) => {
  const jangruboy_wow = "/images/mo/jangruboy_wow.svg";
  const navigate = useNavigate();

  return (
    <>
      {cansurvey && cansurvey.canSurvey === true ? (
        <>
          <div className="flex flex-col justify-center items-center pt-[119px]">
            <img src={jangruboy_wow} alt="jangruboy_wow" />
            <br />
            <div>아직 저장된 기록이 없네요!</div>
            <div className="pt-[16px]">점검할 준비가 되셨다면</div>
            <div>시작하기 버튼을 눌러주세요.</div>
          </div>
          <div className="mt-[48px] w-full px-[24px]">
            <div
              className=" bg-[#1B5EDC] text-white buttom_700 py-[20px] cursor-pointer text-center hover:bg-[#18428E] rounded-full cursor-pointer  ease-in-out duration-300"
              onClick={() => navigate("/check")}
            >
              시작하기
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col justify-center items-center pt-[119px]">
            <img src={jangruboy_wow} alt="jangruboy_wow" />
            <br />
            <div>{cansurvey ? cansurvey.patient.name : ""}님의 점검날짜는</div>
            <div>
              매월 {cansurvey ? cansurvey.patient.surveyDay[0] : ""}일,
              {cansurvey ? cansurvey.patient.surveyDay[1] : ""}일 입니다.
            </div>

            {cansurvey && dayjs() < dayjs(cansurvey.patient.surveyDay[2]) ? (
              <span className="block text-sm">
                <br />
                첫번째 점검일 :{" "}
                {dayjs(cansurvey.patient.surveyDay[2]).format("YYYY년 M월 D일")}
              </span>
            ) : (
              <>
                <div className="pt-[32px]">정기 점검 날짜가 다가오면</div>
                <div>저희가 알림을 드릴게요!</div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default InspectionDefault;
