import Header2 from "../../../admin_components/Header/Header2";

import { useEffect, useRef, useState } from "react";
import { BtnBlack, BtnWhite } from "../../../admin_components/Buttons";
import commonUrl, { api } from "../../../../../data/common";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useMainContext } from "../../../../../contexts/Provider";
import { convertRole } from "../../../admin_components/ConvertAdmin";
import {
  toastCommonProps,
  findToastPosition,
} from "../../../admin_components/Toast";
import { Loading } from "../../../admin_components/Loading";
import dayjs from "dayjs";
import { handlingError } from "../../../admin_components/Error";

function Modify() {
  const { state } = useLocation(); // 이전 페이지에서 넘겨준 띄울 사용자 정보 id값
  const navigate = useNavigate();
  const { adminLogin } = useMainContext(); // 로그인 정보 Consumer
  const [toastElPosition, setToastElPosition] = useState({ top: 0, left: 0 }); // toast창 위치 값
  const [loading, setLoading] = useState({ first: true, save: false });
  const [professorList, setProfessorList] = useState([]);
  const [diseaseInput, setDiseaseInput] = useState("");
  /* 관리자 등록 정보 */
  const [client, setClient] = useState({
    birth: "", // 생일
    regNum: "", // 주민번호
    startDate: "", // 등록일자
    gender: "", // 성별
    height: 0, // 키
    weight: 0, // 현재 체중
    phone: "", //휴대폰 번호
    bmi: 0, // BMI
    jangruType: "선택", // 장루 종류
    jangruSurgeryDate: "", // 장루 수술일
    professor: "선택", // 담당교수
    jangruClosedSurgeryOX: "O", // 장루 폐쇄술 시행 여부
    jangruClosedSurgeryDate: "", // 장루 폐쇄술 시행일
    chemotherapyOX: "O", // 항암치료 유무
    radiationtherapyOX: "O", // 방사선 치료 유무
    endDate: "", // 참여 종류일
    diseaseOX: "X", // 기저질환 유무
    disease: [], // 기저 질환
    diseaseInputChecked: false,
    surgery: "선택", // 수술명
    createdAt: "", // 등록일
    updatedAt: "", // 최근 접속일
    status: 0,
  });

  const disease = [
    "고혈압",
    "폐암",
    "간암",
    "위암",
    "간경변증",
    "천식",
    "만성신장질환",
    "당뇨병",
    "빈혈",
    "관절염",
  ];

  // input 값 변경
  const change = (e) => {
    const { name, value } = e.target;

    // 체중 항목 소수점 자릿수 안맞으면
    var regExp = /^\d*.?\d{0,1}$/;
    if (name === "weight" && !regExp.test(Number(value))) {
      toast(
        <p className="text-sm">소수점 첫째자리까지 입력 가능합니다.</p>,
        toastCommonProps(1500, "top-left", "toast_common toast__normal")
      );
    }
    // 키 항목 소수점 자릿수 안맞으면
    else if (name === "height" && !regExp.test(Number(value))) {
      toast(
        <p className="text-sm">소수점 첫째자리까지 입력 가능합니다.</p>,
        toastCommonProps(1500, "top-left", "toast_common toast__normal")
      );
    }
    // 휴대폰 번호 - 자동입력
    else if (name === "phone") {
      const regPhone1 = /^([0-9]{3})$/;
      const regPhone2 = /^([0-9]{3})-([0-9]{4})$/;

      // 삭제중인 경우
      if (client.phone.length > value.length) {
        setClient({ ...client, [name]: value });
        return;
      }
      // 숫자가 아니고 + "-"도 아니고 + 현재까지의 값이 13자리 넘으면 넣지 말기
      else if (
        (isNaN(value.substring(value.length, value.length - 1)) &&
          value.substring(value.length, value.length - 1) !== "-") ||
        value.length > 13
      ) {
        setClient({
          ...client,
          [name]: value.substring(0, value.length - 1),
        });
      }
      // 값을 저장해도 되면
      else {
        // 길이가 3이고 정규식에 부합 || 길이가 8이고 정규식에 부합 -> 작대기 넣기
        if (
          (value.length === 3 && regPhone1.test(value)) ||
          (value.length === 8 && regPhone2.test(value))
        ) {
          setClient({ ...client, [name]: value + "-" });
        }
        // 그 밖의 경우
        else setClient({ ...client, [name]: value });
      }
    } else if (name === "disease") {
      const isChecked = e.target.checked;
      const value = e.target.value;
      if (isChecked) {
        setClient({ ...client, disease: [...client.disease, value] });
      } else {
        setClient({
          ...client,
          disease: client.disease.filter((origin) => origin !== value),
        });
      }
    } else {
      setClient({ ...client, [name]: value });
    }
  };
  console.log(client);
  // 제출 전에 제출 형식에 맞는 지 확인
  const checkForSave = () => {
    let msg = ""; // 형식 안맞으면 보여줄 메세지

    const {
      name,
      patientID,
      birth,
      regNum,
      height,
      weight,
      surgery,
      jangruType,
      jangruSurgeryDate,
      professor,
      jangruClosedSurgeryOX,
      jangruClosedSurgeryDate,
      patientPwd,
      phone,
      startDate,
      endDate,
    } = client;

    // 핸드폰 번호 정규표현식
    const regPhone = /^01([0|1|6|7|8|9])-([0-9]{4})-([0-9]{4})$/;
    // 유효성 체크
    if (name.trim() === "") {
      msg = "이름을 입력해 주세요.";
    }

    // 등록번호
    else if (patientID.trim() === "") {
      msg = "등록번호를 입력해 주세요.";
    }
    // // 생일
    // else if (birth.trim() === "") {
    //   msg = "생일을 입력해 주세요.";
    // }
    // 주민번호
    // else if (regNum.trim() === "") {
    //   msg = "주민번호를 입력해 주세요.";
    // }
    // 키

    // 장루 종류
    else if (jangruType.trim() === "") {
      msg = "장루 종류를 입력해 주세요.";
    } // 장루 수술일, 장루 폐쇄술 시행여부, 장루 폐쇄술 시행일
    else if (jangruSurgeryDate === "") {
      msg = "장루 수술일을 입력해 주세요.";
    } else if (
      jangruClosedSurgeryOX === "O" &&
      jangruClosedSurgeryDate === ""
    ) {
      msg = "장루 폐쇄술 시행여부와 장루 폐쇄술 시행일을 입력해 주세요.";
    } // 등록일자
    else if (startDate === "") {
      msg = "등록일자를 입력해 주세요.";
    }

    // 수술명
    // else if (surgery.trim() === "") {
    //   msg = "수술명을 입력해 주세요.";
    // }

    // 휴대폰 번호 형식 체크
    else if (!regPhone.test(phone)) {
      msg = "휴대폰 번호 형식이 알맞지 않습니다.";
    }

    // 모두 통과
    if (msg === "") return true;
    // 형식 오류 있을 때
    else {
      toast(
        <p>{msg}</p>,
        toastCommonProps(1500, "top-left", "toast_common toast__normal")
      );
      return false;
    }
  };

  // 저장하기
  const save = () => {
    // toast창 위치 설정
    setToastElPosition({
      top: findToastPosition("top"),
      left: findToastPosition("left"),
    });

    if (checkForSave()) {
      // BMI 계산
      let newBMI =
        client.weight / ((client.height / 100) * (client.height / 100));
      newBMI = Math.round(Number(newBMI.toFixed(2)) * 10) / 10;

      // DB에 보낼 데이타
      let newData = {
        id: state.id, // 대상자 정보 수정 시 필요
        name: client.name,
        // regNum: client.regNum,
        phone: client.phone,
        jangruType: client.jangruType,
        jangruSurgeryDate: client.jangruSurgeryDate,
        jangruClosedSurgeryOX: client.jangruClosedSurgeryOX,
        chemotherapyOX: client.chemotherapyOX,
        radiationtherapyOX: client.radiationtherapyOX,
        surgery: client.surgery,
        jangruClosedSurgeryDate:
          client.jangruClosedSurgeryOX === "O"
            ? client.jangruClosedSurgeryDate
            : null,
        recentAdmin: adminLogin.id,
        // startDate: client.startDate,
        status: Number(client.status),
        disease: client.diseaseInputChecked
          ? [...client.disease, diseaseInput]
          : client.disease,
      };
      // console.log(newData, ">>> newData");

      // Axios
      async function fetchData() {
        setLoading({ ...loading, save: true });
        const res = await api({
          method: "post",
          url: commonUrl.patient + "edit",
          data: newData,
        }).catch((error) => {
          handlingError(error, "top-left", "toast_common toast__normal");
        });
        console.log(res.data, ">>> Axios patient + edit");
        // axios 성공시
        if (res.data.ok) {
          toast(
            <p>저장되었습니다.</p>,
            toastCommonProps(1500, "top-left", "toast_common toast__normal")
          );
          setTimeout(() => {
            setLoading({ ...loading, save: false });
            navigate("/admin/main/client");
          }, 2000);
        }
        // axios 실패시
        else {
          setLoading(false);
          toast(
            <p>{res.data.error}</p>,
            toastCommonProps(1500, "top-left", "toast_common toast__normal")
          );
        }
      }
      fetchData();
    }
  };

  // toast창 위치 설정
  useEffect(() => {
    setToastElPosition({
      top: findToastPosition("top"),
      left: findToastPosition("left"),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*** 첫 랜더시 정보 불러오기***/
  useEffect(() => {
    // let isMounted = true; //  컴포넌트의 마운트 상태를 추적

    async function fetchData() {
      const response = await api({
        method: "get",
        url: commonUrl.patient + "Id/" + state.id,
      }).catch((error) => {
        handlingError(error);
      });
      // if (isMounted) {
      console.log(response.data, " >>> Axios patient id/id");
      setLoading({ ...loading, first: false });
      // axios 성공 시
      if (response.data.ok && response.data.patient) {
        const p = response.data.patient;

        setDiseaseInput(
          p.disease
            ? p.disease.filter((prev) => !disease?.includes(prev))?.[0]
            : ""
        );
        // 표에 적합한 형식으로 다듬어서 저장
        setClient({
          ...p,
          recentEditAdmin: p.recentEditAdmin
            ? dayjs(new Date(p.recentEditAdmin))
            : null,
          jangruClosedSurgeryDate:
            p.jangruClosedSurgeryDate === null ? "" : p.jangruClosedSurgeryDate,
          status: p.status,
          diseaseOX: p.disease && p.disease?.length !== 0 ? "O" : "X",
          diseaseInputChecked:
            p.disease &&
            p.disease.filter((prev) => !disease?.includes(prev)).length > 0,
          disease: p.disease
            ? p.disease?.filter((prev) => disease?.includes(prev))
            : [],
        });
      }
      // axios 실패시
      else {
        toast(
          <p>{response.data.error}</p>,
          toastCommonProps(1500, "top-center", "toast_common toast__normal")
        );
      }

      api
        .get(commonUrl.admin + "all/" + adminLogin.belong)
        .catch((error) => {
          handlingError(error);
        })
        .then((res) => {
          if (res.data.ok) {
            setProfessorList(
              res.data.adminList.filter((list) => list.role === 1)
            );
          }
        });
    }
    // }
    fetchData();

    // return () => {
    //   isMounted = false;
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header2
        title={[
          { title: "대상자 등록/관리", url: -1 },
          { title: "대상자 확인/수정", url: 0 },
        ]}
      >
        <style jsx="true">{`
          .Toastify__toast {
            top: ${toastElPosition.top - 20}px;
            left: ${toastElPosition.left - 295}px;
          }
        `}</style>
        <ToastContainer />
        <div className="admin flex flex-col grow p-[32px]">
          <div className="h-[80px] min-h-[80px]"></div>
          <label className="text-2xl font-extrabold leading-8 text-[#020A1B] mb-3">
            대상자 확인/수정
          </label>
          {loading.first ? (
            <Loading />
          ) : (
            <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] min-w-[996px]">
              {/* 표 */}
              <div className="grid grid-cols-[16%_16%_19%_16%_17%_16%]  text-center">
                {/* 이름 */}
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  *이름
                </label>
                {adminLogin.power === 0 && adminLogin.belong !== 1 ? (
                  <div className="flex p-[4px] w-full border border-[#D7E5F8] font-normal box-border">
                    <input
                      type="text"
                      className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#D7E5F8] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none"
                      name="name"
                      onChange={change}
                      value={client.name}
                    />
                  </div>
                ) : (
                  <label className="flex justify-center items-center border border-[#D7E5F8] font-normal p-[5px]">
                    {client.name}
                  </label>
                )}
                {/* 등록 번호 */}
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border">
                  *등록번호
                </label>
                <label className="flex justify-center items-center border border-[#D7E5F8] font-normal p-[5px]">
                  {client.patientID}
                </label>
                {/* 주민등록 번호 */}
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                  *주민등록번호
                </label>
                <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border text-[#020A1B]">
                  <input
                    type="text"
                    className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none"
                    name="regNum"
                    value={
                      client.regNum
                        ? client.regNum.slice(0, 6) +
                          "-" +
                          client.regNum.slice(6)
                        : ""
                    }
                    disabled
                  />
                </div>
                {/* 성별 */}
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                  *성별
                </label>
                {/* 슈퍼관리자이고 헤링스 소속이 아니면 */}
                {adminLogin.power === 0 && adminLogin.belong !== 1 ? (
                  <div className="flex p-[4px] w-full border border-[#D7E5F8] font-normal box-border">
                    <div className="flex items-center justify-evenly p-[4px] w-full font-normal box-border">
                      <label>
                        <input
                          type="radio"
                          name="gender"
                          value="M"
                          onChange={change}
                          className="mr-[5px]"
                          checked={client.gender === "M"}
                          disabled
                        />
                        남
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="gender"
                          value="F"
                          onChange={change}
                          className="mr-[5px]"
                          checked={client.gender === "F"}
                          disabled
                        />
                        여
                      </label>
                    </div>
                  </div>
                ) : (
                  <label className="flex justify-center items-center border border-[#D7E5F8] font-normal p-[5px]">
                    {client.gender === "M" ? "남자" : "여자"}
                  </label>
                )}

                {/* 생년 월일 */}
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border">
                  *생년월일
                </label>
                {adminLogin.power === 0 && adminLogin.belong !== 1 ? (
                  <div className="flex p-[4px] w-full border border-[#D7E5F8] font-normal box-border">
                    <input
                      type="date"
                      max={dayjs(new Date()).format("YYYY-MM-DD")}
                      className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#D7E5F8] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none"
                      name="birth"
                      onChange={change}
                      value={client.birth}
                      disabled
                    />
                  </div>
                ) : (
                  <label className="flex justify-center items-center border border-[#D7E5F8] font-normal p-[5px]">
                    {client.birth}
                  </label>
                )}

                {/* 닉네임 */}
                {/* <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  닉네임
                </label>
                <label className="flex justify-center items-center border border-[#D7E5F8] font-normal p-[5px]">
                  {client.nickName}
                </label> */}
                {/* 휴대폰 번호 */}
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  *휴대폰 번호
                </label>
                {adminLogin.power === 0 && adminLogin.belong !== 1 ? (
                  <div className="flex p-[4px] w-full border border-[#D7E5F8] font-normal box-border">
                    <input
                      type="text"
                      className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#D7E5F8] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none"
                      name="phone"
                      value={client.phone}
                      placeholder="010-1234-5678"
                      onChange={change}
                    />
                  </div>
                ) : (
                  <label className="flex justify-center items-center border border-[#D7E5F8] font-normal p-[5px]">
                    {client.phone}
                  </label>
                )}
              </div>

              <div className="grid grid-cols-[16%_16%_19%_16%_17%_16%]  text-center mt-5">
                {/* 등록일자 */}
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                  *등록일자
                </label>
                <label className="flex justify-center items-center border border-[#D7E5F8] font-normal p-[5px]">
                  {client.startDate}
                </label>

                {/* 장루 정기 점검일 */}
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                  장루 정기 점검일
                </label>
                <label className="flex justify-center items-center border border-[#D7E5F8] font-normal p-[5px] col-span-1 text-left pl-[10px]">
                  {client.surveyDay
                    ? `매 월 ${client.surveyDay[0]}일, ${client.surveyDay[1]}일`
                    : null}
                </label>

                {/* 수술명 */}
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                  수술명
                </label>
                {adminLogin.power === 0 && adminLogin.belong !== 1 ? (
                  <div className="flex p-[4px] w-full border border-[#D7E5F8] font-normal box-border">
                    <select
                      className="w-full mx-[1px] px-[5px] py-[3px] border border-[#D7E5F8] focus:outline-none "
                      name="surgery"
                      value={client.surgery}
                      onChange={change}
                    >
                      <option value="선택">선택</option>
                      <option value="전방절제술">전방절제술</option>
                      <option value="저위전방절제술">저위전방절제술</option>
                      <option value="초저위전방절제술">초저위전방절제술</option>
                      <option value="복회음절제술">복회음절제술</option>
                      <option value="하트만씨수술">하트만씨수술</option>
                      <option value="결장전절제술">결장전절제술</option>
                      <option value="회장낭-항문문합술">
                        회장낭-항문문합술
                      </option>
                      <option value="우측결장반절제술">우측결장반절제술</option>
                      <option value="장루조성술">장루조성술</option>
                      <option value="소장절제술">소장절제술</option>
                      <option value="복강경탐색술">복강경탐색술</option>
                      <option value="장적출술">장적출술</option>
                      <option value="종양감축술">종양감축술</option>
                    </select>
                  </div>
                ) : (
                  <label className="flex justify-center items-center border border-[#D7E5F8] font-normal p-[5px]">
                    {client.surgery}
                  </label>
                )}

                {/* 장루 종류 */}
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  *장루 종류
                </label>
                {adminLogin.power === 0 && adminLogin.belong !== 1 ? (
                  <div className="flex p-[4px] w-full border border-[#D7E5F8] font-normal box-border">
                    <select
                      className="w-full mx-[1px] px-[5px] py-[3px] border border-[#D7E5F8] focus:outline-none"
                      name="jangruType"
                      value={client.jangruType}
                      onChange={change}
                    >
                      <option value="선택">선택</option>
                      <option value="공장루">공장루</option>
                      <option value="하행/구불결장루">하행/구불결장루</option>
                      <option value="횡행결장루">횡행결장루</option>
                      <option value="상행결장루">상행결장루</option>
                      <option value="회장루">회장루</option>
                    </select>
                  </div>
                ) : (
                  <label className="flex justify-center items-center border border-[#D7E5F8] font-normal p-[5px]">
                    {client.jangruType}
                  </label>
                )}

                {/* 장루 수술일 */}
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  *장루 수술일
                </label>
                {adminLogin.power === 0 && adminLogin.belong !== 1 ? (
                  <div className="flex p-[4px] w-full border border-[#D7E5F8] font-normal box-border">
                    <input
                      type="date"
                      max={dayjs(new Date()).format("YYYY-MM-DD")}
                      className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#D7E5F8] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none"
                      name="jangruSurgeryDate"
                      onChange={change}
                      value={client.jangruSurgeryDate}
                    />
                  </div>
                ) : (
                  <label className="flex justify-center items-center border border-[#D7E5F8] font-normal p-[5px]">
                    {client.jangruSurgeryDate}
                  </label>
                )}

                {/* 장루 폐쇄술 시행여부 */}
                <label className="flex justify-start items-center px-4 py-[7px] text-left border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  *장루 폐쇄술 시행여부
                </label>
                {adminLogin.power === 0 && adminLogin.belong !== 1 ? (
                  <div className="flex p-[4px] w-full border border-[#D7E5F8] font-normal box-border">
                    <div className="flex items-center justify-evenly p-[4px] w-full font-normal box-border">
                      <label>
                        <input
                          type="radio"
                          name="jangruClosedSurgeryOX"
                          value="O"
                          onChange={change}
                          className="mr-[5px]"
                          checked={client.jangruClosedSurgeryOX === "O"}
                        />
                        유
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="jangruClosedSurgeryOX"
                          value="X"
                          onChange={change}
                          className="mr-[5px]"
                          checked={client.jangruClosedSurgeryOX === "X"}
                        />
                        무
                      </label>
                    </div>
                  </div>
                ) : (
                  <label className="flex justify-center items-center border border-[#D7E5F8] font-normal p-[5px]">
                    {client.jangruClosedSurgeryOX === "O" ? "유" : "무"}
                  </label>
                )}

                {/* 장루 폐쇄술 시행일 */}
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  *장루 폐쇄술 시행일
                </label>
                {adminLogin.power === 0 && adminLogin.belong !== 1 ? (
                  <div className="flex p-[4px] w-full border border-[#D7E5F8] font-normal box-border">
                    <input
                      type="date"
                      max="2100-01-01"
                      className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#D7E5F8] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none"
                      name="jangruClosedSurgeryDate"
                      onChange={change}
                      value={client.jangruClosedSurgeryDate}
                      disabled={client.jangruClosedSurgeryOX === "X"}
                      style={{
                        backgroundColor:
                          client.jangruClosedSurgeryOX === "X"
                            ? "rgb(226 232 240)"
                            : "transparent",
                        color:
                          client.jangruClosedSurgeryOX === "X"
                            ? "transparent"
                            : "#666",
                      }}
                    />
                  </div>
                ) : (
                  <label className="flex justify-center items-center border border-[#D7E5F8] font-normal p-[5px]">
                    {client.jangruClosedSurgeryOX === "O"
                      ? "해당 없음"
                      : client.jangruClosedSurgeryDate}
                  </label>
                )}

                {/* 항암 치료 */}
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  *항암 치료
                </label>
                {adminLogin.power === 0 && adminLogin.belong !== 1 ? (
                  <div className="flex p-[4px] w-full border border-[#D7E5F8] font-normal box-border">
                    <div className="flex items-center justify-evenly p-[4px] w-full font-normal box-border">
                      <label>
                        <input
                          type="radio"
                          name="chemotherapyOX"
                          value="O"
                          onChange={change}
                          className="mr-[5px]"
                          checked={client.chemotherapyOX === "O"}
                        />
                        유
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="chemotherapyOX"
                          value="X"
                          onChange={change}
                          className="mr-[5px]"
                          checked={client.chemotherapyOX === "X"}
                        />
                        무
                      </label>
                    </div>
                  </div>
                ) : (
                  <label className="flex justify-center items-center border border-[#D7E5F8] font-normal p-[5px]">
                    {client.chemotherapyOX === "O" ? "유" : "무"}
                  </label>
                )}

                {/* 방사선 치료 */}
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  *방사선 치료
                </label>
                {adminLogin.power === 0 && adminLogin.belong !== 1 ? (
                  <div className="flex p-[4px] w-full border border-[#D7E5F8] font-normal box-border">
                    <div className="flex items-center justify-evenly p-[4px] w-full font-normal box-border">
                      <label>
                        <input
                          type="radio"
                          name="radiationtherapyOX"
                          value="O"
                          onChange={change}
                          className="mr-[5px]"
                          checked={client.radiationtherapyOX === "O"}
                        />
                        유
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="radiationtherapyOX"
                          value="X"
                          onChange={change}
                          className="mr-[5px]"
                          checked={client.radiationtherapyOX === "X"}
                        />
                        무
                      </label>
                    </div>
                  </div>
                ) : (
                  <label className="flex justify-center items-center border border-[#D7E5F8] font-normal p-[5px]">
                    {client.radiationtherapyOX === "O" ? "유" : "무"}
                  </label>
                )}

                {/* 기저질환 */}
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border ">
                  기저질환
                </label>
                {adminLogin.power === 0 && adminLogin.belong !== 1 ? (
                  <>
                    <div className="flex items-center justify-evenly p-[4px] w-full font-normal box-border text-[#020A1B] border border-[#E5E6E7]">
                      <label>
                        <input
                          type="radio"
                          name="diseaseOX"
                          value="O"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setClient({ ...client, diseaseOX: "O" });
                            }
                          }}
                          className="mr-[5px]"
                          checked={client.diseaseOX === "O"}
                        />
                        유
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="diseaseOX"
                          value="X"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setClient({
                                ...client,
                                diseaseOX: "X",
                                disease: [],
                                diseaseInputChecked: false,
                              });
                              setDiseaseInput("");
                            }
                          }}
                          className="mr-[5px]"
                          checked={client.diseaseOX === "X"}
                        />
                        무
                      </label>
                    </div>
                    <div className="col-span-3 p-[4px] px-2  w-full border border-[#E5E6E7] font-normal box-border text-[#020A1B]">
                      <div className="flex flex-wrap items-center gap-3">
                        {disease.map((oneDisease, idx) => (
                          <div key={idx} className="flex items-center gap-1">
                            <input
                              className="h-6"
                              type="checkbox"
                              id={oneDisease}
                              name="disease"
                              disabled={client.diseaseOX === "X"}
                              onChange={change}
                              value={oneDisease}
                              checked={client.disease?.includes(oneDisease)}
                            />
                            <label
                              className="h-6 shrink-0"
                              htmlFor={oneDisease}
                            >
                              {oneDisease}
                            </label>
                          </div>
                        ))}
                      </div>

                      <div className="flex items-center gap-1">
                        <input
                          type="checkbox"
                          id="diseaseInputChecked"
                          name="diseaseInputChecked"
                          disabled={client.diseaseOX === "X"}
                          onClick={(e) => {
                            setClient({
                              ...client,
                              diseaseInputChecked: !client.diseaseInputChecked,
                            });
                            if (!e.target.checked) {
                              setDiseaseInput("");
                            }
                          }}
                          checked={client.diseaseInputChecked}
                        />
                        <label
                          className="shrink-0"
                          htmlFor={"diseaseInputChecked"}
                        >
                          직접입력
                        </label>
                        <input
                          type="text"
                          className="border border-[#E5E6E7] px-[5px] w-full"
                          disabled={!client.diseaseInputChecked}
                          value={diseaseInput}
                          onChange={(e) => setDiseaseInput(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <label className="flex flex-wrap justify-center items-center border border-[#D7E5F8] font-normal p-[5px] col-span-2">
                    {(client.disease && client.disease.length > 0) ||
                    client.diseaseInput !== ""
                      ? [...client.disease, diseaseInput].map(
                          (disease, idx) => (
                            <span className="shrink-0">
                              {disease}
                              {idx ===
                              [...client.disease, diseaseInput].length - 1 ? (
                                ""
                              ) : (
                                <span>,&nbsp;</span>
                              )}
                            </span>
                          )
                        )
                      : "-"}
                  </label>
                )}
              </div>

              <div className="grid grid-cols-[16%_35%_12%_12.5%_12%_12.5%] text-center mt-5">
                {/* 등록 /수정 정보 */}
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                  등록/수정 정보
                </label>
                <div className="flex flex-col justify-center items-start pl-[10px] border border-[#D7E5F8] font-normal p-[5px] text-sm ">
                  {client.admin ? (
                    <p className="mb-[5px]">{`최초 등록  ${dayjs(
                      new Date(client.createdAt)
                    ).format("YYYY-MM-DD A hh시 mm분")} / ${
                      client.admin.name
                    } ${convertRole(client.admin.role)}`}</p>
                  ) : null}
                  {client.recentAdmin && client.recentEditAdmin !== null ? (
                    <p>{`마지막 수정  ${client.recentEditAdmin.format(
                      "YYYY-MM-DD A hh시 mm분"
                    )} / ${client.recentAdmin.name} ${convertRole(
                      client.recentAdmin.role
                    )}`}</p>
                  ) : null}
                </div>

                {/* 최근 접속일 */}
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                  최근 앱 접속일
                </label>
                <label className="flex justify-center items-center border border-[#D7E5F8] font-normal p-[5px]">
                  {client.recentEnter
                    ? dayjs(new Date(client.recentEnter)).format("YYYY-MM-DD")
                    : "접속 기록 없음"}
                </label>

                {/* 상태 변경 */}
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                  상태 변경
                </label>
                {adminLogin.power === 0 && adminLogin.belong !== 1 ? (
                  <div className="flex p-[4px] w-full border border-[#D7E5F8] font-normal box-border">
                    <div className="flex items-center justify-evenly p-[4px] w-full font-normal box-border">
                      <label>
                        <input
                          type="radio"
                          name="status"
                          value={1}
                          onChange={change}
                          className="mr-[5px]"
                          checked={Number(client.status) === 1}
                        />
                        비활성
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="status"
                          value={0}
                          onChange={change}
                          className="mr-[5px]"
                          checked={Number(client.status) === 0}
                        />
                        활성
                      </label>
                    </div>
                  </div>
                ) : (
                  <label className="flex justify-center items-center border border-[#D7E5F8] font-normal p-[5px]">
                    {client.status === 0 ? "활성" : "비활성"}
                  </label>
                )}
              </div>

              {/* 저장하기 버튼 */}
              {adminLogin.power === 0 && adminLogin.belong !== 1 ? (
                <div className="flex justify-end mt-[20px]">
                  <div className="flex justify-between gap-x-3">
                    <BtnWhite
                      context="목록"
                      onClick={() => {
                        navigate(-1);
                      }}
                      style={{ width: "88px" }}
                      standard="toast_standard"
                    />
                    <BtnBlack
                      context="저장하기"
                      onClick={save}
                      loading={loading.save}
                      style={{ width: "88px" }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </Header2>
    </>
  );
}

export default Modify;
