import React, { useState, useEffect } from "react";
import common, { api } from "../../../../../data/common";
import ToggleButton from "react-toggle-button";
import { Flip, toast, ToastContainer } from "react-toastify";
import { toastCommonProps } from "../../../admin_components/Toast";

const ToggleBtnComment = ({ commentcont, commudata, index }) => {
  const [on, setOn] = useState(false);
  const postingid = commudata.id;
  const commentid = commentcont.id;
  const borderRadiusStyle = { borderRadius: 2 };

  useEffect(() => {
    let pageOpen = false;
    const ToggleSet = async () => {
      await api
        .get(common.baseURL + "community/posting/idForAdmin/" + postingid)
        .then((res) => {
          if (res.data.ok === true) {
            if (res.data.commentList[index].public === 0) setOn(true);
          } else setOn(false);
        })
        .catch((error) => console.log(error));
    };
    if (!pageOpen) {
      ToggleSet();
    }
    return () => {
      pageOpen = true;
    };
  }, [index, postingid]);

  const onClickToggle = () => {
    let toast_text;

    on
      ? (toast_text = "댓글 비공개 처리 되었습니다.")
      : (toast_text = "댓글 공개 처리 되었습니다.");

    toast(
      <p>{toast_text}</p>,
      toastCommonProps(2000, "top-right", "toast_common toast__search", Flip)
    );
    api
      .post(common.baseURL + "community/comment/changePublic", {
        id: commentid,
        public: on ? 1 : 0,
      })
      .then((res) => {
        if (res.data.ok === true) {
          on ? setOn(false) : setOn(true);
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <div>
      <ToggleButton
        value={on}
        thumbStyle={borderRadiusStyle}
        trackStyle={borderRadiusStyle}
        standard="toast_standard"
        onToggle={() => {
          onClickToggle();
        }}
      />
      <ToastContainer />
    </div>
  );
};

export default ToggleBtnComment;
