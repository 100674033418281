import { useEffect, useRef, useState } from "react";
import commonUrl, { api } from "../../../../data/common";
import TableBlue from "../../admin_components/TableBlue";
import { BtnBlue } from "../../admin_components/Buttons";
import { useNavigate } from "react-router-dom";
import Header2 from "../../admin_components/Header/Header2";
import GroupRegisterPopup from "./components/GroupRegisterPopup";
import { ToastContainer, toast, Flip } from "react-toastify";
import { useMainContext } from "../../../../contexts/Provider";
import { Loading } from "../../admin_components/Loading";
import { toastCommonProps } from "../../admin_components/Toast";
import { handlingError } from "../../admin_components/Error";
import excelDown from "../Check/components/excelDown";
import dayjs from "dayjs";

export const getAge = (birth) => {
  let today = new Date();
  let birthDay = new Date(birth);
  let age = today.getFullYear() - birthDay.getFullYear();

  let todayMonth = today.getMonth() + 1;
  let birthMonth = birthDay.getMonth() + 1;

  if (
    birthMonth > todayMonth ||
    (birthMonth === todayMonth && birthDay.getDate() > today.getDate())
  ) {
    age--;
  }
  return age;
};

export function Client() {
  const search_img = "/images/admin/search.svg";
  const { adminLogin } = useMainContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [groupRegisterOpen, setGroupRegisterOpen] = useState(false); // 단체 등록 위한 popup 열기
  /************ 검색 관련 state, ref ************/
  const [data, setData] = useState([]); // 표기할 정보 담기
  const [search, setSearch] = useState(""); // 검색할 단어
  const dataRef = useRef([]); // 검색으로 걸러진 정보 담기
  const showAllRef = useRef(false); // 전체보기 버튼 보여줄지 말지
  // const [checkedPatientList, setCheckedPatientList] = useState([]);

  // 검색버튼과 엔터키 연결
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      searchClick();
    }
  };

  // 전체 보기 클릭시
  const onClickShowAll = () => {
    setSearch("");
    setData(dataRef.current);
    showAllRef.current = false;
  };

  // 검색 버튼 클릭했을 때
  const searchClick = () => {
    // 검색할 값이 있으면
    if (search !== "") {
      const newData = dataRef.current.filter((el) => {
        return el.name.includes(search) || el.patientID.includes(search);
      });
      // 검색된 내용 없으면
      if (newData.length === 0) {
        toast(
          <p>검색된 내용이 없습니다.</p>,
          toastCommonProps(
            1500,
            "top-right",
            "toast_common toast__search",
            Flip
          )
        );
        setSearch("");
      }
      // 검색된 내용 있으면
      else {
        setData(newData);
        showAllRef.current = true;
      }
    }
    // 검색할 값이 없으면
    else {
      setData(dataRef.current);
      setSearch("");
      showAllRef.current = false;
    }
  };

  // 첫 랜더시 데이터 불러오기
  useEffect(() => {
    // Axios
    async function fetchData() {
      setLoading(true);
      const res = await api
        .get(commonUrl.patient + "all/" + adminLogin.belong)
        .catch((error) => {
          handlingError(error);
        });
      // console.log(res.data, ">>> Axios patient/all/belongID");
      // axios 성공 시
      if (res.data.ok && res.data.patientList) {
        setLoading(false);
        console.log(res.data.patientList);
        // table에 띄우기 적합한 형태로 다듬어서 저장
        const newData = res.data.patientList.map((el, idx) => {
          return {
            ...el,
            idx: res.data.patientList.length - idx,
            gender: el.gender === "M" ? "남" : "여",
            age: getAge(el.birth),
            status: el.status === 0 ? "활성" : "비활성",
            regNum: el.regNum,
            check: false,
          };
        });
        dataRef.current = newData;
        setData(dataRef.current);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupRegisterOpen]);

  const jangruType = {
    상행결장루: 1,
    횡행결장루: 2,
    "하행/구불결장루": 3,
    회장루: 4,
    공장루: 5,
  };
  /******************************* Table UI 관련 변수 & 함수들  ******************************/
  // Table에 들어갈 column명
  const columns = [
    {
      accessor: "idx",
      Header: "NO.",
      width: "5%",
    },
    {
      accessor: "name",
      Header: "이름",
      width: "12%",
    },
    {
      accessor: "patientID",
      Header: "등록번호",
      width: "12%",
    },
    {
      accessor: "gender",
      Header: "성별",
      width: "6%",
    },
    {
      accessor: "age",
      Header: "만 나이",
      width: "6%",
    },
    {
      accessor: "jangruType",
      Header: "장루 유형",
      width: "15%",
    },
    {
      accessor: "status",
      Header: "상태",
      width: "8%",
    },
    {
      accessor: "check",
      Header: (
        <button
          className={`text-[#FAFBFC] bg-[#1B5EDC] rounded-[6px] px-6 py-2`}
          onClick={() => {
            if (data.filter((patient) => patient.check === true).length === 0) {
              toast(
                <p>내려받을 환자를 선택해 주세요.</p>,
                toastCommonProps(1500, "top-right", "toast_common")
              );
            } else {
              const checkedPatientList = data
                .filter((patient) => patient.check === true)
                .map((filtered) => {
                  return {
                    patientRN: filtered.regNum,
                    patientName: filtered.name,
                    jangruSurgeryDate: dayjs(filtered.jangruSurgeryDate).format(
                      "YYYYMMDD"
                    ),
                    jangruType: jangruType[filtered.jangruType],
                    registerDate: dayjs(filtered.startDate).format("YYYYMMDD"),
                  };
                });
              setData(
                data.map((patient) => {
                  return {
                    ...patient,
                    check: false,
                  };
                })
              );
              excelDown({
                type: 0,
                patientList: checkedPatientList,
              });
            }
          }}
        >
          일괄등록 서식 받기
        </button>
      ),
      width: "10%",
    },
  ];

  return (
    <>
      <Header2 title={[{ title: "대상자 등록/관리", url: 0 }]}>
        <ToastContainer />
        {/* 단체등록 팝업 */}
        {/* <GroupRegisterPopup
          open={groupRegisterOpen}
          setOpen={setGroupRegisterOpen}
        /> */}
        {/* 대상자 등록/관리 */}
        <div className="admin p-[32px]">
          <div className="h-[80px] min-h-[80px"></div>
          <label className="text-2xl font-extrabold leading-8 text-[#020A1B]">
            대상자 등록/관리
          </label>
          {loading ? (
            <Loading />
          ) : (
            <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-3 min-w-[977px]">
              <div className="flex justify-between">
                <div className="flex items-center justify-start">
                  {/* 슈퍼관리자가 아니거나 헤링스 관리자이면 버튼 안보여줌 */}
                  {adminLogin.power !== 0 || adminLogin.belong === 1 ? null : (
                    <div className="flex items-center justify-between mr-3 gap-x-3">
                      <BtnBlue
                        context="개별 등록"
                        onClick={() => {
                          navigate("/admin/main/client/register");
                        }}
                        style={{ width: "auto", padding: "8px 16px" }}
                      />
                      {/* <BtnBlue
                        context="단체 등록"
                        onClick={() => {
                          setGroupRegisterOpen(true);
                        }}
                        style={{ width: "auto", padding: "8px 16px" }}
                      /> */}
                    </div>
                  )}
                </div>
                {/* 검색창 */}
                <div className="flex gap-x-2 ml-[10px]">
                  <div className="flex items-center w-[290px] h-[38px] border border-[#CCCDD1] box-border px-6 py-2 rounded-[48px]">
                    <img
                      src={search_img}
                      alt="search_img"
                      className="w-[16.5px] h-[16.6px] mr-[9.69px]"
                    />
                    <input
                      type="text"
                      className="w-[210px] h-[22px] bg-transparent text-[#4E535F] focus:outline-none placeholder:font-semibold placeholder:text-base placeholder:leading-[22px] placeholder:text-[#4E535F]"
                      placeholder="이름 또는 등록번호를 검색하세요."
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      onKeyPress={onKeyPress}
                    />
                  </div>
                  <BtnBlue
                    onClick={searchClick}
                    context="검색"
                    style={{
                      width: "76px",
                      height: "38px",
                      borderRadius: "48px",
                    }}
                  />
                </div>
              </div>
              {/* 대상자 테이블 */}
              <div className="flex flex-col grow  mt-[20px] y-[10px]">
                <TableBlue
                  columns={columns}
                  data={data}
                  setData={setData}
                  // checkedPatientList={checkedPatientList}
                  // setCheckedPatientList={setCheckedPatientList}
                  pageMove={"/admin/main/client/modify"}
                  sorting={true}
                  pagination={true}
                  showAll={showAllRef.current ? onClickShowAll : false}
                />
              </div>
            </div>
          )}
        </div>
      </Header2>
    </>
  );
}

export default Client;
