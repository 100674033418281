import React from "react";

const LifeInManage4 = ({ bigTitle, btn4 }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400 wordbreak-keepall">
      <div className="pt-[72px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
        <h1 className="h2_800 pt-[4px] pb-[24px]">{btn4}</h1>
      </div>
      <div className="w-full pt-[24px] body_1_400_jangrucont">
        <div>
          <span className="inline-block">• 수술 후 적응하기</span>

          <div className="flex">
            <span className="inline-block">-&nbsp;</span>
            <div>
              신체적, 감정적으로 회복할 수 있는 충분한 시간을 가지도록 하세요.
            </div>
          </div>
          <div className="flex">
            <span className="inline-block">-&nbsp;</span>
            <div>
              일상에서 불편감, 탈진, 피로감 또는 우울증이 성욕에 영향을 줄 수
              있습니다.
            </div>
          </div>
          <div className="flex">
            <span className="inline-block">-&nbsp;</span>
            <div>
              의심과 불안보다는 신체적, 심리적, 감정적으로 건강한 관점으로
              상대에 집중할 필요가 있습니다.
            </div>
          </div>
          <div className="flex">
            <span className="inline-block">-&nbsp;</span>
            <div>
              자신을 받아들이고, 편안함과 자신감을 가짐으로써 스스로 기분도
              나아지고 상대도 이런 점들에 편안해질 수 있습니다.
            </div>
          </div>
          <div className="flex">
            <span className="inline-block">-&nbsp;</span>
            <div>
              개방적이고 솔직한 의사소통이 중요하며 서로 경청하여 걱정과 불안을
              해소하도록 합니다.
            </div>
          </div>
          <div className="flex">
            <span className="inline-block">-&nbsp;</span>
            <div>
              가까운 신체접촉 등은 장루에 해를 주지 않으며 장루에 압박을 주지
              않는 방식으로 하는 것이 좋습니다.
            </div>
          </div>
        </div>
        <div className="pt-[32px]">
          <span className="inline-block">• 참고사항</span>

          <div className="flex">
            <span className="inline-block">-&nbsp;</span>
            <div>
              냄새를 방지하는 방향주머니를 사용하고 양배추, 양파, 계란 등 냄새를
              유발하는 음식 섭취를 자제하세요.
            </div>
          </div>
          <div className="flex">
            <span className="inline-block">-&nbsp;</span>
            <div>장루주머니는 사전에 비우도록 합니다.</div>
          </div>
          <div className="flex">
            <span className="inline-block">-&nbsp;</span>
            <div>가능하다면 작은 크기의 주머니를 착용하세요.</div>
          </div>
          <div className="flex">
            <span className="inline-block">-&nbsp;</span>
            <div>
              남성의 경우 직장을 제거한 경우 발기부전이 1년 정도 있을 수 있으나
              일시적이며 다른 성감에는 영향을 주지 않습니다.
            </div>
          </div>
          <div className="flex">
            <span className="inline-block">-&nbsp;</span>
            <div>
              여성의 경우 건조함을 느낄 수 있으며 이를 위한 추가 용품을 고려해볼
              수 있습니다.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LifeInManage4;
