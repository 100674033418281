import React from "react";

const DiaryCenterModal = ({ title, showHydroguide, showPoopguide }) => {
  const closeicon = "/images/mo/closebasic.svg";

  return (
    <>
      <div
        className="w-full h-screen fixed top-0 left-0 bg-black opacity-[35%] z-10"
        onClick={() => {
          if (showHydroguide) {
            showHydroguide();
          } else {
            showPoopguide();
          }
        }}
      ></div>
      <div className="fixed z-20 top-1/2 left-1/2 transform-50 p-6 w-[340px] bg-[#FAFCFF] rounded-xl wordbreak-keepall">
        <div className="flex justify-between">
          <strong className="h3_800">{title}</strong>
          {title === "수분 섭취량 측정 안내" ? (
            <img
              src={closeicon}
              alt="closeicon"
              className="cursor-pointer absolute top-0 right-0"
              onClick={showHydroguide}
            />
          ) : (
            <img
              src={closeicon}
              alt="closeicon"
              className="cursor-pointer absolute top-0 right-0"
              onClick={showPoopguide}
            />
          )}
        </div>

        <div>
          {title === "수분 섭취량 측정 안내" ? (
            <>
              <span className="pt-4 block">
                ​하루 동안 마신 수분 섭취량을 기록합니다.
              </span>
              <span className="pt-4 block">
                물, 커피, 차, 음료 등의 수분을 ​ 섭취하였을 때 [+] 버튼을
                이용하여 섭취량을 기록합니다.
              </span>
              <span className="pt-4 block font-bold">
                [+] 버튼 선택 시 200ml씩 섭취한 것으로 측정합니다.
              </span>
              <span className="block pt-4">
                ​섭취량을 잘못 기록한 경우​ [-] 버튼을 이용하여 조절합니다.
              </span>
            </>
          ) : (
            <>
              <span className="pt-4 block">
                하루 동안의 배변량을 장루 주머니가 채워진 정도와 함께 횟수로
                기록합니다.
              </span>
              <span className="pt-4 block">
                배변의 양은 장루 주머니 전체의 1/3, 2/3의 양으로 선택적 기록이
                가능합니다.
              </span>
              <strong className="pt-4 block">
                장루 주머니에 배변이 1/3정도 찼을때
              </strong>
              <strong className="block">
                비운 경우에는 1/3 그림 아래의 [-],[+] 버튼으 로 횟수를 조절하여
                기록할 수 있습니다.
              </strong>
              <span className="pt-4 block">
                장루 주머니에 배변이 2/3정도 찼을때 비운 경우에는 2/3 그림
                아래의 [-],[+] 버튼으 로 횟수를 조절하여 기록할 수 있습니다.
              </span>
              <strong className="pt-4 block">
                주머니 교체시에는 체크 버튼을 선택 합니다.
              </strong>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DiaryCenterModal;
