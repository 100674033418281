import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMainContext } from "../../../../../contexts/Provider";

import common, { api } from "../../../../../data/common";
/*component*/
import PhotoGuide from "./PhotoGuide";
import CheckImageShow from "./CheckImageShow";
import ImageSizeBig from "../../HealthDiary/component/ImageSizeBig";
const Check8 = ({ checkInfo, setCheckInfo, onChangeCheck }) => {
  const { loginInput, fileArray, selectedImages, files } = useMainContext();
  const navigate = useNavigate();
  const sectionRef = useRef();
  const [guide, setGuide] = useState(false);
  const [imageSizeBig, setImageSizeBig] = useState(false);

  const showGuide = () => {
    setGuide(!guide);
  };
  //이미지사이즈 15mb 이상일때 모달
  const showImageBig = () => {
    setImageSizeBig(!imageSizeBig);
  };

  const handleClick = () => {
    setTimeout(() => {
      const forScrollEl = document.querySelector(".for__scroll");
      forScrollEl.scroll(0, forScrollEl.clientHeight);
      console.log(forScrollEl.clientHeight);
    }, [400]);
  };
  const goCheck6or7 = ({ loginInput }) => {
    api.get(common.baseURL + "patient/Id/" + loginInput.id).then((res) => {
      if (res.data.ok === true) {
        if (res.data.patient.jangruType === "회장루") {
          navigate(`/check/7`);
        } else {
          navigate("/check/6");
        }
      }
    });
  };
  const goCheck9 = () => {
    console.log(files, ">>files");
    let fileBigNum = 0;
    files.forEach((el) => {
      if (el.size / 1000000 > 15) {
        fileBigNum = 1;
      } else {
        navigate(`/check/9`);
        setCheckInfo({ ...checkInfo, imageURL: fileArray });
      }
    });
    if (fileBigNum === 1) {
      setImageSizeBig(true);
    }
  };

  return (
    <>
      <div className="pt-[94px] px-[24px]">
        <h1 className="h3_800 text-[#020A1B]">장루 및 장루 피부 확인을 위해</h1>
        <h1 className="h3_800 text-[#020A1B]">
          장루 사진을 찍어서 첨부해 주세요.
        </h1>
        <div className="flex justify-between items-center pb-[80px]">
          <div className="pt-[8px] text-[#676C76] body_1_400 ">
            <span className="block ">
              사진은 장루를 중심으로 정면, 좌측면, 우측면,
            </span>
            <span className="block ">문제가 되는 부분등을 촬영해 주세요.</span>
          </div>
          <span
            className="border border-black rounded-full w-[21.5px] h-[21.5px] flex justify-center items-center inline-block cursor-pointer"
            onClick={showGuide}
          >
            ?
          </span>
        </div>
        <div className="bg-[#F1F2F3] text-[#676C76] caption_1_400 p-[8px] flex justify-between">
          <span className={"text-[#676C76]"}>
            3장 이상의 사진을 첨부해 주세요.
          </span>
          <span className="text-black caption_1_600">
            {selectedImages.length} / 5
          </span>
        </div>
        <CheckImageShow />
        <div className="w-full mt-2">
          <textarea
            type="text"
            className="surveycheck mb-4 border rounded w-full h-[200px] px-6 py-3 cursor-pointer bg-[#F2F6FC] border-[#9DC4FA] active:bg-[#CBDFFC] focus:outline-none body_1_600"
            name="imageText"
            placeholder="장루에 대해 의료진이 확인해야 할 내용이 있다면 입력해 주세요. (200자 이내)"
            value={checkInfo.imageText}
            onChange={onChangeCheck}
            onClick={handleClick}
          />
        </div>
        <div className="h-[120px]" ref={sectionRef}></div>
        {selectedImages.length >= 1 ? (
          <div className="fixed  bottom-0 left-0 w-full font-bold bg-[#FAFCFF]">
            <div className="flex ">
              <div
                className="button_700 border w-2/5 py-[20px] flex justify-center items-center cursor-pointer 
          border-[#679DEE] text-[#1B5EDC] bg-[#FAFCFF] active:text-[#18428E] active:bg-[#F2F6FC] ease-in-out duration-300"
                onClick={() => {
                  goCheck6or7({ loginInput });
                }}
              >
                이전
              </div>
              <div
                className=" w-3/5 py-[20px] flex justify-center items-center cursor-pointer bg-[#1B5EDC] button_700 text-white active:bg-[#18428E] ease-in-out duration-300"
                onClick={goCheck9}
              >
                다음
              </div>
            </div>
          </div>
        ) : (
          <div className="fixed  bottom-0 left-0 w-full font-bold bg-[#FAFCFF]">
            <div className="flex ">
              <div
                className="border w-2/5 py-[20px]  flex justify-center items-center button_700 cursor-pointer
        border-[#679DEE] text-[#1B5EDC] bg-[#FAFCFF] active:text-[#18428E] active:bg-[#F2F6FC] button_700 ease-in-out duration-300"
                onClick={() => {
                  goCheck6or7({ loginInput });
                }}
              >
                이전
              </div>
              <div className=" w-3/5 py-[20px] button_700 flex  justify-center items-center bg-[#9DC4FA] text-white ease-in-out duration-300">
                다음
              </div>
            </div>
          </div>
        )}
      </div>
      {imageSizeBig ? <ImageSizeBig showImageBig={showImageBig} /> : ""}
      {guide ? <PhotoGuide showGuide={showGuide} /> : ""}
    </>
  );
};

export default Check8;
