import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
const Check3 = ({ checkInfo, onChangeCheck }) => {
  const [state1, setState1] = useState(false);
  let navigate = useNavigate();
  const onClickState1 = (e) => {
    setState1(!state1);
  };
  const goCheck2 = () => {
    navigate(`/check/2`);
  };
  const goCheck4 = () => {
    navigate(`/check/4`);
  };
  return (
    <>
      <div className="pt-[94px] px-[24px]">
        <div>
          <h1 className="h3_800 ">현재 체중을 입력해 주세요.</h1>
          <span className="block text-[#676C76] body_1_400 pt-[8px] pb-[80px]">
            소수점 첫째자리까지만 입력해주세요.
          </span>
          <div
            className={
              state1
                ? "flex items-center border rounded w-full px-[24px] py-[16px] cursor-pointer bg-[#CBDFFC] border-[#1B5EDC] "
                : "flex items-center border rounded w-full px-[24px] py-[16px] cursor-pointer bg-[#F2F6FC] border-[#9DC4FA] active:bg-[#CBDFFC]"
            }
          >
            <input
              type="number"
              inputMode="decimal"
              className="surveycheck w-full h-full bg-transparent focus:outline-none body_1_600"
              name="weight"
              placeholder="예)50.5kg"
              onChange={onChangeCheck}
              onClick={onClickState1}
              value={checkInfo.weight}
            />
            <span className="pl-4">kg</span>
          </div>
        </div>
      </div>
      {checkInfo.weight > 0 ? (
        <div className="fixed  bottom-0 left-0 w-full font-bold flex ">
          <div
            className="border w-2/5 py-[20px] flex justify-center items-center cursor-pointer
                 bg-[#FAFCFF] active:bg-[#F2F6FC] border border-[#679DEE] active:border-[#18428E] inline-block button_700   cursor-pointer  ease-in-out duration-300 text-[#1B5EDC] active:text-[#18428E]"
            onClick={goCheck2}
          >
            이전
          </div>
          <div
            className=" w-3/5 py-[20px] flex justify-center items-center cursor-pointer  bg-[#1B5EDC] button_700 text-white active:bg-[#18428E] ease-in-out duration-300"
            onClick={goCheck4}
          >
            다음
          </div>
        </div>
      ) : (
        <div className="fixed  bottom-0 left-0 w-full font-bold flex ">
          <div
            className="border w-2/5 py-[20px] flex justify-center items-center cursor-pointer
               bg-[#FAFCFF] active:bg-[#F2F6FC] border border-[#679DEE] active:border-[#18428E] inline-block button_700   cursor-pointer  ease-in-out duration-300 text-[#1B5EDC] active:text-[#18428E]"
            onClick={goCheck2}
          >
            이전
          </div>
          <div className=" w-3/5 py-[20px] flex justify-center items-center bg-[#9DC4FA]  text-white button_700">
            다음
          </div>
        </div>
      )}
    </>
  );
};

export default Check3;
