const headerDashboard = "/images/admin/header_dash/header_dashboard.svg";
const headerManager = "/images/admin/header_dash/header_manager.svg";
const headerClient = "/images/admin/header_dash/header_client.svg";
const headerJangruCheck = "/images/admin/header_dash/header_jangrucheck.svg";
const headerCommunity = "/images/admin/header_dash/header_community.svg";
const headerApp = "/images/admin/header_dash/header_app.svg";
const headerNews = "/images/admin/header_dash/header_news.svg";
const headerSend = "/images/admin/header_dash/header_send.svg";
const headerHospital = "/images/admin/header_dash/header_hospital.svg";
const headerService = "/images/admin/header_dash/header_service.svg";

export const NavigationList = [
  {
    id: 1,
    title: "Dashboard",
    url: "/admin/main/dashboard",
    icon: (
      <img
        src={headerDashboard}
        alt="dashboard"
        className="w-[20px] h-[20px] cursor-pointer "
      />
    ),
  },
  {
    id: 3,
    title: "관리자 등록/관리",
    url: "/admin/main/manager",
    icon: (
      <img
        src={headerManager}
        alt="manager"
        className="w-[20px] h-[20px] cursor-pointer "
      />
    ),
  },
  {
    id: 4,
    title: " 대상자 등록/관리",
    url: "/admin/main/client",
    icon: (
      <img
        src={headerClient}
        alt="client"
        className="w-[20px] h-[20px] cursor-pointer "
      />
    ),
  },
  {
    id: 5,
    title: "장루 점검",
    url: "/admin/main/check",
    icon: (
      <img
        src={headerJangruCheck}
        alt="jangrucheck"
        className="w-[20px] h-[20px] cursor-pointer "
      />
    ),
  },
  {
    id: 9,
    title: "쪽지",
    url: "/admin/main/message",
    icon: (
      <img
        src={headerSend}
        alt="send"
        className="w-[20px] h-[20px] cursor-pointer "
      />
    ),
  },
  // {
  //   id: 6,
  //   title: "커뮤니티 관리",
  //   url: "/admin/main/community",
  //   icon: (
  //     <img
  //       src={headerCommunity}
  //       alt="community"
  //       className="w-[20px] h-[20px] cursor-pointer "
  //     />
  //   ),
  // },
  {
    id: 2,
    title: "병원 등록/관리",
    url: "/admin/main/hospital",
    icon: (
      <img
        src={headerHospital}
        alt="headerHospital"
        className="w-[20px] h-[20px] cursor-pointer "
      />
    ),
  },
  {
    id: 7,
    title: "이용 문의",
    url: "/admin/main/inquiry",
    icon: (
      <img
        src={headerApp}
        alt="app"
        className="w-[20px] h-[20px] cursor-pointer "
      />
    ),
  },
  {
    id: 8,
    title: "새소식",
    url: "/admin/main/news",
    icon: (
      <img
        src={headerNews}
        alt="news"
        className="w-[20px] h-[20px] cursor-pointer "
      />
    ),
  },

  {
    id: 10,
    title: "서비스 신청",
    url: "/admin/main/service",
    icon: (
      <img
        src={headerService}
        alt="headerService"
        className="w-[20px] h-[20px] cursor-pointer "
      />
    ),
  },
];
