import { useEffect, useRef, useState } from "react";
import { BtnBlack } from "../../../admin_components/Buttons";
import Popup from "reactjs-popup";
import autoInputPhone from "../../../admin_components/AutoInputPhone";
import commonUrl, { api } from "../../../../../data/common";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Postcode from "../../Hospital/components/Postcode";
import { handlingError } from "../../../admin_components/Error";

const ServiceRegister = () => {
  const [resize, setResize] = useState();

  // 마운트시 화면 가로 크기 구함
  useEffect(() => {
    window.addEventListener("resize", () => {
      // addEventListener 새로고침 안된 상태에서 화면 넓이가 달라질 때 CSS 적용
      setResize(window.innerWidth);
    });

    // setTimeout 새로고침 시 처음에 화면 넓이를 가져옴
    const time = setTimeout(() => {
      //   console.log(window.innerWidth);
      setResize(window.innerWidth);
    }, 0);

    return () => {
      window.removeEventListener("resize", () => {
        setResize(window.innerWidth);
      });

      clearTimeout(time);
    };
  }, []);

  const navigate = useNavigate();
  const radio_check = "/images/admin/radio_check.svg";
  const [loading, setLoading] = useState(false); // 비동기
  // 서비스 등록 입력
  const [register, setRegister] = useState({
    name: "",
    employeeName: "",
    tel: "",
    address: "",
    addressPlus: "",
    email: "",
    inquiry: "",
  });
  const [open, setOpen] = useState(false); // 주소 검색창 열기 여부
  const [agree, setAgree] = useState(false); // 정보 수집 동의 체크 여부
  const phoneRef = useRef(); // 핸드폰번호 input ref

  // input 값 입력
  const change = (e) => {
    const { name, value } = e.target;

    // 휴대폰 번호 - 자동입력
    if (name === "tel") {
      autoInputPhone(
        register.tel,
        "tel",
        value,
        setRegister,
        register,
        phoneRef
      );
    }
    // 휴대폰 번호 제외한 변수들
    else setRegister({ ...register, [name]: value });
  };

  // 제출 형식에 맞는 지 확인
  const handlingForSubmit = () => {
    const regPhone = /^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/;
    const regEmail =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    // 누락된 칸 체크
    if (
      register.name.trim() === "" ||
      register.address.trim() === "" ||
      register.email.trim() === "" ||
      register.employeeName.trim() === "" ||
      register.inquiry.trim() === "" ||
      register.tel.trim() === ""
    ) {
      toast.error(<p>누락된 칸이 있습니다.</p>);
      return false;
    }
    // 휴대폰 번호 형식 체크
    else if (!regPhone.test(register.tel)) {
      toast.error(<p>휴대폰 번호 형식이 알맞지 않습니다.</p>);
      return false;
    }
    // 이메일 체크
    else if (!regEmail.test(register.email)) {
      toast.error(<p>이메일 형식이 알맞지 않습니다.</p>);
      return false;
    }
    // 동의 체크
    else if (!agree) {
      toast.error(<p>정보 수집 및 이용 동의란에 체크해주세요.</p>);
      return false;
    }
    // 모두 패스
    else return true;
  };

  // 신청 버튼 클릭 시
  const submit = () => {
    // 제출 형식 올바르면 handlingForSubmit()가 true
    if (handlingForSubmit()) {
      let newData = {
        ...register,
        addressPlus: undefined,
        address: `${register.address} (${register.addressPlus})`,
      };

      // axios
      async function fetchData() {
        setLoading(true);
        const res = await api({
          method: "post",
          url: commonUrl.baseURL + "service/apply",
          data: newData,
        }).catch((error) => {
          handlingError(error, "top-left", "toast_common toast__normal");
        });
        console.log(res.data, ">>> Axios belong");
        // Axios admin 성공
        if (res.data.ok) {
          toast.success(<p>저장 되었습니다.</p>);
          setTimeout(() => {
            setLoading(false);
            navigate("/admin/");
          }, 1900);
        }
        // Axios admin 실패
        else {
          setLoading(false);
          toast.error(<p className="text-sm">서비스 신청에 실패하였습니다.</p>);
        }
      }
      fetchData();
    }
  };

  if (resize > 959) {
    return (
      <>
        <Popup
          open={open}
          closeOnDocumentClick
          onClose={() => {
            setOpen(false);
          }}
        >
          <Postcode
            place={register}
            setPlace={setRegister}
            onClose={() => {
              setOpen(false);
            }}
          />
        </Popup>
        <div className="admin h-screen w-full bg-[#FAFBFC]">
          <ToastContainer
            autoClose={1500}
            position="bottom-right"
            hideProgressBar
          />
          <div className="flex justify-center w-full h-full">
            <div className="flex flex-col gap-y-5 p-8 max-w-[950px] min-w-[950px] h-full">
              {/* 제목 & 안내문구 */}
              <label className="flex text-xl leading-[22px] text-[#020A1B] font-bold ">
                OstoCare 서비스 신청하기
              </label>
              <div className="p-2 border border-[#343B48] bg-[#F2F6FC] text-[#343B48] text-base leading-[22px] font-semibold ">
                안녕하세요. 오스토케어입니다. <br />
                본 서비스는 장루조성술 후 지속적으로 재택관리가 필요한 암환자를
                모니터링하고 관리를 할 수 있도록 지원합니다.
                <br />
                서비스를 이용하고자 하시는 병원에서는 신청서를 작성해 주시기
                바랍니다.
              </div>
              {/* 표 */}
              <div className="grid grid-cols-[20%,30%,20%,30%] border border-[#E5E6E7]">
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  병원 명
                </label>
                <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                  <input
                    type="text"
                    className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-base"
                    name="name"
                    onChange={change}
                    placeholder="병원명을 입력해 주세요."
                  />
                </div>
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border row-span-2">
                  병원 주소
                </label>
                <div className="flex flex-col gap-y-[6px] p-[4px] w-full border border-[#E5E6E7] font-normal box-border row-span-2">
                  <div className="flex gap-x-1">
                    <input
                      type="text"
                      className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-base"
                      name="address"
                      onChange={change}
                      value={register.address}
                      placeholder="주소를 입력해 주세요."
                    />
                    <BtnBlack
                      context="주소찾기"
                      style={{
                        minHeight: "35px",
                        width: "75px",
                        minWidth: "75px",
                        padding: "0 5px",
                      }}
                      onClick={() => {
                        setOpen(true);
                      }}
                    />
                  </div>
                  <input
                    type="text"
                    className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-base"
                    name="addressPlus"
                    onChange={change}
                    placeholder="상세주소를 입력해주세요."
                  />
                </div>
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  담당자 이름
                </label>
                <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                  <input
                    type="text"
                    className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-base"
                    name="employeeName"
                    onChange={change}
                    placeholder="이름을 입력해 주세요."
                  />
                </div>
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                  담당자 휴대폰번호
                </label>
                <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                  <input
                    type="text"
                    className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-base"
                    name="tel"
                    onChange={change}
                    placeholder="예) 010-1234-5678"
                    ref={phoneRef}
                  />
                </div>
                <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border ">
                  이메일 주소
                </label>
                <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                  <input
                    type="text"
                    className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-base"
                    name="email"
                    onChange={change}
                    placeholder="이메일 주소를 입력해 주세요."
                  />
                </div>
                <label className="flex justify-start items-center h-[104px] px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border ">
                  문의 사항
                </label>
                <textarea
                  type="text"
                  className="flex py-[7px] px-3 w-full h-[104px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] font-normal placeholder:text-base col-span-3"
                  name="inquiry"
                  onChange={change}
                  placeholder="문의하실 내용을 입력해 주세요."
                />
              </div>
              {/* 동의 체크 & 신청 버튼 */}
              <div className="flex justify-between w-full">
                {/* 동의 체크 */}
                <div className="flex items-center gap-x-2">
                  {agree ? (
                    <div
                      className="flex justify-center items-center w-[16px] h-[16px] border border-[#4E535F] rounded-[8px] bg-[#4E535F] cursor-pointer "
                      onClick={() => {
                        setAgree(!agree);
                      }}
                    >
                      <img src={radio_check} alt="radio_check" />
                    </div>
                  ) : (
                    <div
                      className="flex justify-center items-center w-[16px] h-[16px] border border-[#4E535F] rounded-[8px] cursor-pointer "
                      onClick={() => {
                        setAgree(!agree);
                      }}
                    ></div>
                  )}
                  <label className="flex text-base font-semibold leading-[22px] text-[#343B48]">
                    서비스 신청을 위해 병원 정보, 담당자 정보를 수집 및 이용함에
                    동의합니다.
                  </label>
                </div>
                {/* 신청 버튼 */}
                <BtnBlack
                  context="신청하기"
                  loading={loading}
                  style={{
                    width: "80px",
                    minWidth: "80px",
                    padding: "8px 8px",
                  }}
                  onClick={submit}
                  standard="toast_standard"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        {open && (
          <div
            className="fixed inset-0 flex items-center px-10 bg-black bg-opacity-70"
            onClick={() => setOpen(false)}
          >
            <Postcode
              place={register}
              setPlace={setRegister}
              onClose={() => {
                setOpen(false);
              }}
            />
          </div>
        )}

        <div className={`bg-[#FAFBFC] min-h-screen`}>
          <ToastContainer
            position="bottom-center"
            hideProgressBar
            autoClose={1500}
          />
          <div className="flex justify-center w-full h-full">
            <div className="flex flex-col h-full p-8 gap-y-5">
              {/* 제목 & 안내문구 */}
              <label className="flex text-xl leading-[18px] text-[#020A1B] font-semibold">
                OstoCare 서비스 신청하기
              </label>
              <div className="p-2 border border-[#343B48] bg-[#F2F6FC] text-[#343B48] text-[13px] leading-[18px] font-semibold ">
                안녕하세요. 오스토케어입니다. <br />
                본 서비스는 장루조성술 후 지속적으로 재택관리가 필요한 <br />
                암환자를 모니터링하고 관리를 할 수 있도록 지원합니다.
                <br />
                서비스를 이용하고자 하시는 병원에서는 <br />
                신청서를 작성해 주시기 바랍니다.
              </div>
              {/* 표 */}
              <div className="text-[16px]">
                <div className="flex justify-between border border-[#E5E6E7] bg-[#F2F6FC]">
                  <label className="flex items-center px-2 border-r border-[#E5E6E7] bg-[#F2F6FC] font-normal text-[#4E535F] w-2/5">
                    병원 명
                  </label>
                  <div className="w-3/5 p-1">
                    <input
                      type="text"
                      className="w-full py-[10px] px-1 vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-sm"
                      name="name"
                      onChange={change}
                      placeholder="병원명을 입력해 주세요."
                    />
                  </div>
                </div>
                <div className="flex justify-between border border-[#E5E6E7] bg-[#F2F6FC]">
                  <label className="flex items-center px-2 border-r border-[#E5E6E7] bg-[#F2F6FC] font-normal text-[#4E535F] w-2/5">
                    담당자 이름
                  </label>
                  <div className="w-3/5 p-1">
                    <input
                      type="text"
                      className="w-full py-[10px] px-1 vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-sm"
                      name="employeeName"
                      onChange={change}
                      placeholder="이름을 입력해 주세요."
                    />
                  </div>
                </div>
                <div className="flex justify-between border border-[#E5E6E7] bg-[#F2F6FC]">
                  <label className="flex items-center px-2 border-r border-[#E5E6E7] bg-[#F2F6FC] font-normal text-[#4E535F] w-2/5">
                    담당자 휴대폰번호
                  </label>
                  <div className="w-3/5 p-1">
                    <input
                      type="text"
                      className="w-full py-[10px] px-1 vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-sm"
                      name="tel"
                      onChange={change}
                      placeholder="예) 010-1234-5678"
                      ref={phoneRef}
                    />
                  </div>
                </div>
                <div className="flex justify-between border border-[#E5E6E7] bg-[#F2F6FC]">
                  <label className="flex items-center px-2 border-r border-[#E5E6E7] bg-[#F2F6FC] font-normal text-[#4E535F] w-2/5">
                    이메일 주소
                  </label>
                  <div className="w-3/5 p-1">
                    <input
                      type="text"
                      className="w-full py-[10px] px-1 vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-sm"
                      name="email"
                      onChange={change}
                      placeholder="이메일 주소를 입력해 주세요."
                    />
                  </div>
                </div>
                <label className="flex justify-start items-center px-2 py-[13px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal leading-[22px] text-[#4E535F] box-border row-span-2">
                  병원 주소
                </label>
                <div className="flex flex-col gap-y-[6px] p-[4px] w-full border border-[#E5E6E7] font-normal box-border row-span-2">
                  <div className="flex gap-x-1">
                    <input
                      type="text"
                      className="w-full mx-[1px] px-[7px] py-[10px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-sm"
                      name="address"
                      onChange={change}
                      value={register.address}
                      placeholder="주소를 입력해 주세요."
                    />
                    <BtnBlack
                      context="주소찾기"
                      style={{
                        minHeight: "35px",
                        width: "75px",
                        minWidth: "75px",
                        padding: "0 5px",
                      }}
                      onClick={() => {
                        setOpen(true);
                      }}
                    />
                  </div>
                  <input
                    type="text"
                    className="w-full mx-[1px] px-[7px] py-[10px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] placeholder:text-sm"
                    name="addressPlus"
                    onChange={change}
                    placeholder="상세주소를 입력해주세요."
                  />
                </div>

                <label className="flex justify-start items-center px-2 py-[13px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                  문의 사항
                </label>
                <textarea
                  type="text"
                  className="flex py-[7px] px-3 w-full h-[80px] vertical-middle border border-[#E5E6E7] focus:outline-none text-[#020A1B] font-normal placeholder:text-sm col-span-3"
                  name="inquiry"
                  onChange={change}
                  placeholder="문의하실 내용을 입력해 주세요."
                />
              </div>

              {/* 동의 체크 */}
              <div className="flex items-center gap-x-2">
                {agree ? (
                  <div
                    className="flex justify-center items-center w-[13px] h-[13px] border border-[#4E535F] rounded-[8px] bg-[#4E535F] cursor-pointer shrink-0"
                    onClick={() => {
                      setAgree(!agree);
                    }}
                  >
                    <img src={radio_check} alt="radio_check" />
                  </div>
                ) : (
                  <div
                    className="flex justify-center items-center w-[13px] h-[13px] border border-[#4E535F] rounded-[8px] cursor-pointer shrink-0"
                    onClick={() => {
                      setAgree(!agree);
                    }}
                  ></div>
                )}
                <label className="flex text-[14px] font-medium leading-[16px] text-[#343B48]">
                  서비스 신청을 위해 병원 정보, 담당자 정보를 수집 및 이용함에
                  동의합니다.
                </label>
              </div>
              {/* 신청 버튼 */}
              <BtnBlack
                context="신청하기"
                loading={loading}
                style={{
                  width: "",
                  minWidth: "full",
                  padding: "17px 8px",
                }}
                onClick={submit}
                standard="toast_standard"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default ServiceRegister;
