import Header2 from "../../admin_components/Header/Header2";

import { useCallback, useEffect, useRef, useState } from "react";
import { BtnBlack } from "../../admin_components/Buttons";
import commonUrl, { api } from "../../../../data/common";
import { ToastContainer, toast } from "react-toastify";
import { useMainContext } from "../../../../contexts/Provider";
import { convertRole } from "../../admin_components/ConvertAdmin";
import { convertBelongToName } from "../../admin_components/ConvertAdmin";
import { toastCommonProps } from "../../admin_components/Toast";
import { useNavigate } from "react-router-dom";
import { handlingError } from "../../admin_components/Error";

function MyInfo() {
  const profile = "/images/admin/profile.svg";
  const navigate = useNavigate();
  const { adminLogin, setAdminLogin, belongs } = useMainContext(); // 로그인 정보 Consumer
  const [loading, setLoading] = useState(false); //
  const [myInfo, setMyInfo] = useState({
    phone: adminLogin.phone,
    profileURL: adminLogin.profileURL,
    newPwd: "", // 새 비밀번호
    againNewPwd: "", // 비밀번호 재입력
  });
  const changePhoto = useRef(false); // 사진 바꿨는지 여부
  const fileInputRef = useRef(); // 사진 input
  const [files, setFiles] = useState([]); // 선택한 파일 저장
  const originProfileURL = useRef(adminLogin.profileURL); // 기존 사진 이름 저장
  const [msg, setMsg] = useState(""); // 비밀번호 체크
  const showMsg = useRef(false); // 비밀번호 오류 안내
  const inputRef = useRef([]); //input focus 위한 Ref

  // 카메라 아이콘은 input file과 연동
  const clickCamera = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  // 선택한 사진이 바뀌었을 시 실행
  const fileChange = (e) => {
    const file = e.target.files;
    setFiles(file);
    let tempName = file[0].lastModified + file[0].name;
    tempName = tempName.replace(/ /g, "");
    setMyInfo({ ...myInfo, profileURL: tempName });
    // 15MB 이상이면
    if (file[0].size / 1000000 > 1) {
      toast(
        <p>15MB 이하의 파일만 추가 가능합니다.</p>,
        toastCommonProps(3000, "top-left", "toast_common toast__normal")
      );
    }
  };

  // 휴대폰 번호 값 변경
  const change = (e) => {
    const { name, value } = e.target;

    // 휴대폰 번호 - 자동입력
    if (name === "phone") {
      const regPhone1 = /^([0-9]{3})$/;
      const regPhone2 = /^([0-9]{3})-([0-9]{4})$/;

      // 삭제중인 경우
      if (myInfo.phone.length > value.length) {
        setMyInfo({ ...myInfo, [name]: value });
        return;
      }

      // 숫자 아니면
      if (
        (isNaN(value.substring(value.length, value.length - 1)) &&
          value.substring(value.length, value.length - 1) !== "-") ||
        value.length > 13
      ) {
        setMyInfo({
          ...myInfo,
          [name]: value.substring(0, value.length - 1),
        });
        return;
      }

      // 작대기 자동 넣기
      let result = value;
      if (value.length === 3 && regPhone1.test(value)) {
        result = value + "-";
      } else if (value.length === 8 && regPhone2.test(value)) {
        result = value + "-";
      }
      setMyInfo({ ...myInfo, [name]: result });
    } else setMyInfo({ ...myInfo, [name]: value });
  };

  // toast창 위치 값을 담음
  const [toastElPosition, setToastElPosition] = useState({ top: 0, left: 0 }); // toast창 위치 값

  // 숫자, 문자, 특수문자 포함 8~12자리 체크
  const checkPwd = useCallback(() => {
    const specialRule = /[`~!@#$%^&*|\\'";:/?]/;
    const num = /[0-9]/;
    const char = /[a-zA-Z]/;

    if (
      specialRule.test(myInfo.newPwd) &&
      num.test(myInfo.newPwd) &&
      char.test(myInfo.newPwd) &&
      myInfo.newPwd.length >= 8 &&
      myInfo.newPwd.length <= 12
    ) {
      return true;
    }
    return false;
  }, [myInfo.newPwd]);

  // 저장하기 버튼 눌렀을 시
  const save = () => {
    // toast창 위치 설정 위해 toast창 띄우기 전, 저장하기 버튼 위치 알아내기
    const toastEl = document.querySelector(".toast_standard");

    setToastElPosition({
      top: toastEl.getBoundingClientRect().top,
      left: toastEl.getBoundingClientRect().left,
    });

    // 휴대폰 번호 형식
    const regPhone = /^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/;

    // 누락된 칸 & 형식 체크
    if (!regPhone.test(myInfo.phone)) {
      toast(
        <p>휴대폰 번호 형식이 알맞지 않습니다.</p>,
        toastCommonProps(1500, "top-left", "toast_common toast__normal")
      );
      return;
    } else if (msg !== "예외 경우" && msg !== "비밀번호가 일치합니다.") {
      toast(
        <p>비밀번호를 다시 입력하세요.</p>,
        toastCommonProps(1500, "top-left", "toast_common toast__normal")
      );
      return;
    }
    // 사진이 15MB 이상이면
    else if (files[0] && files[0].size / 1000000 > 1) {
      toast(
        <p>15MB 이하의 파일만 추가 가능합니다.</p>,
        toastCommonProps(3000, "top-left", "toast_common toast__normal")
      );
      return;
    }

    //  함
    async function fetchData() {
      setLoading(true);
      const response = await api({
        method: "post",
        url: commonUrl.admin + "edit",
        data: {
          name: adminLogin.name,
          email: adminLogin.email,
          phone: myInfo.phone,
          profileURL: myInfo.profileURL,
          adminPwd: myInfo.newPwd === "" ? undefined : myInfo.newPwd,
        },
      }).catch((error) => {
        handlingError(error);
      });
      console.log(response.data, "Axios admin/edit");
      if (response.data.ok) {
        if (files.length !== 0) {
          // 기존 사진 삭제
          const deleteRes = await api({
            method: "post",
            url: commonUrl.fileUploadDelete,
            data: { files: [originProfileURL.current] },
          }).catch((error) => {
            handlingError(error);
          });
          console.log(deleteRes.data, ">>> Axios fileUloadDelete");
          // 사진 저장
          const formData = new FormData();
          formData.append("file", files[0]);
          let tempName = files[0].lastModified + files[0].name;
          tempName = tempName.replace(/ /g, "");
          formData.append("name", tempName);

          const response = await api({
            method: "post",
            url: commonUrl.fileUpload,
            data: formData,
          }).catch((error) => {
            handlingError(error);
          });
          console.log(response.data, ">>> Axios fileUpload");
        }
        changePhoto.current = true;
        setAdminLogin({
          ...adminLogin,
          phone: myInfo.phone,
          profileURL: myInfo.profileURL,
        });

        // 비번 안바꾼 경우
        if (myInfo.newPwd === "") {
          toast(
            <p>저장 되었습니다.</p>,
            toastCommonProps(1500, "top-left", "toast_common toast__normal")
          );
          setTimeout(() => {
            navigate("/admin/main/dashboard");
          }, 2000);
        }
        // 비번 바꾼 경우
        else {
          toast(
            <p>저장 되었습니다.</p>,
            toastCommonProps(1500, "top-left", "toast_common toast__normal")
          );
          setTimeout(() => {
            setAdminLogin({ ..."" });
            window.sessionStorage.removeItem("adminLogin");
            navigate("/admin/");
          }, 2000);
        }
      }
      setLoading(false);
    }
    fetchData();
  };

  useEffect(() => {
    // file 선택을 하지 않았을 때
    if (files.length === 0) {
      // 파일선택도 안했고, 미리 저장된 사진도 없을 떄
      if (!myInfo.profileURL) return;
      // 미리 저장된 사진이 있을 때
      else {
        const imgEl = document.querySelector(".myinfo__img__box");
        if (imgEl) {
          imgEl.style.backgroundImage = `url(${
            commonUrl.networkImg + encodeURIComponent(myInfo.profileURL)
          })`;
        }
        console.log(
          commonUrl.networkImg + encodeURIComponent(myInfo.profileURL)
        );
      }
    }
    // 파일 선택을 했을 때
    else {
      const imgEl = document.querySelector(".myinfo__img__box");
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);

      reader.onloadend = () =>
        (imgEl.style.backgroundImage = `url(${reader.result})`);
    }
  }, [myInfo.profileURL, files]);

  // 비밀번호 양식에 맞는지 + 재확인 일치 체크
  useEffect(() => {
    const { newPwd, againNewPwd } = myInfo;
    // 둘다 빈칸일 경우
    if (newPwd === "" && againNewPwd === "") {
      showMsg.current = false;
      setMsg("예외 경우");
    }
    // 윗칸만 빈칸이 아닐 경우
    else if (newPwd !== "" && againNewPwd === "") {
      showMsg.current = true;
      if (!checkPwd()) {
        setMsg("숫자, 문자, 특수문자 포함 8~12자리를 입력하세요");
      } else {
        setMsg("");
      }
    }
    // 밑칸만 빈칸이 아닐 경우
    else if (newPwd === "" && againNewPwd !== "") {
      showMsg.current = true;
      setMsg("비밀번호가 일치하지 않습니다.");
    }
    // 둘다 빈칸이 아닐 경우
    else if (newPwd !== "" && againNewPwd !== "") {
      showMsg.current = true;
      if (!checkPwd()) {
        setMsg("숫자, 문자, 특수문자 포함 8~12자리를 입력하세요");
      } else if (newPwd !== againNewPwd) {
        setMsg("비밀번호가 일치하지 않습니다.");
      } else {
        setMsg("비밀번호가 일치합니다.");
      }
    } else {
      showMsg.current = false;
      setMsg("예외 경우");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInfo.newPwd, myInfo.againNewPwd, checkPwd]);

  useEffect(() => {
    // toast창 위치 설정 위해 toast창 띄우기 전, 저장하기 버튼 위치 알아내기
    const toastEl = document.querySelector(".toast_standard");

    setToastElPosition({
      top: toastEl.getBoundingClientRect().top,
      left: toastEl.getBoundingClientRect().left,
    });
  }, []);

  return (
    <Header2
      title={[{ title: "내 정보 관리", url: 0 }]}
      changePhoto={changePhoto}
    >
      {/* toast 창 ui 변경 */}
      <style jsx="true">{`
        .Toastify__toast {
          top: ${toastElPosition.top - 20}px;
          left: ${toastElPosition.left - 295}px;
        }
      `}</style>

      <div className="admin flex flex-col grow p-[32px]">
        <ToastContainer />
        <div className="h-[8/0px] min-h-[80px]"></div>
        <label className="text-2xl font-extrabold leading-8 text-[#020A1B] mb-3">
          내 정보 관리
        </label>
        <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-3 min-w-[975px]">
          {/* 사진 선택 */}
          <div className="flex justify-center h-[85px]">
            {myInfo.profileURL ? (
              <div className="myinfo__img__box rounded-[50px] w-[80px] h-[80px] bg-cover bg-no-repeat bg-center border"></div>
            ) : (
              <img className="w-[80px] h-[80px] " src={profile} alt="profile" />
            )}
            <div className="flex flex-col justify-end">
              <span
                className="material-icons text-[25px] text-[#CBDFFC] cursor-pointer "
                onClick={clickCamera}
              >
                photo_camera
              </span>
              <input
                type="file"
                ref={fileInputRef}
                name="profileURL"
                onChange={fileChange}
                accept="image/*"
                hidden
              />
            </div>
          </div>
          {/* 표 (이름 소속 구분) */}
          <div className="grid grid-cols-[121px_1fr] mt-[20px] text-center">
            <label className="flex justify-start items-center px-4 py-2 border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] box-border text-[#4E535F] box-border ">
              이름
            </label>
            <label className="flex justify-start items-center px-6 py-2 border border-[#E5E6E7] font-normal p-[5px] text-[#020A1B]">
              {adminLogin.name}
            </label>
            <label className="flex justify-start items-center px-4 py-2 border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] box-border text-[#4E535F] box-border ">
              소속
            </label>
            <label className="flex justify-start items-center px-6 py-2 border border-[#E5E6E7] font-normal p-[5px] text-[#020A1B]">
              {convertBelongToName(belongs, adminLogin.belong)}
            </label>
            <label className="flex justify-start items-center px-4 py-2 border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] box-border box-border text-[#4E535F]">
              구분
            </label>
            <label className="flex justify-start items-center px-6 py-2 border border-[#E5E6E7] box-border font-normal p-[5px] text-[#020A1B]">
              {convertRole(adminLogin.role)}
            </label>
          </div>

          {/* 표 (이메일 휴대폰 번호) */}
          <div className="grid grid-cols-[121px_1fr] mt-[20px] text-center">
            <label className="flex justify-start items-center px-4 py-2 border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] box-border text-[#4E535F] box-border ">
              이메일
            </label>
            <label className="flex justify-start items-center px-6 py-2 border border-[#E5E6E7] font-normal p-[5px] text-[#020A1B]">
              {adminLogin.email}
            </label>
            <label className="text-left px-4 py-2 border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] box-border text-[#4E535F] box-border ">
              휴대폰 번호
            </label>
            <input
              type="text"
              className="w-full px-6 py-2 border border-[#E5E6E7] text-[#676C76] placeholder:text-[#ccc] placeholder:text-base focus:outline-none"
              name="phone"
              placeholder="010-1234-5678"
              onChange={change}
              value={myInfo.phone}
              autoFocus
            />
          </div>

          {/* 표 (비밀번호 변경) */}
          <div className="grid grid-cols-[121px_1fr] mt-[20px] text-center">
            <label className="text-left px-4 py-2 border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] ">
              비밀번호 변경
            </label>
            <input
              type="password"
              className="w-full px-6 py-2 border border-[#E5E6E7] text-[#676C76] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none"
              name="newPwd"
              onChange={change}
              placeholder="숫자, 문자, 특수문자 포함 8~12자리"
              value={myInfo.newPwd}
              ref={(el) => (inputRef.current[0] = el)}
            />

            <label className="text-left px-4 py-2 border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] box-border box-border text-[#4E535F]">
              비밀번호 확인
            </label>
            <input
              type="password"
              className="w-full px-6 py-2 border border-[#E5E6E7] text-[#676C76] placeholder:text-[#ccc] focus:outline-none"
              name="againNewPwd"
              onChange={change}
              value={myInfo.againNewPwd}
              ref={(el) => (inputRef.current[1] = el)}
            />
          </div>
          {/* 비밀번호 확인 문구 */}
          <div className="w-full pl-[140px] mt-[6px] ">
            {showMsg.current ? (
              msg === "비밀번호가 일치합니다." ? (
                <p className="text-[12px] text-blue-500 mt-[5px]">{msg}</p>
              ) : (
                <p className="text-[12px] text-red-500 mt-[5px]">{msg}</p>
              )
            ) : null}
          </div>
          {/* 저장하기 버튼 */}
          <div className="flex justify-end mt-[20px]">
            <BtnBlack
              context="저장하기"
              onClick={save}
              loading={loading}
              style={{ width: "88px" }}
              standard="toast_standard"
            />
          </div>
        </div>
      </div>
    </Header2>
  );
}
export default MyInfo;
