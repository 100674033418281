import common, { api } from "../../../../../data/common";
import dayjs from "dayjs";

const CommunityInfoCheerup = ({ cheerupthing }) => {
  let imgurl = "";
  if (cheerupthing.admin !== null && cheerupthing.patient === null) {
    imgurl = cheerupthing.admin.profileURL;
  } else if (cheerupthing.patient !== null && cheerupthing.admin === null) {
    imgurl = cheerupthing.patient.profileURL;
  }
  return (
    <div className="flex justify-center items-center gap-x-4 p-2 mt-3 ">
      <div className="flex">
        {imgurl ? (
          <img
            src={
              cheerupthing.patient
                ? common.networkImg +
                  encodeURIComponent(cheerupthing.patient.profileURL)
                : cheerupthing.admin
                ? common.networkImg +
                  encodeURIComponent(cheerupthing.admin.profileURL)
                : ""
            }
            alt="cummunity img"
            className="w-10 h-10 rounded-full mx-2"
          />
        ) : (
          <span className="material-icons text-5xl mx-2">account_circle</span> //default image
        )}
        <div className="flex flex-col mx-1">
          <span className="font-bold">
            {cheerupthing.admin
              ? `${cheerupthing.admin.name}`
              : cheerupthing.patient
              ? `${cheerupthing.patient.name}(${cheerupthing.patient.patientID})`
              : "비회원(none)"}
          </span>
          <span className="text-gray-500 text-sm">
            {dayjs(new Date(cheerupthing.createdAt)).format("YYYY.MM.DD")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CommunityInfoCheerup;
