import { useEffect, useRef, useState } from "react";
import commonUrl, { api } from "../../../../data/common";
import TableBlue from "../../admin_components/TableBlue";
import { BtnBlue } from "../../admin_components/Buttons";
import { useNavigate } from "react-router-dom";
import Header2 from "../../admin_components/Header/Header2";
import { ToastContainer, toast, Flip } from "react-toastify";
import { toastCommonProps } from "../../admin_components/Toast";
import { Loading } from "../../admin_components/Loading";
import dayjs from "dayjs";
import { handlingError } from "../../admin_components/Error";

export function Hospital() {
  const search_img = "/images/admin/search.svg";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  /*** 검색 관련 state, ref ***/
  const dataRef = useRef([]); // 전체 정보를 항상 가지고 있는 변수
  const [data, setData] = useState([]); // UI에 표기할 정보 담기
  const [search, setSearch] = useState(""); // 검색한 단어
  const showAllRef = useRef(false); // 전체보기 버튼 보여줄지 말지
  const [range, setRange] = useState({
    start: dayjs(new Date()).subtract(6, "days").format("YYYY-MM-DD"),
    end: dayjs(new Date()).format("YYYY-MM-DD"),
  });

  const changeRange = (e, type) => {
    if (type === "start") {
      setRange({ ...range, start: e.target.value });
    } else {
      setRange({ ...range, end: e.target.value });
    }
  };

  // 검색 값 변화할 때
  const change = (e) => {
    setSearch(e.target.value);
  };

  // 전체 보기 클릭시 전체 데이타 보여주기
  const onClickShowAll = () => {
    setSearch("");
    setData(dataRef.current);
    showAllRef.current = false;
  };

  // 엔터키 검색 버튼과 연결
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      searchClick();
    }
  };

  // 검색 버튼 클릭했을 때
  const searchClick = async() => {
    setLoading(true);
    const response = await api
      .post(commonUrl.baseURL + "belong/search", {
        name: search,
        startDate: range.start,
        endDate: range.end
      })
      .catch((error) => {
        handlingError(error);
      });
    console.log(response, ">>> Axios belong");
    // axios 성공시
    if (response.data.ok) {
      if (response.data.belongList.length === 0) {
        toast(
          <p>검색된 내용이 없습니다.</p>,
          toastCommonProps(
            1500,
            "top-right",
            "toast_common toast__search",
            Flip
          )
        );
        setSearch("");
      }else {
        // 데이터 table에 띄우기 적합한 형태로 바꾸기
        let newData = response.data.belongList.map((el, idx) => {
          return {
            id: el.id,
            idx: response.data.belongList.length - idx,
            name: el.name,
            createdAt: dayjs(new Date(el.createdAt)).format("YYYY-MM-DD"),
            status: el.status === 0 ? "활성" : "비활성",
            adminCnt : `${el.adminCnt} (${el.doctorCnt}/${el.nurseCnt})`,
            patientCnt : el.patientCnt,
            patientRangeCnt : el.patientRangeCnt 
          };
        });
        // dataRef.current = newData;
        setData(newData);
        showAllRef.current = true;
      }
    }
    setLoading(false);

  };

  // 첫 랜더시 데이터 불러오기
  useEffect(() => {
    // axios
    async function fetchData() {
      setLoading(true);
      const response = await api
        .post(commonUrl.baseURL + "belong/search")
        .catch((error) => {
          handlingError(error);
        });
      console.log(response, ">>> Axios belong");
      setLoading(false);
      // axios 성공시
      if (response.data.ok && response.data.belongList) {
        // 데이터 table에 띄우기 적합한 형태로 바꾸기
        let newData = response.data.belongList.map((el, idx) => {
          return {
            id: el.id,
            idx: response.data.belongList.length - idx,
            name: el.name,
            createdAt: dayjs(new Date(el.createdAt)).format("YYYY-MM-DD"),
            status: el.status === 0 ? "활성" : "비활성",
            adminCnt : `${el.adminCnt} (${el.doctorCnt}/${el.nurseCnt})`,
            patientCnt : el.patientCnt,
            patientRangeCnt : "-"
          };
        });
        dataRef.current = newData;
        setData(dataRef.current);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    searchClick()
  },[range])

  // Table에 들어갈 column명
  const columns = [
    {
      accessor: "idx",
      Header: "NO.",
      width: "10%",
    },
    {
      accessor: "name",
      Header: "병원명",
      width: "20%",
    },
    {
      accessor: "createdAt",
      Header: "등록일",
      width: "20%",
    },
    {
      accessor: "status",
      Header: "상태",
      width: "10%",
    },
    {
      accessor: "adminCnt",
      Header: "관리자 수 (의사/간호사)",
      width: "25%",
    },
    {
      accessor: "patientCnt",
      Header: "환자 수",
      width: "15%",
    },    
    {
      accessor: "patientRangeCnt",
     Header: "해당기간 환자 수",
      width: "15%",
    }
  ];

  return (
    <Header2 title={[{ title: "병원 등록/관리", url: 0 }]}>
      <ToastContainer />
      <div className="admin p-[32px]">
        <div className="h-[80px] min-h-[80px]"></div>
        <label className="text-2xl font-extrabold leading-8 text-[#020A1B]">
          병원 등록/관리
        </label>
        {loading ? (
          <Loading />
        ) : (
          <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-3 min-w-[975px]">
            <div className="flex justify-between">
              {/* 병원등록 버튼 */}
              <div className="flex items-center">
                <BtnBlue
                  context="등록하기"
                  onClick={() => {
                    navigate("/admin/main/hospital/register");
                  }}
                  style={{ width: "auto", padding: "8px 16px" }}
                />
              </div>
              {/* 검색창 */}
              <div className="flex gap-x-5 ml-[10px] items-center">
              <div className="flex items-center gap-1">
                  <span className="mr-3 text-gray_100">기간 설정</span>
                  <input
                    type="date"
                    className="text-body_2 rounded-[4px] border border-gray_100 bg-transparent px-3 py-2 text-black focus:outline-none"
                    value={range.start}
                    onChange={(e) => changeRange(e, "start")}
                    max={range.end}
                  />{" "}
                  ~
                  <input
                    type="date"
                    className="text-body_2 rounded-[4px] border border-gray_100 bg-transparent px-3 py-2 text-black focus:outline-none"
                    value={range.end}
                    onChange={(e) => changeRange(e, "end")}
                    min={range.start}
                    max={dayjs(new Date()).format("YYYY-MM-DD")}
                  />
                </div>
                <div className="flex items-center w-[290px] h-[38px] border border-[#CCCDD1] box-border px-6 py-2 rounded-[48px]">
                
                  <img
                    src={search_img}
                    alt="search_img"
                    className="w-[16.5px] h-[16.6px] mr-[9.69px]"
                  />
                  <input
                    type="text"
                    className="w-[210px] h-[22px] bg-transparent text-[#4E535F] focus:outline-none placeholder:font-semibold placeholder:text-base placeholder:leading-[22px] placeholder:text-[#4E535F]"
                    placeholder="병원명을 검색하세요."
                    value={search}
                    onChange={change}
                    // onKeyPress={onKeyPress}
                    onKeyDown={onKeyPress}
                  />
                </div>
                <BtnBlue
                  onClick={searchClick}
                  context="검색"
                  style={{
                    width: "76px",
                    height: "38px",
                    borderRadius: "48px",
                  }}
                />
              </div>
            </div>
            {/* 병원관리 테이블 */}
            {loading === false ? (
              <div className="flex grow mt-[20px]">
                <TableBlue
                  columns={columns}
                  data={data}
                  pageMove={"/admin/main/hospital/modify"}
                  sorting={true}
                  pagination={true}
                  showAll={showAllRef.current ? onClickShowAll : false}
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </Header2>
  );
}
export default Hospital;
