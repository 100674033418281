import { useEffect, useRef, useState } from "react";
import commonUrl, { api } from "../../../../data/common";
import TableBlue from "../../admin_components/TableBlue";
import { BtnBlue } from "../../admin_components/Buttons";
import { useNavigate } from "react-router-dom";
import Header2 from "../../admin_components/Header/Header2";
import { useMainContext } from "../../../../contexts/Provider";
import { ToastContainer, toast, Flip } from "react-toastify";
import { toastCommonProps } from "../../admin_components/Toast";
import { Loading } from "../../admin_components/Loading";
import { convertBelongToName } from "../../admin_components/ConvertAdmin";
import { convertRole } from "../../admin_components/ConvertAdmin";
import { handlingError } from "../../admin_components/Error";

export function Manager() {
  const search_img = "/images/admin/search.svg";
  const navigate = useNavigate();
  const { adminLogin, belongs } = useMainContext(); // 로그인 정보 Consumer
  const [loading, setLoading] = useState(false);
  /*** 검색 관련 state, ref ***/
  const dataRef = useRef([]); // 표기할 정보 담기
  const [data, setData] = useState([]); // 표기할 정보 담기
  const [search, setSearch] = useState(""); // 검색하는 단어
  const showAllRef = useRef(false); // 전체보기 버튼 보여줄지 말지

  // 엔터키와 검색 버튼 연결
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      searchClick();
    }
  };

  // 전체 보기 클릭시
  const onClickShowAll = () => {
    setSearch("");
    setData(dataRef.current);
    showAllRef.current = false;
  };

  // 검색 버튼 클릭했을 때
  const searchClick = () => {
    // 검색할 값이 있으면
    if (search !== "") {
      const newData = dataRef.current.filter((el) => {
        return el.name.includes(search) || el.email.includes(search);
      });
      // 검색된 값이 없으면
      if (newData.length === 0) {
        toast(
          <p>검색된 내용이 없습니다.</p>,
          toastCommonProps(
            1500,
            "top-right",
            "toast_common toast__search",
            Flip
          )
        );
        setSearch("");
      }
      // 검색된 값이 있으면
      else {
        setData(newData);
        showAllRef.current = true;
      }
    }
  };

  // 첫 랜더시 데이터 불러오기
  useEffect(() => {
    // Axios
    async function fetchData() {
      setLoading(true);
      const response = await api
        .get(commonUrl.admin + "all/" + adminLogin.belong)
        .catch((error) => {
          handlingError(error);
        });
      console.log(response.data, ">>> Axios admin/all/belongID");
      // axios 성공 시
      if (response.data.ok && response.data.adminList) {
        // table에 띄우기 적합한 형태로 다듬어서 저장
        let newData = response.data.adminList.map((el, idx) => {
          return {
            ...el,
            idx: response.data.adminList.length - idx,
            role: convertRole(el.role),
            belong: convertBelongToName(belongs, el.belong.id),
            status: el.status === 0 ? "활성" : "비활성",
          };
        });
        // 일반관리자일 경우 해당기관의 일반관리자들만 보여주기
        if (adminLogin.power === 1) {
          dataRef.current = newData.filter((el) => el.power === 1);
        }
        // 슈퍼관리자일 경우 모두 다 보여주기
        else {
          dataRef.current = newData;
        }
        setData(dataRef.current);
        setLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /******************************* Table UI 관련 변수 & 함수들  ******************************/
  // Table에 들어갈 column명
  const columns =
    adminLogin.role === 0
      ? [
          {
            accessor: "idx",
            Header: "NO.",
            width: "5%",
          },
          {
            accessor: "name",
            Header: "이름",
            width: "12%",
          },
          {
            accessor: "email",
            Header: "아이디",
            width: "28%",
          },
          {
            accessor: "belong",
            Header: "소속",
            width: "15%",
          },
          {
            accessor: "role",
            Header: "구분",
            width: "15%",
          },
          {
            accessor: "status",
            Header: "상태",
            width: "10%",
          },
        ]
      : [
          {
            accessor: "idx",
            Header: "NO.",
            width: "5%",
          },
          {
            accessor: "name",
            Header: "이름",
            width: "20%",
          },
          {
            accessor: "email",
            Header: "아이디",
            width: "20%",
          },
          {
            accessor: "role",
            Header: "구분",
            width: "15%",
          },
          {
            accessor: "status",
            Header: "상태",
            width: "10%",
          },
        ];

  return (
    <Header2 title={[{ title: "관리자 등록/관리", url: 0 }]}>
      <ToastContainer />
      <div className="admin p-[32px]">
        <div className="h-[80px] min-h-[80px]"></div>
        <label className="text-2xl font-extrabold leading-8 text-[#020A1B]">
          관리자 등록/관리
        </label>
        {loading ? (
          <Loading />
        ) : (
          <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-3 min-w-[975px]">
            <div className="flex justify-between">
              <div className="flex items-center">
                <BtnBlue
                  context="등록하기"
                  onClick={() => {
                    navigate("/admin/main/manager/register");
                  }}
                  style={{ width: "auto", padding: "8px 16px" }}
                />
              </div>
              {/* 검색창 */}
              <div className="flex gap-x-2 ml-[10px]">
                <div className="flex items-center w-[290px] h-[38px] border border-[#CCCDD1] box-border px-6 py-2 rounded-[48px]">
                  <img
                    src={search_img}
                    alt="search_img"
                    className="w-[16.5px] h-[16.6px] mr-[9.69px]"
                  />
                  <input
                    type="text"
                    className="w-[210px] h-[22px] bg-transparent text-[#4E535F] focus:outline-none placeholder:font-semibold placeholder:text-base placeholder:leading-[22px] placeholder:text-[#4E535F]"
                    placeholder="이름 또는 아이디를 검색하세요."
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    onKeyPress={onKeyPress}
                  />
                </div>
                <BtnBlue
                  onClick={searchClick}
                  context="검색"
                  style={{
                    width: "76px",
                    height: "38px",
                    borderRadius: "48px",
                  }}
                />
              </div>
            </div>
            {loading === false ? (
              <div className="flex grow mt-[20px]">
                <TableBlue
                  columns={columns}
                  data={data}
                  pageMove={"/admin/main/manager/modify"}
                  sorting={true}
                  pagination={true}
                  showAll={showAllRef.current ? onClickShowAll : false}
                />
              </div>
            ) : null}
          </div>
        )}

        <div className="w-full h-[32px]"></div>
      </div>
    </Header2>
  );
}
export default Manager;
