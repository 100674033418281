import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import common, { api } from "../../../../../data/common";

import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Popup from "reactjs-popup";
import commonUrl from "../../../../../data/common";
import { useMainContext } from "../../../../../contexts/Provider";
//popup
const PopUp = ({ data, open, onClose, value }) => {
  const number_id = [1, 2, 3, 4, 5];
  return (
    <>
      <style jsx="true">{`
        .popup-content.jangrucheck__popup-content {
          box-sizing: border-box;
          border-radius: 16px;
          overflow-y: auto;
          max-width: 384px;
          width: auto;
          height: auto;
          max-height: 100%;
          padding: 0px;
          border-width: 2px;
        }
      `}</style>
      <ToastContainer className="admin" />
      <Popup
        key={number_id.toString()}
        open={open}
        closeOnDocumentClick
        onClose={onClose}
        className="jangrucheck__popup"
      >
        <div className="relative flex justify-end">
          <span
            className="absolute m-2 font-medium text-white bg-black rounded-full cursor-pointer material-icons hover:bg-gray-500"
            onClick={onClose}
          >
            clear
          </span>
        </div>

        {data.imageURL.length !== 0
          ? data.imageURL.map((el, idx) => {
              return value === el ? (
                <img
                  key={idx}
                  className="flex justify-center "
                  src={
                    commonUrl.networkImg +
                    encodeURIComponent(data.imageURL[idx])
                  }
                  alt="사진"
                />
              ) : (
                <div key={idx}></div>
              );
            })
          : ""}
      </Popup>
    </>
  );
};
const InquiryInfoTable = ({ inquirydata, realdata, images }) => {
  // popup open 여부
  const [open, setOpen] = useState(false);
  // popup 닫을 시 실행하는 함수
  const closeModal = () => setOpen(false);
  // 사진 구분
  const { adminLogin } = useMainContext();
  const [sort, setSort] = useState("");
  const [url, setUrl] = useState([]);
  const [contents, setContents] = useState([]);
  const [date, setDate] = useState([]);
  const questionid = useLocation().state.question.id;
  const newsdata = useLocation().state;
  let flag = false;
  useEffect(() => {
    let pageOpen = false;
    const getNews = () => {
      api.get(common.baseURL + "question/id/" + questionid).then((res) => {
        if (res.data.ok === true) {
          if (res.data.answer !== undefined) {
            console.log(res.data);
            setDate(res.data.answer.updatedAt);
            setUrl(res.data.answer.fileURL);
            setContents(res.data.answer.content);
          }
        }
      });
    };
    if (!pageOpen) {
      getNews();
    }
    return () => {
      pageOpen = true;
    };
  }, [newsdata, date, questionid]);

  let new_category;
  const category_list = ["서비스 문의", "장애 및 불편신고", "제안사항", "기타"];
  if (contents.length > 0) flag = true;

  if (inquirydata.question.category !== null)
    new_category = category_list[inquirydata.question.category];

  return (
    <>
      <PopUp
        key={sort}
        open={open}
        onClose={closeModal}
        value={sort}
        data={{
          imageURL: images,
        }}
      />

      <div className="grid grid-cols-10 min-w-[915px] text-center border border-[#E5E6E7] bg-[#fafbfc] ">
        <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-2">
          문의분야
        </label>
        <label className="flex justify-start bg-white items-center text-left px-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-5">
          {inquirydata.category ? inquirydata.category : new_category}
        </label>
        <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-1">
          문의일
        </label>
        <label className="flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-1 bg-white col-span-2">
          {dayjs(realdata.createdAt).format("YYYY.MM.DD")}
        </label>
        <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-2">
          작성자(등록번호)
        </label>
        <label className="flex justify-start items-center px-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-5 bg-white">
          {inquirydata.writer ? inquirydata.writer : inquirydata.name}
        </label>
        <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-1">
          구분
        </label>
        <label className="flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-2 bg-white">
          {inquirydata.question.patient.belong.name}
        </label>
        <label className="flex justify-start items-center p-4 border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-2">
          제목
        </label>
        <label className="flex justify-start bg-white items-center text-left px-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-8">
          {inquirydata.title || ""}
        </label>
        <label className="flex justify-start items-center p-4 border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-2">
          내용
        </label>
        <label className="flex justify-start bg-white items-center text-left px-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-8">
          {inquirydata.content ? inquirydata.content : inquirydata.contents}
        </label>
        {images.length === 0 ? (
          ""
        ) : (
          <>
            <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-2">
              첨부이미지
              {/* 환자가 올린 문의에 대한 첨부파일 image */}
            </label>
            <label className="flex justify-start items-center pl-4 py-[3px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-8 bg-white">
              {images.map((el) => (
                <img
                  key={el}
                  src={common.networkImg + el}
                  alt={el}
                  className="w-12 h-12 mx-1 cursor-pointer overflow-hidden rounded-[6px] border-2"
                  onClick={() => {
                    setSort(el);
                    setOpen(true);
                  }}
                />
              ))}
            </label>
          </>
        )}
        {flag ? (
          <>
            <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-2">
              답변내용
            </label>
            <label className="w-full h-auto p-4 border border-[#E5E6E7] box-border col-span-8 bg-white">
              <div
                className="p-2 text-left "
                dangerouslySetInnerHTML={{ __html: contents }}
              ></div>
            </label>

            {url.length === 0 ? (
              ""
            ) : (
              <>
                <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-2">
                  {/* 답변에 포함된 첨부파일 */}
                  첨부이미지
                </label>
                <div className="flex flex-col items-start gap-y-2 px-6 py-3 border border-[#E5E6E7] col-span-8 bg-white">
                  {
                    // 기존에 올린 첨부 파일이 있을 경우
                    url.map((el, index) => (
                      <span
                        key={index}
                        className="flex inline-block mr-2 text-left break-all"
                      >
                        <p
                          className="underline cursor-pointer font-semibold text-base leading-[22px] text-[#020A1B]"
                          onClick={(e) => {
                            window.open(
                              `${common.networkImg}${el.replace(/(\s*)/g, "")}`
                            );
                          }}
                        >
                          {el.slice(13, el.length)}
                        </p>
                      </span>
                    ))
                  }
                </div>
              </>
            )}
            <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-2">
              작성자
            </label>
            <label className="flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7] bg-[#F1F2F3] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-5">
              {adminLogin.role === 0
                ? adminLogin.belongName + " 관리자"
                : adminLogin.role === 1
                ? adminLogin.belongName + " 의사"
                : adminLogin.belongName + " 간호사"}
            </label>
            <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-1">
              작성일시
            </label>
            <label className="flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-2 bg-white">
              {dayjs(date).format("YYYY.MM.DD")}
            </label>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default InquiryInfoTable;
