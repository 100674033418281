import React from "react";

const Tutorial2 = () => {
  const tutorial2 = "/images/mo/pngjpg/tutorial2.png";
  const lefthand = "/images/mo/pngjpg/lefthand.png";
  return (
    <div>
      <div className="pt-[25.5px] flex justify-center ">
        <div className="w-[375px] h-[329px] flex items-center justify-center relative">
          <img src={tutorial2} alt="tutorial2" />
          <img
            src={lefthand}
            alt="lefthand"
            className="absolute bottom-[-40px] right-24 tutohand2"
          />
        </div>
      </div>
      <div className="flex items-center flex-col pt-[50px]">
        <span className="block text-[#1B5EDC] h2_800">건강 다이어리</span>
        <div className="text-[#343B48] text-center body_1_400 pt-[8px]">
          <div>건강 상태를 기록하면서</div>
          <div>체계적인 관리를 시작해 보세요.</div>
        </div>
      </div>
    </div>
  );
};

export default Tutorial2;
