function Loading() {
  return (
    <svg
      className="animate-spin h-[20px] w-[20px] my-[15px] rounded-full border-2 border-white border-t-[#04142B] border-r-[#04142B] "
      viewBox="0 0 24 24"
    />
  );
}

function LoadingBlue() {
  return (
    <svg
      className="animate-spin h-[20px] w-[20px] my-[15px] rounded-full border-2 border-white border-t-[#679DEE] border-r-[#679DEE] "
      viewBox="0 0 24 24"
    />
  );
}

export { Loading, LoadingBlue };
