import React, { useState } from "react";
import ChangeContent from "./ChangeContent";

const QuestionList = ({ question }) => {
  const [openQues, setOpenQues] = useState(false);
  const downicon = "/images/mo/down_blue.svg";
  return (
    <div
      key={question.id}
      className="mb-[16px] border rounded-xl px-[24px] py-[17px]"
    >
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={(e) => {
          let isClicked = false;
          if (e.target) {
            isClicked = true;
            setOpenQues(!openQues);
          }
        }}
      >
        <div className=" subtitle_1_600 text-[#245BBF] flex">
          <span className="block">Q.&nbsp;</span>
          <span className="block">{question.title}</span>
        </div>
        <img
          src={downicon}
          alt="downsvg"
          className={
            openQues
              ? `w-[16px] h-[16px] noticeup`
              : `w-[16px] h-[16px] noticedown`
          }
        />
      </div>
      {openQues ? (
        <div className="bg-[#F2F6FC] p-[16px] mt-[9px] border-t border-[#CBDFFC]">
          {question.title ===
          "장루주머니 교환할 때 필요한 물품은 무엇인가요?" ? (
            <ChangeContent content={question.content} title={question.title} />
          ) : question.title === "콘텐츠 출처 안내" ? (
            <ChangeContent content={question.content} title={question.title} />
          ) : (
            question.content
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default QuestionList;
