import React, { useState } from "react";
import common, { api } from "../../../../../data/common";
import ImageModal from "../../../mobilecomponent/ImageModal";
const ShowSelectedImage = ({ el, index }) => {
  const [imageModal, setImageModal] = useState(false);
  const showImageModal = () => {
    setImageModal(!imageModal);
  };
  console.log(el);
  return (
    <>
      <div className="inline-block w-[80px] h-[80px] border border-[#999CA3] relative overflow-hidden cursor-pointer">
        <div className="object-cover">
          <img
            alt={el + index}
            key={index}
            src={common.networkImg + encodeURIComponent(el)}
            className="w-full"
            onClick={showImageModal}
          />
        </div>
      </div>

      {imageModal ? (
        <ImageModal showImageModal={showImageModal} imageurl={el} />
      ) : (
        ""
      )}
    </>
  );
};

export default ShowSelectedImage;
