import { useState } from "react";
import { ToastContainer } from "react-toastify";
import UserAuth from "../components/UserAuth";
import SetNewPwd from "../components/SetNewPwd";

function FindPwd() {
  const [showSetNewPwd, setShowSetNewPwd] = useState(false); // 비번 관련 페에지 띄우기 여부
  const [toastPosition, setToastPosition] = useState(0);

  return (
    <>
      <style jsx="true">{`
        .Toastify__toast {
          top: ${toastPosition + 150}px;
          margin-left: 40px;
        }
      `}</style>
      <div className="admin bg-[#F5F6F7] ">
        <ToastContainer />
        <div className="relative bg-[#4472c4] h-screen min-h-[500px] min-w-[450px]">
          <div className="absolute w-[460px] h-[440px] inset-0 m-auto bg-white drop-shadow-md rounded-sm">
            {!showSetNewPwd ? (
              // 비밀번호 찾기 페이지 (사용자 신원 확인)
              <UserAuth
                setShowSetNewPwd={setShowSetNewPwd}
                setToastPosition={setToastPosition}
              />
            ) : (
              // 새 비밀번호 등록 페이지
              <SetNewPwd setToastPosition={setToastPosition} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default FindPwd;
