import React from "react";
import Style6_1 from "./Style6/Style6_1";
import Style6_2 from "./Style6/Style6_2";
import Style6_3 from "./Style6/Style6_3";
import Style6_4 from "./Style6/Style6_4";
import Style6_5 from "./Style6/Style6_5";

const Style6 = ({ title2, bigTitle }) => {
  return (
    <>
      {bigTitle === "장루 교체방법" ? (
        <Style6_1 title2={title2} bigTitle={bigTitle} />
      ) : bigTitle === "일상속 장루" ? (
        <Style6_2 title2={title2} bigTitle={bigTitle} />
      ) : bigTitle === "원피스 교체" ? (
        <Style6_3 title2={title2} bigTitle={bigTitle} />
      ) : bigTitle === "투피스 교체" ? (
        <Style6_4 title2={title2} bigTitle={bigTitle} />
      ) : bigTitle === "장루 셀프 교체" ? (
        <Style6_5 title2={title2} bigTitle={bigTitle} />
      ) : (
        ""
      )}
    </>
  );
};

export default Style6;
