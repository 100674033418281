import React from "react";

const QuestionTap = ({ setTabname, tabname }) => {
  const active =
    "inline-block py-[8px] px-[16px] border rounded-3xl bg-[#4E535F] text-[#FAFBFC] subtitle_1_400 cursor-pointer";
  const unactive =
    "inline-block py-[8px] px-[16px] border rounded-3xl text-[#4E535F] subtitle_1_400 border-[#4E535F] cursor-pointer";
  const onClickTabName = (e) => {
    setTabname(e.target.innerText);
  };
  return (
    <div className="pt-[20px] ">
      <div className="flex gap-x-[12px]">
        <span
          className={tabname === "전체보기" ? active : unactive}
          onClick={onClickTabName}
        >
          전체보기
        </span>
        <span
          className={tabname === "식생활" ? active : unactive}
          onClick={onClickTabName}
        >
          식생활
        </span>
        <span
          className={tabname === "운동" ? active : unactive}
          onClick={onClickTabName}
        >
          운동
        </span>
      </div>
      <div className="flex gap-x-[12px] pt-[12px]">
        <span
          className={tabname === "일상생활" ? active : unactive}
          onClick={onClickTabName}
        >
          일상생활
        </span>
        <span
          className={tabname === "장루" ? active : unactive}
          onClick={onClickTabName}
        >
          장루
        </span>
        <span
          className={tabname === "증상" ? active : unactive}
          onClick={onClickTabName}
        >
          증상
        </span>
        <span
          className={tabname === "혜택" ? active : unactive}
          onClick={onClickTabName}
        >
          혜택
        </span>
      </div>
    </div>
  );
};

export default QuestionTap;
