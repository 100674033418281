import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMainContext } from "../../../../contexts/Provider";
import MainIcons from "../../mobilecomponent/MainIcons";
/*component*/
import FoodManage2ColostomyMeal from "./subpage_sub/FoodManage2ColostomyMeal";
import FoodManage2IleumMeal from "./subpage_sub/FoodManage2IleumMeal";
import FoodManage2MealPlan from "./subpage_sub/FoodManage2MealPlan";
import FoodManage3AfterConsider from "./subpage_sub/FoodManage3AfterConsider";
import FoodManage3AfterFood from "./subpage_sub/FoodManage3AfterFood";
import FoodManage3StomaBlock from "./subpage_sub/FoodManage3StomaBlock";
import FoodManage3WeightLose from "./subpage_sub/FoodManage3WeightLose";
/*component*/

const SubPageList = () => {
  const leftarrow = "/images/mo/leftarrow.svg";
  const more_big = "/images/mo/more_big.svg";
  const navigate = useNavigate();
  const locationState = useLocation().state;
  const { loginInput } = useMainContext();
  useEffect(() => {
    let pageOpen = false;

    if (!pageOpen) {
      if (
        loginInput.id === null ||
        loginInput.id === undefined ||
        loginInput.id === ""
      ) {
        navigate("/main", { replace: true });
      }
    }
    return () => {
      pageOpen = true;
    };
  }, [loginInput.id, navigate]);
  return (
    <>
      <div className="h-screen relative overflow-y-auto">
        <div className="fixed top-0 left-0 w-full  border-b-[1px] flex justify-between items-center px-[8px] bg-[#FAFCFF]">
          <img
            className="cursor-pointer inline-block "
            src={leftarrow}
            alt="leftarrow"
            onClick={() => {
              navigate(-1);
            }}
          />
          <span className="inline-block subtitle_1_400">
            {locationState.bigTitle}
          </span>
          <img
            src={more_big}
            alt="more_big"
            className=" cursor-pointer"
            onClick={() => {
              navigate("/alljangru");
            }}
          />
        </div>
        <div className="pt-[48px]">
          {locationState.btn2_1 !== undefined ? (
            <FoodManage2MealPlan
              bigTitle={locationState.bigTitle}
              btn2={locationState.btn2}
              btn2_1={locationState.btn2_1}
            />
          ) : locationState.btn2_2 !== undefined ? (
            <FoodManage2IleumMeal
              bigTitle={locationState.bigTitle}
              btn2={locationState.btn2}
              btn2_2={locationState.btn2_2}
            />
          ) : locationState.btn2_3 !== undefined ? (
            <FoodManage2ColostomyMeal
              bigTitle={locationState.bigTitle}
              btn2={locationState.btn2}
              btn2_3={locationState.btn2_3}
            />
          ) : locationState.btn3_1 !== undefined ? (
            <FoodManage3WeightLose
              bigTitle={locationState.bigTitle}
              btn3={locationState.btn3}
              btn3_1={locationState.btn3_1}
            />
          ) : locationState.btn3_2 !== undefined ? (
            <FoodManage3AfterConsider
              bigTitle={locationState.bigTitle}
              btn3={locationState.btn3}
              btn3_2={locationState.btn3_2}
            />
          ) : locationState.btn3_3 !== undefined ? (
            <FoodManage3AfterFood
              bigTitle={locationState.bigTitle}
              btn3={locationState.btn3}
              btn3_3={locationState.btn3_3}
            />
          ) : locationState.btn3_4 !== undefined ? (
            <FoodManage3StomaBlock
              bigTitle={locationState.bigTitle}
              btn3={locationState.btn3}
              btn3_4={locationState.btn3_4}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <MainIcons />
    </>
  );
};

export default SubPageList;
