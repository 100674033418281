import React from "react";
import common, { api } from "../../../../../data/common";
import dayjs from "dayjs";
const AppuseContAnswer = ({
  admin,
  answer,
  imageUrls,
  extraUrls,
  question,
}) => {
  const fileicon = "/images/mo/file.svg";
  return (
    <div className="px-[24px]  mt-[8px] bg-[#FAFCFF]">
      <div className="w-full">
        <div className="flex items-center pb-[16px] border-b">
          <img
            src={
              admin.profileURL
                ? common.networkImg + encodeURIComponent(admin.profileURL)
                : "/images/admin/profile.svg"
            }
            alt="adminprofile"
            className="w-[40px] h-[40px] rounded-full"
          />
          <div className="pl-[16px]">
            <span className="block leading-4">
              {admin.name}{" "}
              {admin.role === 0
                ? "관리자"
                : admin.role === 1
                ? "의사"
                : admin.role === 2
                ? "간호사"
                : ""}
            </span>
            <span className="caption_2 text-[#676C76]">
              {dayjs(answer.createdAt).format("YYYY.MM.DD")}
            </span>
          </div>
        </div>

        <div
          className="pt-[16px]"
          dangerouslySetInnerHTML={{ __html: answer.content }}
        ></div>

        <>
          {imageUrls?.length > 0
            ? imageUrls.map((el, index) => (
                <div
                  key={index}
                  className="w-full rounded overflow-hidden mb-[8px]"
                >
                  <img
                    alt={el}
                    src={common.networkImg + encodeURIComponent(el)}
                  />
                </div>
              ))
            : ""}

          {extraUrls?.length > 0
            ? extraUrls.map((el, index) => (
                <div className="bg-[#F2F6FC] p-[8px] rounded-lg flex items-start ">
                  <img src={fileicon} alt="file" />

                  <div key={index} className="pl-[6px] w-full">
                    <a
                      href={common.networkImg + encodeURIComponent(el)}
                      className="underline block hover:text-[#1B5EDC] overflow-hidden break-all"
                      download
                    >
                      {el}
                    </a>
                  </div>
                </div>
              ))
            : ""}
          {/* <span className="pt-[16px] block">{question.content}</span> 1:1 문의 답변확인시 내 질문 보기로만 내용 보게 주석처리 */}
        </>
      </div>
      <div className="h-[70px]"></div>
    </div>
  );
};

export default AppuseContAnswer;
