import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { NavigationList } from "./NavigationList";
import { useMainContext } from "../../../../contexts/Provider";
import commonUrl, { api } from "../../../../data/common";
import HeaderTitle from "./HeaderTitle";
import HeaderAlertPopup from "./HeaderAlertPopup";
import HeaderMyInfoPopup from "./HeaderMyInfoPopup";

export const Header2 = ({ children, changePhoto, title }) => {
  // changePhoto : MyInfo(내 정보 관리)페이지에서 사진바꿀 경우 true/false로 헤더사진 재로드
  const herings_logo = "/images/admin/header_dash/herings_logo.svg";
  const header_bellIcon_red =
    "/images/admin/header_dash/header_bellIcon_red.svg";
  const header_bellIcon = "/images/admin/header_dash/header_bellIcon.svg";
  const hide = "/images/admin/header_dash/hide.svg";
  const show = "/images/admin/header_dash/show.svg";
  const profile = "/images/admin/profile.svg";

  const navigate = useNavigate();
  const location = useLocation();
  const { adminLogin, Nav, setNav, redDot, setRedDot } = useMainContext();
  const titleRef = useRef(NavigationList.map(() => React.createRef())); // Nav 버튼(button tag)
  const titleLabelRef = useRef(NavigationList.map(() => React.createRef())); // Nav 버튼의 글자 부분(label tag)
  const [hidden, setHidden] = useState(() => {
    // 창 가로너비 줄어들 경우 헤더 타이틀 보여줄지 여부
    if (window.innerWidth >= 738) return false;
    else return true;
  });
  const [myInfoOpen, setMyInfoOpen] = useState(false); // popup-내 정보 관리
  const [alertOpen, setAlertOpen] = useState(false); // popup-미신청자 목록
  const gradientRef = useRef(false); // gradient 애니메이션 보여줄지 여부

  // 왼쪽 네비게이션 접었다 폈다
  const showNav = () => {
    // hide -> show
    if (Nav) {
      gradientRef.current = true;
    }
    setNav(!Nav);
  };

  // resize시 페이지이동 버튼 가로  길이 변경
  const handleResize = () => {
    if (window.innerWidth >= 738) setHidden(false);
    else setHidden(true);
  };

  // changePhoto가 true일 경우 새롭게 사진 불러오기
  useEffect(() => {
    if (changePhoto) {
      const imgEl = document.querySelector(".img__header");
      if (imgEl) {
        imgEl.style.backgroundImage = `url(${
          commonUrl.networkImg + encodeURIComponent(adminLogin.profileURL)
        })`;
      }
    }
  }, [changePhoto, adminLogin.profileURL]);

  // 헤더 사진 적용
  useEffect(() => {
    if (adminLogin.profileURL) {
      const imgEl = document.querySelector(".img__header");
      if (imgEl) {
        imgEl.style.backgroundImage = `url(${
          commonUrl.networkImg + encodeURIComponent(adminLogin.profileURL)
        })`;
      }
    }
  }, [adminLogin.id, adminLogin.profileURL, navigate]);

  // title 클릭시 버튼 디자인 변경
  useEffect(() => {
    let pageIndex = 0;
    const titleBtns = titleRef.current;
    if (titleBtns) {
      // 현재 경로와 맞는 버튼 찾기
      if (location.pathname.includes("/admin/main/dashboard")) pageIndex = 0;
      else if (location.pathname.includes("/admin/main/manager")) pageIndex = 1;
      else if (location.pathname.includes("/admin/main/client")) pageIndex = 2;
      else if (location.pathname.includes("/admin/main/check")) pageIndex = 3;
      else if (location.pathname.includes("/admin/main/message")) pageIndex = 4;
      // else if (location.pathname.includes("/admin/main/community"))
      //   pageIndex = 5;
      if (location.pathname.includes("/admin/main/hospital")) pageIndex = 5;
      else if (location.pathname.includes("/admin/main/inquiry")) pageIndex = 6;
      else if (location.pathname.includes("/admin/main/news")) pageIndex = 7;
      else if (location.pathname.includes("/admin/main/service")) pageIndex = 8;

      /* title button 색 반전 효과 */
      const titleBtn = titleRef.current[pageIndex].current;
      // 모든 버튼 dark
      titleBtns.forEach((element) => {
        if (element.current && element.current.classList.contains("bright")) {
          element.current.classList.remove("bright");
          element.current.classList.add("dark");
        }
        if (titleBtn) {
          titleBtn.classList.remove("dark");
          titleBtn.classList.add("bright");
        }
      });
    }

    /* title label 그라데이션 효과 */
    const labelBtns = titleLabelRef.current;
    const labelBtn = titleLabelRef.current[pageIndex].current;
    // 모든 버튼 dark
    labelBtns.forEach((element) => {
      if (element.current && element.current.classList.contains("bright")) {
        element.current.classList.remove("bright");
        element.current.classList.add("dark");
      }
      if (labelBtn) {
        labelBtn.classList.remove("dark");
        labelBtn.classList.add("bright");
      }
    });
  }, [location.pathname, Nav]);

  // window창 resize detect
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {/******  PopUp - 내 정보 관리 ******/}
      <HeaderMyInfoPopup
        open={myInfoOpen}
        onClose={() => {
          setMyInfoOpen(false);
        }}
      />
      {/******  PopUp - 미신청자 알림 ******/}
      <HeaderAlertPopup alertOpen={alertOpen} setAlertOpen={setAlertOpen} />

      {/******  Header ******/}
      <div className="relative w-full h-full adminScroll ">
        {/* fixed 된 회색 배경 z-0*/}
        <div className="fixed h-full w-full bg-[#F5F6F7]"></div>
        {/* 헤더 z-30 */}
        <div className="headerTop fixed flex items-center top-0 right-[0px] min-w-full h-[80px] min-h-[80px] bg-[#F5F6F7] border border-b-[#CCCDD1] z-30 ">
          <div
            className="h-[80px]"
            style={{
              width: Nav ? "128px" : `${220}px`,
              minWidth: Nav ? "128px" : `${220}px`,
              transition: "width 0.3s ease-out",
            }}
          ></div>
          {/* 페이지 이름 */}
          <div className="flex grow justify-between items-center min-w-[480px] pl-6">
            {hidden ? (
              <div className="w-[10px] h-[80px] "></div>
            ) : (
              <label className="flex items-center text-[18px] font-semibold leading-[22px] text-[#676C76] ">
                {/* 페이지 경로 */}
                <HeaderTitle title={title} />
              </label>
            )}

            <div className="flex items-center gap-x-6">
              {/* 종모양 버튼 */}
              {redDot ? (
                <img
                  className="cursor-pointer w-[40px] h-[40px]"
                  src={header_bellIcon_red}
                  alt="header_bellIcon_red"
                  onClick={() => {
                    setRedDot(false);
                    setAlertOpen(true);
                  }}
                />
              ) : (
                <img
                  className="cursor-pointer w-[40px] h-[40px] "
                  src={header_bellIcon}
                  alt="header_bellIcon"
                  onClick={() => {
                    setRedDot(false);
                    setAlertOpen(true);
                  }}
                />
              )}
              {/* 사진 */}
              {adminLogin.profileURL ? (
                <div
                  className="img__header flex justify-start border border-slate-200 rounded-[50px] w-[48px] h-[48px] min-w-[48px] max-h-[48px] mr-6 bg-cover bg-no-repeat bg-center  cursor-pointer"
                  onClick={() => {
                    setMyInfoOpen(true);
                  }}
                ></div>
              ) : (
                <img
                  className="cursor-pointer w-[48px] h-[48px] mr-[24px] border border-slate-200 rounded-[40px]"
                  src={profile}
                  alt="profile"
                  onClick={() => {
                    setMyInfoOpen(true);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {/* 네비바 z-50 */}
        <div
          className={
            Nav
              ? "hide headerBtn fixed top-0 left-0 flex flex-col h-full justify-start bg-[#04142B] text-white z-50 overflow-x-hidden overflow-y-auto"
              : "show headerBtn fixed top-0 left-0 flex flex-col h-full justify-start bg-[#04142B] text-white z-50 overflow-x-hidden overflow-y-auto"
          }
          style={{
            width: Nav ? "128px" : `${220}px`,
            transition: "width 0.3s ease-out",
          }}
        >
          {/* 헤링스 로고 */}
          <div
            className="flex max-h-[88px] min-h-[88px] w-full bg-[#04142B]"
            style={{
              width: Nav ? "128px" : `${220}px`,
              transition: "width 0.3s ease-out",
            }}
          >
            <div className="flex items-center h-[32px] mt-6">
              <img
                src={herings_logo}
                alt="herings_logo"
                className={
                  Nav
                    ? "w-[24px] h-[24px] ml-[56px] cursor-pointer"
                    : "w-[24px] h-[24px] ml-10 mr-3 cursor-pointer"
                }
                onClick={showNav}
                style={{ transition: "margin-left 0.5s ease-out" }}
              />
              {/* 글자 */}
              {Nav ? null : (
                <div className="flex herings_char">
                  <title className="block text-xl text-white montserrat_700">
                    OstoCare
                  </title>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col justify-between h-full">
            {/* 로고 제외한 버튼들 */}
            <ul
              className="flex flex-col h-auto bg-[#04142B] gap-y-4 "
              style={{
                width: Nav ? "128px" : `${220}px`,
                transition: "width 0.3s ease-out",
              }}
            >
              {NavigationList.map((el, index) => {
                // 슈퍼관리자만 보이도록
                if (adminLogin.power !== 0 && el.id === 3) return null;
                // 헤링스 관리자만 보이도록
                if (
                  adminLogin.belong !== 1 &&
                  (el.id === 2 || el.id === 7 || el.id === 8 || el.id === 10)
                )
                  return null;
                // if (adminLogin.belong !== 1 && el.id === 7) return null;
                // if (adminLogin.belong !== 1 && el.id === 8) return null;
                // if (adminLogin.belong !== 1 && el.id === 10) return null;

                return (
                  <li
                    className={
                      Nav
                        ? `flex justify-end w-[128px] h-[48px] ${
                            el.title === "쪽지" ? "mb-20" : null
                          }`
                        : `flex flex-col items-end pl-6 ${
                            el.title === "쪽지" ? "mb-20" : null
                          }`
                    }
                    key={index}
                    style={
                      Nav
                        ? {
                            transition: "width 0.3s ease-out",
                          }
                        : {
                            width: `${220}px`,
                            transition: "width 0.3s ease-out",
                          }
                    }
                  >
                    {/* rounded 버튼 */}
                    <button
                      className={
                        Nav
                          ? "navIconList dark flex justify-end items-center w-full h-full ml-6 py-[16px] pr-[50px]"
                          : "navigationList dark  w-full h-[48px] pr-6 py-[14px] pl-[34px] "
                      }
                      style={
                        Nav
                          ? null
                          : {
                              paddingRight: "20px",
                              transition: "width 2.5s ease-out 400ms ",
                            }
                      }
                      ref={titleRef.current[index]}
                      onClick={() => {
                        if (location.pathname === el.url) {
                          window.location.reload();
                        } else navigate(`${el.url}`);
                      }}
                    >
                      <div
                        className={Nav ? null : "flex w-full cursor-pointer"}
                      >
                        {el.icon}

                        <label
                          ref={titleLabelRef.current[index]}
                          className={
                            Nav
                              ? "w-full text-left text-transparent "
                              : gradientRef.current
                              ? "navLabel dark w-full text-left ml-[10px] text-base font-semibold leading-5 cursor-pointer"
                              : "dark w-full text-left ml-[10px] text-base font-semibold leading-5 cursor-pointer"
                          }
                        >
                          {Nav ? "" : el.title}
                        </label>
                      </div>
                    </button>
                  </li>
                );
              })}
            </ul>
            {/* hide & show 화살표 */}
            {Nav ? (
              <div className="flex justify-end items-end h-[40px] w-full bg-[#04142B] mt-5">
                <img
                  src={show}
                  className=" w-[22px] h-[16px] mb-8 mr-[48px] cursor-pointer"
                  onClick={showNav}
                  alt="show"
                />
              </div>
            ) : (
              <div className="flex justify-end items-end h-[40px] w-full bg-[#04142B] mt-5">
                <img
                  src={hide}
                  className=" w-[22px] h-[16px] mb-8 mr-[37px] cursor-pointer"
                  onClick={showNav}
                  alt="hide"
                />
              </div>
            )}
          </div>
        </div>

        {/* 네비바에 따라 바뀌는 화면 z-10 */}
        <div
          className="z-10 flex w-full h-full"
          style={{
            width: Nav ? "calc(100%-128px)" : `calc(100%-${220}px)`,
          }}
        >
          {/* 네비바 만큼 왼쪽에 거리두기 */}
          <div
            className="relative z-10 h-full min-h-screen bg-transparent"
            style={{
              width: Nav ? "128px" : `${220}px`,
              minWidth: Nav ? "128px" : `${220}px`,
              transition: "min-width 0.3s ease-out",
            }}
          ></div>
          {/* 페이지마다 변경되는 부분 */}
          <div className="relative z-10 w-full">{children}</div>
        </div>
      </div>
    </>
  );
};

export default Header2;
