import { createContext, useContext, useEffect, useState, useRef } from "react";

const Contexts = createContext({});
const Provider = ({ children }) => {
  //app
  const [loginInput, setLoginInput] = useState(
      JSON.parse(window.localStorage.getItem("loginInput")) || 
      {
        isLoggedIn: false,
        patientID: "",
        id: "",
        name: "",
        nickName: "",
        createdAt: "",
        belongid: "",
        fcmToken: "",
      }
  );

  useEffect(() => {
    window.localStorage.setItem("loginInput", JSON.stringify(loginInput));
  }, [loginInput]);

  const [canSurvey, setCanSurvey] = useState(false);
  const [noReply, setNoReply] = useState(false);
  const [noPeriod, setNoPeriod] = useState(false);
  const [surveyInfo, setSurveyInfo] = useState({
    id: null,
    createdAt: "",
    date: [],
    recentSurveyId: null,
  });
  const [alarmList, setAlarmList] = useState([]);
  const [files, setFiles] = useState([]);
  const [edit_info, setEdit_info] = useState([]);
  const [fileArray, setFileArray] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [clickedDate, setClickedDate] = useState();
  const [selectedText, setSelectedText] = useState("");

  const tabWhat = useRef("check"); // 장루점검, 다이어리, 그래프 페이지 중 어떤 탭을
  // url 이동기록
  const [mobileHistory, setMobileHistory] = useState(
    () => JSON.parse(window.sessionStorage.getItem("mobileHistory")) || []
  ); // mobilehistory
  const [mobhistoryPrevIdx, setMobHistoryPrevIdx] = useState(
    () => JSON.parse(window.sessionStorage.getItem("mobhistoryPrevIdx")) || 0
  ); // mobhistoryPrevIdx
  useEffect(() => {
    window.sessionStorage.setItem(
      "mobileHistory",
      JSON.stringify(mobileHistory)
    );
  }, [mobileHistory]);

  useEffect(() => {
    window.sessionStorage.setItem(
      "mobhistoryPrevIdx",
      JSON.stringify(mobhistoryPrevIdx)
    );
  }, [mobhistoryPrevIdx]);
  //admin
  const [adminLogin, setAdminLogin] = useState(
    () =>
      JSON.parse(window.sessionStorage.getItem("adminLogin")) || {
        id: "",
        name: "",
        email: "",
        phone: "",
        belong: "",
        belongName: "",
        role: "",
        profileURL: "",
        power: "",
        isLoggedIn: false,
      }
  );
  const [belongs, setBelongs] = useState(
    () => JSON.parse(window.sessionStorage.getItem("belongs")) || []
  ); // 소속
  const [adminHistory, setAdminHistory] = useState(
    () => JSON.parse(window.sessionStorage.getItem("adminHistory")) || []
  ); // history
  const [historyPrevIdx, setHistoryPrevIdx] = useState(
    () => JSON.parse(window.sessionStorage.getItem("historyPrevIdx")) || 0
  ); // historyPrevIdx

  const [redDot, setRedDot] = useState(false); // 헤더의 알림팝업 빨간 점 표시
  const [Nav, setNav] = useState(false);
  const [Apostings, setApostings] = useState([]);
  const [ApostingsCheer, setApostingsCheer] = useState([]);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    window.sessionStorage.setItem("adminLogin", JSON.stringify(adminLogin));
  }, [adminLogin]);
  useEffect(() => {
    window.sessionStorage.setItem("belongs", JSON.stringify(belongs));
  }, [belongs]);

  // url 이동기록
  useEffect(() => {
    window.sessionStorage.setItem("adminHistory", JSON.stringify(adminHistory));
  }, [adminHistory]);
  // window.history.state.idx
  useEffect(() => {
    window.sessionStorage.setItem(
      "historyPrevIdx",
      JSON.stringify(historyPrevIdx)
    );
  }, [historyPrevIdx]);

  return (
    <Contexts.Provider
      value={{
        // mobile
        edit_info,
        setEdit_info,
        loginInput,
        setLoginInput,
        files,
        setFiles,
        fileArray,
        setFileArray,
        selectedImages,
        setSelectedImages,
        clickedDate,
        setClickedDate,
        alarmList,
        setAlarmList,
        selectedText,
        setSelectedText,
        tabWhat,
        mobhistoryPrevIdx,
        setMobHistoryPrevIdx,
        mobileHistory,
        setMobileHistory,
        canSurvey,
        setCanSurvey,
        noReply,
        setNoReply,
        noPeriod,
        setNoPeriod,
        surveyInfo,
        setSurveyInfo,

        // adminLogin
        adminLogin,
        setAdminLogin,
        Nav,
        setNav,
        Apostings,
        setApostings,
        ApostingsCheer,
        setApostingsCheer,
        members,
        setMembers,
        redDot,
        setRedDot,
        belongs,
        setBelongs,
        adminHistory,
        setAdminHistory,
        historyPrevIdx,
        setHistoryPrevIdx,
      }}
    >
      {children}
    </Contexts.Provider>
  );
};
export const useMainContext = () => useContext(Contexts);
export default Provider;
