import React, { useState } from "react";
import { toast } from "react-toastify";
import common, { api } from "../../../../../data/common";

import AnnouncedUserId from "./AnnouncedUserId";
const FindUserId = ({ showfindId }) => {
  const closeimg = "/images/mo/closebasic.svg";
  const [findIdInput, setFindIdInput] = useState({
    name: "",
    birth: "",
  });
  const [findeuserId, setFindUserId] = useState("");
  const [announcedUserId, setAnnouncedUserId] = useState(false);

  const onChangeFindId = (e) => {
    const { value, name } = e.target;
    setFindIdInput({ ...findIdInput, [name]: value });
    if (name === "birth" && value.length >= 9) {
      setFindIdInput({ ...findIdInput, birth: value.slice(0, 8) });
    } else {
      setFindIdInput({ ...findIdInput, [name]: value });
    }
  };
  const onClickFindId = (e) => {
    e.preventDefault();
    let body = {
      name: findIdInput.name,
      birth: `${findIdInput.birth.slice(0, 4)}-${findIdInput.birth.slice(
        4,
        6
      )}-${findIdInput.birth.slice(6, 8)}`,
    };
    api.post(common.baseURL + "patient/findId", body).then((res) => {
      if (res.data.ok === true) {
        setFindUserId(res.data.patient.patientID);
        showAnnounceUserId();
      } else {
        toast(
          `
          😳  검색 결과가 없습니다. 확인후 다시 시도해주세요.`,
          {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: "transparent toastbottom",
          }
        );
        let start = setTimeout(() => {
          setFindIdInput({
            name: "",
            birth: "",
          });
        }, 2500);
        return () => {
          clearTimeout(start);
        };
      }
    });
  };
  const showAnnounceUserId = () => {
    setAnnouncedUserId(!announcedUserId);
  };
  return (
    <>
      <div className="w-full fixed top-0 left-0 z-1">
        <div className="px-[24px] max-w-md h-screen flex flex-col mx-auto text-gray-600 antialiased bg-[#FAFCFF] relative">
          <div className="w-full h-[48px] ">
            <img
              className="absolute right-0 top-0 cursor-pointer"
              src={closeimg}
              alt="close"
              onClick={showfindId}
            />
          </div>

          <h1 className="h2_800 ">등록번호 찾기</h1>
          <form className="pt-[48px]">
            <div className="border-b-[1px]">
              <label htmlFor="name" className="block subtitle_1_600">
                이름
              </label>
              <input
                autoComplete="off"
                type="text"
                className="w-full py-[12px] subtitle_1_600 focus:outline-none bg-transparent"
                placeholder="홍길동"
                name="name"
                value={findIdInput.name}
                onChange={onChangeFindId}
              />
            </div>
            <div className="border-b-[1px] pt-6">
              <label htmlFor="name" className="block subtitle_1_600">
                생년월일(예시:19870101)
              </label>
              <input
                autoComplete="off"
                type="number"
                className="w-full py-[12px] subtitle_1_600 focus:outline-none bg-transparent"
                placeholder="YYYYMMDD"
                name="birth"
                value={findIdInput.birth}
                onChange={onChangeFindId}
              />
            </div>
          </form>
        </div>
        {findIdInput.name !== "" && findIdInput.birth !== "" ? (
          <span
            className="fixed bottom-0 left-0 w-full block text-center text-white button_700 bg-[#1B5EDC] hover:bg-[#18428E] py-[20px] ease-in-out duration-300 cursor-pointer"
            onClick={onClickFindId}
          >
            등록번호 찾기
          </span>
        ) : (
          <span className="fixed bottom-0 left-0 w-full block text-center text-white button_700 bg-[#9DC4FA] py-[20px] ">
            등록번호 찾기
          </span>
        )}
      </div>

      {announcedUserId ? (
        <AnnouncedUserId
          showfindId={showfindId}
          closeimg={closeimg}
          findeuserId={findeuserId}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default FindUserId;
