import TableBlue from "./../../../admin_components/TableBlue";
import TableYellow from "./../../../admin_components/TableYellow";
import TableGrey from "./../../../admin_components/TableGrey";
import { useNavigate } from "react-router-dom";
import commonUrl, { api } from "../../../../../data/common";
import { useEffect, useState } from "react";

import dayjs from "dayjs";
import "dayjs/locale/ko";
import common from "../../../../../data/common";
import { Loading } from "../../../admin_components/Loading";
import { handlingError } from "../../../admin_components/Error";

function DashboardOnlyHerings() {
  const dash_community = "/images/admin/header_dash/header_community.svg";
  const dash_app = "/images/admin/header_dash/header_app.svg";
  const dash_news = "/images/admin/header_dash/header_news.svg";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); //
  const [community, setCommunity] = useState([]); // 커뮤니티 관리
  const [app, setApp] = useState([]); // 앱 이용문의
  const [news, setNews] = useState([]); // 새소식

  /******************************* TableBlue UI 관련 변수 & 함수들  ******************************/
  // column명(커뮤니티 & 앱 이용문의)
  const commuAndAppColumns = [
    {
      accessor: "idx",
      Header: "NO.",
      width: "10%",
    },
    {
      accessor: "createdAt",
      Header: "게시일",
      width: "25%",
    },
    {
      accessor: "name",
      Header: "작성자",
      width: "25%",
    },
    {
      accessor: "contents",
      Header: "내용",
      width: "40%",
    },
  ];

  // column명(새소식)
  const newsColumns = [
    {
      accessor: "idx",
      Header: "NO.",
      width: "10%",
    },
    {
      accessor: "category",
      Header: "구분",
      width: "20%",
    },
    {
      accessor: "date",
      Header: "게시 기간",
      width: "30%",
    },
    {
      accessor: "title",
      Header: "제목",
      width: "40%",
    },
  ];

  // 커뮤니티 관리표에 띄울 데이타 다듬기
  function communityPosts(data) {
    return data.map((post, idx) => {
      let newContents = post.contents;
      let newName = post.patient.name;
      let newPatientID = post.patient.patientID;
      let newCreatedAt = dayjs(post.createdAt).format("YYYY/MM/DD");

      return {
        ...post,
        idx: data.length - idx,
        contents: newContents,
        name: `${newName}(${newPatientID})`,
        createdAt: newCreatedAt,
      };
    });
  }

  // 앱 이용문의 표에 띄울 데이타 다듬기
  function appPosts(data) {
    return data.map((post, idx) => {
      let newContents = post.question.content;
      let newName = post.question.patient.name;
      let newPatientID = post.question.patient.patientID;
      let newCreatedAt = dayjs(post.question.createdAt).format("YYYY/MM/DD");

      return {
        ...post,
        idx: data.length - idx,
        contents: newContents,
        name: `${newName}(${newPatientID})`,
        createdAt: newCreatedAt,
        title: post.question.title,
      };
    });
  }

  // 새소식 표에 띄울 데이타 다듬기
  const newsPosts = (data) => {
    return data.map((post, idx) => {
      let newCategory = "";
      let newTitle = post.title;
      let newDate = `${post.startDate}~${post.endDate}`;

      if (post.category === 1) {
        newCategory = "장루소식";
      } else if (post.category === 2) {
        newCategory = "앱소식";
      } else if (post.category === 3) {
        newCategory = "커뮤니티";
      }

      return {
        ...post,
        idx: data.length - idx,
        category: newCategory,
        title: newTitle,
        date: newDate,
      };
    });
  };

  // 첫 랜더시 데이터 불러오기
  useEffect(() => {
    async function fetchData() {
      // 커뮤니티 관리
      const res1 = await api.get(commonUrl.community).catch((error) => {
        handlingError(error);
      });
      console.log(res1.data, ">>> Axios community");
      if (res1.data.ok && res1.data.postingList)
        setCommunity(res1.data.postingList.slice(0, 5));
      // 앱 이용문의
      const res2 = await api.get(common.baseURL + "question").catch((error) => {
        handlingError(error);
      });
      console.log(res2.data, ">>> Axios app question");
      if (res2.data.ok && res2.data.questionList)
        setApp(res2.data.questionList.slice(0, 5));
      // 새 소식
      const res3 = await api.get(common.baseURL + "news").catch((error) => {
        handlingError(error);
      });
      console.log(res3.data, ">>> Axios news");
      if (res3.data.ok && res3.data.newsList)
        setNews(res3.data.newsList.slice(0, 3));
      setLoading(false);
    }

    fetchData();
  }, []);

  return (
    <>
      {/* 커뮤니티 & 앱 이용문의 */}
      {/* 커뮤니티 관리 */}
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="flex justify-between w-full mt-6">
            {/* <div className="flex flex-col w-1/2 min-w-[492px] mr-6 p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)]">
              <div className="flex justify-between w-full">
                <div className="flex grow gap-x-2 mb-[25px]">
                  <img
                    src={dash_community}
                    alt="dash_community"
                    className="w-[20px] h-[20px] cursor-pointer "
                  />
                  <label className="text-[18px] font-bold leading-[22px] text-[#343B48]">
                    커뮤니티 관리
                  </label>
                </div>
                <p
                  className="mt-[1px] font-normal cursor-pointer text-base text-[#020A1B]"
                  onClick={() => {
                    navigate("/admin/main/community");
                  }}
                >
                  더보기 &gt;
                </p>
              </div> */}
            {/* 커뮤니티 테이블 */}
            {/* <div className="max-h-[482px] w-full rounded-2xl overflow-hidden">
                <TableYellow
                  columns={commuAndAppColumns}
                  data={communityPosts(community)}
                  pageMove={"/admin/main/communityid"}
                />
              </div>
            </div> */}
            <div className="flex flex-col w-1/2 min-w-[492px] p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mr-6">
              <div className="flex justify-between">
                <div className="flex grow gap-x-2 mb-[25px]">
                  <img
                    src={dash_app}
                    alt="dash_app"
                    className="w-[20px] h-[20px] cursor-pointer "
                  />
                  <label className="text-[18px] font-bold leading-[22px] text-[#343B48]">
                    앱 이용문의 관리
                  </label>
                </div>
                <p
                  className="mt-[1px] font-normal cursor-pointer text-base text-[#020A1B]"
                  onClick={() => {
                    navigate("/admin/main/inquiry");
                  }}
                >
                  더보기 &gt;
                </p>
              </div>
              {/* 앱 이용문의 테이블 */}
              <div className="max-h-[482px] rounded-2xl overflow-hidden">
                <TableGrey
                  columns={commuAndAppColumns}
                  data={appPosts(app)}
                  pageMove={"/admin/main/inquiryid"}
                />
              </div>
            </div>

            <div className="flex flex-col w-1/2 min-w-[492px] p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)]">
              <div className="flex justify-between">
                <div className="flex grow gap-x-2 mb-[25px]">
                  <img
                    src={dash_news}
                    alt="dash_news"
                    className="w-[20px] h-[20px] cursor-pointer "
                  />
                  <label className="text-[18px] font-bold leading-[22px] text-[#343B48]">
                    새 소식
                  </label>
                </div>
                <p
                  className="mt-[1px] font-normal cursor-pointer text-base text-[#020A1B]"
                  onClick={() => {
                    navigate("/admin/main/news");
                  }}
                >
                  더보기 &gt;
                </p>
              </div>
              {/* 새 소식 테이블 */}
              <div className="max-h-[482px] rounded-2xl overflow-hidden">
                <TableBlue
                  columns={newsColumns}
                  data={newsPosts(news)}
                  pageMove={"/admin/main/newsid"}
                  pageSizeNum={3}
                />
              </div>
            </div>
          </div>

          {/* 새소식 */}
        </>
      )}
    </>
  );
}

export default DashboardOnlyHerings;
