// 총 참여자 & 참여종료자
const ParticipantOX = ({ data }) => {
  const participant = "/images/admin/header_dash/participant.svg";
  return (
    <div className="flex flex-col p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] text-[#343B48] ">
      <div className="flex gap-x-[6px] mb-[26px]">
        <img
          src={participant}
          alt="participant"
          className="w-[20px] h-[20px]"
        />
        <label className="text-[18px] font-extrabold leading-[22px]">
          참여자 수
        </label>
      </div>
      <div className="flex text-[#020A1B]">
        {/* 총 참여자 */}
        <div className="flex flex-col w-full gap-y-2">
          <label className="w-full text-left text-lg leading-[22px] font-semibold">
            총 참여자
          </label>
          <p className="flex justify-end items-center h-full w-full text-lg leading-[22px] font-semibold">
            <span className="text-2xl font-extrabold mr-1">
              {data[2].total}
            </span>
            명
          </p>
        </div>
        <div className="w-[1px] h-[62px] bg-[#CCCDD1] mx-6"></div>
        {/* 참여 종료자 */}
        <div className="flex flex-col w-full gap-y-2">
          <label className="w-full text-left text-lg leading-[22px] font-semibold">
            참여 종료자
          </label>
          <p className="flex justify-end items-center h-full w-full text-lg leading-[22px] font-semibold">
            <span className="text-2xl font-extrabold mr-1">{data[2].end}</span>
            명
          </p>
        </div>
      </div>
    </div>
  );
};

export default ParticipantOX;
