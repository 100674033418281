import React from "react";

const Tutorial7 = () => {
  const tutorial7 = "/images/mo/pngjpg/tutorial7.png";
  return (
    <div>
      <div className="pt-[25.5px] flex justify-center ">
        <div className="w-[375px] h-[329px] flex items-center justify-center relative">
          <img src={tutorial7} alt="tutorial7" />
        </div>
      </div>
      <div className="flex items-center flex-col pt-[50px]">
        <span className="block text-[#1B5EDC] h2_800">마이페이지</span>
        <div className="text-[#343B48] text-center body_1_400 pt-[8px]">
          <div>마이페이지에서 내 정보 관리,</div>
          <div>비밀번호 변경, 앱 이용 문의 등을 할 수 있어요.</div>
        </div>
      </div>
    </div>
  );
};

export default Tutorial7;
