import React, { useState } from "react";
import common, { api } from "../../../../../data/common";
import { toast } from "react-toastify";
/*component*/
import NewPass from "./NewPass";
const FindPass = ({ showfindPass }) => {
  const closeimg = "/images/mo/closebasic.svg";
  const [findPassInput, setFindPassInput] = useState({
    name: "",
    birth: "",
    patientID: "",
  });
  const [newPass, setNewPass] = useState(false);

  const onChangeFindPass = (e) => {
    const { value, name } = e.target;
    setFindPassInput({ ...findPassInput, [name]: value });
    if (name === "birth" && value.length >= 9) {
      setFindPassInput({ ...findPassInput, birth: value.slice(0, 8) });
    } else {
      setFindPassInput({ ...findPassInput, [name]: value });
    }
  };
  console.log(
    `${findPassInput.birth.slice(0, 4)}-${findPassInput.birth.slice(
      4,
      6
    )}-${findPassInput.birth.slice(6, 8)}`
  );
  const showNewPass = () => {
    api
      .get(common.baseURL + `patient/all/1`)
      .then((res) => {
        if (res.data.ok === true) {
          const patientList = res.data.patientList;
          const filterPatient = patientList.filter(
            (el) =>
              el.name === findPassInput.name &&
              String(el.birth) ===
                `${findPassInput.birth.slice(0, 4)}-${findPassInput.birth.slice(
                  4,
                  6
                )}-${findPassInput.birth.slice(6, 8)}` &&
              el.patientID === findPassInput.patientID
          );
          console.log(filterPatient);
          if (filterPatient.length === 1) {
            setNewPass(true);
          } else {
            toast(
              `
              😳  검색 결과가 없습니다. 확인후 다시 시도해주세요.`,
              {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                className: "transparent toastbottom",
              }
            );
            let start = setTimeout(() => {
              setFindPassInput({
                name: "",
                birth: "",
                patientID: "",
              });
            }, 2500);
            return () => {
              clearTimeout(start);
            };
          }
        }
      })
      .catch((error) => console.log(error));
  };
  const setTimeoutScroll = () => {
    const forScrollEl = document.querySelector(".for__scroll");
    forScrollEl.scroll(0, forScrollEl.clientHeight);
  };
  return (
    <>
      <div className="w-full fixed top-0 left-0 z-10 h-screen ">
        <div className=" px-[24px] max-w-md h-screen flex flex-col mx-auto text-gray-600 antialiased bg-[#FAFCFF] ">
          <div className=" absolute top-0 left-0 px-[24px] w-full bg-[#FAFCFF]">
            <div className="w-full h-[48px] ">
              <img
                className="absolute right-0 top-0 cursor-pointer "
                src={closeimg}
                alt="close"
                onClick={showfindPass}
              />
            </div>
            <h1 className="h2_800 pt-[14px]">비밀번호 찾기</h1>
            <div className=" body_1_400 pt-[8px]">
              <span className="text-[#1B5EDC]">이름, 생년월일, 등록번호</span>
              <span>를 입력해 주세요.</span>
            </div>
          </div>
          <form className="for__scroll mt-[124px] pt-[48px] relative overflow-y-auto ">
            <div className="border-b-[1px]">
              <label htmlFor="name" className="block subtitle_1_600">
                이름
              </label>
              <input
                autoComplete="off"
                type="text"
                className="w-full py-[12px] caption_1_600 focus:outline-none bg-transparent"
                placeholder="홍길동"
                name="name"
                value={findPassInput.name}
                onChange={onChangeFindPass}
              />
            </div>
            <div className="border-b-[1px] pt-2">
              <label htmlFor="name" className="block subtitle_1_600">
                생년월일
              </label>
              <input
                autoComplete="off"
                type="number"
                className="w-full py-[12px] caption_1_600 focus:outline-none bg-transparent"
                placeholder="YYYYMMDD"
                name="birth"
                onClick={setTimeoutScroll}
                value={findPassInput.birth}
                onChange={onChangeFindPass}
              />
            </div>
            <div className="border-b-[1px] pt-2">
              <label htmlFor="name" className="block subtitle_1_600">
                등록번호
              </label>
              <input
                autoComplete="off"
                type="text"
                className="w-full py-[12px] caption_1_600 focus:outline-none bg-transparent"
                placeholder="ABCD123"
                name="patientID"
                value={findPassInput.patientID}
                onChange={onChangeFindPass}
              />
            </div>
          </form>
          <div className="h-[110px]"></div>
        </div>
        {findPassInput.name !== "" &&
        findPassInput.birth !== "" &&
        findPassInput.patientID !== "" ? (
          <span
            className="fixed bottom-0 left-0 w-full block text-center text-white button_700 bg-[#1B5EDC] hover:bg-[#18428E] py-[20px] ease-in-out duration-300 cursor-pointer"
            onClick={showNewPass}
          >
            비밀번호 찾기
          </span>
        ) : (
          <span className="fixed bottom-0 left-0 w-full block text-center text-white button_700 bg-[#9DC4FA] py-[20px]">
            비밀번호 찾기
          </span>
        )}
      </div>
      {newPass ? (
        <NewPass
          showfindPass={showfindPass}
          closeimg={closeimg}
          findPassInput={findPassInput}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default FindPass;
