import React from "react";
import GobackPage from "../../mobilecomponent/GoBackPage";
import MainIcons from "../../mobilecomponent/MainIcons";
import AllJangruVideo from "../Main/components/AllJangruVideo";
/*comonent*/
import InnertextModalPass from "../Main/components/InnertextModalPass";
import { StomaManageText } from "../Main/data/StomaManageText";

const AllJangru = () => {
  return (
    <>
      <div className="relative h-screen overflow-y-auto">
        <GobackPage children={"장루의 모든 것"} />
        <div className="px-[24px] pt-[48px] flex justify-between relative">
          <div className="pt-[24px]">
            <h1 className="h2_800">💬 &nbsp;장루의 모든 것</h1>
            <span className="block caption_1_400 pt-[4px]">
              장루에 대한 궁금증 바로 바로 해결하세요!
            </span>
          </div>
        </div>
        <div className="relative overflow-y-auto">
          <div className="grid grid-cols-3 justify-center gap-4 py-6 px-[24px]">
            {StomaManageText.map((val, index) =>
              index < 12 ? (
                <InnertextModalPass
                  key={val.title}
                  children={val.title}
                  url={val.svgurl}
                  StomaManageText={StomaManageText}
                />
              ) : null
            )}
          </div>

          <AllJangruVideo />
        </div>
      </div>
      <MainIcons />
    </>
  );
};

export default AllJangru;
