import Popup from "reactjs-popup";
import commonUrl, { api } from "../../../../../data/common";
import { useEffect, useState } from "react";
import ExplainPolicy from "../components/ExplainPolicyPopup";

import { useMainContext } from "../../../../../contexts/Provider";
import { LoadingBlue } from "../../../admin_components/Loading";
import { toast, ToastContainer } from "react-toastify";
import {
  toastCommonProps,
  findToastPosition,
} from "../../../admin_components/Toast";
import { useNavigate } from "react-router-dom";
import { handlingError } from "../../../admin_components/Error";

const AgreePopup = () => {
  const stoma_logo = "/images/admin/header_dash/herings_logo.svg";
  const rectangle_check = "/images/admin/rectangle_check.svg";
  const paging_right_arrow = "/images/admin/paging_right_arrow.svg";
  const navigate = useNavigate();
  const { adminLogin, setAdminLogin } = useMainContext();
  const [loading, setLoading] = useState(false);
  const [allCheck, setAllCheck] = useState(false); // 전체 동의
  // 필수 동의 2개
  const [subCheck, setSubCheck] = useState({
    service: false,
    personalInfo: false,
  });
  const [policyOpen, setPolicyOpen] = useState(false); // 이용약관 팝업 띄우기
  const [clickPolicy, setClickPolicy] = useState(null); // 이용약관/개인정보 버튼 중 뭐눌렀는지
  const [toastPosition, setToastPosition] = useState(0);

  // 확인버튼
  const agree = () => {
    // 토스트창 위치 설정
    setToastPosition(findToastPosition("top"));

    // 전부 동의하면
    if (allCheck) {
      // axios
      async function fetchData() {
        setLoading(true);
        const res = await api({
          method: "post",
          url: commonUrl.admin + "agree",
          data: {
            id: adminLogin.id,
            agree: true,
          },
        }).catch((error) => {
          handlingError(
            error,
            "top-center",
            "toast_common toast__login rounded-[48px] !left-[27px]"
          );
        });
        console.log(res.data, ">>> Axios admin/agree");
        setLoading(false);
        // axios 성공 시
        if (res.data.ok) {
          setAdminLogin({ ...adminLogin, isLoggedIn: true });
          navigate("/admin/main/dashboard")
        }
        // axios 실패 시
        else {
          toast(
            <p> 정보 저장 오류 </p>,
            toastCommonProps(
              2000,
              "top-center",
              "toast_common toast__login rounded-[48px] !left-[27px]"
            )
          );
        }
      }
      fetchData();
    }
    // 전체 동의 아닐 시
    else {
      toast(
        <p>전체 동의가 필요합니다. </p>,
        toastCommonProps(
          2000,
          "top-center",
          "toast_common toast__login rounded-[48px] !left-[27px]"
        )
      );
    }
  };

  // 정책 보기 버튼들 중 하나 눌렀을 때
  useEffect(() => {
    if (clickPolicy) {
      setPolicyOpen(true);
    }
  }, [clickPolicy]);

  // 전체 동의 누른 후
  useEffect(() => {
    // 전체동의 체크됐는데
    if (allCheck) {
      // 필수 2개 전부 체크되어 있는 상황이 아닌 경우 둘다 체크
      if (!(subCheck.service && subCheck.personalInfo))
        setSubCheck({ service: true, personalInfo: true });
    }
    // 전체동의 해제됐는데
    else {
      // 필수 2개 전부 체크되어 있는 경우 둘다 해제
      if (subCheck.service && subCheck.personalInfo) {
        setSubCheck({ service: false, personalInfo: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCheck]);

  // 필수 동의 2개 중 무언가를 누른 후
  useEffect(() => {
    // 필수 2개중 체크되지 않은 것이 생겼고
    if (!(subCheck.service && subCheck.personalInfo)) {
      // 전체 동의가 체크되어있는 경우 전체 동의 해제
      if (allCheck) setAllCheck(false);
    }
    // 필수 2개가 모두 체크되었는데
    else {
      // 전체 동의가 해제되어 있는 경우 전체 동의 체크
      if (!allCheck) setAllCheck(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCheck]);

  return (
    <>
      <style jsx="true">{`
        @import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@700&display=swap");
        .Toastify__toast {
          top: ${toastPosition + 110};
          margin-left: 10px;
        }
        .popup-overlay.adminAgree_popup-overlay {
          position: absolute !important;
          min-width: 500px !important;
          min-height: 100vh;
          overflow: auto;
        }
        .popup-content.adminAgree_popup-content {
          font-family: "Pretendard", sans-serif !important;
          width: 376px important;
          max-width: 376px !important;
          min-width: 376px !important;
          box-sizing: border-box;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          background: #fafbfc;
          padding: 0px;
          border: none;
        }
        .montserrat {
          font-family: "Montserrat Alternates", sans-serif !important;
        }
      `}</style>
      <ToastContainer />
      <ExplainPolicy
        policyOpen={policyOpen}
        setPolicyOpen={setPolicyOpen}
        clickPolicy={clickPolicy}
      />
      <div className="w-full h-full">
        <Popup
          className="adminAgree_popup"
          open={true}
          closeOnDocumentClick={false}
        >
          <div className="flex flex-col rounded-[8px] w-[376px] h-[480px] max-h-[480px] bg-transparent">
            <div className="w-full h-full px-12 py-8 bg-transparent ">
              {/* 제목 */}
              <div className="flex justify-center w-full gap-x-1">
                <img src={stoma_logo} alt="stoma_logo" className="" />
                <label className="montserrat text-lg font-semibold text-[#020A1B] leading-[26px]">
                  OstoCare
                </label>
              </div>
              {/* 설명 */}
              <p className="mt-8 text-base font-normal leading-[22px] text-[#676C76] ">
                오스토케어를 이용하시기에 앞서 <br /> 아래의​ 서비스 동의를
                진행해 주세요.
                <br /> 미 동의시 ​웹 이용이 불가능 합니다.
              </p>
              {/* 동의 체크 박스들 */}
              <div className="flex flex-col mt-8 gap-y-2 ">
                {/* 전체 동의 */}
                <div className="flex items-center gap-x-2 px-1 py-[17px] border border-transparent border-b-[#CCCDD1] ">
                  {allCheck ? (
                    <img
                      src={rectangle_check}
                      alt="rectangle_check"
                      className="cursor-pointer"
                      onClick={() => {
                        setAllCheck(false);
                      }}
                    />
                  ) : (
                    <div
                      className="h-[16px] w-[16.39px] rounded-[2px] bg-[#020A1B] cursor-pointer"
                      onClick={() => {
                        setAllCheck(true);
                      }}
                    ></div>
                  )}
                  <label className="text-[#020A1B] leading-[22px] font-normal text-lg">
                    서비스 내용 전체 동의하기
                  </label>
                </div>
                {/* 서비스 동의 */}
                <div className="flex items-center px-1 gap-x-2 ">
                  {subCheck.service ? (
                    <img
                      src={rectangle_check}
                      alt="rectangle_check"
                      className="cursor-pointer"
                      onClick={() => {
                        setSubCheck({ ...subCheck, service: false });
                      }}
                    />
                  ) : (
                    <div
                      className="h-[16px] w-[18px] rounded-[2px] bg-[#020A1B] cursor-pointer"
                      onClick={() => {
                        setSubCheck({ ...subCheck, service: true });
                      }}
                    ></div>
                  )}
                  <label className="flex w-full justify-between items-center text-[#4E535F] leading-[22px] font-normal text-lg">
                    [필수] 서비스 이용약관
                    <img
                      src={paging_right_arrow}
                      alt="paging_right_arrow"
                      className="cursor-pointer"
                      style={{
                        filter:
                          "brightness(0) saturate(100%) invert(63%) sepia(8%) saturate(206%) hue-rotate(184deg) brightness(96%) contrast(93%)",
                      }}
                      onClick={() => {
                        console.log("service");
                        setClickPolicy("service");
                      }}
                    />
                  </label>
                </div>
                {/* 개인정보 동의 */}
                <div className="flex items-center px-1 gap-x-2 ">
                  {subCheck.personalInfo ? (
                    <img
                      src={rectangle_check}
                      alt="rectangle_check"
                      className="cursor-pointer"
                      onClick={() => {
                        setSubCheck({ ...subCheck, personalInfo: false });
                      }}
                    />
                  ) : (
                    <div
                      className="h-[16px] w-[18px] rounded-[2px] bg-[#020A1B] cursor-pointer"
                      onClick={() => {
                        setSubCheck({ ...subCheck, personalInfo: true });
                      }}
                    ></div>
                  )}
                  <label className="flex w-full justify-between items-center text-[#4E535F] leading-[22px] font-normal text-lg">
                    [필수] 개인정보 처리방침
                    <img
                      src={paging_right_arrow}
                      alt="paging_right_arrow"
                      className="cursor-pointer"
                      style={{
                        filter:
                          "brightness(0) saturate(100%) invert(63%) sepia(8%) saturate(206%) hue-rotate(184deg) brightness(96%) contrast(93%)",
                      }}
                      onClick={() => {
                        console.log("personalInfo");
                        setClickPolicy("personalInfo");
                      }}
                    />
                  </label>
                </div>
                {/* 확인 */}
                <button
                  className="toast_standard flex justify-center items-center h-[62px] w-[280px] mt-6 border border-[#679DEE] box-border hover:bg-[#679DEE] text-[#679DEE] hover:text-[#FFFFFF] font-bold text-base leading-[22px] focus:outline-none"
                  onClick={agree}
                >
                  {loading ? <LoadingBlue /> : "확인"}
                </button>
                {/* 취소하기 */}
                <div className="flex items-center justify-center w-full">
                  <button
                    className="flex justify-center items-center box-border h-[30px] w-[81px] py-1 px-3 border border-transparent border-b-[#999CA3] font-bold leading-[22px] text-[15px] text-[#999CA3]"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    취소하기
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Popup>
      </div>
    </>
  );
};

export default AgreePopup;
