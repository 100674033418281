import React from "react";

const Meditation = ({ bigTitle, btn6 }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400 wordbreak-keepall">
      <div className="pt-[72px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
        <h1 className="h2_800 pt-[4px] pb-[24px]">{btn6}</h1>
      </div>
      <div className="w-full pt-[24px] body_1_400_jangrucont">
        <span className="inline-block ">1. 심상유도란 무엇인가요?</span>
        <div className="pl-[12px]">
          편안하고 안전한 장소를 머릿속으로 생생하게 그려보면서 불안을
          가라앉히는 방법입니다.
        </div>

        <div className="flex pt-[25px]">
          <span className="inline-block ">2. 심상유도 연습해보기(예시)</span>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            자신에게 편안하고 안전하다고 느껴지는 장소를 떠올려보세요. 따스한
            햇살이 비치는 해변이나 시냇물이 졸졸 흐르는 숲속 등 어디든 좋습니다.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            복용하는 약물의 목록을 작성한 후 추후 의료진에게 보여주세요.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            그곳에서 한가롭게 쉬고 있는 자신을 상상해 보세요. 무엇이 보이나요?
            눈앞에 펼쳐진 장면을 생생하게 그려보세요.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            어떤 소리가 들리는지, 어떤 냄새가 나는지, 피부에 무엇이 느껴지는지에
            집중해보세요.
          </div>
        </div>
        <div className="flex">
          <span className="inline-block ">•</span>
          <div className="pl-[4px]">
            발바닥 아래로 물이 얕게 찰랑이고 얼굴에 시원한 바람이 스칩니다.
            청량한 바다 향기, 상쾌한 솔 내음이 느껴집니다. 파도가 잘게 부서지는
            소리, 멀리서 새가 지저귀는 소리를 상상하십시오.
          </div>
        </div>
      </div>
      <div className="h-[70px]"></div>
    </div>
  );
};

export default Meditation;
