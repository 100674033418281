import React, { useState } from "react";
import common, { api } from "../../../../../data/common";
import { toast, ToastContainer } from "react-toastify";
/*component*/
import CenterModal from "../../../mobilecomponent/CenterModal";

const UnderstandingModal = ({
  understanded,
  showUnderStandModal,
  surveyid,
  setUnderstanded,
  surveydate,
  MYID,
}) => {
  const radio_on = "/images/mo/radio_on.svg";
  const radio_off = "/images/mo/radio_off.svg";
  const [radioBtn, setRadioBtn] = useState(null);
  const understandingList = [
    { name: "전부 이해함", degree: 4 },
    { name: "대부분 이해함", degree: 3 },
    { name: "보통 이해함", degree: 2 },
    { name: "대부분 이해 못함", degree: 1 },
    { name: "거의 이해 못함", degree: 0 },
  ];

  const closeicon = "/images/mo/closebasic.svg";
  const jangruboyhi = "/images/mo/jangruboyhi.svg";
  const onClickSubmitUnderstand = () => {
    let body = {
      surveyId: surveyid,
      degree: radioBtn,
    };
    // console.log(body);

    api
      .post(common.baseURL + "jangruSurvey/addUnderstanding", body)
      .then((res) => {
        console.log(res);
        if (res.data.ok === true) {
          api

            .get(common.baseURL + `jangruSurvey/${MYID}/${surveydate}`)
            .then((getsurv) => {
              if (getsurv.data.ok === true) {
                setUnderstanded(getsurv.data.understanding);
                toast(`이해도가 저장되었습니다.`, {
                  position: "bottom-center",
                  autoClose: 2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  limit: 1,
                  className: "transparent toastbottom",
                });
                let start = setTimeout(() => {
                  showUnderStandModal();
                }, 2000);
                return () => {
                  clearTimeout(start);
                };
              }
            })
            .catch((error) => console.log(error));
        } else {
          alert("이해정도 저장에 문제가 있습니다.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {understanded === undefined ? (
        <CenterModal>
          <span className="block">의료진이 제공한 점검내용에 대해</span>
          <span className="block">어느정도 이해되셨나요?</span>
          <span className="text-[#4E535F] caption_1_400">
            (이해도 체크는 1:1 정보제공에 도움이 됩니다.)
          </span>
          <ul className="pt-[24px]">
            {understandingList.map((el) => (
              <li
                className="cursor-pointer bg-[#F2F6FC] p-[16px] mb-[4px] rounded-md flex items-center gap-x-[8px]"
                onClick={() => setRadioBtn(el.degree)}
                key={el.degree}
              >
                {el.degree === radioBtn ? (
                  <img src={radio_on} alt={radio_on} />
                ) : (
                  <img src={radio_off} alt={radio_off} />
                )}
                <span>{el.name}</span>
              </li>
            ))}
          </ul>
          <div className="flex gap-x-[8px] pt-[24px]">
            <span
              className="p-[16px] w-1/2 rounded-sm flex justify-center items-center border border-[#679DEE] text-[#679DEE] button_700 cursor-pointer"
              onClick={showUnderStandModal}
            >
              취소
            </span>
            <span
              className="p-[16px] w-1/2 rounded-sm flex justify-center items-center bg-[#1B5EDC] text-white 
              hover:bg-[#18428E] hover:text-white ease-in-out duration-300 button_700 cursor-pointer"
              onClick={onClickSubmitUnderstand}
            >
              확인
            </span>
          </div>
        </CenterModal>
      ) : (
        <CenterModal>
          <div className="flex justify-end">
            <img
              src={closeicon}
              alt="closebasic"
              className="cursor-pointer"
              onClick={showUnderStandModal}
            />
          </div>
          <div className="flex flex-col items-center ">
            <img src={jangruboyhi} alt="jangruboyhi" />
            <div className="pt-[16px]">
              <span>이해도 </span>
              <span className="text-[#1B5EDC]">
                {
                  understandingList[
                    understandingList.findIndex(
                      (el) => el.degree === understanded?.degree
                    )
                  ]?.name
                }
              </span>
              <span>에 체크하셨습니다.</span>
            </div>
          </div>
        </CenterModal>
      )}

      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        bodyClassName={() =>
          "w-[175px] p-3 rounded-full overflow-hidden bg-[#CBDFFC] text-black text-sm font-normal"
        }
      ></ToastContainer>
    </>
  );
};

export default UnderstandingModal;
