import React from "react";

const FoodManage2MealPlan = ({ bigTitle, btn2, btn2_1 }) => {
  return (
    <div className="w-full flex flex-col px-[16px]">
      <div className="py-[24px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">
          {bigTitle} &gt; {btn2}
        </span>
        <h1 className="h2_800 pt-[4px]">{btn2_1} - 회장루/결장루 공통</h1>
      </div>
      <div className="w-full pt-[24px] body_1_400_jangrucont">
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <div>
            <span className="inline-block ">
              수술 후에는 미음, 죽 형태의 부드러운 식사로 시작하고 소화력이
              양호하면 고형식으로 전환하여 점차 식사량을 늘려갑니다.
            </span>
          </div>
        </div>
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <div>
            <span className="inline-block ">
              수술 후 4-6주 정도는 섬유소 섭취량을 줄여봅니다.(완전한 제한의
              의미는 아닙니다.)
            </span>
            <span className="inline-block ">
              소화 불편감이 없고 장루 배설량이 양호하면 섬유소 섭취량은
              점차적으로 늘려나갑니다.
            </span>
          </div>
        </div>
        <div className="border w-full my-[24px]">
          <span className="inline-block w-full p-[8px] bg-[#F2F6FC] border-b text-center">
            섬유소가 많은 음식
          </span>
          <span className="inline-block w-full p-[16px] text-center">
            잡곡, 채소 및 과일류, 견과류 등
          </span>
        </div>
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <div>
            <span className="inline-block ">
              규칙적인 식사는 규칙적인 배변습관에 도움이 되고 가스차는 것을 줄일
              수 있습니다.
            </span>
          </div>
        </div>
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <div>
            <span className="inline-block ">
              특정 음식을 제한하지 말고 골고루 섭취하여 균형잡힌 영양섭취를
              합니다.
            </span>
          </div>
        </div>
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <div>
            <span className="inline-block ">천천히 꼭꼭 씹어 먹습니다.</span>
          </div>
        </div>
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <div>
            <span className="inline-block ">
              매 끼니 또는 간식으로 단백질 포함된 음식을 먹습니다.
            </span>
          </div>
        </div>
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <div>
            <span className="inline-block ">
              한밤 중의 배변 횟수를 줄일 수 있도록 저녁식사 시간 또는 야식
              섭취를 조절할 수 있습니다.
            </span>
          </div>
        </div>
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <div>
            <span className="inline-block ">
              가스 생성을 막기 위해 빨대 사용을 자제합니다.
            </span>
          </div>
        </div>
        <div className="flex">
          <span className="inline-block">•&nbsp;</span>
          <div>
            <span className="inline-block ">
              가스, 설사, 냄새 등의 불편감을 줄이기 위해 주의 식품들은 한 번에
              한가지씩 적은 양으로 시도하고, 익숙해지면 점차 먹는 양을 늘려
              봅니다.
            </span>
          </div>
        </div>
        <span className="inline-block pt-[24px]">
          각각의 식품에 대한 순응도를 확인할 수 있도록 한 번에 한가지씩 섭취하고
          불편감이 있을 때는 중단하였다가 2~3주 내에 다시 시도합니다.
        </span>
        <div className="h-[70px]"></div>
      </div>
    </div>
  );
};

export default FoodManage2MealPlan;
