import React from "react";
import { useLocation } from "react-router-dom";

const StomaManage = ({ showStomaManage }) => {
  const text = useLocation().state.text;
  const content = useLocation().state.content;
  const conttext1 = useLocation().state.conttext1;
  const conttext2 = useLocation().state.conttext2;
  const video = useLocation().state.video;
  const leftimg = "/images/mo/left.svg";
  return (
    <div className="w-full fixed top-[34px] left-0 z-60 bg-[#FAFCFF] ">
      <div className="max-w-md h-screen flex flex-col mx-auto text-gray-600 antialiased relative">
        <div className="absolute z-50 top-4 left-0 w-full flex justify-between items-center">
          <img
            src={leftimg}
            alt="leftimag"
            className="cursor-pointer inline-block p-2"
            onClick={showStomaManage}
          />
          <span className="inline-block p-2">{text}</span>
          <span className="inline-block p-2"></span>
        </div>
        <div className="bg-gray-100 w-full h-10 mt-20 px-4 flex items-center">
          {content}
        </div>
        <div className="mt-4">
          <div className="bg-gray-100 h-40 flex justify-center items-center">
            {video ? (
              <video src={video} width="500px" height="200px" />
            ) : (
              "image"
            )}
          </div>
          <div>
            <span className="block pt-10">{conttext1}</span>
            <span className="block pt-10">{conttext2}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StomaManage;
