import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import common, { api } from "../../../../data/common";
import { useMainContext } from "../../../../contexts/Provider";

/*compononets*/
import GobackPage from "../../mobilecomponent/GoBackPage";
import SaveList from "./components/SaveList";

import SkeletonLoading from "../../mobilecomponent/SkeletonLoading";
import CanUseModal from "./components/CanUseModal";
import FirstAdminContent from "./components/FirstAdminContent";
import CantuseBg from "./components/CantuseBg";

const Community = () => {
  const { loginInput } = useMainContext();
  const [postings, setPostings] = useState([]);

  const sirenicon = "images/mo/siren.svg";
  const whitepen = "images/mo/whitepen.svg";
  const [loading, setLoading] = useState(true);
  const [morePosts, setMorePosts] = useState(false);
  const [postingOpenDate, setPostingOpenDate] = useState(String(new Date()));
  const [canuse, setCanuse] = useState(false);
  const [canusemodal, setCanuseModal] = useState(false);
  const navigate = useNavigate();

  const showCanUseModal = () => {
    setCanuseModal(!canusemodal);
  };

  console.log(morePosts, ">>moreposts");
  const getPostings = useCallback(
    (postingOpenDate) => {
      const date =
        postingOpenDate !== undefined ? postingOpenDate : String(new Date());
      console.log(date, ">>???date");
      api
        .get(common.baseURL + "community/all/" + date + "/" + loginInput.id)
        .then((res) => {
          console.log(res, ">>");

          if (
            res.data.ok === true &&
            res.data.message !== "게시글이 하나도 없습니다."
          ) {
            console.log(res.data.postingList, ">>>commu all");
            setPostings(res.data.postingList);
            setMorePosts(res.data.more);
            setPostingOpenDate(
              res.data.postingList[res.data.postingList.length - 1]?.posting
                .createdAt
            );
            let start = setTimeout(() => {
              setLoading(false);
            }, 500);
            return () => {
              clearTimeout(start);
            };
          } else {
            setPostings();
            setLoading(false);
          }
        });
    },
    [setPostings, setMorePosts, setPostingOpenDate, setLoading]
  );
  const getPatient = useCallback(() => {
    api
      .get(common.baseURL + "patient/id/" + loginInput.id)
      .then((res) => {
        if (res.data.ok === true) {
          setCanuse(res.data.patient.writable);
        }
      })
      .catch((error) => console.log(error));
  }, [setCanuse, loginInput.id]);

  useEffect(() => {
    let pageOpen = false;
    if (!pageOpen) {
      if (
        loginInput.id === null ||
        loginInput.id === undefined ||
        loginInput.id === ""
      ) {
        navigate("/main", { replace: true });
      } else {
        getPostings();
        getPatient();
      }
    }
    return () => {
      pageOpen = true;
    };
  }, [navigate, loginInput.id, getPatient, getPostings, loginInput]);
  console.log(
    window.scrollY + document.documentElement.clientHeight,
    document.documentElement.scrollHeight - 100
  );
  useEffect(() => {
    function onScroll() {
      if (
        window.scrollY + document.documentElement.clientHeight >=
        document.documentElement.scrollHeight - 100
      ) {
        if (morePosts) {
          getPostings(postingOpenDate);
        }
      }
    }
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [morePosts, postingOpenDate]);

  const goCommuNotice = () => {
    navigate("/community/notice");
  };
  const goCommuWrite = () => {
    navigate("/community/write");
  };
  return (
    <>
      {loading ? <SkeletonLoading /> : ""}
      {canuse ? (
        ""
      ) : (
        <CantuseBg canuse={canuse} setCanuseModal={setCanuseModal} />
      )}
      {canusemodal ? <CanUseModal showCanUseModal={showCanUseModal} /> : ""}
      <div className=" relative overflow-auto bg-[#F1F2F3] h-screen">
        <GobackPage children={"커뮤니티"} />
        <div
          className="w-full mt-[48px] py-[18px] px-[27px] flex bg-[#CBDFFC] cursor-pointer"
          onClick={goCommuNotice}
        >
          <img src={sirenicon} alt="sirenicon" className="pr-3" />
          <span className="inline-block w-full subtitle_1_600">
            커뮤니티 이용안내
          </span>
        </div>

        <div>
          {postings
            ? postings
                .filter((el) => el.posting?.public === 0)
                .map((post, index) => (
                  <SaveList
                    post={post}
                    key={index}
                    index={index}
                    setPostings={setPostings}
                    postings={postings}
                  />
                ))
            : ""}
        </div>
        <FirstAdminContent />
        <div className="h-[120px]"></div>
      </div>
      <span
        className="fixed z-1 bottom-[24px] right-[24px] inline-block bg-[#1B5EDC] p-3 w-[48px] h-[48px] rounded-full cursor-pointer"
        onClick={goCommuWrite}
      >
        <img src={whitepen} alt="whitepen" className="w-full h-full" />
      </span>
    </>
  );
};

export default Community;
