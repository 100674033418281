import { useEffect, useState } from "react";
import { Loading } from "../../../admin_components/Loading";
import commonUrl, { api } from "../../../../../data/common";
import { useNavigate } from "react-router-dom";
import { useTable, useSortBy, usePagination } from "react-table";
import { handlingError } from "../../../admin_components/Error";
import excelDown from "./excelDown";
import dayjs from "dayjs";

function JangruCheck({ state }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  /******************************* Table UI 관련 변수 & 함수들  ******************************/
  // Table에 들어갈 column명
  const columns = [
    {
      accessor: "idx",
      Header: "NO.",
      width: "7%",
    },
    {
      accessor: "regular",
      Header: "정기 점검일",
      width: "13%",
    },
    {
      accessor: "recentSurveyDay",
      Header: "설문 작성일",
      width: "13%",
    },
    {
      accessor: "replyDate",
      Header: "장루 평가일",
      width: "13%",
    },
    {
      accessor: "progress",
      Header: "평가 상태",
      width: "10%",
    },
    {
      accessor: "replyName",
      Header: "담당자",
      width: "13%",
    },
    {
      accessor: "understanding",
      Header: "관리내용 이해도 평가",
      width: "13%",
    },
    {
      accessor: "pdf",
      Header: "환자관리료 제출양식",
      width: "13%",
      pdf: "/admin/main/pdf",
    },
  ];

  // axios로 불러온 데이타 UI에 맞게 가공하여 state에 저장시키는 함수
  const handleData = (data) => {
    let newData = data.patientForCheckList ? data.patientForCheckList : []; // 그 외 정보

    return newData.map((el, idx) => {
      let newPdf =
        el.understanding === "평가완료" &&
        (newData.length - idx) % 2 === 0 &&
        newData[idx + 1]?.understanding === "평가완료"
          ? `다운로드`
          : "-";
      let replyName = "";
      if (el.replyAdmin) {
        if (el.replyAdmin.role === 0) replyName = "관리자";
        else if (el.replyAdmin.role === 1) replyName = "의사";
        else if (el.replyAdmin.role === 2) replyName = "간호사";
        else replyName = "미확인";
      }

      return {
        idx: newData.length - idx,
        id: el.surveyId,
        replyName: el.replyAdmin ? el.replyAdmin.name + " " + replyName : "-",
        regular: el.regular,
        recentSurveyDay: el.recentSurveyDay,
        replyDate: el.replyDate,
        progress: el.progress,
        pdf: newPdf,
        surveyId: el.surveyId,
        checkId: el.checkId,
        replyId: el.replyId,
        understanding: el.understanding,
        patientName: state.patientName,
      };
    });
  };

  /*** 첫 랜더시 환자 id로 정보 불러오기***/
  useEffect(() => {
    // 비동기 함수
    async function fetchData() {
      setLoading(true);
      const response = await api({
        method: "get",
        url: commonUrl.jangruSurvey + "check/" + state.id,
      }).catch((error) => {
        handlingError(error);
      });
      // console.log(response, ">>> jangruSurvey/check + id");
      setData(handleData(response.data));

      setLoading(false);
    }
    if (state) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : data.length !== 0 ? (
        <JangruCheckTable
          columns={columns}
          data={data}
          pageMove={"/admin/main/check/jangrucheck"}
          pageSize={5}
          pagination={true}
          state={state}
        />
      ) : (
        <label className=" text-base font-normal leading-[20px] text-[#A0A3AB] mt-2 ">
          등록된 내용이 없습니다.
        </label>
      )}
    </>
  );
}

function JangruCheckTable({
  columns,
  data,
  pageMove,
  state, // prevPath 절달
  sorting, // sorting 기능이 필요한 테이블이면 true
  pagination, // pagination이 필요한 테이블이면 true
  showAll, // 검색기능이 있어서 전체보기가 필요한 테이블이면 click(=showAll) 함수
  pageSize, // pageSize 조정
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: pageSize ? pageSize : 10 },
    },
    useSortBy,
    usePagination
  );
  const arrow_left = "/images/admin/carousel_left.svg";
  const arrow_right = "/images/admin/carousel_right.svg";
  const navigate = useNavigate();
  const paginationBtns = new Array(pageCount).fill(0).map((el, idx) => idx + 1);
  let flag = 0;
  console.log(data);
  // pagination UI
  const paginationUI = () => {
    return (
      <div className="flex justify-center items-center mt-[25px]">
        <button
          className="px-3"
          onClick={() => {
            previousPage();
          }}
          disabled={!canPreviousPage}
        >
          <img
            src={arrow_left}
            alt="arrow_left"
            className="w-[6.4px] h-[11.2px]"
            style={
              !canPreviousPage
                ? {
                    filter:
                      "brightness(0) saturate(100%) invert(48%) sepia(0%) saturate(5417%) hue-rotate(195deg) brightness(134%) contrast(82%)",
                  }
                : {
                    filter:
                      "brightness(0) saturate(100%) invert(3%) sepia(29%) saturate(7499%) hue-rotate(218deg) brightness(99%) contrast(100%)",
                  }
            }
          />
        </button>
        <div className="flex gap-x-1">
          {paginationBtns.map((el, idx) => {
            if (
              idx >= Math.floor(pageIndex / 5) * 5 &&
              idx <= Math.floor(pageIndex / 5) * 5 + 4
            ) {
              return (
                <button
                  key={idx}
                  className="flex justify-center items-center border  w-[32px] h-[32px] text-center text-base text-[#020A1B] font-semibold leading-5 rounded-[28px]"
                  onClick={() => {
                    gotoPage(idx);
                  }}
                  style={{
                    borderColor: pageIndex === idx ? "#020A1B" : "transparent",
                  }}
                >
                  {idx + 1}
                </button>
              );
            } else return null;
          })}
        </div>
        <button
          className="px-3"
          onClick={() => {
            nextPage();
          }}
          disabled={!canNextPage}
        >
          <img
            src={arrow_right}
            alt="arrow_right"
            className="w-[6.4px] h-[11.2px]"
            style={
              !canNextPage
                ? {
                    filter:
                      "brightness(0) saturate(100%) invert(48%) sepia(0%) saturate(5417%) hue-rotate(195deg) brightness(134%) contrast(82%)",
                  }
                : {
                    filter:
                      "brightness(0) saturate(100%) invert(3%) sepia(29%) saturate(7499%) hue-rotate(218deg) brightness(99%) contrast(100%)",
                  }
            }
          />
        </button>
      </div>
    );
  };
  console.log(page);
  return (
    <>
      <div className="flex flex-col w-full">
        <table className="w-full table-fixed">
          <thead {...getTableProps()}>
            {headerGroups.map((headerGroup) => {
              return (
                <tr key={headerGroup.headers}>
                  {headerGroup.headers.map((col, idx) => {
                    if (col.render("Header") === "내용") flag = idx;
                    return (
                      <th
                        key={col.id}
                        className="relative first:rounded-tl-2xl last:rounded-tr-2xl align-middle p-4 bg-[#CBDFFC] text-base text-[#020A1B] leading-[22px] font-semibold"
                        {...col.getHeaderProps(
                          idx === 0
                            ? {
                                style: { width: col.width },
                              }
                            : sorting
                            ? col.getSortByToggleProps({
                                style: { width: col.width },
                              })
                            : {
                                style: { width: col.width },
                              }
                        )}
                      >
                        {col.render("Header")}
                        <span className="text-xs align-middle">
                          {sorting
                            ? col.isSorted
                              ? col.isSortedDesc
                                ? " ▼"
                                : " ▲"
                              : idx === 0
                              ? ""
                              : " ▷"
                            : null}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, rowIdx) => {
              prepareRow(row);
              return (
                <tr
                  key={row.id}
                  {...row.getRowProps()}
                  className={
                    // 보여주는 행 개수가 홀수이면
                    page.length % 2 !== 0
                      ? rowIdx % 2 === 0
                        ? " bg-[#F2F6FC] hover:bg-[#DDE9FA]"
                        : " bg-[#FAFCFF] hover:bg-[#DDE9FA]"
                      : // 보여주는 행 개수가 짝수이면
                      rowIdx % 2 !== 0
                      ? " bg-[#F2F6FC] hover:bg-[#DDE9FA]"
                      : " bg-[#FAFCFF] hover:bg-[#DDE9FA]"
                  }
                  style={{
                    cursor: row.cells[2].value === "-" ? null : "pointer",
                  }}
                >
                  {row.cells.map((cell, idx) => {
                    if (cell.column.Header === "환자관리료 제출양식") {
                      return (
                        <td
                          key={cell.row.id}
                          className={
                            page.length - 1 === rowIdx
                              ? "align-middle p-4 first:rounded-bl-2xl last:rounded-br-2xl border-t border-[#CBDFFC] text-base text-[#020A1B] leading-[22px] font-normal"
                              : "align-middle p-4 border-t border-[#CBDFFC] text-base text-[#020A1B] leading-[22px] font-normal "
                          }
                          {...cell.getCellProps({
                            style: { width: cell.column.width },
                          })}
                          onClick={() => {
                            if (row.original.pdf === "다운로드") {
                              console.log("1212");
                              excelDown({
                                type: 1,
                                patientId: state.id,
                                month:
                                  dayjs(row.original.regular).get("month") + 1,
                              });
                            }
                          }}
                        >
                          <button
                            style={{
                              display: "block",
                              width: "50%",
                              margin: "0 auto",
                              padding: "3px 0",
                              borderRadius: "5px",
                              color: "white",
                              fontWeight: 600,
                              backgroundColor: `${
                                row.original.pdf === "다운로드" && "#1B5EDC"
                              }`,
                            }}
                          >
                            {cell.render("Cell")}
                          </button>
                        </td>
                      );
                    } else {
                      return (
                        <td
                          key={cell.row.id}
                          className={
                            page.length - 1 === rowIdx
                              ? "align-middle p-4 first:rounded-bl-2xl last:rounded-br-2xl border-t border-[#CBDFFC] text-base text-[#020A1B] leading-[22px] font-normal"
                              : "align-middle p-4 border-t border-[#CBDFFC] text-base text-[#020A1B] leading-[22px] font-normal "
                          }
                          {...cell.getCellProps({
                            style: { width: cell.column.width },
                          })}
                          onClick={() => {
                            if (
                              pageMove !== undefined &&
                              row.cells[2].value !== "-"
                            ) {
                              navigate(pageMove, {
                                state: {
                                  data: row.original,
                                  detailPageId: state.id,
                                },
                              });
                            }
                          }}
                        >
                          {
                            <div
                              className={
                                cell.column.Header === "PDF" &&
                                row.original.understanding === "평가완료"
                                  ? "w-full text-center truncate hover:underline hover:text-[#1B5EDC]"
                                  : "w-full text-center truncate"
                              }
                              id={cell.column.Header}
                              onClick={() => {}}
                              style={
                                cell.row.original.checkOX === "미신청"
                                  ? { color: cell.column.color }
                                  : null
                              }
                            >
                              {/* {flag === idx ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: cell.value,
                                }}
                              ></div>
                            ) : (
                              cell.render("Cell")
                            )} */}
                              {cell.render("Cell")}
                            </div>
                          }
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* pagenation */}
        {pagination && page.length !== 0 ? (
          showAll ? (
            <div className="flex justify-between">
              <p
                className="mx-[5px] mt-[25px] min-w-[65px] cursor-pointer underline underline-offset-4 font-bold"
                onClick={showAll}
              >
                전체보기
              </p>
              {paginationUI()}
              <div className="mx-[5px] mt-[25px] min-w-[65px]"></div>
            </div>
          ) : (
            paginationUI()
          )
        ) : null}
      </div>
    </>
  );
}

export default JangruCheck;
