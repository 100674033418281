import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMainContext } from "../../../../contexts/Provider";
import MainIcons from "../../mobilecomponent/MainIcons";
/*component*/

import AbdominalBreathing from "./subpage/AbdominalBreathing";
import AlergyDamage from "./subpage/AlergyDamage";
import AroundStomaBlooding from "./subpage/AroundStomaBlooding";
import AroundStomaHernia from "./subpage/AroundStomaHernia";
import Epidermis from "./subpage/Epidermis";
import FeelAnxiety from "./subpage/FeelAnxiety";
import FeelBetter from "./subpage/FeelBetter";
import FeelSad from "./subpage/FeelSad";
import FeelTired from "./subpage/FeelTired";
import FoodManage1 from "./subpage/FoodManage1";
import FoodManage2 from "./subpage/FoodManage2";
import FoodManage3 from "./subpage/FoodManage3";
import LifeInManage1 from "./subpage/LifeInManage1";
import LifeInManage2 from "./subpage/LifeInManage2";
import LifeInManage3 from "./subpage/LifeInManage3";
import LifeInManage4 from "./subpage/LifeInManage4";
import Meditation from "./subpage/Meditation";
import PoopDamage from "./subpage/PoopDamage";
import PyodermaGangrene from "./subpage/PyodermaGangrene";
import SkinAndMucosa from "./subpage/SkinAndMucosa";
import StomDepression from "./subpage/StomaDepression";
import StomaHernia from "./subpage/StomaHernia";
import StomaStricture from "./subpage/StomaStricture";
import WelfareConsultant from "./subpage/WelfareConsultant";
import WelfareNote from "./subpage/WelfareNote";
import WelfareOrganize from "./subpage/WelfareOrganize";
import WelfareSalary from "./subpage/WelfareSalary";
const SubPage = () => {
  const leftarrow = "/images/mo/leftarrow.svg";
  const more_big = "/images/mo/more_big.svg";
  const navigate = useNavigate();
  const locationState = useLocation().state;
  const { loginInput } = useMainContext();
  useEffect(() => {
    let pageOpen = false;

    if (!pageOpen) {
      if (
        loginInput.id === null ||
        loginInput.id === undefined ||
        loginInput.id === ""
      ) {
        navigate("/main", { replace: true });
      }
    }
    return () => {
      pageOpen = true;
    };
  }, [loginInput.id, navigate]);
  console.log(locationState, ">>>>???");
  return (
    <>
      <div className="h-screen relative overflow-y-auto ">
        <div className="fixed top-0 left-0 bg-[#FAFCFF] w-full border-b-[1px] flex justify-between items-center px-[8px]">
          <img
            className="cursor-pointer inline-block "
            src={leftarrow}
            alt="leftarrow"
            onClick={() => {
              navigate(-1);
            }}
          />
          <span className="inline-block subtitle_1_400">
            {locationState.title2}
          </span>
          <img
            src={more_big}
            alt="more_big"
            className="cursor-pointer"
            onClick={() => {
              navigate("/alljangru");
            }}
          />
        </div>
        {locationState.btn1 === "장루로 인한 영양문제 & 장루식사요법의 목표" ? (
          <FoodManage1
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn1={locationState.btn1}
          />
        ) : locationState.btn2 === "수술 후 첫 4-6주간 영양가이드" ? (
          <div>
            <FoodManage2
              title2={locationState.title2}
              bigTitle={locationState.bigTitle}
              btn2={locationState.btn2}
            />
          </div>
        ) : locationState.btn3 === "기타 고려사항" ? (
          <FoodManage3
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn3={locationState.btn3}
          />
        ) : locationState.btn1 === "옷은 어떻게 입을까?" ? (
          <LifeInManage1
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn1={locationState.btn1}
          />
        ) : locationState.btn2 === "여행시 고려사항은?" ? (
          <LifeInManage2
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn2={locationState.btn2}
          />
        ) : locationState.btn3 === "외출과 사회활동은?" ? (
          <LifeInManage3
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn3={locationState.btn3}
          />
        ) : locationState.btn4 === "장루와 성생활은?" ? (
          <LifeInManage4
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn4={locationState.btn4}
          />
        ) : locationState.btn1 === "장루 주위 탈장" ? (
          <AroundStomaHernia
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn1={locationState.btn1}
          />
        ) : locationState.btn2 === "장루 주위 출혈" ? (
          <AroundStomaBlooding
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn2={locationState.btn2}
          />
        ) : locationState.btn3 === "장탈출" ? (
          <StomaHernia
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn3={locationState.btn3}
          />
        ) : locationState.btn4 === "피부와 장점막 분리" ? (
          <SkinAndMucosa
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn4={locationState.btn4}
          />
        ) : locationState.btn5 === "함몰" &&
          locationState.bigTitle === "장루 합병증" ? (
          <StomDepression
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn5={locationState.btn5}
          />
        ) : locationState.btn6 === "협착" &&
          locationState.bigTitle === "장루 합병증" ? (
          <StomaStricture
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn6={locationState.btn6}
          />
        ) : locationState.btn1 === "대변에 의한 손상" ? (
          <PoopDamage
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn1={locationState.btn1}
          />
        ) : locationState.btn2 === "알러지에 의한 손상" ? (
          <AlergyDamage
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn2={locationState.btn2}
          />
        ) : locationState.btn3 === "표피 비대" ? (
          <Epidermis
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn3={locationState.btn3}
          />
        ) : locationState.btn4 === "괴저성 농피증" ? (
          <PyodermaGangrene
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn4={locationState.btn4}
          />
        ) : locationState.btn1 === "기분이 나아지는 방법" ? (
          <FeelBetter
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn1={locationState.btn1}
          />
        ) : locationState.btn2 === "피로감이 느껴질 때" ? (
          <FeelTired
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn2={locationState.btn2}
          />
        ) : locationState.btn3 === "불안감이 느껴질 때" ? (
          <FeelAnxiety
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn3={locationState.btn3}
          />
        ) : locationState.btn4 === "우울감이 느껴질 때" ? (
          <FeelSad
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn4={locationState.btn4}
          />
        ) : locationState.btn5 === "복식호흡 하는 방법" ? (
          <AbdominalBreathing
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn5={locationState.btn5}
          />
        ) : locationState.btn6 === "명상/심상유도 방법" ? (
          <Meditation
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn6={locationState.btn6}
          />
        ) : locationState.btn1 === "복지혜택 참고사항" ? (
          <WelfareNote
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn1={locationState.btn1}
          />
        ) : locationState.btn2 === "복지혜택 정리" ? (
          <WelfareOrganize
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn2={locationState.btn2}
          />
        ) : locationState.btn3 === "치료재료 급여 인정 기준" ? (
          <WelfareSalary
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn3={locationState.btn3}
          />
        ) : locationState.btn4 === "교육 상담료 급여 기준" ? (
          <WelfareConsultant
            title2={locationState.title2}
            bigTitle={locationState.bigTitle}
            btn4={locationState.btn4}
          />
        ) : (
          ""
        )}

        <div className="h-[80px]"></div>
      </div>
      <MainIcons />
    </>
  );
};

export default SubPage;
