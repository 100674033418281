import { useNavigate } from "react-router-dom";
import { useMainContext } from "../../../../../contexts/Provider";
import { useEffect, useState } from "react";

import common, { api } from "../../../../../data/common";
import Check10Images from "./Check10Images";
import BasicLoading from "../../../mobilecomponent/BasicLoading";

const Check10 = ({ checkInfo }) => {
  const navigate = useNavigate();

  const {
    files,
    setFileArray,
    loginInput,
    selectedImages,
    setCanSurvey,
    setNoReply,
  } = useMainContext();
  const [user, setUser] = useState([]);
  const [saving, setSaving] = useState(false);
  const submitData = () => {
    let num = 0;
    setSaving(true);

    // for (let i = 0; i < files.length; i++) {
    //   console.log(files[i]);
    //   let formData = new FormData();
    //   formData.append("file", files[i]);
    //   formData.append(
    //     "name",
    //     files[i].lastModified + files[i].name.replaceAll(" ", "")
    //   );
    //   api.post(common.baseURL + "upload/oneNew", formData).then((awsres) => {
    //     console.log(awsres);
    //     if (awsres.data.ok === true) {
    //       num = num + 1;
    //       if (num === files.length) {
    //         api
    //           .post(common.baseURL + "jangruSurvey", checkInfo)
    //           .then((postRes) => {
    //             console.log(postRes);
    //             if (postRes.data.ok === true) {
    //               setFileArray([]);
    //               setSaving(false);
    //               navigate(`/check/11`);
    //               setCanSurvey(false);
    //               setNoReply(true);
    //             } else {
    //               console.log(postRes);
    //             }
    //           })
    //           .catch((error) => console.log(error));
    //       }
    //     }
    //   });
    // }
    Promise.all(
      files.map((file) => {
        let formData = new FormData();
        formData.append("file", file);
        formData.append(
          "name",
          file.lastModified + file.name.replaceAll(" ", "")
        );
        return api.post(common.baseURL + "upload/oneNew", formData);
      })
    )
      .then((result) => {
        api
          .post(common.baseURL + "jangruSurvey", checkInfo)
          .then((postRes) => {
            console.log(postRes);
            if (postRes.data.ok === true) {
              setFileArray([]);
              setSaving(false);
              navigate(`/check/11`);
              setCanSurvey(false);
              setNoReply(true);
            } else {
              console.log(postRes);
            }
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    const getPatientId = () => {
      api
        .get(common.baseURL + "patient/Id/" + loginInput.id)
        .then((res) => {
          console.log(res);
          if (res.data.ok === true) {
            if (checkInfo.writer === "") {
              navigate("/main");
            } else {
              setUser(res.data.patient);
            }
          }
        })
        .catch((error) => console.log(error));
    };

    getPatientId();
  }, [loginInput.id, checkInfo, navigate]);
  const goCheck1 = () => {
    navigate(`/check/1`);
  };
  return (
    <>
      {saving ? <BasicLoading /> : ""}

      <div className="h-screen break-all">
        <div className="pt-[56px] px-[16px]">
          <span className="body_1_600 block py-[20px]">
            작성자 |{" "}
            {checkInfo.writer !== "본인" && checkInfo.writer !== ""
              ? `${user.name}의 보호자 (${checkInfo.writer})`
              : checkInfo.writer === "본인"
              ? checkInfo.writer
              : "미작성"}
          </span>
        </div>
        <div className="mx-[16px] bg-[#FAFCFF] rounded-lg p-[16px]">
          <span className="subtitle_1_700">현재상황</span>
          <div className="py-[8px] border-b">
            {checkInfo.symptom.map((el, index) => (
              <span className="block text-[#245BBF] body_1_600" key={index}>
                {el === 1
                  ? "· 문제없음"
                  : el === 2
                  ? "· 장루교체가 능숙하지 않음"
                  : el === 3
                  ? "· 일상생활,사회활동 어려움"
                  : el === 4
                  ? "· 식생활,영양관리 어려움"
                  : el === 5
                  ? "· 대변 누출이 잦음"
                  : el === 6
                  ? "· 심리적 어려움"
                  : ""}
              </span>
            ))}
            <span className="block text-[#245BBF] body_1_600">
              {checkInfo.symptomNote ? `· ${checkInfo.symptomNote}` : ""}
            </span>
          </div>
          <ul className="py-[8px] subtitle_2">
            <li className="flex justify-between ">
              <span>현재 체중</span>
              <span className="text-[#245BBF] ">{checkInfo.weight}kg</span>
            </li>
            <li className="flex justify-between pt-[10px]">
              <span>장루주머니 교체일</span>
              <span className="text-[#245BBF] ">{checkInfo.changeDate}</span>
            </li>
            <li className="flex justify-between pt-[10px]">
              <span>장루주머니 교체 주기</span>
              <span className="text-[#245BBF] ">{checkInfo.changeCycle}일</span>
            </li>
          </ul>
        </div>
        <div className="mx-[16px] bg-[#FAFCFF] rounded-lg p-[16px] mt-[16px]">
          <ul className="py-[8px] subtitle_2">
            <li className="flex justify-between">
              <span>장루 합병증</span>
              <span className="text-[#245BBF] ">
                {checkInfo.jangruComplicationsOX === "O" ? "있음" : "없음"}
              </span>
            </li>
            <li className="flex justify-between pt-[10px]">
              <span>피부 합병증</span>
              <span className="text-[#245BBF] ">
                {checkInfo.skinComplicationsOX === "O" ? "있음" : "없음"}
              </span>
            </li>

            {checkInfo.dehydrationOX === "O" ? (
              <li className="flex justify-between pt-[10px]">
                <span>탈수 증상</span>
                <span className="text-[#245BBF] ">있음</span>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
        <div className="w-full mt-[16px] relative px-[32px] flex justify-end">
          <span className="absolute top-[10px] right-[18px] inline-block w-4 h-4  rounded-br-full bg-[#CBDFFC] "></span>
          <div className=" rounded-lg bg-[#CBDFFC] p-[16px]">
            {selectedImages.length > 3 ? (
              <>
                <div className="flex gap-x-[4px] justify-end">
                  {selectedImages.slice(0, 3).map((el) => (
                    <Check10Images key={el} el={el} />
                  ))}
                </div>
                <div className="flex justify-end gap-x-[4px] pt-[4px]">
                  {selectedImages
                    .slice(-(selectedImages.length - 3))
                    .map((el) => (
                      <Check10Images key={el} el={el} />
                    ))}
                </div>
              </>
            ) : (
              <div className="flex gap-x-[4px] justify-end">
                {selectedImages.map((el) => (
                  <Check10Images key={el} el={el} />
                ))}
              </div>
            )}
            <p className="text-right pt-[8px] body_1_600 ">
              {checkInfo.imageText}
            </p>
          </div>
        </div>
        {checkInfo.question ? (
          <div className="w-full mt-[16px] relative px-[32px] flex justify-end">
            <span className="absolute top-[10px] right-[18px] inline-block w-4 h-4  rounded-br-full bg-[#CBDFFC] "></span>
            <div className=" rounded-lg bg-[#CBDFFC] p-[16px] body_1_600">
              {checkInfo.question}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="h-[120px]"></div>
        <div className="fixed  bottom-0 left-0 w-full  bg-[#FAFCFF]">
          <div className="flex ">
            <div
              className="border w-2/5 py-[20px] flex justify-center items-center cursor-pointer 
         bg-[#FAFCFF] active:bg-[#F2F6FC] border-[#679DEE] active:border-[#18428E] button_700 text-[#1B5EDC] active:text-[#18428E] ease-in-out duration-300"
              onClick={goCheck1}
            >
              수정하기
            </div>
            <div
              className=" w-3/5 py-[20px] button_700 flex justify-center items-center cursor-pointer  bg-[#1B5EDC] text-white active:bg-[#18428E] ease-in-out duration-300"
              onClick={submitData}
            >
              확인완료
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Check10;
