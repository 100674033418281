// context : 버튼 내부 이름
// standard : toast창 띄울 시 기준이 될 버튼으로 표시하기 위해 standard props가 넘어오면 클래스에 이름 추가

import { useNavigate } from "react-router-dom";

function BtnBlue({ context, onClick, loading, style, standard }) {
  return (
    <button
      className={
        standard
          ? `toast_standard btnPosition flex justify-center items-center w-auto px-6 py-2 font-bold text-base leading-[22px] text-[#FAFBFC] bg-[#1B5EDC] rounded-[6px]`
          : "btnPosition flex justify-center items-center w-auto px-6 py-2 font-bold text-base leading-[22px] text-[#FAFBFC] bg-[#1B5EDC] rounded-[6px]"
      }
      onClick={() => {
        if (!loading) {
          onClick();
        }
      }}
      style={style ? style : null}
    >
      {loading ? (
        <svg
          className="animate-spin h-[15px] w-[15px] rounded-full border-2 border-white border-t-[#1B5EDC] border-r-[#1B5EDC] "
          viewBox="0 0 24 24"
        />
      ) : (
        context
      )}
    </button>
  );
}

function BtnBlack({ context, onClick, loading, style, standard, disabled }) {
  const modify = "/images/admin/modify.svg";
  let input = 0;
  if (context === "저장하기_댓글") {
    input = 1;
    context = "저장하기";
  } else if (context === "저장하기_댓글_non") {
    input = 2;
    context = "저장하기";
  }
  return (
    <button
      className={
        standard
          ? input === 2
            ? " toast_standard btnPosition flex justify-center items-center w-auto min-h-[40px] px-4 py-2 font-bold text-base leading-[22px] text-white bg-gray-400  border border-[#E5E6E7] cursor-not-allowed"
            : " toast_standard btnPosition flex justify-center items-center w-auto min-h-[40px] px-4 py-2 font-bold text-base leading-[22px] text-[#FFFFFF] bg-[#04142B] rounded-[6px]"
          : " btnPosition flex justify-center items-center w-auto min-h-[40px] px-4 py-2 font-bold text-base leading-[22px] text-[#FFFFFF] bg-[#04142B] rounded-[6px]"
      }
      onClick={() => {
        if (!loading) {
          onClick();
        }
      }}
      style={style ? style : null}
      // disabled={disabled ? true : false}
      disabled={disabled}
    >
      {loading ? (
        <svg
          className="animate-spin h-[15px] w-[15px] rounded-full border-2 border-white border-t-[#E5E6E7] border-r-[#E5E6E7] "
          viewBox="0 0 24 24"
        />
      ) : (
        <div className="flex justify-center items-center gap-x-[11px]">
          <p>{context}</p>
          {context === "수정하기" ? (
            <img
              src={modify}
              alt="modify"
              className="w-[18px] h-[18px] cursor-pointer "
            />
          ) : null}
        </div>
      )}
    </button>
  );
}

function BtnWhite({ context, onClick, loading, style, standard }) {
  return (
    <button
      className={
        standard
          ? "toast_standard btnPosition flex justify-center items-center w-auto px-4 py-2 font-bold text-base leading-[22px] text-[#04142B] border border-[#04142B] bg-[#FAFCFF] rounded-[6px]"
          : `btnPosition flex justify-center items-center w-auto px-4 py-[8px] font-bold text-base leading-[22px] text-[#04142B] border border-[#04142B] bg-[#FAFCFF] rounded-[6px]`
      }
      onClick={() => {
        if (!loading) {
          onClick();
        }
      }}
      style={style ? style : null}
    >
      {loading ? (
        <svg
          className="animate-spin h-[15px] w-[15px] rounded-full border-2 border-white border-t-[#E5E6E7] border-r-[#E5E6E7] "
          viewBox="0 0 24 24"
        />
      ) : (
        <div className="flex justify-center items-center gap-x-[11px]">
          <p>{context}</p>
        </div>
      )}
    </button>
  );
}

// 대상자 목록보기 버튼(장루 상태평가 페이지)
function BtnWhiteList({ to, context, style }) {
  const list_btn = "/images/admin/list_btn.svg";
  const navigate = useNavigate();

  return (
    <button
      className="btnPosition flex justify-center items-center w-auto px-4 py-[8px] font-bold text-base leading-[22px] text-[#04142B] border border-[#04142B] bg-[#FAFCFF] rounded-[6px]"
      onClick={() => {
        navigate(to);
      }}
      style={style ? style : null}
    >
      <div className="flex justify-center items-center gap-x-[10px]">
        <img src={list_btn} alt="list_btn" className="w-[20px] h-[12px]" />
        <p className="font-bold text-base leading-[22px] text-[#04142B]">
          {context}
        </p>
      </div>
    </button>
  );
}

// 공용 뒤로가기 버튼
function BtnWhiteBack({ to, style, state }) {
  const back_btn = "/images/admin/back_btn.svg";
  const navigate = useNavigate();
  return (
    <button
      className="btnPosition flex justify-center items-center w-auto px-4 py-[8px] font-bold text-base leading-[22px] text-[#04142B] border border-[#04142B] bg-[#FAFCFF] rounded-[6px]"
      onClick={() => {
        // navigate(to, { state: state ? state : undefined, replace: true });
        navigate(to, { state: state, replace: true });
      }}
      style={style ? style : { width: "128px", height: "40px" }}
    >
      <div className="flex justify-center items-center gap-x-[14px]">
        <img src={back_btn} alt="back_btn" className="w-[12px] h-[12px]" />
        <p className="font-bold text-base leading-[22px] text-[#04142B]">
          뒤로가기
        </p>
      </div>
    </button>
  );
}

export { BtnBlue, BtnBlack, BtnWhite, BtnWhiteList, BtnWhiteBack };
