import dayjs from "dayjs";
import React, { useState } from "react";
import { useMainContext } from "../../../../../contexts/Provider";
import common, { api } from "../../../../../data/common";
import { useNavigate } from "react-router-dom";
import NoPeriodModal from "./NoPeriodModal";
import NoReplyModal from "./NoReplyModal";

const AlamEachList = ({ el, index, user }) => {
  const rightimg = "/images/mo/right_unactive.svg";
  const navigate = useNavigate();
  const { loginInput, surveyInfo, canSurvey, noPeriod, noReply } =
    useMainContext();

  const [noReplyModal, setNoReplyModal] = useState(false);
  const [noPeriodModal, setNoPeriodModal] = useState(false);

  const onClickNavigate = (el) => {
    console.log(el);
    if (el.jangruSurvey !== null) {
      if (el.comment === "장루 점검 결과가 제공되었습니다. 확인해 보세요.") {
        api
          .get(common.baseURL + "alarm/id/" + el.id + "/" + 1)
          .then((res) => {
            if (res.data.ok === true) {
              navigate("/report/inspection", {
                state: dayjs(el.jangruSurvey.createdAt).format("YYYY-MM-DD"),
              });
            }
          })
          .catch((error) => console.log(error));
      }
      //  else {
      //   //기간지난경우 장루점검 alert 남은 흔적이 있을경우
      //   api
      //     .get(common.baseURL + "alarm/id/" + el.id + "/" + 1)
      //     .then((res) => {
      //       if (res.data.ok === true) {
      //         showSurveyDateModal();
      //       }
      //     })
      //     .catch((error) => console.log(error));
      // }
    }
    if (el.message !== null) {
      console.log(el.message);
      api
        .get(common.baseURL + "alarm/id/" + el.id + "/" + 1)
        .then((res) => {
          console.log(res);
          if (res.data.ok === true) {
            navigate("/main/eachmessage", { state: el.message });
          }
        })
        .catch((error) => console.log(error));
    }
    if (el.posting !== null) {
      api
        .get(common.baseURL + "alarm/id/" + el.id + "/" + 1)
        .then((res) => {
          if (res.data.ok === true) {
            console.log(el.posting.id);
            api
              .get(
                common.baseURL +
                  `community/posting/${el.posting.id}/${loginInput.id}`
              )

              .then((commures) => {
                if (commures.data.ok === true) {
                  navigate("/community/post", { state: el.posting.id });
                }
              })
              .catch((error) => console.log(error));
          }
        })
        .catch((error) => console.log(error));
    }
    if (el.question !== null) {
      console.log(el);

      api
        .get(common.baseURL + "alarm/id/" + el.id + "/" + 1)
        .then((res) => {
          if (res.data.ok === true) {
            navigate("/myinfo/appusecont", {
              state: el.question.id,
            });
          }
        })
        .catch((error) => console.log(error));
    }
    if (el.news !== null) {
      api
        .get(common.baseURL + "alarm/id/" + el.id + "/" + 1)
        .then((res) => {
          if (res.data.ok === true) {
            navigate("/myinfo/eachnews", {
              state: el.news,
            });
          }
        })
        .catch((error) => console.log(error));
    }

    if (el.category === 0 || el.category === 1) {
      //category 0은 오늘 장루 점검일입니다 알림
      //category 1은 장루점검일 지연 알림
      // 장루점검 알림 클릭시 메인홈에서 장루점검하기 버튼 클릭했을 때와 동일한 로직으로 수정
      if (noPeriod === true) {
        setNoPeriodModal(true);
      } else if (canSurvey === true) {
        navigate("/check");
      } else if (noReply === true) {
        setNoReplyModal(true);
      } else {
        navigate("/report/inspection", {
          state: surveyInfo.createdAt,
        });
      }
    }

    if (el.category === 2) {
      //category 2는 건강기록 알림
      api
        .get(common.baseURL + "alarm/id/" + el.id + "/" + 1)
        .then((res) => {
          if (res.data.ok === true) {
            navigate("/healthdiary");
          }
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <>
      {noPeriodModal ? (
        <NoPeriodModal
          showNOPeriodModal={() => setNoPeriodModal(!noPeriodModal)}
          sendperiodDay={surveyInfo.date}
          recentSurveyId={surveyInfo.recentSurveyId}
        />
      ) : null}
      {noReplyModal ? (
        <NoReplyModal showNOReplyModal={() => setNoReplyModal(!noReplyModal)} />
      ) : null}

      <div
        key={index}
        className="border-b-[1px] border-gray py-3 wordbreak-keepall"
        onClick={() => {
          onClickNavigate(el);
        }}
      >
        <div className="flex justify-between">
          <div>
            <span className="caption_1_400 text-[#999CA3] block">
              {dayjs(el.createdAt).format("YYYY.MM.DD")}
              &nbsp;
              {dayjs().format("YYYY.MM.DD") ===
                dayjs(el.createdAt).format("YYYY.MM.DD") &&
              dayjs(el.createdAt).format("A") === "AM" &&
              dayjs(el.createdAt).format("H") > 12
                ? `오전 ${dayjs(el.createdAt).format("H") - 12}시`
                : dayjs().format("YYYY.MM.DD") ===
                    dayjs(el.createdAt).format("YYYY.MM.DD") &&
                  dayjs(el.createdAt).format("A") === "AM" &&
                  dayjs(el.createdAt).format("H") <= 12
                ? `오전 ${dayjs(el.createdAt).format("H")}시`
                : dayjs().format("YYYY.MM.DD") ===
                    dayjs(el.createdAt).format("YYYY.MM.DD") &&
                  dayjs(el.createdAt).format("A") !== "AM" &&
                  dayjs(el.createdAt).format("H") > 12
                ? `오후 ${dayjs(el.createdAt).format("H") - 12}시`
                : dayjs().format("YYYY.MM.DD") ===
                    dayjs(el.createdAt).format("YYYY.MM.DD") &&
                  dayjs(el.createdAt).format("A") !== "AM" &&
                  dayjs(el.createdAt).format("H") <= 12
                ? `오후 ${dayjs(el.createdAt).format("H")}시`
                : ""}
            </span>

            <div className="pt-[4px] flex gap-x-[4px]">
              <span className="inline-block body_1_400">{el.comment}</span>
              {el.read === 0 ? (
                <span className="bg-[#F9D867] w-[20px] h-[20px] flex justify-center items-center inline-block rounded-full">
                  N
                </span>
              ) : null}
            </div>
          </div>
          <img src={rightimg} alt="righticon" />
        </div>
      </div>
    </>
  );
};

export default AlamEachList;
