import { BtnBlack } from "../../../admin_components/Buttons";
import DetailPageMemoTable from "./DetailPageMemoTable";
import commonUrl, { api } from "../../../../../data/common";
import { handlingError } from "../../../admin_components/Error";
import { useRef, useState } from "react";
import { useMainContext } from "../../../../../contexts/Provider";
import Popup from "reactjs-popup";

const Memo = ({ flexCol, patientMemos, state, setClient }) => {
  const [loading, setLoading] = useState(false);
  const memoRef = useRef(); // 메모 textarea focus 위해
  const { adminLogin } = useMainContext(); // 로그인 정보 Consumer
  const [memo, setMemo] = useState(""); // 메모 입력 내용
  // 메모 삭제 팝업/ 삭제되는 메모의 id
  const [memoCancelPopup, setMemoCancelPopup] = useState({
    memoCancelPopup: false,
    id: 0,
  });

  // 메모 저장하는 함수
  const saveMemo = () => {
    // 빈칸일 경우 저장 불가
    if (memo.trim() === "") {
      memoRef.current.focus();
      return;
    }
    async function fetchData() {
      setLoading(true);
      const response = await api({
        method: "post",
        url: commonUrl.patient + "memo",
        data: {
          content: memo,
          patient: state.id,
          admin: adminLogin.id,
        },
      }).catch((error) => {
        handlingError(error);
      });
      // console.log(response.data, ">>> memo 저장 후");
      if (response.data.ok) {
        setMemo("");
        const response = await api({
          method: "get",
          url: commonUrl.patient + "Id/" + state.id,
        }).catch((error) => {
          handlingError(error);
        });
        // console.log(
        //   response.data,
        //   ">>> Patient/id/id, memo 저장 후 데이터 다시 부르기"
        // );
        if (response.data.ok) {
          const p = response.data.patient;
          // 표에 적합한 형식으로 다듬어서 저장
          setClient({
            ...p,
            age: new Date().getFullYear() - p.birth.substring(0, 4) + 1,
            gender: p.gender === "M" ? "남" : "여",
            jangruClosedSurgeryOX:
              p.jangruClosedSurgeryOX === "O" ? "유" : "무",
            chemotherapyOX: p.chemotherapyOX === "O" ? "유" : "무",
            radiationtherapyOX: p.radiationtherapyOX === "O" ? "유" : "무",
            status: p.status === 0 ? "활성" : "비활성",
          });
          setLoading(false);
        }
      }
    }
    fetchData();
  };

  // 메모 삭제하는 함수
  const cancelMemo = () => {
    async function fetchData() {
      setLoading(true);
      const response = await api({
        method: "get",
        url: commonUrl.patient + "memo/delete/" + memoCancelPopup.id,
      });
      console.log(response.data);
      if (response.data.ok) {
        const response = await api({
          method: "get",
          url: commonUrl.patient + "Id/" + state.id,
        }).catch((error) => {
          handlingError(error);
        });
        console.log(response.data, ">>> Axios patient/Id/", `${state.id}`);
        if (response.data.ok && response.data.patient) {
          const p = response.data.patient;
          // 표에 적합한 형식으로 다듬어서 저장
          setClient({
            ...p,
            age: new Date().getFullYear() - p.birth.substring(0, 4) + 1,
            gender: p.gender === "M" ? "남" : "여",
            jangruClosedSurgeryOX:
              p.jangruClosedSurgeryOX === "O" ? "유" : "무",
            chemotherapyOX: p.chemotherapyOX === "O" ? "유" : "무",
            radiationtherapyOX: p.radiationtherapyOX === "O" ? "유" : "무",
            status: p.status === 0 ? "활성" : "비활성",
          });
          setLoading(false);
        }
      }
    }
    fetchData();
    setMemoCancelPopup({ ...memoCancelPopup, memoCancelPopup: false });
  };

  return (
    <>
      <style jsx="true">{`
        .popup-content {
          font-family: Pretendard !important;
          width: 303px;
          height: 170px;
          border: 1px solid #1b5edc;
          box-sizing: border-box;
          border-radius: 8px;
          padding: 16px;
        }
      `}</style>
      {/* 메모 삭제 팝업 */}
      <Popup
        open={memoCancelPopup.memoCancelPopup}
        closeOnDocumentClick
        onClose={() => {
          setMemoCancelPopup({ ...memoCancelPopup, memoCancelPopup: false });
        }}
      >
        <div className="flex flex-col justify-between w-full h-full">
          <div className="w-full h-full mt-4 text-center font-normal text-base leading-[22px] text-[#020A1B] ">
            삭제 하시겠습니까?
          </div>
          <div className="flex gap-x-2">
            <button
              className="flex justify-center items-center w-[131.5px] h-[46px] font-bold text-base leading-[22px] text-[#1B5EDC] border border-[#1B5EDC] rounded-[4px] focus:outline-none hover:bg-[#1B5EDC] hover:text-[#FAFCFF]"
              onClick={cancelMemo}
            >
              삭제
            </button>
            <button
              className="flex justify-center items-center w-[131.5px] h-[46px] font-bold text-base leading-[22px] text-[#1B5EDC] border border-[#1B5EDC] rounded-[4px] focus:outline-none hover:bg-[#1B5EDC] hover:text-[#FAFCFF]"
              onClick={() => {
                setMemoCancelPopup({
                  ...memoCancelPopup,
                  memoCancelPopup: false,
                });
              }}
            >
              취소
            </button>
          </div>
        </div>
      </Popup>
      <div
        className={
          flexCol
            ? "flex flex-col w-full min-w-[483px] p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)]"
            : "min-w-[390px] max-w-[414px] w-full flex flex-col p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)]"
        }
      >
        {/* 메모 입력란 */}
        <label className="text-lg font-extrabold leading-[22px] text-[#343B48] mb-[10px]">
          새 메모 추가
        </label>
        <div className="flex w-full items-end gap-x-1 min-h-[60px]">
          <textarea
            rows="1"
            ref={memoRef}
            className="min-h-[60px] w-full text-[#4E535F] box-border border border-[#04142B] rounded-[6px] placeholder:text-[#D5D6D8] placeholder:text-sm px-4 py-2 h-[40px] text-base font-normal leading-[22px] text-[#020A1B] focus:outline-none"
            name="memo"
            value={memo}
            onChange={(e) => {
              setMemo(e.target.value);
            }}
            autoFocus
            placeholder="메모를 입력하세요."
          />
          <BtnBlack
            onClick={saveMemo}
            loading={loading}
            context="저장하기"
            style={{ width: "88px", minWidth: "88px" }}
            standard="toast_standard"
          />
        </div>

        {/* 메모 리스트 */}
        {patientMemos.length === 0 ? (
          <>
            <label className="text-lg font-extrabold leading-[22px] text-[#4E535F] mt-[18px] ">
              메모
            </label>
            <label className="text-base font-normal leading-[20px] text-[#A0A3AB] mt-2 ">
              작성된 메모가 없습니다.
            </label>
          </>
        ) : (
          <>
            <label className="text-lg font-extrabold leading-[22px] text-[#4E535F] mt-[18px] ">
              메모
            </label>
            <DetailPageMemoTable
              columns={[
                {
                  accessor: "memo",
                  Header: "memo",
                  width: "100%",
                },
              ]}
              data={patientMemos}
              setMemoCancelPopup={setMemoCancelPopup}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Memo;
