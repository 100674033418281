import React, { useState } from "react";
import dayjs from "dayjs";
import common, { api } from "../../../../../data/common";
import { useMainContext } from "../../../../../contexts/Provider";

import { toast } from "react-toastify";
import CommentWarnModal from "./CommentWarnModal";
import CommentBlockModal from "./CommentBlockModal";
const CommentList = ({ comment, postingid, setCommentUsers, TOKEN }) => {
  const { loginInput } = useMainContext();
  const commentUserId = comment.patient ? comment.patient.id : comment.admin.id;

  let threedots = "/images/mo/threedots_nopad.svg";
  let defaultimage = "/images/mo/defaultimage.svg";
  const [dotmodal, setDotModal] = useState(false);
  const [warnBlock, setWarnBlock] = useState(false);
  const [warning, setWarning] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [submitwarn, setSubmitwarn] = useState(false);
  const trashcan = "/images/mo/trashcan.svg";

  const deleteComment = () => {
    console.log(comment.id);
    api
      .post(common.baseURL + "community/comment/delete/" + comment.id)
      .then((res) => {
        console.log(res);
        if (res.data.ok === true) {
          console.log(res);
          toast(`삭제되었습니다.`, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: "transparent toastbottom",
          });
          api

            .get(
              common.baseURL + `community/posting/${postingid}/${loginInput.id}`
            )
            .then((getres) => {
              if (getres.data.ok === true) {
                setCommentUsers(getres.data.commentList);
                setDotModal(false);
              }
            });
        } else {
          alert("댓글 삭제에 오류가있습니다.");
        }
      })
      .catch((error) => console.log(error));
  };
  console.log(submitwarn, ">>?");

  return (
    <div className=" p-[16px] bg-[#FAFCFF] rounded-sm border-b relative">
      <div className="flex justify-between">
        <div className="flex items-center ">
          <span className="w-[24px] h-[24px] border inline-block rounded-full overflow-hidden">
            <img
              src={
                comment.admin !== null &&
                comment.patient === null &&
                comment.admin.profileURL !== null
                  ? common.networkImg +
                    encodeURIComponent(comment.admin.profileURL)
                  : comment.patient !== null &&
                    comment.admin === null &&
                    comment.patient.profileURL !== null
                  ? common.networkImg +
                    encodeURIComponent(comment.patient.profileURL)
                  : defaultimage
              }
              className="w-full h-full"
              alt="commnuitypostcomment"
            />
          </span>
          <div
            className={
              comment.admin === null &&
              (comment.patient.id === loginInput.id ||
                (comment.patient.fcmToken === TOKEN &&
                  comment.patient.fcmToken !== null))
                ? "pl-3 text-[#3F82ED] caption_1_600"
                : "pl-3 caption_1_600"
            }
          >
            {comment.admin === null
              ? comment.patient.nickName
              : comment.patient === null
              ? comment.admin.name
              : ""}
          </div>
        </div>
        {comment.admin ? (
          ""
        ) : comment.admin === null &&
          (comment.patient.id === loginInput.id ||
            (comment.patient.fcmToken === TOKEN &&
              comment.patient.fcmToken !== null)) ? (
          <img
            src={threedots}
            alt="threedots"
            className="cursor-pointer "
            onClick={(e) => {
              let isClicked = false;
              if (e.target) {
                isClicked = true;
                setDotModal(!dotmodal);
              }
            }}
          />
        ) : (
          <img
            src={threedots}
            alt="threedots"
            className="cursor-pointer "
            onClick={(e) => {
              let isClicked = false;
              if (e.target) {
                isClicked = true;
                setWarnBlock(!warnBlock);
              }
            }}
          />
        )}
      </div>
      {dotmodal && (
        <>
          <div
            className="absolute top-[36px] right-[16px] z-2 bg-[#FAFCFF] p-[17px] cursor-pointer rounded-lg drop-shadow-md flex gap-1"
            onClick={deleteComment}
          >
            <span>댓글삭제</span>
            <img src={trashcan} alt="trashcan" />
          </div>
          <div
            className="fixed top-0 left-0 w-full h-full bg-transparent z-1"
            onClick={() => {
              setDotModal(false);
            }}
          ></div>
        </>
      )}
      {warnBlock && (
        <>
          <div className="absolute top-[36px] right-[16px] z-2 bg-[#FAFCFF] rounded-lg drop-shadow-md">
            <div
              className="p-[17px] cursor-pointer flex border-b"
              onClick={() => {
                setWarning(true);
              }}
            >
              <span>신고하기</span>
            </div>
            <div
              className="p-[17px] cursor-pointer flex "
              onClick={() => {
                setBlocking(true);
              }}
            >
              <span>차단하기</span>
            </div>
          </div>

          <div
            className="fixed top-0 left-0 w-full h-full bg-transparent z-1"
            onClick={() => {
              setWarnBlock(false);
            }}
          ></div>
        </>
      )}
      {warning && (
        <CommentWarnModal
          setWarning={setWarning}
          setBlocking={setBlocking}
          setWarnBlock={setWarnBlock}
          commentid={comment.id}
          postingid={postingid}
          setSubmitwarn={setSubmitwarn}
          setCommentUsers={setCommentUsers}
        />
      )}
      {blocking && (
        <CommentBlockModal
          setBlocking={setBlocking}
          setWarning={setWarning}
          setWarnBlock={setWarnBlock}
          commentUserId={commentUserId}
        />
      )}
      <div className="pt-[16px]">{comment.contents}</div>
      <div className="caption_2 text-[#676C76] pt-[4px]">
        {dayjs(comment.createdAt).format("YYYY.MM.DD")}{" "}
        {dayjs(comment.createdAt).format("HH:mm")}
      </div>
    </div>
  );
};

export default CommentList;
