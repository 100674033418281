import React, { useEffect, useState } from "react";
import { useMainContext } from "../../../../../contexts/Provider";

import common, { api } from "../../../../../data/common";
import dayjs from "dayjs";
import InspectionMonthList from "./InspectionMonthList";
import ReportGraph from "./ReportGraph";
import DiaryMonthList from "./DiaryMonthList";

const DateList = ({
  cansurvey,
  inspection,
  diary,
  graph,
  thisyear,
  setThisYear,
  inspectJan,
  inspectFeb,
  inspectMar,
  inspectApr,
  inspectMay,
  inspectJun,
  inspectJul,
  inspectAug,
  inspectNov,
  inspectOct,
  inspectSep,
  inspectDec,
  setInspectJan,
  setInspectFeb,
  setInspectMar,
  setInspectApr,
  setInspectMay,
  setInspectJun,
  setInspectJul,
  setInspectAug,
  setInspectNov,
  setInspectDec,
  setInspectOct,
  setInspectSep,

  diaryJan,
  diaryFeb,
  diaryMar,
  diaryApr,
  diaryMay,
  diaryJun,
  diaryJul,
  diaryAug,
  diaryNov,
  diaryOct,
  diarySep,
  diaryDec,
  setDiaryJan,
  setDiaryFeb,
  setDiaryMar,
  setDiaryApr,
  setDiaryMay,
  setDiaryJun,
  setDiaryJul,
  setDiaryAug,
  setDiaryNov,
  setDiaryOct,
  setDiarySep,
  setDiaryDec,
}) => {
  const { loginInput } = useMainContext();
  const [diarydata, setDiaryData] = useState([]);

  useEffect(() => {
    let pageOpen = false;
    const getJangruSurvey = () => {
      api
        .get(common.baseURL + "jangruSurvey/list/" + loginInput.id)
        .then((res) => {
          console.log(res);
          if (res.data.ok === true) {
            let Janu = res.data.Jan.filter(
              (el) => el.slice(0, 4) === thisyear.year
            );
            let Febu = res.data.Feb.filter(
              (el) => el.slice(0, 4) === thisyear.year
            );
            let Marc = res.data.Mar.filter(
              (el) => el.slice(0, 4) === thisyear.year
            );
            let Apri = res.data.Apr.filter(
              (el) => el.slice(0, 4) === thisyear.year
            );
            let May = res.data.May.filter(
              (el) => el.slice(0, 4) === thisyear.year
            );
            let June = res.data.Jun.filter(
              (el) => el.slice(0, 4) === thisyear.year
            );
            let July = res.data.Jul.filter(
              (el) => el.slice(0, 4) === thisyear.year
            );
            let Augu = res.data.Aug.filter(
              (el) => el.slice(0, 4) === thisyear.year
            );
            let Octo = res.data.Oct.filter(
              (el) => el.slice(0, 4) === thisyear.year
            );
            let Sept = res.data.Sep.filter(
              (el) => el.slice(0, 4) === thisyear.year
            );
            let Nove = res.data.Nov.filter(
              (el) => el.slice(0, 4) === thisyear.year
            );
            let Dece = res.data.Dec.filter(
              (el) => el.slice(0, 4) === thisyear.year
            );
            setInspectJan(Janu);
            setInspectFeb(Febu);
            setInspectMar(Marc);
            setInspectApr(Apri);
            setInspectMay(May);
            setInspectJun(June);
            setInspectJul(July);
            setInspectAug(Augu);
            setInspectOct(Octo);
            setInspectSep(Sept);
            setInspectNov(Nove);
            setInspectDec(Dece);
          }
        })
        .catch((error) => console.log(error));
    };
    const getGraphData = () => {
      api
        .get(common.baseURL + "diary/pid/" + loginInput.id)
        .then((res) => {
          // console.log(res, " DiatyData");
          // console.log(loginInput.id, "loginInput.id");
          if (res.data.ok === true) {
            //report-diary
            setDiaryData(res.data.diaryList);
          }
        })
        .catch((error) => console.log(error));
    };
    const getDiary = () => {
      api
        .get(common.baseURL + "diary/list/" + loginInput.id)
        .then((res) => {
          console.log(res);
          if (res.data.ok === true) {
            //diarylist
            console.log(res, ">>??");
            let Janu = res.data.Jan.filter(
              (el) => el.slice(0, 4) === dayjs().format("YYYY")
            );
            let Febu = res.data.Feb.filter(
              (el) => el.slice(0, 4) === dayjs().format("YYYY")
            );
            let Marc = res.data.Mar.filter(
              (el) => el.slice(0, 4) === dayjs().format("YYYY")
            );
            let Apri = res.data.Apr.filter(
              (el) => el.slice(0, 4) === dayjs().format("YYYY")
            );
            let May = res.data.May.filter(
              (el) => el.slice(0, 4) === dayjs().format("YYYY")
            );
            let June = res.data.Jun.filter(
              (el) => el.slice(0, 4) === dayjs().format("YYYY")
            );
            let July = res.data.Jul.filter(
              (el) => el.slice(0, 4) === dayjs().format("YYYY")
            );
            let Augu = res.data.Aug.filter(
              (el) => el.slice(0, 4) === dayjs().format("YYYY")
            );
            let Octo = res.data.Oct.filter(
              (el) => el.slice(0, 4) === dayjs().format("YYYY")
            );
            let Sept = res.data.Sep.filter(
              (el) => el.slice(0, 4) === dayjs().format("YYYY")
            );
            let Nove = res.data.Nov.filter(
              (el) => el.slice(0, 4) === dayjs().format("YYYY")
            );
            let Dece = res.data.Dec.filter(
              (el) => el.slice(0, 4) === dayjs().format("YYYY")
            );
            setDiaryJan(Janu);
            setDiaryFeb(Febu);
            setDiaryMar(Marc);
            setDiaryApr(Apri);
            setDiaryMay(May);
            setDiaryJun(June);
            setDiaryJul(July);
            setDiaryAug(Augu);
            setDiaryOct(Octo);
            setDiarySep(Sept);
            setDiaryNov(Nove);
            setDiaryDec(Dece);
          } else {
            console.log(res);
          }
        })
        .catch((error) => console.log(error));
    };
    if (!pageOpen) {
      getJangruSurvey();
      getDiary();
      getGraphData();
    }
    return () => {
      pageOpen = true;
    };
  }, [
    loginInput.id,
    setDiaryJan,
    setDiaryFeb,
    setDiaryMar,
    setDiaryApr,
    setDiaryMay,
    setDiaryJun,
    setDiaryJul,
    setDiaryAug,
    setDiarySep,
    setDiaryOct,
    setDiaryNov,
    setDiaryDec,
    setInspectJan,
    setInspectFeb,
    setInspectMar,
    setInspectApr,
    setInspectMay,
    setInspectJun,
    setInspectJul,
    setInspectAug,
    setInspectSep,
    setInspectOct,
    setInspectNov,
    setInspectDec,
    thisyear,
  ]);
  console.log(thisyear.year);
  console.log(diarydata);

  return (
    <>
      <div className="pt-[24px] border-t-2">
        {inspection ? (
          <InspectionMonthList
            cansurvey={cansurvey}
            inspection={inspection}
            inspectJan={inspectJan}
            inspectFeb={inspectFeb}
            inspectMar={inspectMar}
            inspectApr={inspectApr}
            inspectMay={inspectMay}
            inspectJun={inspectJun}
            inspectJul={inspectJul}
            inspectAug={inspectAug}
            inspectNov={inspectNov}
            inspectOct={inspectOct}
            inspectSep={inspectSep}
            inspectDec={inspectDec}
            setInspectJan={setInspectJan}
            setInspectFeb={setInspectFeb}
            setInspectMar={setInspectMar}
            setInspectApr={setInspectApr}
            setInspectMay={setInspectMay}
            setInspectJun={setInspectJun}
            setInspectJul={setInspectJul}
            setInspectAug={setInspectAug}
            setInspectNov={setInspectNov}
            setInspectOct={setInspectOct}
            setInspectSep={setInspectSep}
            setInspectDec={setInspectDec}
            setThisYear={setThisYear}
            thisyear={thisyear}
          />
        ) : diary ? (
          <DiaryMonthList
            diary={diary}
            diaryJan={diaryJan}
            diaryFeb={diaryFeb}
            diaryMar={diaryMar}
            diaryApr={diaryApr}
            diaryMay={diaryMay}
            diaryJun={diaryJun}
            diaryJul={diaryJul}
            diaryAug={diaryAug}
            diaryNov={diaryNov}
            diaryOct={diaryOct}
            diarySep={diarySep}
            diaryDec={diaryDec}
            setDiaryJan={setDiaryJan}
            setDiaryFeb={setDiaryFeb}
            setDiaryMar={setDiaryMar}
            setDiaryApr={setDiaryApr}
            setDiaryMay={setDiaryMay}
            setDiaryJun={setDiaryJun}
            setDiaryJul={setDiaryJul}
            setDiaryAug={setDiaryAug}
            setDiaryNov={setDiaryNov}
            setDiaryOct={setDiaryOct}
            setDiarySep={setDiarySep}
            setDiaryDec={setDiaryDec}
            setThisYear={setThisYear}
            thisyear={thisyear}
          />
        ) : graph ? (
          <ReportGraph diarydata={diarydata} />
        ) : (
          ""
        )}
        <div className="h-[64px]"></div>
      </div>
    </>
  );
};

export default DateList;
