import commonUrl, { api } from "../../../../../data/common";

const excelDown = ({ type, patientList, patientId, month }) => {
  // type
  // 0 : 대상자 일괄등록 서식, 1: 환자관리료 서식
  const Excel = require("exceljs");
  const workbook = new Excel.Workbook();
  const sheetOne = workbook.addWorksheet("Sheet1");
  console.log(patientList, patientId, month);
  // 컬럼 설정
  // header: 엑셀에 표기되는 이름
  // key: 컬럼을 접근하기 위한 key
  // hidden: 숨김 여부
  // width: 컬럼 넓이
  // style: 셀 스타일 설정

  sheetOne.columns =
    type === 0
      ? [
          {
            header: "주민등록번호",
            key: "patientRN",
            width: 25,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "환자성명",
            key: "patientName",
            width: 25,
            style: {
              alignment: { horizontal: "center" },
            },
          },
          {
            header: "수술일자",
            key: "jangruSurgeryDate",
            width: 21,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "장루종류",
            key: "jangruType",
            width: 21,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "등록일자",
            key: "registerDate",
            width: 21,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
        ]
      : [
          {
            header: "환자성명",
            key: "patientName",
            width: 24,
            style: {
              alignment: { horizontal: "center" },
            },
          },
          {
            header: "주민등록번호",
            key: "patientRN",
            width: 20,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "등록일자",
            key: "registerDate",
            width: 10,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "수가코드",
            key: "mdfeeCode",
            width: 10,
            style: {
              alignment: { horizontal: "center" },
            },
          },
          {
            header: "장루조성술시행일",
            key: "jangruSurgeryDate",
            width: 20,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "장루종류",
            key: "jangruType",
            width: 12,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "장루폐쇄(복원술)_시행유무",
            key: "jangruClosedSurgeryOX",
            width: 23,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "장루폐쇄(복원술)_시행일",
            key: "jangruClosedSurgeryDate",
            width: 23,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },

          {
            header: "1회차_관리자직종",
            key: "adminRole_1",
            width: 16,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_관리자성명",
            key: "adminName_1",
            width: 16,
            style: {
              alignment: { horizontal: "center" },
            },
          },
          {
            header: "1회차_관리자면허번호",
            key: "adminLicenseNum_1",
            width: 21,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_관리자전문과목코드",
            key: "adminLicenseCode_1",
            width: 25,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_관리자자격번호",
            key: "adminCertifiedNum_1",
            width: 21,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_관리일자",
            key: "checkDate_1",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
            },
          },
          {
            header: "1회차_관리방법",
            key: "checkMethod_1",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_관리방법_기타메신저",
            key: "checkMessengerEtc_1",
            width: 25,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_관리제공대상코드",
            key: "writerCode_1",
            width: 25,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_환자및보호자코드",
            key: "writerRelationCode_1",
            width: 20,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_체중",
            key: "weight_1",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_장루교환일",
            key: "jangruChangeDate_1",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_장루교환주기",
            key: "jangruChangeCycle_1",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_주요문제현황BITNO",
            key: "symptomCode_1",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_주요문제현황기타",
            key: "symptomEtc_1",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_장루합병증_유무",
            key: "jangruComplicationsOX_1",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_장루합병증BITNO",
            key: "jangruComplicationsCode_1",
            width: 25,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_장루합병증_기타",
            key: "jangruComplicationsEtc_1",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_피부합병증_유무",
            key: "skinComplicationsOX_1",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_피부합병증BITNO",
            key: "skinComplicationsCode_1",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_피부합병증_기타",
            key: "skinComplicationsEtc_1",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_서비스제공내용BITNO",
            key: "serviceCode_1",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_서비스제공내용_기타",
            key: "serviceEtc_1",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_관리내용이해정도",
            key: "understanding_1",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "1회차_기타기재사항",
            key: "etcText_1",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },

          {
            header: "2회차_관리자직종",
            key: "adminRole_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_관리자성명",
            key: "adminName_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_관리자면허번호",
            key: "adminLicenseNum_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_관리자전문과목코드",
            key: "adminLicenseCode_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_관리자자격번호",
            key: "adminCertifiedNum_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_관리일자",
            key: "checkDate_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_관리방법",
            key: "checkMethod_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_관리방법_기타메신저",
            key: "checkMessengerEtc_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_관리제공대상코드",
            key: "writerCode_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_환자및보호자코드",
            key: "writerRelationCode_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_체중",
            key: "weight_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_장루교환일",
            key: "jangruChangeDate_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_장루교환주기",
            key: "jangruChangeCycle_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_주요문제현황BITNO",
            key: "symptomCode_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_주요문제현황기타",
            key: "symptomEtc_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_장루합병증_유무",
            key: "jangruComplicationsOX_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_장루합병증BITNO",
            key: "jangruComplicationsCode_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_장루합병증_기타",
            key: "jangruComplicationsEtc_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_피부합병증_유무",
            key: "skinComplicationsOX_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_피부합병증BITNO",
            key: "skinComplicationsCode_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_피부합병증_기타",
            key: "skinComplicationsEtc_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_서비스제공내용BITNO",
            key: "serviceCode_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_서비스제공내용_기타",
            key: "serviceEtc_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_관리내용이해정도",
            key: "understanding_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
          {
            header: "2회차_기타기재사항",
            key: "etcText_2",
            width: 15,
            style: {
              alignment: { horizontal: "center" },
              numFmt: "@",
            },
          },
        ];
  // sheetOne.getColumnKey("adminRole_1").numFmt = "@";
  const fetchData = async () => {
    const response = await api({
      method: "get",
      url:
        commonUrl.patient +
        "patientSurveyCompleteExcel/" +
        `${month}/` +
        patientId,
    });
    console.log(response);
    return response.data.excel;
  };

  fetchData().then((res) => {
    const data =
      type === 0
        ? [{}, {}, {}, {}, {}, ...patientList]
        : [
            {},
            {},
            {},
            {},
            {},
            {},
            {
              patientName: "9번째 줄부터 작성",
            },
            res,
          ];

    data.map((item, idx) => {
      sheetOne.addRow(item);
    });
    console.log(data);

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      // 파일명
      anchor.download =
        type === 0 ? `대상자 일괄등록 서식` : `환자관리료 서식.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  });
};

export default excelDown;
