import React from "react";

const WelfareSalary = ({ bigTitle, btn3 }) => {
  return (
    <div className="w-full flex flex-col px-[16px] body_1_400 wordbreak-keepall">
      <div className="pt-[72px] flex justify-center flex-col items-center w-full border-b">
        <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
        <h1 className="h2_800 pt-[4px] pb-[24px]">{btn3}</h1>
      </div>
      <div className="w-full pt-[24px] body_1_400_jangrucont">
        <div className="border mb-[24px]">
          <div className="border-b bg-[#F2F6FC] p-[8px] subtitle_2 text-center">
            외래 진료시 주기적으로 의사의 처방에 따라 구입(사용)해야 하는
            치료재료 관련 행위 비용의 본인부담액 경감 적용기준
          </div>
          <div className="p-[16px]">
            <p>
              국민건강보험법시행령[별표2] 제1호나목 비고 4.의 규정에 의하여 외래
              진료 시 주기적으로 의사의 처방에 따라 구입 (사용)해야 하는
              치료재료는 해당 치료재료 총액의 100분의 20에 해당하는 금액을
              본인이 부담 하도록 되어 있으며, 해당 치료재료의 적용범위는 다음과
              같이 함.
            </p>
            <p className="py-[8px]">- 다 음 –</p>
            <p>가. 대상 </p>
            <div className="flex">
              <span className="inline-block">1.</span>
              <p className="pl-[4px]">
                장애인복지법시행령 별표1 제14호에 따른 장루‧요루 장애인
                ｢치료재료 급여·비급여 목록 및 급여 상한금액표｣ 에서
                L3(OSTOMY류)로 분류된 장루·요루용 주머니 (BAG), 피부
                보호부착판(FLANGE) 및 OSTOMY용 액세서리
              </p>
            </div>
            <div className="flex">
              <span className="inline-block">2.</span>
              <p>
                인공성대삽입술을 시행한 환자 - ｢치료재료 급여·비급여 목록 및
                급여 상한금액표｣의 인공성대 삽입술용 치료재료 (PROVOX VEGA)
              </p>
            </div>
            <div className="pt-[25px] flex">
              <span className="inline-block">나.</span>
              <div className="pl-[4px]">
                인정범위 각 대상별 세부 인정기준에 따름. (고시 제2015-110호,
                ’15.7.1. 시행)
              </div>
            </div>
          </div>
        </div>
        <div className="border mb-[24px]">
          <div className="border-b bg-[#F2F6FC] p-[8px] subtitle_2 text-center">
            장루(Colostomy)와 요루(Urostomy)용 피부판(Flange) 주머니(Bag)의
            인정기준
          </div>
          <div className="p-[16px]">
            <div className="flex">
              <span className="inline-block">1.</span>
              <p>
                피부판과 주머니(분리형 또는 일체형)는 입원기간 중에는
                실사용량으로 인정하고, 외래기간 중에는 일주일에 4개 까지
                인정하되, 다음과 같은 경우에는 1일 1개 까지 인정함. 다만, 상기
                인정개수를 초과하여 사용한 경우에는 사례별로 인정함.
              </p>
            </div>

            <p className="py-[8px]">- 다 음 –</p>
            <p>가. 장루, 요루 관련 피부 합병증</p>
            <p>나. 3세 미만 소아, 치매 환자</p>
            <p>다. 장루, 요루 수술 후 외래 진료 시 2개월간</p>

            <div className="pt-[25px] flex">
              <span className="inline-block">2.</span>
              <p className="pl-[4px]">
                자연적으로 형성된 루(fistula)를 통해 분변(뇨) 배출이 이루어지는
                환자의 경우 식약처 허가사항을 초과하여 Colostomy와 Urostomy 용
                Flange &amp; Bag을 사용한 경우 요양급여를 인정하되, 인정개수는
                상기 1과 같이 함. (고시 제2014-66호, ’14.5.10. 시행)
              </p>
            </div>
          </div>
        </div>
        <div className="border ">
          <div className="border-b bg-[#F2F6FC] p-[8px] text-center">
            Ostomy용 액세서리 급여 인정기준
          </div>
          <div className="p-[16px]">
            <div className="flex">
              <span className="inline-block">1)</span>
              <p className="pl-[4px] ">
                입원 시 피부보호용 액세서리(Powder, Paste, 피부 보호판)는 실사
                용량으로 인정하고, 복대는 장루 및 요루 수술 후 탈장 등의 관리를
                위해 사용하는 점을 감안하여 수술 후 1개 인정함.
              </p>
            </div>
            <p className="pt-[25px]">2) 외래</p>
            <div className="flex">
              <span className="inline-block">1.</span>
              <p className="pl-[4px]">
                피부보호용 액세서리는 다음과 같이 요양 급여를 인정하고,
                인정개수를 초과하여 사용한 경우에는 사례별로 인정함.
              </p>
            </div>
            <div className="pt-[8px] flex">
              <span className="inline-block">①</span>
              <p className="inline-block pl-[4px]">
                Powder - 상처보호 및 피부자극을 감소하기 위해 사용하는 제품으로
                연간 60g 이내 인정.
              </p>
            </div>
            <div className="flex">
              <span className="inline-block">②</span>
              <p className="inline-block pl-[4px]">
                Paste와 피부보호판 - 피부판(Flange)과 루(Ostomy) 틈새를 메워서
                피부자극을 최소화하는 제품으로 Paste는 60g/월 이내 인정.
              </p>
            </div>
            <div className="pt-[25px] flex">
              <span className="inline-block">-</span>
              <p className="inline-block pl-[4px]">
                피부보호판은 대 1개/주, 소 2개/주, 막대형 2개/주 중 한가지를
                인정.
              </p>
            </div>
            <div className="flex">
              <span className="inline-block">-</span>
              <p className="pl-[4px]">
                Paste와 피부보호판은 기능이 동일하고 제형만 다른 점을 감안하여
                동시 사용은 인정하지 아니함을 원칙으로 함. 다만 피부관련 합병증
                등 발생시 사례별로 동시 사용을 인정함.
              </p>
            </div>
            <div className="pt-[8px] flex">
              <span className="inline-block">2.</span>
              <p className="pl-[4px]">
                Stoma Cap - 장세척 후 잔여 배설물을 받아내는 재료로 1일 1개
                인정함 - 장루 주머니(Bag) 대용으로 사용하는 점을 감안하여 장루
                주머니 와 동시 사용은 인정하지 아니함.
              </p>
            </div>
            <div className="flex">
              <span className="inline-block">3.</span>
              <p className="pl-[4px]">
                Leg Bag - 소변배출 목적의 인공루(신장루, 방광루, 요도루 등)를
                가진 환자 중 활동이 가능한 환자가 장시간 외출 시 소변을 모으는
                목적으로 사용하는 경우에 요양 급여를 인정함.
              </p>
            </div>
            <div className="flex">
              <span className="inline-block">4.</span>
              <p className="inline-block pl-[4px]">
                복대 - 탈장 또는 루(Ostomy)의 탈출 등이 발생한 경우 사례별로
                인정함.
              </p>
            </div>
            <div className="flex">
              <span className="inline-block">5.</span>
              <p className="inline-block pl-[4px]">
                결장루 환자가 장 세척 시 사용하는 장세척기, 고정용 belt,
                Sleeve는 각각 사례별로 인정함.
              </p>
            </div>
            <div className="pt-[8px] flex">
              <span className="inline-block">3)</span>
              <p className="pl-[4px]">
                자연적으로 형성된 루(fistula)를 통해 분변(뇨) 배출이 이루어지는
                환자의 경우 식약처 허가사항을 초과하여 피부보호용 액세서리
                (Powder, Paste와 피부보호판)를 사용한 경우 요양급여를 인정하되,
                인정개수는 상기 1,2와 같이 함 (고시 제2015-43호, ’15.4.1. 시행)
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[80px]"></div>
    </div>
  );
};

export default WelfareSalary;
