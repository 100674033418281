import React, { useState } from "react";
import common, { api } from "../../../../../data/common";
import { useMainContext } from "../../../../../contexts/Provider";
import ImageModal from "../../../mobilecomponent/ImageModal";
const SelectedImages = ({ el, index, hasimageData, setHasImageData }) => {
  const closeicon = "/images/mo/check_close.svg";
  const {
    selectedImages,
    setSelectedImages,
    setFileArray,
    fileArray,
    files,
    setFiles,
  } = useMainContext();
  const [imageModal, setImageModal] = useState(false);
  const deleteImage = (el, index) => {
    console.log(index);
    //selectedImages, files, fileArray 가 클릭된 순간 전체 length가 줄어들며 index는 고정값이므로 length에 따른 index 변경이필요함
    selectedImages.splice(index, 1);
    files.splice(index, 1);
    fileArray.splice(index, 1);
    setSelectedImages([...selectedImages]);
    setFiles([...files]);
    setFileArray([...fileArray]);
  };
  const deleteHasImage = (el, index) => {
    hasimageData.splice(index, 1);
    setHasImageData([...hasimageData]);
  };

  const showImageModal = () => {
    setImageModal(!imageModal);
  };
  console.log(el, ">>.");
  console.log(hasimageData);
  return (
    <>
      <div
        key={index}
        className="inline-block w-[88px] h-[88px] border border-[#999CA3] relative overflow-hidden cursor-pointer"
      >
        <div
        // className="w-[300px]"
        >
          {hasimageData && hasimageData.length > 0 ? (
            <>
              <img
                src={common.networkImg + encodeURIComponent(el)}
                alt={el}
                key={el}
                className="object-cover"
                onClick={showImageModal}
              />

              <img
                src={closeicon}
                alt="closeicon"
                className="absolute z-1 top-1 right-1  w-[20px] h-[20px] cursor-pointer"
                onClick={(e) => {
                  if (e.target) {
                    deleteHasImage(el, index);
                  }
                }}
              />
            </>
          ) : (
            <>
              <img
                src={el}
                alt={el}
                key={el}
                className="object-cover"
                onClick={showImageModal}
              />

              <img
                src={closeicon}
                alt="closeicon"
                className="absolute z-1 top-1 right-1 w-[20px] h-[20px] cursor-pointer"
                onClick={(e) => {
                  if (e.target) {
                    deleteImage(el, index);
                  }
                }}
              />
            </>
          )}
        </div>
      </div>
      {imageModal ? (
        <ImageModal
          showImageModal={showImageModal}
          imageurl={el}
          hasimageData={hasimageData}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default SelectedImages;
