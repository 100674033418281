import Header2 from "../../../admin_components/Header/Header2";

import { useEffect, useRef, useState } from "react";
import { BtnBlack, BtnWhite } from "../../../admin_components/Buttons";
import commonUrl, { api } from "../../../../../data/common";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useMainContext } from "../../../../../contexts/Provider";
import {
  toastCommonProps,
  findToastPosition,
} from "../../../admin_components/Toast";
import autoInputPhone from "../../../admin_components/AutoInputPhone";
import dayjs from "dayjs";
import { handlingError } from "../../../admin_components/Error";

function Register() {
  const navigate = useNavigate();
  const { adminLogin } = useMainContext();
  const [toastElPosition, setToastElPosition] = useState({ top: 0, left: 0 }); // toast창 위치 값
  const [loading, setLoading] = useState(false);
  // const [professorList, setProfessorList] = useState([]);
  const disease = [
    "고혈압",
    "폐암",
    "간암",
    "위암",
    "간경변증",
    "천식",
    "만성신장질환",
    "당뇨병",
    "빈혈",
    "관절염",
  ];
  // 대상자 등록 정보 // todo
  const [client, setClient] = useState({
    name: "", // 이름
    patientID: "", // 등록 번호
    birth: "", // 생일
    regNum: "", // 주민번호
    phone: "", // 휴대폰 번호
    gender: "M", // 성별
    // height: "", // 키
    // weight: "", // 현재 체중
    // bmi: 0, // BMI
    jangruType: "선택", // 장루 종류
    jangruSurgeryDate: "", // 장루 수술일
    // professor: "선택", // 담당교수
    jangruClosedSurgeryOX: "O", // 장루 폐쇄술 시행 여부
    jangruClosedSurgeryDate: "", // 장루 폐쇄술 시행일
    patientPwd: "", // 임시 비밀번호
    chemotherapyOX: "O", // 항암치료 유무
    radiationtherapyOX: "O", // 방사선 치료 유무
    diseaseOX: "X", // 기저 질환 유무(백엔드에는 따로 전송하지 않음, 무의 경우 disease에 빈배열보냄)
    disease: [], // 기저 질환
    diseaseInputChecked: false,
    surgery: "선택", // 수술명
    startDate: "",
    firstSurvey: "선택",
    secondSurvey: "선택",
  });
  const [secondSurveyArr, setSecondSurveyArr] = useState([]);

  const phoneRef = useRef(); // 휴대폰 번호 입력칸 ref
  const firstRegRef = useRef("");
  const secondRegRef = useRef("");
  const [diseaseInput, setDiseaseInput] = useState("");
  console.log(client.disease);
  // input 값 변경 // todo
  const change = (e) => {
    const { name, value } = e.target;
    const onlyNum = /^[0-9]*$/;

    console.log(name, "name");
    console.log(value, "value");
    // 주민번호 자동 포커스이동
    if (name === "regNum" && value.length === 6) {
      secondRegRef.current.focus();
    }

    // 휴대폰 번호 자동입력
    if (name === "phone") {
      autoInputPhone(client.phone, "phone", value, setClient, client, phoneRef);
    }
    // 주민번호 저장시 첫째 폼 둘째 폼 합친값 저장
    else if (name === "regNum") {
      // 숫자입력일 경우에만 저장
      if (onlyNum.test(value)) {
        setClient({
          ...client,
          regNum: firstRegRef.current.value + secondRegRef.current.value,
        });
      }
    }
    // 장루 정기 점검일
    else if (name === "firstSurvey") {
      const valueIsNum = Number(value);
      if (valueIsNum < dayjs().get("date")) {
        toast(
          <p>첫 점검일이 다음달 {valueIsNum}일로 설정됩니다.</p>,
          toastCommonProps(2500, "top-left", "toast_common toast__normal")
        );
      }
      setClient({ ...client, firstSurvey: valueIsNum });

      // 첫 날짜 기준 4일 뒤 부터 28일까지
      const newSecondOptions = Array.from(
        { length: 28 - (valueIsNum + 4) + 1 },
        (_, i) => i + valueIsNum + 4
      );
      setSecondSurveyArr(newSecondOptions);

      // 첫 날짜 4일 뒤 날짜 배열에 둘째 날짜가 포함 안될경우 바뀐 배열 첫 날짜로 지정
      // if (
      //   valueIsNum + 4 >= client.secondSurvey ||
      //   client.secondSurvey === "선택"
      // ) {
      //   setClient((prevClient) => ({
      //     ...prevClient,
      //     secondSurvey: newSecondOptions[0],
      //   }));
      // }
    } else if (name === "secondSurvey") {
      setClient({ ...client, secondSurvey: Number(value) });
    } else if (name === "disease") {
      const isChecked = e.target.checked;
      const value = e.target.value;
      if (isChecked) {
        setClient({ ...client, disease: [...client.disease, value] });
      } else {
        setClient({
          ...client,
          disease: client.disease.filter((origin) => origin !== value),
        });
      }
    }

    // 일반적인 경우
    else {
      setClient({ ...client, [name]: value });
    }
  };
  // console.log(client, "client");

  // savePassWord
  useEffect(() => {
    if (client.phone.length === 13) {
      const phoneParts = client.phone.split("-");
      const lastPhoneParts = phoneParts[phoneParts.length - 1];

      setClient({ ...client, patientPwd: lastPhoneParts });
    }
  }, [client.phone]);

  // saveBirthAndGender
  useEffect(() => {
    if (
      firstRegRef.current.value.length + secondRegRef.current.value.length ===
      13
    ) {
      saveBirthAndGender(
        firstRegRef.current.value + secondRegRef.current.value
      );
    }
  }, [client.regNum]);

  // 주민등록번호 유효성 검사
  const regNumCheck = () => {
    // 현재 날짜 정보
    const now = new Date();
    const currentYear = now.getFullYear();

    // 입력된 주민번호 앞자리에서 년, 월, 일 추출
    const yearPrefix = secondRegRef.current.value.charAt(0) <= 2 ? 1900 : 2000;
    const year =
      yearPrefix + parseInt(firstRegRef.current.value.substring(0, 2));
    const month = parseInt(firstRegRef.current.value.substring(2, 4));
    const day = parseInt(firstRegRef.current.value.substring(4, 6));
    const lastDayOfMonth = new Date(year, month, 0).getDate(); // 해당 월의 마지막 날

    // 유효성 검사
    // 1. 년도 검사: 미래 년도는 유효하지 않음
    // 2. 월 검사: 1월 ~ 12월 사이
    if (year > currentYear || month < 1 || month > 12) {
      return 1;
    }

    // 3. 일 검사: 1일 ~ 해당 월의 마지막 날 사이
    else if (day < 1 || day > lastDayOfMonth) {
      return 1;
    }

    // 4. 유효 체크
    const regCheckArr = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5];
    let regCheck = (
      firstRegRef.current.value + secondRegRef.current.value
    ).split("");
    let sum = 0;

    for (let i = 0; i < 12; i++) {
      sum += regCheck[i] * regCheckArr[i];
    }
    sum = (11 - (sum % 11)) % 10;

    if (String(sum) !== regCheck[12]) {
      return 2;
    } else {
      return true;
    }
  };

  // 제출 전에 제출 형식에 맞는 지 확인 // todo
  const checkForSave = () => {
    const {
      name,
      patientID,
      // birth,
      regNum,
      // height,
      // weight,
      // surgery,
      jangruType,
      jangruSurgeryDate,
      jangruClosedSurgeryOX,
      jangruClosedSurgeryDate,
      patientPwd,
      startDate,
      phone,
    } = client;
    let msg = ""; // 형식 안맞을 때의 메세지

    // 핸드폰 번호 정규표현식
    const regPhone = /^01([0|1|6|7|8|9])-([0-9]{4})-([0-9]{4})$/;
    // 오직 문자열만 정규식
    const onlyString = /^[가-힣a-zA-Z]+$/;

    // 유효성 체크
    if (name.trim() === "") {
      msg = "이름을 입력해 주세요.";
    } // 이름 형식 체크
    else if (!onlyString.test(name)) {
      msg = "이름이 올바르지 않습니다.";
    }

    // 등록번호
    else if (patientID.trim() === "") {
      msg = "등록번호를 입력해 주세요.";
    }
    // 주민번호
    else if (regNum.trim() === "") {
      msg = "주민등록번호를 입력해 주세요.";
    }
    // 주민번호 형식 체크 1
    else if (regNumCheck() === 1) {
      msg = "주민등록번호가 잘못되었습니다.";
    }
    // 주민번호 형식 체크 2
    else if (regNumCheck() === 2) {
      msg = "유효하지 않은 주민등록번호입니다.";
    }
    // 휴대폰 번호 형식 체크
    else if (!regPhone.test(phone)) {
      msg = "휴대폰 번호 형식이 알맞지 않습니다.";
    }
    // 비밀번호
    else if (patientPwd.trim() === "") {
      msg = "비밀번호를 입력해 주세요.";
    }

    // 등록일자 체크
    else if (startDate === "") {
      msg = "등록일자를 입력해 주세요.";
    }
    // 장루 정기 점검일
    else if (client.firstSurvey === "선택") {
      msg = "장루 정기 점검일을 선택해 주세요.";
    } else if (client.secondSurvey === "선택") {
      msg = "장루 정기 점검일을 선택해 주세요.";
    }
    // 장루 종류
    else if (jangruType.trim() === "선택") {
      msg = "장루 종류를 입력해 주세요.";
    } // 장루 수술일, 장루 폐쇄술 시행여부, 장루 폐쇄술 시행일
    else if (jangruSurgeryDate === "") {
      msg = "장루 수술일을 입력해 주세요.";
    } else if (
      jangruClosedSurgeryOX === "O" &&
      jangruClosedSurgeryDate === ""
    ) {
      msg = "장루 폐쇄술 시행일을 입력해 주세요.";
    }

    if (msg === "") {
      // 수술명
      // else if (surgery.trim() === "") {
      //   msg = "수술명을 입력해 주세요.";
      // }

      // 모두 통과
      return true;
    }
    // 형식 오류 있을 때
    else {
      toast(
        <p>{msg}</p>,
        toastCommonProps(1500, "top-left", "toast_common toast__normal")
      );
      return false;
    }
  };

  // 주민등록번호로 성별, 생일 저장하기
  const saveBirthAndGender = (reg) => {
    const yearPrefix = reg.charAt(6) <= "2" ? "19" : "20";
    const year = yearPrefix + reg.substring(0, 2);
    const month = reg.substring(2, 4);
    const day = reg.substring(4, 6);

    // 주민등록번호 7번째 자리에서 성별 추출
    const genderCode = reg.charAt(6);
    const genderSave = genderCode === "1" || genderCode === "3" ? "M" : "F";

    const birthDate = year + "-" + month + "-" + day;
    const gender = genderSave;
    setClient({ ...client, birth: birthDate, gender: gender });

    //  return {
    //    birthDate: `${year}-${month}-${day}`,
    //    gender: gender,
    //  };
  };

  // 저장하기 버튼 눌렀을 때
  const save = async () => {
    // toast창 위치 설정
    setToastElPosition({
      top: findToastPosition("top"),
      left: findToastPosition("left"),
    });
    if (checkForSave()) {
      // DB에 보낼 데이타 // todo

      let newData = {
        ...client,
        // bmi: newBMI,
        // height: Number(client.height),
        // weight: Number(client.weight),
        gender: client.gender,
        birth: client.birth,
        nickName: client.patientID,
        regNum: client.regNum,
        jangruClosedSurgeryDate:
          client.jangruClosedSurgeryOX === "X"
            ? null
            : client.jangruClosedSurgeryDate,
        admin: adminLogin.id,
        status: 0,
        pushOX: 0,
        tutorial: 0,
        writable: true,
        belong: adminLogin.belong,
        agree: false,
        disease: client.diseaseInputChecked
          ? [...client.disease, diseaseInput]
          : client.disease,
        firstSurvey: client.firstSurvey,
        secondSurvey: client.secondSurvey,
      };
      // console.log(newData, ">>> newData");

      // Axios // todo
      async function fetchData() {
        setLoading(true);
        console.log(newData);
        const res = await api({
          method: "post",
          url: commonUrl.patient,
          data: newData,
        }).catch((error) => {
          handlingError(error, "top-left", "toast_common toast__normal");
        });
        console.log(res.data, ">>> Axios patient(저장)");
        // axios 성공 시
        if (res.data.ok) {
          toast(
            <p>저장되었습니다.</p>,
            toastCommonProps(1500, "top-left", "toast_common toast__normal")
          );
          setTimeout(() => {
            setLoading(false);
            navigate("/admin/main/client/modify", {
              state: { id: res.data.messageNum, replace: true },
            });
          }, 2000);
        }
        // axios 실패 시
        else {
          setLoading(false);
          toast(
            <p>{res.data.error}</p>,
            toastCommonProps(1500, "top-left", "toast_common toast__normal")
          );
        }
      }
      fetchData();
    }
  };

  // 토스트창 위치 설정
  useEffect(() => {
    setToastElPosition({
      top: findToastPosition("top"),
      left: findToastPosition("left"),
    });
  }, []);

  useEffect(() => {
    //todo
    api
      .get(commonUrl.admin + "all/" + adminLogin.belong)
      .catch((error) => {
        handlingError(error);
      })
      .then((res) => {
        if (res.data.ok) {
          console.log(
            res.data,
            "get(commonUrl.admin + all/ + adminLogin.belong)"
          );
          // setProfessorList(
          //   res.data.adminList.filter((list) => list.role === 1)
          // );
        }
      });
  }, []);
  return (
    <>
      <Header2
        title={[
          { title: "대상자 등록/관리", url: -1 },
          { title: "대상자 등록", url: 0 },
        ]}
      >
        <ToastContainer />
        <style jsx="true">{`
          .Toastify__toast {
            top: ${toastElPosition.top - 20}px;
            left: ${toastElPosition.left - 295}px;
          }
        `}</style>
        <div className="admin flex flex-col grow p-[32px]">
          <div className="h-[80px] min-h-[80px]"></div>
          <label className="text-2xl font-extrabold leading-8 text-[#020A1B] mb-3">
            대상자 등록
          </label>
          <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] min-w-[996px]">
            {/* 표 */}
            <div className="grid grid-cols-[16%_16%_19%_16%_17%_16%] text-center">
              {/* 이름 */}
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border ">
                *이름
              </label>
              <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border text-[#020A1B]">
                <input
                  type="text"
                  className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] focus:outline-none"
                  name="name"
                  value={client.name}
                  onChange={change}
                />
              </div>
              {/* 등록 번호 */}
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                *등록번호
              </label>
              <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                <input
                  type="text"
                  className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none text-[#020A1B] "
                  name="patientID"
                  onChange={change}
                  placeholder="등록번호가 환자의 아이디가 됩니다."
                />
              </div>
              {/* 주민등록 번호 */}
              <label className="flex justify-start items-center px-4  py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border">
                *주민등록번호
              </label>
              <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border text-[#020A1B]">
                <input
                  ref={firstRegRef}
                  type="text"
                  className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none"
                  name="regNum"
                  maxLength="6"
                  // value={client.regNum.slice(0, 6)}
                  value={firstRegRef.current.value}
                  placeholder="앞 6자리"
                  onChange={(e) => {
                    change(e);
                  }}
                />
                <span className="flex items-center gap-2">-</span>
                <input
                  ref={secondRegRef}
                  type="text"
                  className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none"
                  name="regNum"
                  value={secondRegRef.current.value}
                  placeholder="뒷 7자리"
                  maxLength="7"
                  onChange={(e) => {
                    change(e);
                  }}
                />
              </div>

              {/* 휴대폰 번호 */}
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                *휴대폰 번호
              </label>
              <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border text-[#020A1B]">
                <input
                  type="text"
                  className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none"
                  name="phone"
                  placeholder="010-1234-5678"
                  onChange={change}
                  ref={phoneRef}
                />
              </div>

              {/* 임시 비밀번호 */}
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                *임시 비밀번호
              </label>
              <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border text-[#020A1B]">
                <input
                  type="text"
                  className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none"
                  name="patientPwd"
                  value={client.patientPwd}
                  onChange={change}
                />
              </div>
            </div>

            <div className="grid grid-cols-[16%_16%_19%_16%_17%_16%] text-center mt-3">
              {/* 등록일자 */}
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                *등록일자
              </label>
              <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border text-[#020A1B]">
                <input
                  type="date"
                  max={dayjs(new Date()).format("YYYY-MM-DD")}
                  className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none"
                  name="startDate"
                  onChange={change}
                />
              </div>

              {/* 장루 정기 점검일 */}
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                *장루 정기 점검일
              </label>
              <div className="flex col-span-1 p-[3px] w-full gap-1 border border-[#E5E6E7] font-normal box-border text-[#020A1B]">
                <p className="flex w-[4rem] font-normal justify-center items-center">
                  매 월
                </p>
                <select
                  className="w-[7rem] px-[5px] py-[3px] border border-[#E5E6E7] vertical-middle focus:outline-none "
                  name="firstSurvey"
                  value={client.firstSurvey}
                  onChange={change}
                >
                  <option value="선택">선택</option>
                  {Array.from({ length: 24 }, (_, i) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1}일
                    </option>
                  ))}
                </select>
                <select
                  className="w-[7rem] px-[5px] py-[3px] border border-[#E5E6E7] vertical-middle focus:outline-none "
                  name="secondSurvey"
                  onChange={change}
                  value={client.secondSurvey}
                >
                  <option value="선택">선택</option>
                  {secondSurveyArr.map((day) => (
                    <option key={day} value={day}>
                      {day}일
                    </option>
                  ))}
                </select>
              </div>

              {/* 수술명 */}
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                수술명
              </label>
              <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border text-[#020A1B]">
                <select
                  className="w-full mx-[1px] px-[5px] py-[3px] border border-[#E5E6E7] vertical-middle focus:outline-none "
                  name="surgery"
                  value={client.surgery}
                  onChange={change}
                >
                  <option value="선택">선택</option>
                  <option value="전방절제술">전방절제술</option>
                  <option value="저위전방절제술">저위전방절제술</option>
                  <option value="초저위전방절제술">초저위전방절제술</option>
                  <option value="복회음절제술">복회음절제술</option>
                  <option value="하트만씨수술">하트만씨수술</option>
                  <option value="결장전절제술">결장전절제술</option>
                  <option value="회장낭-항문문합술">회장낭-항문문합술</option>
                  <option value="우측결장반절제술">우측결장반절제술</option>
                  <option value="장루조성술">장루조성술</option>
                  <option value="소장절제술">소장절제술</option>
                  <option value="복강경탐색술">복강경탐색술</option>
                  <option value="장적출술">장적출술</option>
                  <option value="종양감축술">종양감축술</option>
                </select>
              </div>

              {/* 장루 종류 */}
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                *장루 종류
              </label>
              <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border text-[#020A1B]">
                <select
                  className="w-full mx-[1px] px-[5px] py-[3px] border border-[#E5E6E7] focus:outline-none"
                  name="jangruType"
                  value={client.jangruType}
                  onChange={change}
                >
                  <option value="선택">선택</option>
                  <option value="공장루">공장루</option>
                  <option value="하행/구불결장루">하행/구불결장루</option>
                  <option value="횡행결장루">횡행결장루</option>
                  <option value="상행결장루">상행결장루</option>
                  <option value="회장루">회장루</option>
                </select>
              </div>

              {/* 장루 수술일 */}
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                *장루 수술일
              </label>
              <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border text-[#020A1B]">
                <input
                  type="date"
                  max={dayjs(new Date()).format("YYYY-MM-DD")}
                  className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none"
                  name="jangruSurgeryDate"
                  onChange={change}
                />
              </div>

              {/* 장루 폐쇄술 시행여부 */}
              <label className="flex justify-start items-center text-left px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border ">
                *장루 폐쇄술 시행여부
              </label>
              <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                <div className="flex items-center justify-evenly p-[4px] w-full font-normal box-border text-[#020A1B]">
                  <label>
                    <input
                      type="radio"
                      name="jangruClosedSurgeryOX"
                      value="O"
                      onClick={change}
                      className="mr-[5px]"
                      defaultChecked
                    />
                    유
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="jangruClosedSurgeryOX"
                      value="X"
                      onClick={change}
                      className="mr-[5px]"
                    />
                    무
                  </label>
                </div>
              </div>

              {/* 장루 폐쇄술 시행일 */}
              <label className="flex justify-start items-center text-left px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                *장루 폐쇄술 시행일
              </label>
              <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border text-[#020A1B]">
                <input
                  type="date"
                  max="2100-01-01"
                  className="w-full mx-[1px] px-[7px] py-[3px] vertical-middle border border-[#E5E6E7] placeholder:text-[#ccc] placeholder:text-sm focus:outline-none"
                  name="jangruClosedSurgeryDate"
                  onChange={change}
                  disabled={client.jangruClosedSurgeryOX === "X"}
                  style={{
                    backgroundColor:
                      client.jangruClosedSurgeryOX === "X"
                        ? "rgb(226 232 240)"
                        : "transparent",
                    color:
                      client.jangruClosedSurgeryOX === "X"
                        ? "transparent"
                        : "#666",
                  }}
                />
              </div>

              {/* 항암 치료 */}
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border text-[#4E535F] box-border ">
                *항암 치료
              </label>
              <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                <div className="flex items-center justify-evenly p-[4px] w-full font-normal box-border text-[#020A1B]">
                  <label>
                    <input
                      type="radio"
                      name="chemotherapyOX"
                      value="O"
                      onClick={change}
                      className="mr-[5px]"
                      defaultChecked
                    />
                    유
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="chemotherapyOX"
                      value="X"
                      onClick={change}
                      className="mr-[5px]"
                    />
                    무
                  </label>
                </div>
              </div>

              {/* 방사선 치료 */}
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border">
                *방사선 치료
              </label>
              <div className="flex p-[4px] w-full border border-[#E5E6E7] font-normal box-border">
                <div className="flex items-center justify-evenly p-[4px] w-full font-normal box-border text-[#020A1B]">
                  <label>
                    <input
                      type="radio"
                      name="radiationtherapyOX"
                      value="O"
                      onClick={change}
                      className="mr-[5px]"
                      defaultChecked
                    />
                    유
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="radiationtherapyOX"
                      value="X"
                      onClick={change}
                      className="mr-[5px]"
                    />
                    무
                  </label>
                </div>
              </div>

              {/* 기저질환 */}
              <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-normal text-base leading-[22px] text-[#4E535F] box-border ">
                기저질환
              </label>
              <div className="flex items-center justify-evenly p-[4px] w-full font-normal box-border text-[#020A1B] border border-[#E5E6E7]">
                <label>
                  <input
                    type="radio"
                    name="diseaseOX"
                    value="O"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setClient({ ...client, diseaseOX: "O" });
                      }
                    }}
                    className="mr-[5px]"
                  />
                  유
                </label>
                <label>
                  <input
                    type="radio"
                    name="diseaseOX"
                    value="X"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setClient({
                          ...client,
                          diseaseOX: "X",
                          disease: [],
                          diseaseInputChecked: false,
                        });
                        setDiseaseInput("");
                      }
                    }}
                    className="mr-[5px]"
                    defaultChecked
                  />
                  무
                </label>
              </div>
              <div className="col-span-3 p-[4px] px-2  w-full border border-[#E5E6E7] font-normal box-border text-[#020A1B]">
                <div className="flex flex-wrap items-center gap-3">
                  {disease.map((oneDisease, idx) => (
                    <div key={idx} className="flex items-center gap-1">
                      <input
                        className="h-6"
                        type="checkbox"
                        id={oneDisease}
                        name="disease"
                        disabled={client.diseaseOX === "X"}
                        onChange={change}
                        value={oneDisease}
                        checked={client.disease.includes(oneDisease)}
                      />
                      <label className="h-6 shrink-0" htmlFor={oneDisease}>
                        {oneDisease}
                      </label>
                    </div>
                  ))}
                </div>

                <div className="flex items-center gap-1">
                  <input
                    type="checkbox"
                    id="diseaseInputChecked"
                    name="diseaseInputChecked"
                    disabled={client.diseaseOX === "X"}
                    onClick={(e) => {
                      setClient({
                        ...client,
                        diseaseInputChecked: !client.diseaseInputChecked,
                      });
                      if (!e.target.checked) {
                        setDiseaseInput("");
                      }
                    }}
                    checked={client.diseaseInputChecked}
                  />
                  <label className="shrink-0" htmlFor={"diseaseInputChecked"}>
                    직접입력
                  </label>
                  <input
                    type="text"
                    className="border border-[#E5E6E7] px-[5px] w-full"
                    disabled={!client.diseaseInputChecked}
                    value={diseaseInput}
                    onChange={(e) => setDiseaseInput(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* 저장하기 버튼 */}
            <div className="flex justify-end mt-[20px]">
              <div className="flex justify-between gap-x-3">
                <BtnWhite
                  context="목록"
                  onClick={() => {
                    navigate(-1);
                  }}
                  style={{ width: "88px" }}
                  standard="toast_standard"
                />
                <BtnBlack
                  context="저장하기"
                  onClick={save}
                  loading={loading}
                  style={{ width: "88px" }}
                />
              </div>
            </div>
          </div>
          <div className="w-full h-[32px]"></div>
        </div>
      </Header2>
    </>
  );
}

export default Register;
