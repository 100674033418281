import React, { useState, useEffect } from "react";
import TextEditorToolbar, {
  modules,
  formats,
} from "../../../admin_components/TextEditorToolbar";
import ReactQuill from "react-quill";
import dayjs from "dayjs";
import { useMainContext } from "../../../../../contexts/Provider";

import common, { api } from "../../../../../data/common";
import { Flip, toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { BtnBlack } from "../../../admin_components/Buttons";
import { toastCommonProps } from "../../../admin_components/Toast";

let disable = [];

function getDatesStartToLast(startDate, lastDate) {
  var regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
  if (!(regex.test(startDate) && regex.test(lastDate)))
    return "Not Date Format";
  var result = [];
  var curDate = new Date(startDate);
  while (curDate <= new Date(lastDate)) {
    result.push(curDate.toISOString().split("T")[0]);
    curDate.setDate(curDate.getDate() + 1);
  }
  return result;
}
let num = 20;

const NewsAddComponent = () => {
  const navigate = useNavigate();
  const [loading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [disabledDates, setDisabledDates] = useState([]);

  const { adminLogin } = useMainContext(); // 로그인 정보 Consumer
  const [fileon, setFileon] = useState(false);
  const [setfile, setSetfile] = useState([]);
  const [toastElPosition, setToastElPosition] = useState({ top: 0, left: 0 }); // toast창 위치 값
  const [newsinfo, setNewsInfo] = useState({
    admin: adminLogin.id,
    title: "",
    category: "",
    startDate: "",
    endDate: "",
    content: "",
    term: "",
    attachedFileURL: [],
  });
  const [btnState, setBtnState] = useState(false);

  const toast_func = (text) => {
    console.log(text);
    toast(
      <p>{text}</p>,
      toastCommonProps(3000, "top-left", "toast_common toast__normal", Flip)
    );
  };
  useEffect(() => {
    let pageOpen = false;
    disable = [];

    const getNews = () => {
      api.get(common.baseURL + "news").then((res) => {
        if (res.data.ok === true) {
          const get_data = res.data.newsList;

          for (let i = 0; i < get_data.length; i++) {
            disable[i] = getDatesStartToLast(
              get_data[i].startDate,
              get_data[i].endDate
            );
          }
          disable = disable.flat();
          for (let i = 0; i < disable.length; i++) {
            disable[i] = new Date(disable[i]);
          }
          setDisabledDates(disable); // update the state here/  disable 배열을 그냥 바로 사용하면 DatePicker 먼저 렌더링되어서 한번 클릭해야 업데이트 되는 문제 있음
        }
      });
    };
    if (!pageOpen) {
      getNews();
    }
    return () => {
      pageOpen = true;
    };
  }, []);

  const newsFileArr = [];
  const [files, setFiles] = useState([]);
  const regex = /\s/gi;
  const bowl = [];

  const onChangeFile = (e) => {
    const toastEl = document.querySelector(".toast_standard");

    setToastElPosition({
      top: toastEl.getBoundingClientRect().top,
      left: toastEl.getBoundingClientRect().left,
    });
    //공백제거
    const file = e.target.files;
    let cnt = 0;
    for (let i = 0; i < file.length; i++) {
      const file_form = file[i].name.slice(
        file[i].name.indexOf("."),
        file[i].name.length
      );
      newsFileArr.push(file[i].lastModified + file[i].name);
      bowl.push(file[i].name);
      if (file_form === ".jpg" || file_form === ".jpeg") {
        cnt++;
        if (cnt > 5) {
          toast_func("이미지가 5개를 초과하였습니다.");
          break;
        }
      }
    }
    if (cnt < 6) {
      setFiles(file);
      setNewsInfo({ ...newsinfo, attachedFileURL: newsFileArr });
      setFileon(true);
      setSetfile(bowl);
    }
  };

  const onContent = (value) => {
    setNewsInfo({ ...newsinfo, content: value });
  };
  const onChangeInfo = (e) => {
    const { name, value } = e.target;

    setNewsInfo({ ...newsinfo, [name]: value });
  };
  const onSubmitData = () => {
    console.log(newsinfo);
    num = 20;
    const toastEl = document.querySelector(".toast_standard");

    setToastElPosition({
      top: toastEl.getBoundingClientRect().top,
      left: toastEl.getBoundingClientRect().left,
    });

    let include = false;
    for (let i = 0; i < disable.length; i++) {
      if (startDate < disable[i] && endDate > disable[i]) include = true;
    }

    let axiosnum = 0;
    //게시기간 체크
    if (
      newsinfo.startDate === "" ||
      newsinfo.startDate === "Invalid Date" ||
      newsinfo.endDate === "" ||
      newsinfo.endDate === "Invalid Date"
    ) {
      num = 493;
      toast_func("게시기간을 등록해주세요.");
    }
    //제목과 내용 체크
    else if (
      newsinfo.content === "" ||
      newsinfo.content === "<p><br></p>" ||
      newsinfo.content.replace(/(\s*)/g, "") === "<p></p>" ||
      newsinfo.title.replace(/(\s*)/g, "") === ""
    ) {
      toast_func("누락된 항목이 있습니다.");
    }
    //게시기간 중복 체크
    else if (include) {
      alert(
        "게시 기간이 중복된 새소식이 있습니다.\n이전 글의 게시 기간 또는 현재 글의 게시 기간을 조정하여 등록해 주세요."
      );
    } else {
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          let formData = new FormData();
          const filename = files[i].lastModified + files[i].name;
          formData.append("file", files[i]);
          formData.append("name", filename.replace(regex, ""));
          // eslint-disable-next-line no-loop-func
          api
            .post(common.baseURL + "upload/oneNew", formData)
            .then((awsres) => {
              if (awsres.data.ok === true) {
                axiosnum = axiosnum + 1;
              } else {
                toast_func("사진 등록이 실패하였습니다.");
              }
              if (axiosnum === files.length) {
                api
                  .post(common.baseURL + "news", newsinfo)
                  .then((postRes) => {
                    setBtnState(true);

                    const p_text = postRes.data.ok
                      ? "저장되었습니다."
                      : "새소식을 등록할 수 없습니다.";
                    toast_func(p_text);

                    if (postRes)
                      setTimeout(() => navigate("/admin/main/news"), 3000);
                  })
                  .catch((error) => console.log(error));
              }
            });
        }
      } else {
        api
          .post(common.baseURL + "news", newsinfo)
          .then((postRes) => {
            if (postRes.data.ok === true) {
              setBtnState(true);

              toast_func("저장되었습니다.");

              setTimeout(() => navigate("/admin/main/news"), 3000);
            } else toast_func("누락된 항목이 있습니다.");
          })
          .catch((error) => console.log(error));
      }
    }
  };

  return (
    <>
      <style jsx="true">{`
        .Toastify__toast {
          top: ${toastElPosition.top - num}px;
          left: ${toastElPosition.left - 285}px;
        }
        .ql-toolbar.ql-snow {
          border-top-width: 0 !important;
          border-left-width: 0 !important;
          border-right-width: 0 !important;
          border-bottom-width: 2px !important;
          border-color: #e5e6e7 !important;
        }
        .ql-container.ql-snow {
          border: none !important;
        }
        .ql-container.ql-snow .ql-editor {
          padding: 0px !important;
        }
      `}</style>

      <div className="admin flex flex-col grow py-[42px] px-[12px]">
        <label className="text-2xl font-extrabold leading-8 text-[#020A1B]">
          새소식 등록
        </label>
        <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-3 min-w-[975px]">
          <div className="grid grid-cols-5 min-w-[915px] text-center border border-[#E5E6E7] bg-[#fafbfc] ">
            <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border">
              작성자
            </label>
            <label className="flex justify-start items-center pl-6 py-[7px] border border-[#E5E6E7] bg-[#F1F2F3] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-4">
              {adminLogin.role === 0
                ? adminLogin.belongName + " 관리자"
                : adminLogin.role === 1
                ? adminLogin.belongName + " 의사"
                : adminLogin.belongName + " 간호사"}
            </label>

            <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border">
              제목
            </label>
            <input
              className="w-full flex justify-start items-center text-left px-6 py-[7px] border border-[#E5E6E7] leading-[22px] box-border col-span-3"
              placeholder=" 제목을 입력하세요."
              value={newsinfo.title}
              name="title"
              onChange={onChangeInfo}
            />
            <select
              className="inline-block border-l pl-4 bg-[#F2F6FC] col-span-1 border border-[#E5E6E7]"
              onChange={onChangeInfo}
              name="category"
              value={newsinfo.category}
            >
              <option value="-">선택해주세요</option>
              <option value="1">장루소식</option>
              <option value="2">앱소식</option>
              <option value="3">커뮤니티</option>
            </select>
            <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border">
              게시 기간
            </label>
            <div className="w-full flex text-left px-6 py-[7px] border border-[#E5E6E7] leading-[22px] box-border col-span-4 bg-white">
              <div className="flex w-5/6 bg-white">
                <div
                  className="inline-block w-4/6 px-2"
                  standard="toast_standard_2"
                >
                  <DatePicker
                    className="w-full my-1 font-bold text-center border rounded-full "
                    selectsRange={true}
                    dateFormat="yyyy년 MM월 dd일"
                    selected=""
                    startDate={startDate}
                    endDate={endDate}
                    minDate={new Date()}
                    excludeDates={disabledDates}
                    monthsShown={2}
                    onChange={(update) => {
                      setStartDate(update[0]);
                      setEndDate(update[1]);
                      setNewsInfo({
                        ...newsinfo,
                        startDate: dayjs(startDate).format("YYYY-MM-DD"),
                        endDate: dayjs(update[1]).format("YYYY-MM-DD"),
                        term: 0,
                      });
                    }}
                    isClearable={false}
                    selectsDisabledDaysInRange
                    withPortal
                  />
                </div>
              </div>
            </div>
            <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border">
              내용
            </label>
            <div className="w-full bg-white vertical-middle border border-[#E5E6E7] col-span-4 overflow-auto">
              <TextEditorToolbar />
              <ReactQuill
                className="w-full h-40 p-6"
                theme="snow"
                value={newsinfo.content}
                onChange={onContent}
                placeholder={"내용을 입력하세요"}
                modules={modules}
                formats={formats}
              />
            </div>
            <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border">
              첨부파일
            </label>
            <div className="flex flex-col flex-wrap p-6 bg-white border border-[#E5E6E7] font-semibold col-span-4 ">
              <div className="flex flex-col flex-wrap col-span-4 p-2 font-semibold ">
                <label
                  htmlFor="input_file"
                  className="w-[88px] h-[38px] px-4 py-2 bg-[#4E535F] hover:bg-gray-700 cursor-pointer text-[#FFFFFF] font-bold text-base leading-[22px] rounded-[6px] mb-2"
                >
                  파일첨부
                </label>
                <input
                  id="input_file"
                  className="hidden w-full"
                  type="file"
                  accept=".jpeg, .jpg, .png, .pdf"
                  label="Upload"
                  multiple
                  placeholder="첨부할 파일이 있는 경우 버튼을 클릭하여 등록하세요. (이미지는 최대 5개)"
                  onChange={onChangeFile}
                />
                {fileon ? (
                  <p className="w-auto mt-2 text-sm text-left text-gray-400">
                    {setfile.join(", ")}
                  </p>
                ) : (
                  <p className="mt-2 text-sm text-left text-gray-400">
                    *첨부 파일이 있는 경우 이곳을 클릭하여 등록하세요.(최대 5개)
                  </p>
                )}
              </div>
            </div>

            <label className="flex justify-start items-center pl-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border">
              작성일
            </label>
            <label className="flex bg-white justify-start items-center pl-6 py-[7px] border border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-4 ">
              {dayjs().format("YYYY-MM-DD")}
            </label>
          </div>
          <div className="flex justify-end items-center mt-[18px]">
            <div className="flex gap-x-2">
              <BtnBlack
                context="저장하기"
                onClick={onSubmitData}
                loading={loading}
                width="88px"
                standard="toast_standard"
                disabled={btnState}
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default NewsAddComponent;
