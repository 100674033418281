import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMainContext } from "../../../../contexts/Provider";

/*component*/

import Style1A from "./components/Style1A";
import Style2B from "./components/Style2B";
import Style3 from "./components/Style3";
import Style2A from "./components/Style2A";
import Style5 from "./components/Style5";
import Style6 from "./components/Style6";
import MainIcons from "../../mobilecomponent/MainIcons";
const More = () => {
  const { loginInput } = useMainContext();

  const title = useLocation().state.title;
  const title2 = useLocation().state.title2;
  const svgurl = useLocation().state.svgurl;
  const btn1 = useLocation().state.btn1;
  const btn2 = useLocation().state.btn2;
  const btn3 = useLocation().state.btn3;
  const btn4 = useLocation().state.btn4;
  const btn5 = useLocation().state.btn5;
  const btn6 = useLocation().state.btn6;
  const bigTitle = useLocation().state.bigTitle;
  const gomain = useLocation().state.jangrucontents;
  const navigate = useNavigate();
  const leftarrow = "/images/mo/leftarrow.svg";
  const more_big = "/images/mo/more_big.svg";
  const pathname = useLocation().pathname;
  // window.onpopstate = function () {
  //   if (pathname === "/check/11" || pathname === "/check/10") {
  //     window.location.replace("/", true);
  //   }
  // };
  useEffect(() => {
    let pageOpen = false;

    if (!pageOpen) {
      if (
        loginInput.id === null ||
        loginInput.id === undefined ||
        loginInput.id === ""
      ) {
        navigate("/main");
      }
    }
    return () => {
      pageOpen = true;
    };
  }, [loginInput.id, navigate]);

  return (
    <>
      <div className="relative h-screen overflow-y-auto wordbreak-keepall ">
        <div className="fixed top-0 left-0 w-full bg-[#FAFCFF]  border-b-[1px] px-[8px] z-2">
          <div className="flex items-center justify-between">
            <img
              className="inline-block cursor-pointer "
              src={leftarrow}
              alt="leftarrow"
              onClick={() => {
                if (gomain === "gomain") {
                  navigate("/main");
                } else {
                  navigate(-1);
                }
              }}
            />
            <span className="inline-block subtitle_1_400">{title2}</span>
            <img
              src={more_big}
              alt="more_big"
              className="cursor-pointer"
              onClick={() => {
                navigate("/alljangru");
              }}
            />
          </div>
        </div>
        <div className="pt-[48px] content w-full">
          {title === "소화기관?!" ? (
            <Style1A title2={title2} bigTitle={bigTitle} />
          ) : title === "장루?!" ? (
            <Style1A title2={title2} bigTitle={bigTitle} />
          ) : title === "장루 관리방법" ? (
            <Style2B title2={title2} bigTitle={bigTitle} />
          ) : title === "식생활 관리" ? (
            <Style3
              title2={title2}
              bigTitle={bigTitle}
              btn1={btn1}
              btn2={btn2}
              btn3={btn3}
              svgurl={svgurl}
            />
          ) : title === "생활 속 관리" ? (
            <Style3
              title2={title2}
              bigTitle={bigTitle}
              btn1={btn1}
              btn2={btn2}
              btn3={btn3}
              btn4={btn4}
              svgurl={svgurl}
            />
          ) : title === "신체활동 관리" ? (
            <Style1A title2={title2} bigTitle={bigTitle} svgurl={svgurl} />
          ) : title === "장루 합병증" ? (
            <Style3
              title2={title2}
              bigTitle={bigTitle}
              btn1={btn1}
              btn2={btn2}
              btn3={btn3}
              btn4={btn4}
              btn5={btn5}
              btn6={btn6}
              svgurl={svgurl}
            />
          ) : title === "피부 합병증" ? (
            <Style3
              title2={title2}
              bigTitle={bigTitle}
              btn1={btn1}
              btn2={btn2}
              btn3={btn3}
              btn4={btn4}
              btn5={btn5}
              btn6={btn6}
              svgurl={svgurl}
            />
          ) : title === "응급상황 대처" ? (
            <Style2A title={title} title2={title2} bigTitle={bigTitle} />
          ) : title === "정신건강 관리" ? (
            <Style3
              title2={title2}
              bigTitle={bigTitle}
              btn1={btn1}
              btn2={btn2}
              btn3={btn3}
              btn4={btn4}
              btn5={btn5}
              btn6={btn6}
              svgurl={svgurl}
            />
          ) : title === "복지혜택" ? (
            <Style3
              title2={title2}
              bigTitle={bigTitle}
              btn1={btn1}
              btn2={btn2}
              btn3={btn3}
              btn4={btn4}
              svgurl={svgurl}
            />
          ) : title === "자주묻는질문" ? (
            <Style5 title2={title2} bigTitle={bigTitle} svgurl={svgurl} />
          ) : title === "장루 교체방법" ? (
            <Style6 title2={title2} bigTitle={bigTitle} svgurl={svgurl} />
          ) : title === "일상속 장루" ? (
            <Style6 title2={title2} bigTitle={bigTitle} svgurl={svgurl} />
          ) : title === "원피스 교체" ? (
            <Style6 title2={title2} bigTitle={bigTitle} />
          ) : title === "투피스 교체" ? (
            <Style6 title2={title2} bigTitle={bigTitle} />
          ) : title === "장루 셀프 교체" ? (
            <Style6 title2={title2} bigTitle={bigTitle} />
          ) : (
            ""
          )}
          <div className="h-[72px]"></div>
        </div>
      </div>
      <MainIcons />
    </>
  );
};

export default More;
