import React from "react";
const AroundStomaHernia = ({ bigTitle, btn1 }) => {
  const image1 = "/images/mo/jangrucont/장루합병증1.jpg";
  const image2 = "/images/mo/jangrucont/장루합병증2.jpg";
  const image3 = "/images/mo/jangrucont/장루합병증3.jpg";
  const image4 = "/images/mo/jangrucont/장루합병증4.jpg";

  return (
    <div className="w-full flex flex-col  px-[16px] body_1_400 wordbreak-keepall">
      <div className="pt-[72px] ">
        <div className="flex flex-col items-center justify-center w-full border-b">
          <span className="text-[#679DEE] caption_1_400">{bigTitle}</span>
          <h1 className="h2_800 pt-[4px] pb-[24px]">{btn1}</h1>
        </div>
        <div className="w-full pt-[24px]">
          <div>
            <div className="subtitle_1_700 pb-[8px]">1) 정의</div>
            <div className="w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
              <img src={image1} alt="image1" className="h-full" />
            </div>
            <div className="pt-[8px]">
              장루주위의 피하조직 안으로 장이 돌출되는 것을 말합니다.
            </div>
          </div>
          <div className="pt-[32px]">
            <div className="subtitle_1_700 pb-[8px]">2) 관리방법</div>
            <div className="w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
              <img src={image2} alt="image2" className="h-full" />
            </div>
            <div className=" mt-[8px] w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
              <img src={image3} alt="image3" className="h-full" />
            </div>
            <span className="block pt-[8px] text-center text-[#676C76] caption_1_400">
              [장루용 복대 착용 모습]
            </span>
            <div className="relative mt-[8px] w-full h-[220px] bg-[#E5E6E7] flex justify-center items-center">
              <img src={image4} alt="image4" className="h-full" />
            </div>
            <span className="block pt-[8px] text-center text-[#676C76] caption_1_400">
              [장루용 벨트 착용 모습]
            </span>
            <div className="pt-[8px] body_1_400_jangrucont">
              <div>1. 장루용 복대를 착용합니다.</div>
              <div className="flex justify-end ">
                <span className="inline-block">2. &nbsp;</span>
                <div>
                  투피스인 경우 피부 보호판은 가위집을 낸다. 또는 제품을
                  원피스로 교체합니다.
                </div>
              </div>
              <div>3. 장세척을 중단합니다.</div>
              <div className="flex justify-end ">
                <span className="inline-block">4. &nbsp;</span>
                <div>
                  탈장의 정도가 심한 경우는 수술이 필요할 수도 있으므로 의사와
                  상담합니다.
                </div>
              </div>
              <div className="flex justify-end ">
                <span className="inline-block">5. &nbsp;</span>
                <div>
                  장루로 가스와 대변이 나오지 않으면서 복통이 지속될때는 병원을
                  방문합니다.
                </div>
              </div>
            </div>
          </div>
          <div className="pt-[32px]">
            <div className="subtitle_1_700 pb-[8px]">3) 예방</div>

            <div className="pt-[8px] ">
              <div>1. 과도한 체중 증가를 피합니다.</div>
              <div>2. 무거운 물건을 드는 것과 강도 높은 활동은 피합니다.</div>
              <div>3. 복부근육이 약한 경우 장루용 복대를 사용합니다.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AroundStomaHernia;
