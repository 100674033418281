import React, { useEffect } from "react";
import { useMainContext } from "../../../../contexts/Provider";
/*component*/
import Header2 from "../../admin_components/Header/Header2";
import MessageSend from "./component/MessageSend";

const Write = () => {
  const { members, setMembers } = useMainContext();

  useEffect(() => {
    setMembers([]);
  }, [setMembers]);

  useEffect(() => {
    let pageOpen = false;
    const getTenMembers = () => {
      alert("10이상입니다");
      let tenmembers = members.slice(-10);
      setMembers(tenmembers);
    };
    if (!pageOpen && members.length > 10) {
      getTenMembers();
    }
    return () => {
      pageOpen = true;
    };
  }, [members, setMembers]);

  return (
    <Header2
      title={[
        { title: "쪽지", url: "/admin/main/message" },
        {
          title: "쪽지 작성",
          url: 0,
        },
      ]}
    >
      <div className="w-full h-full ">
        <div className="px-[20px] py-[70px]">
          <MessageSend />
        </div>
      </div>
    </Header2>
  );
};

export default Write;
