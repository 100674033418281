import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMainContext } from "../../../../contexts/Provider";

import common, { api } from "../../../../data/common";
/*component*/
import Header2 from "../../admin_components/Header/Header2";
import CommuInfoTable from "./components/CommuInfoTable";
import CommunityInfoCheerup from "./components/CommunityInfoCheerup";
import CommunityInfoComment from "./components/CommunityInfoComment";
import { Loading } from "../../admin_components/Loading";
import { Flip, toast, ToastContainer } from "react-toastify";
import { BtnBlack } from "../../admin_components/Buttons";
import { toastCommonProps } from "../../admin_components/Toast";

const CommunityId = () => {
  const [toastElPosition, setToastElPosition] = useState({ top: 0, left: 0 }); // toast창 위치 값
  const [loading, setLoading] = useState(true);
  const commudata = useLocation().state.data; //게시글 정보
  const [reportdata, setReportdata] = useState([]);
  const [input, setInput] = useState(""); //댓글 입력
  const [cheerup, setCheerup] = useState([]); //응원한 사람 List
  const [mycheer, setMyCheer] = useState([]); //나의 응원 여부
  const [comment, setComment] = useState([]); //댓글 List
  const postingid = commudata.id;
  const { adminLogin } = useMainContext(); //로그인 정보
  const adminid = adminLogin.id;
  let flag = 0;
  const toast_func = (text) => {
    console.log(text);
    toast(
      <p>{text}</p>,
      toastCommonProps(3000, "top-right", "toast_common toast__normal", Flip)
    );
  };
  //해당 게시글의 정보 불러오기
  useEffect(() => {
    let pageOpen = false;
    const getCommunitiyID = () => {
      api
        .get(common.baseURL + "community/posting/idForAdmin/" + postingid)
        .then((res) => {
          if (res.data.ok === true) {
            console.log(res.data.posting, postingid);
            setReportdata(res.data.posting.report);
            setLoading(false);
            setCheerup(res.data.cheerupList);
            setComment(res.data.commentList);
            const mine = res.data.cheerupList.filter(
              (el) =>
                el.admin !== null &&
                el.admin.id === adminLogin.id &&
                el.status === 1
            );
            setMyCheer(mine);
          }
        });
    };
    if (!pageOpen) {
      getCommunitiyID();
    }
    return () => {
      pageOpen = true;
    };
  }, [adminLogin.id, postingid]);
  //댓글 저장
  const submitData = () => {
    const body = {
      contents: input,
      public: 0,
      posting: postingid,
      admin: adminid,
    };
    if (body.contents.replace(/(\s*)/g, "").length !== 0)
      flag = 108; //댓글 입력 값이 공백이 아닐 때, flag로 위치 설정 // 댓글 추가에 따라 toast 위치 내리기
    else flag = 0;

    const toastEl = document.querySelector(".toast_standard"); //
    setToastElPosition({
      top: toastEl.getBoundingClientRect().top + flag, //
      right: toastEl.getBoundingClientRect().right, //==> 저장하기 버튼의 좌표 값 붎러옴
    });

    if (body.contents.replace(/(\s*)/g, "").length === 0) {
      toast_func("댓글 내용을 작성해주세요.");
    } else {
      api
        .post(common.baseURL + "community/comment", body)
        .then((res) => {
          console.log(body, res, comment, adminLogin);
          if (res.data.ok === true) {
            toast_func("댓글이 저장되었습니다.");
            setInput("");
            api
              .get(common.baseURL + "community/posting/idForAdmin/" + postingid)
              .then((getRes) => {
                if (getRes.data.ok === true) {
                  setComment(getRes.data.commentList);
                }
              });
          } else toast_func("뎃글 저장에 실패하였습니다.");
        })
        .catch((error) => console.log(error));
    }
  };
  // 따봉 버튼 클릭
  const cheerUpThumb = () => {
    let body = {
      status: mycheer.length > 0 ? 0 : 1,
      posting: postingid,
      admin: adminLogin.id,
    };

    api.post(common.baseURL + "community/cheerup", body).then((postRes) => {
      if (postRes.data.ok === true) {
        api
          .get(common.baseURL + "community/posting/idForAdmin/" + postingid)
          .then((getRes) => {
            if (getRes.data.ok === true) {
              setCheerup(getRes.data.cheerupList);
              const minecheer = getRes.data.cheerupList.filter(
                (el) =>
                  el.admin !== null &&
                  el.patient === null &&
                  el.admin.id === adminLogin.id &&
                  el.status === 1
              );
              setMyCheer(minecheer);
            }
          });
      }
    });
  };

  return (
    <>
      <style jsx="true">
        {`
          .Toastify__toast {
            top: ${toastElPosition.top - 65}px;
            left: ${toastElPosition.width}px;
          }
        `}
      </style>
      <Header2
        title={[
          { title: "커뮤니티 관리", url: "/admin/main/community" },
          {
            title: "게시글 상세보기",
            url: 0,
          },
        ]}
      >
        <div className="admin flex flex-col grow p-[32px]">
          <div className="h-[80px] min-h-[80px]"></div>
          <label className="text-2xl font-extrabold leading-8 text-[#020A1B] ">
            게시글 상세보기
          </label>
          {loading.first ? (
            <Loading />
          ) : (
            <>
              <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-3 min-w-[975px]">
                <CommuInfoTable commudata={commudata} reportdata={reportdata} />
              </div>
              <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-6 min-w-[975px]">
                <span className="font-bold flex">
                  {mycheer.length > 0 ? (
                    <span
                      key="off"
                      className="material-icons cursor-pointer text-red-500 mr-1"
                      onClick={() => {
                        cheerUpThumb();
                      }}
                    >
                      thumb_up
                    </span>
                  ) : (
                    <span
                      key="on"
                      className="material-icons hover:text-red-500 cursor-pointer mr-1"
                      onClick={() => {
                        cheerUpThumb();
                      }}
                    >
                      thumb_up_off_alt
                    </span>
                  )}
                  {/* thumb_up_off_alt cheerUpThumb*/}
                  <span className="font-bold mx-1">
                    응원해요 {cheerup.length}
                  </span>
                </span>
                <div className="flex flex-wrap">
                  {loading ? (
                    <Loading />
                  ) : cheerup ? (
                    cheerup.map((el) => (
                      <CommunityInfoCheerup key={el.id} cheerupthing={el} />
                    ))
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* 세번째 박스 */}
              <div className="p-6 rounded-3xl bg-[#FAFBFC] shadow-[0_1px_4px_rgba(0,0,0,0.25)] mt-6  min-w-[975px]">
                <div className="pt-1">
                  <div className="flex mb-1 pb-2">
                    <span className="material-icons mr-1">chat</span>
                    <span className="font-bold mx-1 ">
                      댓글 {comment.length}
                    </span>
                  </div>

                  {loading ? (
                    <Loading />
                  ) : (
                    comment.map((el, index) => (
                      <CommunityInfoComment
                        key={el.id}
                        commentcont={el}
                        commudata={commudata}
                        index={index}
                      />
                    ))
                  )}
                </div>
                {/* <div className="grid mt-6 rounded-r-[6px] grid-cols-[repeat(6,minmax(156.5px,1fr))] min-w-[690px] text-center border border-[#E5E6E7] border-y border-y-[#E5E6E7] bg-white"> */}
                <div className="grid grid-cols-10  mt-6 rounded-r-[6px] min-w-[690px] text-center border border-[#E5E6E7] bg-[#fafbfc] ">
                  <label className="flex justify-start items-center px-4 py-[7px] border border-[#E5E6E7] bg-[#F2F6FC] font-semibold text-base leading-[22px] text-[#4E535F] box-border col-span-1">
                    댓글작성
                  </label>

                  <input
                    className="flex outline-0 justify-center bg-white items-center px-3 py-[7px] border-y border-l border-[#E5E6E7] font-semibold text-base leading-[22px] text-[#020A1B] box-border col-span-8"
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") submitData();
                    }}
                    value={input}
                  />
                  <BtnBlack
                    context={input ? "저장하기_댓글" : "저장하기_댓글_non"}
                    onClick={submitData}
                    loading={loading}
                    width="88px"
                    standard="toast_standard"
                  />
                </div>
              </div>
            </>
          )}
          <div className="h-[80px] min-h-[80px]"></div>
        </div>
        )
        <ToastContainer />
      </Header2>
    </>
  );
};

export default CommunityId;
