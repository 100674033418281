import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
const Check2 = ({ checkInfo, setCheckInfo }) => {
  const [state1, setState1] = useState(false);
  const [state2, setState2] = useState(false);
  const [state3, setState3] = useState(false);
  const [state4, setState4] = useState(false);
  const [state5, setState5] = useState(false);
  const [state6, setState6] = useState(false);
  const [state7, setState7] = useState(false);
  const [allSymptoms, setAllSymptoms] = useState([]);
  let newSetSypmtoms = [...new Set(allSymptoms)];

  const thisRef = useRef();
  const sectionRef = useRef();
  const navigate = useNavigate();

  const onChangeCheck = (e) => {
    const { value } = e.target;
    setCheckInfo({ ...checkInfo, symptomNote: value });
  };
  console.log(checkInfo, ">>checkinfo");
  useEffect(() => {
    let pageOpen = false;
    let numSymptoms = [];
    const SymptomToggle = () => {
      if (checkInfo.symptom.length > 0) {
        checkInfo.symptom.map((el) => {
          if (el === 1) {
            numSymptoms.push("문제없음");
          }
          if (el === 2) {
            numSymptoms.push("장루교체가 능숙치 않음");
          }
          if (el === 3) {
            numSymptoms.push("일상생활,사회활동 어려움");
          }
          if (el === 4) {
            numSymptoms.push("식생활,영양관리 어려움");
          }
          if (el === 5) {
            numSymptoms.push("대변 누출이 잦음");
          }
          if (el === 6) {
            numSymptoms.push("심리적 어려움");
          }
        });
        console.log(numSymptoms, checkInfo.symptom, ">>>.");
        setAllSymptoms(numSymptoms);
      }
    };
    if (!pageOpen) {
      SymptomToggle();
    }
    return () => {
      pageOpen = true;
    };
  }, [checkInfo]);
  const setTimeoutScroll = () => {
    const forScrollEl = document.querySelector(".for__scroll");
    forScrollEl.scroll(0, forScrollEl.clientHeight);
  };
  const filteredAllSymptom = (e) => {
    let filtered = newSetSypmtoms.filter((el) => el !== e.target.innerText);
    setAllSymptoms(filtered);
  };
  const noProblemExceptAllsymtpom = (e) => {
    let noprofiltered = allSymptoms.filter((el) => el !== "문제없음");
    setAllSymptoms([...noprofiltered, e.target.innerText]);
    setState1(false);
  };
  const onClickState1 = (e) => {
    setState1(!state1);
    if (state1) {
      let filtered = newSetSypmtoms.filter((el) => el !== e.target.innerText);
      setAllSymptoms([...filtered]);

      console.log(e.target.innerText, ">>>>etarget 문제없음");
    } else {
      setAllSymptoms([e.target.innerText]);
      setState2(false);
      setState3(false);
      setState4(false);
      setState5(false);
      setState6(false);
      setState7(false);
      checkInfo.symptomNote = "";
    }
  };
  const onClickState2 = (e) => {
    setState2(!state2);

    if (state2) {
      filteredAllSymptom(e);
    } else {
      noProblemExceptAllsymtpom(e);
    }
  };
  const state1true = allSymptoms.filter((el) => el === "문제없음");
  const state2true = allSymptoms.filter(
    (el) => el === "장루교체가 능숙치 않음"
  );
  const state3true = allSymptoms.filter(
    (el) => el === "일상생활,사회활동 어려움"
  );
  const state4true = allSymptoms.filter(
    (el) => el === "식생활,영양관리 어려움"
  );
  const state5true = allSymptoms.filter((el) => el === "대변 누출이 잦음");
  const state6true = allSymptoms.filter((el) => el === "심리적 어려움");

  const onClickState3 = (e) => {
    // sectionRef.current.scrollIntoView({ behavior: "auto", block: "end" });
    setTimeoutScroll();
    setState3(!state3);
    if (state3) {
      filteredAllSymptom(e);
    } else {
      noProblemExceptAllsymtpom(e);
    }
  };
  const onClickState4 = (e) => {
    setTimeoutScroll();
    setState4(!state4);
    if (state4) {
      filteredAllSymptom(e);
    } else {
      noProblemExceptAllsymtpom(e);
    }
  };
  const onClickState5 = (e) => {
    setTimeoutScroll();
    setState5(!state5);
    if (state5) {
      filteredAllSymptom(e);
    } else {
      noProblemExceptAllsymtpom(e);
    }
  };
  const onClickState6 = (e) => {
    setTimeoutScroll();
    setState6(!state6);
    if (state6) {
      filteredAllSymptom(e);
    } else {
      noProblemExceptAllsymtpom(e);
    }
  };
  const onClickState7 = (e) => {
    setTimeout(() => {
      setTimeoutScroll();
    }, [400]);
    setState7(!state7);

    if (state7) {
      filteredAllSymptom(e);
    } else {
      noProblemExceptAllsymtpom(e);
    }
  };
  const setNewSymptoms = () => {
    let numSymptoms = [];
    newSetSypmtoms.map((el) => {
      if (el === "문제없음") {
        numSymptoms.push(1);
      }
      if (el === "장루교체가 능숙치 않음") {
        numSymptoms.push(2);
      }
      if (el === "일상생활,사회활동 어려움") {
        numSymptoms.push(3);
      }
      if (el === "식생활,영양관리 어려움") {
        numSymptoms.push(4);
      }
      if (el === "대변 누출이 잦음") {
        numSymptoms.push(5);
      }
      if (el === "심리적 어려움") {
        numSymptoms.push(6);
      }
    });
    setCheckInfo({ ...checkInfo, symptom: numSymptoms });
    navigate(`/check/3`);
  };
  const coloredBg =
    "surveycheck coloredBg mb-[12px] border rounded w-full px-[24px] py-[16px] flex items-center cursor-pointer bg-[#CBDFFC] border-[#1B5EDC] focus:outline-none body_1_600";
  const noneBg =
    "surveycheck mb-[12px] border rounded w-full px-[24px] py-[16px] flex items-center cursor-pointer bg-[#F2F6FC] border-[#9DC4FA] active:bg-[#CBDFFC] focus:outline-none";

  return (
    <>
      <div className="pt-[94px] px-[24px]">
        <div className="pb-[80px]">
          <h1 className="h3_800">지난 일주일 동안 장루관리를</h1>
          <h1 className="h3_800 pb-[4px]">
            하면서 불편하셨던 점을 체크해 주세요.
          </h1>
          <h1 className="text-[#676C76] body_1_400">중복 체크가 가능합니다.</h1>
        </div>
        <div className="text-[#4E535F] pb-[76px]">
          <div
            className={state1true.length === 1 ? coloredBg : noneBg}
            onClick={onClickState1}
          >
            문제없음
          </div>
          <div
            className={state2true.length === 1 ? coloredBg : noneBg}
            onClick={onClickState2}
          >
            장루교체가 능숙치 않음
          </div>
          <div
            className={state3true.length === 1 ? coloredBg : noneBg}
            onClick={onClickState3}
          >
            일상생활,사회활동 어려움
          </div>
          <div
            className={state4true.length === 1 ? coloredBg : noneBg}
            onClick={onClickState4}
          >
            식생활,영양관리 어려움
          </div>
          <div
            className={state5true.length === 1 ? coloredBg : noneBg}
            onClick={onClickState5}
          >
            대변 누출이 잦음
          </div>
          <div
            className={state6true.length === 1 ? coloredBg : noneBg}
            onClick={onClickState6}
          >
            심리적 어려움
          </div>
          <input
            ref={thisRef}
            className={checkInfo.symptomNote !== "" ? coloredBg : noneBg}
            name="symptomNote"
            placeholder="기타"
            value={checkInfo.symptomNote !== "" ? checkInfo.symptomNote : ""}
            onChange={onChangeCheck}
            onClick={onClickState7}
          />
        </div>
        <div className="h-[80px]"></div>
        <div className="h-[1px]" ref={sectionRef}></div>
      </div>
      {newSetSypmtoms.length > 0 || checkInfo.symptomNote !== "" ? (
        <div className="fixed w-full bottom-0 left-0 button_700 bg-[#FAFCFF]">
          <div className="flex ">
            <div
              className="border  w-2/5 py-[20px] flex justify-center items-center cursor-pointer
                     bg-[#FAFCFF] active:bg-[#F2F6FC] border border-[#679DEE] active:border-[#18428E] inline-block button_700  cursor-pointer  ease-in-out duration-300 text-[#1B5EDC] active:text-[#18428E]"
              onClick={() => {
                navigate(`/check/1`);
              }}
            >
              이전
            </div>
            <div
              className=" w-3/5 py-[20px]  flex justify-center items-center cursor-pointer bg-[#1B5EDC] active:bg-[#18428E] text-white button_700 ease-in-out duration-300 "
              onClick={setNewSymptoms}
            >
              다음
            </div>
          </div>
        </div>
      ) : (
        <div className="fixed w-full bottom-0 left-0 w-full button_700 bg-[#FAFCFF]">
          <div className="flex ">
            <div
              className="border  py-[20px] w-2/5 h-full flex justify-center items-center cursor-pointer
                     bg-[#FAFCFF] active:bg-[#F2F6FC] border border-[#679DEE] active:border-[#18428E] inline-block button_700 cursor-pointer  ease-in-out duration-300 text-[#1B5EDC] active:text-[#18428E]"
              onClick={() => {
                navigate(`/check/1`);
              }}
            >
              이전
            </div>
            <div className=" w-3/5 py-[20px]  flex justify-center items-center bg-[#9DC4FA] text-white button_700 ">
              다음
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Check2;
